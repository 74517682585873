
import { Select } from 'antd'
import { compose, withHandlers } from 'recompose'

const handlers = withHandlers({
  changeJob: (props) => (value, option) => {
    const { onChange } = props
    onChange(value, option)
  }
})

const enhance = compose(handlers)

const Option = Select.Option

const JobSelect = (props) => {
  const { jobs, changeJob, value, disabled = false } = props

  const clonedJobs = jobs?.map((job, index) => {
    return {
      key: job?.uuid || index + 1,
      ...job
    }
  })

  return (
    <Select
      allowClear
      value={value}
      disabled={disabled}
      onChange={changeJob}
      showSearch
      filterOption
      placeholder="Select a job"
      optionFilterProp="children"
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      {clonedJobs?.map((job, index) => (
        <Option key={job?.key} value={job?.uuid}>
          {`${job?.jobNo || 'N/A'}
            ${job?.details?.size ? ` - ${job?.details?.size}` : ''}
            ${job?.details?.containerType ? ` - ${job?.details?.containerType}` : ''}
            ${job?.details?.uom ? ` / ${job?.details?.uom}` : ''}`}
        </Option>
      ))}
    </Select>
  )
}

export default enhance(JobSelect)
