
import { Fragment } from 'react'
import { Tooltip } from 'antd'
import moment from 'moment'

import { ApprovedApprovalIcon, PendingApprovalIcon, ApproveType } from '../Container'
import { documentType } from 'App/utils/labelMap'

const ApprovalIcon = (props) => {
  const { workflowApproval, approvedApproval } = props

  if (!approvedApproval) {
    return (
      <Tooltip
        title={
          <Fragment>
            <ApproveType>{documentType[workflowApproval] || workflowApproval}</ApproveType>
            <p>Pending approval.</p>
          </Fragment>
        }
      >
        <PendingApprovalIcon type="clock-circle" />
      </Tooltip>
    )
  }

  return (
    <Tooltip
      title={
        <Fragment>
          <ApproveType>{documentType[workflowApproval] || workflowApproval}</ApproveType>
          <p>
            Approved by : <br />
            {approvedApproval.approvedBy && approvedApproval.approvedBy.email}
          </p>
          Approved on : <br />
          {moment(approvedApproval.createdAt).format('Do MMM, YYYY - h:mm a')}
        </Fragment>
      }
    >
      <ApprovedApprovalIcon type="check-circle-o" />
    </Tooltip>
  )
}

export default ApprovalIcon
