import { memo } from 'react'
import Link from 'App/components/Link'

const HeaderLogo = () => {
  return (
    <Link to="/">
      <picture>
        <source
          width={53}
          height={42}
          alt="miniSwiftLogo"
          media="(max-width:1100px)"
          srcSet="/static/switftlogo_notext.png"
        />
        <img width={110} height={50} alt="swiftLogo" src="/static/swiftLogoR.png" />
      </picture>
    </Link>
  )
}

export default memo(HeaderLogo)
