import styled from 'styled-components'

export const LegTableStyles = styled.div`
  display: block;
  overflow-x: auto;
  margin-top: 10px;
  margin-bottom: 20px;

  table {
    width: 100%;
    border-spacing: 0;
    font-size: 10px !important;
    border: 1px solid LightGray;
    font-family: Arial, Helvetica, sans-serif !important;

    th {
      text-align: center;
    }

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
      :hover {
        background-color: #e6f7ff;
      }
    }

    td {
      text-align: center;
    }

    th,
    td {
      width: 1%;
      margin: 0px;
      padding: 0px 1px;
      border-bottom: 1px solid LightGray;
      border-right: 1px solid LightGray;

      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      :last-child {
        border-right: 0;
      }
    }
  }

  .pagination {
    padding: 0.5rem;
  }
`
