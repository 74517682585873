
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'

import * as voucherActions from 'App/states/reducers/voucher'
import AddCostItemActionUI from './AddCostItemActionUI'

const handlers = withHandlers({
  handleOnCostItemAdded: (props) => (newCostItemValues, bookingUuid) => {
    if (bookingUuid) {
      const { costsheetBookings, updateCostsheetBookings, onCostItemAdded = () => { } } = props

      const updatedBookings =
        costsheetBookings &&
        costsheetBookings.map((booking) => {
          const updatedBooking = { ...booking }

          if (booking.uuid === bookingUuid) {
            const updatedBookingCostItems = [...booking.costItems]

            updatedBookingCostItems.push(newCostItemValues)

            updatedBooking.costItems = updatedBookingCostItems.map((bci) => bci)
          }

          return updatedBooking
        })

      updateCostsheetBookings(updatedBookings)

      onCostItemAdded()
    }
  }
})

const enhance = compose(
  connect(
    (state, props) => ({
      costsheetBookings: state.voucher.costsheetBookings
    }),
    (dispatch) => ({
      dispatch,
      ...bindActionCreators(
        {
          ...voucherActions
        },
        dispatch
      )
    })
  ),
  handlers
)

const AddCostItemAction = (props) => {
  const { bookingUuid, handleOnCostItemAdded } = props

  return <AddCostItemActionUI bookingUuid={bookingUuid} onCostItemAdded={handleOnCostItemAdded} />
}

export default enhance(AddCostItemAction)
