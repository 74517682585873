export const actions = {
  TOGGLE_DOCUMENT_VIEWER: 'TOGGLE_DOCUMENT_VIEWER',
  UPDATE_SELECTED_BOOKING_DOCUMENT: 'UPDATE_SELECTED_BOOKING_DOCUMENT'
}

export const toggleDocumentViewer = () => ({
  type: actions.TOGGLE_DOCUMENT_VIEWER
})

export const updateSelectedBookingDocument = (selectedBookingDocument) => ({
  type: actions.UPDATE_SELECTED_BOOKING_DOCUMENT,
  selectedBookingDocument
})

const initialState = {
  showDocumentViewer: false,
  selectedBookingDocument: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE_DOCUMENT_VIEWER:
      return { ...state, showDocumentViewer: !state.showDocumentViewer }
    case actions.UPDATE_SELECTED_BOOKING_DOCUMENT:
      return { ...state, selectedBookingDocument: action.selectedBookingDocument }
    default:
      return state
  }
}
