import { Icon } from 'antd'
import { Fragment } from 'react'
import { compose, withStateHandlers } from 'recompose'

import { CostItemModal } from './Styled'
import { StyledAnchor } from '../Styled'
import EditCostItemForm from './EditCostItemForm'

const stateHandlers = withStateHandlers(
  {
    visible: false
  },
  {
    handleClickEdit: (state, props) => () => ({
      visible: true
    }),
    handleOnVisibleChange: (state, props) => (visible) => ({
      visible
    }),
    handleOnCostItemEdited: (state, props) => (newCostItemValues, bookingUuid) => {
      const { onCostItemEdited = () => { } } = props

      onCostItemEdited(newCostItemValues)

      return { visible: false }
    },
    handleOnCostItemDeleted: (state, props) => (deletedCostItemValues) => {
      const { onCostItemDeleted = () => { } } = props

      onCostItemDeleted(deletedCostItemValues)

      return { visible: false }
    }
  }
)

const enhance = compose(stateHandlers)

const EditCostItemAction = (props) => {
  const {
    index,
    visible,
    costItem,
    handleClickEdit,
    handleOnVisibleChange,
    handleOnCostItemEdited,
    handleOnCostItemDeleted
  } = props

  return (
    <Fragment>
      <StyledAnchor
        id={`edit-charge-item-${index}-anchor`}
        onClick={handleClickEdit}
      >
        <Icon type='edit' />
      </StyledAnchor>
      <CostItemModal
        width={600}
        title="Edit cost item"
        footer={null}
        visible={visible}
        onCancel={() => handleOnVisibleChange(false)}
      >
        <EditCostItemForm
          costItem={costItem}
          onCostItemEdited={handleOnCostItemEdited}
          onCostItemDeleted={handleOnCostItemDeleted}
        />
      </CostItemModal>
    </Fragment>
  )
}

export default enhance(EditCostItemAction)
