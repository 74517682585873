
import { Table } from 'antd'
import { compose, graphql } from 'react-apollo'
import moment from 'moment'

import { QUERY_REQUEST_EMAIL_RESULTS } from '../../../graphql/template'

const withGraphqlRequestEmailResults = graphql(QUERY_REQUEST_EMAIL_RESULTS, {
  name: 'requestEmailResultsQuery',
  options: (props) => {
    return {
      variables: {
        uuid: props.request.uuid
      },
      fetchPolicy: 'network-only'
    }
  }
})

const enhance = compose(withGraphqlRequestEmailResults)

const columns = [
  {
    title: 'Recipient',
    dataIndex: 'recipient',
    key: 'recipient'
  },
  {
    title: 'Status',
    dataIndex: 'event',
    key: 'event'
  },
  {
    title: 'Date',
    dataIndex: 'timestamp',
    key: 'timestamp',
    render: (text) => moment(text).format('DD/MM/YYYY HH:mm')
  }
]

const RequestItemResultModalContent = (props) => {
  const { requestEmailResultsQuery } = props

  const ds =
    requestEmailResultsQuery.requestEmailResults &&
    requestEmailResultsQuery.requestEmailResults.map((rs, index) => ({
      key: index,
      ...rs
    }))

  return <Table size="small" pagination={false} dataSource={ds} columns={columns} />
}

export default enhance(RequestItemResultModalContent)
