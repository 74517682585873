import styles from './SearchResult.module.css'

import { useEffect, useState, memo, useCallback } from 'react'
import { Row, Col, Typography, Icon, notification } from 'antd'
import { gql, useLazyQuery } from '@apollo/client'
import { useTranslation } from 'react-i18next'
import { withApollo } from 'react-apollo'
import { useHistory } from 'react-router'
import { stringify } from 'query-string'

import UserFilter from './UserFilter'
import SideFilter from './SideFilters'
import BookingListView from './ListView'
import { getBookingQuery } from 'App/components/Layouts/Search/helper'
import { BookingTableColumnProps, getColumns } from './ListView/ColumnBuilder'

import { logger } from 'App/utils/logger'
import { getSearchParamValue } from 'App/utils/u'
import responseHandler from 'App/utils/responseHandler'
import useWindowDimensions from 'App/hooks/useWindowDimensions'
import { useBaseCompanySetting, DEPARTMENTS_SETTING } from 'App/hooks/useBaseCompanySetting'

import { SearchControls, SingleLineTableContainer } from './Styled'

export const BOOKINGS_MAIN_QUERY_STRING = `
  query bookingsMainEs($input: BookingsSearchInput) {
    bookingsSearchJson(input: $input) {
      rows
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

const BOOKINGS_MAIN_QUERY = gql`
  query bookingsMainEs($input: BookingsSearchInput) {
    bookingsSearchJson(input: $input) {
      rows
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

const SearchResult = memo(({ client }: any) => {
  const history = useHistory()
  const { t } = useTranslation()
  const { width } = useWindowDimensions()

  const { setting } = useBaseCompanySetting(DEPARTMENTS_SETTING, {
    client,
    variables: { key: 'overviewPageSettings' },
    fetchPolicy: 'cache-first'
  })

  const [columns, setColumns] = useState<BookingTableColumnProps[]>(getColumns())

  useEffect(() => {
    if (setting?.[0]?.value) {
      setColumns(getColumns(JSON.parse(setting?.[0]?.value)))
    }
  }, [setting])

  const [total, setTotal] = useState(0)
  const [results, setResults] = useState([])
  const [pageSize, setPageSize] = useState(20)
  const [currentPage, setCurrentPage] = useState(0)
  const [showSideDrawer, setShowSideDrawer] = useState(width < 768 ? false : true)
  const [query, setQuery] = useState<any>({
    q: '',
    sort: '',
    limit: 20,
    offset: 0,
    filter: {},
    _noSubGraph: true
  })

  const [getBookings, { loading, data, error }] = useLazyQuery(BOOKINGS_MAIN_QUERY, {
    client,
    variables: { input: query },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    const isAssigned = getSearchParamValue(history, 'isAssigned')
    const offset = Number(getSearchParamValue(history, 'offset')) || 0
    const limit = Number(getSearchParamValue(history, 'limit')) || 20

    const { graphqlFormat } = getBookingQuery(history, {
      __noSubGraph: true,
      isAssigned: isAssigned ? !!Number(getSearchParamValue(history, 'isAssigned')) : null,
      offset,
      limit
    })

    setQuery(graphqlFormat)
    getBookings()
  }, [getBookings, history, history.location.search])

  useEffect(() => {
    if (data?.bookingsSearchJson?.rows) {
      setResults(data.bookingsSearchJson.rows)
      setTotal(data.bookingsSearchJson.pageInfo.count)
      setCurrentPage(
        data.bookingsSearchJson.pageInfo?.offset / data.bookingsSearchJson.pageInfo?.limit
      )
    }
  }, [data])

  const handlePageChange = useCallback(
    (page: any, pageSize: any) => {
      const limit = pageSize
      const offset = (page - 1) * pageSize

      if (limit + offset > 10000) {
        notification.error({
          message: 'You have exceeded query limit. Please narrow your search.',
          duration: 5
        })
        return
      }

      const { graphqlFormat, urlFormat } = getBookingQuery(history, {
        offset,
        limit,
        __noSubGraph: true
      })

      history.push(`/?${stringify(urlFormat)}`)

      setQuery({ ...query, ...graphqlFormat })
    },
    [query, history]
  )

  const handleSizeChange = useCallback((current: number, size: number) => {
    setPageSize(size)
    handlePageChange(current, size)
  }, [handlePageChange])

  useEffect(() => {
    if (history.location.search) return

    const { urlFormat } = getBookingQuery(history, query)

    history.push(`/?${stringify(urlFormat)}`)
  }, [history, query])

  if (error) {
    responseHandler(error, 'error')
    logger.error('BookingsListView BOOKING_SEARCH_QUERY error', error)
  }

  return (
    <SingleLineTableContainer>
      <SearchControls>
        <Row style={{ padding: '3px 0px' }} type="flex" align="middle" justify="space-between">
          <Col>
            <Row type="flex" align="middle" gutter={8}>
              <Col>
                <Typography.Text type="secondary">{t('index.filterByUsers')} :</Typography.Text>
              </Col>
              <Col>
                <UserFilter />
              </Col>
            </Row>
          </Col>
        </Row>
      </SearchControls>

      <div className={styles.result}>
        {showSideDrawer ? (
          <div className={styles.container}>
            <div className={styles.sideFilter}>
              <Icon
                type="menu-fold"
                className={styles.iconAbsolute}
                onClick={() => setShowSideDrawer(false)}
              />
              <SideFilter />
            </div>
            <div className={styles.resultTable}>
              <BookingListView
                total={total}
                query={query}
                client={client}
                columns={columns}
                loading={loading}
                results={results}
                pageSize={pageSize}
                currentPage={currentPage}
                handleSizeChange={handleSizeChange}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        ) : (
          <div className={styles.container}>
            <div className={styles.icon}>
              <Icon type="menu-unfold" onClick={() => setShowSideDrawer(true)} />
            </div>
            <div className={styles.resultTable}>
              <BookingListView
                total={total}
                query={query}
                client={client}
                columns={columns}
                loading={loading}
                results={results}
                pageSize={pageSize}
                currentPage={currentPage}
                handleSizeChange={handleSizeChange}
                handlePageChange={handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </SingleLineTableContainer>
  )
})

export default withApollo(SearchResult)
