import { Skeleton } from 'antd'
import { sortBy } from 'lodash'
import { useQuery } from '@apollo/client'
import { withApollo } from 'react-apollo'
import { compose, withProps } from 'recompose'
import { useState, useEffect } from 'react'
import JobsOverview from './JobsOverview'
import { ErrorWrapper, NoPermissionWrapper } from 'App/components/TableView/Styled'
import documentCreatorsQuery from 'App/containers/documentCreator/query/documentCreatorsQuery'
import { JobStatus } from 'App/types/types'
import config from 'App/config'
import { logger } from 'App/utils/logger'
import { hasPermissionError } from 'App/utils/u'

import { JOBS_FROM_BOOKING_SLIM_QUERY } from 'App/containers/booking/schema/basicBookingQuerySlim'

const bookingUuidProps = withProps(({ booking }) => {
  return {
    bookingUuid: booking.uuid
  }
})

const enhance = compose(bookingUuidProps, documentCreatorsQuery, withApollo)

const getTripAttributes = (job) => {
  return job.trips.map((trip) => {
    return {
      jobUuid: job.uuid,
      fromUuid: trip.from?.uuid,
      fromCompanyUuid: trip.from?.companyUuid,
      toUuid: trip.to?.uuid,
      toCompanyUuid: trip.to?.companyUuid,
      ...trip
    }
  })
}

const getJobsWithROT = (jobs, documentCreators) => {
  const jobsWithROT = jobs?.map((job) => {
    const jobWithROT = {
      ...job,
      trips: getTripAttributes(job),
      isROT: false,
      documentCreator: null
    }

    const documentCreator = documentCreators.find((dc) => {
      return dc.data.jobs.find((j) => j.uuid === job.uuid)
    })

    if (documentCreator) {
      jobWithROT.isROT = true
      jobWithROT.documentCreator = documentCreator
    }

    return jobWithROT
  })

  return sortBy(jobsWithROT, ['jobNo'])
}

const JobsDocumentCreatorOverview = (props) => {
  const { booking, bookingUuid, client, documentCreatorsQuery, expandAllTrips = false, hideDeleted } = props
  const { loading, error, data, refetch } = useQuery(JOBS_FROM_BOOKING_SLIM_QUERY, {
    client,
    variables: { uuid: bookingUuid || booking?.uuid },
    skip: !bookingUuid && !booking?.uuid,
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all'
  })
  const [filterData, setFilterData] = useState(data)
  useEffect(() => {
    if(!data) return
    const filteredData = data?.booking?.jobs?.filter((job) => job?.status !== JobStatus.Cancelled)
    setFilterData(hideDeleted ? { booking: { ...data?.booking, jobs: filteredData } } : data)
  }, [data, hideDeleted, booking])

  if (loading) {
    return <Skeleton active />
  }

  if (error) {
    if (hasPermissionError(error)) {
      return <NoPermissionWrapper>{config.youDoNotHavePermissionToViewThis}</NoPermissionWrapper>
    }

    logger.error(`Failed to load jobs for this booking=${bookingUuid || booking?.uuid}.`, error)
    return <ErrorWrapper>Failed to load, please try again later.</ErrorWrapper>
  }

  const documentCreators = (
    (documentCreatorsQuery.documentCreators && documentCreatorsQuery.documentCreators.rows) || []
  ).filter((dc) => dc.type === 'ROT')

  const jobsWithROT = getJobsWithROT(filterData?.booking?.jobs, documentCreators)
  return (
    <JobsOverview
      booking={booking}
      refetch={refetch}
      jobs={jobsWithROT}
      expandAllTrips={expandAllTrips}
      shipmentType={booking.details.shipmentType}
    />
  )
}

export default enhance(JobsDocumentCreatorOverview)
