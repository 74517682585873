import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose, graphql } from 'react-apollo'
import { Form, Modal, message } from 'antd'

import RequisitionForm from './RequisitionForm'
import ROTImportFormTemplate from './ROTImportFormTemplate'
import ROTExportFormTemplate from './ROTExportFormTemplate'
import { SEND_REQUISITION, QUERY_REQUISITION_TEMPLATE } from 'App/graphql/template'
import respHandler from 'App/utils/responseHandler'
import bookingSchema from 'App/containers/booking/schema'
import DefaultFormTemplate from './DefaultFormTemplate'
import { requestTypes } from 'App/utils/labelMap'

const withGraphqlSendRequisition = graphql(SEND_REQUISITION, {
  props: ({ mutate, ownProps }) => ({
    sendRequisition: (params) =>
      mutate({
        variables: {
          input: params
        },
        refetchQueries: [
          {
            query: bookingSchema.BOOKING_QUERY_WORKFLOW,
            variables: {
              uuid: ownProps.bookingUuid
            }
          }
        ]
      })
  })
})

const withGraphqlRequisitionTemplate = graphql(QUERY_REQUISITION_TEMPLATE, {
  name: 'requisitionTemplateQuery',
  options: (props) => {
    return {
      variables: {
        uuid: props.bookingUuid,
        bookingType: props.booking.type,
        type: props.type
      },
      fetchPolicy: 'network-only'
    }
  }
})

const typeTemplateMap = {
  ImpHlgRequestOfTransportExtraDocs: ROTImportFormTemplate,
  ImpHlgRequestOfTransport: ROTImportFormTemplate,
  ExpHlgRequestOfTransport: ROTExportFormTemplate
}

class RequisitionModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      submitting: false
    }
  }

  handleSendRequest = () => {
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          message.loading('Sending request, hang on...')

          this.setState({
            submitting: true
          })

          await this.props.sendRequisition(values)

          message.destroy()
          message.success('Request has been sent.', 5)

          this.setState({
            submitting: false
          })

          this.props.onClose()
        } catch (error) {
          respHandler(error, 'error')
          this.setState({
            submitting: false
          })
        }
      }
    })
  }

  render() {
    const { type, open, booking } = this.props
    const { requestTemplate, error } = this.props.requisitionTemplateQuery

    if (error) {
      respHandler(error, 'error')
      return null
    }

    if (!requestTemplate) {
      return null
    }

    const Template = typeTemplateMap[type] || DefaultFormTemplate

    return (
      <Modal
        destroyOnClose
        confirmLoading={this.state.submitting}
        title={(requestTypes[type] && requestTypes[type].text) || 'Send Request / Notify'}
        visible={open}
        width={1000}
        okText="Send Request"
        onCancel={this.props.onClose}
        onOk={this.handleSendRequest}
      >
        <RequisitionForm
          bookingUuid={this.props.bookingUuid}
          type={this.props.type}
          jobs={this.props.jobs}
          form={this.props.form}
          requisitionTemplate={requestTemplate}
        >
          {Template && (
            <Template
              form={this.props.form}
              booking={booking}
              requisitionTemplate={requestTemplate}
            />
          )}
        </RequisitionForm>
      </Modal>
    )
  }
}

RequisitionModal.propTypes = {
  open: PropTypes.bool,
  form: PropTypes.object,
  requisitionTemplateQuery: PropTypes.object,
  bookingUuid: PropTypes.string,
  type: PropTypes.string,
  jobs: PropTypes.array,
  onClose: PropTypes.func
}

export default compose(
  Form.create(),
  withGraphqlSendRequisition,
  withGraphqlRequisitionTemplate
)(RequisitionModal)
