import { ApolloProvider } from 'react-apollo'

import initApollo from '../libs/initApollo'

const WithApollo = (WrappedComponent) => {
  const WithApolloComponent = (props) => {
    const apollo = props.apollo
      ? props.apollo
      : initApollo(
        {},
        {
          headers: props.headers,
          router: props.router
        }
      )

    return (
      <ApolloProvider client={apollo}>
        <WrappedComponent {...props} />
      </ApolloProvider>
    )
  }
  return WithApolloComponent
}
export default WithApollo
