import React, { Component } from 'react'
import { DatePicker } from 'antd'
import { compose, withState, withHandlers } from 'recompose'

const modeState = withState('mode', 'setMode', 'date')

const handlers = withHandlers({
  handleDatepickerChange: (props) => (date, dateString) => {
    const { mode, setMode, onChange = () => {} } = props
    if (mode === 'date') {
      setMode('time')
    }

    onChange(date, dateString)
  },
  handleOpenChange: (props) => (open) => {
    const { setMode } = props
    setMode('date')
  },
  handlePanelChange: (props) => (value, mode) => {
    const { setMode } = props
    setMode(mode)
  }
})

const enhance = compose(modeState, handlers)

const styles = {
  width: '100%',
  maxWidth: '350px'
}

class DateTimePicker extends Component {
  render() {
    const { value, mode, handleDatepickerChange, handleOpenChange, handlePanelChange } = this.props

    return (
      <DatePicker
        showTime
        value={value}
        style={styles}
        format="DD/MM/YYYY HH:mm"
        placeholder="Select a date and time"
        getCalendarContainer={(trigger) => trigger.parentNode}
        mode={mode}
        onChange={handleDatepickerChange}
        onOpenChange={handleOpenChange}
        onPanelChange={handlePanelChange}
      />
    )
  }
}

export default enhance(DateTimePicker)
