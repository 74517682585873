
import { compose, withHandlers } from 'recompose'
import { Card } from 'antd'

const handlers = withHandlers({
  handleClick: (props) => () => {
    const { documentCreatorTemplate, onClick = () => { } } = props
    onClick(documentCreatorTemplate)
  }
})

const enhance = compose(handlers)

const DocumentCreatorTemplateCard = (props) => {
  const { documentCreatorTemplate, handleClick } = props

  return <Card onClick={handleClick}>{documentCreatorTemplate.type}</Card>
}

export default enhance(DocumentCreatorTemplateCard)
