import styled from 'styled-components'
import { Modal } from 'antd'

export const StyledModal = styled(Modal)`
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  width: auto !important;
  padding-bottom: 0;

  .ant-modal-body {
    position: absolute;
    bottom: 59px;
    top: 47px;
    right: 0;
    left: 0;
    padding: 0px;
  }
  .ant-modal-content {
    height: 100%;
  }
  .ant-modal-close-x {
    height: 47px;
    line-height: 47px;
  }
  .ant-modal-footer {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background: white;
  }
`
export const DocumentTitle = styled.div`
  font-size: 13.5px;
  font-weight: 500;
  letter-spacing: 0.1px;

  .type {
    font-size: 14.5px;
    font-weight: 500;
    letter-spacing: 0.3px;
    color: black;
  }
  a {
    color: #2d91ef;
    text-decoration: underline;

    .anticon {
      font-size: 1rem;
      margin-left: 6px;
      vertical-align: middle;
    }
  }
`

export const ImagePreview = styled.div`
  height: 100%;
  text-align: center;
  overflow: ${(props) => (props.type === 'application/pdf' ? 'hidden' : 'auto')};
`

export const Approval = styled.div`
  text-align: left;

  .label {
    font-size: 13px;
    font-weight: 500;
    color: black;
    display: inline-block;
    padding-right: 5px;
  }

  .value {
    font-size: 13px;
    font-weight: 600;
    color: rgb(130, 130, 130);
    display: inline-block;
  }

  .actions {
    text-align: right;
  }
`
