import styles from './OverviewSection.module.css'

import includes from 'lodash/includes'
import { Button } from 'antd'

import Title from './Title'
import General from './General'
import BackButton from './BackButton'
import Link from 'App/components/Link'
import AssigneeSelector from './Assignee'
import BookingStatusIcon from 'App/components/Booking/ListView/BookingStatusIcon'

import { getButtons } from './BookingActionButton'
import useProcessPortalUser from 'App/hooks/useProcessPortalUser'

const OverviewSection = ({ booking }) => {

  const { isPortalUser } = useProcessPortalUser()

  return (
    <div className={styles.wrapper}>
      {/* header */}
      <div className={styles.header}>
        <div className={styles.left}>
          <BackButton />
          <div className={styles.bookingText}>
            <BookingStatusIcon booking={booking} />
            <Title booking={booking} />
          </div>
        </div>
        <AssigneeSelector booking={booking} />
      </div >

      <div className={styles.content}>
        <div className={styles.buttonsContainer}>
          {!isPortalUser && getButtons(booking)}

          {!isPortalUser && !includes(['CANCELLED', 'DRAFT', 'SUBMITTED'], booking.status) && (
            <Link to={`/bookings/${booking.uuid}/edit`}>
              <Button id="edit-booking-button" type="dashed" icon="edit">
                Edit
              </Button>
            </Link>
          )}

          <Link to={`/bookings/${booking.uuid}/duplicate`}>
            <Button type="dashed" icon="copy">
              Duplicate
            </Button>
          </Link>

          {!isPortalUser && (
            <Link to={`/bookings/${booking.uuid}/cost-sheet`}>
              <Button type="dashed">Cost sheet</Button>
            </Link>
          )}

          {!isPortalUser && booking.status === 'CANCELLED' && <span>Status: CANCELLED</span>}
        </div>

        <General booking={booking} />
      </div>
    </div>
  )
}

export default OverviewSection
