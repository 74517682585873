import React, { Component } from 'react'
import { Select } from 'antd'
import { Query } from 'react-apollo'
import find from 'lodash/find'
import { compose, withStateHandlers } from 'recompose'
import debounce from 'lodash/debounce'

import CHARGE_ITEMS_QUERY from './schema'

const Option = Select.Option

const handlers = withStateHandlers(
  {
    keyword: ''
  },
  {
    handleChange: (state, props) => (value, option) => {
      props.onChange(value, option.props.item)
    },
    handleSearch: (state, props) => (value) => ({ keyword: value })
  }
)

const enhance = compose(handlers)

class ChargeItemSelect extends Component {
  componentDidMount() {
    const { handleSearch, name } = this.props

    if (name) {
      handleSearch(name)
    }
  }

  render() {
    const { keyword, value, disabled = false, name, isUseCode = false } = this.props

    return (
      <Query
        query={CHARGE_ITEMS_QUERY}
        variables={{ query: keyword, statuses: this.props.statuses }}
      >
        {({ loading, error, data }) => {
          const chargeItems = (data && data.chargeItems && data.chargeItems.rows) || []

          let bindingValue = value

          if (!find(chargeItems, (c) => c.uuid === value || c.code === value)) {
            bindingValue = name
          }

          return (
            <Select
              id='cost-charge-item-selector'
              value={bindingValue}
              showSearch
              onChange={this.props.handleChange}
              disabled={disabled}
              placeholder="Select a charge item..."
              notFoundContent={loading ? 'Searching...' : null}
              filterOption={false}
              getPopupContainer={(triggerNode) => triggerNode.parentNode}
              onSearch={debounce(this.props.handleSearch, 500)}
            >
              {chargeItems.map((chargeItem, index) => (
                <Option key={isUseCode ? chargeItem.code : chargeItem.uuid} value={isUseCode ? chargeItem.code : chargeItem.uuid} item={chargeItem}>
                  {chargeItem.code ? `${chargeItem.code} - ` : ''}
                  {chargeItem.name}
                </Option>
              ))}
            </Select>
          )
        }}
      </Query>
    )
  }
}

export default enhance(ChargeItemSelect)
