import { withApollo } from 'react-apollo'
import { Alert, Button, Icon } from 'antd'
import { useMutation } from '@apollo/client'
import { withRouter } from 'react-router-dom'

import auth0 from 'App/hocs/auth0'
import { logger } from 'App/utils/logger'
import { handleResponse } from 'App/utils/booking'

import { StatusSummary } from './Styled'
import { VERIFY_BOOKING_DOCUMENT } from '../schema'

type DocumentVerifyViewProps = {
  client: any
  bookingDocument: any
  toggleDocumentViewer: any
  refreshBooking: any
}

const DocumentVerifyView = (props: DocumentVerifyViewProps) => {
  const { bookingDocument, refreshBooking, toggleDocumentViewer, client } = props

  const [verifyBookingDocumentMutation, { error, loading }] = useMutation(VERIFY_BOOKING_DOCUMENT, {
    client,
    variables: { uuid: bookingDocument.uuid }
  })

  const handleVerified = async () => {
    try {
      await verifyBookingDocumentMutation()

      setTimeout(async () => {
        if (refreshBooking) {
          await refreshBooking()
        }
        toggleDocumentViewer()
      }, 2000)
    } catch (error) {
      if (error) {
        logger.error('Verify document err:', error)
        handleResponse(error)
      }
    }
  }

  if (bookingDocument.status === 'VERIFIED') {
    return (
      <StatusSummary>
        <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a" />{' '}
        Document has been verified.
      </StatusSummary>
    )
  } else if (bookingDocument.status === 'DELETED') {
    return (
      <StatusSummary>
        <Icon type="close-circle" theme="twoTone" twoToneColor="#f5222d" />{' '}
        Document has been deleted.
      </StatusSummary>
    )
  }

  return (
    <>
      {error && <Alert message={error.toString()} type="error" />}
      <Button type="primary" loading={loading} onClick={() => handleVerified()}>
        {loading ? 'Hang on...' : 'Verify this document'}
      </Button>
    </>
  )
}

export default withRouter(auth0(withApollo(DocumentVerifyView)))
