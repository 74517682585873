import { gql } from '@apollo/client'

const currencyDetails = gql`
  fragment CurrencyDetails on Currency {
    uuid
    code
    name
  }
`

export const fragments = {
  currencyDetails
}
