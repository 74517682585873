import styled from 'styled-components'
import { Form } from 'antd'

export const SecondaryText = styled.div`
  font-size: 12.5px;
  color: rgb(120, 120, 120);
  margin-top: 0px;
  font-weight: normal;
  line-height: 1.2em;
`
export const BoldSecondaryText = styled.div`
  margin-top: 0px;
  font-weight: bold;
  font-size: 12.5px;
  line-height: 1.2em;
  color: rgb(120, 120, 120);
`

export const Actions = styled.div`
  padding: 10px 0;
  text-align: right;
`

export const Separator = styled.div`
  height: 5px;
`
export const QuickAddDiv = styled.div`
  line-height: 10px;
  font-size: 0.85em;
  text-align: right;

  span {
    color: gray;
    cursor: pointer;

    :hover {
      color: #40a9ff;
    }

    .anticon {
      margin-right: 4px;
    }
  }
`
export const Inputs = styled(Form)`
  padding: 20px 0 0;

  .ant-btn,
  .ant-select,
  .ant-select-dropdown-menu-item,
  .ant-select-search--inline .ant-select-search__field {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.1px;
  }

  .ant-btn {
    height: 30px;
  }

  .ant-select-selection-selected-value,
  .ant-select-search--inline .ant-select-search__field {
    color: black;
  }
`
