import { gql } from '@apollo/client'

const CHANGE_VOUCHER_STATUS_SLIM_MUT = gql`
  mutation changeVoucherStatusSlim1($uuid: UUID!, $status: VoucherStatus!) {
    changeVoucherStatus(uuid: $uuid, status: $status) {
      uuid
      status
    }
  }
`

export default CHANGE_VOUCHER_STATUS_SLIM_MUT
