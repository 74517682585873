import { Steps } from 'antd'

const FormSteps = (props) => {
  const { currentStep } = props

  return (
    <Steps current={currentStep} size="small">
      <Steps.Step title="Shipment Information" />
      <Steps.Step title="Details" />
      <Steps.Step title="Confirmation" />
    </Steps>
  )
}

export default FormSteps
