import { gql } from '@apollo/client'

import { fragments as taxFragment } from 'App/containers/tax/fragment'
import { fragments as costItemFragment } from 'App/containers/costItem/fragment'
import { fragments as currencyFragment } from 'App/containers/currency/fragment'
import jobDetails from 'App/containers/booking/fragment/job'

export default gql`
  fragment VoucherItemDetails on VoucherItem {
    uuid
    description
    unit
    size
    quantity
    isDeleted
    currency {
      ...CurrencyDetails
    }
    baseRate
    exchangeRate
    localExchangeRate
    rate
    tax {
      ...TaxDetails2
    }
    job {
      ...JobDetails
    }
    taxPercentage
    taxTotal
    subTotal
    total
    bookingUuid
    costItem {
      ...CostItemDetails
    }
    voucherCn {
      uuid
      voucherNumber
      invoiceNumber
    }
    sorting
  }

  ${taxFragment.taxDetails}
  ${jobDetails}
  ${currencyFragment.currencyDetails}
  ${costItemFragment.costItemDetails}
`
