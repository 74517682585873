import type { LoggedInUserType } from 'App/hocs/types/auth0'

interface UserState {
  loggedInUser: LoggedInUserType | null
}
interface UserActions {
  type: string, user: LoggedInUserType
}

export const actions = {
  SET_LOGGED_IN_USER: 'SET_LOGGED_IN_USER'
}

export const setLoggedInUser = (user: any) => {
  return {
    type: actions.SET_LOGGED_IN_USER,
    user
  }
}

const initialState = {
  loggedInUser: null
}

export default (state: UserState = initialState, action: UserActions) => {
  switch (action.type) {
    case actions.SET_LOGGED_IN_USER:
      return { ...state, loggedInUser: action.user }

    default:
      return state
  }
}

