export const actions = {
  TOGGLE_VIEW_MODAL: 'TOGGLE_VIEW_MODAL',
  UPDATE_SELECTED_COMPANY: 'UPDATE_SELECTED_COMPANY'
}

export const toggleViewModal = () => ({
  type: actions.TOGGLE_VIEW_MODAL
})

export const updateSelectedCompany = (company) => ({
  type: actions.UPDATE_SELECTED_COMPANY,
  company
})

const initialState = {
  showViewModal: false,
  selectedCompany: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE_VIEW_MODAL:
      return { ...state, showViewModal: !state.showViewModal }
    case actions.UPDATE_SELECTED_COMPANY:
      return { ...state, selectedCompany: action.company }
    default:
      return state
  }
}
