import { gql } from '@apollo/client'

export const TRANSPORT_ZONES_QUERY = gql`
  query transportZonesMain($limit: Int, $offset: Int, $q: String, $statuses: [TransportZoneStatus]) {
    transportZones(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        code
        uuid
        name
        status
        sorting
        description
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

export const CREATE_TRANSPORT_ZONES_GQL = gql`
  mutation createTransportZone($input: CreateTransportZoneInput!) {
    createTransportZone(input: $input) {
      uuid
    }
  }
`

export const VIEW_TRANSPORT_ZONE_GQL = gql`
  query viewTransportZone($uuid: UUID!) {
    transportZone(uuid: $uuid) {
      code
      uuid
      name
      description
      sorting
      status
    }
  }
`

export const UPDATE_TRANSPORT_ZONE_GQL = gql`
  mutation updateTransportZone($input: CreateTransportZoneInput!) {
    updateTransportZone(input: $input) {
      uuid
      name
      description
      sorting
    }
  }
`

export const DELETE_TRANSPORT_ZONE_GQL = gql`
  mutation deleteTransportZone($uuid: UUID!) {
    deleteTransportZone(uuid: $uuid) {
      success
    }
  }
`

export const UNDELETE_TRANSPORT_ZONE_GQL = gql`
  mutation undeleteTransportZone($uuid: UUID!) {
    undeleteTransportZone(uuid: $uuid) {
      success
    }
  }
`
