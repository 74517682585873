import { gql } from '@apollo/client'

export const INCENTIVES_QUERY = gql`
  query Incentives($input: IncentiveQueryInput!) {
    incentives(input: $input) {
      rows {
        legUuid
        amount
        incentiveVoucher {
          voucherNo
        }
        status
        shift {
          start
          end
        }
      }
    }
  }
`
export const ADDRESS_QUERY = gql`
  query Address($uuid: UUID) {
    address(uuid: $uuid) {
      uuid
      name
      status
      type
      address1
      address2
      address3
      address4
      city
      district
      postCode
      placeId
      plusCode
      areaCode
      zone
      phone
      fax
      tags
      location {
        coordinates
      }
      countryAlpha3
      companyUuid
    }
  }
`
