import React, { Component } from 'react'
import { Col, Form } from 'antd'

import {
  Divider,
  StyledRow,
  ColWrapper,
  StyledForm,
  CombineWrapper
} from 'App/components/Manage/FormItemMapper/Styled'

const FormItem = Form.Item

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

class InputMapper extends Component {
  renderDivider = (label) => {
    if (label) {
      return <Divider>{label}</Divider>
    } else {
      return null
    }
  }

  render() {
    const { fields, getFieldDecorator, style, headerTitle } = this.props
    return (
      <StyledForm.Row gutter={24}>
        <div style={{ paddingLeft: '15px', margin: '20px 0'}}><b>{headerTitle}</b></div>
        {fields?.map((field, fieldIndex) => {
          if (field.combine) {
            return (
              <Col span={24} key={[fieldIndex]}>
                <CombineWrapper full={field.full}>
                  <FormItem label={field.label} {...formItemLayout}>
                    <StyledRow gutter={6}>
                      {field.cols.map((col, colIndex) => (
                        <Col key={[colIndex]} span={col.span}>
                          <FormItem>
                            {getFieldDecorator(col.value, col.attributes)(col.input)}
                          </FormItem>
                        </Col>
                      ))}
                    </StyledRow>
                  </FormItem>
                </CombineWrapper>
              </Col>
            )
          }

          return (
            <Col span={field.span || 24} key={[fieldIndex]}>
              {this.renderDivider(field.label)}
              <ColWrapper full={field.full} width={field.width} style={style}>
                {field.cols.map((col, colIndex) => {
                  if (col.isCustom) {
                    return (
                      <React.Fragment key={[colIndex]}>
                        {col.removeDecorator
                          ? col.input
                          : getFieldDecorator(col.value, col.attributes)(col.input)}
                      </React.Fragment>
                    )
                  } else {
                    return (
                      <FormItem key={[colIndex]} label={col.label} {...formItemLayout}>
                        {col.removeDecorator
                          ? col.input
                          : getFieldDecorator(col.value, col.attributes)(col.input)}
                      </FormItem>
                    )
                  }
                })}
              </ColWrapper>
            </Col>
          )
        })}
      </StyledForm.Row>
    )
  }
}

export default InputMapper
