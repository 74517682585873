import { Fragment } from 'react'
import { Row, Col, Form } from 'antd'

import TripsContent from './TripsContent'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  },
  style: {
    marginBottom: 0
  }
}

const JobModal = (props) => {
  const { job, onChange } = props
  return (
    <Fragment>
      <Form>
        <Row>
          <Col span={12}>
            <Form.Item label="No." {...formItemLayout}>
              {job.no}
            </Form.Item>
            <Form.Item label="Seal No." {...formItemLayout}>
              {job.details.seal}
            </Form.Item>
            <Form.Item label="Container Type" {...formItemLayout}>
              {job.details.containerType}
            </Form.Item>
            <Form.Item label="Size" {...formItemLayout}>
              {job.details.size}
            </Form.Item>
            <Form.Item label="Weight" {...formItemLayout}>
              {job.details.weight}
            </Form.Item>
            <Form.Item label="UOM" {...formItemLayout}>
              {job.details.uom}
            </Form.Item>
            <Form.Item label="Transport Type" {...formItemLayout}>
              {job.details.transportType}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Status" {...formItemLayout}>
              {job.status}
            </Form.Item>
            <Form.Item label="Options" {...formItemLayout}>
              {job.details.options && job.details.options.join(', ')}
            </Form.Item>
            <Form.Item label="Reference - Haulage" {...formItemLayout}>
              {job.reference && job.reference.haulage}
            </Form.Item>
            <Form.Item label="Remarks" {...formItemLayout}>
              {job.remarks}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <TripsContent trips={job.trips} onChange={onChange} />
    </Fragment>
  )
}

export default JobModal
