
import { withState, withHandlers, compose } from 'recompose'
import JobModal from '../JobModal'
import { StyledButton } from '../../ButtonWrapper'

const state = withState('isModalVisible', 'setIsModalVisible', false)

const handlers = withHandlers({
  handleClick: (props) => (e) => {
    props.setIsModalVisible(true)
  },
  handleClose: (props) => (e) => {
    props.setIsModalVisible(false)
  }
})

const enhance = compose(state, handlers)

const JobModalAction = (props) => {
  const { isModalVisible, handleClick, handleClose, job, onChange } = props

  return (
    <>
      <StyledButton style={{ marginLeft: '10px' }} onClick={handleClick}>
        View
      </StyledButton>
      <JobModal visible={isModalVisible} job={job} onClose={handleClose} onChange={onChange} />
    </>
  )
}

export default enhance(JobModalAction)
