import styled from 'styled-components'

export const InputWrapper = styled.div`
  .ant-input {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.1px;
    height: 34px;
    width: 100%;
    background: rgb(242, 243, 244);
    border: 1px solid rgb(242, 243, 244);
    border-radius: 1px;

    :not(:first-child) {
      padding-left: 35px;
    }

    .ant-input-affix-wrapper .ant-input-prefix {
      color: rgb(140, 140, 140);
    }

    &:hover {
      border: 1px solid rgb(220, 220, 220) !important;
      outline: none !important;
    }

    &:focus {
      background: white;
      box-shadow: 0 0 4px 0px rgba(0, 0, 0, 0.15);
      outline: none;
      border: 1px solid rgb(220, 220, 220);
    }
  }
`
