import { useEffect, useState, forwardRef, memo, useCallback } from 'react'
import { Avatar, Select, Typography } from 'antd'
import debounce from 'lodash/debounce'

import { withApollo } from 'react-apollo'
import { useQuery } from '@apollo/client'

import { NoPermissionWrapper } from 'App/components/TableView/Styled'
import USERS_QUERY from 'App/containers/user/schema/usersQuery'
import { OptionWrapper } from '../Assignee/Styled'
import { hasPermissionError } from 'App/utils/u'
import { logger } from 'App/utils/logger'
import config from 'App/config'

const Option = Select.Option

interface UserProps {
  uuid: string
  picture?: any
  email?: string
}

const UsersSelect = forwardRef((props: any, ref: any) => {
  const { client, focusOutField, handleSelectDeselect } = props

  const [users, setUsers] = useState([])
  const [searchInput, setSearchInput] = useState('')

  const { data, error, loading, refetch } = useQuery(USERS_QUERY, {
    client,
    variables: {
      q: searchInput || '',
      limit: 20
    },
    fetchPolicy: 'cache-first'
  })

  useEffect(() => {
    if (data?.users?.rows?.length && setUsers) {
      setUsers(data.users.rows)
    }
  }, [data])

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchInput(value)
      refetch()
    }, 500),
    []
  )

  const handleChange = useCallback(
    (item: any) => {
      const getKeys = item.map((item: any) => item.key)
      const selected = users.filter((user: any) => getKeys.includes(user.uuid))
      handleSelectDeselect(selected)
    },
    [users, handleSelectDeselect]
  )

  if (error) {
    logger.error('UsersSelect USERS_QUERY error', error)
    if (hasPermissionError(error)) {
      return <NoPermissionWrapper>{config.youDoNotHavePermissionToViewThis}</NoPermissionWrapper>
    } else {
      return <Typography.Text type="danger">Error while loading users</Typography.Text>
    }
  }

  return (
    <Select
      autoFocus
      ref={ref}
      labelInValue
      mode="multiple"
      loading={loading}
      filterOption={false}
      onBlur={focusOutField}
      onSearch={handleSearch}
      onChange={handleChange}
      style={{ paddingRight: 10 }}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
    >
      {users?.map((user: UserProps) => {
        return (
          <Option key={user.uuid}>
            <OptionWrapper>
              <Avatar src={user.picture} />
              <Typography.Text>{user.email}</Typography.Text>
            </OptionWrapper>
          </Option>
        )
      })}
    </Select>
  )
})

export default withApollo(memo(UsersSelect))
