import styled from 'styled-components'

export default styled.div`
  width: 192px;
  display: inline-block;
  cursor: pointer;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: 1px solid rgb(230, 230, 230);

  &:hover {
    transition: 0.3s;
    border-color: rgb(200, 200, 200);
  }
`

export const RequestType = styled.div`
  display: table-cell;
  font-size: 12.5px;
  font-weight: 500;
  color: black;
  letter-spacing: 0.2px;
  max-width: 160px;
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const RequestDesc = styled.div`
  margin-top: 5px;
`

export const Status = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  color: rgb(140, 143, 146);
`

export const CreatedDate = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: rgb(140, 143, 146);
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
