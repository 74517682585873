import voucherDetails from './voucher'
import voucherItemDetails from './voucherItem'
import approvalDetails from './approval'
import paymentDetails from './payment'
import voucherTemplateDetails from './voucherTemplate'

export const fragments = {
  voucherDetails,
  paymentDetails,
  approvalDetails,
  voucherItemDetails,
  voucherTemplateDetails
}
