import { gql } from '@apollo/client'

export const documentCreatorGql = gql`
  query documentCreator($uuid: UUID!) {
    documentCreator(uuid: $uuid) {
      uuid
      bookingUuid
      status
      type
      dataJSON
      dataJSONObject
      template
      createdAt
      updatedAt
    }
  }
`
