import React, { Component } from 'react'

import { Popover, Button } from 'antd'
import EditableFields from './EditableFields'
import withCreatePayment from './Container'

class CreateVoucherPayment extends Component {
  render() {
    const {
      handleView,
      showPayment,
      handleCancel,
      createPayment,
      selectedPayment,
      togglePaymentView
    } = this.props

    return (
      <Popover
        title="Create Payment"
        trigger="click"
        visible={showPayment}
        placement="right"
        content={
          <EditableFields
            onCancel={handleCancel}
            createPayment={createPayment}
            selectedPayment={selectedPayment}
            togglePaymentView={togglePaymentView}
          />
        }
        overlayStyle={{
          width: 400
        }}
        onVisibleChange={(visible) => togglePaymentView(visible)}
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        <Button shape="circle" icon="plus" onClick={handleView} />
      </Popover>
    )
  }
}

export default withCreatePayment(CreateVoucherPayment)
