import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { compose, graphql } from 'react-apollo'
import { Popconfirm, Button } from 'antd'

import { UNDELETE_JOB } from 'App/graphql/booking'
import { JOBS_FROM_BOOKING_SLIM_QUERY } from 'App/containers/booking/schema/basicBookingQuerySlim'
import respHandler from 'App/utils/responseHandler'

class JobUndeleteAction extends PureComponent {
  handleConfirm = () => async (e) => {
    const {
      undeleteJob,
      job: { uuid }
    } = this.props
    respHandler('undeleting job, hang on...', 'load')
    try {
      await undeleteJob(uuid)
      respHandler('Job undeleted successfully.', 'success')
    } catch (error) {
      respHandler(error, 'error')
    }
  }

  render() {
    return (
      <Popconfirm
        title="Confirm to undelete this job?"
        onConfirm={this.handleConfirm()}
        okText="Yes"
        okType="danger"
        cancelText="No"
      >
        <Button type="danger">Undelete</Button>
      </Popconfirm>
    )
  }
}

JobUndeleteAction.propTypes = {
  job: PropTypes.object.isRequired,
  undeleteJob: PropTypes.func
}

export default compose(
  graphql(UNDELETE_JOB, {
    props: ({ mutate, ownProps }) => ({
      undeleteJob: (uuid) =>
        mutate({
          variables: { uuid },
          update: (cache, mutationResult) => {
            const { booking } = cache.readQuery({
              query: JOBS_FROM_BOOKING_SLIM_QUERY,
              variables: { uuid: ownProps.job.bookingUuid }
            })

            // const jobIndex = booking.jobs.findIndex(job => job.uuid === uuid)
            // booking.jobs.splice(jobIndex, 1)

            cache.writeQuery({
              query: JOBS_FROM_BOOKING_SLIM_QUERY,
              variables: { uuid: ownProps.job.bookingUuid },
              data: { booking }
            })
          },
          refetchQueries: [
            {
              query: JOBS_FROM_BOOKING_SLIM_QUERY,
              variables: {
                uuid: ownProps.job.bookingUuid
              }
            }
          ]
        })
    })
  })
)(JobUndeleteAction)
