import styled from 'styled-components'

export default styled.div`
  margin-right: 25px;
  margin-bottom: 30px;
`

export const Title = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: black;
  margin-bottom: 15px;
`

export const ItemsWrapper = styled.div`
  vertical-align: top;
`

export const NoDocument = styled.div`
  margin: 20px 0 30px;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
`
