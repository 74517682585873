import { gql } from '@apollo/client'

import { fragments as chargeCategoryFragment } from 'App/containers/chargeCategory/fragment'
import { fragments as currencyFragment } from 'App/containers/currency/fragment'
import glCodeFragment from './glCode'
import { fragments as taxFragment } from 'App/containers/tax/fragment'

export default gql`
  fragment ChargeItemDetails on ChargeItem {
    uuid
    code
    name
    description
    sequence
    unit
    sellRate
    costRate
    status
    rateType
    term
    taxes {
      uuid
      type
      rate
      rateType
      tax {
        ...TaxDetails2
      }
    }
    sellTax {
      ...TaxDetails2
    }
    costTax {
      ...TaxDetails2
    }
    sellCurrency {
      ...CurrencyDetails
    }
    costCurrency {
      ...CurrencyDetails
    }
    categories {
      ...ChargeCategoryDetails
    }
    revenueCode {
      ...GlCodeDetails
    }
    expenseCode {
      ...GlCodeDetails
    }
  }

  ${taxFragment.taxDetails}
  ${glCodeFragment}
  ${currencyFragment.currencyDetails}
  ${chargeCategoryFragment.chargeCategoryDetails}
`
