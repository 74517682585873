import { Component } from 'react'
import { compose } from 'recompose'

import Mobile from 'App/pages/mobile'
import bootstrap from 'App/hocs/bootstrap'
import WithHeader from 'App/components/Layouts/WithHeader'
import SearchResult from 'App/components/Booking/SearchResult'
import { Desktop, MobileTablet } from 'App/components/Responsive'

class HomePage extends Component {
  renderDesktop() {
    return (
      <Desktop>
        <WithHeader {...this.props}>
          <SearchResult {...this.props} />
        </WithHeader>
      </Desktop>
    )
  }

  renderMobileTablet() {
    return (
      <MobileTablet>
        <Mobile {...this.props} />
      </MobileTablet>
    )
  }

  render() {
    const { isClientMounted } = this.props

    if (!isClientMounted) {
      return this.renderDesktop()
    }

    return (
      <>
        {this.renderDesktop()}
        {this.renderMobileTablet()}
      </>
    )
  }
}

export default compose(bootstrap)(HomePage)
