
import { Input, Button, Form } from 'antd'
import { compose } from 'react-apollo'
import { withHandlers } from 'recompose'

const FormItem = Form.Item

const handlers = {
  handleSubmit: (props) => async (event) => {
    event.preventDefault()
    const { field } = props
    props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          await props.onValueUpdate(values[field])
        } catch (error) {
          console.log(error)
        }
      }
    })
  }
}

const enhance = compose(Form.create(), withHandlers(handlers))

const TextInputUpdate = (props) => {
  const { field, value } = props
  const { getFieldDecorator } = props.form

  return (
    <Form layout="inline" onSubmit={props.handleSubmit}>
      <FormItem>
        {getFieldDecorator(field, {
          initialValue: value
        })(<Input />)}
      </FormItem>
      <FormItem style={{ marginRight: 0 }}>
        <Button type="primary" htmlType="submit">
          Update
        </Button>
      </FormItem>
    </Form>
  )
}

export default enhance(TextInputUpdate)
