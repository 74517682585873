import { useState } from 'react'
import { Button, Popconfirm } from 'antd'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'

import respHandler from 'App/utils/responseHandler'
import changeDocumentCreatorStatusAdvancedGql from './changeDocumentCreatorStatusAdvancedGql'

const changeDocumentCreatorStatusAdvancedGraphql = graphql(changeDocumentCreatorStatusAdvancedGql, {
  props: ({ mutate, ownProps }) => ({
    changeDocumentCreatorStatusAdvanced: (uuid, status) =>
      mutate({
        variables: {
          uuid,
          status
        }
      })
  })
})

const enhance = compose(changeDocumentCreatorStatusAdvancedGraphql)

const CancelButton = (props) => {
  const { documentCreator, changeDocumentCreatorStatusAdvanced, onChange = () => {} } = props

  const [loading, setLoading] = useState(false)
  const handleConfirm = async () => {
    setLoading(true)

    try {
      const result = await changeDocumentCreatorStatusAdvanced(documentCreator.uuid, 'CANCELLED')

      if (onChange) {
        onChange(result.data && result.data.changeDocumentCreatorStatusAdvanced)
      }

      respHandler('Document creator is cancelled successfully.', 'success')
    } catch (error) {
      console.log(error)
      respHandler(error, 'error')
    }
    setLoading(false)
  }

  return (
    <Popconfirm
      title={'Confirm to cancel this document creator?'}
      onConfirm={handleConfirm}
      okText="Yes"
      okType="danger"
      cancelText="No"
    >
      <Button type="danger" loading={loading}>
        Cancel
      </Button>
    </Popconfirm>
  )
}

export default enhance(CancelButton)
