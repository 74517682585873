import styled from 'styled-components'
import { Icon } from 'antd'

const sideTop = 58

export const LogoWrapper = styled.div`
  img {
    height: 30px;
    cursor: pointer;
  }
`

export const Wrapper = styled.div`
  position: fixed;
  top: 55px;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(249, 250, 251);
`

export const MenuIcon = styled(Icon)`
  font-size: 0.9rem;
  font-weight: 500;
  margin-right: 10px;
  padding: 8px;
  background: ${(props) => (props.needbg ? 'rgb(235, 237, 239)' : 'transparent')};
  border-radius: 24px;
`

export const ManageLayoutStyles = {
  sidebar: {
    position: 'fixed',
    left: 0,
    top: sideTop,
    bottom: 0,
    zIndex: 10,
    width: 200,
    overflowX: 'hidden',
    overflowY: 'auto',
    background: 'white',
    borderRight: '1px solid rgb(240, 240, 240)',
    boxShadow: 'unset'
  }
}

export const LayoutStyles = {
  layout: {
    background: 'transparent'
  },
  loginLayout: {
    background: 'transparent',
    height: '100vh'
  },
  header: {
    position: 'fixed',
    width: '100%',
    background: 'white',
    marginBottom: 0,
    boxShadow: '0 0 1px 1px rgba(0, 0, 0, 0.15)',
    zIndex: 99
  },
  content: {
    marginTop: 55,
    background: 'transparent',
    marginLeft: 200
  },
  loginContent: {
    background: 'transparent'
  },
  contentNoSide: {
    marginTop: 55,
    background: 'transparent'
  },
  sidebar: {
    position: 'fixed',
    left: 0,
    top: sideTop,
    bottom: 0,
    zIndex: 10,
    overflowX: 'hidden',
    overflowY: 'auto'
  }
}

export const Fixer = styled.div`
  position: relative;
  ${(props) => props.width && `width: ${props.width};`}
  height: 100%;
  margin: auto;
  padding: ${(props) => (props.needSidePad ? '0 10px' : '0px')};

  @media print {
    display: none;
  }
`

export const Header = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
  height: 56px;
  line-height: 56px;
  background: white;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);

  @media print {
    display: none;
  }
`

export const Content = styled.div`
  margin-top: 56px;
  background: transparent;
  overflow: hidden;

  @media print {
    margin-top: 0;
  }
`
