import { gql } from '@apollo/client'

export default gql`
  query getVoucherBookingCostSheet($uuid: UUID!) {
    booking(uuid: $uuid) {
      uuid
      quotation {
        uuid
        quotationNo
        description
      }
      bookingDocuments {
        uuid #
        type #
        reference
        remarks
        tags
        status #
        url
        document {
          uuid #
          name #
          type #
        }
        approvals {
          uuid
          type
          status
          createdAt
          approvedBy {
            email
          }
        }
        createdBy {
          uuid
          email
        }
        verifiedBy {
          uuid
          email
        }
        createdAt
        updatedAt
      }
      vouchers {
        uuid
        type
        paymentType
        status
        createdBy {
          name
          givenName
          nickname
          email
        }
        contactPerson
        description
        internalDescription
        invoiceNumber
        voucherNumber
        subTotal
        taxTotal
        total
        term
        printCount
        accountDate
        isCreditNote
        balance
        transactionType
        currency {
          uuid
          code
          name
        }
        vendor {
          uuid
          name
          types
          code
          tags
          debtorCode
          creditorCode
          status
          countryAlpha3
          description
        }
        customer {
          uuid
          name
          types
          code
          tags
          debtorCode
          creditorCode
          status
          countryAlpha3
          description
        }
        voucherPayments {
          uuid
          amount
          voucherUuid
          payment {
            uuid
            status
            remarks
            transactionType
            reference
            date
            amount
          }
        }
        documentCreatorTemplate {
          uuid
          name
        }
      }
      costItems {
        uuid
        bookingUuid
        description
        quantity
        size
        unit
        quantity
        sellRate
        costRate
        chargeItem {
          uuid
          code
          name
          description
          sequence
          unit
          sellRate
          costRate
          status
          rateType
          term
          taxes {
            uuid
            type
            rate
            rateType
            tax {
              uuid
              name
              code
              percentage
              exemptionDocumentUuid
            }
          }
          sellTax {
            uuid
            name
            code
            percentage
            exemptionDocumentUuid
          }
          costTax {
            uuid
            name
            code
            percentage
            exemptionDocumentUuid
          }
          sellCurrency {
            uuid
            code
            name
          }
          costCurrency {
            uuid
            code
            name
          }
          categories {
            uuid
            code
            description
            sequence
          }
          revenueCode {
            uuid
            code
          }
          expenseCode {
            uuid
            code
          }
        }
        sellBaseRate
        sellRate
        sellTotal #
        sellRate
        sellBaseRate
        sellExchangeRate
        sellCurrency {
          uuid
          code
          name
        }
        costBaseRate
        costRate
        costTotal #
        costBaseRate
        costRate
        costExchangeRate
        costCurrency {
          uuid
          code
          name
        }
        accountPayable #
        accountPayableDraft
        accountReceivable #
        accountReceivableDraft
        cashBook
        blankCheque
        accrual #
        estimatedProfit #
        shortBill #
        grossProfit #
        isDeleted #
        errors {
          msg
          field
          urgency
          expectedValue
          receivedValue
        }
      }
      costSheet {
        estimatedProfit
        grossProfit
        costTotal
        sellTotal
        accrual
        shortBill
        shortBillPercentage
        accountPayable
        accountReceivable
        accountPayableDraft
        accountReceivableDraft
      }
    }
  }
`
