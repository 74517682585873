import { gql } from '@apollo/client'

export default gql`
  query exchangeRates2($limit: Int, $offset: Int, $fromUuid: UUID, $toUuid: UUID, $date: DateTime) {
    exchangeRates(
      limit: $limit
      offset: $offset
      fromUuid: $fromUuid
      toUuid: $toUuid
      date: $date
    ) {
      rows {
        uuid
        from {
          uuid
          code
          name
        }
        to {
          uuid
          code
          name
        }
        start
        end
        rate
      }
    }
  }
`
