import {
  DEPARTMENT_QUERY,
  GET_COMPANIES_QUERY,
  QUOTATION_QUERY
} from 'App/components/Booking/DynamicField/schema'
import { FormMode } from 'App/components/Manage/Shared/CrudType/Form'
import { getChronologyDate } from 'App/components/Booking/NewBookingForm/SubmitAction/convertValuesToInput'
import {
  Booking,
  BookingDynamicFieldQuery,
  BookingDynamicFieldType,
  BookingTypeDynamicField,
  QuotationStatus
} from 'App/types/types'
import { DocumentNode } from 'graphql'
import { get } from 'lodash'
import moment from 'moment'

export const rootKeys = ['remarks', 'serviceTypes', 'quotationUuid']

export type DynamicFieldQueryGraphQLArgs = {
  query: DocumentNode | null
  variables?: any
  getData?: (data: any) => any
  valueFormatter?: (value: any) => any
  labelFormatter?: (value: any) => any
}

export const getDynamicFieldQuery = (
  dynamicField: BookingTypeDynamicField
): DynamicFieldQueryGraphQLArgs => {
  switch (dynamicField.query) {
    case BookingDynamicFieldQuery.Department:
      return {
        query: DEPARTMENT_QUERY,
        variables: { key: 'bookingDepartments' },
        getData: (data: any) => {
          const val = data?.setting?.setting?.[0]?.value
          return val ? JSON.parse(val).departments : []
        }
      }
    case BookingDynamicFieldQuery.Quotation:
      return {
        query: QUOTATION_QUERY,
        variables: {
          statuses: QuotationStatus.Activated
        },
        getData: (data: any) => {
          return data?.quotations?.rows
        },
        labelFormatter: (item) => item.quotationNo,
        valueFormatter: (item) => item.quotationNo
      }
    case BookingDynamicFieldQuery.Company:
      return {
        query: GET_COMPANIES_QUERY,
        variables: {
          types: dynamicField.companyTypes
        },
        getData: (data: any) => {
          return data?.companies?.rows
        },
        labelFormatter: (item) => item.name,
        valueFormatter: (item) => item.uuid
      }
    default:
      return {
        query: null
      }
  }
}

const getDateFieldPath = (key: string, mode: FormMode = FormMode.Create, booking: Booking = {}) => {
  let path = `dates.${key}`

  if (mode === FormMode.Edit) {
    const dateIndex = booking?.chronologies?.findIndex((chronology) => chronology?.type === key)
    path = `chronologies[${dateIndex}].date`
  }

  return path
}

export const getDynamicFieldLookupKey = (
  key: string,
  mode: FormMode = FormMode.Create,
  type?: BookingDynamicFieldType | null,
  booking?: Booking | null
) => {
  let fieldKey: any = rootKeys.includes(key || '') ? key || '' : `details.${key}`

  if (type === BookingDynamicFieldType.Date) {
    fieldKey = getDateFieldPath(key, mode, booking || {})
  }

  return fieldKey
}

export const getDynamicFieldInitialValue = (
  key: string,
  dynamicField: BookingTypeDynamicField,
  booking: Booking | any = {},
  mode: FormMode = FormMode.Create,
) => {
  let initialValue = get(booking, key)
  const [prefixKey, suffixKey] = key.split('.')

  if (dynamicField.type === BookingDynamicFieldType.Date) {
    initialValue = getChronologyDate(booking?.type, suffixKey)
  }

  if (mode === FormMode.Edit) {
    if (dynamicField.type === BookingDynamicFieldType.Date) {
      initialValue = moment(initialValue || '')
    }

    if (dynamicField.type === BookingDynamicFieldType.Selector) {
      if (dynamicField.query === BookingDynamicFieldQuery.Company) {
        const company = get(booking, `details.${key}`) || get(booking, key) || {}
        initialValue = company.uuid
      }
    }
  }

  if (!initialValue) {
    initialValue = dynamicField.multiple ? [] : ''
  }

  if (dynamicField.query === BookingDynamicFieldQuery.CustomValues && !dynamicField.customValues) {
    initialValue = initialValue || undefined
  }

  return initialValue || undefined
}

export const DYNAMIC_FIELD_DATE_FORMAT = 'DD/MM/YYYY HH:mm'
