import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import webStorage from 'App/utils/webStorage'

export const actions = {
  UPDATE_TAXES: 'UPDATE_TAXES',
  TOGGLE_LOADER: 'TOGGLE_LOADER',
  UPDATE_COST_ITEMS: 'UPDATE_COST_ITEMS',
  TOGGLE_VOUCHER_VIEW: 'TOGGLE_VOUCHER_VIEW',
  UPDATE_VOUCHER_BOOKINGS: 'UPDATE_VOUCHER_BOOKINGS',
  UPDATE_SELECTED_VOUCHER: 'UPDATE_SELECTED_VOUCHER',
  UPDATE_SELECTED_SECTION: 'UPDATE_SELECTED_SECTION',
  UPDATE_COST_ITEMS_VIEWS: 'UPDATE_COST_ITEMS_VIEWS',
  UPDATE_BILL_TO_COMPANIES: 'UPDATE_BILL_TO_COMPANIES',
  UPDATE_VOUCHER_SUBMITTING: 'UPDATE_VOUCHER_SUBMITTING',
  UPDATE_AVAILABLE_APPROVALS: 'UPDATE_AVAILABLE_APPROVALS',
  UPDATE_SELECTABLE_DOCUMENTS: 'UPDATE_SELECTABLE_DOCUMENTS',
  UPDATE_COSTSHEET_BOOKINGS: 'UPDATE_COSTSHEET_BOOKINGS',
  TOGGLE_VOUCHER_BOOKING_LOADER: 'TOGGLE_VOUCHER_BOOKING_LOADER',
  UPDATE_COST_ITEMS_DELETED_VIEW: 'UPDATE_COST_ITEMS_DELETED_VIEW',
  UPDATE_SELECTED_VOUCHER_BOOKING: 'UPDATE_SELECTED_VOUCHER_BOOKING',
  UPDATE_SELECTED_VOUCHER_TEMPLATE: 'UPDATE_SELECTED_VOUCHER_TEMPLATE',
  UPDATE_VOUCHER_ITEMS_DELETED_VIEW: 'UPDATE_VOUCHER_ITEMS_DELETED_VIEW'
}

export const updateTaxes = (taxes) => ({
  type: actions.UPDATE_TAXES,
  taxes
})

export const updateVoucherSubmitting = (submitting) => ({
  type: actions.UPDATE_VOUCHER_SUBMITTING,
  submitting
})

export const toggleVoucherBookingLoader = (loading) => ({
  type: actions.TOGGLE_VOUCHER_BOOKING_LOADER,
  loading
})

export const updateSelectedSection = (section) => ({
  type: actions.UPDATE_SELECTED_SECTION,
  section
})

export const updateVoucherBookings = (voucherBookings) => ({
  type: actions.UPDATE_VOUCHER_BOOKINGS,
  voucherBookings
})

export const updateCostsheetBookings = (bookings) => ({
  type: actions.UPDATE_COSTSHEET_BOOKINGS,
  bookings
})

export const updateBillToCompanies = (billToCompanies) => ({
  type: actions.UPDATE_BILL_TO_COMPANIES,
  billToCompanies
})

export const updateAvailableApprovals = (approvals) => ({
  type: actions.UPDATE_AVAILABLE_APPROVALS,
  approvals
})

export const updateCostItems = (costItems) => ({
  type: actions.UPDATE_COST_ITEMS,
  costItems
})

export const updateSelectedVoucher = (voucher) => ({
  type: actions.UPDATE_SELECTED_VOUCHER,
  voucher
})

export const updateSelectedVoucherTemplate = (voucherTemplate) => ({
  type: actions.UPDATE_SELECTED_VOUCHER_TEMPLATE,
  voucherTemplate
})

export const toggleVoucherView = () => ({
  type: actions.TOGGLE_VOUCHER_VIEW
})

export const updateSelectableDocument = (documents) => ({
  type: actions.UPDATE_SELECTABLE_DOCUMENTS,
  documents
})

export const toggleLoader = (loading) => ({
  type: actions.TOGGLE_LOADER,
  loading
})

export const updateSelectedVoucherBooking = (booking) => ({
  type: actions.UPDATE_SELECTED_VOUCHER_BOOKING,
  booking
})

export const updateCostItemsView = (show) => {
  webStorage.setItem(LOCAL_STORAGE_KEYS.UPDATE_COST_ITEMS_VIEWS, show)
  return {
    type: actions.UPDATE_COST_ITEMS_VIEWS,
    show
  }
}

export const updateCostItemsDeletedView = (show) => ({
  type: actions.UPDATE_COST_ITEMS_DELETED_VIEW,
  show
})

export const updateVoucherItemsDeletedView = (show) => ({
  type: actions.UPDATE_VOUCHER_ITEMS_DELETED_VIEW,
  show
})

const initialState = {
  taxes: [],
  loading: true,
  costItems: [],
  submitting: false,
  showVoucher: false,
  costItemsView: webStorage.getItem(LOCAL_STORAGE_KEYS.UPDATE_COST_ITEMS_VIEWS) || 'operation',
  costsheetBookings: [],
  selectedSection: {},
  billToCompanies: [],
  selectedVoucher: {},
  voucherBookings: [],
  availableApprovals: [],
  selectableDocuments: [],
  showDeletedCostItems: false,
  selectedVoucherBooking: '',
  voucherBookingsLoading: false,
  showDeletedVoucherItems: false,
  selectedVoucherTemplate: {},
  paymentTypes: [
    {
      text: 'Cash',
      value: 'CASH'
    },
    {
      text: 'Credit',
      value: 'CREDIT'
    },
    {
      text: 'One Time Vendor',
      value: 'ONETIMEVENDOR'
    },
    {
      text: 'Petty Cash',
      value: 'PETTYCASH'
    }
  ],
  paymentTransactionTypes: [
    {
      text: 'Wire Transfer',
      value: 'WIRE_TRANSFER'
    },
    {
      text: 'Cash',
      value: 'CASH'
    },
    {
      text: 'Cheque',
      value: 'CHEQUE'
    },
    {
      text: 'Other',
      value: 'OTHER'
    }
  ],
  linkableBookings: [
    {
      uuid: '7d2efc29-a2d2-4a0d-96e1-588344d6f1b7',
      bl: 'rJtuACwkz',
      status: 'CLOSED OPERATION'
    },
    {
      bl: 'rklUmLFkX',
      uuid: '46c4706b-3ff4-478b-8346-ebb5b9032ffc',
      status: 'CLOSED OPERATION'
    },
    {
      bl: 'rJM3dmFyQ',
      uuid: '2f58f9ae-eafa-4725-a989-f3ecb19ed5d3',
      status: 'ACCEPTED'
    }
  ]
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_TAXES:
      return { ...state, taxes: action.taxes }
    case actions.TOGGLE_LOADER:
      return { ...state, loading: action.loading }
    case actions.UPDATE_COST_ITEMS:
      return { ...state, costItems: action.costItems }
    case actions.UPDATE_SELECTED_VOUCHER:
      return { ...state, selectedVoucher: action.voucher }
    case actions.UPDATE_SELECTED_SECTION:
      return { ...state, selectedSection: action.section }
    case actions.TOGGLE_VOUCHER_VIEW:
      return { ...state, showVoucher: !state.showVoucher }
    case actions.UPDATE_VOUCHER_SUBMITTING:
      return { ...state, submitting: action.submitting }
    case actions.UPDATE_COSTSHEET_BOOKINGS:
      return { ...state, costsheetBookings: action.bookings }
    case actions.TOGGLE_VOUCHER_BOOKING_LOADER:
      return { ...state, voucherBookingsLoading: action.loading }
    case actions.UPDATE_BILL_TO_COMPANIES:
      return { ...state, billToCompanies: action.billToCompanies }
    case actions.UPDATE_VOUCHER_BOOKINGS:
      return { ...state, voucherBookings: action.voucherBookings }
    case actions.UPDATE_COST_ITEMS_DELETED_VIEW:
      return { ...state, showDeletedCostItems: action.show }
    case actions.UPDATE_VOUCHER_ITEMS_DELETED_VIEW:
      return { ...state, showDeletedVoucherItems: action.show }
    case actions.UPDATE_AVAILABLE_APPROVALS:
      return { ...state, availableApprovals: action.approvals }
    case actions.UPDATE_SELECTABLE_DOCUMENTS:
      return { ...state, selectableDocuments: action.documents }
    case actions.UPDATE_COST_ITEMS_VIEWS:
      return { ...state, costItemsView: action.show }
    case actions.UPDATE_SELECTED_VOUCHER_BOOKING:
      return { ...state, selectedVoucherBooking: action.booking }
    case actions.UPDATE_SELECTED_VOUCHER_TEMPLATE:
      return { ...state, selectedVoucherTemplate: action.voucherTemplate }
    default:
      return state
  }
}
