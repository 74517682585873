import { useCallback } from 'react'
import { Button, Typography } from 'antd'
import { useAuth0 } from '@auth0/auth0-react'
import { useTranslation } from 'react-i18next'

import useAuthStore from 'App/store/auth'

const Logout = () => {
  const { logout } = useAuth0()
  const { t } = useTranslation()

  const setLogout = useAuthStore.use.setLogout()

  const onLogOut = useCallback(
    (e) => {
      e.preventDefault()
      setLogout()
      logout({ logoutParams: { returnTo: window.location.origin } })
    },
    [logout, setLogout]
  )

  return (
    <Button type="link" onClick={onLogOut} style={{ padding: 0, height: '100%', width: '100%' }}>
      <Typography.Text style={{ textAlign: 'left' }}>
        {t('header.logout')}
      </Typography.Text>
    </Button>
  )
}

export default Logout
