import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Row, Col } from 'antd'
import sanitizeHtml from 'sanitize-html'
import handlebars from 'handlebars'

import {
  Receivers,
  TemplateWrapper,
  TemplateSection,
  SectionTitle
} from '../Requisition/Template/Container'
import Subject from '../Requisition/Template/Subject'
import Receiver from '../Requisition/Template/Receiver'
import HtmlTemplate from '../Requisition/Template/HtmlTemplate'

const FormItem = Form.Item
const { TextArea } = Input

class RequisitionForm extends Component {
  getHtml() {
    const {
      form: { getFieldValue },
      requisitionTemplate
    } = this.props
    const template = handlebars.compile(sanitizeHtml(requisitionTemplate.template))
    const remarks = (getFieldValue('remarks') || requisitionTemplate.fields.remarks || '').replace(
      /(?:\r\n|\r|\n)/g,
      '<br />'
    )

    const result = template({
      ...requisitionTemplate.fields,
      remarks,
      documents: '' // Still need this?
    })

    return result
  }

  render() {
    const {
      form: { getFieldDecorator },
      bookingUuid,
      type,
      jobs,
      requisitionTemplate
    } = this.props

    return (
      <Form>
        {getFieldDecorator('bookingUuid', {
          initialValue: bookingUuid
        })(<Input type="hidden" />)}
        {getFieldDecorator('type', {
          initialValue: type
        })(<Input type="hidden" />)}
        {getFieldDecorator('jobs', {
          initialValue: jobs
        })(<Input type="hidden" />)}
        <Row>
          <Col span={24}>
            <Receivers>
              <FormItem>
                {getFieldDecorator('subject', {
                  initialValue: requisitionTemplate.fields.subject,
                  rules: [
                    {
                      required: true,
                      message: 'Please specify subject.'
                    }
                  ]
                })(<Subject />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator('to', {
                  initialValue: requisitionTemplate.to,
                  rules: [
                    {
                      required: true,
                      message: 'Please specify who to send to.'
                    }
                  ]
                })(<Receiver type="to" receivers={requisitionTemplate.to} />)}
              </FormItem>
              <FormItem>
                {getFieldDecorator('cc', {
                  initialValue: requisitionTemplate.cc
                })(<Receiver type="cc" receivers={requisitionTemplate.cc} />)}
              </FormItem>
            </Receivers>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <HtmlTemplate dangerouslySetInnerHTML={{ __html: this.getHtml() }} />
          </Col>
          <Col span={12}>
            <TemplateWrapper>
              {this.props.children}
              <TemplateSection>
                <SectionTitle>Remarks</SectionTitle>
                <FormItem required>
                  {getFieldDecorator('remarks', {
                    initialValue: requisitionTemplate.fields.remarks
                  })(<TextArea autoSize placeholder="Additional remarks..." />)}
                </FormItem>
              </TemplateSection>
            </TemplateWrapper>
          </Col>
        </Row>
      </Form>
    )
  }
}

RequisitionForm.propTypes = {
  bookingUuid: PropTypes.string,
  type: PropTypes.string,
  jobs: PropTypes.array,
  form: PropTypes.object,
  requisitionTemplate: PropTypes.object,
  children: PropTypes.element
}

export default RequisitionForm
