import { List } from 'antd';


interface DateListProps {
  dates: string[];
}

const DateList: React.FC<DateListProps> = ({ dates }) => {
  return (
    <List
      dataSource={dates}
      renderItem={item => <List.Item>{item}</List.Item>}
    />
  )
}

export default DateList
