import pick from 'lodash/pick'
import { get, omit, uniq } from 'lodash'
import moment, { Moment } from 'moment'
import { AddJobInput, BookingDynamicFieldQuery, BookingDynamicFieldType, BookingTypeDynamicField, BookingTypeDynamicFieldChronologyLevel, JobType, MakeBookingInput, Trip } from 'App/types/types'
import { getTrimmedJobDetails } from 'App/components/Booking/NewBookingForm/SubmitAction/helper'
import { v4 as uuidV4 } from 'uuid'
import { rootKeys } from 'App/components/Booking/DynamicField/helper'

const getLastTrip = (jobs: AddJobInput[]) => {
  const lastJob = jobs[jobs.length - 1]
  return lastJob?.trips?.[lastJob?.trips.length - 1]
}

const chronologyDateKeys = ['shipperRequiredDate', 'consigneeRequiredDate']

export const getChronologyDate = (type: any, key: string, value: any = moment()): Moment => {
  if (type === 'TRANSPORT' && chronologyDateKeys.includes(key)) {
    return value?.set({ hour: 14, minute: 0, second: 0, millisecond: 0 })
  }

  return value
}

const getChronologies = (dynamicFields: BookingTypeDynamicField[], input: Partial<MakeBookingInput>) => {
  const fields = dynamicFields.filter((df) => df.type === BookingDynamicFieldType.Date && chronologyDateKeys.includes(df.key || ''))
  const chronologies: any[] = []

  for (const field of fields) {
    switch (field.chronologyLevel) {
      case BookingTypeDynamicFieldChronologyLevel.Job: {
        // @ts-ignore
        for (let j = 0; j < input?.jobs?.length; j++) {
          const job = input?.jobs?.[j] || {}
          const value = get(job, 'dates')?.[field?.key || '']

          chronologies.push({
            type: field.key,
            date: getChronologyDate(input.type, field.key || '', value).toDate(),
            jobUuid: job?.uuid
          })
        }

        break
      }

      case BookingTypeDynamicFieldChronologyLevel.Trip: {
        // @ts-ignore
        for (let j = 0; j < input?.jobs?.length; j++) {
          const job = input?.jobs?.[j]
          // @ts-ignore
          for (let t = 0; t < job?.trips?.length; t++) {
            const trip = job?.trips?.[t] || {}
            const value = get(trip, 'dates')?.[field?.key || '']

            chronologies.push({
              type: field.key,
              date: getChronologyDate(input.type, field.key || '', value).toDate(),
              jobUuid: job?.uuid,
              tripUuid: trip?.uuid
            })
          }
        }

        break
      }

      default: {
        const value = get(input, 'dates')?.[field?.key || '']

        chronologies.push({
          type: field.key,
          date: getChronologyDate(input.type, field.key || '', value).toDate()
        })

        break
      }
    }
  }

  //@ts-ignore
  delete input.dates
  input.jobs?.forEach((job) => {
    //@ts-ignore
    delete job.dates
    //@ts-ignore
    job.trips?.forEach((trip) => {
      //@ts-ignore
      delete trip.dates
    })
  })

  return chronologies
}

export const parseDetails = (details: any, dynamicFields: BookingTypeDynamicField[]) => {
  const detailsItemsToParse = uniq(
    dynamicFields.reduce((items, df) => {
      if (df.query === BookingDynamicFieldQuery.Company) {
        df.key && items.push(df.key)
      }

      return items
    }, [] as any[])
  )

  detailsItemsToParse.forEach((item) => {
    if (item) {
      try {
        details[item] = JSON.parse(details[item]).uuid
      } catch (e) {
        // do nothing
      }
    }
  })
}

export default (
  values: any,
  dynamicFields: BookingTypeDynamicField[] = [],
  jobTypes: JobType[] = []
): Partial<MakeBookingInput> | undefined => {
  const input: Partial<MakeBookingInput> = pick(values, [
    'uuid',
    'type',
    'override',
    'tags',
    'shipperUuid',
    'shipperAddressUuid',
    'consigneeUuid',
    'consigneeAddressUuid',
    'billToUuid',
    'jobs',
    'chronologies',
    'details',
    'forwarderDest',
    'forwarderOri',
    'dates',
    ...rootKeys
  ])

  let hasInvalidPoint = false
  for (let i = 0; i < values.jobs.length; i++) {
    const invalidPoint = values.jobs[i].trips?.find(
      (t: any) => !t.toCompanyUuid || !t.toUuid || !t.fromCompanyUuid || !t.fromUuid
    )

    if (invalidPoint) {
      hasInvalidPoint = true
      throw new Error(`Please select the company and address for all trips in Job #${i + 1}.`)
    }
  }

  if (hasInvalidPoint) {
    return
  }

  input.details.shipperUuid = values.jobs[0].trips[0]?.fromCompanyUuid
  input.details.shipperAddressUuid = values.jobs[0].trips[0]?.fromUuid
  const lastTrip = getLastTrip(values.jobs)
  input.details.consigneeUuid = lastTrip?.toCompanyUuid
  input.details.consigneeAddressUuid = lastTrip?.toUuid

  input.jobs = [
    ...values.jobs.map((job: any) => {
      const formattedJob: any = {
        details: getTrimmedJobDetails(job, jobTypes),
        type: job.type,
        no: job.no,
        remarks: job.remarks,
        trips: job.trips.map((t: Trip) => Object.assign(omit(t, ['key']), { uuid: uuidV4() })),
        tripFormat: job.tripFormat,
        uuid: uuidV4(),
        dates: job.dates
      }

      return formattedJob
    })
  ]

  input.chronologies = getChronologies(dynamicFields, input)

  return input
}
