import { Table } from 'antd'
import moment from 'moment'
import { compose, withHandlers } from 'recompose'

import config from 'App/config'
import EditAction from './EditAction'
import { logger } from 'App/utils/logger'
import { hasPermissionError } from 'App/utils/u'
import { NoPermissionWrapper } from 'App/components/TableView/Styled'

const handlers = withHandlers({
  handleEdited: (props) => () => {
    const { documentCreatorsGraphql } = props
    documentCreatorsGraphql.refetch()
  },
  handlePageChange: (props) => (page, pageSize) => {
    const { documentCreatorsGraphql } = props
    documentCreatorsGraphql.refetch({
      limit: pageSize,
      offset: (page - 1) * pageSize
    })
  }
})

const enhance = compose(handlers)

const getColumns = (props) => {
  const { handleEdited } = props

  return [
    {
      key: 'type',
      title: 'Type',
      dataIndex: 'type'
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status'
    },
    {
      key: 'createdAt',
      title: 'Created',
      dataIndex: 'createdAt',
      render: (text, record, index) => {
        const { createdAt } = record
        return moment(createdAt).format('ll')
      }
    },
    {
      key: 'updatedAt',
      title: 'Updated',
      dataIndex: 'updatedAt',
      render: (text, record, index) => {
        const { updatedAt } = record
        return moment(updatedAt).format('ll')
      }
    },
    {
      key: 'action',
      title: '',
      align: 'right',
      render: (text, record) => {
        return <EditAction documentCreatorUuid={record.uuid} onEdited={handleEdited} />
      }
    }
  ]
}

const TableView = (props) => {
  const { documentCreatorsGraphql, handlePageChange } = props
  const { loading, error, documentCreators = {} } = documentCreatorsGraphql

  if (error) {
    if (hasPermissionError(error)) {
      return <NoPermissionWrapper>{config.youDoNotHavePermissionToViewThis}</NoPermissionWrapper>
    }

    logger.error('DocumentCreatorSection TableView Error.', error)
  }

  const { rows = [], pageInfo } = documentCreators
  const dataSource = rows.filter((r) => r.type !== 'ROT')

  const pagination = pageInfo && {
    total: pageInfo.count,
    pageSize: pageInfo.limit,
    current: pageInfo.offset / pageInfo.limit + 1,
    onChange: handlePageChange
  }

  return (
    <Table
      dataSource={dataSource}
      rowKey={(record) => record.uuid}
      columns={getColumns(props)}
      loading={loading && !rows}
      size="small"
      pagination={pagination}
    />
  )
}

export default enhance(TableView)
