import { memo, useState } from 'react'
import { Drawer,Button } from 'antd'

import { EventToggle } from './Styled'
import EventList from 'App/components/Booking/Events/EventList'

const Events = ({ bookingUuid }) => {
  const [showDrawer, setShowDrawer] = useState(false)

  const openDrawer = () => setShowDrawer(true)
  const closeDrawer = () => setShowDrawer(false)

  return (
    <>
        <EventToggle>
          <Button icon="arrow-left" onClick={openDrawer}>
            View events
          </Button>
        </EventToggle>
      <Drawer
        width={350}
        placement="right"
        visible={showDrawer}
        onClose={closeDrawer}
        title="Booking Events"
      >
        <EventList bookingUuid={bookingUuid} />
      </Drawer>
    </>
  )
}

export default memo(Events)
