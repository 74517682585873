import { useState } from 'react'
import { Popover, Button, InputNumber, Row } from 'antd'

type DuplicateButtonProps = {
  handleDuplicate: (index, duplicateNumber) => void
  index: number
}

const DuplicateButton = ({ handleDuplicate = () => {}, index }: DuplicateButtonProps) => {
  const [duplicateNumber, setDuplicateNumber] = useState(1)

  const updateDuplicateNumber = (value?: number) => {
    if (value) {
      setDuplicateNumber(value)
    }
  }

  const content = (
    <>
      <Row>
        <InputNumber
          style={{ marginBottom: 5 }}
          defaultValue={duplicateNumber}
          onChange={updateDuplicateNumber}
        />
      </Row>

      <Button
        type="default"
        style={{ width: '100%' }}
        onClick={() => handleDuplicate(index, duplicateNumber)}
      >
        Duplicate
      </Button>
    </>
  )

  return (
    <Popover trigger="click" content={content}>
      <Button icon="copy" />
    </Popover>
  )
}

export default DuplicateButton
