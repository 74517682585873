import { memo } from 'react'
import { withApollo } from 'react-apollo'
import { gql, useQuery } from '@apollo/client'

const PORT_VIEW = gql`
  query port2($uuid: UUID!) {
    port(uuid: $uuid) {
      name
    }
  }
`

const Port = memo(({ uuid, client }: { uuid: string; client: any }) => {
  const { data, loading, error } = useQuery(PORT_VIEW, {
    client,
    variables: { uuid },
    skip: !uuid
  })

  if (!uuid) {
    return <span>Port is not selected.</span>
  }

  if (loading) {
    return <span>Loading...</span>
  }

  if (error) {
    return <span>Error while loading port</span>
  }

  if (!data) {
    return <span>Error: Port not found</span>
  }

  return <span>{data.port?.name}</span>
})

export default withApollo(Port)
