import { gql } from '@apollo/client'

import paymentDetails from './payment'

export default gql`
  fragment VoucherPaymentDetails on VoucherPayment {
    uuid
    amount
    voucherUuid
    payment {
      ...PaymentDetails
    }
  }

  ${paymentDetails}
`
