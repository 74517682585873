import { gql } from '@apollo/client'

import { fragments as userFragment } from 'App/containers/user/fragment'

const chronologyDetails = gql`
  fragment ChronologyDetails on Chronology {
    uuid
    jobUuid
    tripUuid
    date
    type
    remarks
    reference
    updatedAt
    createdAt
    user {
      ...UserDetails
    }
  }

  ${userFragment.userDetails}
`

export const fragments = {
  chronologyDetails
}
