import React, { Component } from 'react'
import { Row, Col } from 'antd'

import Container from './Container'
import { SectionTitle } from 'App/components/Workflow/Requisition/Template/Container'
import Selectables from 'App/components/Voucher/Attachments/Selectables'
import DocumentSelector from 'App/components/DocumentSelector'

class Attachments extends Component {
  handleSelectDocument = (document) => {
    const { value, onChange } = this.props
    onChange([...value, document.uuid])
  }

  handleRemove = (document) => () => {
    const { value, onChange } = this.props
    const newValue = value.filter((v) => v !== document.uuid)

    onChange(newValue)
  }

  render() {
    const { selectableDocuments, value } = this.props
    const selectedAttachments = selectableDocuments.filter((a) => value.includes(a.uuid))

    return (
      <React.Fragment>
        <SectionTitle>
          Attachments
          <span style={{ marginLeft: '10px' }}>
            <Selectables
              selectable
              selectableDocuments={selectableDocuments}
              selectedDocuments={selectedAttachments}
              onSelectSingleDocument={this.handleSelectDocument}
            />
          </span>
        </SectionTitle>
        <Container>
          <Row>
            {selectedAttachments.length === 0 && (
              <Col span={24}>
                <span>No attachments.</span>
              </Col>
            )}
            {selectedAttachments.map((doc) => {
              return (
                <Col key={doc.uuid} span={8}>
                  <DocumentSelector
                    hrefUrl={`/booking/document/${doc.uuid}`}
                    uploadedDoc={doc}
                    removable
                    onRemove={this.handleRemove(doc)}
                  />
                </Col>
              )
            })}
          </Row>
        </Container>
      </React.Fragment>
    )
  }
}

export default Attachments
