import { graphql } from 'react-apollo'

import DOCUMENT_CREATORS_QUERY from 'App/containers/documentCreator/schema/documentCreatorsQuery'

export default graphql(DOCUMENT_CREATORS_QUERY, {
  name: 'documentCreatorsQuery',
  options: (props) => ({
    variables: {
      bookingUuid: props.bookingUuid
    }
  })
})
