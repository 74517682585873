import { gql } from '@apollo/client'

export default gql`
  query getBookingDocument2($uuid: UUID!) {
    bookingDocument(uuid: $uuid) {
      uuid
      jobUuid
      tripUuid
      type
      reference
      remarks
      tags
      status
      url
      document {
        uuid
        name
        type
        size
      }
      approvals {
        uuid
        type
        status
        createdAt
        approvedBy {
          email
        }
      }
      createdBy {
        uuid
        email
      }
      verifiedBy {
        uuid
        email
      }
      createdAt
      updatedAt
    }
  }
`
