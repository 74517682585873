import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Divider } from 'antd'
import { Wrapper, Text } from './Styled'

class DividerWithText extends Component {
  render() {
    const { text } = this.props

    return (
      <Wrapper>
        <Divider orientation={'left'}>
          <Text>{text}</Text>
        </Divider>
      </Wrapper>
    )
  }
}

DividerWithText.propTypes = {
  text: PropTypes.string
}

export default DividerWithText
