import { gql } from '@apollo/client'

export default gql`
  query chargeItemsForSelector1($query: String, $limit: Int, $offset: Int, $statuses: [String]) {
    chargeItems(query: $query, limit: $limit, offset: $offset, statuses: $statuses) {
      rows {
        uuid
        code
        name

        # For add new cost item in voucher:
        unit
        rateType
        costRate
        sellRate
        sellCurrency {
          uuid
        }
        costCurrency {
          uuid
        }
        costTax {
          uuid
          percentage
        }
      }
    }
  }
`
