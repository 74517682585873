
import { compose, withHandlers } from 'recompose'
import { List, Spin } from 'antd'
import styled from 'styled-components'
import { graphql } from 'react-apollo'

import { documentCreatorTemplatesGql } from './documentCreatorTemplatesGql'
import { createDocumentCreatorFromTemplateGql } from './createDocumentCreatorFromTemplateGql'
import DocumentCreatorTemplateCard from './DocumentCreatorTemplateCard'
import respHandler from 'App/utils/responseHandler'
import { useTranslation } from 'react-i18next'

const documentCreatorTemplatesGraphql = graphql(documentCreatorTemplatesGql, {
  name: 'documentCreatorTemplatesGraphql',
  options: (props) => ({
    variables: {
      limit: 20,
      offset: 0,
      statuses: ['activated']
    },
    fetchPolicy: 'cache-and-network'
  })
})

const createDocumentCreatorFromTemplateGraphql = graphql(createDocumentCreatorFromTemplateGql, {
  props: ({ mutate, ownProps }) => ({
    createDocumentCreatorFromTemplate: (params) =>
      mutate({
        variables: {
          templateUuid: params.templateUuid,
          bookingUuid: params.bookingUuid
        }
      })
  })
})

const handlers = withHandlers({
  handleClick: (props) => async (documentCreatorTemplate) => {
    const { createDocumentCreatorFromTemplate, bookingUuid, onCreated = () => { } } = props

    respHandler('Creating document creator from template, hang on...', 'load')

    try {
      const result = await createDocumentCreatorFromTemplate({
        templateUuid: documentCreatorTemplate?.uuid,
        bookingUuid
      })
      const documentCreator = result.data.createDocumentCreatorFromTemplate

      respHandler('Created successfully', 'success')

      onCreated(documentCreator)
    } catch (error) {
      console.log(error)
      respHandler(error, 'error')
    }
  },
  handlePageChange: (props) => (page, pageSize) => {
    const { documentCreatorTemplatesGraphql } = props
    documentCreatorTemplatesGraphql.refetch({
      limit: pageSize,
      offset: (page - 1) * pageSize
    })
  }
})

const enhance = compose(
  documentCreatorTemplatesGraphql,
  createDocumentCreatorFromTemplateGraphql,
  handlers
)

const StyledListItem = styled(List.Item)`
  cursor: pointer;
`

const TemplateSelectionList = (props) => {
  const { t } = useTranslation()
  const { documentCreatorTemplatesGraphql, handleClick, handlePageChange } = props
  const { loading, error, documentCreatorTemplates = {} } = documentCreatorTemplatesGraphql

  if (error) {
    const errorCodes = error.graphQLErrors.map(e => e.extensions.exception.statusCode)

    if (errorCodes.includes(403)) {
      return <span>{t("error.permission.general")}</span>
    }

    return <span>{t("common.errorOccurred")}</span>
  }
  const { rows = [], pageInfo } = documentCreatorTemplates
  const dataSource = rows.filter((el) => {
    return el.type.toUpperCase() !== el.type
  })

  const pagination = pageInfo && {
    total: pageInfo.count,
    pageSize: pageInfo.limit,
    current: pageInfo.offset / pageInfo.limit + 1,
    onChange: handlePageChange
  }

  return (
    <Spin spinning={loading && !rows}>
      <List
        grid={{ gutter: 16, column: 4 }}
        dataSource={dataSource}
        pagination={pagination}
        renderItem={(item) => (
          <StyledListItem>
            <DocumentCreatorTemplateCard documentCreatorTemplate={item} onClick={handleClick} />
          </StyledListItem>
        )}
      />
    </Spin>
  )
}

export default enhance(TemplateSelectionList)
