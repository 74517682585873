import React, { useEffect } from 'react'
import { Button } from 'antd'
import config from 'App/config'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import webStorage from 'App/utils/webStorage'

const CheckPdfStatusButton = (props) => {
  const { uuid, documentCreatorUuid, onGenerated = () => {} } = props

  useEffect(() => {
    const intervalId = setInterval(async () => {
      const pdfServiceUrl = `${config.pdf.baseUrl}/status/${uuid}`

      try {
        const response = await fetch(pdfServiceUrl)

        if (!response.ok) {
          throw new Error(await response.text())
        }

        const body = await response.json()

        const pdfServiceObjectMap =
          webStorage.getItem(LOCAL_STORAGE_KEYS.DOCUMENT_CREATOR_PDF_SERVICE) || {}
        pdfServiceObjectMap[documentCreatorUuid] = body
        webStorage.setItem(LOCAL_STORAGE_KEYS.DOCUMENT_CREATOR_PDF_SERVICE, pdfServiceObjectMap)

        if (body.finishedOn && body.finishedOn > 0) {
          onGenerated()
        }
      } catch (error) {
        console.log(error)
      }
    }, 3000)

    return () => {
      clearInterval(intervalId)
    }
  })

  return (
    <Button loading type="primary">
      Generating...
    </Button>
  )
}

export default CheckPdfStatusButton
