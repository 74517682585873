import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { compose, withStateHandlers } from 'recompose'
import { Popover, Button, Row } from 'antd'

import Container from './Styled'
import RequestButton from './RequestButton'
import { requestTypes } from 'App/utils/labelMap'
import RequisitionModal from '../RequisitionModal'

class Requisition extends Component {
  viewRequisition = (requisition) => {
    const { openRequisition } = this.props
    openRequisition(requisition)
  }

  renderAddRequest = (requisitions) => {
    if (requisitions && requisitions.length) {
      return (
        <React.Fragment>
          {requisitions.map((req, index) => {
            const requestText = (requestTypes[req.text] && requestTypes[req.text].text) || req.text

            return (
              <Row key={index} style={{ marginBottom: '5px' }}>
                <RequestButton text={requestText} onClick={() => this.viewRequisition(req)} />
              </Row>
            )
          })}
        </React.Fragment>
      )
    }
  }

  render() {
    const { toggleTemplate, requisitions, open, isPopoverOpen } = this.props

    return (
      <Container>
        <Popover
          trigger="click"
          title="Create Request / Notify"
          placement="right"
          content={this.renderAddRequest(requisitions)}
          visible={isPopoverOpen}
          onVisibleChange={(visible) => toggleTemplate({ isPopoverOpen: visible })}
        >
          {requisitions && requisitions.length > 0 && (
            <Button
              onClick={() => toggleTemplate({ isPopoverOpen: !isPopoverOpen })}
              icon="plus"
              shape="circle"
            />
          )}
        </Popover>
        {open && (
          <RequisitionModal
            open={open}
            booking={this.props.booking}
            bookingUuid={this.props.booking.uuid}
            type={this.props.selectedRequisition.type}
            onClose={() => toggleTemplate({ open: false })}
          />
        )}
      </Container>
    )
  }
}

Requisition.propTypes = {
  booking: PropTypes.object,
  selectedRequisition: PropTypes.object,
  open: PropTypes.bool,
  isPopoverOpen: PropTypes.bool,
  toggleTemplate: PropTypes.func,
  openRequisition: PropTypes.func,
  requisitions: PropTypes.array
}

export default compose(
  withStateHandlers(
    {
      selectedRequisition: {},
      open: false,
      isPopoverOpen: false
    },
    {
      toggleTemplate:
        () =>
        ({ open = false, isPopoverOpen = false }) => ({ open, isPopoverOpen }),
      openRequisition: () => (selectedRequisition) => ({
        open: true,
        isPopoverOpen: false,
        selectedRequisition
      })
    }
  )
)(Requisition)
