export const getDataGql = `
  query companiesExport1 (
    $types: [CompanyType!],
    $countryAlpha3: LimitedString,
    $query: String,
    $limit: Int,
    $offset: Int
    $statuses: [CompanyStatus!]
  ) {
    companies (
      types: $types,
      countryAlpha3: $countryAlpha3,
      query: $query,
      limit: $limit,
      offset: $offset,
      statuses: $statuses
    ) {
      rows {
        code
        country {
          name
          alpha3
        }
        billTo {
          code
          uuid
        }
        creditorCode
        creditorTerm
        currency {
          code
          uuid
        }
        debtorCode
        debtorTerm
        description
        name
        registration
        status
        tags
        taxNumber
        types
        uuid
        addresses {
          name
          type
          countryAlpha3
          address1
          address2
          address3
          address4
          city
          district
          postCode
          areaCode
          zone
          location {
            type
            coordinates
          }
          phone
          fax
          tags
          status
          uuid
        }
        contacts {
          name
          email
          phone
          title
          designation
          notes
          status
          uuid
        }
      }
    }
  }
`

export const getExistingCompanyGql = `
  query getExistingCompanyGql1 ($uuid: UUID!) {
    company (uuid: $uuid) {
      code
      creditorCode
      creditorTerm
      debtorCode
      debtorTerm
      name
      status
      uuid
    }
  }
`

export const createEntityGql = `
  mutation createCompanyImport1 ($input: CreateCompanyInput!){
    createCompany (input: $input){
      uuid
      name
      code
      types
      countryAlpha3
      description
      registration
      status
      tags
      taxNumber
    }
  }
`

export const updateEntityGql = `
  mutation updateCompanyImport1 ($input: UpdateCompanyInput!){
    updateCompany (input: $input){
      uuid
      name
      types
      countryAlpha3
      description
      registration
      status
      tags
      taxNumber
    }
  }
`

export const updateCompanyCodeGql = `
  mutation updateCompanyCodeImport1 ($input: UpdateCompanyCodeInput!){
    updateCompanyCode (input: $input){
      uuid
      code
      debtorCode
      debtorTerm
      registration
      taxNumber
    }
  }
`

export const getAllAreaCodesGql = `
  query transportAreaCodesExport2 (
    $limit: Int,
    $offset: Int,
    $q: String
  ) {
    transportAreaCodes (
      limit: $limit,
      offset: $offset,
      q: $q
    ) {
      rows {
        category
        code
        description
        name
        zone
        uuid
      }
    }
  }
`

export const getTransportAreaCodeGql = `
  query transportAreaCodesImport2 (
    $limit: Int,
    $offset: Int,
    $q: String
  ) {
    transportAreaCodes (
      limit: $limit,
      offset: $offset,
      q: $q
    ) {
      rows {
        uuid
        name
        description
        code
        zone
        category
      }
    }
  }
`

export const createAddressGql = `
  mutation addAddressImport1 ($input: AddAddressInput!){
    addAddress (input: $input){
      uuid
      name
      type
      countryAlpha3
      address1
      address2
      address3
      address4
      city
      district
      postCode
      areaCode
      zone
      location {
        type
        coordinates
      }
      phone
      fax
      tags
      status
    }
  }
`

export const updateAddressGql = `
  mutation updateAddressImport1 ($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      uuid
      name
      type
      countryAlpha3
      address1
      address2
      address3
      address4
      city
      district
      postCode
      areaCode
      zone
      location {
        type
        coordinates
      }
      phone
      fax
      tags
      status
    }
  }
`

export const createContactGql = `
  mutation addContactImport1 ($input: AddContactInput!){
    addContact (input: $input){
      uuid
      name
      email
      phone
      title
      designation
      notes
      status
    }
  }
`

export const updateContactGql = `
  mutation updateContactImport1 ($input: UpdateContactInput!){
    updateContact (input: $input){
      uuid
      name
      email
      phone
      title
      designation
      notes
      status
    }
  }
`
