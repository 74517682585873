import { gql } from '@apollo/client'

export default gql`
  fragment ExchangeRateDetails on ExchangeRate {
    uuid
    from {
      uuid
      code
      name
    }
    to {
      uuid
      code
      name
    }
    start
    end
    rate
  }
`
