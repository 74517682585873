import styled from 'styled-components'

export const ScheduleWrapper = styled.div`
  padding-top: 20px;
  display: flex;
  gap: 240px;
`

export const Wrapper = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`

export const ChronoName = styled.div`
  font-size: 11px;
  color: rgb(40, 45, 50);
  text-transform: uppercase;
  letter-spacing: 0.3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const ChronoDate = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: rgb(150, 152, 154);
`
