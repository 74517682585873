import { gql } from '@apollo/client'

export const fragments = {
  requisitionTemplateDetail: gql`
    fragment RequisitionTemplateDetail on RequisitionTemplate {
      uuid
      to
      cc
      bcc
      template
      fields {
        ... on EmailRequestFieldsNoa {
          bl
          consignee
          containers
          subject
          remarks
          receiverGreeting
          customer
          documents {
            uuid
            status
            hardCopyStatus
            location
            type
            url
            document {
              uuid
              name
              type
              size
            }
          }
        }
        ... on EmailRequestFieldsEdo {
          bl
          consignee
          containers
          subject
          remarks
          receiverGreeting
          customer
          documents {
            uuid
            status
            hardCopyStatus
            location
            type
            url
            document {
              uuid
              name
              type
              size
            }
          }
        }
        ... on EmailRequestFieldsImportDocs {
          bl
          consignee
          containers
          subject
          remarks
          receiverGreeting
          customer
          documents {
            uuid
            status
            hardCopyStatus
            location
            type
            url
            document {
              uuid
              name
              type
              size
            }
          }
        }
        ... on EmailRequestFieldsExportDocs {
          bl
          consignee
          containers
          subject
          remarks
          receiverGreeting
          customer
          documents {
            uuid
            status
            hardCopyStatus
            location
            type
            url
            document {
              uuid
              name
              type
              size
            }
          }
        }
        ... on EmailRequestFieldsHaulageRot {
          subject
          remarks
          receiverGreeting
          customer
          bl
          referenceNo
          documents {
            uuid
            status
            hardCopyStatus
            location
            type
            url
            document {
              uuid
              name
              type
              size
            }
          }
        }
        ... on EmailRequestFieldsHaulageRFC {
          subject
          remarks
          receiverGreeting
          customer
          bl
          referenceNo
          requiredDate
          eta
          etd
          lastDay
          closingDay
          deliveryHour
          scn
          dropOff
          operatorCode
          commodity
        }
        ... on EmailRequestFieldsConsigneeDraftNotification {
          bl
          consignee
          containers
          subject
          remarks
          receiverGreeting
          customer
          documents {
            uuid
            status
            hardCopyStatus
            location
            type
            url
            document {
              uuid
              name
              type
              size
            }
          }
        }
        ... on EmailRequestFieldsLinerNotifyCustomerComplete {
          subject
          remarks
          receiverGreeting
          customer
          bl
          shipper
          consignee
          containers
          customerRef
          volume
          commodity
          vessel
          etdPkg
          etaDestination
          airwayBillNo
          date
          documents {
            uuid
            status
            hardCopyStatus
            location
            type
            url
            document {
              uuid
              name
              type
              size
            }
          }
        }
        ... on EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill {
          subject
          remarks
          receiverGreeting
          customer
          bl
          shipper
          consignee
          containers
          customerRef
          volume
          commodity
          vessel
          etdPkg
          etaDestination
          airwayBillNo
          date
          documents {
            uuid
            status
            hardCopyStatus
            location
            type
            url
            document {
              uuid
              name
              type
              size
            }
          }
        }
        ... on EmailRequestFieldsLinerBookingConfirmationCustomerRequest {
          subject
          remarks
          receiverGreeting
          bl
          shipper
          containers
          documents {
            uuid
            status
            hardCopyStatus
            location
            type
            url
            document {
              uuid
              name
              type
              size
            }
          }
        }
        ... on EmailRequestFieldsBookingConfirmationFromLiner {
          subject
          remarks
          receiverGreeting
          bl
          shipper
          consignee
          containers
          volume
          commodity
          vessel
          origin
          destination
          etdFrom
          etaTo
          documents {
            uuid
            status
            hardCopyStatus
            location
            type
            url
            document {
              uuid
              name
              type
              size
            }
          }
        }
      }
    }
  `
}

export const QUERY_REQUISITION_TEMPLATE = gql`
  query requestTemplate($uuid: UUID, $bookingType: String, $type: RequestType) {
    requestTemplate(uuid: $uuid, bookingType: $bookingType, type: $type) {
      ...RequisitionTemplateDetail
    }
  }

  ${fragments.requisitionTemplateDetail}
`

export const SEND_REQUISITION = gql`
  mutation submitRequest($input: EmailRequestInput!) {
    submitRequest(input: $input) {
      type
    }
  }
`

export const QUERY_REQUEST_EMAIL_RESULTS = gql`
  query requestEmailResults($uuid: UUID!) {
    requestEmailResults(uuid: $uuid) {
      recipient
      event
      timestamp
      logLevel
    }
  }
`
