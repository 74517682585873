import { useMemo } from 'react'

import useUserStore from 'App/store/user'
import webStorage from 'App/utils/webStorage'
import { LOCAL_STORAGE_KEYS, namespace } from 'App/utils/auth'

import type { LoggedInUserType } from 'App/hocs/types/auth0'

const groupByBaseCompanyUuid = (user: LoggedInUserType) => {
  if (!user?.baseCompanyUuids?.length || !user?.portalCompanyUuids?.length) {
    return
  }

  return user.baseCompanyUuids.reduce((res: any, bcUuid: string, i: number) => {
    if (!res[bcUuid]) {
      res[bcUuid] = user.portalCompanyUuids?.[i]
    }

    return res
  }, {})
}

const useProcessPortalUser = (loggedInUser?: LoggedInUserType) => {
  const profile = webStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE)
  const stateUser = useUserStore.use.loggedInUser()

  const thisUser = useMemo(
    () => loggedInUser || stateUser || profile, [loggedInUser, profile, stateUser]
  )
  const appMetaData = useMemo(
    () => thisUser?.[namespace]?.app_metadata || thisUser,
    [thisUser]
  )
  const baseCompanyUuids = useMemo(
    () => appMetaData?.baseCompanyUuids,
    [appMetaData]
  )
  const groupedByBaseCompany = useMemo(
    () => groupByBaseCompanyUuid(appMetaData),
    [appMetaData]
  )
  const isPortalUser = useMemo(
    () =>
      appMetaData?.portalCompanyUuids?.reduce(
        (res: boolean, uuids: Array<string>) => {
          if (uuids.length) {
            res = true
          }

          return res
        },
        false
      ),
    [appMetaData]
  )

  return {
    isPortalUser,
    loading: !appMetaData,
    groupedByBaseCompany,
    baseCompanyUuids
  }
}

export default useProcessPortalUser
