import { useState } from 'react'
import { withApollo } from 'react-apollo'
import { Col, Divider, Form, Row } from 'antd'
import { get, isArray, startCase } from 'lodash'
import { type ApolloClient, useQuery } from '@apollo/client'

import JobsTable from './JobsTable'
import Company from './CompanyInfo/Company'

import Port from 'App/components/Booking/NewBookingForm/ConfirmationSubForm/Port'
import Place from 'App/components/Booking/NewBookingForm/ConfirmationSubForm/Place'
import DateList from 'App/components/Booking/NewBookingForm/ConfirmationSubForm/DateList'

import {
  BookingDynamicFieldQuery,
  BookingDynamicFieldType,
  BookingTypeDynamicField,
  BookingTypeDynamicFieldChronologyLevel
} from 'App/types/types'

import { VIEW_BOOKING_TYPE_GQL } from '../../General'
import { rootKeys } from 'App/components/Booking/DynamicField/helper'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

function formatArrayString(value: any, field: BookingTypeDynamicField, client: ApolloClient<any>, values: any) {
  let v = value

  if (isArray(v) || (field.multiple && field.type === BookingDynamicFieldType.Selector)) {
    v = value.join(', ')
  }

  if (field.type === BookingDynamicFieldType.Date) {
    switch (field.chronologyLevel) {
      case BookingTypeDynamicFieldChronologyLevel.Job: {
        //@ts-ignore
        const jobLevelDates = values.jobs.map((job, j) => `JOB#${j + 1}: ${get(job, field.key)?.format('LLL') || 'NA'}`)
        v = <DateList dates={jobLevelDates} />
        break
      }

      case BookingTypeDynamicFieldChronologyLevel.Trip: {
        //@ts-ignore
        const tripLevelDates = values.jobs.flatMap((job, j) => job.trips.map((trip, t) => `TRIP#${j + 1}.${t + 1}: ${get(trip, field.key)?.format('LLL') || 'NA'}`))
        v = <DateList dates={tripLevelDates} />
        break
      }

      default: {
        v = value && value.format('LLL')
        break;
      }
    }
  }

  if (field.query === BookingDynamicFieldQuery.Company) {
    v = value && <Company companyUuid={value.uuid || value} />
  }

  if (field.query === BookingDynamicFieldQuery.Place) {
    v = value && <Place placeId={value} client={client} />
  }

  if (field.query === BookingDynamicFieldQuery.Port) {
    v = value && <Port uuid={value} client={client} />
  }

  return v || ''
}

const BookingType = ({ value }: any) => {
  return (
    <Form.Item label="Booking Type" {...formItemLayout}>
      {value}
    </Form.Item>
  )
}

const BillingCustomerFormItem = ({ value }: any) => {
  return (
    <Form.Item label="Billing Customer" {...formItemLayout}>
      <Company companyUuid={value} />
    </Form.Item>
  )
}

const getDynamicFieldKey = (key: string, type?: BookingDynamicFieldType) => {
  return rootKeys.includes(key)
    ? key
    : type === BookingDynamicFieldType.Date
      ? `dates.${key}`
      : `details.${key}`
}

const ConfirmationSubForm = ({ form, step, isMultiPoint, client }: any) => {
  const { getFieldValue, getFieldsValue } = form
  const [dynamicFields, setDynamicFields] = useState<BookingTypeDynamicField[]>([])

  const { loading } = useQuery(VIEW_BOOKING_TYPE_GQL, {
    variables: { code: getFieldValue('type') },
    client,
    fetchPolicy: 'cache-first',
    onCompleted: (data) => {
      const fields = get(data, 'bookingType.dynamicFields', [])

      const parsedFields = fields.map(({ key, type, ...res }: any) => {
        return {
          ...res,
          label: startCase(key),
          key: getDynamicFieldKey(key, type),
          type
        }
      })

      setDynamicFields(parsedFields)
    }
  })

  return (
    <>
      <Divider>Details</Divider>

      <Row>
        <Col span={12}>
          <BillingCustomerFormItem value={getFieldValue('billToUuid')} />
          <BookingType value={getFieldValue('type')} />
        </Col>

        <Col span={12}>
          {dynamicFields.map((field) => {
            return (
              // @ts-ignore
              <Form.Item
                key={field.key}
                // @ts-ignore
                label={startCase(field.label)}
                {...formItemLayout}
              >
                {formatArrayString(getFieldValue(field.key), field, client, getFieldsValue())}
              </Form.Item>
            )
          })}
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <JobsTable form={form} step={step} isMultiPoint={isMultiPoint} />
        </Col>
      </Row>
    </>
  )
}

export default withApollo(ConfirmationSubForm)
