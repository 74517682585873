import { gql } from '@apollo/client'

const addressDetails = gql`
  fragment AddressDetails on Address {
    uuid
    name
    type
    address1
    address2
    address3
    address4
    city
    district
    postCode
    phone
    fax
    status
    countryAlpha3
  }
`

export const fragments = {
  addressDetails
}
