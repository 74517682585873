export default {
  impDocPre: {
    text: 'Documentation Pre',
    icon: 'file-alt'
  },
  impLinerPre: {
    text: 'Liner Pre',
    icon: 'anchor'
  },
  impLinerVoucher: {
    text: 'Liner Payment',
    icon: 'anchor'
  },
  impLinerPost: {
    text: 'Liner Post',
    icon: 'anchor'
  },
  impFreightPre: {
    text: 'Freight Pre',
    icon: 'ship'
  },
  impFreightPost: {
    text: 'Freight Post',
    icon: 'ship'
  },
  impCustPre: {
    text: 'Custom Pre',
    icon: 'chalkboard-teacher'
  },
  impCustNotify: {
    text: 'Custom Notify',
    icon: 'chalkboard-teacher'
  },
  impCustSubmit: {
    text: 'Custom Submit',
    icon: 'chalkboard-teacher'
  },
  impCustVoucher: {
    text: 'Custom Voucher',
    icon: 'chalkboard-teacher'
  },
  impCustPost: {
    text: 'Custom Post',
    icon: 'chalkboard-teacher'
  },
  impHlgPre: {
    text: 'Haulage/Transport Pre',
    icon: 'truck'
  },
  impHlgPost: {
    text: 'Haulage/Transport Post',
    icon: 'truck'
  },
  impCompApPayment: {
    text: 'Payment',
    icon: 'money-bill'
  },
  impCompArInvoice: {
    text: 'Invoice',
    icon: 'file-invoice-dollar'
  },
  impCompPre: {
    text: 'Completion Pre',
    icon: 'flag-checkered'
  },
  impCompPayment: {
    text: 'Completion Vendor Invoice Entry',
    icon: 'flag-checkered'
  },
  impCompInvoice: {
    text: 'Completion Customer Invoice Entry',
    icon: 'flag-checkered'
  },
  impCompPost: {
    text: 'Completion Post',
    icon: 'flag-checkered'
  },
  expDocPre: {
    text: 'Documentation Pre',
    icon: 'file-alt'
  },
  expHlgPre: {
    text: 'Haulage/Transport Pre',
    icon: 'truck'
  },
  expHlgPost: {
    text: 'Haulage/Transport Post',
    icon: 'truck'
  },
  expLinerPre: {
    text: 'Liner Pre',
    icon: 'anchor'
  },
  expLinerSubmit: {
    text: 'Liner Submit',
    icon: 'anchor'
  },
  expLinerVoucher: {
    text: 'Liner Voucher',
    icon: 'anchor'
  },
  expLinerPost: {
    text: 'Liner Post',
    icon: 'anchor'
  },
  expFreightPre: {
    text: 'Freight Pre',
    icon: 'ship'
  },
  expFreightPost: {
    text: 'Freight Post',
    icon: 'ship'
  },
  expCustPre: {
    text: 'Custom Pre',
    icon: 'chalkboard-teacher'
  },
  expCustNotify: {
    text: 'Custom Notify',
    icon: 'chalkboard-teacher'
  },
  expCustSubmit: {
    text: 'Custom Submit',
    icon: 'chalkboard-teacher'
  },
  expCustVoucher: {
    text: 'Custom Voucher',
    icon: 'chalkboard-teacher'
  },
  expCustPost: {
    text: 'Custom Post',
    icon: 'chalkboard-teacher'
  },
  expCompApPayment: {
    text: 'Payment',
    icon: 'money-bill'
  },
  expCompArInvoice: {
    text: 'Invoice',
    icon: 'file-invoice-dollar'
  },
  expCompPayment: {
    text: 'Completion Vendor Invoice Entry',
    icon: 'flag-checkered'
  },
  expCompInvoice: {
    text: 'Invoice',
    icon: 'flag-checkered'
  },
  expCompPost: {
    text: 'Completion Post',
    icon: 'flag-checkered'
  },
  transDocPre: {
    text: 'Documentation',
    icon: 'file-alt'
  },
  transHlgPre: {
    text: 'Transport',
    icon: 'truck'
  },
  transCompApPayment: {
    text: 'Payment',
    icon: 'money-bill'
  },
  transHlgPost: {
    text: 'Transport',
    icon: 'truck'
  },
  transCompArInvoice: {
    text: 'Invoice',
    icon: 'file-invoice-dollar'
  },
  transCompPost: {
    text: 'Completion',
    icon: 'flag-checkered'
  },
  linerEstimatedTimeOfArrival: 'Liner ETA',
  electronicDeliveryOrder: 'EDO Clearance',
  impCustRelease: 'Custom Release'
}
