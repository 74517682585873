import { useState } from 'react'
import styled from 'styled-components'

import PortSelector from 'App/components/Booking/DynamicField/PortSelector'
import CountrySelect from 'App/components/Select/TypeToFetch/CountrySelect'

import { FormMode } from 'App/components/Manage/Shared/CrudType/Form'

interface Props {
  value?: any
  onChange?: any
  mode?: FormMode
}

const PortPlaceSelectorWrapper = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
`

const PortPlaceSelector = (props: Props) => {
  const [countryAlpha3, setCountryAlpha3] = useState<string>('')

  return (
    <PortPlaceSelectorWrapper>
      <CountrySelect value={countryAlpha3} onChange={setCountryAlpha3} useAlpha3={true} />

      {/* @ts-ignore */}
      <PortSelector
        disabled={!countryAlpha3}
        countryAlpha3={countryAlpha3}
        setCountryAlpha3={setCountryAlpha3}
        {...props}
      />
    </PortPlaceSelectorWrapper>
  )
}

export default PortPlaceSelector
