import { gql } from '@apollo/client'

// import voucherItemDetails from '../fragment/voucherItem'

export default gql`
  mutation addVoucherItemSlim1($input: AddVoucherItemInput!) {
    addVoucherItem(input: $input) {
      uuid
      description
      unit
      size
      quantity
      isDeleted
      currency {
        uuid
        code
        name
      }
      baseRate
      exchangeRate
      localExchangeRate
      rate
      tax {
        uuid
        name
        code
        percentage
        #   company {
        #     uuid
        #     name
        #     types
        #     code
        #     tags
        #     debtorCode
        #     creditorCode
        #     status
        #     countryAlpha3
        #     description
        #     addresses {
        #       uuid
        #       name
        #       type
        #       address1
        #       address2
        #       address3
        #       address4
        #       city
        #       district
        #       postCode
        #       phone
        #       fax
        #       status
        #       location {
        #         type
        #         coordinates
        #       }
        #       countryAlpha3
        #       tags
        #     }
        #     contacts {
        #       uuid
        #       name
        #       email
        #       phone
        #     }
        #     currency {
        #       uuid
        #       code
        #       name
        #     }
        #   }
      }
      job {
        bookingUuid
        uuid
        no
        jobNo
        #   status
        #   remarks
        #   details
        #   reference {
        #     haulage
        #     transport
        #     shipper
        #     consignee
        #     forwardingOri
        #     forwardingDest
        #   }
        #   impRequestOfTransportDate
        #   impReadyForCollectionDate
        #   expRequestOfTransportDate
        #   expReadyForCollectionDate
      }
      taxPercentage
      taxTotal
      subTotal
      total
      bookingUuid
      costItem {
        uuid
        bookingUuid
        description
        quantity
        #   size
        #   unit
        #   quantity
        #   sellRate
        #   costRate
        chargeItem {
          uuid
          code
          name
          description
          #     sequence
          #     unit
          #     sellRate
          #     costRate
          #     status
          #     rateType
          #     term
          #     taxes {
          #       uuid
          #       type
          #       rate
          #       rateType
          #       tax {
          #         uuid
          #         name
          #         code
          #         percentage
          #         company {
          #           uuid
          #           name
          #           types
          #           code
          #           tags
          #           debtorCode
          #           creditorCode
          #           status
          #           countryAlpha3
          #           description
          #           addresses {
          #             uuid
          #             name
          #             type
          #             address1
          #             address2
          #             address3
          #             address4
          #             city
          #             district
          #             postCode
          #             phone
          #             fax
          #             status
          #             location {
          #               type
          #               coordinates
          #             }
          #             countryAlpha3
          #             tags
          #           }
          #           contacts {
          #             uuid
          #             name
          #             email
          #             phone
          #           }
          #           currency {
          #             uuid
          #             code
          #             name
          #           }
          #         }
          #       }
          #     }
          sellTax {
            uuid
            name
            code
            percentage
          #       company {
          #         uuid
          #         name
          #         types
          #         code
          #         tags
          #         debtorCode
          #         creditorCode
          #         status
          #         countryAlpha3
          #         description
          #         addresses {
          #           uuid
          #           name
          #           type
          #           address1
          #           address2
          #           address3
          #           address4
          #           city
          #           district
          #           postCode
          #           phone
          #           fax
          #           status
          #           location {
          #             type
          #             coordinates
          #           }
          #           countryAlpha3
          #           tags
          #         }
          #         contacts {
          #           uuid
          #           name
          #           email
          #           phone
          #         }
          #         currency {
          #           uuid
          #           code
          #           name
          #         }
          #       }
          }
          costTax {
            uuid
            name
            code
            percentage
          #       company {
          #         uuid
          #         name
          #         types
          #         code
          #         tags
          #         debtorCode
          #         creditorCode
          #         status
          #         countryAlpha3
          #         description
          #         addresses {
          #           uuid
          #           name
          #           type
          #           address1
          #           address2
          #           address3
          #           address4
          #           city
          #           district
          #           postCode
          #           phone
          #           fax
          #           status
          #           location {
          #             type
          #             coordinates
          #           }
          #           countryAlpha3
          #           tags
          #         }
          #         contacts {
          #           uuid
          #           name
          #           email
          #           phone
          #         }
          #         currency {
          #           uuid
          #           code
          #           name
          #         }
          #       }
          }
          #     sellCurrency {
          #       uuid
          #       code
          #       name
          #     }
          #     costCurrency {
          #       uuid
          #       code
          #       name
          #     }
          #     categories {
          #       uuid
          #       code
          #       description
          #       sequence
          #     }
          #     revenueCode {
          #       uuid
          #       code
          #       company {
          #         uuid
          #         name
          #         types
          #         code
          #         tags
          #         debtorCode
          #         creditorCode
          #         status
          #         countryAlpha3
          #         description
          #         addresses {
          #           uuid
          #           name
          #           type
          #           address1
          #           address2
          #           address3
          #           address4
          #           city
          #           district
          #           postCode
          #           phone
          #           fax
          #           status
          #           location {
          #             type
          #             coordinates
          #           }
          #           countryAlpha3
          #           tags
          #         }
          #         contacts {
          #           uuid
          #           name
          #           email
          #           phone
          #         }
          #         currency {
          #           uuid
          #           code
          #           name
          #         }
          #       }
          #     }
          #     expenseCode {
          #       uuid
          #       code
          #       company {
          #         uuid
          #         name
          #         types
          #         code
          #         tags
          #         debtorCode
          #         creditorCode
          #         status
          #         countryAlpha3
          #         description
          #         addresses {
          #           uuid
          #           name
          #           type
          #           address1
          #           address2
          #           address3
          #           address4
          #           city
          #           district
          #           postCode
          #           phone
          #           fax
          #           status
          #           location {
          #             type
          #             coordinates
          #           }
          #           countryAlpha3
          #           tags
          #         }
          #         contacts {
          #           uuid
          #           name
          #           email
          #           phone
          #         }
          #         currency {
          #           uuid
          #           code
          #           name
          #         }
          #       }
          #     }
        }
        #   sellBaseRate
        #   sellRate
        #   sellTotal
        #   sellRate
        #   sellBaseRate
        #   sellExchangeRate
        #   sellCurrency {
        #     uuid
        #     code
        #     name
        #   }
        #   costBaseRate
        #   costRate
        #   costTotal
        #   costBaseRate
        #   costRate
        #   costExchangeRate
        #   costCurrency {
        #     uuid
        #     code
        #     name
        #   }
        #   accountPayable
        #   accountPayableDraft
        #   accountReceivable
        #   accountReceivableDraft
        #   cashBook
        #   blankCheque
        #   accrual
        #   estimatedProfit
        #   shortBill
        #   grossProfit
        #   isDeleted
        #   errors {
        #     msg
        #     field
        #     urgency
        #     expectedValue
        #     receivedValue
        #   }
      }
      voucherCn {
        uuid
        voucherNumber
      }
      sorting
    }
  }
`
