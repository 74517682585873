import { gql } from '@apollo/client'

export default gql`
  query integrationDetails {
    integrationDetails(type: "haulage") {
      rows {
        uuid
        company {
          name
        }
        meta
        integration {
          uuid
          name
          type
        }
      }
    }
  }
`
