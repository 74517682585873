import React, { PureComponent } from 'react'
import { gql } from '@apollo/client'
import pick from 'lodash/pick'
import uuidv4 from 'uuid/v4'
import { withRouter } from 'App/RouterHOC'
import { Mutation } from 'react-apollo'
import { compose } from 'recompose'
import { Form, notification } from 'antd'

import { PageView, ViewContent, Inputs } from '../Styled'
import PageHeader from '../PageHeader'
import InputForm from './Form'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'

class CreateYard extends PureComponent {
  handleSubmit = (e, createYard) => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return
      }

      try {
        values.address = JSON.parse(values.address)

        const requestValues = {
          ...pick(values, ['code', 'name', 'remarks', 'address', 'status']),
          uuid: uuidv4()
        }

        await createYard({
          variables: {
            input: requestValues
          }
        })
      } catch (error) {
        logger.error('CreateYard error', error)
        respHandler(error, 'error')
      }
    })
  }

  handleError = (error) => {
    notification.error({
      message: 'Error',
      description: error.message.replace('GraphQL error: ', '')
    })
  }

  handleCompleted = (data) => {
    notification.success({
      message: 'Submit Notice',
      description: 'Yard has been created successfully.'
    })

    this.props.router.push('/manage/yards')
  }

  render() {
    const { form } = this.props

    return (
      <PageView>
        <PageHeader title="Create Yard" subtitle="Please fill in all required fields." />
        <ViewContent>
          <Mutation
            mutation={yardCreate}
            onError={this.handleError}
            onCompleted={this.handleCompleted}
          >
            {(createYard, { loading, error }) => (
              <Inputs onSubmit={(e) => this.handleSubmit(e, createYard)}>
                <InputForm
                  item={{}}
                  loading={loading}
                  submitText="Submit"
                  onDelete={this.handleDelete}
                  form={form}
                />
              </Inputs>
            )}
          </Mutation>
        </ViewContent>
      </PageView>
    )
  }
}

export const yardCreate = gql`
  mutation createYard1($input: CreateYardInput!) {
    createYard(input: $input) {
      uuid
    }
  }
`

export default compose(Form.create(), withRouter)(CreateYard)
