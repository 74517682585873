import find from 'lodash/find'
import isArray from 'lodash/isArray'
import moment from 'moment'
import uniq from 'lodash/uniq'
import get from 'lodash/get'
import set from 'lodash/set'

import { formatVehicle } from 'App/components/Transport/Components/VehicleSelector'
import { getActTime, getLocalTimeString } from 'App/components/RelativeDateTime'
import { getDriverName } from '../Planning/Jobs/helpers/getTableAccessors'
import { formatCurrencyAmount } from 'App/utils/voucher'
import { transTripTypes } from 'App/utils/labelMap'
import Enums from 'App/types/enums'
import {
  TransportReportJob,
  TransportActivity,
  TransportJob,
  TripTimings,
  LegStatus,
  Address, Incentive
} from 'App/types/types'
import { isNil } from 'lodash'
import { GroupingMethod } from 'App/components/Transport/Components/Monitoring/JobStatus/maps'
import { getGroupingLookupKey } from 'App/components/Transport/Components/Monitoring/JobStatus/jobStatusExportHelper'
import { printStringOrArray } from 'App/utils/u'

export const getJobNo = (job: TransportJob) => {
  return job?.no || job?.bookingNo || ''
}

export type TransportJobStatusType =
  | 'PENDING'
  | 'PLANNED'
  | 'STARTED'
  | 'AT_PICKUP'
  | 'ENROUTE'
  | 'STAGED'
  | 'AT_DROPOFF'
  | 'COMPLETED'
  | 'CANCELLED'
  | 'DELETED'
  | 'MT'
  | 'LATE'

export type TransportJobEmptyTrip = TransportJob & { isEmptyTrip?: boolean }

export const getStatusFromJob = (job: TransportJobEmptyTrip): TransportJobStatusType => {
  if (!job) return 'COMPLETED'

  //@ts-ignore
  const statuses = job.statuses ? job.statuses.filter((s) => s?.compile !== 'TRANS_BOOKED') : []

  const lastStatus = statuses && statuses[statuses.length - 1]

  if (job.isEmptyTrip) return 'MT'

  if (!lastStatus) return 'PLANNED'

  if (lastStatus.compile === 'COMPLETED') {
    return 'COMPLETED'
  }

  if (lastStatus.compile === 'STAGED') {
    return 'STAGED'
  }

  // @ts-ignore
  const plan: TransportActivity = find(
    statuses,
    (status: TransportActivity) => status.compile === 'PLANNED'
  )
  if (!plan || moment().subtract(10, 'minutes').isAfter(new Date(plan.ts))) {
    return 'LATE'
  } else {
    return 'STARTED'
  }
}

export const colorRangeLookup = {
  MT: '#ffc87c',
  PLANNED: '#8fbaff',
  STARTED: '#b6fcc3',
  STAGED: '#525252',
  COMPLETED: '#999999',
  LATE: '#ffb8b8',
  ENROUTE: '#489C3C'
}

export const getColorFromJobStatus = (jobStatus: TransportJobStatusType): string => {
  // @ts-ignore
  return colorRangeLookup[jobStatus]
}

export const isValidContainerNumber = (jobNo: string) => {
  let t
  let sum = 0

  if (!jobNo) return true // empty is valid container for imports
  jobNo = jobNo.replace(' ', '')
  if (jobNo.length !== 11) return false

  for (let i = 0; i < 10; i++) {
    if (/[A-Z]/.test(jobNo[i])) {
      // @ts-ignore
      t = Enums.Container.CharLookup[jobNo[i]]
    } else if (/[0-9]/.test(jobNo[i])) {
      t = jobNo[i]
    }
    sum += t * Math.pow(2, i)
  }

  return (sum % 11) % 10 === Number.parseInt(jobNo[10])
}

export const sanitizeToArray = (
  input: Array<string | null | undefined> | string | null | undefined
) => {
  if (!input) {
    return undefined
  }

  if (isArray(input)) {
    return input
  }

  if (input && !isArray(input)) {
    if (input.indexOf(',') >= 0) {
      return input?.split(',')
    }

    return [input]
  }
}

export const formatIncentivesVoucher = (
  job: TransportReportJob,
  country: string,
  currency: string,
  dateFormat?: boolean,
  isExport?: boolean
) => {
  const incentiveVoucherDate = job.incentiveVoucherDates
    ? job.incentiveVoucherDates
      ?.map((date) => (date ? getActTime(new Date(date), dateFormat) : ''))
      ?.join(', ') || '-'
    : '-'
  const incentiveVoucherAmount = formatCurrencyAmount(job.incentiveVoucherTotal, country, currency)

  return {
    shiftNos: job.shiftNos?.join(', ') || '-',
    incentiveVoucherNo: job.incentiveVoucherNos?.join(', ') || '-',
    incentiveVoucherDate,
    incentiveVoucherAmount: isExport ? job.incentiveVoucherTotal : incentiveVoucherAmount,
    incentiveVoucherStatus: job.incentiveVoucherStatuses?.join(', ') || '-'
  }
}

export const getIncentiveTypes = (job: any): Record<string, any> => {
  const latestIncentives =
    job.incentives?.sort(
      (a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime()
    ) || []

  return latestIncentives.reduce((obj: any, incentive: Incentive, i) => {
    const lookup = `${incentive.type?.code}[${incentive.type?.name}]` || incentive.typeUuid

    if (i === 0) {
      // insert latest incentive value
      obj[getGroupingLookupKey(GroupingMethod.LATEST_VALUE, lookup)] = incentive.amount || 0
    }

    if (isNil(obj[lookup])) {
      obj[lookup] = incentive.amount || 0
      return obj
    }

    obj[lookup] += incentive?.amount || 0

    return obj
  }, {})
}

export const formatCostItems = (
  job: TransportReportJob,
  country: string,
  currency: string,
  isExport?: boolean
) => {
  const voucherCurrency = job.voucherCurrencies?.[0] || '-'
  const costItemSellTotal = job.costItemSellTotal
    ? `${voucherCurrency} ${job.costItemSellTotal}`
    : '-'
  const costItemARTotal = job.costItemARTotal ? `${voucherCurrency} ${job.costItemARTotal}` : '-'
  const costItemARDraftTotal = job.costItemARDraftTotal
    ? `${voucherCurrency} ${job.costItemARDraftTotal}`
    : '-'
  const costItemAPTotal = job.costItemAPTotal ? `${voucherCurrency} ${job.costItemAPTotal}` : '-'
  const costItemAPDraftTotal = job.costItemAPDraftTotal
    ? `${voucherCurrency} ${job.costItemAPDraftTotal}`
    : '-'

  return {
    costItemSellTotal: isExport ? job.costItemSellTotal : costItemSellTotal,
    costItemARTotal: isExport ? job.costItemARTotal : costItemARTotal,
    costItemARDraftTotal: isExport ? job.costItemARDraftTotal : costItemARDraftTotal,
    costItemAPTotal: isExport ? job.costItemAPTotal : costItemAPTotal,
    costItemAPDraftTotal: isExport ? job.costItemAPDraftTotal : costItemAPDraftTotal
  }
}

export const formatVouchers = (
  job: any,
  country: string,
  currency: string,
  dateFormat?: boolean,
  isExport?: boolean
) => {
  const voucherAccDate =
    job.voucherAccountDates
      ?.map((date: string) => getActTime(new Date(date), dateFormat))
      ?.join(', ') || '-'
  const voucherIssueDate =
    job.voucherIssueDates
      ?.map((date: string) => getActTime(new Date(date), dateFormat))
      ?.join(', ') || '-'
  // job.voucherCurrencies !== selectedGlobalCompany?.company?.currency?.code
  const voucherCurrency = job.voucherCurrencies?.[0] || '-'
  const voucherArTotal = job.voucherArTotal ? `${voucherCurrency} ${job.voucherArTotal}` : '-'
  const voucherApTotal = job.voucherApTotal ? `${voucherCurrency} ${job.voucherApTotal}` : '-'
  return {
    voucherNo: job.voucherNos?.join(', ') || '-',
    arVoucherNo: job.voucherNosAr?.join(', ') || '-',
    apVoucherNo: job.voucherNosAp?.join(', ') || '-',
    voucherAccDate,
    voucherIssueDate,
    voucherCurrency,
    voucherArTotal: isExport ? job.voucherArTotal : voucherArTotal,
    voucherApTotal: isExport ? job.voucherApTotal : voucherApTotal,
    voucherStatus: job.voucherStatuses?.join(', ') || '-'
  }
}

export const formatLegTimings = (leg: TransportJob) => {
  return {
    planStart: leg?.planStart ? getActTime(leg.planStart) : '-',
    start: leg.start ? getActTime(leg.start) : '-',
    startOut: leg.startOut ? getActTime(leg.startOut) : '-',
    planEnd: leg.planEnd ? getActTime(leg.planEnd) : '-',
    end: leg.end ? getActTime(leg.end) : '-',
    endOut: leg.endOut ? getActTime(leg.endOut) : '-',
    transportSource: leg.transportSource || '-',
    remarks: leg.remarks || '-'
  }
}

export const formatFirstLeg = (legs: [TransportJob], dateFormat?: boolean) => {
  const firstLeg = legs?.[0]
  const firstLegPlan = firstLeg?.planStart ? getActTime(firstLeg.planStart, dateFormat) : '-'
  const firstLegStart = firstLeg?.startOut ? getActTime(firstLeg.startOut, dateFormat) : '-'
  return { firstLegPlan, firstLegStart }
}

export const formatFirstLegPlanning = (trip: any) => {
  const firstLeg = trip.legs?.[0]
  const firstLegPlan = firstLeg?.planStart ? getActTime(new Date(firstLeg.planStart)) : '-'
  const firstLegStart = firstLeg?.startOut
    ? getActTime(new Date(firstLeg.startOut))
    : firstLeg?.start
      ? { showIcon: true, time: getActTime(firstLeg.start) }
      : '-'
  return { firstLegPlan, firstLegStart }
}

export const formatLastLeg = (legs: [TransportJob], dateFormat?: boolean) => {
  const legNoEndOut = legs.find((leg: any) => !leg.endOut && leg.planStart)
  const lastLeg = legs[legs?.length - 1]
  const lastLegEnd = lastLeg.endOut ? getActTime(lastLeg?.endOut, dateFormat) : '-'
  const transportSource = legNoEndOut ? legNoEndOut.transportSource : lastLeg.transportSource
  return { lastLegEnd, transportSource }
}

export const formatLastLegPlanning = (trip: any) => {
  const legNoEndOut = trip.legs?.find((leg: any) => !leg.endOut && leg.planStart)
  const lastLeg = trip.legs?.[trip.legs?.length - 1]
  const lastLegEnd = lastLeg?.endOut
    ? getActTime(new Date(lastLeg?.endOut))
    : lastLeg?.end
      ? { showIcon: true, time: getActTime(lastLeg?.end) }
      : '-'

  const transportSource = legNoEndOut
    ? legNoEndOut.transportSource
    : lastLeg?.transportSource || '-'

  const legRemarks =
    trip.legs
      ?.map((leg: any) => leg.remarks)
      ?.filter((item: string) => item)
      ?.join(', ') || '-'

  return { lastLegEnd, transportSource, legRemarks }
}

export const formatAppendLegs = (legs: any, dateFormat?: boolean) => {
  let appendLegs: any[] = []

  for (let index = 0; index < legs.length; index++) {
    const leg = {
      driver: legs[index].driverName,
      vehicle: legs[index].vehicleName,
      trailer: legs[index].trailerName,
      planStart: getActTime(legs[index].planStart, dateFormat),
      planEnd: getActTime(legs[index].planEnd, dateFormat),
      start: getActTime(legs[index].start, dateFormat),
      startOut: getActTime(legs[index].startOut, dateFormat),
      end: getActTime(legs[index].end, dateFormat),
      endOut: getActTime(legs[index].endOut, dateFormat)
    }
    appendLegs = [appendLegs, leg].filter((item: any) => !(isArray(item) && !item.length))
  }
  return JSON.stringify(appendLegs)
}

export const formatUniqueData = (legs: any, vehicleCodeOrReg: string) => {
  if (vehicleCodeOrReg === 'trailer') {
    const trailers = legs?.map((leg: any) => leg.trailerName || leg.trailerEntity?.code)
    const uniqTrailers = uniq(trailers?.filter((data: string) => data))?.join(', ')
    return uniqTrailers || '-'
  } else if (vehicleCodeOrReg === 'driver') {
    const drivers = legs?.map((leg: any) => getDriverName(leg))
    const uniqDrivers = uniq(drivers?.filter((data: string) => data))?.join(', ')
    return uniqDrivers
  } else {
    const vehicles = legs?.map((leg: any) => formatVehicle(leg, vehicleCodeOrReg))
    const uniqVehicles = uniq(vehicles?.filter((data: string) => data))?.join(', ')
    return uniqVehicles
  }
}

export const formatDrivers = (legs: any) => {
  const drivers = legs?.map((leg: any) => getDriverName(leg))
  const uniqDrivers = uniq(drivers?.filter((data: string) => data))?.join(', ')
  return uniqDrivers || '-'
}

export const formatVehicles = (legs: any, vehicleCodeOrReg: string) => {
  // vehicleCodeOrReg is undefined if the user hasn't set this user preference
  const vehicles = legs?.map((leg: any) => formatVehicle(leg, vehicleCodeOrReg))
  const uniqVehicles = uniq(vehicles?.filter((data: string) => data))?.join(', ')
  return uniqVehicles || '-'
}

export const getBookingInfo = (leg: any, dateFormat?: boolean) => {
  const transportTypeSize = [leg.transportType, leg.size]?.filter((item: string | number) => item)
  const departments = leg.bookingDepts?.join(', ') || '-'
  const tags = leg.bookingTags?.join(', ') || '-'

  return {
    bookingNo: leg.bookingNo,
    bookingDateTimeCreated: getActTime(leg.dateCreated, dateFormat),
    no: leg.no,
    jobNo: leg.jobNo || '-',
    shipperRequiredDateTime: getActTime(leg.shipperRequiredDate, dateFormat),
    shipperRequiredDate: leg.shipperRequiredDate
      ? moment(leg.shipperRequiredDate).format('YYYY/MM/DD')
      : '-',
    shipperRequiredTime: getLocalTimeString(leg.shipperRequiredDate),
    consigneeRequiredDate: getActTime(leg.consigneeRequiredDate, dateFormat),
    shipperRef: leg.shipperRef || '-',
    references: leg.references?.join(', ') || '-',
    departments,
    tags,
    billTo: leg.billToName || '-',
    billToSource: leg.billToSource || '-',
    shipper: leg.shipperName,
    consignee: leg.consigneeName,
    origin: leg.from?.areaCode || leg.shipperAddressCache?.areaCode || '-',
    destination: leg.to?.areaCode || leg.consigneeAddressCache?.areaCode || '-',
    shipperZone: leg.from?.zone || leg.shipperAddressCache?.zone || '-',
    consigneeZone: leg.to?.zone || leg.consigneeAddressCache?.zone || '-',
    containerType: leg.containerType || '-',
    size: transportTypeSize?.join('-') || '-',
    bookingStatus: leg.bookingStatus,
    jobType: leg.jobType || '-',
    jobStatus: leg.jobStatus || '-',
    bookingDetails: JSON.stringify(leg.bookingDetails) || '-',
    // The two fields below should be moved to a dedicated getInfo function
    // if one is ever created. Since these are small additions to job and trip,
    // leave them here.
    jobDetails: JSON.stringify(leg.jobDetails) || '-',
    tripDetails: JSON.stringify(leg.tripDetails) || '-'
  }
}

export const getBkDetailsForPlanning = (leg: any, query?: any) => {
  // @ts-ignore
  const hasZones = leg.from?.zone && leg.to?.zone
  const fromToZone = hasZones ? leg.from?.zone + ' - ' + leg.to?.zone : '-'
  const hasAreaCodes = leg.from?.areaCode && leg.to?.areaCode
  const fromToAreaCodes = hasAreaCodes ? leg.from?.areaCode + ' - ' + leg.to?.areaCode : '-'
  const department = printStringOrArray(leg.bookingDetails?.departments || leg.bookingDetails?.dept)
  const tag = printStringOrArray(leg.bookingDetails?.tags)
  const fromAddress = mergeAddressFull(leg?.from)
  const toAddress = mergeAddressFull(leg?.to)

  return {
    bookingDetails: {
      ...leg.bookingDetails,
      departments: department || ['UNKNOWN'],
      tags: tag || ['UNKNOWN']
    },
    shipperDept: leg.shipDept || leg.dept || '-',
    consigneeDept: leg.conDept || leg.dept || '-',
    MT: leg.isEmptyTrip ? 'MT' : 'LADEN',
    transportType: leg.transportType,
    containerType: leg.containerType,
    tripStatus: (leg.isEmptyTrip ? leg.status : leg.tripStatus) || '-',
    legStatus: [leg.sequence, leg.legStatus]?.filter(Boolean)?.join('-') || '-',
    fromToZone,
    fromToAreaCodes,
    department,
    tag,
    fromAddress,
    toAddress,
    fromName: leg.from?.name || '-',
    toName: leg.to?.name || '-',
    // fromName: (leg.shipperName || '') + '\n' + (leg.from?.name || '-'),
    // toName: (leg.consigneeName || '') + '\n' + (leg.to?.name || '-'),
    VDJMStartDate: query?.shipperRequiredDateStart || leg.start || leg.shipperRequiredDate,
    VDJMEndDate:
      query?.shipperRequiredDateEnd ||
      leg.endOut ||
      moment(leg.shipperRequiredDate).add(1, 'day').toDate()
  }
}

export const formatJobDistanceTime = (job: TransportReportJob, isExport?: boolean) => {
  const estimatedDistanceKm = job.estimatedDistanceKm ? `${job.estimatedDistanceKm} km` : '-'
  const actualDistanceKm = job.actualDistanceKm ? `${job.actualDistanceKm} km` : '-'
  const estimatedTravelTimeMins = job.estimatedTravelTimeMins
    ? `${job.estimatedTravelTimeMins} mins`
    : '-'
  const actualTravelTimeMins = job.actualTravelTimeMins ? `${job.actualTravelTimeMins} mins` : '-'

  return {
    estimatedDistanceKm: isExport ? job.estimatedDistanceKm : estimatedDistanceKm,
    actualDistanceKm: isExport ? job.actualDistanceKm : actualDistanceKm,
    estimatedTravelTimeMins: isExport ? job.estimatedTravelTimeMins : estimatedTravelTimeMins,
    actualTravelTimeMins: isExport ? job.actualTravelTimeMins : actualTravelTimeMins
  }
}

export const formatReportJobTripTimings = (job: TransportReportJob, dateFormat?: boolean) => {
  const tripType =
    // @ts-ignore
    job?.tripsTimings?.map((timing: TripTimings) => transTripTypes[timing.tripType])?.join(', ') ||
    '-'
  const tripStatus =
    // @ts-ignore
    job?.tripsTimings?.map((timing: TripTimings) => timing.tripStatus)?.join(', ') || '-'
  const tripTimings = JSON.stringify(
    // @ts-ignore
    job?.tripsTimings?.map((timing: TripTimings) => {
      return {
        tripSequence: timing.tripSequence,
        firstLegStart: timing.firstLegStart && getActTime(timing.firstLegStart, dateFormat),
        firstLegStartOut: timing.firstLegStartOut && getActTime(timing.firstLegStart, dateFormat),
        timeAtShipperMins: timing.timeAtShipperMins,
        lastLegEnd: timing.lastLegEnd && getActTime(timing.firstLegStart, dateFormat),
        lastLegEndOut: timing.lastLegEndOut && getActTime(timing.firstLegStart, dateFormat),
        timeAtConsigneeMins: timing.timeAtConsigneeMins
      }
    })
  )

  return {
    tripTimings,
    tripType,
    tripStatus
  }
}

export const formatReportJobLegs = (job: TransportReportJob) => {
  return {
    // vehicleCode & driverCode are optional and so may be undefined for subcon
    vehicleDepts: job.vehicleDepts?.join(', ') || '-',
    PMs: job.vehicleNames?.join(', ') || '-',
    DRs: job.driverNames?.join(', ') || '-',
    trailers: job.trailerNames?.join(', ') || '-',
    transporters: job.transportNames?.join(', ') || '-',
    transportSource: job.transportSources?.join(', ') || '-',
    remarks: job.remarks?.join(', ') || '-'
  }
}

export const mergeAddressFull = (address: Address) => {
  if (!address) {
    return '-'
  }

  const splitAddress = [
    address.address1,
    address.address2,
    address.address3,
    address.address4,
    address.city,
    address.postCode,
    address.district,
    address.countryAlpha3
  ]
  return splitAddress.filter((item: string) => item)?.join(', ')
}

export const mergeAddressShort = (address: Address) => {
  if (!address) {
    return '-'
  }

  const splitAddress = [address.name, address.address1, address.areaCode, address.zone]
  return splitAddress.filter(Boolean)?.join(', ')
}

export const filterByBookingDetail = (
  filteredData: any,
  selectedDepts: Array<string>,
  option: string
) => {
  return filteredData
    ?.filter((d: any) => {
      const { bookingDetails } = d
      if (
        !bookingDetails ||
        !Array.isArray(bookingDetails[option]) ||
        !bookingDetails[option].length
      ) {
        return null
      }
      const items = d.bookingDetails?.[option]
        ?.map((item: string) => {
          if (selectedDepts.includes(item)) {
            return item
          }
          return null
        })
        ?.filter(Boolean)

      if (items?.length) {
        return d
      } else {
        return null
      }
    })
    ?.filter(Boolean)
}

export const isActiveLeg = (leg: TransportJob): boolean => {
  if (!leg?.legStatus) return false
  const validStatuses: LegStatus[] = Object.values(LegStatus).filter(
    (s: LegStatus) => s !== LegStatus.Cancelled && s !== LegStatus.Deleted
  )
  return validStatuses.includes(leg.legStatus)
}

const convertTypeMapper: any = {
  tripSummary: {
    toStr: 'to',
    fromStr: 'from'
  },
  transportJob: {
    toStr: 'to.zone',
    fromStr: 'from.zone'
  }
}

export const convertZones = (data: any, zonesMapper: any, dataType: string) => {
  if (!data) return
  if (!zonesMapper) return data

  const toStr = convertTypeMapper[dataType].toStr
  const fromStr = convertTypeMapper[dataType].fromStr

  const newData = { ...data }

  if (zonesMapper[get(newData, fromStr)]) {
    set(newData, fromStr, zonesMapper[get(newData, fromStr)])
  }
  if (zonesMapper[get(newData, toStr)]) {
    set(newData, toStr, zonesMapper[get(newData, toStr)])
  }

  return newData
}
