import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import { withHandlers } from 'recompose'
import findIndex from 'lodash/findIndex'
import { Table } from 'antd'

import * as voucherActions from 'App/states/reducers/voucher'
import { TableWrapper, NoItemsFound, SectionTitle } from '../Styled'
import { Brief } from 'App/components/Workflow/Styled'
import SummaryView from './SummaryView'

const enhance = compose(
  connect(
    (state, props) => ({
      showDeletedVoucherItems: state.voucher.showDeletedVoucherItems
    }),
    (dispatch) => ({
      dispatch,
      ...bindActionCreators(
        {
          ...voucherActions
        },
        dispatch
      )
    })
  ),
  withHandlers({
    toggleVoucherItemsView: (props) => async (e) => {
      if (e.target && e.target.value) {
        const { updateVoucherItemsView } = props

        updateVoucherItemsView(e.target.value)
      }
    },
    toggleVoucherItemsDeletedView: (props) => (e) => {
      const { showDeletedVoucherItems, updateVoucherItemsDeletedView } = props

      updateVoucherItemsDeletedView(!showDeletedVoucherItems)
    }
  })
)

class TableView extends Component {
  renderShowHideButton = () => {
    const { selectedVoucher, showDeletedVoucherItems, toggleVoucherItemsDeletedView } = this.props

    const { voucherItems = [] } = selectedVoucher

    const hasDeletedVoucherItem = findIndex(voucherItems, (vi) => vi.isDeleted) >= 0

    if (hasDeletedVoucherItem) {
      return (
        <Brief onClick={toggleVoucherItemsDeletedView}>
          {showDeletedVoucherItems ? 'Hide deleted' : 'Show deleted'}
        </Brief>
      )
    } else {
      return null
    }
  }

  render() {
    const { columns, dataSource, selectedVoucher } = this.props
    const voucherItems = selectedVoucher?.voucherItems || []

    if (!voucherItems.length) {
      return <NoItemsFound>No voucher items selected.</NoItemsFound>
    }

    return (
      <>
        <SectionTitle borderless>
          Selected Items
          {this.renderShowHideButton()}
        </SectionTitle>
        <TableWrapper>
          <Table
            pagination={false}
            locale={{
              emptyText: 'No items selected.'
            }}
            columns={columns}
            dataSource={dataSource}
          />
          <SummaryView selectedVoucher={selectedVoucher} />
        </TableWrapper>
      </>
    )
  }
}

export default enhance(TableView)
