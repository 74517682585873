import styled from 'styled-components'

import { Modal } from 'antd'

export const ModalViewer = styled(Modal)`
  animation-duration: 0s !important;
  position: absolute;
  top: 5px;
  left: 5px;
  right: 5px;
  bottom: 5px;
  padding-bottom: 0;
  width: auto !important;

  .ant-modal-content,
  .ant-modal-body {
    height: 100%;
    padding: 0;
  }

  .ant-modal-content {
    border-radius: 0;
  }
`
