import { memo, useCallback, useMemo } from 'react'
import { Dropdown, Icon, Menu, Typography } from 'antd'
import { compose, withState } from 'recompose'
import { useQuery, gql } from '@apollo/client'
import { bindActionCreators } from 'redux'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'

import Rollover from './Rollover'
import Link from 'App/components/Link'
import ChangeTypeAction from './ChangeTypeAction'
import { getPrintableLink } from 'App/components/Shared/documentCreator/openPrintableInNewTab'
import VoucherPdfAsyncDownloadAction from 'App/components/Voucher/Management/VoucherPdfAsyncDownloadAction'

import useGlobalCompanyStore from 'App/store/globalCompany'
import * as voucherActions from 'App/states/reducers/voucher'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'

const DOCUMENT_CREATOR_TEMPLATES = gql`
  query documentCreatorTemplates5($q: String, $statuses: [DocumentCreatorTemplateStatus]) {
    documentCreatorTemplates(q: $q, statuses: $statuses) {
      rows {
        uuid
        type
      }
    }
  }
`

const Selections = memo((props) => {
  const {
    client,
    selectedVoucher,
    loadRequiredData,
    bookingQuery,
    handleCreateCreditNote,
    fullBookingQuery,
    updateSelectedVoucher
  } = props

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const {
    data: dcTemplates,
    loading: dcTemplatesLoading,
    error: dcTemplatesError
  } = useQuery(DOCUMENT_CREATOR_TEMPLATES, {
    client,
    variables: { q: 'INVOICE', statuses: ['activated'] }
  })

  const { hasPermission } = usePermissionIsAllowed({
    client,
    resource: 'documentCreatorTemplate',
    permission: 'index'
  })

  const renderRollover = useCallback(() => {
    return (
      <Rollover
        bookingQuery={bookingQuery}
        selectedVoucher={selectedVoucher}
        loadRequiredData={loadRequiredData}
      />
    )
  }, [bookingQuery, selectedVoucher, loadRequiredData])

  const handleClick = useCallback(({ item, key }) => {
    if (key === 'creditNote') {
      handleCreateCreditNote()
    }
  }, [])

  const handleChangedVoucherType = useCallback(
    (value) => {
      if (!value) return

      if (fullBookingQuery) {
        fullBookingQuery.refetch()
      }

      updateSelectedVoucher({
        ...selectedVoucher,
        type: value
      })
    },
    [fullBookingQuery, selectedVoucher]
  )

  const status = useMemo(() => selectedVoucher?.status, [selectedVoucher])

  const menu = useMemo(
    () => (
      <Menu onClick={handleClick}>
        {status !== 'NEW' && (
          <Menu.Item key="view">
            <Link target="_blank" to={`/vouchers/${selectedVoucher?.uuid}/view`}>
              <Icon type="eye" style={{ marginRight: '8px' }} />
              View voucher
            </Link>
          </Menu.Item>
        )}
        {status !== 'NEW' && (
          <Menu.Item key="viewWithCostSheet">
            <Link target="_blank" to={`/vouchers/${selectedVoucher?.uuid}/view?withCostSheet=true`}>
              <Icon type="eye" style={{ marginRight: '8px' }} />
              View voucher with cost sheet
            </Link>
          </Menu.Item>
        )}
        {status !== 'NEW' && (
          <Menu.Item key="changeVoucherType">
            <ChangeTypeAction voucher={selectedVoucher} onChanged={handleChangedVoucherType} />
          </Menu.Item>
        )}
        {hasPermission &&
          status === 'APPROVED' &&
          selectedVoucher?.transactionType === 'ACCREC' &&
          dcTemplates?.documentCreatorTemplates?.rows.length &&
          dcTemplates?.documentCreatorTemplates?.rows
            .filter((t) =>
              selectedVoucher?.documentCreatorTemplate?.uuid
                ? t.uuid === selectedVoucher.documentCreatorTemplate.uuid
                : t
            )
            .map((t, i) => (
              <Menu.Item key={t.type + i}>
                <VoucherPdfAsyncDownloadAction
                  voucher={selectedVoucher}
                  type={t.type}
                  text={`${t.type} as PDF`}
                />
              </Menu.Item>
            ))}
        {hasPermission &&
          status === 'APPROVED' &&
          selectedVoucher?.transactionType === 'ACCREC' &&
          dcTemplates?.documentCreatorTemplates?.rows.length &&
          dcTemplates?.documentCreatorTemplates?.rows
            .filter((t) =>
              selectedVoucher?.documentCreatorTemplate?.uuid
                ? t.uuid === selectedVoucher.documentCreatorTemplate.uuid
                : t
            )
            .map((t, i) => (
              <Menu.Item key={t.type + i}>
                <a
                  target="_blank"
                  href={getPrintableLink(selectedVoucher.uuid, selectedGlobalCompany.uuid, t.type)}
                  rel="noreferrer"
                >
                  <Icon type="printer" style={{ marginRight: '8px' }} />
                  {`Print as ${t.type}`}
                </a>
              </Menu.Item>
            ))}
        {hasPermission &&
          status === 'APPROVED' &&
          selectedVoucher?.transactionType === 'ACCREC' &&
          !dcTemplates?.documentCreatorTemplates?.rows.length && (
            <Menu.Item key="downloadAsyncNoTemplates" disabled>
              <Typography.Text strong style={{ color: 'red' }}>
                No Document Creator Templates
              </Typography.Text>
            </Menu.Item>
          )}
        {dcTemplatesLoading && <Menu.Item key="downloadAsyncLoading">Loading...</Menu.Item>}
        {dcTemplatesError && hasPermission && (
          <Menu.Item key="downloadAsyncError">Error loading templates</Menu.Item>
        )}
        {(status === 'APPROVED' || status === 'PAID') && !selectedVoucher?.isCreditNote && (
          <Menu.Item key="creditNote">
            <Icon type="rollback" />
            Credit note
          </Menu.Item>
        )}
        {status !== 'NEW' && (
          <Menu.Item key="viewIntegrationLogs">
            <Link
              target="_blank"
              to={`/manage/integration-logs?voucherUuids=${selectedVoucher?.uuid}`}
            >
              <Icon type="database" style={{ marginRight: '8px' }} />
              Integration logs
            </Link>
          </Menu.Item>
        )}
        <Menu.Item key="logs">
          <Link
            target="_blank"
            to={`/manage/logs/voucher/?models=voucher&voucherUuid=${selectedVoucher?.uuid}`}
          >
            <Icon type="database" style={{ marginRight: '8px' }} />
            Voucher logs
          </Link>
        </Menu.Item>
      </Menu>
    ),
    [status, selectedVoucher, hasPermission, dcTemplates, dcTemplatesLoading, dcTemplatesError]
  )

  return (
    <>
      {renderRollover()}
      <Dropdown overlay={menu} trigger={['click']}>
        <Icon type="ellipsis" style={{ cursor: 'pointer' }} />
      </Dropdown>
    </>
  )
})

export default compose(
  withApollo,
  withState('showSelections', 'toggleSelections', false),
  connect(null, (dispatch) => ({
    dispatch,
    ...bindActionCreators(
      {
        ...voucherActions
      },
      dispatch
    )
  }))
)(Selections)
