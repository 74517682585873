import { useState } from 'react'
import { Button } from 'antd'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'

import respHandler from 'App/utils/responseHandler'
import { updateDocumentCreatorGql } from './updateDocumentCreatorGql'

const updateDocumentCreatorGraphql = graphql(updateDocumentCreatorGql, {
  props: ({ mutate, ownProps }) => ({
    updateDocumentCreator: (input) =>
      mutate({
        variables: {
          input
        }
      })
  })
})

const enhance = compose(updateDocumentCreatorGraphql)

const SaveButton = (props) => {
  const { documentCreator, form, updateDocumentCreator, onChange = () => {} } = props

  const [loading, setLoading] = useState(false)
  const handleClick = async () => {
    form.validateFields(async (err, values) => {
      if (err) return

      setLoading(true)

      try {
        const input = {
          uuid: documentCreator.uuid,
          ...values
        }

        await updateDocumentCreator(input)

        onChange()

        respHandler('Document creator is saved successfully.', 'success')
      } catch (error) {
        console.log(error)
        respHandler(error, 'error')
      }
      setLoading(false)
    })
  }

  return (
    <Button loading={loading} onClick={handleClick}>
      Save
    </Button>
  )
}

export default enhance(SaveButton)
