import { gql } from '@apollo/client'

const APPROVE_VOUCHER_SLIM_MUT = gql`
  mutation approveVoucherSlim($input: ApprovalInput!) {
    approve(input: $input) {
      uuid
      type
      status
      approvedBy {
        name
        uuid
        nickname
        familyName
        email
      }
      createdAt
    }
  }
`

export default APPROVE_VOUCHER_SLIM_MUT
