import { useTranslation } from 'react-i18next'
import { useEffect, useMemo } from 'react'
import { Icon, Table } from 'antd'
import { isArray } from 'lodash'

const AddPlanVehicleJobsTable = (props) => {
  const { t } = useTranslation()

  const { jobSearch, selectedJobs, setSelectedJobs, getJobs, data, pageObj, loading } = props

  useEffect(() => {
    getJobs()
  }, [getJobs, jobSearch])

  const tableRows = useMemo(() => {
    const dataRows = isArray(data) ? data : data?.transportJobs?.rows || []
    return dataRows.filter((row) => !selectedJobs?.find((job) => row.uuid === job.uuid))
  }, [data, selectedJobs])

  const columns = useMemo(() => [
    {
      title: (
        <span
          style={{ cursor: 'pointer' }}
          onClick={() => setSelectedJobs([...selectedJobs, ...tableRows])}
        >
          {t('transport.bulkUpdateLegsModal.addAll')}
        </span>
      ),
      key: 'addJob',
      render: (text: any, record: any) => (
        <span style={{ margin: '0', padding: '0', color: '#222222' }}>
          <Icon type="plus" onClick={() => setSelectedJobs([...selectedJobs, record])} />
        </span>
      )
    },
    {
      title: t('common.jobNo'),
      key: 'jobNo',
      dataIndex: 'jobNo',
      render: (jobNo: string) => (
        <span style={{ margin: '0', padding: '0', color: '#222222' }}>{jobNo}</span>
      )
    },
    {
      title: t('common.from'),
      key: 'from',
      render: (text: any, record: any) => (
        <span style={{ margin: '0', padding: '0', color: '#222222' }}>
          {record.from?.name || '-'}
        </span>
      )
    },
    {
      title: t('common.to'),
      key: 'to',
      render: (text: any, record: any) => (
        <span style={{ margin: '0', padding: '0', color: '#222222' }}>
          {record.to?.name || '-'}
        </span>
      )
    },
    {
      title: t('transport.bulkUpdateLegsModal.transportSource'),
      key: 'transportSource',
      render: (text: any, record: any) => (
        <span style={{ margin: '0', padding: '0', color: '#222222' }}>
          {record.transportSource || '-'}
        </span>
      )
    }
  ], [selectedJobs, setSelectedJobs, t, tableRows])

  return (
    <Table
      loading={loading}
      columns={columns}
      pagination={pageObj}
      dataSource={tableRows}
      rowKey={(row) => row.legUuid}
    />
  )
}

export default AddPlanVehicleJobsTable
