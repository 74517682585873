import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import config from 'App/config'

import auth, { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import webStorage from 'App/utils/webStorage'

let apolloClient = null

function create(initialState, headers) {
  const httpLink = new HttpLink({
    uri: config.gateway.graphqlUrl,
    opts: {
      credentials: 'same-origin'
      // Pass headers here if your graphql server requires them
    }
  })

  const middlewareLink = new ApolloLink((operation, forward) => {
    const currentHeaders = headers.headers

    if (auth.jwtIsExpiring()) {
      auth.useRefreshToken()
    }

    const token = auth.getToken(currentHeaders)
    const newHeaders = {
      authorization: token ? `JWT ${token}` : null
    }

    const router = headers.router

    if (router && router.query && router.query['access-token']) {
      newHeaders['jwt-client'] = router.query['access-token']
    }

    let globCompany = webStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_GLOBAL_COMPANY, currentHeaders)

    if (!globCompany) {
      // Default to selecting first baseCompany in JWT token.
      const profile = auth.getProfile(currentHeaders)

      if (profile?.baseCompanyUuids?.length) {
        globCompany = { uuid: profile.baseCompanyUuids[0] }
      } else {
        console.error(
          'Seems to be something wrong loading the profiles from you header.',
          currentHeaders,
          profile
        )
      }
    }

    newHeaders['base-company-uuid'] = globCompany?.uuid
    operation.setContext({ headers: newHeaders })

    return forward(operation)
  })

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    auth.handleLoggedOutError({ graphQLErrors })

    if (graphQLErrors && graphQLErrors[0]) {
      const { statusCode } = graphQLErrors[0]
      if (statusCode === 440) {
        // Router.push(`/auth/sign-off?statusCode=${statusCode}`)
      }
    }
  })

  const link = ApolloLink.from([middlewareLink, errorLink, httpLink])

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData: {
      __schema: {
        types: [
          {
            kind: 'UNION',
            name: 'events',
            possibleTypes: [
              { name: 'BookingEvent' },
              { name: 'ContainerEvent' },
              { name: 'MessageEvent' }
            ]
          }
        ]
      }
    }
  })

  return new ApolloClient({
    connectToDevTools: true,
    ssrMode: false,
    link,
    cache: new InMemoryCache({
      fragmentMatcher
    }).restore(initialState)
  })
}

export default function initApollo(initialState = {}, headers) {
  if (!apolloClient) {
    apolloClient = create(initialState, headers)
  }

  return apolloClient
}
