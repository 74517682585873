import find from 'lodash/find'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import { Popover, Button } from 'antd'
import { memo, useMemo, useCallback } from 'react'

import withVouchers from './Container'
import { Wrapper, Actions } from './Styled'
import TypeSelections from './TypeSelections'
import ViewSelections from './ViewSelections'
import { CategoryTitle, Brief } from '../Styled'
import useProcessPortalUser from 'App/hooks/useProcessPortalUser'

function Vouchers(props) {
  const {
    showPopover,
    voucherTypes,
    handleViewClick,
    viewableVouchers,
    togglePopoverView,
    handleCreateClick,
    showDeletedVouchers,
    toggleShowDeletedVouchers,
    section = { vouchers: [] }
  } = props

  const { isPortalUser } = useProcessPortalUser()

  const onToggleDeleted = useCallback(() => {
    toggleShowDeletedVouchers(!showDeletedVouchers)
  }, [showDeletedVouchers, toggleShowDeletedVouchers])

  const renderTypeSelections = useCallback(() => {
    return (
      <TypeSelections
        section={section}
        voucherTypes={voucherTypes}
        handleCreateClick={handleCreateClick}
      />
    )
  }, [section, voucherTypes, handleCreateClick])

  const renderAddBtn = useCallback(() => {
    if (isPortalUser) {
      return <></>
    }
    return (

      <Actions>
        <Popover
          title='Voucher Types'
          trigger='click'
          placement='right'
          content={renderTypeSelections()}
          visible={showPopover}
          onVisibleChange={(visible) => togglePopoverView(visible)}
        >
          <Button
            id='voucher-types-button'
            icon='plus'
            shape='circle'
          />
        </Popover>
      </Actions>
    )
  }, [isPortalUser, renderTypeSelections, showPopover, togglePopoverView])

  const deletedVoucher = useMemo(
    () => find(viewableVouchers, (voucher) => includes(['DELETED', 'VOIDED'], voucher.status)),
    [viewableVouchers]
  )

  const isAllowed = useMemo(() => section.vouchers?.length > 0, [section])

  const displayingVouchers = useMemo(
    () =>
      deletedVoucher && !showDeletedVouchers
        ? filter(viewableVouchers, (sv) => !includes(['DELETED', 'VOIDED'], sv.status))
        : viewableVouchers,
    [deletedVoucher, showDeletedVouchers, viewableVouchers]
  )

  return (
    <Wrapper>
      <CategoryTitle>
        Customer Invoice/Vendor Invoice Entry
        {deletedVoucher && (
          <Brief onClick={onToggleDeleted}>{showDeletedVouchers ? 'Hide' : 'Show'} Deleted</Brief>
        )}
        {isAllowed && renderAddBtn()}
      </CategoryTitle>

      <ViewSelections
        section={section}
        voucherTypes={voucherTypes}
        handleViewClick={handleViewClick}
        viewableVouchers={displayingVouchers}
      />
    </Wrapper>
  )
}

export default withVouchers(memo(Vouchers))
