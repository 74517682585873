
import { Icon, Tooltip } from 'antd'

const getIcon = (urgency) => {
  let result = null
  switch (urgency) {
    case 'ERROR': {
      result = <Icon type="exclamation-circle-o" style={{ color: 'red' }} />
      break
    }
    case 'WARN': {
      result = <Icon type="warning" style={{ color: 'orange' }} />
      break
    }
    case 'INFO': {
      result = <Icon type="info-circle-o" style={{ color: 'deepskyblue' }} />
      break
    }
    default:
  }

  return result
}

const Error = (props) => {
  const { error } = props

  if (!error) {
    return null
  }

  return (
    <Tooltip
      title={`${error.msg}. Expected ${error.expectedValue}, received ${error.recievedValue}.`}
    >
      {getIcon(error.urgency)}
    </Tooltip>
  )
}

export default Error
