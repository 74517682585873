import { gql } from '@apollo/client'

export const GET_SETTING_AREA_CODE_INPUT_REQUIREMENT_QUERY = gql`
  query getSettingAreaCodeInputRequirement {
    getSettingAreaCodeInputRequirement {
      value
    }
  }
`

export const TRANSPORT_AREA_CODES_QUERY = gql`
  query transportAreaCodesSelect1($limit: Int, $offset: Int, $q: String) {
    transportAreaCodes(limit: $limit, offset: $offset, q: $q) {
      rows {
        uuid
        name
        code
        zone
      }
    }
  }
`
