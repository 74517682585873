import { Button, Form, Input, Radio, Row, Col, Typography } from 'antd'
import { memo, useState, useCallback, useMemo } from 'react'
import { useMutation } from '@apollo/client'
import { withApollo } from 'react-apollo'
import { v4 as uuidv4 } from 'uuid'
import { isArray } from 'lodash'

import YardSelector from 'App/components/Select/YardSelector'
import AddressSelect from 'App/components/Select/AddressSelect'
import YardAddressForm from 'App/components/Manage/Yards/YardAddressForm'
import CompanySelect from 'App/components/Select/TypeToFetch/CompanySelect'

import { Actions, ActionItem } from 'App/components/Manage/Styled'

import config from 'App/config'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'
import { mergeAddressShort } from 'App/components/Transport/Utils/jobHelper'

import { ADD_LEG_MUTATION } from 'App/components/Transport/Schemas/schema'
import { ADDRESS_QUERY } from 'App/components/Transport/Components/Modals/JobActivityModal/schema'

import { CompanyStatus } from 'App/types/types'
import { AddressType } from 'App/types/types'
import type { FormComponentProps } from 'antd/lib/form'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 }
  }
}

interface AddLegFormProps extends FormComponentProps {
  client?: any
  row?: any
  rowProps?: any
  refetchLegs?: any
  visibleColumns?: any
}

enum AddLegAddressMode {
  Yard = 'yard',
  Address = 'address',
  Company = 'company',
  ShipperConsignee = 'shipperConsignee',
}

const AddLegForm = memo((props: AddLegFormProps) => {
  const { client, form, row, rowProps, refetchLegs, visibleColumns } = props
  const { getFieldDecorator } = form

  const leg = useMemo(() => row.original, [row.original])
  const frontGoogleMapsUrl = useMemo(() => config.google.mapsPlaceUrl, [])

  const [uuid] = useState(uuidv4())
  const [companyUuid, setCompanyUuid] = useState('')
  const [mode, setMode] = useState(AddLegAddressMode.Company)
  const [googleMapsURL, setGoogleMapsURL] = useState(frontGoogleMapsUrl)

  const [addLeg, { loading: addLegLoading }] = useMutation(ADD_LEG_MUTATION, { client })

  const setAddressView = useCallback((e: any) => setMode(e.target.value), [])
  const setFormVisibility = useCallback(() => row.toggleRowExpanded(), [row])

  const onAddLeg = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) {
        return
      }

      try {
        let address: any = null

        if (mode === AddLegAddressMode.Company && values.companyAddressUuid) {
          const addr = await client.query({
            query: ADDRESS_QUERY,
            variables: { uuid: values.companyAddressUuid }
          })

          address = addr?.data?.address
        } else if (mode === AddLegAddressMode.ShipperConsignee) {
          address =
            typeof values.shipperConsigneeAddress === 'string'
              ? JSON.parse(values.shipperConsigneeAddress)
              : null
        }

        if (isArray(address?.location?.coordinates)) {
          address.location = address.location.coordinates
        }
        if (address && Object.keys(address)?.length) {
          delete address.__typename
        }

        const inputObj = {
          _id: uuid,
          tripUuid: leg?.tripUuid,
          legUuid: uuid,
          yardUuid: mode === AddLegAddressMode.Yard ? values?.yardUuid : null,
          addAfterSequence: leg?.sequence,
          remarks: values?.remarks,
          address:
            mode === AddLegAddressMode.Address
              ? {
                uuid: values?.address_uuid || uuidv4(),
                name: values?.address_name,
                status: CompanyStatus.Activated,
                type: values?.address_type || null,
                address1: values?.address_address1,
                address2: values?.address_address2 || null,
                address3: values?.address_address3 || null,
                address4: values?.address_address4 || null,
                city: values?.address_city || null,
                district: values?.address_district || null,
                postCode: values?.address_postCode || null,
                placeId: values?.address_placeId || null,
                plusCode: values?.address_plusCode || null,
                areaCode: values?.address_areaCode || null,
                zone: values?.address_zone || null,
                phone: values?.address_phone || null,
                fax: values?.address_fax || null,
                tags: values?.address_tags || null,
                countryAlpha3: values?.address_countryAlpha3 || null,
                location:
                  values?.address_latitude && values?.address_latitude
                    ? [values?.address_latitude, values?.address_longitude]
                    : null
              }
              : mode === AddLegAddressMode.ShipperConsignee || mode === AddLegAddressMode.Company
                ? address
                : null
        }

        if (inputObj.legUuid && addLeg) {
          const res = await addLeg({ variables: { input: inputObj } })
          if (res?.data?.addLeg?.success) {
            row.toggleRowExpanded()
            respHandler('Successfully added leg.', 'success')
            if (refetchLegs) {
              await refetchLegs()
            }
          }
        }
      } catch (error) {
        logger.error('AddLegButton onAddLeg error', error)
        respHandler(error, 'error')
      }
    })
  }, [form, mode, uuid, leg?.tripUuid, leg?.sequence, addLeg, client, row, refetchLegs])

  return (
    <tr {...rowProps} key={`${rowProps.key}-expanded-${leg?.legUuid}`}>
      <td colSpan={visibleColumns.length}>
        <Row key={leg?.legUuid} gutter={24} style={{ minWidth: 600 }}>
          <Col span={4} />
          <Col span={16}>
            <Form>
              <Form.Item label="Sequence" {...formItemLayout} style={{ textAlign: 'left' }}>
                {getFieldDecorator('sequence', {
                  initialValue: leg?.sequence
                })(<Typography.Text>{leg?.sequence}</Typography.Text>)}
              </Form.Item>

              <Row type="flex" justify="end" style={{ margin: '5px', paddingRight: '42px' }}>
                <Radio.Group buttonStyle="solid" defaultValue={mode}>
                  <Radio.Button value={AddLegAddressMode.Company} onClick={setAddressView}>
                    Company
                  </Radio.Button>
                  <Radio.Button value={AddLegAddressMode.Yard} onClick={setAddressView}>
                    Yard
                  </Radio.Button>
                  <Radio.Button value={AddLegAddressMode.Address} onClick={setAddressView}>
                    Address
                  </Radio.Button>
                  <Radio.Button value={AddLegAddressMode.ShipperConsignee} onClick={setAddressView}>
                    Shipper/Consignee
                  </Radio.Button>
                </Radio.Group>
              </Row>

              {mode === AddLegAddressMode.Company && (
                <>
                  <Form.Item label="Company" {...formItemLayout}>
                    <CompanySelect
                      quickCreate
                      value={companyUuid}
                      style={{ width: '100%' }}
                      types={['shipperConsignee']}
                      onChange={(uuid: string) => setCompanyUuid(uuid)}
                      disablePopupContainer={true}
                    />
                  </Form.Item>

                  <Form.Item label="Address" {...formItemLayout}>
                    {getFieldDecorator('companyAddressUuid', {
                      rules: [{ required: true, message: 'Address is required.' }]
                    })(
                      <AddressSelect
                        companyId={companyUuid}
                        companyUuid={companyUuid}
                        style={{ width: '100%' }}
                        type={[AddressType.Delivery]}
                      />
                    )}
                  </Form.Item>
                </>
              )}

              {mode === AddLegAddressMode.Yard && (
                <Form.Item label="Yard" {...formItemLayout}>
                  {getFieldDecorator('yardUuid', {
                    rules: [{ required: true, message: 'Yard is required.' }]
                  })(
                    <YardSelector quickCreate style={{ width: '100%' }} />
                  )}
                </Form.Item>
              )}

              {mode === AddLegAddressMode.Address && (
                <YardAddressForm
                  form={form}
                  setGoogleMapsURL={setGoogleMapsURL}
                  frontGoogleMapsUrl={frontGoogleMapsUrl}
                />
              )}

              {mode === AddLegAddressMode.ShipperConsignee && (
                <Form.Item label="Address" {...formItemLayout}>
                  {getFieldDecorator('shipperConsigneeAddress', {
                    initialValue: leg.shipperAddress || leg.from
                  })(
                    <Row type="flex" justify="start" style={{ margin: 5, paddingRight: 42 }}>
                      <Radio.Group style={{ textAlign: 'left' }}>
                        <Radio value={JSON.stringify(leg.shipperAddress || leg.from)}>
                          {mergeAddressShort(leg.shipperAddress || leg.from)}
                        </Radio>
                        <br />
                        <Radio value={JSON.stringify(leg.consigneeAddress || leg.to)}>
                          {mergeAddressShort(leg.consigneeAddress || leg.to)}
                        </Radio>
                      </Radio.Group>
                    </Row>
                  )}
                </Form.Item>
              )}

              <Form.Item label="Remarks" {...formItemLayout}>
                {getFieldDecorator('remarks', {})(<Input placeholder="Enter remarks..." />)}
              </Form.Item>
            </Form>

            <Actions>
              <ActionItem span={24}>
                <Row type='flex' justify='end'>
                  <Button onClick={setFormVisibility}>Cancel</Button>

                  {mode === AddLegAddressMode.Address && (
                    <a href={googleMapsURL} target="_blank" rel="noreferrer noopener">
                      <Button>Open Map</Button>
                    </a>
                  )}

                  <Button onClick={onAddLeg} loading={addLegLoading} type="primary">
                    Add Leg
                  </Button>
                </Row>
              </ActionItem>
            </Actions>

          </Col>
          <Col span={4} />
        </Row>
      </td>
    </tr>
  )
})

// @ts-expect-error
export default withApollo(Form.create()(AddLegForm))
