import { gql } from '@apollo/client'

import { fragments } from '../fragment'

export default gql`
  mutation addPayment($input: AddPaymentInput!) {
    addPayment(input: $input) {
      ...PaymentDetails
    }
  }

  ${fragments.paymentDetails}
`
