import { Component } from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Form } from 'antd'

import { withBooking } from 'App/contexts/booking'
import * as voucherActions from 'App/states/reducers/voucher'

export default (WrappedComponent) => {
  class WithVoucherItems extends Component {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return compose(
    withBooking,
    Form.create(),
    connect(
      (state) => ({
        voucherBookings: state.voucher.voucherBookings,
        selectedVoucher: state.voucher.selectedVoucher,
        selectableDocuments: state.voucher.selectableDocuments,
        selectedGlobalCompany: state.globalCompany.selectedGlobalCompany,
        showDeletedVoucherItems: state.voucher.showDeletedVoucherItems
      }),
      (dispatch) => ({
        dispatch,
        ...bindActionCreators(
          {
            ...voucherActions
          },
          dispatch
        )
      })
    )
  )(WithVoucherItems)
}
