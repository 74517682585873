import { Layout } from 'antd'

import BookingsList from 'App/components/Booking/ListView/BookingsList'
import BookingsTopBar from 'App/components/Layouts/TopBars/BookingsTopBar'

const { Header, Content } = Layout

const RootIndex = () => {
  return (
    <Layout>
      <Header
        style={{
          zIndex: 1,
          padding: '0',
          width: '100%',
          position: 'fixed',
          backgroundColor: 'white',
        }}
      >
        <BookingsTopBar />
      </Header>
      <Content style={{ marginTop: 64, backgroundColor: 'white' }}>
        <BookingsList />
      </Content>
    </Layout>
  )
}

export default RootIndex
