import { useEffect } from 'react'
import config from 'App/config'

import respHandler from 'App/utils/responseHandler'
import { logger } from 'App/utils/logger'

const useCheckingEffect = (state: any, setState: any) => {
  useEffect(() => {
    if (state.status !== 'checking' || state.pdfUuid === null) {
      return () => {}
    }

    const pdfServiceUrl = `${config.pdf.baseUrl}/status/${state.pdfUuid}`
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch(pdfServiceUrl)

        if (!response.ok) {
          throw new Error(await response.text())
        }

        const body = await response.json()

        if (body.finishedOn && body.finishedOn > 0) {
          setState({ ...state, status: 'downloading' })
        }
      } catch (error) {
        logger.error('useCheckingEffect pdfService error', error)
        respHandler(error, 'error')
        clearInterval(intervalId)
      }
    }, 3000)

    return () => {
      clearInterval(intervalId)
    }
  })
}

export default useCheckingEffect
