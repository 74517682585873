import { gql } from '@apollo/client'

export const BaseCompanyCurrenciesGql = gql`
  query baseCompanyCurrencies2($uuid: UUID!) {
    baseCompany(uuid: $uuid) {
      uuid
      currencies {
        uuid
        code
        name
      }
    }
  }
`
