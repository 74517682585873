import { Component } from 'react'
import Apollo from './apollo'

import { withRouter } from 'App/RouterHOC'

export default (WrappedComponent) => {
  class withLogin extends Component {
    componentDidMount() {
      const { isAuthenticated, router } = this.props

      if (isAuthenticated) router.push('/')
    }

    render() {
      const { isAuthenticated = false } = this.props

      if (isAuthenticated) return <div />

      return <WrappedComponent {...this.props} />
    }
  }

  return Apollo(withRouter(withLogin))
}
