import SET_BOOKING_ASSIGNEE from './setBookingAssignee'
import UNSET_BOOKING_ASSIGNEE from './unsetBookingAssignee'
import { BASIC_BOOKING_SLIM_QUERY } from './basicBookingQuerySlim'
import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from './bookingForWorkflowsSlim'
import BOOKING_VOUCHER_COST_SHEET from './bookingVoucherCostSheet'

export default {
  BOOKING_QUERY: BASIC_BOOKING_SLIM_QUERY,
  BOOKING_QUERY_WORKFLOW: BOOKING_FOR_WORKFLOWS_SLIM_QUERY,
  BOOKING_VOUCHER_COST_SHEET,
  SET_BOOKING_ASSIGNEE,
  UNSET_BOOKING_ASSIGNEE
}
