import find from 'lodash/find'
import sortBy from 'lodash/sortBy'
import { withApollo } from 'react-apollo'

import { NoDocument } from '../../Styled'

import ViewSelector from './ViewSelector'

import { statuses } from 'App/utils/voucher'
import useGlobalCompanyStore from 'App/store/globalCompany'
import useProcessPortalUser from 'App/hooks/useProcessPortalUser'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import { openPrintableInNewTab } from 'App/components/Shared/documentCreator/openPrintableInNewTab'

function ViewSelections(props) {
  const { client } = props
  const { isPortalUser } = useProcessPortalUser()

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  // TODO: check permission
  const { hasPermission } = usePermissionIsAllowed({
    client,
    resource: 'voucher',
    permission: 'generatePdf'
  })

  const getSortedVoucher = (vouchers, section) => {
    const vouchersWithSection = vouchers.map((voucher) => {
      const sectionVoucher = section.vouchers.find((v) => v.type === voucher.type)
      return { ...voucher, sectionVoucher }
    })

    return sortBy(vouchersWithSection, [
      (voucher) => statuses.indexOf(voucher.status),
      (voucher) => voucher.sectionVoucher.type
    ])
  }

  const { section, viewableVouchers, voucherTypes, handleViewClick } = props

  if (!viewableVouchers.length) {
    return <NoDocument>No vouchers found.</NoDocument>
  }

  const sortedVouchers = getSortedVoucher(viewableVouchers, section)

  return (
    <>
      {sortedVouchers.map((voucher, index) => {
        const sectionVoucher = find(section.vouchers, (item) => item.type === voucher.type)
        return (
          <ViewSelector
            key={index}
            voucher={voucher}
            isPortalUser={isPortalUser}
            voucherTypes={voucherTypes}
            sectionVoucher={sectionVoucher}
            // TODO: this handleClick is to be removed, no longer in use.
            handleClick={() => handleViewClick(voucher, sectionVoucher)}
            openPrintableVoucherInNewTab={() =>
              openPrintableInNewTab(voucher.uuid, selectedGlobalCompany.uuid, 'INVOICE')
            }
          />
        )
      })}
    </>
  )
}

export default withApollo(ViewSelections)
