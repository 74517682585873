import { Badge, Typography } from 'antd'

interface BadgeProps {
  count: number
  text?: string
}

const CustomBadge = (props: BadgeProps) => {
  const { count, text } = props
  return (
    <div>
      <Badge
        style={{ background: 'rgb(220, 220, 220)', color: 'black' }}
        overflowCount={50000}
        count={count}
        showZero
      />
      <Typography.Text style={{ marginLeft: 5 }}>{text}</Typography.Text>
    </div>
  )
}
export default CustomBadge
