import { Component } from 'react'
import { Row, Col, Icon, Skeleton } from 'antd'

import { StyledHeader, HeaderTitle, HeaderDescription, TextWrapper } from '../Styled'
import { CapsText, MutedText, NormalText } from './Styled'
import { voucherTypes } from 'App/utils/labelMap'
import withModalHeader from './Container'
import Selections from './Selections'

class ModalHeader extends Component {
  renderSelections = () => {
    const {
      bookingQuery,
      fullBookingQuery,
      selectedVoucher,
      loadRequiredData,
      handleCreateCreditNote
    } = this.props

    return (
      <Selections
        bookingQuery={bookingQuery}
        fullBookingQuery={fullBookingQuery}
        selectedVoucher={selectedVoucher}
        loadRequiredData={loadRequiredData}
        handleCreateCreditNote={handleCreateCreditNote}
      />
    )
  }

  render() {
    const { onCancel, selectedVoucher } = this.props

    if (!selectedVoucher) return <Skeleton />

    const type = selectedVoucher.type
    const description = selectedVoucher.status

    const mappedType = voucherTypes[type] || { text: type }
    const titleCategory =
      selectedVoucher.transactionType === 'ACCPAY' ? 'Vendor Invoice' : 'Customer Invoice'

    return (
      <StyledHeader>
        <Row type="flex" justify="space-around" align="middle">
          <Col span={20}>
            <TextWrapper>
              <HeaderTitle>
                <CapsText>{titleCategory}</CapsText>
                {' - '}
                <NormalText>{mappedType.text}</NormalText>{' '}
                <MutedText>({selectedVoucher.transactionType})</MutedText>
                {selectedVoucher.isCreditNote && <MutedText> [CREDIT NOTE]</MutedText>}
              </HeaderTitle>
              <HeaderDescription>{description}</HeaderDescription>
            </TextWrapper>
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            {selectedVoucher.status !== 'NEW' && this.renderSelections()}
            <Icon style={{ marginLeft: '15px' }} type="close" onClick={onCancel} />
          </Col>
        </Row>
      </StyledHeader>
    )
  }
}

export default withModalHeader(ModalHeader)
