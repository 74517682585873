import React, { Component } from 'react'
import { compose, withProps } from 'recompose'

import bookingQueryFromRoute from 'App/containers/booking/query/fromRoute'
import Section from '../Section'
import ROTAddAction from './ROTAddAction'
import ROTTable from './ROTTable'
import documentCreatorsQuery from 'App/containers/documentCreator/query/documentCreatorsQuery'
import { CategoryTitle } from '../../Workflow/Styled'

const bookingUuidProps = withProps((ownerProps) => {
  return {
    bookingUuid: ownerProps.booking.uuid
  }
})

const enhance = compose(bookingUuidProps, documentCreatorsQuery, bookingQueryFromRoute)

const ROTSection = class extends Component {
  handleROTAdded = () => {
    const { documentCreatorsQuery, bookingQuery } = this.props
    documentCreatorsQuery.refetch()
    bookingQuery.refetch()
  }

  handleChange = () => {
    const { documentCreatorsQuery, bookingQuery } = this.props
    documentCreatorsQuery.refetch()
    bookingQuery.refetch()
  }

  render() {
    const { documentCreatorsQuery } = this.props

    const documentCreators = (
      (documentCreatorsQuery &&
        documentCreatorsQuery.documentCreators &&
        documentCreatorsQuery.documentCreators.rows) ||
      []
    ).filter((dc) => dc.type === 'ROT')

    return (
      <Section
        removeBorder
        title={
          <>
            <CategoryTitle>
              ROT
              <ROTAddAction booking={this.props.booking} onAdded={this.handleROTAdded} />
            </CategoryTitle>
          </>
        }
      >
        <ROTTable documentCreators={documentCreators} onChange={this.handleChange} />
      </Section>
    )
  }
}

export default enhance(ROTSection)
