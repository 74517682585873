import { isUuid } from 'App/utils/u'

export const getBkQueryVariables = (params: any) => {
  if (!params?.no && !params?.uuid) return

  const { no, uuid } = params

  const variables = uuid ? { uuid } : isUuid(no) ? { uuid: no } : { no }

  return variables
}
