import styled from 'styled-components'

export const Container = styled.div`
  margin: 20px;
  padding: 0px 15px 10px;
  background: white;
  border: 1px solid rgb(235, 235, 235);
`

export const SectionHeader = styled.div`
  font-size: 0.95rem;
  font-weight: 600;
  color: rgb(40, 45, 50);
  padding: 6px 0px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(230, 232, 234);
`

export const ActionWrapper = styled.div`
  padding: ${(props) => (props.removePadding ? '0' : '10px')};

  text-align: ${(props) => (props.toLeft ? 'left' : 'right')};

  button {
    ${(props) => (props.toLeft ? 'margin-right: 10px;' : 'margin-left: 10px;')}
  }
`
