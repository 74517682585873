import styled from 'styled-components'

import { Icon, Row } from 'antd'

export const Wrapper = styled.div`
  position: relative;
  line-height: normal;

  .anticon {
    margin-right: 3px;
  }

  .anticon-paper-clip {
    color: rgb(140, 140, 140);
  }

  .anticon-delete {
    color: #f44242;
  }

  .anticon-check-circle-o {
    color: gray;
  }

  .anticon-warning {
    color: #f4c542;
  }
`

export const SelectorWrapper = styled.div`
  padding: 15px 10px 0 0px;
`

export const Selector = styled.a`
  width: 100%;
  text-decoration: none;
  display: block;
  cursor: pointer;
  padding: 7px 10px 5px;
  border: 1px solid rgb(230, 230, 230);
  ${(props) => (!props.verified && !props.deleted ? 'border-left: 2px solid #f4c542;' : '')}
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;

  &:hover {
    transition: 0.3s;
    border-color: rgb(200, 200, 200);
    ${(props) => (!props.verified && !props.deleted ? 'border-left: 2px solid #f4c542;' : '')}
  }
`

export const StatusWrapper = styled.div`
  text-align: right;
`

export const RemoveIcon = styled(Icon)`
  position: absolute;
  right: 0px;
  top: 10px;
  cursor: pointer;
  background: rgb(245, 245, 245);
  border: 1px solid rgb(240, 240, 240);
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 2px;
  font-size: 0.7rem;

  &:hover {
    background: rgb(235, 235, 235);
  }
`

export const Header = styled(Row)`
  font-size: 0.75rem;
  font-weight: 500;
  color: rgb(30, 34, 38);
  line-height: normal;
`

export const Description = styled(Row)`
  padding-top: 3px;
`

export const FileName = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.45);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const Name = styled.div`
  font-size: 12.5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: 0.2px;
`
