import styles from './Filter.module.css'

import { memo, useState, useMemo, useCallback } from 'react'
import { parse, stringify } from 'query-string'
import { useHistory } from 'react-router'
import { Menu } from 'antd'

import labelMap from 'App/utils/labelMap'

import CustomBadge from './CustomBadge'
import { getBookingQuery } from 'App/components/Layouts/Search/helper'

const { SubMenu } = Menu
interface FacetProps {
  key: string
  doc_count: number
}

interface SubMenuProps {
  key?: string
}

const Filter = (props: any) => {
  const { titleAttribute, facet } = props
  const count = useMemo(
    () => (facet ? facet.reduce((sum: number, item: FacetProps) => sum + item.doc_count, 0) : 0),
    [facet]
  )
  // @ts-ignore
  const title = useMemo(() => labelMap[titleAttribute], [titleAttribute])
  const [selectSubMenu, setSelectSubMenu] = useState<string | null>()

  const history = useHistory()

  const buildQuery = useCallback(
    (facet: string | undefined, key: string) => {
      const fromURL = history.location.search.slice(1)
      const urlData: any = parse(fromURL)
      const query = {
        q: urlData?.q || '',
        sort: urlData?.sort || '',
        limit: urlData?.limit || 20,
        offset: urlData?.offset || 0,
        filter: facet ? { facetFilter: { [key]: facet } } : {}
      }

      const { urlFormat } = getBookingQuery(history, query)

      const queryParams = stringify(urlFormat)
      history.push(`/?${queryParams}`)
    },
    [history]
  )

  const handleSubMenuClick = useCallback(
    (e: SubMenuProps) => {
      if (selectSubMenu === e.key) {
        setSelectSubMenu(null)
        history.push('/')
      } else {
        setSelectSubMenu(e.key)
        buildQuery(e.key, titleAttribute)
      }
    },
    [selectSubMenu, history, buildQuery, titleAttribute]
  )

  return (
    <Menu
      mode="inline"
      onClick={handleSubMenuClick}
      defaultOpenKeys={selectSubMenu ? [selectSubMenu] : []}
    >
      <SubMenu title={<CustomBadge count={count} text={title} />}>
        {facet?.map((item: FacetProps) => {
          // @ts-expect-error
          const key = item.key === 1 ? 'TRUE' : item.key === 0 ? 'FALSE' : item.key
          const display = labelMap[key] || key

          return (
            <Menu.Item key={item.key} className={styles.menuItem}>
              <span className={styles.itemText}>
                {display}
              </span>
              <CustomBadge count={item.doc_count} />
            </Menu.Item>
          )
        })}
      </SubMenu>
    </Menu>
  )
}

export default memo(Filter)
