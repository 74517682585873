
import { Modal, Form, Spin } from 'antd'
import { compose, withState, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'

import DocumentCreatorModalContent from './ModalContent'
import ModalFooter from './ModalFooter'
import { documentCreatorGql } from './documentCreatorGql'

const visibleState = withState('visible', 'setVisible', true)
const refreshTimeState = withState('refreshTime', 'setRefreshTime', true)

const documentCreatorGraphql = graphql(documentCreatorGql, {
  name: 'documentCreatorGraphql',
  options: (props) => ({
    variables: {
      uuid: props.documentCreatorUuid
    },
    fetchPolicy: 'cache-and-network'
  })
})

const handlers = withHandlers({
  handleCancel: (props) => () => {
    const { setVisible } = props
    setVisible(false)
  },
  handleChange: (props) => async () => {
    const { documentCreatorGraphql, setRefreshTime } = props

    await documentCreatorGraphql.refetch()

    setRefreshTime(new Date().getTime())
  }
})

const enhance = compose(
  visibleState,
  refreshTimeState,
  documentCreatorGraphql,
  Form.create(),
  handlers
)

const DocumentCreatorModal = (props) => {
  const {
    visible,
    refreshTime,
    documentCreatorGraphql,
    form,
    handleCancel,
    handleChange,
    afterClose = () => { }
  } = props

  const { loading, error, documentCreator } = documentCreatorGraphql

  return (
    <Modal
      destroyOnClose
      title={(documentCreator && documentCreator.type) || 'Document Creator'}
      width="80%"
      visible={visible}
      footer={
        <Spin spinning={loading}>
          <ModalFooter
            documentCreator={documentCreator}
            form={form}
            onChange={handleChange}
            onClose={handleCancel}
          />
        </Spin>
      }
      onCancel={handleCancel}
      afterClose={afterClose}
    >
      {error && <span>An error occurred. Please try again later.</span>}
      {loading && !documentCreator && <span>Loading...</span>}
      {!error && documentCreator && (
        <DocumentCreatorModalContent
          documentCreator={documentCreator}
          form={form}
          refreshTime={refreshTime}
        />
      )}
    </Modal>
  )
}

export default enhance(DocumentCreatorModal)
