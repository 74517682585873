import { gql } from '@apollo/client'

export default gql`
  fragment CostItemErrorDetails on costItemErrorType {
    msg
    field
    urgency
    expectedValue
    receivedValue
  }
`
