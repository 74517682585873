import { gql } from '@apollo/client'

export default gql`
  query countriesSelect1($query: String, $limit: Int) {
    countries(query: $query, limit: $limit) {
      rows {
        name
        alpha2
        alpha3
      }
    }
  }
`
