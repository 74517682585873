import { message, notification } from 'antd'

import config from 'App/config'
import webStorage from './webStorage'
import { hasPermissionError } from 'App/utils/u'
import auth, { LOCAL_STORAGE_KEYS, namespace } from 'App/utils/auth'

export const handleFatal = (error) => {
  if (hasPermissionError(error)) {
    return {
      success: false,
      message: `${config.youDoNotHavePermission}${error.message.split('resource:')[1]}`
    }
  } else if (error.graphQLErrors && error.graphQLErrors.length) {
    const graphQLError = error.graphQLErrors[0]

    return {
      success: false,
      message: graphQLError.message || `${graphQLError.statusCode} - ${graphQLError.error}`
    }
  } else {
    return {
      success: false,
      message: (error && error.toString()) || 'Whoops. Something went wrong.'
    }
  }
}

export const handleWithMessage = (msg, type = 'normal', status = true) => {
  if (type === 'fatal') {
    return handleFatal(msg)
  }

  return {
    success: status,
    message: msg,
    data: msg
  }
}

/**
 * @param {any} resp
 * @param {"success" | "error" | "load" | "warning"} type
 * @param {number} duration
 */
export default (resp, type, duration = 0) => {
  message.destroy()
  if (type === 'success') {
    notification.success({
      message: resp,
      duration: duration || 5,
      key: resp
    })
  } else if (type === 'error') {
    const refreshToken = webStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)
    const profile = webStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE)

    if (
      refreshToken &&
      (auth.hasLoggedOutError(resp) || auth.jwtIsExpiring()) &&
      (profile.baseCompanyUuids?.length ||
        profile[namespace]?.app_metadata?.baseCompanyUuids?.length)
    ) {
      // auth.useRefreshToken() should have been triggered from initApollo
      message.loading('Re-logging in, please wait...', 0)
    } else {
      console.error(resp)
      const error = handleFatal(resp)
      notification.error({
        message: error.message,
        duration: duration || 10,
        key: error.message
      })
    }
  } else if (type === 'load') {
    message.loading(resp, duration)
  } else if (type === 'warning') {
    notification.warning({
      message: resp,
      duration: duration || 10,
      key: resp
    })
  }
}
