import { gql } from '@apollo/client'

import { fragments as currencyFragment } from 'App/containers/currency/fragment'

export const companyDetails = gql`
  fragment CompanyDetails on Company {
    uuid
    name
    types
    code
    tags
    debtorCode
    creditorCode
    status
    countryAlpha3
    description
    addresses {
      uuid
      name
      type
      address1
      address2
      address3
      address4
      city
      district
      postCode
      phone
      fax
      status
      location {
        type
        coordinates
      }
      countryAlpha3
      tags
    }
    contacts {
      uuid
      name
      email
      phone
    }
    currency {
      ...CurrencyDetails
    }
  }

  ${currencyFragment.currencyDetails}
`
export const companyBasic = gql`
  fragment CompanyDetailsSlim on Company {
    uuid
    name
    code
    __typename
  }
`
