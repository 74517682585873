
import { Tooltip } from 'antd'

import { PendingIcon } from '../Container'

const PendingVerificationIcon = (props) => {
  return (
    <Tooltip title="Pending verification.">
      <PendingIcon type="warning" />
    </Tooltip>
  )
}

export default PendingVerificationIcon
