import { forwardRef } from 'react'
import styled from 'styled-components'
import { UnControlled as CodeMirror } from 'react-codemirror2'

import 'codemirror/theme/dracula.css'
import 'codemirror/lib/codemirror.css'
import 'codemirror/mode/handlebars/handlebars'

const StyledDiv = styled.div`
  font-size: 0.85em;
  line-height: 18px;

  .react-codemirror2 {
    .CodeMirror {
      border: 1px solid #eee;
      height: 550px;
    }
  }
`

const TemplateEditor = forwardRef((props, ref) => {
  const { value, onChange, disabled } = props

  return (
    <StyledDiv >
      <CodeMirror
        ref={ref}
        value={value}
        options={{
          tabSize: 2,
          indentUnit: 2,
          theme: 'dracula',
          lineNumbers: true,
          mode: 'handlebars',
          indentWithTabs: true,
          readOnly: disabled && 'nocursor',
        }}
        autoCursor={false}
        onChange={(editor, data, value) => onChange(value)}
      />
    </StyledDiv>
  )
})

export default TemplateEditor
