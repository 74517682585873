import styled from 'styled-components'

import { Row } from 'antd'

export const StyledForm = {
  Row: styled(Row)`
    .ant-divider-horizontal.ant-divider-with-text {
      font-size: 16.222px;
      font-weight: bold;
      padding: 90px 0 30px;
    }

    .ant-form-item-label {
      padding-right: 5px;
      text-align: right;

      label {
        font-size: 13.5px;
        font-weight: 500;
        letter-spacing: 0.2px;
        color: rgb(130, 130, 130);
      }
    }

    .ant-form-item {
      margin-bottom: 10px;
    }

    .ant-form-explain {
      font-size: 12.5px;
      font-weight: 600;
      letter-spacing: 0.3px;
    }

    .ant-input {
      height: 32px;
    }

    .ant-input,
    .ant-input-number-input {
      font-size: 13px;
      font-weight: 500;
      color: black;
      letter-spacing: 0.2px;
      padding: 4px 9px;
    }

    .ant-input-number,
    .ant-calendar-picker {
      width: 100%;
    }

    .ant-input-number-input {
      height: 32px;
    }

    .ant-radio-button-wrapper {
      font-size: 13px;
      font-weight: 500;
      color: rgb(60, 70, 80);
      letter-spacing: 0.1px;
      height: 28px;
      line-height: 26px;
    }

    .ant-select-selection--multiple .ant-select-selection__choice__remove {
      font-size: 14px;
    }
  `
}

export const CombineWrapper = styled.div`
  width: ${(props) => (props.full ? '100%' : '550px')};
`

export const ColWrapper = styled.div`
  width: ${(props) => (props.full ? '100%' : (props) => (props.width ? props.width : '500px'))};
`

export const StyledRow = styled(Row)``

export const Divider = styled.div`
  font-size: 17.222px;
  font-weight: bold;
  color: #111;
  padding: 10px 0;
  margin: 60px 0 20px;
  border-bottom: 1px solid rgb(230, 230, 230);
`
