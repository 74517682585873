

import ApprovalIcon from './ApprovalIcon'
import PendingVerificationIcon from './PendingVerificationIcon'

const StatusIcons = (props) => {
  const {
    uploadedDoc,
    documentApproval: { approvals = [] }
  } = props

  return (
    <div style={{ float: 'right', marginTop: '2px' }}>
      {uploadedDoc.status === 'UPLOADED' && <PendingVerificationIcon />}
      {approvals &&
        approvals.map((workflowApproval) => {
          const approvedApproval =
            uploadedDoc.approvals &&
            uploadedDoc.approvals.find((docApproval) => docApproval.type === workflowApproval)

          return (
            <ApprovalIcon
              key={workflowApproval}
              workflowApproval={workflowApproval}
              approvedApproval={approvedApproval}
            />
          )
        })}
    </div>
  )
}

export default StatusIcons
