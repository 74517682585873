

import { FieldItemView, ItemName, ItemValue } from './Styled'

const FieldItem = (props) => {
  const { name, type = 'normal', value } = props

  return (
    <FieldItemView>
      <ItemName>{name}</ItemName>
      <ItemValue type={type}>{value || '-'}</ItemValue>
    </FieldItemView>
  )
}

export default FieldItem
