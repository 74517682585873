import React, { Component } from 'react'
import PropTypes from 'prop-types'
import findIndex from 'lodash/findIndex'

import { Steps, Tooltip } from 'antd'

import Container from './Container'

const Step = Steps.Step

class Progress extends Component {
  render() {
    const { steps = [] } = this.props
    const foundCurrentStepIndex = findIndex(steps, (step) => step.isCurrentStep)
    let currentIndex

    if (steps[steps.length - 1].isCurrentStep) {
      currentIndex = steps.length - 1 // Set to last one
    } else if (steps[steps.length - 1].isComplete) {
      currentIndex = steps.length // Set past it.
    } else if (foundCurrentStepIndex >= 0) {
      currentIndex = foundCurrentStepIndex
    } else {
      currentIndex = -1
    }

    return (
      <Container>
        <Steps progressDot current={currentIndex}>
          {steps.map((step, index) => {
            const helpText = step.helpText

            return (
              <Step
                key={index}
                description={
                  <Tooltip placement="bottom" title={helpText}>
                    {helpText}
                  </Tooltip>
                }
              />
            )
          })}
        </Steps>
      </Container>
    )
  }
}

Progress.propTypes = {
  steps: PropTypes.array
}

export default Progress
