import { gql } from '@apollo/client'

export const createDocumentCreatorFromTemplateGql = gql`
  mutation createDocumentCreatorFromTemplate($templateUuid: UUID!, $bookingUuid: UUID!) {
    createDocumentCreatorFromTemplate(templateUuid: $templateUuid, bookingUuid: $bookingUuid) {
      uuid
      type
      status
      bookingUuid
    }
  }
`
