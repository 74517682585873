import { Component } from 'react'
import PropTypes from 'prop-types'

import Container from './Container'
import SectionHeader from './SectionHeader'

class Section extends Component {
  render() {
    return (
      <Container>
        <SectionHeader removeBorder={this.props.removeBorder}>{this.props.title}</SectionHeader>
        {this.props.children}
      </Container>
    )
  }
}

Section.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element)
  ])
}

export default Section
