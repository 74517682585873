import { useState } from 'react'
import join from 'lodash/join'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import { Popover, Icon } from 'antd'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import webStorage from 'App/utils/webStorage'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import useGlobalCompanyStore from 'App/store/globalCompany'
import { changeGlobalCompany } from 'App/states/reducers/globalCompany'

import {
  GuideText,
  Selections,
  IconWrapper,
  NameWrapper,
  SelectOption,
  SelectedName,
  SelectedTypes,
  SelectedCompany,
} from './Styled'

function CompaniesSelection() {
  const [visible, setVisible] = useState(false)

  const { t } = useTranslation()
  const dispatch = useDispatch()

  const globalCompanies = useGlobalCompanyStore.use.globalCompanies()
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const setSelectedGlobalCompany = useGlobalCompanyStore.use.setSelectedGlobalCompany()

  if (isEmpty(selectedGlobalCompany)) {
    return (
      <SelectedCompany>
        <GuideText>{t('header.noBaseCompanyFound')}</GuideText>
      </SelectedCompany>
    )
  }

  const handleClickChange = () => setVisible(!visible)

  const handleSelect = (selected) => {
    if (selected && selectedGlobalCompany.uuid !== selected.uuid) {
      const baseCompany = pick(selected, ['uuid', 'name', 'slug', 'types'])

      dispatch(changeGlobalCompany(baseCompany))
      setSelectedGlobalCompany(baseCompany)

      const keys = [
        LOCAL_STORAGE_KEYS.VIEWER_DATA,
        LOCAL_STORAGE_KEYS.BASE_COMPANY_TYPES,
        LOCAL_STORAGE_KEYS.TIMELINE_VEHICLE_DEPARTMENTS,
        LOCAL_STORAGE_KEYS.SELECTED_DEPARTMENTS_PLANNING_TABLE,
        LOCAL_STORAGE_KEYS.SELECTED_DEPARTMENTS_PLANNING_VEHICLES_TABLE
      ]
      keys.forEach((key) => webStorage.removeItem(key))
      window.location.reload()
    }
  }

  const types = selectedGlobalCompany.types || []

  return (
    <SelectedCompany >
      <SelectedTypes>{join(types, ', ')}</SelectedTypes>
      <Popover
        trigger={'click'}
        visible={visible}
        placement="bottomLeft"
        onVisibleChange={handleClickChange}
        getPopupContainer={(trigger) => trigger.parentNode}
        content={
          <Selections>
            <GuideText>{t('header.allowedCompanies')} ({globalCompanies?.length ?? 0})</GuideText>
            {globalCompanies?.map((gc) => {
              return (
                <SelectOption
                  key={gc.uuid}
                  onClick={() => handleSelect(gc)}
                  selected={gc.uuid === selectedGlobalCompany.uuid}
                >
                  {gc.name}
                </SelectOption>
              )
            })}
          </Selections>
        }
      >
        <SelectedName type="flex">
          <NameWrapper>{selectedGlobalCompany.name}</NameWrapper>
          <IconWrapper>
            <Icon type="edit" />
          </IconWrapper>
        </SelectedName>
      </Popover>
    </SelectedCompany>
  )
}

export default CompaniesSelection