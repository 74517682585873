import { useState, useEffect, useRef } from 'react'
import { Icon } from 'antd'

import config from 'App/config'

const inputSearchStyle = {
  width: '94%',
  color: 'black',
  fontSize: '13px',
  fontWeight: '500',
  lineHeight: '30px',
  padding: '4px 9px',
  borderRadius: '4px',
  marginBottom: '20px',
  letterSpacing: '0.2px',
  boxSizing: 'border-box',
  backgroundColor: '#fff',
  backgroundImage: 'none',
  border: '1px solid #d9d9d9'
}

// similar to <script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=MY_KEY&libraries=places"></script>
const loadScript = (url, callback) => {
  if (window.google?.maps) {
    return callback()
  }

  const script = document.createElement('script')
  script.type = 'text/javascript'

  // run callback if script state is loaded or complete
  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null
        callback()
      }
    }
  } else {
    script.onload = callback
  }

  script.src = url
  document.getElementsByTagName('head')[0].appendChild(script)
}

let autoComplete
const initAutoComplete = (updateQuery, autoCompleteRef, getGoogleMapsData) => {
  // assign autoComplete with Google maps place once
  autoComplete = new window.google.maps.places.Autocomplete(autoCompleteRef.current)
  // specify what properties to get from API
  autoComplete.setFields([
    'address_components',
    'business_status',
    'formatted_address',
    'geometry',
    'icon',
    'name',
    'photo',
    'place_id',
    'plus_code',
    'type'
  ])
  // add a listener to handle when the place is selected ('place_changed' from Google API)
  autoComplete.addListener('place_changed', () => {
    handlePlaceSelect(updateQuery, getGoogleMapsData)
  })
}

let searchResults = {}
let val = {}

const componentForm = {
  street_number: 'short_name',
  route: 'long_name',
  sublocality_level_1: 'long_name',
  sublocality_level_2: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'short_name',
  country: 'long_name',
  postal_code: 'short_name',
  point_of_interest: 'long_name'
}

// listen whenever a user selects an autocomplete suggestion
const handlePlaceSelect = async (updateQuery, getGoogleMapsData) => {
  // get place from Google API
  const addressObject = autoComplete.getPlace()

  if (!addressObject?.geometry) {
    // User did not select a prediction; reset the input field
    updateQuery('')
  } else {
    const formattedAddress = addressObject.formatted_address
    updateQuery(formattedAddress)

    // Get each component of the address from the place details,
    // and then fill-in the corresponding field (e.g. street_number) into val.
    searchResults = {}
    val = {}
    for (const component of addressObject.address_components) {
      const addressType = component.types[0]
      if (componentForm[addressType]) {
        val[addressType] = component[componentForm[addressType]]
      }
    }

    if (val.point_of_interest) {
      searchResults.address1 = val.point_of_interest
    } else if (val.street_number && val.route) {
      searchResults.address1 = `${val.street_number}, ${val.route}`
    } else if (!val.street_number && val.route) {
      searchResults.address1 = val.route
    } else {
      searchResults.address1 = formattedAddress
    }

    if (addressObject?.geometry.location) {
      // prevent addressObject.geometry.location.lat from returning a function
      const location = JSON.parse(JSON.stringify(addressObject.geometry.location))
      searchResults.latitude = location.lat
      searchResults.longitude = location.lng
    }

    searchResults.address2 = val.sublocality_level_1
    searchResults.address3 = val.sublocality_level_2
    searchResults.city = val.locality
    searchResults.district = val.administrative_area_level_1
    searchResults.countryName = val.country
    searchResults.postCode = val.postal_code
    searchResults.name = addressObject.name
    searchResults.placeId = addressObject.place_id
    searchResults.plusCode = addressObject.plus_code?.global_code

    getGoogleMapsData(searchResults)
  }
}

// get (onClickSearchResult) from App/components/Select/AddressSelect
const SearchLocationInput = (props) => {
  const autoCompleteRef = useRef(null)
  const [query, setQuery] = useState('')

  useEffect(() => {
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${config.google.mapsAPIKey}&libraries=places&region=MY`,
      () => initAutoComplete(setQuery, autoCompleteRef, props.onClickSearchResult)
    )
  }, [props.onClickSearchResult])

  return (
    <div>
      <Icon type="search" style={{ fontSize: '18px', width: '6%' }} />
      <input
        value={query}
        ref={autoCompleteRef}
        style={inputSearchStyle}
        placeholder="Search Google Maps"
        onChange={(event) => setQuery(event.target.value)}
      />
    </div>
  )
}

export default SearchLocationInput
