import { graphql } from 'react-apollo'

import schema from '../schema'
import bookingSchema from 'App/containers/booking/schema'

export default graphql(schema.ADD_VOUCHER_ITEM, {
  props: ({ mutate, ownProps }) => ({
    addVoucherItem: (params, bookingUuid) =>
      mutate({
        variables: {
          input: params
        },
        refetchQueries: [
          {
            query: bookingSchema.BOOKING_VOUCHER_COST_SHEET,
            variables: {
              uuid: bookingUuid
            }
          }
        ]
      })
  })
})

export const addVoucherItemWithRefetchBooking = graphql(schema.ADD_VOUCHER_ITEM, {
  props: ({ mutate, ownProps }) => ({
    addVoucherItem: (params, bookingUuid) =>
      mutate({
        variables: {
          input: params
        },
        refetchQueries: [
          {
            query: bookingSchema.BOOKING_VOUCHER_COST_SHEET,
            variables: {
              uuid: bookingUuid
            }
          }
        ]
      })
  })
})
