export default {
  DRAFT: {
    tips: 'Draft',
    icon: 'edit'
  },
  SUBMITTED: {
    tips: 'Submitted',
    color: '#FFC000',
    icon: 'info-circle-o'
  },
  ACCEPTED: {
    tips: 'Accepted',
    color: 'green',
    icon: 'check-circle-o'
  },
  CLOSE_OPERATION: {
    tips: 'Operation Closed',
    icon: 'check-square-o',
    color: '#00FF00',
    backgroundColor: '#cfffcf'
  },
  CLOSE_FINANCE: {
    tips: 'Finance Closed',
    icon: 'clock-circle-o',
    color: 'blue',
    backgroundColor: '#e0ffff'
  },
  REJECTED: {
    tips: 'Rejected',
    icon: 'close-circle',
    color: 'red'
  },
  CANCELLED: {
    tips: 'Cancelled',
    icon: 'close-circle-o',
    color: 'red'
  },
  default: {
    tips: 'N/A',
    icon: 'minus'
  }
}
