import { gql } from '@apollo/client'

export default gql`
  fragment TaxDetails2 on Tax {
    uuid
    name
    code
    percentage
  }
`
