import config from 'App/config'
import webStorage from 'App/utils/webStorage'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import useGlobalCompanyStore from 'App/store/globalCompany'

import ResourceViewer from 'App/components/ResourceViewer'
import TemplateEditor from 'App/components/TemplateEditor'

function TemplateContent(props) {
  const { view, value, disabled, documentCreator, refreshTime, onChange } = props

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  if (view === 'preview') {
    const url = `${config.api.baseUrl}/documentCreator/generate/${documentCreator.uuid}?refreshTime=${refreshTime}`
    const fileType = 'text/html'

    return (
      <div style={{ height: '95%', border: 'solid 1px lightgray' }}>
        <ResourceViewer
          url={url}
          mimeType={fileType}
          fetchOptions={{
            method: 'GET',
            headers: {
              authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
              'base-company-uuid': selectedGlobalCompany.uuid
            }
          }}
        />
      </div>
    )
  }

  if (view === 'html') {
    return <TemplateEditor disabled={disabled} value={value} onChange={onChange} />
  }

  return null
}

export default TemplateContent