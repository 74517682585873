import { compose } from 'recompose'

import Auth0 from 'App/hocs/auth0'
import withLogin from 'App/hocs/login'

import AuthLayout from 'App/components/Layouts/Auth'
import UniversalSignIn from 'App/components/Auth/SignIn/UniversalSignIn'

const UniversalSignInPage = (props) => {

  return (
    <AuthLayout>
      <UniversalSignIn {...props} />
    </AuthLayout>
  )
}

export default compose(Auth0, withLogin)(UniversalSignInPage)
