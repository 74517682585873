import moment from 'moment'
import { Tooltip } from 'antd'

import UserIcon from './UserIcon'
import BookingStatusIcon from './BookingStatusIcon'
import { getContainerStats } from 'App/utils/booking'

import type { Booking } from 'App/types/types'

export type BookingTableColumnProps = {
  title: string
  key: string
  dataIndex?: string
  width?: string
  fnc?: string
  convert?: any
  render?: any
}

const renderWithTooltip = (text?: string) => {
  return (
    <Tooltip title={text} mouseEnterDelay={0.1}>
      {text}
    </Tooltip>
  )
}

export const getShortCntr = (record: Booking) => {
  const activeJobs = record?.jobs?.filter((j: any) => j.status !== 'CANCELLED')
  let containerStats = getContainerStats(activeJobs)
    .map((s) => `${s.count}x${s.size} ${s.type ? s.type : ''}`)
    .join(', ')
  containerStats = (record.type || record.details.shipmentType || '') + ' ' + containerStats
  return containerStats
}

export const getFormattedDateBookingList = (record: Record<string, any>, key: string) => {
  return record[key] ? moment(record[key]).format('DD/MM/YY') : '-'
}

export const getNumJobTrip = (record: Record<string, any>, key: string) => {
  return record.jobs?.length || 0
}

const isProd = process.env.NODE_ENV === 'production'

const defaultCols: Array<BookingTableColumnProps> = [
  {
    title: 'No.',
    key: 'no',
    width: isProd ? '120px' : '150px',
    fnc: 'shortLink',
    dataIndex: 'no'
  },
  {
    title: 'Bill To',
    dataIndex: 'billTo.name',
    key: 'billTo'
  },
  {
    title: 'Shipper',
    dataIndex: 'shipper.name',
    key: 'shipperName'
  },
  {
    title: 'Consignee',
    dataIndex: 'consignee.name',
    key: 'consigneeName'
  },
  {
    title: 'ShortCntr',
    dataIndex: 'shortCntr',
    key: 'shortCntr',
    width: '140px',
    fnc: 'shortCntr'
  },
  {
    title: 'ESTPick/ESTDrop',
    width: '160px',
    key: 'etaEtd',
    fnc: 'etaEtd'
  },
  {
    title: 'Created',
    key: 'createdAt',
    width: '90px',
    fnc: 'date'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    width: '50px',
    fnc: 'bookingStatus'
  }
]

const functions = {
  shortLink: (text: string, record: Booking) => renderWithTooltip(record.no as string),
  shortCntr: (text: string, record: Booking) => {
    const containerStats = getShortCntr(record)
    return renderWithTooltip(containerStats)
  },
  etaEtd: (text: string, record: Booking) => {
    const eta = getFormattedDateBookingList(record, 'estPick')
    const etd = getFormattedDateBookingList(record, 'estDrop')
    return renderWithTooltip(`${eta} / ${etd}`)
  },
  date: (value: any, record: Booking, key: string) => {
    const dateCreated = getFormattedDateBookingList(record, key)
    return renderWithTooltip(dateCreated)
  },
  bookingStatus: (text: string, record: Booking) => {
    return (
      <>
        <BookingStatusIcon booking={record} />
        {record.assignees?.map((u: any) => (
          <UserIcon key={u.user.uuid} user={u} />
        ))}
      </>
    )
  },
  stringArray: (text: string[]) => (Array.isArray(text) ? text.join(', ') : '')
}

export const getColumns = (columns?: BookingTableColumnProps[]) => {
  const cols = Array.isArray(columns) ? columns : [...defaultCols]

  return cols.map((s) => {
    // @ts-ignore
    s.onCell = (record: Booking) => ({
      style: {
        color: "black",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      onClick: (e: React.MouseEvent<HTMLDivElement>) => {
        const url = `/bookings/${record.no}`;
        if (e.ctrlKey || e.metaKey || e.button === 1) {
          window.open(url, '_blank');
        } else {
          window.location.href = url;
        }
      }
    })

    if (s.fnc && functions[s.fnc]) {
      // @ts-ignore
      s.render = (value: any, record: Booking) => functions[s.fnc](value, record, s.key)
      return s
    }

    if (s.convert) {
      s.render = (text: string) => s.convert[text] || text
      return s
    }

    if (s.fnc && !functions[s.fnc]) {
      console.error(`GetColumns: fnc ${s.fnc} not found. Please check your baseCompanySettings.`)
    }

    s.render = (text: string) => renderWithTooltip(text)

    return s
  })
}
