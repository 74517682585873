import React, { Component } from 'react'
import PropTypes from 'prop-types'

import UploadButton from './UploadButton'

class Uploader extends Component {
  render() {
    const { label, handleDocumentUpload, type } = this.props

    return (
      <UploadButton
        label={label}
        handleUpload={(acceptedFiles, rejectedFiles) =>
          handleDocumentUpload(acceptedFiles, rejectedFiles, type)
        }
      />
    )
  }
}

Uploader.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  handleDocumentUpload: PropTypes.func
}

export default Uploader
