import styled from 'styled-components'

export const Wrapper = styled.div``

export const Text = styled.span`
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.3);
`
