import { gql } from '@apollo/client'

import voucherItemDetails from './voucherItem'
import approvalDetails from './approval'
import voucherPaymentDetails from './voucherPayment'

import { fragments as currencyFragment } from 'App/containers/currency/fragment'
import { fragments as companyFragment } from 'App/containers/company/fragment'
import { fragments as userFragment } from 'App/containers/user/fragment'
import { fragments as addressFragment } from 'App/containers/address/fragment'

export default gql`
  fragment VoucherDetails on Voucher {
    uuid
    type
    paymentType
    status
    createdBy {
      name
      givenName
      nickname
      email
    }
    approvedBy {
      name
      givenName
      nickname
      email
    }
    contactPerson
    description
    internalDescription
    invoiceNumber
    voucherNumber
    subTotal
    taxTotal
    total
    term
    printCount
    accountDate
    isCreditNote
    address {
      ...AddressDetails
    }
    currency {
      ...CurrencyDetails
    }
    permissions {
      canApprove
      canCancel
    }
    balance
    transactionType
    owner {
      ...BaseCompanyDetails
    }
    vendor {
      ...CompanyDetails
    }
    customer {
      ...CompanyDetails
    }
    salesPerson {
      ...UserDetails
    }
    issueDate
    dueDate
    updatedAt
    createdAt
    bookingDocuments {
      uuid
      status
      hardCopyStatus
      location
      type
      url
      document {
        uuid
        name
        type
        size
      }
    }
    voucherPayments {
      ...VoucherPaymentDetails
    }
    voucherItems {
      ...VoucherItemDetails
    }
    approvals {
      ...ApprovalDetails
    }
    bookings {
      uuid
      type
      no
      remarks
      commodities
      jobs {
        uuid
        no
        jobNo
        status
        remarks
        details
        reference {
          haulage
          transport
          shipper
          consignee
          forwardingOri
          forwardingDest
        }
      }
      details
    }
  }

  ${voucherPaymentDetails}
  ${approvalDetails}
  ${voucherItemDetails}
  ${addressFragment.addressDetails}
  ${userFragment.userDetails}
  ${companyFragment.companyDetails}
  ${companyFragment.baseCompanyDetails}
  ${currencyFragment.currencyDetails}
`
