import { gql } from '@apollo/client'

import { fragments as userFragment } from 'App/containers/user/fragment'

export default gql`
  fragment ApprovalDetails on Approval {
    uuid
    type
    status
    approvedBy {
      ...UserDetails
    }
    createdAt
  }

  ${userFragment.userDetails}
`
