import { gql } from '@apollo/client'

export const BOOKING_SEARCH_QUERY = gql`
  query voucherBookings($input: BookingsSearchInput) {
    bookingsSearchJson(input: $input) {
      rows
      # rows {
      #   uuid
      #   no
      #   shipper {
      #     name
      #   }
      #   consignee {
      #     name
      #   }
      #   jobs {
      #     status
      #     type
      #     details
      #   }
      #   type
      #   status
      #   details
      #   createdAt
      #   assignees {
      #     user {
      #       uuid
      #       email
      #       name
      #       nickname
      #     }
      #   }
      #   billTo {
      #     name
      #   }
      #   portOri {
      #     countryAlpha3
      #   }
      #   portDest {
      #     countryAlpha3
      #   }
      # }
    }
  }
`
