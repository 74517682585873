import styles from './WithHeader.module.css'

import { useEffect } from 'react'
import { startCase } from 'lodash'
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router-dom'

import Logo from '../Logo'
import Options from '../Options'
import SearchBox from '../Search'
import Companies from '../Companies'
import ErrorFallback from 'App/ErrorFallback'

import { isUuid } from 'App/utils/u'

export default function WithHeaderLayout({ loggedInUser, children }) {
  const location = useLocation()

  useEffect(() => {
    const pathname = location.pathname.split('/').filter((e) => !isUuid(e))
    const length = pathname.length

    let title
    if (length <= 2) {
      title = pathname?.[1]?.replace('-', ' ')
    } else if (length === 3) {
      title = pathname?.[2]?.replace('-', ' ')
    } else {
      title = pathname?.[3]?.replace('-', ' ')
    }
    document.title = `ShipX ${!title ? '' : '|'} ${startCase(title)}`
  }, [location])

  return (
    <>
      <div className={styles.header}>
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.companies} id='basecompany'>
          <Companies />
        </div>
        <div className={styles.searchBox}>
          <SearchBox />
        </div>
        <div className={styles.options} >
          <Options loggedInUser={loggedInUser} />
        </div>
      </div>

      <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
        <div className={styles.content}>
          {children}
        </div>
      </Sentry.ErrorBoundary>
    </>
  )
}
