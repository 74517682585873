import Label from './Label'
import moment from 'moment'

const EtaEtd = (props) => {
  const { booking } = props
  const label = booking.type === 'EXPORT' ? 'ETD' : 'ETA'
  const date = booking.type === 'EXPORT' ? booking.etd : booking.eta
  const formattedDate = (date && moment(date).format('DD/MM/YY')) || '-'

  return (
    <span>
      <Label>{label}: </Label>
      {formattedDate}
    </span>
  )
}

export default EtaEtd
