import styled from 'styled-components'

const categoryColor = {
  customsPayment: '#f5222d',
  supplierPayment: '#faad14',
  customerInvoice: '#1890ff',
  creditNote: '#52c41a'
}

export const Selector = styled.div`
  display: inline-block;
  width: 192px;
  cursor: pointer;
  padding: 5px 10px;
  margin-right: 10px;
  margin-bottom: 5px;
  box-shadow: 0 0 1px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  border: 1px solid rgb(230, 230, 230);
  border-left: 2px solid ${(props) => categoryColor[props.category]};

  &:hover {
    transition: 0.3s;
    border-color: rgb(200, 200, 200);
    border-left: 2px solid ${(props) => categoryColor[props.category]};
  }
`

export const VoucherType = styled.div`
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const Header = styled.div`
  font-size: 11px;
  font-weight: bold;
  color: rgb(150, 150, 150);
  letter-spacing: 0.2px;
`

export const InvoiceNo = styled.div`
  font-size: 0.75rem;
  font-weight: 500;
  color: black;
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Status = styled.span`
  font-size: 0.7rem;
  font-weight: bold;
  color: rgb(140, 143, 146);
`

export const Invoice = styled.div`
  text-align: right;
  text-overflow: ellipsis;
  overflow: hidden;
`

export const Amount = styled.span`
  font-size: 12px;
  font-weight: 600;
  color: ${(props) =>
    props.isCreditNote ? (props.isAp ? '#1890ff' : '#e03131') : 'rgb(30, 33, 36);'};
`

export const Currency = styled.span`
  font-size: 10.5px;
  font-weight: 600;
  padding-right: 3px;
`
