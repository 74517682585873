import styled from 'styled-components'

export default styled.div`
  line-height: normal;
`

export const Attachment = styled.div`
  display: inline-block;
  font-size: 12px;
  padding: 4px 6px;
  margin-right: 5px;
  background: rgb(240, 240, 240);
  color: rgba(0, 0, 0, 0.45);
  border-radius: 4px;
  line-height: normal;
`

export const NoAttachment = styled.div`
  margin: 5px 0 15px;
  font-size: 13px;
  font-weight: 500;
`
