import styled from 'styled-components'

export const Wrapper = styled.div`
  .ant-select {
    width: 240px;
  }

  .ant-select-selection {
    border-radius: 0;
  }

  .ant-select-selection--single {
    height: 30px;
  }

  .ant-select-selection__rendered {
    line-height: 29px;
    margin: 0 9px;
  }
`

export const Title = styled.div`
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  color: rgb(150, 150, 150);
  padding-bottom: 2px;
`
