import { gql } from '@apollo/client'

import countryDetails from 'App/containers/country/fragment/country'
import { companyDetails } from 'App/containers/company/fragment/company'

export default gql`
  fragment BaseCompanyDetails on BaseCompany {
    uuid
    name
    registration
    logo
    types
    status
    country {
      ...CountryDetails
    }
    company {
      ...CompanyDetails
    }
  }

  ${countryDetails}
  ${companyDetails}
`
