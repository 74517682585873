
import { compose, withStateHandlers } from 'recompose'

import { Button } from 'antd'

import AddCostItemForm from './AddCostItemForm'
import { CostItemModal } from './Styled'

const stateHandlers = withStateHandlers(
  {
    visible: false
  },
  {
    handleClick: (state, props) => () => ({
      visible: true
    }),
    handleOnVisibleChange: (state, props) => (visible) => ({
      visible
    }),
    handleCostItemAdded: (state, props) => (costItem, bookingUuid) => {
      const { onCostItemAdded = () => { } } = props

      onCostItemAdded(costItem, bookingUuid)

      return {
        visible: false
      }
    }
  }
)

const enhance = compose(stateHandlers)

const AddCostItemAction = (props) => {
  const { bookingUuid, visible, handleClick, handleOnVisibleChange, handleCostItemAdded } = props

  return (
    <>
      <Button id='invoice-add-new-cost-item-button' icon="plus" shape="circle" onClick={handleClick} />
      <CostItemModal
        width={600}
        title="Add new cost item"
        footer={null}
        visible={visible}
        destroyOnClose
        onCancel={() => handleOnVisibleChange(false)}
      >
        <AddCostItemForm bookingUuid={bookingUuid} onCostItemAdded={handleCostItemAdded} />
      </CostItemModal>
    </>
  )
}

export default enhance(AddCostItemAction)
