import styled from 'styled-components'

export const Wrapper = styled.div`
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.2);
  background: white;
  margin: 10px 0;
`

export const Title = styled.div`
  font-size: 0.95rem;
  font-weight: 500;
  color: rgb(40, 45, 50);
  text-align: left;
  margin: 0 0;
  padding: 15px 20px 15px;
  background: white;
`

export const Content = styled.div`
  padding: 0px 20px 15px;
`

export const ReferenceLabel = styled.span`
  color: rgb(40, 45, 50);
  text-transform: uppercase;
  font-size: 12.5px;
`
