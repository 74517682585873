import { useQuery } from '@apollo/client'
import { Dropdown, Icon, Menu } from 'antd'
import { memo } from 'react'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import { compose, withState } from 'recompose'
import { bindActionCreators } from 'redux'
import styled from 'styled-components'

import * as voucherActions from 'App/states/reducers/voucher'
import useGlobalCompanyStore from 'App/store/globalCompany'
import { hasPermissionError } from 'App/utils/u'

import { openPrintableInNewTab } from 'App/components/Shared/documentCreator/openPrintableInNewTab'
import { DOCUMENT_CREATOR_TEMPLATES } from 'App/components/Transport/Components/Modals/IncentiveVoucherModal/ModalTitleMenu'

type CNModalTitleMenuProps = {
  client?: any
  jobUuid: string
}

const StyledAnchor = styled.span`
  color: rgba(0, 0, 0, 0.65);
`

const CNModalTitleMenu = memo((props: CNModalTitleMenuProps) => {
  const { client, jobUuid } = props

  const {
    data: dcTemplates,
    error: dcTemplatesError
  } = useQuery(DOCUMENT_CREATOR_TEMPLATES, {
    client,
    variables: {
      q: 'CONSIGNMENT_NOTE',
      statuses: ['activated']
    },
    fetchPolicy: 'cache-and-network'
  })

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  if (hasPermissionError(dcTemplatesError)) {
    return <></>
  }

  const docOptions = (
    <Menu>
      {dcTemplates?.documentCreatorTemplates?.rows?.map((t: any) => (
        <Menu.Item
          key={`viewDoc-${t.type}`}
          style={{ cursor: 'pointer' }}
          onClick={() => openPrintableInNewTab(jobUuid, selectedGlobalCompany.uuid, t.type)}
        >
          <StyledAnchor>
            <Icon type="printer" style={{ marginRight: '10px' }} />
            {t.type}
          </StyledAnchor>
        </Menu.Item>

      ))}
    </Menu>
  )

  return (
    <Dropdown overlay={docOptions}>
      <span>Consignment Note</span>
    </Dropdown>

  )
})

export default compose(
  withApollo,
  withState('showSelections', 'toggleSelections', false),
  connect(null, (dispatch) => ({
    dispatch,
    // @ts-ignore
    ...bindActionCreators(
      /* @ts-ignore */
      { ...voucherActions },
      dispatch
    )
  }))
  // @ts-ignore
)(CNModalTitleMenu)
