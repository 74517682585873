import isEmpty from 'lodash/isEmpty'

import auth from '../utils/auth'
import useUserStore from 'App/store/user'
import { getComponentDisplayName } from '../utils/class'

const WithAuth0 = (WrappedComponent) => {
  const displayName = `Auth0(${getComponentDisplayName(WrappedComponent)})`

  const WithAuth0Component = (props) => {
    const loggedInUser = auth.getProfile() || useUserStore.use.loggedInUser()

    const isAuthenticated = !isEmpty(loggedInUser)

    return (
      <WrappedComponent
        auth={auth}
        {...props}
        loggedInUser={loggedInUser}
        isAuthenticated={isAuthenticated}
      />
    )
  }

  WithAuth0Component.displayName = displayName

  return WithAuth0Component
}

export default WithAuth0
