import React, { Component } from 'react'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'

import { NotAvailable } from '../Styled'
import { Wrapper } from './Styled'
import ApprovalSelector from './Selector'

class ApprovalsView extends Component {
  render() {
    const { bookingDocument, approvedApprovals = [], approvals = [] } = this.props

    if (bookingDocument.status === 'DELETED') {
      return <NotAvailable>Document has been deleted.</NotAvailable>
    } else if (bookingDocument.status === 'UPLOADED') {
      return <NotAvailable>Document is not verified.</NotAvailable>
    } else if (!approvals || !approvals.length) {
      return <NotAvailable>No approvals required.</NotAvailable>
    }

    return (
      <Wrapper>
        {approvals &&
          approvals.map((approval, index) => {
            const approvedApproval = find(approvedApprovals, (aa) => aa.type === approval)

            return (
              <ApprovalSelector
                key={index}
                approvalType={approval}
                approvedApproval={approvedApproval}
                isApproved={!isEmpty(approvedApproval)}
              />
            )
          })}
      </Wrapper>
    )
  }
}

export default ApprovalsView
