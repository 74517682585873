import React, { Component } from 'react'
import { Mutation } from 'react-apollo'

import { Form, Col, Select, Icon, Input, Button } from 'antd'

import { Editable, ToggleActions } from './Styled'
import { Loader } from '../Styled'

const inputs = {
  text: <Input autoComplete="off" spellCheck="false" />,
  tags: <Select mode="tags" />
}

class MutationView extends Component {
  renderEditableActions = (loading) => {
    if (!loading) {
      return (
        <ToggleActions top="8px">
          <Button onClick={this.props.handleToggleMode}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </ToggleActions>
      )
    }

    return (
      <ToggleActions top="16px">
        <Loader>
          <Icon type="loading" /> Updating...
        </Loader>
      </ToggleActions>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { mutationProps, fieldName, value, type = 'text' } = this.props

    const input = inputs[type]

    return (
      <Mutation {...mutationProps} onCompleted={this.props.handleCompleted}>
        {(onMutate, { loading, error }) => {
          return (
            <Form onSubmit={(e) => this.props.handleMutation(e, onMutate)}>
              <Editable type="flex" align="middle">
                <Col span={24}>
                  {getFieldDecorator(fieldName, {
                    initialValue: value || (type === 'tags' ? [] : null),
                    rules: [
                      {
                        required: true,
                        message: 'Field required.'
                      }
                    ]
                  })(input)}
                </Col>
                <Col span={24}>{this.renderEditableActions(loading)}</Col>
              </Editable>
            </Form>
          )
        }}
      </Mutation>
    )
  }
}

export default MutationView
