import { useEffect, useState } from 'react'
import { DatePicker, Input } from 'antd'
import { withApollo } from 'react-apollo'

import SelectWithQuery from './SelectWithQuery'
import EnumSelector from 'App/components/Shared/EnumSelector'
import { FormMode } from 'App/components/Manage/Shared/CrudType/Form'
import PlaceSelector from 'App/components/Booking/DynamicField/PlaceSelector'
import PortPlaceSelector from 'App/components/Booking/DynamicField/PortPlaceSelector'

import {
  DYNAMIC_FIELD_DATE_FORMAT,
  getDynamicFieldInitialValue,
  getDynamicFieldLookupKey,
  getDynamicFieldQuery
} from './helper'

import {
  BookingDynamicFieldQuery,
  BookingDynamicFieldType,
  BookingTypeDynamicFieldControl
} from 'App/types/types'
import type { BookingTypeDynamicField } from 'App/types/types'
import TagsInput from 'App/components/Shared/TagsInput/TagsInput'

type BookingDynamicFieldProps = {
  form: any
  mode: FormMode
  dynamicField: any
  duplicateBookingObject: any
  client?: any
}

const BookingDynamicField = (props: BookingDynamicFieldProps) => {
  const { form, duplicateBookingObject, dynamicField, client, mode } = props

  const [query, setQuery] = useState(getDynamicFieldQuery(dynamicField))

  useEffect(() => {
    if (dynamicField.query !== BookingDynamicFieldQuery.Quotation) return

    const billToUuid = form.getFieldValue('billToUuid')

    if (!billToUuid) return

    setQuery(prev => ({ ...prev, variables: { ...prev.variables, buyerUuid: billToUuid } }))

  }, [form, dynamicField])

  const renderField = (props: BookingTypeDynamicField) => {
    const { key, query: queryType, type, customValues, multiple, isMultiline } = props

    const defaultRule = {
      required: dynamicField.control === BookingTypeDynamicFieldControl.Required,
      message: `${key} is required`
    }

    const fieldKey = getDynamicFieldLookupKey(key || '', mode, type, duplicateBookingObject)

    switch (type) {
      case BookingDynamicFieldType.Selector:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode,
          )
        })(
          (function () {
            switch (queryType) {
              case BookingDynamicFieldQuery.Place:
                return <PlaceSelector />
              case BookingDynamicFieldQuery.Port:
                return <PortPlaceSelector mode={mode} />
              case BookingDynamicFieldQuery.Enums:
                return (
                  <EnumSelector
                    client={client}
                    placeholder="Select a value..."
                    enumName={dynamicField.enumName}
                    multiple={dynamicField.multiple}
                  />
                )
              default: {
                if (queryType === BookingDynamicFieldQuery.CustomValues && !customValues) {
                  return (
                    <TagsInput
                      placeholder={`Enter ${key} here and press <Enter> to add`}
                    />
                  )
                }

                return (
                  <SelectWithQuery
                    fieldKey={fieldKey}
                    query={query}
                    options={
                      queryType === BookingDynamicFieldQuery.CustomValues
                        ? customValues?.split('\n') || []
                        : undefined
                    }
                    mode={
                      queryType === BookingDynamicFieldQuery.CustomValues && !customValues
                        ? 'tags'
                        : multiple
                          ? 'multiple'
                          : 'default'
                    }
                    autoSelectFirst={queryType === BookingDynamicFieldQuery.Quotation}
                  />
                )
              }
            }
          })()
        )
      case BookingDynamicFieldType.Date:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode,
          )
        })(
          <DatePicker
            showTime
            format={DYNAMIC_FIELD_DATE_FORMAT}
            disabled={mode === FormMode.Edit}
            placeholder="Select a date and time"
            // @ts-ignore
            getCalendarContainer={(trigger) => trigger.parentNode}
          />
        )

      default:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode,
          )
        })(
          isMultiline
            ? (
              <Input.TextArea rows={4} autoComplete="off" placeholder={`Enter ${key}`} />
            )
            : (
              <Input autoComplete="off" placeholder={`Enter ${key}`} />
            )
        )
    }
  }

  return renderField(dynamicField)
}

export default withApollo(BookingDynamicField)
