
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { Icon } from 'antd'

import FAIcon from '../../Icons/FA'

export const AnchorIcon = (props) => {
  const { type, fa, size = 20 } = props

  if (fa) {
    return <FAIcon type={type} style={{ fontSize: 18, width: 16, height: 16 }} />
  }

  return <StyledIcon type={type} size={size} />
}

AnchorIcon.propTypes = {
  type: PropTypes.string
}

export const StyledIcon = styled(Icon)`
  font-size: ${(props) => props.size}px;
`

export const AnchorTitle = styled.span`
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  padding: 30px 0;
`

export const LinkWrapper = styled.div`
  background: white;
`

export const CardWrapper = styled.div`
  .ant-card-head {
    min-height: 0;

    .ant-card-head-title {
      padding: 8px 0;
      text-align: center;
    }
  }
`

export const AnchorState = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: rgb(150, 150, 150);
  padding-right: 10px;
  padding-bottom: 5px;
  margin-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`
