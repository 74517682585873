
import { compose } from 'recompose'
import { graphql } from 'react-apollo'
import integrationDetailsQueryGql from 'App/components/Workflow/HaulageJobLink/integrationDetailsQueryGql'

const query = graphql(integrationDetailsQueryGql, {
  name: 'integrationDetailsQuery'
})

const enhance = compose(query)

const format = (template, params) => {
  const tpl = template?.replace(/\${(?!this\.)/g, '${this.')
  const tplFunc = () => {
    return tpl
  }

  return tplFunc.call(params)
}

const HaulageJobLink = (props) => {
  const { job, integrationDetailsQuery } = props

  if (!integrationDetailsQuery.integrationDetails || !job.reference || !job.reference.haulage) {
    return null
  }

  const metaString =
    integrationDetailsQuery.integrationDetails.rows &&
    integrationDetailsQuery.integrationDetails.rows.length > 0 &&
    integrationDetailsQuery.integrationDetails.rows[0].meta

  const meta = JSON.parse(metaString)

  return (
    <a
      href={format(meta.queryUrl, { JobNo: job.reference.haulage })}
      target="_blank"
      rel="noopener noreferrer"
      style={{ marginLeft: '10px' }}
    >
      Link
    </a>
  )
}

export default enhance(HaulageJobLink)
