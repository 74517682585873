import { Component } from 'react'

import withInBookingPage from './Container'
import ModalViewVoucher from './View'

class ModalViewVoucherInBookingPage extends Component {
  render() {
    return <ModalViewVoucher {...this.props} />
  }
}

export default withInBookingPage(ModalViewVoucherInBookingPage)
