import React, { Component } from 'react'
import { compose } from 'recompose'
import { Popconfirm, Button } from 'antd'
import { graphql } from 'react-apollo'

import respHandler from 'App/utils/responseHandler'
import { changeDocumentCreatorStatusGql } from './changeDocumentCreatorStatusGql'

const changeDocumentCreatorStatusGraphql = graphql(changeDocumentCreatorStatusGql, {
  props: ({ mutate, ownProps }) => ({
    changeDocumentCreatorStatus: (uuid, status) =>
      mutate({
        variables: {
          uuid,
          status
        }
      })
  })
})

const enhance = compose(changeDocumentCreatorStatusGraphql)

class DeleteAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  handleConfirm = async () => {
    const {
      changeDocumentCreatorStatus,
      documentCreator: { uuid },
      onDelete
    } = this.props

    try {
      this.setState({
        loading: true
      })

      await changeDocumentCreatorStatus(uuid, 'VOID')

      if (onDelete) {
        onDelete()
      }
    } catch (error) {
      console.log(error)
      respHandler(error, 'error')
    } finally {
      this.setState({
        loading: false
      })
    }
  }

  render() {
    return (
      <Popconfirm
        title={'Confirm to delete this document creator?'}
        onConfirm={this.handleConfirm}
        okText="Yes"
        okType="danger"
        cancelText="No"
      >
        <Button type="danger" loading={this.state.loading}>
          Delete
        </Button>
      </Popconfirm>
    )
  }
}

export default enhance(DeleteAction)
