import React, { useState } from 'react'
import { Row, Col, Radio, Button } from 'antd'

import TemplateContent from './TemplateContent'
import DataForm from './DataForm'

const ModalContent = (props) => {
  const { documentCreator, form, refreshTime } = props
  const { getFieldDecorator } = form

  const [view, setView] = useState('preview')
  const disabled = documentCreator.status !== 'DRAFT'

  return (
    <Row gutter={16} type="flex">
      <Col span={16}>
        <Row type="flex" justify="space-between" style={{ marginBottom: '8px' }}>
          <Col>
            <h3>Template</h3>
          </Col>
          <Col>
            <Radio.Group value={view} size="small" onChange={(e) => setView(e.target.value)}>
              <Radio.Button value="preview" size="small">
                Preview
              </Radio.Button>
              <Radio.Button value="html" size="small">
                HTML
              </Radio.Button>
            </Radio.Group>
            <Button
              type="link"
              icon="export"
              href={`/documentCreator/view/${documentCreator.uuid}`}
              target="_blank"
              size="small"
              style={{ marginLeft: '8px' }}
            />
          </Col>
        </Row>
        {getFieldDecorator('template', {
          initialValue: documentCreator.template
        })(
          <TemplateContent
            view={view}
            documentCreator={documentCreator}
            disabled={disabled}
            refreshTime={refreshTime}
          />
        )}
      </Col>
      <Col span={8}>
        <h3>Data</h3>
        <DataForm documentCreator={documentCreator} form={form} />
      </Col>
    </Row>
  )
}

export default ModalContent
