import { graphql } from 'react-apollo'

import schema from '../schema'
import bookingSchema from 'App/containers/booking/schema'

export default graphql(schema.DELETE_VOUCHER_ITEM, {
  props: ({ mutate, ownProps }) => ({
    deleteVoucherItem: (params) =>
      mutate({
        variables: params
      })
  })
})

export const deleteVoucherItemWithRefetchBooking = graphql(schema.DELETE_VOUCHER_ITEM, {
  props: ({ mutate, ownProps }) => ({
    deleteVoucherItem: (params, bookingUuid) =>
      mutate({
        variables: params,
        refetchQueries: [
          {
            query: bookingSchema.BOOKING_VOUCHER_COST_SHEET,
            variables: {
              uuid: bookingUuid
            }
          }
        ]
      })
  })
})
