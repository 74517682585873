import { graphql } from 'react-apollo'

import bookingSchema from 'App/containers/booking/schema'
import schema from '../schema'

export default graphql(schema.COST_ITEMS_UPDATE, {
  props: ({ mutate, ownProps }) => ({
    updateCostItem: (params, bookingUuid) =>
      mutate({
        variables: {
          input: params
        },
        refetchQueries: [
          {
            query: bookingSchema.BOOKING_VOUCHER_COST_SHEET,
            variables: {
              uuid: bookingUuid
            }
          }
        ],
        awaitRefetchQueries: true
      })
  })
})
