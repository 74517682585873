import { Button, Icon, Popconfirm } from 'antd'

import config from 'App/config'
import webStorage from 'App/utils/webStorage'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import useGlobalCompanyStore from 'App/store/globalCompany'

import ResourceViewer from 'App/components/ResourceViewer'
import { ModalViewer } from 'App/components/DocumentViewer/Styled'
import { DocumentView, PreviewWrapper } from 'App/components/DocumentViewer/Content/Styled'

function TaxDocumentViewer({ uuid, setDocumentUuid, toggleModal, setToggleModal }) {

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const taxExemptionDocUrl = `${config.api.baseUrl}/booking/document/${uuid}`

  const closeModal = () => setToggleModal(false)

  return (
    <div>
      {/* @ts-ignore */}
      <ModalViewer
        title={null}
        footer={null}
        closable={false}
        visible={toggleModal}
        onCancel={closeModal}
      >
        <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Icon
            type="close"
            style={{ display: 'flex', justifyContent: 'flex-end', padding: 20 }}
            onClick={closeModal}
          />
          <DocumentView>
            {/* @ts-ignore */}
            <PreviewWrapper type={'application/pdf'}>
              <ResourceViewer
                url={taxExemptionDocUrl}
                mimeType={'application/pdf'}
                fetchOptions={{
                  method: 'GET',
                  headers: {
                    authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
                    'base-company-uuid': selectedGlobalCompany.uuid
                  }
                }}
              />
            </PreviewWrapper>
          </DocumentView>

          {setDocumentUuid && (
            <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 10, gap: 10 }}>
              <Button type="danger" key={'delete'}>
                <Popconfirm
                  title="Delete this document?"
                  onConfirm={() => {
                    setDocumentUuid('')
                    setToggleModal(false)
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <a href="#">Delete</a>
                </Popconfirm>
              </Button>

              <Button type="primary" key={'ok'} onClick={closeModal}>
                Ok
              </Button>
            </div>
          )}
        </div>
      </ModalViewer>
    </div>
  )
}

export default TaxDocumentViewer
