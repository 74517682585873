import { setUser } from '@sentry/react'
import { useHistory } from "react-router-dom"
import { Auth0Provider } from "@auth0/auth0-react"

import config from 'App/config'
import useUserStore from "App/store/user"
import { BASE_URL } from 'App/utils/website'

export const Auth0ProviderWithHistory = ({ children }) => {
  const history = useHistory()

  const setLoggedInUser = useUserStore.use.setLoggedInUser()

  const domain = config.auth0.domain
  const clientId = config.auth0.clientId
  const redirectUri = `${BASE_URL}/auth/check-in`

  const onRedirectCallback = (appState, user) => {
    // set User to Zustand store
    setLoggedInUser(user)
    // set user's email to Sentry
    setUser({ email: user.email })
    /*
      Once login is successful, Auth0 will redirect to the path set in the
      Auth0.com -> Applications -> Application URIs -> Allowed Callback URLs
    */
    history.push(appState?.returnTo || window.location.pathname)
  }

  if (!(domain && clientId)) {
    return null
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      authorizationParams={{ redirect_uri: redirectUri }}
    >
      {children}
    </Auth0Provider>
  )
};
