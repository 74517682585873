import { Button, message } from 'antd'
import { withApollo } from 'react-apollo'
import { memo, useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { uniq } from 'lodash'

import { logger } from 'App/utils/logger'
import makeBookingGql from './makeBookingGql'
import respHandler from 'App/utils/responseHandler'
import convertValuesToInput from './convertValuesToInput'
import { VIEW_BOOKING_TYPE_GQL } from 'App/components/Booking/General'

import useJobTypes from 'App/hooks/useJobTypes'

import type { BookingTypeDynamicField } from 'App/types/types'

const SubmitAction = memo((props: any) => {
  const { form, client, onSuccess = () => { } } = props
  const [dynamicFields, setDynamicFields] = useState<BookingTypeDynamicField[]>([])

  const [makeBooking, { loading }] = useMutation(makeBookingGql, { client })
  const { requestJobTypes, jobTypes } = useJobTypes(client)

  useEffect(() => {
    const jobTypesKeys = uniq(form.getFieldValue('jobs').map((job) => job.type)).filter(
      Boolean
    ) as string[]
    requestJobTypes(jobTypesKeys)
    refetchBookingType(form.getFieldValue('type'))
  }, [form.getFieldValue('type'), form.getFieldValue('jobs')])

  const { refetch: refetchBookingType } = useQuery(VIEW_BOOKING_TYPE_GQL, {
    client,
    variables: { code: form.getFieldValue('type') },
    onCompleted: (data) => {
      setDynamicFields(data?.bookingType?.dynamicFields)
    }
  })

  const handleClick = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (!values.details) values.details = {}

      if (err) return

      try {
        const input = convertValuesToInput(values, dynamicFields, jobTypes)
        const { data } = await makeBooking({ variables: { input } })
        onSuccess(data.makeBooking.uuid)
      } catch (error: any) {
        logger.error('SubmitAction makeBookingGql error', error)
        if (error?.message?.includes('Not allowed to create booking as with this Company')) {
          message.error(error.message)
        } else {
          respHandler(error, 'error')
        }
      }
    })
  }, [form, dynamicFields, jobTypes, makeBooking, onSuccess])

  return (
    <Button id='submit-new-booking-button' type="primary" loading={loading} onClick={handleClick}>
      Submit
    </Button>
  )
})

export default withApollo(SubmitAction)
