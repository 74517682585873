import { graphql } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import { compose, withHandlers, withState } from 'recompose'
import { Button, Popconfirm, Popover, Input, Form } from 'antd'

import { CHANGE_BOOKING_STATUS_SLIM } from 'App/graphql/booking'
import { BASIC_BOOKING_SLIM_QUERY } from 'App/containers/booking/schema/basicBookingQuerySlim'
import respHandler from 'App/utils/responseHandler'
import { logger } from 'App/utils/logger'

const withGraphqlChangeBookingStatus = graphql(CHANGE_BOOKING_STATUS_SLIM, {
  props: ({ mutate }) => ({
    changeBookingStatus: (uuid, status, remarks) =>
      mutate({
        variables: {
          uuid,
          status,
          remarks
        },
        refetchQueries: [
          {
            query: BASIC_BOOKING_SLIM_QUERY,
            variables: {
              uuid
            }
          }
        ]
      })
  })
})

const withClickHandler = withHandlers({
  onBookingAction: (props) => async () => {
    const { form, booking, buttonText, changeBookingStatus, newBookingStatus } = props

    let remarks = null

    remarks = form?.getFieldValue('description')

    if (buttonText === 'Reject' || buttonText === 'Cancel') {
      if (!remarks?.length) {
        return respHandler('Please enter a description.', 'error')
      }
    }

    respHandler('Updating booking, hang on...', 'load')

    try {
      const res = await changeBookingStatus(booking.uuid, newBookingStatus, remarks)

      if (res?.data?.changeBookingStatus?.uuid) {
        respHandler('Updated successfully.', 'success')
        window.location.reload()
      }
    } catch (error) {
      logger.error('BookingActionButton changeBookingStatus error', error)
      respHandler(error, 'error')
    }
  }
})

const enhance = compose(
  withRouter,
  withGraphqlChangeBookingStatus,
  Form.create(),
  withState('showCancelBooking', 'setShowCancelBooking', false),
  withClickHandler
)

const BookingActionButton = (props) => {
  const {
    buttonText,
    type,
    showCancelBooking,
    setShowCancelBooking,
    form: { getFieldDecorator }
  } = props

  const renderForm = () => {
    return (
      <Form>
        <Form.Item name="description" label="Why:">
          {getFieldDecorator('description', {
            rules: [
              {
                required: true,
                message: 'Description is required.'
              }
            ]
          })(
            <Input.TextArea name="description" rows={4} />
          )}
        </Form.Item>

        <Button onClick={() => setShowCancelBooking(false)}>
          Cancel
        </Button>

        <Button type="danger" onClick={props.onBookingAction}>
          Yes
        </Button>
      </Form>
    )
  }

  if (buttonText === 'Uncancel') {
    return (
      <Popover
        okText="Yes"
        okType={type}
        cancelText="No"
        trigger="click"
        placement="bottom"
        content={renderForm()}
        visible={showCancelBooking}
        overlayStyle={{ width: 200 }}
        title={'Uncancel this booking?'}
        onVisibleChange={(visible) => setShowCancelBooking(visible)}
      >
        <Button type={type} ghost>
          {buttonText}
        </Button>
      </Popover>
    )
  }

  if (buttonText === 'Reject' || buttonText === 'Cancel') {
    return (
      <Popover
        title={
          buttonText === 'Cancel' ? 'Cancel this booking?' : 'Reject this booking?'
        }
        okText="Yes"
        okType={type}
        cancelText="No"
        trigger="click"
        placement="bottom"
        content={renderForm()}
        visible={showCancelBooking}
        overlayStyle={{ width: 200 }}
        onVisibleChange={(visible) => setShowCancelBooking(visible)}
      >
        <Button type={type} ghost>
          {buttonText}
        </Button>
      </Popover>
    )
  }

  return (
    <Popconfirm
      placement="bottom"
      title="Are you sure?"
      okText="Yes"
      okType={type}
      cancelText="No"
      onConfirm={props.onBookingAction}
    >
      <Button id='confirm-accept-button' type={type}>{buttonText}</Button>
    </Popconfirm>
  )
}

export default enhance(BookingActionButton)
