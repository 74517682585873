import { memo, forwardRef, useMemo, useState, useEffect, useCallback } from 'react'
import { gql, useQuery } from '@apollo/client'
import { withApollo } from 'react-apollo'
import debounce from 'lodash/debounce'
import { Select } from 'antd'

import { logger } from 'App/utils/logger'
import { hasPermissionError } from 'App/utils/u'

const BILLING_UNITS_QUERY = gql`
  query billingUnitsSelector2(
    $limit: Int
    $offset: Int
    $q: String
    $statuses: [BillingUnitStatus]
  ) {
    billingUnits(limit: $limit, offset: $offset, q: $q, statuses: $statuses) {
      rows {
        uuid
        code
      }
    }
  }
`

const Option = Select.Option

const BillingUnitSelect = forwardRef((props, ref) => {
  const {
    client,
    onChange,
    value,
    style,
    getUuid,
    getAll,
    mode = 'default',
    allowClear = true
  } = props

  const [billingUnits, setBillingUnits] = useState([])
  const [searchInput, setSearchInput] = useState('')

  const params = useMemo(
    () => ({
      q: mode === 'multiple' ? searchInput || '' : value || searchInput || '',
      statuses: getAll ? ['activated', 'deleted'] : ['activated'],
      limit: 20
    }),
    [value, searchInput]
  )

  const { loading, error, data, refetch } = useQuery(BILLING_UNITS_QUERY, {
    client,
    variables: params,
    fetchPolicy: 'cache-first'
  })

  const onSearch = useCallback(
    debounce((value, options) => {
      try {
        setSearchInput(value)
        refetch()
      } catch (error) {
        // ok to fail when the component has already unmounted
      }
    }, 500),
    []
  )

  useEffect(() => {
    if (setBillingUnits) {
      setBillingUnits(data?.billingUnits?.rows)
    }
  }, [data])

  if (error) {
    logger.error('BillingUnitSelect BillingUnitQuery error', error)
  }

  return (
    <Select
      ref={ref}
      allowClear
      showSearch
      mode={mode}
      style={style}
      value={value}
      loading={loading}
      onChange={onChange}
      onSearch={onSearch}
      filterOption={mode === 'multiple'}
      placeholder="Select a billing unit..."
      notFoundContent={
        loading
          ? 'Searching...'
          : error && hasPermissionError(error)
            ? 'No permission to view this.'
            : 'Not found.'
      }
    >
      {billingUnits?.map((unit) => (
        <Option key={unit.uuid} value={getUuid ? unit.uuid : unit.code}>
          {unit.code}
        </Option>
      ))}
    </Select>
  )
})

export default withApollo(memo(BillingUnitSelect))
