import styled from 'styled-components'

import { Row } from 'antd'

export const StyledRow = styled(Row)`
  height: 100vh;

  .ant-input {
    font-size: 13.5px;
    font-weight: 400;
    color: #151b26;
    height: 38px;
    line-height: 36px;
  }

  .ant-form-item {
    margin-bottom: 10px;
  }

  .ant-btn {
    font-size: 14.222px;
    font-weight: 600;
    height: 40px;
    width: 100%;
    margin-top: 10px;
  }

  .has-error .ant-form-explain {
    margin-top: 0px;
    font-size: 13.222px;
  }
`

export const CheckInPanel = styled(Row)`
  height: 100vh;
  text-align: center;

  .load-text {
    font-size: 15.222px;
    font-weight: 400;
    color: #151b26;
    margin-bottom: 15px;
  }

  .anticon {
    color: rgb(210, 210, 210);
    font-size: 2rem;
  }

  .anticon-spin:before {
    animation: loadingCircle 0.7s infinite linear;
  }
`

export const PanelTitle = styled.div`
  font-size: 26px;
  font-weight: 500;
  color: #151b26;
  padding: 30px 0 6px;
`

export const PanelDescription = styled.div`
  font-size: 14.222px;
  font-weight: 400;
  color: #151b26;
  margin-bottom: 30px;
`

export const LogoDescription = styled.div`
  font-size: 21.222px;
  font-weight: 500;
  color: #151b26;
  padding: 25px 0 10px;
  letter-spacing: 0.2px;
`

export const OtherActions = styled.div`
  font-size: 14.222px;
  color: #151b26;
  text-align: center;
  padding-top: 30px;

  a {
    font-size: 13.5px;
    font-weight: 500;
  }
`

export const LightNotice = styled.div`
  padding-top: 20px;
  margin-top: 20px;
  font-size: 13.5px;
  text-align: justify;
  letter-spacing: 0.1px;
  color: rgb(135, 135, 135);
  border-top: 1px solid rgb(230, 230, 230);
`
