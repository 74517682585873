import styled from 'styled-components'

export const Formatted = styled.div`
  line-height: normal;
`

export const CurrencyText = styled.span`
  font-size: 11.5px;
  font-weight: 600;
  color: rgb(170, 170, 170);
  padding-right: 5px;
`

export const NumericText = styled.span`
  font-size: 13px;
  font-weight: 500;
`
