
import { Button, Row, Col } from 'antd'

import DeleteAction from '../../../DeleteAction'
import PdfManager from '../PdfManager'
import SubmitButton from './SubmitButton'
import ApproveButton from './ApproveButton'
import CancelButton from './CancelButton'
import SaveButton from './SaveButton'

const ModalFooter = (props) => {
  const { documentCreator, form, onChange = () => { }, onClose = () => { } } = props

  if (
    !documentCreator ||
    documentCreator.status === 'VOID' ||
    documentCreator.status === 'CANCELLED'
  ) {
    return (
      <Row>
        <Col span={24}>
          <Button onClick={onClose}>Close</Button>
        </Col>
      </Row>
    )
  }

  if (documentCreator.status === 'DRAFT') {
    return (
      <Row type="flex" justify="space-between">
        <Col>
          <DeleteAction documentCreator={documentCreator} onDelete={onClose} />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <SaveButton documentCreator={documentCreator} form={form} onChange={onChange} />
          <SubmitButton documentCreator={documentCreator} form={form} onChange={onChange} />
        </Col>
      </Row>
    )
  }

  if (documentCreator.status === 'SUBMITTED') {
    return (
      <Row type="flex" justify="space-between">
        <Col style={{ textAlign: 'left' }}>
          <CancelButton documentCreator={documentCreator} onChange={onClose} />
        </Col>
        <Col>
          <Row type="flex" gutter={8}>
            <Col>
              <PdfManager documentCreatorUuid={documentCreator.uuid} />
            </Col>
            <Col>
              <ApproveButton documentCreator={documentCreator} onChange={onChange} />
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  if (documentCreator.status === 'APPROVED') {
    return (
      <Row type="flex" justify="space-between">
        <Col style={{ textAlign: 'left' }}>
          <CancelButton documentCreator={documentCreator} onChange={onClose} />
        </Col>
        <Col>
          <Row type="flex" gutter={8}>
            <Col>
              <PdfManager documentCreatorUuid={documentCreator.uuid} />
            </Col>
            <Col>
              <Button onClick={onClose}>Close</Button>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  return null
}

export default ModalFooter
