import styled from 'styled-components'

export const StyledButton = styled.span`
  color: #1890ff;
  background-color: transparent;
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
`
