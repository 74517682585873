import { Button, Result } from 'antd'
import { useTranslation } from 'react-i18next';

function ErrorFallback() {
  const { t } = useTranslation()

  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'row',
      justifyContent: 'center',
    }}>
      <Result
        status='500'
        title={t('error.somethingWentWrong')}
        subTitle={t('error.pleaseContactItSupportForFurtherAssistance')}
        extra={<Button style={{ height: 30 }} href='/' type="primary">{t('error.backHome')}</Button>}
      />
    </div>
  )
}

export default ErrorFallback