import { gql } from '@apollo/client'

import { fragments as costItemFragment } from '../fragment'

export default gql`
  query costItems1($uuid: UUID!) {
    costItems(uuid: $uuid) {
      ...CostItemDetails
    }
  }

  ${costItemFragment.costItemDetails}
`
