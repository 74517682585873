export const actions = {
  UPDATE_COST_ITEMS: 'UPDATE_COST_ITEMS',
  UPDATE_VOUCHER_ITEMS: 'UPDATE_VOUCHER_ITEMS'
}

export const updateCostItems = (costItems) => {
  return {
    type: actions.UPDATE_COST_ITEMS,
    costItems
  }
}

export const updateVoucherItems = (voucherItems) => {
  return {
    type: actions.UPDATE_VOUCHER_ITEMS,
    voucherItems
  }
}

const initialState = {
  costItems: [],
  voucherItems: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_COST_ITEMS:
      return { ...state, costItems: action.costItems }
    case actions.UPDATE_VOUCHER_ITEMS:
      return { ...state, voucherItems: action.voucherItems }
    default:
      return state
  }
}
