import { gql } from '@apollo/client'

export const YARDS_QUERY = gql`
  query yardsMain1($input: YardQueryInput!) {
    yards(input: $input) {
      rows {
        uuid
        ownerUuid

        status
        code
        name
        remarks
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`
