import styled from 'styled-components'
import { List } from 'antd'

export const EventType = styled.div`
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.1px;
  color: rgb(140, 143, 146);
  text-transform: uppercase;
`

export const EventActor = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: black;
`

export const EventTime = styled.div`
  font-size: 11px;
  font-weight: 500;
  color: rgb(150, 152, 154);
  letter-spacing: 0.3px;
  display: inline;
`

export const EventPayloadDiv = styled.div`
  word-break: break-all;
`

export const EventTitleSpan = styled.span`
  cursor: pointer;
`

export const StyledEventListItem = styled(List.Item)`
  .ant-list-item-meta-avatar {
    margin-top: 0;
  }
`

export const NoEvents = styled.div`
  font-size: 12.5px;
  font-weight: 600;
  letter-spacing: 0.2px;
  text-align: center;
`

export const EventToggle = styled.div`
  text-align: center;

  .ant-btn {
    font-size: 13px;
    height: 30px;
    letter-spacing: 0.1px;

    .anticon {
      font-size: 14px;
    }
  }
`
export const IntegrationLog = styled.div`
  height: 30px;
  vertical-align: middle;
`
