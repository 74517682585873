import { memo } from 'react'
import useGlobalCompanyStore from 'App/store/globalCompany'
import { openPrintableInNewTab } from 'App/components/Shared/documentCreator/openPrintableInNewTab'

export default memo((props: any) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  return (
      <>
        {props.templates?.map((template: any) => (
          <div key={template.uuid} style={{ marginBottom: '5px', cursor: 'pointer' }} onClick={() => openPrintableInNewTab(props.trip.uuid, selectedGlobalCompany.uuid, template.type)}>
          {template.name}
        </div>
        ))}
      </>
  );
})
