import { isEmpty, pick } from 'lodash'
import { parse } from 'query-string'

export const basicQueryKeys = ['q', 'sort', 'limit', 'offset'] as const

export const facets = ['status', 'isAssigned', 'userUuids', 'companyUuids'] as const

export const fullQueryKeys = [...basicQueryKeys, ...facets, 'filter', '__noSubGraph'] as const

type NewQuery = {
  [key in (typeof fullQueryKeys)[number]]?: any
}

export const getBookingQuery = (history: any, newQuery: NewQuery = {}, clear = false) => {
  const fromURL = history.location.search.slice(1)

  const urlData: any = parse(fromURL)
  const pickBasicQuery = pick(urlData, basicQueryKeys)
  // @ts-ignore
  let filter = urlData?.filter ? JSON.parse(urlData?.filter) : undefined
  if (newQuery.filter || filter) {
    filter = Object.assign(filter || {}, newQuery.filter)
  }

  const newBasicQuery: any = {
    ...pickBasicQuery,
    ...pick(newQuery, basicQueryKeys),
    filter: !filter || isEmpty(filter) ? undefined : filter
  }

  const urlFormat: any = { ...newBasicQuery }
  if (filter) {
    urlFormat.filter = JSON.stringify(filter)
  }
  const graphqlFormat = { ...newBasicQuery, filter }

  return {
    urlFormat,
    graphqlFormat
  }
}
