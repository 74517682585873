import styled from 'styled-components'

export const TableWrapper = styled.div`
  padding: 10px 0;

  .ant-table-thead > tr > th {
    padding: 16px 10px;
  }

  .ant-table-tbody > tr:hover > td {
    background: rgb(245, 247, 249);
  }

  .ant-table-tbody > tr > td {
    padding: 15px 10px;
  }

  .ant-pagination-simple-pager {
    font-size: 13.5px;
    font-weight: 500;
  }

  .anticon-edit {
    cursor: pointer;
    font-size: 16px;
    color: rgb(120, 120, 120);

    &:hover {
      color: black;
    }
  }
`

export const StyledAnchor = styled.span`
  font-size: 13.222px;
  font-weight: 500;
  color: #151b26;

  &:hover {
    color: #1890ff;
  }
`

export const StyledBlockAnchor = styled.a`
  font-size: 13px;
  font-weight: 600;
  color: #151b26;
  display: block;
  max-width: 400px;

  &:hover {
    color: #1890ff;
  }
`

export const StatusText = styled.div`
  font-size: 11px;
  font-weight: 600;
  color: rgb(150, 150, 150);
  text-transform: uppercase;
  letter-spacing: 0.6px;
`

export const NormalText = styled.span`
  font-size: 13px;
  font-weight: 400;
  color: #151b26;
  letter-spacing: 0.1px;
`

export const BoldText = styled(NormalText)`
  font-weight: 600;
`

export const Muted = styled.span`
  font-size: 12.222px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: rgb(120, 120, 120);
`

export const MutedDark = styled(Muted)`
  color: black;
`

export const MutedDescription = styled(Muted)`
  color: rgb(150, 150, 150);
  padding-top: 2px;
`

export const NoItemsFound = styled.div`
  padding: 20px 0;
  font-size: 13.5px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 0.3px;
  color: rgb(150, 150, 150);
`
export const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
`
export const NoPermissionWrapper = styled.div`
  height: 100%;
  display: flex;
  color: light-gray;
  align-items: center;
  font-weight: normal;
  justify-content: center;
`
