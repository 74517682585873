import { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'

import JobsMap from 'App/components/Transport/Components/VehicleMap/JobsMaps/JobsMap'

import type { QueryTruckLocationByTripsArgs } from 'App/types/types'

type JobsMapProps = {
  client?: any
  showScrollZoom?: boolean
  jobTripUuids: QueryTruckLocationByTripsArgs[]
  showNavigationControl?: boolean
}

export type JobMapState = {
  data: any
  index: number
  loading: boolean
  isWaiting: boolean
  showScrollZoom?: boolean
  showNavigationControl?: boolean
} & QueryTruckLocationByTripsArgs


const QUERY_TRUCK_LOCATION_BY_TRIPS = gql`
  query truckLocationByTripsV2($jobUuids: [String], $tripUuids: [String]) {
    truckLocationByTripsV2(jobUuids: $jobUuids, tripUuids: $tripUuids) {
      trip {
        jobNo
        tripUuid
        jobType
        tripType
      }
    }
  }
`

const JobsMaps = (props: JobsMapProps) => {
  const { jobTripUuids = [], client, showScrollZoom, showNavigationControl } = props

  const [currentIndex, setCurrentIndex] = useState(0)
  const [jobMapState, setJobMapState] = useState<JobMapState[]>([])

  const [getLocations] = useLazyQuery(QUERY_TRUCK_LOCATION_BY_TRIPS, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const jobMapStates = [...jobMapState].map((jms, index) => {
        if (index === currentIndex) {
          return {
            ...jms,
            loading: false,
            isWaiting: false,
            data: data.truckLocationByTripsV2
          }
        }

        if (index === currentIndex + 1) {
          return {
            ...jms,
            loading: true,
            isWaiting: false
          }
        }
        return jms
      })

      setJobMapState(jobMapStates)
      setCurrentIndex(currentIndex + 1)
    },
  })

  useEffect(() => {
    if (!jobTripUuids.length) return

    const jobMapStates = jobTripUuids.map((jt, index) => ({
      index,
      data: null,
      jobUuids: jt.jobUuids,
      tripUuids: jt.tripUuids,
      loading: index === 0,
      isWaiting: index === 0 ? false : true
    }))

    setJobMapState(jobMapStates)

  }, [jobTripUuids])

  useEffect(() => {
    const current = jobMapState[currentIndex]

    if (currentIndex > jobMapState.length - 1) return

    if (current.data) {
      const jobMapStates = [...jobMapState].map((jms, index) => {
        if (index === currentIndex) {
          return {
            ...jms,
            loading: false,
            isWaiting: false
          }
        }
        return jms
      })
      setJobMapState(jobMapStates)
      setCurrentIndex(currentIndex + 1)
      return
    }

    getLocations({
      variables: {
        jobUuids: current.jobUuids,
        tripUuids: current.tripUuids
      }
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex, jobMapState])

  return (
    <>
      {jobMapState.map((jobMapState) =>
        <JobsMap key={jobMapState.index} {...jobMapState} showScrollZoom={showScrollZoom} showNavigationControl={showNavigationControl} />)
      }
    </>
  )
}

export default JobsMaps
