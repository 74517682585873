import styled from 'styled-components'

export const FieldItemView = styled.div`
  padding: 5px 0;
  margin-bottom: 10px;
`

export const ItemName = styled.div`
  font-size: 13.5px;
  font-weight: 500;
  color: #1b2733;
  padding-bottom: 2px;
  letter-spacing: 0.1px;
`

const valueStyles = {
  bold: `
    font-size: 11.222px;
    font-weight: bold;
    color: rgb(180, 180, 180);
  `,
  normal: `
    font-size: 13.5px;
    font-weight: 400;
    color: rgb(120,120,120);
  `
}

export const ItemValue = styled.div`
  ${(props) => props.type && valueStyles[props.type]}
`
