import { Button } from 'antd'
import { ActiveStatus } from 'App/types/types'

const DeleteActionButton = (props: any) => {
  const {
    item,
    handleSubmit,
    handleStatusChange,
    parentLoading,
    submitText,
    activeStatus = ActiveStatus.Active
  } = props

  return (
    <>
      <Button type="danger" onClick={(e) => handleStatusChange(e)}>
        {item?.status === activeStatus ? 'Delete' : 'Undelete'}
      </Button>
      <Button type="primary" loading={parentLoading} onClick={(e) => handleSubmit(e)}>
        {submitText}
      </Button>
    </>
  )
}

export default DeleteActionButton
