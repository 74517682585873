import { gql } from '@apollo/client'

export default gql`
  query getBookingEvents($uuid: UUID!) {
    booking(uuid: $uuid) {
      events {
        ... on BookingEvent {
          context
          event
          time
          payload
          user {
            uuid
            nickname
            email
            picture
          }
        }
        ... on MessageEvent {
          event
          time
          payload
          user {
            uuid
            nickname
            email
            picture
          }
        }
      }
    }
  }
`
