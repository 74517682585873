import { useState } from 'react'
import { Button } from 'antd'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'

import respHandler from 'App/utils/responseHandler'
import changeDocumentCreatorStatusAdvancedGql from './changeDocumentCreatorStatusAdvancedGql'
import { updateDocumentCreatorGql } from './updateDocumentCreatorGql'

const changeDocumentCreatorStatusAdvancedGraphql = graphql(changeDocumentCreatorStatusAdvancedGql, {
  props: ({ mutate, ownProps }) => ({
    changeDocumentCreatorStatusAdvanced: (uuid, status) =>
      mutate({
        variables: {
          uuid,
          status
        }
      })
  })
})

const updateDocumentCreatorGraphql = graphql(updateDocumentCreatorGql, {
  props: ({ mutate, ownProps }) => ({
    updateDocumentCreator: (input) =>
      mutate({
        variables: {
          input
        }
      })
  })
})

const enhance = compose(updateDocumentCreatorGraphql, changeDocumentCreatorStatusAdvancedGraphql)

const SubmitButton = (props) => {
  const {
    documentCreator,
    form,
    updateDocumentCreator,
    changeDocumentCreatorStatusAdvanced,
    onChange = () => {}
  } = props

  const [loading, setLoading] = useState(false)
  const handleClick = async () => {
    form.validateFields(async (err, values) => {
      if (err) return

      setLoading(true)

      try {
        const input = {
          uuid: documentCreator.uuid,
          ...values
        }

        await updateDocumentCreator(input)
        await changeDocumentCreatorStatusAdvanced(documentCreator.uuid, 'SUBMITTED')

        onChange()

        respHandler('Document creator is submitted successfully.', 'success')
      } catch (error) {
        console.log(error)
        respHandler(error, 'error')
      }
      setLoading(false)
    })
  }

  return (
    <Button type="primary" loading={loading} onClick={handleClick}>
      Save & Submit
    </Button>
  )
}

export default enhance(SubmitButton)
