import React, { Component } from 'react'
import { Select } from 'antd'

const Option = Select.Option

const types = [
  {
    label: 'Billing',
    value: 'BILLING'
  },
  {
    label: 'Delivery',
    value: 'DELIVERY'
  },
  {
    label: 'Contact',
    value: 'CONTACT'
  },
  {
    label: 'Warehouse',
    value: 'WAREHOUSE'
  }
]

class AddressTypesSelect extends Component {
  handleChange = (value) => {
    const { onChange } = this.props
    onChange(value)
  }

  render() {
    const { value } = this.props

    return (
      <Select
        mode="multiple"
        showSearch
        showArrow
        value={value}
        getPopupContainer={(triggerNode) => triggerNode.parentNode}
        onChange={this.handleChange}
        style={{ width: '100%' }}
      >
        {types.map((type, index) => (
          <Option key={index} value={type.value}>
            {type.label}
          </Option>
        ))}
      </Select>
    )
  }
}

export default AddressTypesSelect
