import { useEffect, useState } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { withApollo } from 'react-apollo'
import startCase from 'lodash/startCase'
import styled from 'styled-components'
import { Form, Row, Col } from 'antd'

import DynamicField from 'App/components/Booking/DynamicField'
import { FormMode } from 'App/components/Manage/Shared/CrudType/Form'
import DynamicTransportSelect from 'App/components/Select/DynamicSelector'
import CompanySelect from 'App/components/Select/TypeToFetch/CompanySelect'

import { useBookingStore } from 'App/store/booking'

import { BookingTypeDynamicFieldChronologyLevel, BookingTypeDynamicFieldControl, BookingDynamicFieldType } from 'App/types/types'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const StyledDiv = styled.div`
  .ant-form-item-control {
    margin-bottom: 10px;
  }
`

const BOOKING_TYPES_QUERY = gql`
  query bookingTypesNewBookingForm(
    $limit: Int
    $offset: Int
    $q: String
    $statuses: [BookingTypeStatus]
    $byCompany: UUID
  ) {
    bookingTypes(limit: $limit, offset: $offset, q: $q, statuses: $statuses, byCompany: $byCompany) {
      rows {
        code
        uuid
        name
      }
    }
  }
`

const VIEW_BOOKING_TYPE_GQL = gql`
  query bookingTypeNewBooking($uuid: UUID, $code: String) {
    bookingType(uuid: $uuid, code: $code) {
      code
      dynamicFields {
        key
        type
        query
        companyTypes
        control
        multiple
        enumName
        customValues
        isMultiline
        chronologyLevel
      }
    }
  }
`

const ShipperConsigneeSubForm = (props) => {
  const { form, duplicateBookingObject = {}, mode = FormMode.Create, client, setFields, setBookingType } = props
  const { getFieldDecorator } = form

  const [dynamicFields, setDynamicFields] = useState([])
  const [queriedBookingType, setQueriedBookingType] = useState(null)
  const [initialValues, setInitialValues] = useState(duplicateBookingObject)
  const selectedBillToCompany = useBookingStore(state => state.selectedBillToCompany)
  const setSelectedBillToCompany = useBookingStore(state => state.setSelectedBillToCompany)

  const [getBookingType] = useLazyQuery(VIEW_BOOKING_TYPE_GQL, {
    client,
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data?.bookingType) {
        setDynamicFields(data.bookingType.dynamicFields)
        setFields?.(data.bookingType.dynamicFields)
        setQueriedBookingType(data.bookingType)
        if (mode === FormMode.Create) {
          setBookingType(data.bookingType)
        }
      }
    }
  })

  useEffect(() => {
    const currentBooking = form.getFieldsValue()
    if (currentBooking.type !== queriedBookingType?.code) {
      setInitialValues({ ...initialValues, type: currentBooking.type })
      getBookingType({
        variables: {
          code: form.getFieldValue('type')
        }
      })
    }

    if (currentBooking.billToUuid) setSelectedBillToCompany(currentBooking.billToUuid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form])

  const companySelectHandleChange = (company) => {
    setSelectedBillToCompany(company)
    form.setFieldsValue({ type: undefined })

    // Reset all job's type if this component is used in the new booking form.
    const jobs = form.getFieldValue('jobs')

    if (jobs && jobs.length > 0) {
      const jobsTypeReset = jobs.map((job) => {
        return {
          ...job,
          type: undefined
        }
      })
      form.setFieldsValue({ jobs: jobsTypeReset })
    }
  }

  return (
    <StyledDiv>
      <Row>
        <Col span={12}>
          <Row>
            <Form.Item label="Billing Customer" {...formItemLayout}>
              {getFieldDecorator('billToUuid', {
                initialValue: initialValues.billTo?.uuid,
                rules: [{ required: true, message: 'Billing customer is required.' }]
              })(
                <CompanySelect
                  formId="billing-customer-selector"
                  quickCreate
                  types={['billing']}
                  accountTypes={['debtor']}
                  portalCompanyQueryType="book"
                  onChange={companySelectHandleChange}
                />
              )}
            </Form.Item>
          </Row>
          <Row>
            <Form.Item label="Booking Type" required {...formItemLayout}>
              {getFieldDecorator('type', {
                initialValue: initialValues?.type,
                rules: [{ required: true, message: 'Booking type is required.' }]
              })(
                <DynamicTransportSelect
                  queryOnMount
                  type={'bookingTypes'}
                  query={BOOKING_TYPES_QUERY}
                  searchText={'Select booking type...'}
                  queryVariables={{ byCompany: selectedBillToCompany }}
                />
              )}
            </Form.Item>
          </Row>
        </Col>

        <Col span={12}>
          {
            dynamicFields?.reduce((acc, field) => {
              if (field.type !== BookingDynamicFieldType.Date || field.chronologyLevel === BookingTypeDynamicFieldChronologyLevel.Booking) {
                acc.push(
                  <Form.Item
                    key={field.key}
                    {...formItemLayout}
                    label={startCase(field.key)}
                    required={field.control === BookingTypeDynamicFieldControl.Required}
                  >
                    <DynamicField
                      form={form}
                      mode={mode}
                      dynamicField={field}
                      duplicateBookingObject={initialValues}
                    />
                  </Form.Item>
                )
              }
              return acc
            }, [])
          }
        </Col>
      </Row>
    </StyledDiv>
  )
}

export default withApollo(ShipperConsigneeSubForm)
