import { gql } from '@apollo/client'

import { fragments as costItemFragment } from 'App/containers/costItem/fragment'

export const COST_ITEMS_QUERY = gql`
  query costItems2($uuid: UUID!) {
    costItems(uuid: $uuid) {
      ...CostItemDetails
    }
  }

  ${costItemFragment.costItemDetails}
`

export const DELETE_COST_ITEM = gql`
  mutation deleteBookingCostItem($uuid: UUID!) {
    deleteBookingCostItem(uuid: $uuid) {
      success
    }
  }
`
