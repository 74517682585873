import { gql } from '@apollo/client'

import { fragments as userFragment } from '../fragment'

export default gql`
  query user($uuid: UUID, $auth0: String) {
    user(uuid: $uuid, auth0: $auth0) {
      ...UserDetails
    }
  }

  ${userFragment.userDetails}
`
