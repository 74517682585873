import { memo } from 'react'
import { Avatar, Row, Col } from 'antd'

import useAuthStore from 'App/store/auth'

const MainDrawerMenuUser = memo(() => {
  const profile = useAuthStore.use.profile

  return (
    <Row type="flex" gutter={8} align="middle">
      <Col span={4}>
        <Avatar src={profile.picture} />
      </Col>
      <Col span={20}>{profile.email}</Col>
    </Row>
  )
})

export default MainDrawerMenuUser
