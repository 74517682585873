import { gql } from '@apollo/client'

const UPDATE_VOUCHER_SLIM_MUT = gql`
  mutation updateVoucherSlim($input: UpdateVoucherInput!) {
    updateVoucher(input: $input) {
      uuid
      paymentType
      contactPerson
      description
      internalDescription
      invoiceNumber
      voucherNumber
      term
      accountDate
      documentCreatorTemplate {
        uuid
        name
      }
      address {
        uuid
        name
        type
        address1
        address2
        address3
        address4
        city
        district
        postCode
        phone
        fax
        status
        countryAlpha3
      }
      currency {
        uuid
        code
        name
      }
      vendor {
        uuid
        name
        code
      }
      customer {
        uuid
        name
        code
      }
      salesPerson {
        name
        uuid
        nickname
        familyName
        email
      }
      issueDate
    }
  }
`

export default UPDATE_VOUCHER_SLIM_MUT
