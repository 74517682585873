import React, { Component } from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as voucherActions from 'App/states/reducers/voucher'

export default (WrappedComponent) => {
  class WithVoucherPayments extends Component {
    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return compose(
    connect(
      (state, props) => ({
        selectedVoucher: state.voucher.selectedVoucher
      }),
      (dispatch) => ({
        dispatch,
        ...bindActionCreators(
          {
            ...voucherActions
          },
          dispatch
        )
      })
    )
  )(WithVoucherPayments)
}
