import { compose, withStateHandlers } from 'recompose'
import { Query } from 'react-apollo'
import { forwardRef } from 'react'
import { Select } from 'antd'

import COUNTRIES_QUERY from './schema'

const handlers = withStateHandlers(
  {
    keyword: ''
  },
  {
    handleChange: (state, props) => (value) => {
      props.onChange(value)
    }
  }
)

const enhance = compose(handlers)

const Option = Select.Option

const CountrySelect = forwardRef((props, ref) => {
  const { disabled = false, useAlpha3 = false } = props

  return (
    <Query query={COUNTRIES_QUERY} variables={{ limit: 500 }}>
      {({ loading, data }) => {
        const countries = data?.countries?.rows || []
        return (
          <Select
            ref={ref}
            showSearch
            allowClear
            filterOption
            disabled={disabled}
            style={{ width: '100%' }}
            onSelect={props.onChange}
            optionFilterProp="children"
            onChange={props.handleChange}
            placeholder="Select a country..."
            notFoundContent={loading ? 'Searching...' : 'No items found.'}
            getPopupContainer={(triggerNode) => triggerNode.parentNode}
          >
            {countries.map((country) => (
              <Option key={country.name} value={useAlpha3 ? country.alpha3 : country.alpha2}>
                {country.name}
              </Option>
            ))}
          </Select>
        )
      }}
    </Query>
  )
})

export default enhance(CountrySelect)
