import { Alert, Button, Form, Icon, Input, Modal } from 'antd'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLazyQuery } from '@apollo/client'
import { withApollo } from 'react-apollo'
import styled from 'styled-components'
import { compose } from 'recompose'
import { startCase } from 'lodash'

import LegFormDetails from 'App/components/Transport/Components/Modals/JobActivityModal/LegFormDetails'
import AddPlanVehicleJobsTable from 'App/components/Transport/Planning/Vehicle/AddPlanVehicleJobsTable'
import SelectedAddPlanVehicleJobsTable from 'App/components/Transport/Planning/Vehicle/SelectedAddPlanVehicleJobsTable'

import useDebounce from 'App/hooks/useDebounce'
import { useUnplannedStore } from 'App/store/unplanned'

import { PLAN_VEHICLE_JOBS_QUERY } from 'App/components/Transport/Planning/Vehicle/schema'

import type { FormComponentProps } from 'antd/lib/form'

const LegContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
`

const Leg = styled.div`
  width: 48%;
  padding: 10px;
`

const JobsContainer = styled.div`
  border-width: 1px;
  border-color: black;
  padding: 0 10px 10px 10px;
  background-color: #eeeeee;
`

const JobsInput = styled.div`
  gap: 10;
  width: 100%;
  display: flex;
  padding-top: 20px;
  align-items: center;
  padding-bottom: 10px;
  justify-content: flex-end;
`

const keys = ['bookingNo', 'no', 'jobNo', 'references', 'shipperRef', 'consigneeRef', 'bl', 'houseBl']

const enhance = compose(withApollo, Form.create())

interface AddPlanVehicleProps extends FormComponentProps {
  client: any
  isValidForUpdate?: boolean
  legUuids?: string[]
  defaultJobSearch?: string
  isMultiSelectMode?: boolean
  isSelectedTable?: string
}

const AddPlanVehicle: FC<Partial<AddPlanVehicleProps>> = (props) => {
  const { form, client, defaultJobSearch = '', isMultiSelectMode = false, legUuids = [], isValidForUpdate = true, isSelectedTable = '' } = props
  const { t } = useTranslation()

  const { selectedUnplannedLegs } = useUnplannedStore()

  const [formError, setFormError] = useState('')
  const [selectedRow, setSelectedRow] = useState<any>()
  const [activityObj, setActivityObj] = useState<any>()
  const [modalVisible, setModalVisible] = useState(false)
  const [formSuccessMsg, setFormSuccessMsg] = useState('')
  const [selectedJobs, setSelectedJobs] = useState<any>([])
  const [jobSearch, setJobSearch] = useState(defaultJobSearch)
  const [showJobSelectTable, setShowJobSelectTable] = useState(true)

  const debouncedSearch = useDebounce(jobSearch, 300)

  const [pageObj, setPageObj] = useState<any>({
    current: 1,
    pageSize: 30,
    onChange: (current: number) => setPageObj({ ...pageObj, current })
  })

  const [getJobs, { data: jobsData, loading: legsLoading }] = useLazyQuery(PLAN_VEHICLE_JOBS_QUERY, {
    client,
    variables: {
      input: {
        q: debouncedSearch,
        legUuids: legUuids,
        isSearchAllFields: true,
        limit: pageObj.pageSize,
        offset: pageObj.current - 1
      }
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (isMultiSelectMode) {
        return setPageObj({
          ...pageObj,
          total: selectedUnplannedLegs.length || 0
        })
      }

      if (legUuids.length) {
        setSelectedJobs(data?.transportJobs?.rows)
      }

      setPageObj({
        ...pageObj,
        pageSize: data?.transportJobs?.pageInfo?.limit,
        showTotal: (total: number, range: any) => `${range[0]}-${range[1]} of ${total} items`,
        total: data?.transportJobs?.pageInfo?.count || 0
      })

      if (selectedJobs?.length) {
        const newSelected = selectedJobs.map((old) => {
          const leg = data?.transportJobs?.rows?.find((leg) => leg.legUuid === old.legUuid)
          const newLeg = leg || old
          return {
            ...newLeg,
            updateStatus:
              old.isUpdated && old.updateStatus !== 'Failed'
                ? 'Updated'
                : old.updateStatus
                  ? old.updateStatus
                  : 'Pending update'
          }
        })
        setSelectedJobs(newSelected || [])
      }
    },
  })

  useEffect(() => {
    if (!selectedRow) return

    setActivityObj({
      planStart: selectedRow?.planStart || null,
      start: selectedRow?.start || null,
      startOut: selectedRow?.startOut || null,
      planEnd: selectedRow?.planEnd || null,
      end: selectedRow?.end || null,
      endOut: selectedRow?.endOut || null
    })
  }, [selectedRow])

  useEffect(() => {
    if (isMultiSelectMode) {
      setSelectedJobs(selectedUnplannedLegs)
    }
  }, [isMultiSelectMode, selectedUnplannedLegs])

  useEffect(() => {
    if (legUuids.length) {
      getJobs()
    }
  }, [getJobs, legUuids.length])

  const selectJobData = isMultiSelectMode ? selectedUnplannedLegs : jobsData

  const disableButton = !isValidForUpdate || (isMultiSelectMode && !selectedUnplannedLegs.length) || !isSelectedTable

  return (
    <>
      {isMultiSelectMode ?
        <Button onClick={() => setModalVisible(true)} disabled={disableButton}>
          {t('transport.bulkUpdateLegsModal.editBulk')}{' '}
        </Button>
        :
        <Button id='bulk-edit-legs-button' style={{ cursor: 'pointer' }} onClick={() => setModalVisible(true)} disabled={disableButton}>
          {disableButton ? t('transport.bulkUpdateLegsModal.invalidForBulkUpdate') : t('transport.planning.byVehicle.bulkUpdateLegs')}{' '}
          <Icon type="plus-circle" />
        </Button>
      }

      <Modal
        width={'90vw'}
        visible={modalVisible}
        cancelText={t('common.close')}
        onCancel={() => setModalVisible(false)}
        okButtonProps={{ style: { display: 'none' } }}
        title={startCase(t('transport.planning.byVehicle.bulkUpdateLegs'))}
      >
        {formError && <Alert message={formError} type="error" style={{ fontSize: 12 }} />}

        {formSuccessMsg && (
          <Alert message={formSuccessMsg} type="success" style={{ fontSize: 12 }} />
        )}

        <LegContainer>
          <Leg>
            <LegFormDetails
              form={form}
              client={client}
              leg={selectedRow}
              setFormError={setFormError}
              setFormSuccessMsg={setFormSuccessMsg}
            />
          </Leg>
        </LegContainer>

        {!selectedUnplannedLegs.length && !legUuids.length &&
          <JobsContainer >
            <JobsInput>
              <Input
                defaultValue={defaultJobSearch}
                style={{ maxWidth: 500, marginRight: 5 }}
                onChange={(e) => setJobSearch(e.target.value)}
                placeholder={t('transport.planning.byVehicle.searchJob')}
                onPressEnter={() => {
                  if (!jobsData?.transportJobs?.rows?.length) return

                  if (jobsData.transportJobs.rows.length === 1) {
                    setSelectedJobs([...selectedJobs, jobsData?.transportJobs?.rows?.[0]])
                  }
                  else {
                    const notSelected = jobsData.transportJobs.rows.filter((leg: any) => {
                      return selectedJobs.every((selected: any) => selected.legUuid !== leg.legUuid)
                    })

                    const match = notSelected?.find((leg: any) => {
                      return keys.some((key) => leg[key]?.toLowerCase() === debouncedSearch.toLowerCase())
                    })

                    if (match) {
                      setSelectedJobs([...selectedJobs, match])
                    }
                  }

                }}
              />
              <Icon
                type={showJobSelectTable ? 'up' : 'down'}
                onClick={() => setShowJobSelectTable(!showJobSelectTable)}
              />
            </JobsInput>

            {showJobSelectTable && (
              <AddPlanVehicleJobsTable
                getJobs={getJobs}
                pageObj={pageObj}
                data={selectJobData}
                loading={legsLoading}
                selectedJobs={selectedJobs}
                jobSearch={debouncedSearch}
                setSelectedJobs={setSelectedJobs}
              />
            )}
          </JobsContainer>
        }

        <SelectedAddPlanVehicleJobsTable
          form={form}
          client={client}
          getJobs={getJobs}
          activityObj={activityObj}
          selectedRow={selectedRow}
          selectedJobs={selectedJobs}
          setFormError={setFormError}
          setSelectedRow={setSelectedRow}
          setSelectedJobs={setSelectedJobs}
          setFormSuccessMsg={setFormSuccessMsg}
        />
      </Modal>
    </>
  )
}

export default enhance(AddPlanVehicle)
