import React, { useState } from 'react'
import { Menu, Divider } from 'antd'
import styled from 'styled-components'
import Link from 'App/components/Link'
import config from '../../../../config'

import Logout from 'App/components/Logout'
import MainDrawerMenuUser from './MainDrawerMenuUser'
import MainDrawerMenuCompany from './MainDrawerMenuCompany'
import { handleCheckUpdate } from '../../../CacheBuster'

const StyledMenu = styled(Menu)`
  border: none;
`
const VersionDisplay = styled.div`
  right: 0;
  bottom: 0;
  position: absolute;
  opacity: 0.5;
  padding: 0.5rem;
  margin: 1.5rem;
  font-size: 0.75rem;
`

const StyledDivider = styled(Divider)`
  margin: 1px 0;
`

const MainDrawerMenu = () => {
  const [versionDisplay, setVersionDisplay] = useState(`V ${config.version}`)
  const initState = () => setVersionDisplay('Checking for updates...')
  const noUpdates = () => setVersionDisplay('App up-to-date')
  const revertState = () => setVersionDisplay(`V ${config.version}`)

  return (
    <div>
      <div style={{ padding: '16px' }}>
        <MainDrawerMenuUser />
      </div>
      <StyledDivider />
      <MainDrawerMenuCompany />
      <StyledDivider />
      <StyledMenu>
        <Menu.Item key="dashboard">
          <Link to="/dashboard">Dashboard</Link>
        </Menu.Item>
        <Menu.Item key="bookings">
          <Link to="/">Bookings</Link>
        </Menu.Item>
        <Menu.Item key="vouchers">
          <Link to="/vouchers">Vouchers</Link>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="adminstration">
          <Link to="/manage">Manage</Link>
        </Menu.Item>
        <Menu.Item key="help">
          <Link to="https://shipxhelp.freshdesk.com/support/home" target="_blank">
            Help
          </Link>
        </Menu.Item>
        <Menu.Item key="logout">
          <Logout />
        </Menu.Item>
        <VersionDisplay
          onClick={() => {
            handleCheckUpdate(initState, noUpdates, revertState)
          }}
        >
          {versionDisplay}
        </VersionDisplay>
      </StyledMenu>
    </div>
  )
}

export default MainDrawerMenu
