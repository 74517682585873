
import { connect } from 'react-redux'
import { Button } from 'antd'
import { compose, withState, withHandlers } from 'recompose'
import config from 'App/config'

import respHandler from 'App/utils/responseHandler'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import webStorage from 'App/utils/webStorage'

const statusState = withState('status', 'setStatus', '')

const handlers = withHandlers({
  handleClick: (props) => async () => {
    const { setStatus, documentCreatorUuid, selectedGlobalCompany, onGenerated = () => { } } = props
    const documentCreatorUrl = `${config.api.baseUrl}/documentCreator/generate/${documentCreatorUuid}`
    const pdfServiceUrl = `${config.pdf.baseUrl}/queue`

    setStatus('loading')

    try {
      const bodyData = {
        url: documentCreatorUrl,
        header: {
          authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`,
          'base-company-uuid': selectedGlobalCompany.uuid
        }
      }

      const response = await fetch(pdfServiceUrl, {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify(bodyData)
      })

      if (!response.ok) {
        throw new Error(await response.text())
      }

      const body = await response.json()

      const pdfServiceObjectMap =
        webStorage.getItem(LOCAL_STORAGE_KEYS.DOCUMENT_CREATOR_PDF_SERVICE) || {}
      pdfServiceObjectMap[documentCreatorUuid] = {
        data: {
          uuid: body.uuid
        }
      }
      webStorage.setItem(LOCAL_STORAGE_KEYS.DOCUMENT_CREATOR_PDF_SERVICE, pdfServiceObjectMap)

      setStatus('')

      onGenerated(body.uuid)
    } catch (error) {
      console.log(error)
      respHandler(error, 'error')
      setStatus('')
    }
  }
})

const enhance = compose(
  connect((state, props) => ({
    selectedGlobalCompany: state.globalCompany.selectedGlobalCompany
  })),
  statusState,
  handlers
)

const GeneratePdfButton = (props) => {
  const { status, handleClick } = props

  return (
    <Button type="primary" loading={status === 'loading'} onClick={handleClick}>
      Generate PDF
    </Button>
  )
}

export default enhance(GeneratePdfButton)
