import moment from 'moment'
import { padTimeTo4Digits } from 'App/components/Shared/CustomTimeInput'
import type { TransportActivity } from 'App/types/types'
import type { TransportActivityTypes, TransportJobCmp } from 'App/types/legacy'

type ValidDateRangeType = {
  to?: any
  from?: any
}

type JobActivtyModalDataType = {
  code?: string
  uuid?: string
  remarks?: string
  ts?: string
  driverUuid?: string
  vehicleUuid?: string
  trailerUuid?: string
}

export const compute = (
  j: TransportJobCmp,
  filteredActCodes: Array<TransportActivityTypes>,
  activityModalData: JobActivtyModalDataType
): ValidDateRangeType => { //@ts-ignore
  if (!j || !j.statuses) {
    return {}
  }

  const foundActIndex = filteredActCodes.findIndex(
    (a: TransportActivityTypes) => a.code === activityModalData.code
  )
  // @ts-ignore
  const foundStatusIndex = j.statuses.findIndex((s: Maybe<TransportActivity>) => {
    if (s === null) {
      return false;
    }
    return (s.code || '') === activityModalData.code;
  });
  const foundAct = filteredActCodes[foundActIndex]

  if (!foundAct) {
    throw new Error(
      `Filtered Activity Not found in computeValidDateRange: ${activityModalData.code}`
    )
  }

  if (foundAct.compile === 'TRANS_PLANNED') {
    return {
      to: moment().add(1, 'year'),
      from: moment().subtract(1, 'year')
    }
  }
  //@ts-ignore
  const statuses = j.statuses
  const prevStatus = statuses[foundStatusIndex - 1]
  if (prevStatus?.compile === 'TRANS_PLANNED') {
    return {
      to: moment().add(5, 'minutes')
    }
  }

  return {
    to: moment().add(5, 'minutes'),
    from: prevStatus?.ts ? moment(prevStatus?.ts) : undefined
  }
}

export const formatDateAndTime = (date: Date, time: string) => {
  const formattedTime = padTimeTo4Digits(time)
  return new Date(moment(date).format('YYYY-MM-DD') + ' ' + formattedTime)
}

export const isValid = (validDateRange: ValidDateRangeType, date: Date): boolean => {
  return (
    (validDateRange.from ? validDateRange.from.isSameOrBefore(date) : true) &&
    (validDateRange.to ? validDateRange.to.isSameOrAfter(date) : true)
  )
}
