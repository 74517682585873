import { gql } from '@apollo/client'

export default gql`
  fragment ChargeCategoryDetails on ChargeCategory {
    uuid
    code
    description
    sequence
  }
`
