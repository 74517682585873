import React, { Component } from 'react'
import { compose, withState } from 'recompose'

import { Col, Icon } from 'antd'

import { documentType } from 'App/utils/labelMap'
import { StyledSelector, ExpandableRow, TypeName } from './Styled'
import HiddenView from './Hidden'

const statusIcons = {
  pending: 'clock-circle',
  approved: 'check-circle'
}

class ApprovalSelector extends Component {
  toggleHiddenFields = () => this.props.toggleHidden(!this.props.showHidden)

  render() {
    const { showHidden, approvalType, isApproved, approvedApproval } = this.props
    const statusIcon = isApproved ? statusIcons.approved : statusIcons.pending

    return (
      <StyledSelector>
        <ExpandableRow type="flex" align="middle" onClick={this.toggleHiddenFields}>
          <Col span={2}>
            <Icon type={statusIcon} />
          </Col>
          <Col span={21}>
            <TypeName>{documentType[approvalType] || approvalType}</TypeName>
          </Col>
          <Col span={1}>
            <Icon type={showHidden ? 'up' : 'down'} />
          </Col>
        </ExpandableRow>
        <HiddenView
          showHidden={showHidden}
          isApproved={isApproved}
          approvalType={approvalType}
          approvedApproval={approvedApproval}
          toggleHiddenFields={this.toggleHiddenFields}
        />
      </StyledSelector>
    )
  }
}

export default compose(withState('showHidden', 'toggleHidden', false))(ApprovalSelector)
