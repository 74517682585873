import { Component } from 'react'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as voucherActions from 'App/states/reducers/voucher'

export default (WrappedComponent) => {
  class WithAttachments extends Component {
    onCheckboxChange = (selectedRowKeys, selectedRows) => {
      const { selectedVoucher, updateSelectedVoucher } = this.props
      const updatedSelectedDocuments = selectedRows.map((row) => row.details)

      const updatedSelectedVoucher = { ...selectedVoucher }
      updatedSelectedVoucher.bookingDocuments = updatedSelectedDocuments

      updateSelectedVoucher(updatedSelectedVoucher)
    }

    render() {
      return <WrappedComponent {...this.props} onCheckboxChange={this.onCheckboxChange} />
    }
  }

  return compose(
    connect(
      (state) => ({
        selectedVoucher: state.voucher.selectedVoucher,
        selectedVoucherTemplate: state.voucher.selectedVoucherTemplate
      }),
      (dispatch) => ({
        dispatch,
        ...bindActionCreators(
          {
            ...voucherActions
          },
          dispatch
        )
      })
    )
  )(WithAttachments)
}
