import find from 'lodash/find'
import { Card, Result } from 'antd'
import { useQuery } from '@apollo/client'
import { withApollo } from 'react-apollo'

import NoAccess from 'App/components/Shared/NoAccess'
import ViewContent from 'App/components/Voucher/Bookings/TabView/ViewContent'

import { hasPermissionError } from 'App/utils/u'
import responseHandler from 'App/utils/responseHandler'

import bookingGql from 'App/components/Voucher/Bookings/TabView/bookingGql'

const TabView = (props: any) => {
  const { onRemoveDocument, onSelectSingleCostItem, onSelectSingleDocument } = props
  const handleCompleted = (result: any) => {
    if (result?.booking) {
      const { costsheetBookings = [], updateCostsheetBookings } = props

      const existBooking = find(costsheetBookings, (b) => b.uuid === result.booking.uuid)
      const newBookings = costsheetBookings.map((b: any) => b)
      if (!existBooking) {
        newBookings.push(result.booking)
        updateCostsheetBookings(newBookings)
      }
    }
  }

  const { data, error, loading, refetch } = useQuery(bookingGql, {
    client: props.client,
    variables: { uuid: props.bookingUuid },
    fetchPolicy: 'cache-and-network',
    onCompleted: handleCompleted
  })

  if (loading) return <Card bordered={false} loading={loading} />

  if (error) {
    if (hasPermissionError(error)) {
      return <NoAccess />
    } else {
      responseHandler(error, 'error')
      return <Result status="warning" title="Something wrong.." />
    }
  }

  return (
    <ViewContent
      data={data}
      refetch={refetch}
      onCostItemAdded={refetch}
      onCostItemEdited={refetch}
      onRemoveDocument={onRemoveDocument}
      onSelectSingleCostItem={onSelectSingleCostItem}
      onSelectSingleDocument={onSelectSingleDocument}
    />
  )
}

export default withApollo(TabView)
