import { compose } from 'recompose'

import bootstrap from 'App/hocs/bootstrap'
import NewBookingPage from 'App/pages/NewBooking'
import WithHeader from 'App/components/Layouts/WithHeader'

const MainPage = (props) => {
  return (
    <WithHeader {...props} >
      <NewBookingPage />
    </WithHeader>
  )
}

export default compose(bootstrap)(MainPage)
