import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from './locales/en/translation.json'
import ms from './locales/ms/translation.json'

const resources = {
  en: { translation: en },
  ms: { translation: ms },
}

export const languageOptions = [
  { value: 'en', label: 'EN' },
  { value: 'ms', label: 'BM' }
]

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    lng: "en", // if you're using a language detector, do not define the lng option
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

export default i18n;
