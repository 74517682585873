import { gql } from '@apollo/client'

export default gql`
  fragment DocumentCreatorDetails on DocumentCreator {
    uuid
    bookingUuid
    status
    type
    data {
      submittedDate
      requiredDate
      deliveryHour
      customerRef
      customerRef2
      haulier {
        uuid
        name
      }
      billToCompany {
        uuid
        name
      }
      pickupCompany {
        uuid
        name
      }
      pickupAddress {
        uuid
        name
      }
      deliveryAddress {
        uuid
        name
      }
      dropoffCompany {
        uuid
        name
      }
      dropoffAddress {
        uuid
        name
      }
      jobs {
        uuid
        bookingUuid
        no
        status
        remarks
        details
        reference {
          haulage
          transport
          shipper
          consignee
          forwardingOri
          forwardingDest
        }
      }
      documents
      remarks
      options
      requestNo
    }
  }
`
