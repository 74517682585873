import find from 'lodash/find'
import filter from 'lodash/filter'
import indexOf from 'lodash/indexOf'
import sortBy from 'lodash/sortBy'

import { ItemsWrapper } from '../Container'
import { NoDocument } from '../../Styled'
import UploadedItem from './UploadedItem'

const validVoucherStatus = ['DRAFT', 'SUBMITTED', 'APPROVED', 'PARTIALLY_PAID', 'PAID']

const UploadedItems = (props) => {
  const {
    uploadedDocs = [],
    section,
    booking,
    showDeleted,
    highlightedDocuments,
    handleOpenViewer
  } = props

  const sortedDocs = sortBy(uploadedDocs, (uploadedDoc) => {
    return indexOf(section.bookingDocuments, (doc) => uploadedDoc.type === doc.type)
  })

  const nonDeletedDocs =
    filter(sortedDocs, (doc) => {
      return doc.status !== 'DELETED' || null
    }) || []
  const displayDocs = showDeleted ? sortedDocs : nonDeletedDocs

  if (displayDocs.length === 0) {
    return (
      <ItemsWrapper>
        <NoDocument>No documents uploaded</NoDocument>
      </ItemsWrapper>
    )
  }

  const sequence = booking?.jobs?.map((job) => job.trips.map((trip) => {
    return {
      jobUuid: job.uuid,
      sequence: trip.sequence,
      tripUuid: trip.uuid
    }
  }))

  const flatSequence = sequence?.reduce((acc, val) => acc.concat(val), [])

  const tripSequenceMap = flatSequence?.reduce((map, trip) => {
    map[trip.tripUuid] = trip.sequence;
    return map
  }, {})

  const sortedDisplayDocs = displayDocs?.sort((a, b) => {
    if (a.jobUuid !== b.jobUuid) {
      return a?.jobUuid?.localeCompare(b.jobUuid)
    }
    const sequenceA = tripSequenceMap[a.tripUuid]
    const sequenceB = tripSequenceMap[b.tripUuid]
    return sequenceA - sequenceB
  });

  const orderedDocs = sortBy(sortedDisplayDocs, (doc) => {
    return doc.status === 'VERIFIED'
  })

  return (
    <ItemsWrapper>
      {orderedDocs.map((doc, index) => {
        const documentApproval = find(
          section.bookingDocuments,
          (bookingDoc) => bookingDoc.type === doc.type
        )
        const isHighlighted = find(highlightedDocuments, (hd) => hd.type === doc.type)

        const documentVouchers = booking.vouchers
          ? booking.vouchers.filter((voucher) => {
            return (
              validVoucherStatus.includes(voucher.status) &&
              voucher.bookingDocuments &&
              voucher.bookingDocuments.find((bd) => bd.uuid === doc.uuid)
            )
          })
          : []

        const documentRequests = booking.requests
          ? booking.requests.filter((request) => {
            return (
              request.meta &&
              request.meta.attachments &&
              request.meta.attachments.find((a) => a === doc.uuid)
            )
          })
          : []

        return (
          <UploadedItem
            key={index}
            uploadedDoc={doc}
            vouchers={documentVouchers}
            requests={documentRequests}
            isHighlighted={isHighlighted}
            documentApproval={documentApproval}
            handleOpenViewer={handleOpenViewer}
          />
        )
      })}
    </ItemsWrapper>
  )
}

export default UploadedItems
