import { graphql } from 'react-apollo'

import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from 'App/containers/booking/schema/bookingForWorkflowsSlim'
import CHANGE_DOCUMENT_CREATOR_STATUS from '../schema/changeDocumentCreatorStatus'

export default graphql(CHANGE_DOCUMENT_CREATOR_STATUS, {
  props: ({ mutate, ownProps }) => ({
    changeDocumentCreatorStatus: (uuid, status) =>
      mutate({
        variables: {
          uuid,
          status
        },
        refetchQueries: [
          {
            query: BOOKING_FOR_WORKFLOWS_SLIM_QUERY,
            variables: {
              uuid:
                (ownProps.booking && ownProps.booking.uuid) ||
                (ownProps.documentCreator && ownProps.documentCreator.bookingUuid)
            }
          }
        ]
      })
  })
})
