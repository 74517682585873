import React, { Component } from 'react'
import PropTypes from 'prop-types'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'

import labels from './labels'

export default (WrappedComponent) => {
  class WithSection extends Component {
    render() {
      const { booking, section, requisitionActions } = this.props

      const uploadedDocs = filter(
        booking.bookingDocuments,
        (doc) =>
          findIndex(section.bookingDocuments, (sectionDoc) => sectionDoc.type === doc.type) !== -1
      )

      const vouchers = filter(
        booking.vouchers,
        (voucher) =>
          findIndex(section.vouchers, (sectionVoucher) => sectionVoucher.type === voucher.type) !==
          -1
      )

      const requisitions =
        section.requests &&
        section.requests.map((request) => {
          return {
            text: request.type,
            type: request.type,
            get: () => requisitionActions.getTemplate(request.type),
            send: (requisition) => requisitionActions.handleSendRequisition(requisition)
          }
        })

      const chronologies = filter(
        booking.chronologies,
        (chrono) =>
          findIndex(section.chronologies, (sectionChrono) => sectionChrono.type === chrono.type) !==
          -1
      )

      const states = {
        vouchers,
        requisitions,
        uploadedDocs,
        chronologies
      }

      return <WrappedComponent labels={labels} sectionStates={states} {...this.props} />
    }
  }

  WithSection.propTypes = {
    process: PropTypes.object,
    booking: PropTypes.object,
    section: PropTypes.object,
    showQrCode: PropTypes.bool,
    uploadActions: PropTypes.object,
    selectedSection: PropTypes.object,
    requisitionActions: PropTypes.object
  }

  return WithSection
}
