import styles from './Section.module.css'

import { Component } from 'react'
import { Row, Col, Tooltip, message, Modal, Icon as AntIcon } from 'antd'
import QRCode from 'qrcode.react'
import filter from 'lodash/filter'
import uniqBy from 'lodash/uniqBy'
import groupBy from 'lodash/groupBy'
import findIndex from 'lodash/findIndex'
import { compose } from 'recompose'

import { IS_SERVER } from 'App/utils/website'
import Icon from '../../Icons/FA'
import withSection from './Container'
import { Wrapper, Title, Content, ReferenceLabel } from './Styled'
import Progress from '../Progress'
import Uploaded from '../Uploaded'
import Voucher from '../Voucher'
import Request from '../Request'
import Chronology from '../Chronology'
import { CategoryTitle } from '../Styled'
import labels from './labels'
import MermaidLink from '../MermaidLink'
import { withRouter } from 'App/RouterHOC'
import Auth0 from 'App/hocs/auth0'
import config from 'App/config'

const enhance = compose(withRouter, Auth0, withSection)

const fallbackCopyTextToClipboard = (text) => {
  const textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try {
    const successful = document.execCommand('copy')

    if (successful) {
      message.success('Link copied to clipboard.', 3)
    } else {
      message.error('Unable to copy link. Please use a modern browser.', 5)
    }
  } catch (err) {
    message.error('Unable to copy link. Please use a modern browser.', 5)
  }

  document.body.removeChild(textArea)
}

const copyTextToClipboard = (text) => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(
    function () {
      message.success('Link copied to clipboard.', 3)
    },
    function () {
      message.error('Unable to copy link. Please use a modern browser.', 5)
    }
  )
}

const handleCopyClick = (text) => (e) => {
  e.preventDefault()
  copyTextToClipboard(text)
}

const handleQRCodeClick = (link) => (e) => {
  e.preventDefault()
  const content = (
    <div>
      <p>Scan the QR code below to go to this booking:</p>
      <QRCode value={link} size={192} />
    </div>
  )

  Modal.info({
    title: 'QR Code',
    content,
    okText: 'Close',
    maskClosable: true
  })
}

class Section extends Component {
  renderUploadedDocs = () => {
    const { section, booking, sectionStates } = this.props

    if (!section.bookingDocuments) {
      return <div />
    }

    const voucherDocuments = []

    booking.vouchers?.forEach((bv) => {
      bv.bookingDocuments &&
        bv.bookingDocuments.forEach((bd) => {
          voucherDocuments.push(bd)
        })
    })

    const highlightedDocuments = filter(
      sectionStates.uploadedDocs,
      (ud) => findIndex(voucherDocuments, (vd) => vd.type === ud.type) !== -1
    )

    return (
      <Uploaded
        section={section}
        booking={booking}
        requiredDocs={section.bookingDocuments}
        uploadedDocs={sectionStates.uploadedDocs || []}
        uploadActions={this.props.uploadActions}
        highlightedDocuments={uniqBy(highlightedDocuments, 'type')}
      />
    )
  }

  renderVouchers = () => {
    const { section, sectionStates } = this.props

    if (section.vouchers === null) {
      return <div />
    }

    return (
      <Voucher
        data={this.props.data}
        type={section.name}
        steps={section.steps}
        section={section}
        viewableVouchers={sectionStates.vouchers || []}
      />
    )
  }

  renderRequests = () => {
    const { section, booking, sectionStates } = this.props

    if (section.requests === null) {
      return <div />
    }

    return (
      <Request
        data={this.props.data}
        section={section}
        steps={section.steps}
        type={section.name}
        requisitions={sectionStates.requisitions}
        booking={booking}
        allowCreate
      />
    )
  }

  renderChronologies = () => {
    const { sectionStates, section, booking } = this.props

    if (!section.chronologies) {
      return null
    }

    const bookingChronoGroups =
      sectionStates.chronologies && groupBy(sectionStates.chronologies, (c) => c.reference)

    return (
      <>
        <div style={{ marginBottom: 30 }}>
          <Row>
            <Col span={24}>
              <CategoryTitle>Chronologies</CategoryTitle>
            </Col>
          </Row>

          {!Object.keys(bookingChronoGroups).length && (
            <Row>
              {section.chronologies.map((sectionChrono) => {
                const chrono = {
                  ...sectionChrono,
                  title: labels[sectionChrono.type] || sectionChrono.type
                }
                return (
                  <Col key={sectionChrono.type} span={6}>
                    <Chronology booking={booking} chrono={chrono} />
                  </Col>
                )
              })}
            </Row>
          )}
          {Object.keys(bookingChronoGroups).map((key) => {
            return (
              <Row key={key} style={{ padding: '5px', marginBottom: '5px' }}>
                <Col span={24} style={{ marginBottom: '5px' }}>
                  <ReferenceLabel>
                    <AntIcon type="right" /> {key || <i>(empty reference)</i>}
                  </ReferenceLabel>
                </Col>
                {section.chronologies.map((sectionChrono, index) => {
                  const existingChronoData = bookingChronoGroups[key].find(
                    (c) => c.type === sectionChrono.type
                  ) || { reference: key }

                  const chrono = {
                    ...sectionChrono,
                    title: labels[sectionChrono.type] || sectionChrono.type,
                    data: existingChronoData
                  }

                  return (
                    <Col key={sectionChrono.type} span={(index + 1) % 5 !== 0 ? 5 : 4}>
                      <Chronology booking={booking} chrono={chrono} />
                    </Col>
                  )
                })}
              </Row>
            )
          })}
        </div>
      </>
    )
  }

  render() {
    const { section, labels, booking, process, extras: Extras, } = this.props
    const mappedSection = labels[section.name]

    const iconType = (mappedSection && mappedSection.icon) || 'bars'
    const title = (mappedSection && mappedSection.text) || section.name

    const domain = IS_SERVER ? '' : window && window.location && window.location.origin
    const bookingLink = `${domain}/bookings/${booking.uuid}#${section.name}`

    const whatsappLink = `${config.whatsapp.apiLinkFront}${encodeURIComponent(bookingLink)}`

    const iconStyle = { color: 'gray', marginLeft: 10 }

    return (
      <Wrapper>
        <Title>
          <div className={styles.container}>
            <div className={styles.left}>
              <Icon type={iconType} style={{ marginRight: 5 }} /> {title}
            </div>

            <div className={styles.right}>
              <MermaidLink type={booking.type} process={process.type} />

              <Tooltip title="Copy to clipboard">
                <a href={bookingLink} rel="noopener noreferrer" onClick={handleCopyClick(bookingLink)}>
                  <Icon type="link" style={iconStyle} />
                </a>
              </Tooltip>

              <Tooltip title="Generate QR code">
                <a href={bookingLink} rel="noopener noreferrer" onClick={handleQRCodeClick(bookingLink)}>
                  <Icon type="qrcode" style={iconStyle} />
                </a>
              </Tooltip>

              <Tooltip title="Send WhatsApp message">
                <a href={whatsappLink} target="_blank" rel="noopener noreferrer">
                  <i className="fa fa-whatsapp" style={iconStyle} />
                </a>
              </Tooltip>
            </div>
          </div>
        </Title>

        <Content id={section.name}>
          <Progress steps={section.steps} />
          <Row>
            <Col span={24}>
              {this.renderChronologies()}
              {this.renderUploadedDocs()}
              {this.renderRequests()}
              {this.renderVouchers()}
            </Col>
          </Row>
          {Extras && <Extras {...this.props} />}
        </Content>
      </Wrapper >
    )
  }
}

export default enhance(Section)
