import { gql } from '@apollo/client'

export default gql`
  query bookingVoucherTypes($uuid: UUID!) {
    booking(uuid: $uuid) {
      uuid
      processFlow {
        sections {
          vouchers {
            type
          }
        }
      }
    }
  }
`
