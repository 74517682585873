import { Typography } from 'antd'
import { isArray, startCase } from 'lodash'

import JobEditAction from '../JobEditAction'

import type { Job, JobType, JobTypeRequiredFields } from 'App/types/types'
import { JobStatus } from 'App/types/types'

type getColumnOptions = {
  canEdit: boolean
}

export const getColumns = (props: any, jobTypes: JobType[], options: getColumnOptions) => {
  const { booking, shipmentType } = props

  const cols: any = [
    {
      dataIndex: 'expandRowButton',
      width: 20,
      className: 'xs-font'
    },
    {
      title: 'Job No',
      dataIndex: 'jobNo',
      width: 140,
    },
    {
      title: 'No',
      dataIndex: 'no',
      width: 100,
      align: 'center',
      render: (no: string, record: Job, index: number) => no || index + 1
    },
    {
      title: 'Unit',
      align: 'center',
      width: 80,
      render: (text: string, record: Job) => {
        if (!record.details?.measurementUnit) {
          return null
        }

        return `${record.details.measurementUnit} ${record.details.uom}`
      }
    },
    {
      title: 'Job Type',
      dataIndex: 'type',
      align: 'center',
      width: 70,
    }
  ]

  // *** Dynamic columns ***
  jobTypes?.length && // @ts-ignore
    jobTypes[0]?.requiredFields?.forEach((r: JobTypeRequiredFields) => {
      cols.push({
        title: startCase(r.name || ''),
        dataIndex: r.name,
        width: 100,
        align: 'center',
        render: (text: string, record: Job) => {
          // @ts-ignore
          const val = record.details?.[r.name]

          if (isArray(val)) {
            return val.join(', ')
          }

          return val
        }
      })
    })

  // *** End columns ***
  const endArray = [
    {
      title: 'Remarks',
      dataIndex: 'remarks',
      width: 100,
    },
    {
      title: 'Status',
      align: 'center',
      width: 70,
      render: (text: string, record: Job) => {
        if (record.status === JobStatus.Cancelled) {
          return <Typography.Text type="danger">{record.status}</Typography.Text>
        }

        return record.status
      }
    },
    {
      title: '',
      key: 'action',
      align: 'center',
      width: 30,
      onCell: () => {
        return {
          onClick: (e) => {
            e.stopPropagation()
          }
        }
      },
      render: (text: string, record: Job) => {
        if (!options.canEdit) return null
        return (
          <JobEditAction
            job={record}
            // @ts-ignore
            shipmentType={shipmentType}
            refetch={props.refetch}
            booking={booking}
          />
        )
      }
    },
  ]

  cols.push(...endArray)

  return cols
}
