import { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'

import { LayoutStyles } from './Styled'

const Content = Layout.Content

class AuthLayout extends PureComponent {
  render() {
    return (
      <Layout style={LayoutStyles.loginLayout}>
        <Content style={LayoutStyles.loginContent}>{this.props.children}</Content>
      </Layout>
    )
  }
}

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element)
  ])
}

export default AuthLayout
