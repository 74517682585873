import { Suspense } from 'react'
import { Spin, Row, Col } from 'antd'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { JobMapState } from 'App/components/Transport/Components/VehicleMap/JobsMaps'

import lazyLoad from 'App/utils/retryLazyLoad'

import type { TripLocations } from 'App/types/types'

const Map = lazyLoad(() => import('App/components/Transport/Components/VehicleMap/Map'))

const HEIGHT = '300px'

export const MapHeaderText = styled.div`
  display: flex;
  font-size: 11px;
  font-weight: 500;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: rgb(150, 150, 150);
`

const JobsMap = ({ data, loading, isWaiting, showScrollZoom, showNavigationControl }: JobMapState) => {
  const { t } = useTranslation()

  const trip = data?.[0]?.trip

  if (loading || isWaiting || !trip) {
    return (
      <Col
        style={{
          padding: 5,
          width: '100%',
          height: HEIGHT,
          flex: '1 0 32%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {loading ? <Spin /> : isWaiting ? t('common.waiting') : !trip ? t('common.noData') : ''}
      </Col>
    )
  }

  const truckLocationData = data?.reduce((acc, curr: TripLocations) => {
    if (curr.locations && curr.locations.length) {
      acc.locations.push(...curr.locations)
    }

    if (curr.trip) {
      acc.jobs.push(curr.trip)
    }

    return acc
  }, { locations: [], jobs: [] })

  return (
    <Col style={{ flex: '1 0 32%', width: '100%' }}>
      <Row>
        <MapHeaderText>
          {trip.jobNo}
        </MapHeaderText>
      </Row>

      <Row style={{ minHeight: HEIGHT, height: '25%' }}>
        <Suspense fallback={<Spin />}>
          <Map
            locsLoading={loading}
            showScrollZoom={showScrollZoom}
            jobs={truckLocationData.jobs}
            filteredLocData={truckLocationData.locations}
            showNavigationControl={showNavigationControl}
          />
        </Suspense>
      </Row>
    </Col>
  )
}

export default JobsMap
