import { Component } from 'react'
import PropTypes from 'prop-types'
import { compose, graphql } from 'react-apollo'
import { Popconfirm, Button } from 'antd'

import { DELETE_JOB } from 'App/graphql/booking'
import { JOBS_FROM_BOOKING_SLIM_QUERY } from 'App/containers/booking/schema/basicBookingQuerySlim'
import respHandler from 'App/utils/responseHandler'

class JobDeleteAction extends Component {
  handleConfirm = () => async (e) => {
    const {
      deleteJob,
      job: { uuid }
    } = this.props

    respHandler('Deleting job, hang on...', 'load')

    try {
      await deleteJob(uuid)

      respHandler('Job deleted successfully.', 'success')
    } catch (error) {
      console.log(error)

      respHandler(error, 'error')
    }
  }

  render() {
    return (
      <Popconfirm
        title="Confirm to delete this job?"
        onConfirm={this.handleConfirm()}
        okText="Yes"
        okType="danger"
        cancelText="No"
      >
        <Button type="danger">Delete</Button>
      </Popconfirm>
    )
  }
}

JobDeleteAction.propTypes = {
  job: PropTypes.object.isRequired,
  deleteJob: PropTypes.func
}

export default compose(
  graphql(DELETE_JOB, {
    props: ({ mutate, ownProps }) => ({
      deleteJob: (uuid) =>
        mutate({
          variables: {
            uuid
          },
          update: (cache, mutationResult) => {
            const { booking } = cache.readQuery({
              query: JOBS_FROM_BOOKING_SLIM_QUERY,
              variables: { uuid: ownProps.job.bookingUuid }
            })

            const jobIndex = booking.jobs.findIndex((job) => job.uuid === uuid)
            booking.jobs.splice(jobIndex, 1)

            cache.writeQuery({
              query: JOBS_FROM_BOOKING_SLIM_QUERY,
              variables: { uuid: ownProps.job.bookingUuid },
              data: { booking }
            })
          },
          refetchQueries: [
            {
              query: JOBS_FROM_BOOKING_SLIM_QUERY,
              variables: {
                uuid: ownProps.job.bookingUuid
              }
            }
          ]
        })
    })
  })
)(JobDeleteAction)
