import React, { Component } from 'react'

import { Wrapper } from './Styled'
import TypeSelector from './TypeSelector'

class TypeSelections extends Component {
  render() {
    const { section = { vouchers: [] }, voucherTypes, handleCreateClick } = this.props

    return (
      <Wrapper>
        {section.vouchers.map((voucher) => {
          const mappedVoucher = voucherTypes[voucher.type] || { text: voucher.type }
          const icon = voucher.transactionType === 'ACCREC' ? 'file-invoice-dollar' : 'money-bill'

          return (
            <TypeSelector
              key={voucher.transactionType}
              voucherTransaction={voucher.transactionType}
              text={mappedVoucher.text}
              icon={icon}
              handleClick={() => handleCreateClick(voucher, section)}
            />
          )
        })}
      </Wrapper>
    )
  }
}

export default TypeSelections
