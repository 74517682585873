import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
`

export const Activities = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 71px;
  padding: 10px 15px;
  overflow: auto;
`

export const SectionName = styled.div`
  font-size: 18.222px;
  font-weight: bold;
  color: #1b2733;
  padding: 30px 0 10px;
  letter-spacing: 0.3px;

  &:first-child {
    padding-top: 15px;
  }
`

export const Actions = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px 20px;
  text-align: center;
  background: white;
  border-top: 1px solid rgb(230, 230, 230);

  .ant-btn {
    width: 100%;
    height: 40px;
    font-size: 14.222px;
    font-weight: 500;
    letter-spacing: 0.1px;
  }
`

export const StatusSummary = styled.div`
  text-align: center;
  font-size: 13.222px;
  color: rgb(50, 60, 70);
  letter-spacing: 0.1px;
  line-height: 40px;

  .anticon {
    font-size: 21px;
    vertical-align: -0.275em;
    margin-right: 5px;
  }
`
