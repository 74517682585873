import { composeWithDevTools } from '@redux-devtools/extension'
import { createStore } from 'redux'

import reducers from 'App/states/reducer'

let reduxStore = null

function create(initialState = {}) {
  /* Setup reducers */
  const store = createStore(
    reducers,
    initialState, // Hydrate the store with server-side data
    composeWithDevTools()
  )
  return store
}

export default function initRedux(initialState) {
  // Reuse store on the client-side

  if (!reduxStore) {
    reduxStore = create(initialState)
  }

  return reduxStore
}
