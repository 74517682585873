import styled from 'styled-components'

export const SelectableContainer = styled.div`
  max-height: 400px;
  overflow: auto;
`

export const SelectableTitle = styled.div`
  font-size: 14.5px;
  color: #151b26;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgb(235, 235, 235);
`
