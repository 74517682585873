import { gql } from '@apollo/client'

import { fragments as bookingFragment } from 'App/containers/booking/fragment'
import { fragments as costItemFragment } from 'App/containers/costItem/fragment'

export default gql`
  query voucherBookingData($bookingUuid: UUID!) {
    booking(uuid: $bookingUuid) {
      ...BookingDetails
    }

    costItems(uuid: $bookingUuid) {
      ...CostItemDetails
    }
  }

  ${costItemFragment.costItemDetails}
  ${bookingFragment.bookingDetails}
`
