import { memo } from 'react'
import { withApollo } from 'react-apollo'
import { gql, useQuery } from '@apollo/client'

const PLACE_VIEW = gql`
  query place($placeId: String) {
    place(placeId: $placeId) {
      placeId
      name
    }
  }
`

const Place = memo(({ placeId, client }: { placeId: string; client: any }) => {
  const { data, loading, error } = useQuery(PLACE_VIEW, {
    client,
    variables: { placeId },
    skip: !placeId
  })

  if (!placeId) {
    return <span>Place is not selected.</span>
  }

  if (loading) {
    return <span>Loading...</span>
  }

  if (error) {
    return <span>Error while loading place.</span>
  }

  if (!data) {
    return <span>Error: Place not found.</span>
  }

  return <span>{data.place?.name}</span>
})

export default withApollo(Place)
