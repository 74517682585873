import { gql } from '@apollo/client'

export const voucherDetailsFragments = gql`
  fragment VoucherDetailsSlim on Voucher {
    uuid
    type
    paymentType
    status
    createdBy {
      name
      givenName
      nickname
      email
    }
    approvedBy {
      name
      givenName
      nickname
      email
    }
    contactPerson
    description
    internalDescription
    invoiceNumber
    voucherNumber
    subTotal
    taxTotal
    taxRounding
    total
    term
    printCount
    accountDate
    isCreditNote
    documentCreatorTemplate {
      uuid
      name
    }
    address {
      ...AddressDetailsSlim
    }
    currency {
      ...CurrencyDetailsSlim
    }
    permissions {
      canApprove
      canCancel
    }
    balance
    transactionType
    owner {
      ...BaseCompanyDetailsSlim
    }
    vendor {
      ...CompanyDetailsSlim
    }
    customer {
      ...CompanyDetailsSlim
    }
    salesPerson {
      ...UserDetailsSlim
    }
    issueDate
    dueDate
    updatedAt
    createdAt
    bookingDocuments {
      uuid
      status
      hardCopyStatus
      location
      type
      url
      document {
        uuid
        name
        type
        size
      }
    }
    voucherPayments {
      ...VoucherPaymentDetailsSlim
    }
    voucherItems {
      ...VoucherItemDetailsSlim
    }
    approvals {
      ...ApprovalDetailsSlim
    }
    bookings {
      uuid
      type
      no
    }
  }

  fragment VoucherPaymentDetailsSlim on VoucherPayment {
    uuid
    amount
    voucherUuid
    __typename
  }

  fragment ApprovalDetailsSlim on Approval {
    uuid
    type
    status
    approvedBy {
      ...UserDetailsSlim
      __typename
    }
    createdAt
    __typename
  }

  fragment UserDetailsSlim on User {
    name
    uuid
    nickname
    givenName
    familyName
    email
    picture
    __typename
  }

  fragment CurrencyDetailsSlim on Currency {
    uuid
    code
    name
    __typename
  }

  fragment VoucherItemDetailsSlim on VoucherItem {
    uuid
    description
    unit
    size
    quantity
    isDeleted
    currency {
      ...CurrencyDetailsSlim
      __typename
    }
    baseRate
    exchangeRate
    localExchangeRate
    rate
    tax {
      ...TaxDetailsSlim
      __typename
    }
    taxPercentage
    taxTotal
    subTotal
    total
    bookingUuid
    costItem {
      ...CostItemDetailsSlim
      __typename
    }
    job {
      uuid
      no
      jobNo
    }
    voucherCn {
      uuid
      voucherNumber
    }
    voucherItemCn {
      voucher {
        voucherNumber
        invoiceNumber
      }
    }
    __typename
    sorting
  }

  fragment TaxDetailsSlim on Tax {
    uuid
    name
    code
    percentage
    __typename
  }

  fragment CompanyDetailsSlim on Company {
    uuid
    name
    code
    __typename
  }

  fragment CostItemDetailsSlim on CostItem {
    uuid
    bookingUuid
    description
    quantity
    chargeItem {
      ...ChargeItemDetailsSlim
      __typename
    }
    __typename
  }

  fragment ChargeItemDetailsSlim on ChargeItem {
    uuid
    code
    name
    description
    sellTax {
      ...TaxDetailsSlim
      __typename
    }
    costTax {
      ...TaxDetailsSlim
      __typename
    }
    __typename
  }

  fragment AddressDetailsSlim on Address {
    uuid
    name
    type
    address1
    address2
    address3
    address4
    city
    district
    postCode
    phone
    fax
    status
    countryAlpha3
    __typename
  }

  fragment BaseCompanyDetailsSlim on BaseCompany {
    uuid
    name
    country {
      ...CountryDetailsSlim
      __typename
    }
    company {
      ...CompanyDetailsSlim
      __typename
    }
    __typename
  }

  fragment CountryDetailsSlim on Country {
    name
    alpha2
    alpha3
    __typename
  }
`

export default gql`
  query voucherSlim1($uuid: UUID!) {
    voucher(uuid: $uuid) {
      ...VoucherDetailsSlim
    }
  }

  ${voucherDetailsFragments}
`
