import { gql } from '@apollo/client'

const BOOKING_FOR_WORKFLOWS_SLIM_QUERY = gql`
  query bookingForSchedulesSlim1($uuid: UUID, $no: String) {
    booking(uuid: $uuid, no: $no) {
      # assignees {
      #   user {
      #     name
      #     uuid
      #     auth0UserId
      #     auth0Token
      #     nickname
      #     givenName
      #     familyName
      #     email
      #     picture
      #     status
      #     roles
      #     companies {
      #       uuid
      #       name
      #       types
      #       status
      #       countryAlpha3
      #       currency {
      #         uuid
      #         code
      #         name
      #       }
      #     }
      #   }
      #   type
      #   uuid
      # }
      # companies {
      #   uuid
      #   name
      #   types
      #   code
      #   tags
      #   debtorCode
      #   creditorCode
      #   status
      #   countryAlpha3
      #   description
      #   addresses {
      #     uuid
      #     name
      #     type
      #     address1
      #     address2
      #     address3
      #     address4
      #     city
      #     district
      #     postCode
      #     phone
      #     fax
      #     status
      #     location {
      #       type
      #       coordinates
      #     }
      #     countryAlpha3
      #     tags
      #   }
      #   contacts {
      #     uuid
      #     name
      #     email
      #     phone
      #   }
      #   currency {
      #     uuid
      #     code
      #     name
      #   }
      # }
      uuid
      no
      # status
      # remarks
      # details
      chronologies {
        uuid
        jobUuid
        tripUuid
        date
        type
        remarks
        reference
        updatedAt
        # createdAt
        user {
          # name
          # uuid
          # auth0UserId
          # auth0Token
          # nickname
          # givenName
          # familyName
          email
          # picture
          # status
          # roles
          # companies {
          #   uuid
          #   name
          #   types
          #   status
          #   countryAlpha3
          #   currency {
          #     uuid
          #     code
          #     name
          #   }
          # }
        }
      }
      # company {
      #   uuid
      #   name
      #   code
      # }
      # state {
      #   key
      #   value
      # }
      requests {
        uuid
        type
        to
        status
        remarks
        createdAt
        from {
          uuid
          name
          email
        }
        meta {
          jobs
          attachments
        }
      }
      type
      # isCloseOperation
      # isCloseFinance
      # placeOri {
      #   placeId
      #   name
      #   country {
      #     name
      #     alpha2
      #     alpha3
      #     ports {
      #       uuid
      #       name
      #     }
      #   }
      #   location {
      #     type
      #     coordinates
      #   }
      # }
      # placeDest {
      #   placeId
      #   name
      #   country {
      #     name
      #     alpha2
      #     alpha3
      #     ports {
      #       uuid
      #       name
      #     }
      #   }
      #   location {
      #     type
      #     coordinates
      #   }
      # }
      # haulierOri {
      #   uuid
      #   name
      #   code
      # }
      # linerOri {
      #   uuid
      #   name
      #   code
      # }
      # forwarderOri {
      #   uuid
      #   name
      #   code
      # }
      # haulierDest {
      #   uuid
      #   name
      #   code
      # }
      # linerDest {
      #   uuid
      #   name
      #   code
      # }
      # forwarderDest {
      #   uuid
      #   name
      #   code
      # }
      # portOri {
      #   uuid
      #   name
      #   countryAlpha3
      # }
      # portDest {
      #   uuid
      #   name
      #   countryAlpha3
      # }
      # billTo {
      #   uuid
      #   name
      #   types
      #   code
      #   tags
      #   debtorCode
      #   creditorCode
      #   status
      #   countryAlpha3
      #   description
      #   addresses {
      #     uuid
      #     name
      #     type
      #     address1
      #     address2
      #     address3
      #     address4
      #     city
      #     district
      #     postCode
      #     phone
      #     fax
      #     status
      #     location {
      #       type
      #       coordinates
      #     }
      #     countryAlpha3
      #     tags
      #   }
      #   contacts {
      #     uuid
      #     name
      #     email
      #     phone
      #   }
      #   currency {
      #     uuid
      #     code
      #     name
      #   }
      # }
      # liner {
      #   uuid
      #   name
      #   code
      # }
      # shipper {
      #   uuid
      #   name
      #   code
      #   addresses {
      #     uuid
      #     name
      #   }
      # }
      # warehouse {
      #   uuid
      #   name
      #   code
      # }
      # consignee {
      #   uuid
      #   name
      #   code
      #   addresses {
      #     uuid
      #     name
      #   }
      # }
      # shipperAddress {
      #   uuid
      #   name
      # }
      # consigneeAddress {
      #   uuid
      #   name
      # }
      bookingDocuments {
        uuid
        type
        reference
        remarks
        tags
        status
        url
        jobUuid
        tripUuid
        document {
          uuid
          name
          type
          size
        }
        approvals {
          uuid
          type
          status
          createdAt
          approvedBy {
            email
          }
        }
        createdBy {
          uuid
          email
        }
        verifiedBy {
          uuid
          email
        }
        createdAt
        updatedAt
      }
      vouchers {
        uuid
        type
        # paymentType
        status
        # createdBy {
        #   name
        #   givenName
        #   nickname
        #   email
        # }
        # approvedBy {
        #   name
        #   givenName
        #   nickname
        #   email
        # }
        # contactPerson
        # description
        # internalDescription
        # invoiceNumber
        voucherNumber
        # subTotal
        # taxTotal
        total
        # term
        # printCount
        # accountDate
        isCreditNote
        # address {
        #   uuid
        #   name
        #   type
        #   address1
        #   address2
        #   address3
        #   address4
        #   city
        #   district
        #   postCode
        #   phone
        #   fax
        #   status
        #   countryAlpha3
        # }
        # currency {
        #   uuid
        #   code
        #   name
        # }
        # permissions {
        #   canApprove
        #   canCancel
        # }
        # balance
        transactionType
        # owner {
        #   uuid
        #   name
        #   registration
        #   logo
        #   types
        #   status
        #   country {
        #     name
        #     alpha2
        #     alpha3
        #     ports {
        #       uuid
        #       name
        #     }
        #   }
        #   company {
        #     uuid
        #     name
        #     types
        #     code
        #     tags
        #     debtorCode
        #     creditorCode
        #     status
        #     countryAlpha3
        #     description
        #     addresses {
        #       uuid
        #       name
        #       type
        #       address1
        #       address2
        #       address3
        #       address4
        #       city
        #       district
        #       postCode
        #       phone
        #       fax
        #       status
        #       location {
        #         type
        #         coordinates
        #       }
        #       countryAlpha3
        #       tags
        #     }
        #     contacts {
        #       uuid
        #       name
        #       email
        #       phone
        #     }
        #     currency {
        #       uuid
        #       code
        #       name
        #     }
        #   }
        # }
        # vendor {
        #   uuid
        #   name
        #   types
        #   code
        #   tags
        #   debtorCode
        #   creditorCode
        #   status
        #   countryAlpha3
        #   description
        #   addresses {
        #     uuid
        #     name
        #     type
        #     address1
        #     address2
        #     address3
        #     address4
        #     city
        #     district
        #     postCode
        #     phone
        #     fax
        #     status
        #     location {
        #       type
        #       coordinates
        #     }
        #     countryAlpha3
        #     tags
        #   }
        #   contacts {
        #     uuid
        #     name
        #     email
        #     phone
        #   }
        #   currency {
        #     uuid
        #     code
        #     name
        #   }
        # }
        # customer {
        #   uuid
        #   name
        #   types
        #   code
        #   tags
        #   debtorCode
        #   creditorCode
        #   status
        #   countryAlpha3
        #   description
        #   addresses {
        #     uuid
        #     name
        #     type
        #     address1
        #     address2
        #     address3
        #     address4
        #     city
        #     district
        #     postCode
        #     phone
        #     fax
        #     status
        #     location {
        #       type
        #       coordinates
        #     }
        #     countryAlpha3
        #     tags
        #   }
        #   contacts {
        #     uuid
        #     name
        #     email
        #     phone
        #   }
        #   currency {
        #     uuid
        #     code
        #     name
        #   }
        # }
        # salesPerson {
        #   name
        #   uuid
        #   auth0UserId
        #   auth0Token
        #   nickname
        #   givenName
        #   familyName
        #   email
        #   picture
        #   status
        #   roles
        #   companies {
        #     uuid
        #     name
        #     types
        #     status
        #     countryAlpha3
        #     currency {
        #       uuid
        #       code
        #       name
        #     }
        #   }
        # }
        # issueDate
        # dueDate
        # updatedAt
        # createdAt
        # bookingDocuments {
        #   uuid
        #   status
        #   hardCopyStatus
        #   location
        #   type
        #   url
        #   document {
        #     uuid
        #     name
        #     type
        #     size
        #   }
        # }
        # voucherPayments {
        #   uuid
        #   amount
        #   voucherUuid
        #   payment {
        #     uuid
        #     status
        #     remarks
        #     transactionType
        #     reference
        #     date
        #     amount
        #   }
        # }
        # voucherItems {
        #   uuid
        #   description
        #   unit
        #   size
        #   quantity
        #   isDeleted
        #   currency {
        #     uuid
        #     code
        #     name
        #   }
        #   baseRate
        #   exchangeRate
        #   localExchangeRate
        #   rate
        #   tax {
        #     uuid
        #     name
        #     code
        #     percentage
        #     company {
        #       uuid
        #       name
        #       types
        #       code
        #       tags
        #       debtorCode
        #       creditorCode
        #       status
        #       countryAlpha3
        #       description
        #       addresses {
        #         uuid
        #         name
        #         type
        #         address1
        #         address2
        #         address3
        #         address4
        #         city
        #         district
        #         postCode
        #         phone
        #         fax
        #         status
        #         location {
        #           type
        #           coordinates
        #         }
        #         countryAlpha3
        #         tags
        #       }
        #       contacts {
        #         uuid
        #         name
        #         email
        #         phone
        #       }
        #       currency {
        #         uuid
        #         code
        #         name
        #       }
        #     }
        #   }
        #   job {
        #     bookingUuid
        #     uuid
        #     no
        #     jobNo
        #     status
        #     remarks
        #     details
        #     reference {
        #       haulage
        #       transport
        #       shipper
        #       consignee
        #       forwardingOri
        #       forwardingDest
        #     }
        #     impRequestOfTransportDate
        #     impReadyForCollectionDate
        #     expRequestOfTransportDate
        #     expReadyForCollectionDate
        #     trips {
        #       uuid
        #       type
        #       status
        #       num
        #       from {
        #         name
        #       }
        #       to {
        #         name
        #       }
        #       legs {
        #         uuid
        #         start
        #         end
        #         driverId
        #         pmId
        #         trailerId
        #         status
        #         type
        #         to {
        #           name
        #         }
        #       }
        #     }
        #   }
        #   taxPercentage
        #   taxTotal
        #   subTotal
        #   total
        #   bookingUuid
        #   costItem {
        #     uuid
        #     bookingUuid
        #     description
        #     quantity
        #     size
        #     unit
        #     quantity
        #     sellRate
        #     costRate
        #     chargeItem {
        #       uuid
        #       code
        #       name
        #       description
        #       sequence
        #       unit
        #       sellRate
        #       costRate
        #       status
        #       rateType
        #       term
        #       taxes {
        #         uuid
        #         type
        #         rate
        #         rateType
        #         tax {
        #           uuid
        #           name
        #           code
        #           percentage
        #           company {
        #             uuid
        #             name
        #             types
        #             code
        #             tags
        #             debtorCode
        #             creditorCode
        #             status
        #             countryAlpha3
        #             description
        #             addresses {
        #               uuid
        #               name
        #               type
        #               address1
        #               address2
        #               address3
        #               address4
        #               city
        #               district
        #               postCode
        #               phone
        #               fax
        #               status
        #               location {
        #                 type
        #                 coordinates
        #               }
        #               countryAlpha3
        #               tags
        #             }
        #             contacts {
        #               uuid
        #               name
        #               email
        #               phone
        #             }
        #             currency {
        #               uuid
        #               code
        #               name
        #             }
        #           }
        #         }
        #       }
        #       sellTax {
        #         uuid
        #         name
        #         code
        #         percentage
        #         company {
        #           uuid
        #           name
        #           types
        #           code
        #           tags
        #           debtorCode
        #           creditorCode
        #           status
        #           countryAlpha3
        #           description
        #           addresses {
        #             uuid
        #             name
        #             type
        #             address1
        #             address2
        #             address3
        #             address4
        #             city
        #             district
        #             postCode
        #             phone
        #             fax
        #             status
        #             location {
        #               type
        #               coordinates
        #             }
        #             countryAlpha3
        #             tags
        #           }
        #           contacts {
        #             uuid
        #             name
        #             email
        #             phone
        #           }
        #           currency {
        #             uuid
        #             code
        #             name
        #           }
        #         }
        #       }
        #       costTax {
        #         uuid
        #         name
        #         code
        #         percentage
        #         company {
        #           uuid
        #           name
        #           types
        #           code
        #           tags
        #           debtorCode
        #           creditorCode
        #           status
        #           countryAlpha3
        #           description
        #           addresses {
        #             uuid
        #             name
        #             type
        #             address1
        #             address2
        #             address3
        #             address4
        #             city
        #             district
        #             postCode
        #             phone
        #             fax
        #             status
        #             location {
        #               type
        #               coordinates
        #             }
        #             countryAlpha3
        #             tags
        #           }
        #           contacts {
        #             uuid
        #             name
        #             email
        #             phone
        #           }
        #           currency {
        #             uuid
        #             code
        #             name
        #           }
        #         }
        #       }
        #       sellCurrency {
        #         uuid
        #         code
        #         name
        #       }
        #       costCurrency {
        #         uuid
        #         code
        #         name
        #       }
        #       categories {
        #         uuid
        #         code
        #         description
        #         sequence
        #       }
        #       revenueCode {
        #         uuid
        #         code
        #         company {
        #           uuid
        #           name
        #           types
        #           code
        #           tags
        #           debtorCode
        #           creditorCode
        #           status
        #           countryAlpha3
        #           description
        #           addresses {
        #             uuid
        #             name
        #             type
        #             address1
        #             address2
        #             address3
        #             address4
        #             city
        #             district
        #             postCode
        #             phone
        #             fax
        #             status
        #             location {
        #               type
        #               coordinates
        #             }
        #             countryAlpha3
        #             tags
        #           }
        #           contacts {
        #             uuid
        #             name
        #             email
        #             phone
        #           }
        #           currency {
        #             uuid
        #             code
        #             name
        #           }
        #         }
        #       }
        #       expenseCode {
        #         uuid
        #         code
        #         company {
        #           uuid
        #           name
        #           types
        #           code
        #           tags
        #           debtorCode
        #           creditorCode
        #           status
        #           countryAlpha3
        #           description
        #           addresses {
        #             uuid
        #             name
        #             type
        #             address1
        #             address2
        #             address3
        #             address4
        #             city
        #             district
        #             postCode
        #             phone
        #             fax
        #             status
        #             location {
        #               type
        #               coordinates
        #             }
        #             countryAlpha3
        #             tags
        #           }
        #           contacts {
        #             uuid
        #             name
        #             email
        #             phone
        #           }
        #           currency {
        #             uuid
        #             code
        #             name
        #           }
        #         }
        #       }
        #     }
        #     sellBaseRate
        #     sellRate
        #     sellTotal
        #     sellRate
        #     sellBaseRate
        #     sellExchangeRate
        #     sellCurrency {
        #       uuid
        #       code
        #       name
        #     }
        #     costBaseRate
        #     costRate
        #     costTotal
        #     costBaseRate
        #     costRate
        #     costExchangeRate
        #     costCurrency {
        #       uuid
        #       code
        #       name
        #     }
        #     accountPayable
        #     accountPayableDraft
        #     accountReceivable
        #     accountReceivableDraft
        #     cashBook
        #     blankCheque
        #     accrual
        #     estimatedProfit
        #     shortBill
        #     grossProfit
        #     isDeleted
        #     errors {
        #       msg
        #       field
        #       urgency
        #       expectedValue
        #       receivedValue
        #     }
        #   }
        #   voucherCn {
        #     uuid
        #     voucherNumber
        #   }
        # }
        # approvals {
        #   uuid
        #   type
        #   status
        #   approvedBy {
        #     name
        #     uuid
        #     auth0UserId
        #     auth0Token
        #     nickname
        #     givenName
        #     familyName
        #     email
        #     picture
        #     status
        #     roles
        #     companies {
        #       uuid
        #       name
        #       types
        #       status
        #       countryAlpha3
        #       currency {
        #         uuid
        #         code
        #         name
        #       }
        #     }
        #   }
        #   createdAt
        # }
        # bookings {
        #   uuid
        #   type
        #   no
        #   remarks
        #   shipper {
        #     uuid
        #     name
        #     addresses {
        #       uuid
        #       name
        #     }
        #   }
        #   consignee {
        #     uuid
        #     name
        #     addresses {
        #       uuid
        #       name
        #     }
        #   }
        #   liner {
        #     uuid
        #     name
        #   }
        #   shipperAddress {
        #     uuid
        #     name
        #     address1
        #     address2
        #     address3
        #     address4
        #     postCode
        #     city
        #   }
        #   consigneeAddress {
        #     uuid
        #     name
        #     address1
        #     address2
        #     address3
        #     address4
        #     postCode
        #     city
        #   }
        #   portOri {
        #     uuid
        #     name
        #     countryAlpha3
        #   }
        #   portDest {
        #     uuid
        #     name
        #     countryAlpha3
        #   }
        #   commodities
        #   jobs {
        #     uuid
        #     no
        #     jobNo
        #     status
        #     remarks
        #     details
        #     reference {
        #       haulage
        #       transport
        #       shipper
        #       consignee
        #       forwardingOri
        #       forwardingDest
        #     }
        #   }
        #   details
      }
      # permissions {
      #   canImpLinerOverrideIncompleteDocs
      #   canImpLinerOverrideDoubleVoucher
      #   canImpLinerOverrideDoubleInvoice
      # }
      # commodities
      jobs {
        bookingUuid
        uuid
        no
        jobNo
        status
        type
        remarks
        details
        # reference {
        #   haulage
        #   transport
        #   shipper
        #   consignee
        #   forwardingOri
        #   forwardingDest
        # }
        # impRequestOfTransportDate
        # impReadyForCollectionDate
        # expRequestOfTransportDate
        # expReadyForCollectionDate
        trips {
          details
          uuid
          type
          sequence
          status
          num
          from {
            name
          }
          to {
            name
          }
          #   legs {
          #     uuid
          #     start
          #     end
          #     driverId
          #     pmId
          #     trailerId
          #     status
          #     type
          #     to {
          #       name
          #     }
          #   }
        }
      }
      # serviceTypes
      # tags
      processFlow {
        type
        sections {
          name
          type
          display
          bookingDocuments {
            type
            approvals
            required
            isJobRequired
            isTripRequired
          }
          vouchers {
            bookingDocuments
            approvals
            type
            transactionCategory
            transactionType
          }
          requests {
            type
          }
          steps {
            key
            helpText
            isComplete
            isCurrentStep
            permission {
              canApprove
              canReject
            }
          }
          chronologies {
            type
            isJobRequired
            isTripRequired
          }
        }
      }
      quotation {
        uuid
        quotationNo
        #   term
        #   description
        #   date
        #   expiry
        #   status
        #   buyer {
        #     uuid
        #     name
        #   }
        #   buyerAddress {
        #     uuid
        #     name
        #   }
        #   buyerContact {
        #     uuid
        #     name
        #   }
        #   seller {
        #     uuid
        #     name
        #   }
        #   sellerAddress {
        #     uuid
        #     name
        #   }
        #   sellerContact {
        #     uuid
        #     name
        #   }
        #   editBy {
        #     email
        #     name
        #     nickname
        #   }
        #   updatedAt
        #   createdAt
        #   tags
        #   quotationItems {
        #     uuid
        #     chargeItem {
        #       uuid
        #       code
        #       name
        #       description
        #       sequence
        #       unit
        #       sellRate
        #       costRate
        #       status
        #       rateType
        #       term
        #       taxes {
        #         uuid
        #         type
        #         rate
        #         rateType
        #         tax {
        #           uuid
        #           name
        #           code
        #           percentage
        #           company {
        #             uuid
        #             name
        #             types
        #             code
        #             tags
        #             debtorCode
        #             creditorCode
        #             status
        #             countryAlpha3
        #             description
        #             addresses {
        #               uuid
        #               name
        #               type
        #               address1
        #               address2
        #               address3
        #               address4
        #               city
        #               district
        #               postCode
        #               phone
        #               fax
        #               status
        #               location {
        #                 type
        #                 coordinates
        #               }
        #               countryAlpha3
        #               tags
        #             }
        #             contacts {
        #               uuid
        #               name
        #               email
        #               phone
        #             }
        #             currency {
        #               uuid
        #               code
        #               name
        #             }
        #           }
        #         }
        #       }
        #       sellTax {
        #         uuid
        #         name
        #         code
        #         percentage
        #         company {
        #           uuid
        #           name
        #           types
        #           code
        #           tags
        #           debtorCode
        #           creditorCode
        #           status
        #           countryAlpha3
        #           description
        #           addresses {
        #             uuid
        #             name
        #             type
        #             address1
        #             address2
        #             address3
        #             address4
        #             city
        #             district
        #             postCode
        #             phone
        #             fax
        #             status
        #             location {
        #               type
        #               coordinates
        #             }
        #             countryAlpha3
        #             tags
        #           }
        #           contacts {
        #             uuid
        #             name
        #             email
        #             phone
        #           }
        #           currency {
        #             uuid
        #             code
        #             name
        #           }
        #         }
        #       }
        #       costTax {
        #         uuid
        #         name
        #         code
        #         percentage
        #         company {
        #           uuid
        #           name
        #           types
        #           code
        #           tags
        #           debtorCode
        #           creditorCode
        #           status
        #           countryAlpha3
        #           description
        #           addresses {
        #             uuid
        #             name
        #             type
        #             address1
        #             address2
        #             address3
        #             address4
        #             city
        #             district
        #             postCode
        #             phone
        #             fax
        #             status
        #             location {
        #               type
        #               coordinates
        #             }
        #             countryAlpha3
        #             tags
        #           }
        #           contacts {
        #             uuid
        #             name
        #             email
        #             phone
        #           }
        #           currency {
        #             uuid
        #             code
        #             name
        #           }
        #         }
        #       }
        #       sellCurrency {
        #         uuid
        #         code
        #         name
        #       }
        #       costCurrency {
        #         uuid
        #         code
        #         name
        #       }
        #       categories {
        #         uuid
        #         code
        #         description
        #         sequence
        #       }
        #       revenueCode {
        #         uuid
        #         code
        #         company {
        #           uuid
        #           name
        #           types
        #           code
        #           tags
        #           debtorCode
        #           creditorCode
        #           status
        #           countryAlpha3
        #           description
        #           addresses {
        #             uuid
        #             name
        #             type
        #             address1
        #             address2
        #             address3
        #             address4
        #             city
        #             district
        #             postCode
        #             phone
        #             fax
        #             status
        #             location {
        #               type
        #               coordinates
        #             }
        #             countryAlpha3
        #             tags
        #           }
        #           contacts {
        #             uuid
        #             name
        #             email
        #             phone
        #           }
        #           currency {
        #             uuid
        #             code
        #             name
        #           }
        #         }
        #       }
        #       expenseCode {
        #         uuid
        #         code
        #         company {
        #           uuid
        #           name
        #           types
        #           code
        #           tags
        #           debtorCode
        #           creditorCode
        #           status
        #           countryAlpha3
        #           description
        #           addresses {
        #             uuid
        #             name
        #             type
        #             address1
        #             address2
        #             address3
        #             address4
        #             city
        #             district
        #             postCode
        #             phone
        #             fax
        #             status
        #             location {
        #               type
        #               coordinates
        #             }
        #             countryAlpha3
        #             tags
        #           }
        #           contacts {
        #             uuid
        #             name
        #             email
        #             phone
        #           }
        #           currency {
        #             uuid
        #             code
        #             name
        #           }
        #         }
        #       }
        #     }
        #     unit
        #     size
        #     containerType
        #     rateType
        #     sellCurrency {
        #       uuid
        #       code
        #       name
        #     }
        #     sellRate
        #     sellTax {
        #       uuid
        #     name
        #     code
        #     percentage
        #     company {
        #       uuid
        #       name
        #       types
        #       code
        #       tags
        #       debtorCode
        #       creditorCode
        #       status
        #       countryAlpha3
        #       description
        #       addresses {
        #         uuid
        #         name
        #         type
        #         address1
        #         address2
        #         address3
        #         address4
        #         city
        #         district
        #         postCode
        #         phone
        #         fax
        #         status
        #         location {
        #           type
        #           coordinates
        #         }
        #         countryAlpha3
        #         tags
        #       }
        #       contacts {
        #         uuid
        #         name
        #         email
        #         phone
        #       }
        #       currency {
        #         uuid
        #         code
        #         name
        #       }
        #     }
        #     }
        #     costRate
        #     costTax {
        #       uuid
        #       name
        #       code
        #       percentage
        #       company {
        #         uuid
        #         name
        #         types
        #         code
        #         tags
        #         debtorCode
        #         creditorCode
        #         status
        #         countryAlpha3
        #         description
        #         addresses {
        #           uuid
        #           name
        #           type
        #           address1
        #           address2
        #           address3
        #           address4
        #           city
        #           district
        #           postCode
        #           phone
        #           fax
        #           status
        #           location {
        #             type
        #             coordinates
        #           }
        #           countryAlpha3
        #           tags
        #         }
        #         contacts {
        #           uuid
        #           name
        #           email
        #           phone
        #         }
        #         currency {
        #           uuid
        #           code
        #           name
        #         }
        #       }
        #     }
        #     costCurrency {
        #       uuid
        #       code
        #       name
        #     }
        #     minChargeAmount
        #     maxChargeAmount
        #     tags
        #     description
        #   }
      }
    }
  }
`

export default BOOKING_FOR_WORKFLOWS_SLIM_QUERY
