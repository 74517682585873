
import { Button } from 'antd'
import config from 'App/config'
import webStorage from 'App/utils/webStorage'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'

const ViewPdfButton = (props) => {
  const { uuid, documentCreatorUuid, onDeleted = () => { } } = props
  const url = `${config.pdf.baseUrl}/pdf/${uuid}`

  return (
    <Button.Group>
      <Button type="primary" href={url} target="_blank">
        View PDF
      </Button>
      <Button
        type="primary"
        icon="delete"
        onClick={() => {
          const pdfServiceObjectMap =
            webStorage.getItem(LOCAL_STORAGE_KEYS.DOCUMENT_CREATOR_PDF_SERVICE) || {}
          pdfServiceObjectMap[documentCreatorUuid] = undefined
          webStorage.setItem(LOCAL_STORAGE_KEYS.DOCUMENT_CREATOR_PDF_SERVICE, pdfServiceObjectMap)

          onDeleted()
        }}
      />
    </Button.Group>
  )
}

export default ViewPdfButton
