import { gql } from '@apollo/client'

const CREATE_VOUCHER_SLIM_MUT = gql`
  mutation createVoucherSlim($input: CreateVoucherInput!) {
    createVoucher(input: $input) {
      uuid
      type
      paymentType
      status
      createdBy {
        name
        givenName
        nickname
        email
      }
      approvedBy {
        name
        givenName
        nickname
        email
      }
      contactPerson
      description
      internalDescription
      invoiceNumber
      voucherNumber
      subTotal
      taxTotal
      total
      term
      printCount
      accountDate
      isCreditNote
      address {
        uuid
        name
        type
        address1
        address2
        address3
        address4
        city
        district
        postCode
        phone
        fax
        status
        countryAlpha3
      }
      currency {
        uuid
        code
        name
      }
      permissions {
        canApprove
        canCancel
      }
      balance
      transactionType
      owner {
        uuid
        name
        country {
          name
          alpha2
          alpha3
        }
        company {
          uuid
          name
          code
        }
      }
      vendor {
        uuid
        name
        code
      }
      customer {
        uuid
        name
        code
      }
      salesPerson {
        name
        uuid
        nickname
        givenName
        familyName
        email
        picture
      }
      issueDate
      dueDate
      updatedAt
      createdAt
      bookingDocuments {
        uuid
        status
        location
        type
        url
        document {
          uuid
          name
          type
          size
        }
      }
      voucherPayments {
        uuid
        amount
        voucherUuid
      }
      voucherItems {
        uuid
        description
        unit
        size
        quantity
        isDeleted
        currency {
          uuid
          code
          name
        }
        baseRate
        exchangeRate
        localExchangeRate
        rate
        tax {
          uuid
          name
          code
          percentage
        }
        taxPercentage
        taxTotal
        subTotal
        total
        bookingUuid
        costItem {
          uuid
          bookingUuid
          description
          quantity
          chargeItem {
            uuid
            code
            name
            description
          }
        }
        job {
          no
          jobNo
        }
        sorting
      }
      approvals {
        uuid
        type
        status
        approvedBy {
          name
          uuid
          nickname
          givenName
          familyName
          email
          picture
        }
        createdAt
      }
      bookings {
        uuid
        type
        no
      }
      documentCreatorTemplate {
        uuid
        name
      }
    }
  }
`

export default CREATE_VOUCHER_SLIM_MUT
