import { forwardRef, memo, useMemo, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { withApollo } from 'react-apollo'
import { useQuery } from '@apollo/client'
import { Select, Input } from 'antd'

import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'
import { GET_SETTING_AREA_CODE_INPUT_REQUIREMENT_QUERY, TRANSPORT_AREA_CODES_QUERY } from './schema'

const Option = Select.Option

const TransportAreaCodeSelect = forwardRef((props: any, ref: any) => {
  const { value, onChange, client, allowClear, placeholder, multipleMode } = props
  const { loading: reqLoading, error: reqError, data: reqData } = useQuery(GET_SETTING_AREA_CODE_INPUT_REQUIREMENT_QUERY, { client })
  const {
    loading: acLoading,
    error: acError,
    data: acData,
    refetch
  } = useQuery(TRANSPORT_AREA_CODES_QUERY, { client, variables: { limit: 500, q: '' } })

  const areaCodes = useMemo(() => acData?.transportAreaCodes?.rows || [], [acData])
  const requirement = useMemo(() => reqData?.getSettingAreaCodeInputRequirement?.value, [reqData])

  const handleSearch = useCallback((val: string) => {
    refetch && refetch({
      q: val,
      limit: 500
    })
  }, [])

  const localChange = useCallback((val: any, options: any) => {
    onChange(val, options)
  }, [])

  if (reqError || acError) {
    logger.error('TransportAreaCodeSelect Error: ', reqError || acError)
    respHandler(reqError || acError, 'error')
  }

  let mode = 'default'
  if (multipleMode) {
    mode = requirement === 'STRICT' ? 'multiple' : 'tags'
  }

  if (!multipleMode && requirement === 'FREETEXT') {
    return (
      <Input
        placeholder="Enter an area code..."
        // value={value}
        value={Array.isArray(value) && !value.length ? '' : value}
        // @ts-ignore
        onChange={localChange}
      />
    )
  }

  return (
    <Select
      allowClear={allowClear || false}
      ref={ref}
      value={value}
      onChange={localChange}
      loading={reqLoading || acLoading}
      showSearch
      showArrow
      // @ts-ignore
      mode={mode}
      filterOption={false}
      onSearch={debounce(handleSearch, 800)}
      style={{ width: '100%' }}
      placeholder={placeholder || ''}
    >
      {requirement !== 'FREETEXT' &&
        areaCodes.map((areaCode: any) => (
          <Option key={areaCode.uuid} value={areaCode.code}>
            {[areaCode.code, areaCode.name, areaCode.zone]?.filter(Boolean)?.join(' - ')}
          </Option>
        ))}
    </Select>
  )
})

export default withApollo(memo(TransportAreaCodeSelect))
