import { gql } from '@apollo/client'

export default gql`
  fragment CountryDetails on Country {
    name
    alpha2
    alpha3
    ports {
      uuid
      name
    }
  }
`
