import React, { Component } from 'react'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { compose, bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as bookingDocumentActions from 'App/states/reducers/bookingDocument'
import { UPDATE_BOOKING_DOCUMENT } from '../schema'
import schema from 'App/containers/booking/schema'
import BookingDocumentQuery from 'App/components/Workflow/Uploaded/UploadedItems/BookingDocumentQuery'
import { Wrapper, Activities, Actions, SectionName } from './Styled'
import FieldItem from '../FieldItem'
import ApprovalsView from '../Approvals'
import DocumentVerifyView from './Verify'
import EditJobAndTrip from './EditJobTrip'
import EditableInput from '../EditableInput'
import { getBkQueryVariables } from 'App/components/Booking/bookingHelper'

class ActivitiesView extends Component {
  onHandleUpdates = (update, key, value) => {
    const { bookingDocument } = this.props

    update({
      variables: {
        uuid: bookingDocument.uuid,
        type: bookingDocument.type,
        [key]: value
      }
    })
  }

  handleCompleted = (result) => {
    const { updateBookingDocument } = result
    const { bookingDocument, updateSelectedBookingDocument } = this.props

    updateSelectedBookingDocument({
      ...updateBookingDocument,
      allowedApprovals: bookingDocument.allowedApprovals
    })
  }

  renderActions = () => {
    const { bookingDocument, toggleDocumentViewer, bookingQuery } = this.props

    return (
      <Actions>
        <DocumentVerifyView
          bookingQuery={bookingQuery}
          bookingDocument={bookingDocument}
          toggleDocumentViewer={toggleDocumentViewer}
          refreshBooking={this.props.refreshBooking}
        />
      </Actions>
    )
  }

  render() {
    const { bookingDocument, match } = this.props
    const isTransportPOD = this.props?.bookingDocument?.allowedApprovals?.type === 'transHlgPodDoc'

    const mutationProps = {
      mutation: UPDATE_BOOKING_DOCUMENT,
      refetchQueries: [
        {
          query: schema.BOOKING_QUERY,
          variables: getBkQueryVariables(match.params)
        },
        {
          query: BookingDocumentQuery,
          variables: {
            uuid: bookingDocument.uuid
          }
        }
      ],
      onCompleted: this.handleCompleted
    }

    return (
      <Wrapper>
        <Activities status={bookingDocument.status}>
          <SectionName>Approvals</SectionName>
          <ApprovalsView
            bookingDocument={bookingDocument}
            approvals={
              bookingDocument.allowedApprovals && bookingDocument.allowedApprovals.approvals
            }
            approvedApprovals={bookingDocument.approvals}
          />
          <SectionName>Activities</SectionName>
          {isTransportPOD && (
            <FieldItem name="Job & Trip :" value={<EditJobAndTrip {...this.props} />} />
          )}
          <FieldItem
            name="Reference :"
            value={
              <EditableInput
                value={bookingDocument.reference}
                fieldName="reference"
                mutationProps={mutationProps}
                handleUpdates={this.onHandleUpdates}
              />
            }
          />
          <FieldItem
            name="Remarks :"
            value={
              <EditableInput
                value={bookingDocument.remarks}
                fieldName="remarks"
                mutationProps={mutationProps}
                handleUpdates={this.onHandleUpdates}
              />
            }
          />
          <FieldItem
            name="Tags :"
            value={
              <EditableInput
                type="tags"
                value={bookingDocument.tags}
                fieldName="tags"
                mutationProps={mutationProps}
                handleUpdates={this.onHandleUpdates}
              />
            }
          />
          <FieldItem name="Status :" value={bookingDocument.status} type="bold" />
          <FieldItem
            name="Last updated on :"
            value={
              bookingDocument.updatedAt &&
              moment(bookingDocument.updatedAt).format('Do MMM, YYYY - h:mm a')
            }
          />
          <FieldItem
            name="Created on :"
            value={
              bookingDocument.createdAt &&
              moment(bookingDocument.createdAt).format('Do MMM, YYYY - h:mm a')
            }
          />
          <FieldItem
            name="Created by :"
            value={bookingDocument.createdBy && bookingDocument.createdBy.email}
          />
        </Activities>
        {this.renderActions()}
      </Wrapper>
    )
  }
}

export default compose(
  withRouter,
  connect(
    (state, props) => ({}),
    (dispatch) => ({
      dispatch,
      ...bindActionCreators(
        {
          ...bookingDocumentActions
        },
        dispatch
      )
    })
  )
)(ActivitiesView)
