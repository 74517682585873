import styled from 'styled-components'

const bubbleSize = '18px'

export const MenuTitle = styled.div`
  span {
    font-size: 11.5px;
    font-weight: bold;
    color: rgb(160, 160, 160);
    text-transform: uppercase;
    letter-spacing: 0.1px;
  }
  .anticon {
    font-size: 1rem;
    font-weight: 500;
    margin-right: 10px;
    vertical-align: middle;
  }
`

export const CountBubble = styled.span`
  font-size: 10.5px;
  font-weight: bold;
  color: black;
  display: inline-block;
  height: ${bubbleSize};
  line-height: ${bubbleSize};
  border-radius: ${bubbleSize};
  text-align: center;
  margin-right: 10px;
  padding: 0 6px;
  ${(props) => (props.hasBackground ? 'background: rgb(220, 220, 220);' : '')}
`

const customOverflow = `
  overflow-x: hidden;
  height: 100%;
  overflow-y: auto;
`

export const Sidebar = styled.div`
  ${(props) => props.customOverflow && customOverflow}

  .ant-menu-inline .ant-menu-submenu-title {
    color: black;
    font-weight: 600;
    font-size: 12.5px;
    letter-spacing: 0.3px;
  }

  .ant-menu-inline .ant-menu-item {
    font-size: 12.5px;
    font-weight: 600;
    color: rgb(100, 100, 100);
    width: 100%;
  }

  .ant-menu-inline {
    border: none;
  }

  .ant-menu-item-group {
    font-size: 12.5px;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    line-height: normal;
    height: auto;
    padding: 8px 0;
    padding-left: 15px !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background: white;
    color: black;
  }

  .ant-menu-item:after {
    border: none;
  }

  .ant-menu-sub.ant-menu-inline {
    margin-bottom: 10px;
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item {
    line-height: normal;
    height: auto;
    margin: 0;
    padding: 10px 0;
    padding-left: 24px !important;
  }
`
