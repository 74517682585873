import { useCallback, useEffect, useState } from 'react'
import { parse, stringify } from 'query-string'
import { useHistory } from 'react-router'
import { Input } from 'antd'

import { InputWrapper } from './Styled'
import { getBookingQuery } from 'App/components/Layouts/Search/helper'

const SearchBox = () => {
  const history = useHistory()
  const [searchInput, setSearchInput] = useState<string | undefined>(undefined)

  useEffect(() => {
    const fromURL = history.location.search.slice(1)
    const urlParams = parse(fromURL)

    // @ts-ignore
    if (urlParams.q) setSearchInput(urlParams?.q)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const generateQuery = () => {
    const { urlFormat } = getBookingQuery(history, { q: searchInput || '' })
    history.push(`/?${stringify(urlFormat)}`)
  }

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    // Remove symbols using a regular expression
    const sanitizedValue = e.target.value.replace(/[^-\w\s]/gi, '');
    setSearchInput(sanitizedValue)
  }, [])

  const isIndexPage = history.location.pathname === '/'

  return (
    <InputWrapper>
      <Input.Search
        allowClear
        type='text'
        suffix={null}
        spellCheck="false"
        autoComplete="off"
        value={searchInput}
        name='searchBookings'
        autoFocus={isIndexPage}
        onChange={handleChange}
        placeholder="Search here..."
        onPressEnter={generateQuery}
        id='booking-elastic-search-input'
      />
    </InputWrapper>
  )
}

export default SearchBox
