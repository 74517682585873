import { useState } from 'react'

import GeneratePdfButton from './GeneratePdfButton'
import CheckPdfStatusButton from './CheckPdfStatusButton'
import ViewPdfButton from './ViewPdfButton'
import webStorage from 'App/utils/webStorage'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'

const PdfManager = (props) => {
  const { documentCreatorUuid } = props
  const [, setUpdateTime] = useState(new Date().getTime())

  const pdfServiceObjectMap = webStorage.getItem(LOCAL_STORAGE_KEYS.DOCUMENT_CREATOR_PDF_SERVICE)
  const documentCreatorPdfStatus = pdfServiceObjectMap && pdfServiceObjectMap[documentCreatorUuid]

  if (!documentCreatorPdfStatus) {
    return (
      <GeneratePdfButton
        documentCreatorUuid={documentCreatorUuid}
        onGenerated={() => setUpdateTime(new Date().getTime())}
      />
    )
  }

  if (!documentCreatorPdfStatus.finishedOn) {
    return (
      <CheckPdfStatusButton
        documentCreatorUuid={documentCreatorUuid}
        uuid={documentCreatorPdfStatus.data.uuid}
        onGenerated={() => setUpdateTime(new Date().getTime())}
      />
    )
  }

  return (
    <ViewPdfButton
      documentCreatorUuid={documentCreatorUuid}
      uuid={documentCreatorPdfStatus.data.uuid}
      onDeleted={() => setUpdateTime(new Date().getTime())}
    />
  )
}

export default PdfManager
