import { Component } from 'react'
import { withApollo } from 'react-apollo'
import { compose, withHandlers } from 'recompose'
import numeral from 'numeral'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Form, Input, InputNumber } from 'antd'

import { StyledForm, StyledLegend } from './Styled'
import * as chargeItemActions from 'App/states/reducers/chargeItem'
import ChargeItemSelect from 'App/components/Select/TypeToFetch/ChargeItemSelect'
import exchangeRatesQuery from 'App/containers/exchangeRates/schema/exchangeRates'
import multiExchangeRatesQuery from 'App/containers/bulk/multiExchangeRates/schema'
import { estimateCostItemQuery, quotationFromBookingQuery } from './schemas'
import handleResp from 'App/utils/responseHandler'
import CurrencySelect from 'App/components/Select/CurrencySelect'
import BillingUnitSelect from 'App/components/Select/BillingUnitSelect'

const handlers = withHandlers({
  handleFormFieldChanged: (props) => (field) => (value) => {
    const { form } = props
    form.setFieldsValue({ [field]: value })
  }
})

const enhance = compose(
  connect(
    (state) => ({
      currencies: state.currency.currencies,
      selectedChargeItem: state.chargeItem.selectedChargeItem,
      selectedGlobalCompany: state.globalCompany.selectedGlobalCompany
    }),
    (dispatch) => ({
      dispatch,
      ...bindActionCreators(
        {
          ...chargeItemActions
        },
        dispatch
      )
    })
  ),
  withApollo,
  handlers
)

const { TextArea } = Input

const FormItem = Form.Item
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class CostItemForm extends Component {
  fetchExchangeRate = async (from, to) => {
    const { client } = this.props

    try {
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: exchangeRatesQuery,
        variables: {
          toUuid: to,
          fromUuid: from,
          date: new Date()
        }
      })

      const exchangeRate = data && data.exchangeRates && data.exchangeRates.rows[0]

      if (data.exchangeRates.rows.length === 0) {
        handleResp(
          'No exchange rate found. Rate of 0 was set, please change accordingly.',
          'warning'
        )
      }

      return (exchangeRate && exchangeRate.rate) || 0
    } catch (error) {
      handleResp(error, 'error')
      return null
    }
  }

  fetchMultiExchangeRates = async (sellFrom, sellTo, costFrom, costTo) => {
    const { client } = this.props

    try {
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: multiExchangeRatesQuery,
        variables: {
          sellFromUuid: sellFrom,
          sellToUuid: sellTo,
          costFromUuid: costFrom,
          costToUuid: costTo,
          date: new Date()
        }
      })

      return data || {}
    } catch (error) {
      handleResp(error, 'error')
      return null
    }
  }

  fetchQuotation = async () => {
    const { client, costItem } = this.props

    try {
      const { data } = await client.query({
        query: quotationFromBookingQuery,
        variables: {
          uuid: costItem.bookingUuid
        }
      })

      const quotation = data && data.booking && data.booking.quotation

      return quotation
    } catch (error) {
      handleResp(error, 'error')
      return null
    }
  }

  estimateCostItem = async (chargeItemUuid) => {
    const { client, costItem } = this.props

    try {
      const { data } = await client.query({
        query: estimateCostItemQuery,
        variables: {
          chargeItemUuid,
          bookingUuid: costItem.bookingUuid
        }
      })

      const estimatedCostItem = data?.estimateCostItem

      return estimatedCostItem
    } catch (error) {
      handleResp(error, 'error')
      return null
    }
  }

  handleCurrencyChange = (source) => async (value, option) => {
    const { form, selectedGlobalCompany } = this.props
    const sgcCompany = selectedGlobalCompany && selectedGlobalCompany.company

    if (sgcCompany.currency.uuid !== value) {
      const exchangeRate = await this.fetchExchangeRate(value, sgcCompany.currency.uuid)

      form.setFieldsValue({
        [source]: exchangeRate
      })
    } else {
      form.setFieldsValue({
        [source]: 1
      })
    }
  }

  handleUnitSizeChange = async (value, options) => {
    const { form, selectedGlobalCompany, updateSelectedChargeItem } = this.props
    const sgcCompany = selectedGlobalCompany && selectedGlobalCompany.company

    if (!this.state?.chargeItem) {
      return
    }

    const { chargeItem } = this.state

    await this.setChargeItemValues(sgcCompany, chargeItem, updateSelectedChargeItem, form)
  }

  handleOnChargeItemChange = async (value, selectedOption) => {
    const { form, selectedGlobalCompany, updateSelectedChargeItem } = this.props
    const sgcCompany = selectedGlobalCompany && selectedGlobalCompany.company
    const chargeItem = selectedOption

    await this.setChargeItemValues(sgcCompany, chargeItem, updateSelectedChargeItem, form)
  }

  setChargeItemValues = async (sgcCompany, chargeItem, updateSelectedChargeItem, form) => {
    const quotation = await this.fetchQuotation()
    const estimatedCostItem = await this.estimateCostItem(chargeItem.uuid)
    this.setState({ quotation, chargeItem })

    // Find matching quotation item
    const quotationItems = quotation?.quotationItems
    const unit = form.getFieldValue('unit')
    const size = form.getFieldValue('size')
    let quoteItem

    if (quotationItems?.length) {
      quoteItem =
        quotationItems.find(
          (qi) => qi.chargeItem.uuid === chargeItem.uuid && qi.size === size && qi.unit === unit
        ) ||
        quotationItems.find((qi) => qi.chargeItem.uuid === chargeItem.uuid && qi.unit === unit) ||
        quotationItems.find((qi) => qi.chargeItem.uuid === chargeItem.uuid)
    }

    const sellFrom = quoteItem?.sellCurrency?.uuid || chargeItem.sellCurrency?.uuid
    const sellTo = sgcCompany?.currency.uuid
    const costFrom = quoteItem?.costCurrency?.uuid || chargeItem.costCurrency?.uuid
    const costTo = sgcCompany?.currency.uuid
    const isFixed = quoteItem?.rateType
      ? quoteItem.rateType === 'FIXED'
      : chargeItem.rateType === 'FIXED'
    const nonFixedSellRate = numeral(estimatedCostItem?.costRate || quoteItem?.costRate || chargeItem?.costRate).multiply(
     quoteItem?.costTax?.percentage || chargeItem?.costTax?.percentage
    )?._value

    form.setFieldsValue({
      sellBaseRate: isFixed ? estimatedCostItem?.sellRate || quoteItem?.sellRate || chargeItem.sellRate : nonFixedSellRate,
      sellCurrencyUuid: chargeItem.sellCurrency?.uuid,
      costBaseRate: estimatedCostItem?.costRate || quoteItem?.costRate || chargeItem.costRate,
      costCurrencyUuid: chargeItem.costCurrency?.uuid,
      sellExchangeRate: 1,
      costExchangeRate: 1
    })

    if (sellFrom !== sellTo && costFrom !== costTo) {
      const exchangeRates = await this.fetchMultiExchangeRates(sellFrom, sellTo, costFrom, costTo)

      if (exchangeRates) {
        form.setFieldsValue({
          sellExchangeRate: exchangeRates.sellExchangeRate && exchangeRates.sellExchangeRate.rate,
          costExchangeRate: exchangeRates.costExchangeRate && exchangeRates.costExchangeRate.rate
        })
      }
    } else if (sellFrom !== sellTo) {
      const exchangeRate = await this.fetchExchangeRate(sellFrom, sellTo)

      form.setFieldsValue({
        sellExchangeRate: exchangeRate.rate
      })
    } else if (costFrom !== costTo) {
      const exchangeRate = await this.fetchExchangeRate(costFrom, costTo)

      form.setFieldsValue({
        costExchangeRate: exchangeRate.rate
      })
    }

    if (!unit) {
      form.setFieldsValue({
        unit: chargeItem.unit
      })
    }

    updateSelectedChargeItem(chargeItem)
  }

  render() {
    const {
      mode,
      costItem = {},
      selectedChargeItem,
      currencies,
      form: { getFieldDecorator }
    } = this.props

    const currencySelectables = currencies.map((currency) => ({
      text: currency.code,
      value: currency.uuid
    }))

    const sellDetails = {
      sellTaxUuid: costItem.sellTax && costItem.sellTax.uuid,
      sellBaseRate: costItem.sellBaseRate,
      sellCurrencyUuid: costItem.sellCurrency && costItem.sellCurrency.uuid,
      sellExchangeRate: costItem.sellExchangeRate
    }

    const costDetails = {
      costTaxUuid: costItem.costTax && costItem.costTax.uuid,
      costBaseRate: costItem.costBaseRate,
      costCurrencyUuid: costItem.costCurrency && costItem.costCurrency.uuid,
      costExchangeRate: costItem.costExchangeRate
    }

    return (
      <StyledForm>
        {costItem.uuid &&
          getFieldDecorator('uuid', {
            initialValue: costItem.uuid
          })(<input type="hidden" />)}
        <div style={{ marginBottom: '16px' }}>
          <FormItem label="Charge Item" {...formItemLayout}>
            {mode === 'add' &&
              getFieldDecorator('chargeItemUuid', {
                initialValue: costItem.chargeItem && costItem.chargeItem.uuid,
                rules: [
                  {
                    required: true,
                    message: 'Field required.'
                  }
                ]
              })(
                <ChargeItemSelect
                  name={costItem.chargeItem && costItem.chargeItem.code}
                  onChange={this.handleOnChargeItemChange}
                />
              )}
            {mode === 'edit' && (
              <ChargeItemSelect
                name={costItem.chargeItem && costItem.chargeItem.code}
                value={costItem.chargeItem && costItem.chargeItem.uuid}
                disabled
              />
            )}
          </FormItem>
          <FormItem label="Quantity" {...formItemLayout}>
            {getFieldDecorator('quantity', {
              initialValue: costItem.quantity || 1,
              rules: [
                {
                  required: true,
                  message: 'Field required.'
                }
              ]
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Unit" {...formItemLayout}>
            {getFieldDecorator('unit', {
              initialValue:
                costItem.unit ||
                (costItem.chargeItem && costItem.chargeItem.unit) ||
                selectedChargeItem.unit
            })(<BillingUnitSelect onChange={this.handleUnitSizeChange} />)}
          </FormItem>
          <FormItem label="Size" {...formItemLayout}>
            {getFieldDecorator('size', {
              initialValue: costItem.size
            })(<InputNumber onChange={this.handleUnitSizeChange} />)}
          </FormItem>
          <FormItem label="Description" {...formItemLayout}>
            {getFieldDecorator('description', {
              initialValue: costItem.description
            })(<TextArea />)}
          </FormItem>
        </div>
        <fieldset style={{ marginBottom: '16px' }}>
          <StyledLegend>Sell</StyledLegend>
          <FormItem label="Sell Currency" {...formItemLayout}>
            {getFieldDecorator('sellCurrencyUuid', {
              initialValue:
                sellDetails.sellCurrencyUuid ||
                (currencySelectables.length && currencySelectables[0].value),
              valuePropName: 'selectedCurrencyUuid',
              trigger: 'onCurrencyChanged',
              rules: [
                {
                  required: true,
                  message: 'Field required.'
                }
              ]
            })(
              <CurrencySelect onCurrencyChanged={this.handleCurrencyChange('sellExchangeRate')} />
            )}
          </FormItem>
          <FormItem label="Sell Exchange Rate" {...formItemLayout}>
            {getFieldDecorator('sellExchangeRate', {
              initialValue: sellDetails.sellExchangeRate,
              rules: [
                {
                  required: true,
                  message: 'Field required.'
                }
              ]
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Sell Base Rate" {...formItemLayout}>
            {getFieldDecorator('sellBaseRate', {
              initialValue: sellDetails.sellBaseRate,
              rules: [
                {
                  required: true,
                  message: 'Field required.'
                }
              ]
            })(<InputNumber />)}
          </FormItem>
        </fieldset>
        <fieldset>
          <StyledLegend>Cost</StyledLegend>
          <FormItem label="Cost Currency" {...formItemLayout}>
            {getFieldDecorator('costCurrencyUuid', {
              initialValue:
                costDetails.costCurrencyUuid ||
                (currencySelectables.length && currencySelectables[0].value),
              valuePropName: 'selectedCurrencyUuid',
              trigger: 'onCurrencyChanged',
              rules: [
                {
                  required: true,
                  message: 'Field required.'
                }
              ]
            })(
              <CurrencySelect onCurrencyChanged={this.handleCurrencyChange('costExchangeRate')} />
            )}
          </FormItem>
          <FormItem label="Cost Exchange Rate" {...formItemLayout}>
            {getFieldDecorator('costExchangeRate', {
              initialValue: costDetails.costExchangeRate,
              rules: [
                {
                  required: true,
                  message: 'Field required.'
                }
              ]
            })(<InputNumber />)}
          </FormItem>
          <FormItem label="Cost Base Rate" {...formItemLayout}>
            {getFieldDecorator('costBaseRate', {
              initialValue: costDetails.costBaseRate,
              rules: [
                {
                  required: true,
                  message: 'Field required.'
                }
              ]
            })(<InputNumber />)}
          </FormItem>
        </fieldset>
      </StyledForm>
    )
  }
}

export default enhance(CostItemForm)
