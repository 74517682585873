import styled from 'styled-components'

export const FieldWrapper = styled.div`
  margin-bottom: 15px;
`

export const FieldText = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: black;
`

export const FieldValue = styled.div`
  font-size: 0.8rem;
  font-weight: 500;
  color: rgb(100, 102, 104);
`
