import { gql } from '@apollo/client'

import { fragments as currencyFragment } from 'App/containers/currency/fragment'

export const userDetails = gql`
  fragment UserDetails on User {
    name
    uuid
    auth0UserId
    auth0Token
    nickname
    givenName
    familyName
    email
    picture
    status
    roles
    companies {
      uuid
      name
      types
      status
      countryAlpha3
      currency {
        ...CurrencyDetails
      }
    }
  }

  ${currencyFragment.currencyDetails}
`

export const userBasic = gql`
  fragment UserBasicDetails on User {
    name
    uuid
    nickname
    familyName
    email
  }
`
