import { gql } from '@apollo/client'

export default gql`
  fragment JobDetails on Job {
    bookingUuid
    uuid
    no
    jobNo
    status
    remarks
    details
    reference {
      haulage
      transport
      shipper
      consignee
      forwardingOri
      forwardingDest
    }
    impRequestOfTransportDate
    impReadyForCollectionDate
    expRequestOfTransportDate
    expReadyForCollectionDate
  }
`
