import { Dropdown, Menu } from 'antd'

import Icon from 'App/components/Icon'

const menu = <Menu />

const EllipsisDropDown = (props) => {
  return (
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      trigger={['click']}
      getPopupContainer={(trigger) => trigger.parentNode}
    >
      <Icon type="ellipsis" />
    </Dropdown>
  )
}

export default EllipsisDropDown
