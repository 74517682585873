import { Component } from 'react'
import { Popover, Form, DatePicker, Input, Divider, Row, Col, Button } from 'antd'
import { IconButton } from './Styled'
import { compose, withState, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import moment from 'moment'

import { chronologyTypes } from 'App/utils/labelMap'
import { INSERT_CHRONOLOGY } from 'App/graphql/booking'
import schema from 'App/containers/booking/schema'
import handleResponse from 'App/utils/responseHandler'
import JobSelect from 'App/components/Select/NoQuery/JobSelect'
import TripSelector from 'App/components/Select/NoQuery/TripSelector'
import { logger } from 'App/utils/logger'

const withGraphqlInsertChronology = graphql(INSERT_CHRONOLOGY, {
  props: ({ mutate, ownProps }) => ({
    insertChronology: (params) =>
      mutate({
        variables: { input: params },
        refetchQueries: [
          {
            query: schema.BOOKING_QUERY_WORKFLOW,
            variables: {
              uuid: ownProps.bookingUuid
            }
          }
        ]
      })
  })
})

const handlers = withHandlers({
  handleInsert: (props) => (e) => {
    e.preventDefault()

    const { chrono, setIsVisible, form, insertChronology, bookingUuid } = props

    form.validateFields(async (err, values) => {
      if (err) {
        console.log(err)
        return
      }

      try {
        handleResponse(`Creating ${chrono.title}, hang on...`, 'load')

        const insertObj = {
          bookingUuid,
          jobUuid: values.jobUuid,
          tripUuid: values.tripUuid,
          type: chrono.type,
          date: values.chronoDatetime,
          remarks: values.remarks,
          reference: values.reference
        }
        await insertChronology(insertObj)

        setIsVisible(false)
        handleResponse('Created successfully.', 'success')
      } catch (error) {
        logger.error('ChronologyAddButton insertChronology error', error)
        handleResponse(error, 'error')
      }
    })
  },
  handleDatepickerChange: (props) => (date) => {
    const { mode, setMode } = props
    if (mode === 'date') {
      setMode('time')
    }
  },
  handleOpenChange: (props) => (open) => {
    const { setMode } = props
    setMode('date')
  },
  handlePanelChange: (props) => (value, mode) => {
    const { setMode } = props
    setMode(mode)
  }
})

const enhance = compose(
  withGraphqlInsertChronology,
  Form.create(),
  withState('isVisible', 'setIsVisible', false),
  withState('mode', 'setMode', 'date'),
  handlers
)

const FormItem = Form.Item
const dateFormat = 'DD/MM/YYYY HH:mm'
const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

export const getJobsAndTrips = (booking, getFieldsValue) => {
  const only1Job = booking?.jobs?.length === 1 && booking?.jobs?.[0]?.uuid
  const selectedValues = getFieldsValue()
  const tripsToSelect = only1Job
    ? booking?.jobs?.[0]?.trips
    : booking?.jobs?.find((job) => job?.uuid === selectedValues?.jobUuid)?.trips
  const only1Trip = tripsToSelect?.length === 1 && tripsToSelect?.[0]?.uuid

  return [only1Job, only1Trip, tripsToSelect]
}

class InsertContent extends Component {
  componentDidMount() {
    this.props.form.resetFields()
  }

  render() {
    const {
      booking,
      form,
      setIsVisible,
      handleInsert,
      chrono,
      mode,
      handleDatepickerChange,
      handleOpenChange,
      handlePanelChange
    } = this.props
    const { getFieldDecorator, getFieldsValue } = form

    const [only1Job, only1Trip, tripsToSelect] = getJobsAndTrips(booking, getFieldsValue)

    return (
      <Form onSubmit={handleInsert} style={{ width: '600px' }}>
        <FormItem label="Date & Time" {...formItemLayout}>
          {getFieldDecorator('chronoDatetime', {
            rules: [
              {
                required: true,
                message: 'Date time required.'
              }
            ],
            initialValue: moment()
          })(
            <DatePicker
              showTime
              format={dateFormat}
              placeholder="Select a date and time"
              getCalendarContainer={(trigger) => trigger.parentNode}
              style={{ width: 200 }}
              mode={mode}
              onChange={handleDatepickerChange}
              onOpenChange={handleOpenChange}
              onPanelChange={handlePanelChange}
            />
          )}
        </FormItem>
        <FormItem label="Job" {...formItemLayout}>
          {getFieldDecorator('jobUuid', {
            initialValue: only1Job || undefined,
            rules: [
              {
                required: !!chrono?.isJobRequired,
                message: 'Job is required.'
              }
            ]
          })(<JobSelect jobs={booking?.jobs} />)}
        </FormItem>
        <FormItem label="Trip" {...formItemLayout}>
          {getFieldDecorator('tripUuid', {
            initialValue: only1Trip || undefined,
            rules: [
              {
                required: !!chrono?.isTripRequired,
                message: 'Trip is required.'
              }
            ]
          })(<TripSelector form={form} trips={tripsToSelect} />)}
        </FormItem>
        <FormItem label="Reference" {...formItemLayout}>
          {getFieldDecorator('reference', {
            initialValue:
              chrono.data && chrono.data.date ? '' : chrono.data && chrono.data.reference
          })(<Input autoComplete="off" />)}
        </FormItem>
        <FormItem label="Remarks" {...formItemLayout}>
          {getFieldDecorator('remarks', {
            initialValue: ''
          })(<Input autoComplete="off" />)}
        </FormItem>
        <Divider style={{ margin: '12px 0' }} />
        <Row>
          <Col span={24} style={{ textAlign: 'right' }}>
            <Button onClick={() => setIsVisible(false)}>Cancel</Button>
            <Button type="primary" htmlType="submit" style={{ marginLeft: '5px' }}>
              Insert
            </Button>
          </Col>
        </Row>
      </Form>
    )
  }
}

const ChronologyAddButton = (props) => {
  const { chrono, isVisible, setIsVisible } = props
  const title = chronologyTypes[chrono.title] || ''

  return (
    <Popover
      trigger="click"
      content={isVisible ? <InsertContent {...props} /> : 'Closing...'}
      placement="right"
      title={title}
      visible={isVisible}
      onVisibleChange={(visible) => setIsVisible(visible)}
    >
      <IconButton icon="plus" onClick={() => setIsVisible(true)} />
    </Popover>
  )
}

export default enhance(ChronologyAddButton)
