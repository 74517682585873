import moment from 'moment'
import get from 'lodash/get'
import { useState, useEffect } from 'react'
import startCase from 'lodash/startCase'
import { withApollo } from 'react-apollo'
import { Row, Col, Spin, Layout } from 'antd'
import { useQuery, gql, ApolloClient } from '@apollo/client'
import { FieldHeader, FieldValueTypography } from './Labels'
import { TableHeaderButtonText } from 'App/styles/TableHeaderButtonText'
import { Brief } from 'App/components/Workflow/Styled'
import Section from '../Section'
import Schedules from './Schedules'
import JobAddAction from './JobAddAction'
import DocumentCreatorsSection from './DocumentCreatorsSection'
import JobsDocumentCreatorOverview from './JobsDocumentCreatorOverview'
import BookingJobsTable from 'App/components/Workflow/Section/Extras/BookingJobsTable'
import { useTranslation } from 'react-i18next'

import type { Booking, BookingTypeDynamicField } from 'App/types/types'
import { JobStatus } from 'App/types/types'
import { BookingDynamicFieldType, BookingDynamicFieldQuery, BookingTypeDynamicFieldChronologyLevel } from 'App/types/types'
import { useBookingStore } from 'App/store/booking'

type GeneralInfoProps = {
  booking: Booking
  client: ApolloClient<any>
}

export const VIEW_BOOKING_TYPE_GQL = gql`
  query bookingTypeBookingSummary($code: String) {
    bookingType(code: $code) {
      dynamicFields {
        key
        type
        query
        control
        chronologyLevel
      }
    }
  }
`

const GeneralInfo = ({ booking, client }: GeneralInfoProps) => {
  const { t } = useTranslation()

  const expandTrips = useBookingStore.use.expandTrips()
  const setExpandTrips = useBookingStore.use.setExpandTrips()
  const hideDeleted = useBookingStore.use.hideDeletedJob()
  const setHideDeleted = useBookingStore.use.setHideDeletedJob()

  const [bookingData, setBookingData] = useState(booking)
  const [hasDeleted, setHasDeleted] = useState(false)
  const showAllTrips = () => {
    setExpandTrips(!expandTrips)
  }

  useEffect(() => {
    if (!booking) return
    const filteredJobs = booking.jobs?.filter((job) => job?.status !== JobStatus.Cancelled)
    setHasDeleted(booking.jobs?.length !== filteredJobs?.length)
    setBookingData(hideDeleted ? { ...booking, jobs: filteredJobs } : booking)
  }, [hideDeleted, booking])

  const [dynamicFields, setDynamicFields] = useState<any[]>([])

  const { loading, refetch: refetchBooking } = useQuery(VIEW_BOOKING_TYPE_GQL, {
    variables: { code: booking.type },
    client,
    fetchPolicy: 'cache-first',
    onCompleted: (data) => setDynamicFields(data?.bookingType?.dynamicFields || [])
  })

  if (loading) return <Spin />

  const compulsoryFields = [
    {
      key: 'no'
    },
    {
      key: 'billTo',
      query: BookingDynamicFieldQuery.Company
    }
  ]

  const getData = (key: string, dynamicField: BookingTypeDynamicField) => {
    if (dynamicField.type === BookingDynamicFieldType.Date) {
      const splitKey = key.split('.')
      const dateKey = splitKey.length > 1 ? splitKey[1] : key
      const chronologies = get(booking, 'chronologies') || []

      switch (dynamicField.chronologyLevel) {
        case BookingTypeDynamicFieldChronologyLevel.Job: {
          const jobChrons = chronologies.filter(c => c?.jobUuid && !c?.tripUuid && c.type === dateKey)
          return booking?.jobs?.map((job, j) => `JOB#${j + 1}: ${moment(jobChrons.find(jc => jc?.jobUuid === job?.uuid)?.date)?.format('LLL') || 'NA'}`).join(', ')
        }
        case BookingTypeDynamicFieldChronologyLevel.Trip: {
          const tripsChrons = chronologies.filter(c => c?.jobUuid && c?.tripUuid && c.type === dateKey)
          return booking?.jobs?.flatMap((job, j) => job?.trips?.map((trip, t) => `TRIP#${j + 1}.${t + 1}: ${moment(tripsChrons.find(tc => tc?.tripUuid === trip?.uuid && tc?.jobUuid === job.uuid)?.date)?.format('LLL') || 'NA'}`)).join(', ')
        }
        default:
          return moment(chronologies
            .find((c) => c?.type === dateKey)?.date
          ).format('LLL')
      }

    }

    if (dynamicField.query === BookingDynamicFieldQuery.Company) {
      const company = get(booking.details, key) || get(booking, key)

      if (!company) {
        return 'No Company'
      }

      return `[${company.code || ''}] ${company.name || ''}`
    }

    if (
      // @ts-ignore
      [BookingDynamicFieldQuery.Place, BookingDynamicFieldQuery.Port].includes(dynamicField.query)
    ) {
      return `${booking.details.name}`
    }

    if (Array.isArray(booking[key])) {
      return (booking[key] || []).join(', ')
    }

    return booking[key] || booking.details[key]
  }

  const renderFields = (dynamicField: BookingTypeDynamicField) => {
    const data = getData(dynamicField.key || '', dynamicField)
    return (
      <Row key={dynamicField.key}>
        <FieldHeader>{startCase(dynamicField.key || '')} :</FieldHeader>
        <FieldValueTypography copyable={!!data}>{data}</FieldValueTypography>
      </Row>
    )
  }

  return (
    <>
      <Layout hasSider style={{ background: 'transparent' }}>
        <Layout.Content>
          <Row gutter={[16, { xs: 20, lg: 48 }]} style={{ marginTop: 1 }}>
            <Col xs={24} sm={12}>
              {compulsoryFields.map(renderFields)}
            </Col>
            <Col xs={24} sm={12}>
              {dynamicFields.map(renderFields)}
            </Col>
          </Row>
        </Layout.Content>
      </Layout>

      <Section
        title={
          <Row type='flex' justify='space-between' style={{ width: '100%' }}>
            <Row type='flex' style={{ gap: '10px' }}>
              Jobs & Trips
              <JobAddAction bookingUuid={booking?.uuid} shipmentType={booking.details.shipmentType} />
            </Row>
            <Row type='flex' style={{ textAlign: 'right', gap: '10px', alignItems: 'center' }}>
              {/* @ts-ignore */}
              <Brief onClick={setHideDeleted}>
                {hasDeleted ? (hideDeleted ? `${t('common.showDeleted')}` : `${t('common.hideDeleted')}`) : ''}
              </Brief>
              <span onClick={showAllTrips}>
                <TableHeaderButtonText>{expandTrips ? 'Minimize Jobs' : 'Expand Jobs'} </TableHeaderButtonText>
              </span>
            </Row>
          </Row>
        }
      >
        <JobsDocumentCreatorOverview booking={bookingData} expandAllTrips={expandTrips} hideDeleted={hideDeleted} />
      </Section>

      <Section>
        <BookingJobsTable booking={bookingData} query={{}} refetchBooking={refetchBooking} />
      </Section>

      <Section title="Schedules">
        <Schedules booking={booking} client={client} refetchBooking={refetchBooking} />
      </Section>

      <DocumentCreatorsSection bookingUuid={booking?.uuid} />
    </>
  )
}

export default withApollo(GeneralInfo)
