import { AddJobInput, JobType } from 'App/types/types'
import { identity, pick, pickBy, uniq } from 'lodash'
import { format } from 'date-fns'
export const getTrimmedJobDetails = (job: AddJobInput, jobTypes: JobType[]) => {
  const rawDetails = { ...(job.details || {}) }
  const foundJobType = jobTypes.find((jt) => jt.code === job.type)

  if (!foundJobType) return rawDetails

  const detailsFields = [
    ...(uniq(foundJobType?.requiredFields?.map((k) => k?.name)) as string[]),
    ...foundJobType?.dynamicFields?.map((k) => k?.key) as string[]
  ].filter(Boolean)

  // strip undefined
  const details = pickBy(rawDetails, identity)

  // pick only required fields
  const trimmedDetails = pick(details, detailsFields)

  return trimmedDetails || {}
}

export const getTrimmedInput = (job: any, jobTypes: JobType[], values?: any, type?: any) => {

  const isValidDate = (dateString: string): boolean => {
    return !isNaN(new Date(dateString).getTime());
  };

  const formattedValues = Object.keys(values).map(key => {
    return {
      [key]: format(values[key], 'DD/MM/YYYY HH:mm')
    };
  });
  const newValues = Object.assign({}, ...formattedValues);
  const rawDetails = { ...(newValues || {}) };
  const foundJobType = jobTypes.find(jt => jt.code === job.job.type);
  if (!foundJobType) return rawDetails;

  const detailsFields = [
    ...(uniq(foundJobType?.requiredFields?.map(k => k?.name) as string[])),
    ...(type === 'job'
      ? (foundJobType?.dynamicFields?.filter(f => f?.type === 'DATE').map(m => m?.key)) as string[]
      : (foundJobType?.tripDynamicFields?.map(k => k?.key) as string[])
    )
  ].filter(Boolean);

  const details = pickBy(rawDetails, identity);
  const trimmedDetails = pick(details, detailsFields);

  const filteredDetails = Object.fromEntries(
    Object.entries(trimmedDetails).filter(([key, value]) => isValidDate(value))
  );

  return filteredDetails;
}
