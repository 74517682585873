// import { getDriverName } from 'App/components/Transport/Planning/Jobs/helpers/getTableAccessors'
import {
  getBookingInfo,
  formatReportJobTripTimings,
  formatIncentivesVoucher,
  formatVouchers,
  formatReportJobLegs,
  formatCostItems,
  formatJobDistanceTime
} from 'App/components/Transport/Utils/jobHelper'
// import { formatVehicle } from '../../VehicleSelector'
import { TransportReportJob } from 'App/types/types'

export const getDriverDetails = (obj: any) => {
  return obj?.driverEntity?.name
    ? `${obj?.driverEntity?.code || '-'} / ${obj?.driverEntity?.name || '-'}`
    : `${obj?.driverCode || '-'} / ${obj?.driverNick || '-'}`
}

export const getTrailerDetails = (obj: any) => {
  if (!obj?.trailerName && !obj?.trailerEntity?.registration) {
    return '-'
  }

  const code = obj?.trailerCode || obj?.trailerEntity?.code
  const registration = obj?.trailerName || obj?.trailerEntity?.registration
  return `${code ? `${code} - ` : ''}${registration}`
}

export const getTableAccessors = (
  jobs: [TransportReportJob],
  selectedGlobalCompany: any,
) => {
  const country = selectedGlobalCompany?.country?.alpha2
  const currency = selectedGlobalCompany?.company?.currency?.code

  const formattedLegs = []

  for (let i = 0; i < jobs?.length; i++) {
    const incentivesVoucher = formatIncentivesVoucher(jobs[i], country, currency, true)
    const costItems = formatCostItems(jobs[i], country, currency, false)
    const vouchers = formatVouchers(jobs[i], country, currency, true)
    const bookingInfo = getBookingInfo(jobs[i], true)
    const distanceTime = formatJobDistanceTime(jobs[i], false)
    const tripTimings = formatReportJobTripTimings(jobs[i])
    const legsInfo = formatReportJobLegs(jobs[i])
    const bookingDocumentsSummary = jobs[i]?.bookingDocumentsSummary

    const formatted = {
      key: jobs[i].jobUuid,
      index: i + 1,
      bookingDocumentsSummary,
      // legs: formatAppendLegs(legs[i].legs, true),
      ...incentivesVoucher,
      ...distanceTime,
      ...bookingInfo,
      ...tripTimings,
      ...costItems,
      ...vouchers,
      ...legsInfo
    }
    //@ts-ignore
    formattedLegs.push(formatted)
  }
  return formattedLegs
}
