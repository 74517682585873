import styled from 'styled-components'
import { Form } from 'antd'

export const Inputs = styled(Form)`
  padding: 20px 0 0;

  .ant-btn,
  .ant-select,
  .ant-select-dropdown-menu-item,
  .ant-select-search--inline .ant-select-search__field {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.1px;
  }

  .ant-btn {
    height: 30px;
  }

  .ant-select-selection-selected-value,
  .ant-select-search--inline .ant-select-search__field {
    color: black;
  }
`

export const Actions = styled.div`
  padding: 10px 0;
  text-align: right;
`

export const QuickCreateDiv = styled.div`
  line-height: 10px;
  font-size: 0.85em;
  text-align: right;

  span {
    color: gray;
    cursor: pointer;

    :hover {
      color: #40a9ff;
    }

    .anticon {
      margin-right: 4px;
    }
  }
`
