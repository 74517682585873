import styled from 'styled-components'

import { Row } from 'antd'

export const SelectedCompany = styled.div`
  height: 100%;
  display: flex;
  line-height: normal;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }
`

export const Selections = styled.div`
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  border: 1px solid #d5dce0;
  font-size: 13px;
  color: #151b26;
  padding: 0px;
`

export const GuideText = styled.div`
  font-size: 13px;
  font-weight: 500;
  padding: 13px 15px 8px;
  color: #848f99;
`
export const IconWrapper = styled.div`
  @media (max-width: 1100px) {
    display: none;
  }
`
export const SelectedName = styled(Row)`
  position: relative;
  font-size: 13px;
  font-weight: 600;
  color: #151b26;
  cursor: pointer;
  max-width: 300px;

  .anticon {
    font-size: 14px !important;
    padding-left: 10px;
    color: rgb(100, 100, 100);
    vertical-align: -0.23em;
  }
`

export const NameWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-top: 3px;

  @media (max-width: 1100px) {
    padding-top: 0px;
  }
`

export const SelectedTypes = styled.div`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 10.5px;
  letter-spacing: 0.3px;
  color: rgb(160, 160, 160);
  line-height: normal;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const SelectOption = styled.div`
  padding: 7px 13px;
  margin-top: 2px;
  cursor: pointer;
  color: #151b26;
  border-left: 3px solid transparent;
  ${(props) => {
    if (props.selected) {
      return `
        color: black;
        font-weight: 600;
        border-left-color: #1890ff;
      `
    }
  }}

  &:hover {
    background: rgb(240, 242, 244);
  }
`
