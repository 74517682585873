
import { gql } from '@apollo/client'
import { graphql } from 'react-apollo'
import { compose } from 'recompose'
import { Icon } from 'antd'
import styled from 'styled-components'

const addressGql = gql`
  query address2($uuid: UUID) {
    address(uuid: $uuid) {
      uuid
      name
      status
      type
      postCode
      areaCode
    }
  }
`

const addressGraphql = graphql(addressGql, {
  name: 'addressGraphql',
  options: (props) => ({
    variables: {
      uuid: props.addressUuid
    }
  })
})

const enhance = compose(addressGraphql)

const StyledDiv = styled.div`
  color: darkorange;
  line-height: 1.5;

  .anticon {
    margin-right: 4px;
  }
`

const AddressAreaCodeChecker = (props) => {
  const { addressGraphql } = props
  const { address } = addressGraphql

  if (!address || address.areaCode) {
    return null
  }

  return (
    <StyledDiv>
      <Icon type="warning" />
      No Area Code found in address, no Cost Items will be generated.
    </StyledDiv>
  )
}

export default enhance(AddressAreaCodeChecker)
