
import Label from './Label'

const ShipperConsignee = (props) => {
  const { booking } = props
  const label = booking.type === 'EXPORT' ? 'SHIPPER' : 'CONSIGNEE'
  const value = booking.type === 'EXPORT' ? booking.shipperName : booking.consigneeName

  return (
    <span>
      <Label>{label}: </Label>
      {value}
    </span>
  )
}

export default ShipperConsignee
