import styled from 'styled-components'
import { Row } from 'antd'

export const SelectorType = styled.div`
  font-size: 12.5px;
  font-weight: 500;
  letter-spacing: 0.2px;
  color: black;
`

export const SelectorName = styled.div`
  font-size: 12.5px;
  font-weight: 600;
  color: rgb(150, 150, 150);
`

export const StyledRow = styled(Row)`
  font-size: 13.5px;
  font-weight: 400;
  color: #151b26;
  letter-spacing: 0.1px;
  margin-top: ${(props) => (props.count === '0' ? '0px' : '10px')};
  padding: 6px 0px;
`

export const ItemDescription = styled.div`
  color: rgb(140, 140, 140);
  font-size: 13px;
  font-weight: 500;

  .anticon {
    color: rgb(140, 140, 140);
    margin-right: 3px;
    vertical-align: -0.225em;
  }
`

export const ItemAction = styled.div`
  text-align: center;

  .anticon-check-circle-o {
    color: #52c41a;
    font-size: 16px;
  }

  .anticon-loading {
    color: rgb(140, 140, 140);
  }
`
