import uuidv4 from 'uuid/v4'
import { Component } from 'react'
import isEmpty from 'lodash/isEmpty'
import { graphql, Mutation } from 'react-apollo'
import { Select, Icon, Modal, Form, notification } from 'antd'
import { compose, withStateHandlers, withState } from 'recompose'
import { debounce } from 'lodash'

import PageHeader from 'App/components/Manage/PageHeader'
import AddressFormModal from 'App/components/Shared/AddressFormModal'
import handleResponse from 'App/utils/responseHandler'
import { formatCompanyAddress } from 'App/utils/company'
import { ModalHeader, HeaderItem } from 'App/components/Manage/Styled'
import { ADDRESS_CREATE } from 'App/graphql/address'

import { SecondaryText, Separator, QuickAddDiv, BoldSecondaryText } from './Styled'

import ADDRESSES_QUERY from './schema'

const Option = Select.Option

const handlers = withStateHandlers(
  {},
  {
    handleChange: (state, props) => (value) => {
      props.onChange(value)
    }
  }
)

const enhance = compose(
  Form.create(),
  handlers,
  withState('isAddressFormOpen', 'toggleAddressFormOpen', false),
  graphql(ADDRESSES_QUERY, {
    skip: (props) => {
      return isEmpty(props.companyUuid)
    },
    options: (props) => ({
      variables: {
        q: props.q,
        type: props.type,
        companyUuid: props.companyUuid
      },
      fetchPolicy: 'cache-and-network'
    })
  })
)

class AddressSelect extends Component {
  constructor(props) {
    super(props)
    this.state = {
      uuid: uuidv4()
    }
  }

  handleSelect = (value) => this.props.handleChange(value)

  handleSearch = debounce((value) => {
    this.props.data?.refetch({
      q: value,
      type: this.props.type,
      companyUuid: this.props.companyUuid
    })
  }, 300)

  handleSubmit = (e, addAddress, companyId) => {
    e.preventDefault()
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          const input = {
            uuid: this.state.uuid,
            ...formatCompanyAddress(values),
            companyUuid: companyId
          }
          await addAddress({
            variables: {
              input
            }
          })
          notification.success({
            message: 'Submit Result',
            description: 'Address has been created successfully, please refresh'
          })
        } catch (error) {
          handleResponse(error, 'Please ensure a company is selected')
        }
      }
    })
  }

  handleFormModalVisibility = () => {
    const { isAddressFormOpen, toggleAddressFormOpen } = this.props

    toggleAddressFormOpen(!isAddressFormOpen)
  }

  componentDidUpdate() {
    const { value, data, onChange } = this.props

    const addresses = data?.addresses?.rows || []

    if (addresses?.length === 1 && (!value || !addresses.find((a) => a.uuid === value))) {
      onChange(addresses[0].uuid)
    }
  }

  handleCompleted = () => {
    this.props.data.refetch()
    this.handleFormModalVisibility()
    this.props.form.resetFields()
    this.props.onChange(this.state.uuid)
  }

  render() {
    const {
      data,
      form,
      style,
      value,
      disabled = false,
      quickAdd = false,
      companyId = null
    } = this.props

    const addresses = data?.addresses?.rows || []
    const selectedAddress = addresses.find((a) => a.uuid === value)
    const bindingValue = selectedAddress?.uuid || undefined

    const renderQuickAdd = () => {
      const { isAddressFormOpen } = this.props

      return (
        <QuickAddDiv>
          <span onClick={this.handleFormModalVisibility}>
            <Icon type='plus-circle' />
            Add Address
          </span>
          <Modal
            width={600}
            style={{ top: 20 }}
            title={
              <ModalHeader>
                <HeaderItem span={20}>
                  <PageHeader
                    type='none'
                    title='Address Details'
                  />
                </HeaderItem>
              </ModalHeader>
            }
            footer={null}
            visible={isAddressFormOpen}
            onCancel={this.handleFormModalVisibility}
          >
            <Mutation
              mutation={ADDRESS_CREATE}
              onCompleted={this.handleCompleted}
            >
              {(addAddress) => (
                <AddressFormModal
                  form={form}
                  mode='create'
                  submitText='Submit'
                  companyId={companyId}
                  onCustomSubmit={(e) => this.handleSubmit(e, addAddress, companyId)}
                />
              )}
            </Mutation>
          </Modal>
        </QuickAddDiv>
      )
    }

    return (
      <>
        <Select
          showSearch
          filterOption
          style={style}
          disabled={disabled}
          value={bindingValue}
          onChange={this.handleSelect}
          placeholder='Select an address...'
          notFoundContent='No items found.'
          optionFilterProp='children'
          optionLabelProp='name'
          onSearch={this.handleSearch}
        >
          {addresses.map((address) => {
            return (
              <Option
                key={address.uuid}
                value={address.uuid}
                name={address.name}
              >
                {address.name}
                <Separator />
                <SecondaryText>{address.address1 || '-'}</SecondaryText>
                {address.address2 && <SecondaryText>{address.address2}</SecondaryText>}
                {address.address3 && <SecondaryText>{address.address3}</SecondaryText>}
                {address.address4 && <SecondaryText>{address.address4}</SecondaryText>}
                <div style={{ display: 'flex' }}>
                  <SecondaryText>{`${address.postCode || ''},`}&nbsp;</SecondaryText>
                  {address.areaCode && (
                    <BoldSecondaryText>{address.areaCode}&nbsp;</BoldSecondaryText>
                  )}
                  <SecondaryText>{`[${address.zone}]`}</SecondaryText>
                </div>
              </Option>
            )
          })}
        </Select>
        {quickAdd && !disabled ? renderQuickAdd() : null}
      </>
    )
  }
}

export default enhance(AddressSelect)
