import React, { Component } from 'react'

export default (WrappedComponent) => {
  class WithClientMounted extends Component {
    constructor(props) {
      super(props)

      this.state = {
        isClientMounted: false
      }
    }

    componentDidMount() {
      this.setState({
        isClientMounted: true
      })
    }

    render() {
      return <WrappedComponent isClientMounted={this.state.isClientMounted} {...this.props} />
    }
  }

  return WithClientMounted
}
