import { useState } from 'react'
import { Button } from 'antd'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'

import respHandler from 'App/utils/responseHandler'
import changeDocumentCreatorStatusAdvancedGql from './changeDocumentCreatorStatusAdvancedGql'

const changeDocumentCreatorStatusAdvancedGraphql = graphql(changeDocumentCreatorStatusAdvancedGql, {
  props: ({ mutate, ownProps }) => ({
    changeDocumentCreatorStatusAdvanced: (uuid, status) =>
      mutate({
        variables: {
          uuid,
          status
        }
      })
  })
})

const enhance = compose(changeDocumentCreatorStatusAdvancedGraphql)

const ApproveButton = (props) => {
  const { documentCreator, changeDocumentCreatorStatusAdvanced, onChange = () => {} } = props

  const [loading, setLoading] = useState(false)
  const handleClick = async () => {
    setLoading(true)

    try {
      const result = await changeDocumentCreatorStatusAdvanced(documentCreator.uuid, 'APPROVED')

      if (onChange) {
        onChange(result.data && result.data.changeDocumentCreatorStatusAdvanced)
      }

      respHandler('Document creator is approved successfully.', 'success')
    } catch (error) {
      console.log(error)
      respHandler(error, 'error')
    }
    setLoading(false)
  }

  return (
    <Button type="primary" loading={loading} onClick={handleClick}>
      Approve
    </Button>
  )
}

export default enhance(ApproveButton)
