import { memo } from 'react'
import { Avatar, Button, Row, Tooltip } from 'antd'

const UserTags = ({ focusToField, selectedUserUuids }: any) => {
  return (
    <Row type='flex' style={{ gap: 2 }}>
      {selectedUserUuids?.map((user: any) => (
        <Tooltip key={user.uuid} title={user.email}>
          <Avatar src={user.picture} />
        </Tooltip>
      ))}
      <Button icon="plus" type="dashed" shape="circle" onClick={focusToField} />
    </Row>
  )
}
export default memo(UserTags)
