import styled from 'styled-components'
import { Row, Col } from 'antd'

import SearchBox from 'App/components/Layouts/Search'
import UserFilter from 'App/components/Booking/UserFilter'
import LeftFilter from 'App/components/Layouts/LeftFilter'
import ClearButton from './ClearButton'

const StyledFilterSectionDiv = styled.div`
  margin: 16px;
`

const SearchDrawerMenu = () => {
  return (
    <div>
      <StyledFilterSectionDiv>
        <Row type="flex" justify="space-between">
          <Col span={19}>
            <SearchBox />
          </Col>
          <Col span={4} align="right">
            <ClearButton />
          </Col>
        </Row>
      </StyledFilterSectionDiv>
      <StyledFilterSectionDiv>
        <span>Filter by users: </span>
        <UserFilter />
      </StyledFilterSectionDiv>
      <LeftFilter />
    </div>
  )
}

export default SearchDrawerMenu
