import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'

import { documentCreatorsGql } from './documentCreatorsGql'
import Section from '../../Section'
import AddAction from './AddAction'
import TableView from './TableView'

const documentCreatorsGraphql = graphql(documentCreatorsGql, {
  name: 'documentCreatorsGraphql',
  options: (props) => ({
    variables: {
      bookingUuid: props.bookingUuid,
      limit: 20,
      offset: 0
    }
  })
})

const handlers = withHandlers({
  handleCreated: (props) => () => {
    const { documentCreatorsGraphql } = props
    documentCreatorsGraphql.refetch()
  }
})

const enhance = compose(documentCreatorsGraphql, handlers)

const DocumentCreatorsSection = (props) => {
  const { bookingUuid, documentCreatorsGraphql, handleCreated } = props

  return (
    <Section
      title={
        <span>
          Generated Documents <AddAction bookingUuid={bookingUuid} onCreated={handleCreated} />
        </span>
      }
    >
      <TableView documentCreatorsGraphql={documentCreatorsGraphql} />
    </Section>
  )
}

export default enhance(DocumentCreatorsSection)
