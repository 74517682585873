
import uuid from 'uuid'
import { Button, Form, Row, Divider } from 'antd'
import { compose, withState, withStateHandlers } from 'recompose'

import handleResponse from 'App/utils/responseHandler'

import addCostItemMutation from 'App/containers/costItem/mutation/createCostItem'
import CostItemForm from './CostItemForm'

const handlers = withStateHandlers(
  {},
  {
    handleAdd: (state, props) => () => {
      const { form, bookingUuid, addCostItem, toggleLoader, onCostItemAdded } = props

      form.validateFields(async (err, values) => {
        const createValues = {
          ...values,
          size: values.size ? values.size.toString() : null
        }
        if (!err) {
          try {
            toggleLoader(true)

            const resp = await addCostItem(
              {
                ...createValues,
                bookingUuid
              },
              bookingUuid
            )

            toggleLoader(false)

            if (onCostItemAdded) {
              onCostItemAdded(resp.data && resp.data.addBookingCostItem, bookingUuid)
            }
          } catch (error) {
            handleResponse(error, 'error')
            toggleLoader(false)
          }
        }
      })
    }
  }
)

const enhance = compose(
  addCostItemMutation,
  Form.create(),
  withState('loading', 'toggleLoader', false),
  handlers
)

const AddCostItemForm = (props) => {
  const { form, bookingUuid, handleAdd, loading } = props

  const costItem = {
    uuid: uuid.v4(),
    bookingUuid
  }

  return (
    <Row>
      <CostItemForm mode="add" form={form} costItem={costItem} />
      <Divider style={{ margin: '15px 0' }} />
      <div style={{ textAlign: 'right' }}>
        <Button id='create-cost-item-button' type="primary" onClick={handleAdd} loading={loading}>
          Create
        </Button>
      </div>
    </Row>
  )
}

export default enhance(AddCostItemForm)
