import { gql } from '@apollo/client'

import { fragments as currencyFragment } from 'App/containers/currency/fragment'
import { fragments as chargeItemFragment } from 'App/containers/chargeItem/fragment'
import costItemError from './error'

export default gql`
  fragment CostItemDetails on CostItem {
    uuid
    bookingUuid
    description
    quantity
    size
    unit
    quantity
    sellRate
    costRate
    chargeItem {
      ...ChargeItemDetails
    }
    sellBaseRate
    sellRate
    sellTotal
    sellRate
    sellBaseRate
    sellExchangeRate
    sellCurrency {
      ...CurrencyDetails
    }
    costBaseRate
    costRate
    costTotal
    costBaseRate
    costRate
    costExchangeRate
    costCurrency {
      ...CurrencyDetails
    }
    accountPayable
    accountPayableDraft
    accountReceivable
    accountReceivableDraft
    cashBook
    blankCheque
    accrual
    estimatedProfit
    shortBill
    grossProfit
    isDeleted
    errors {
      ...CostItemErrorDetails
    }
  }

  ${costItemError}
  ${currencyFragment.currencyDetails}
  ${chargeItemFragment.chargeItemDetails}
`
