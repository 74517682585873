export const actions = {
  TOGGLE_LOADER: 'TOGGLE_LOADER'
}

export const toggleLoader = () => {
  return {
    type: actions.TOGGLE_LOADER
  }
}

const initialState = {
  isLoading: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE_LOADER:
      return { ...state, isLoading: !state.isLoading }
    default:
      return state
  }
}
