import styled from 'styled-components'
import { Layout } from 'antd'
const { Content } = Layout

export const StyledContent = styled(Content)`
  padding-left: 10px;
  padding-top: 10px;

  .ant-table-small {
    box-shadow: none;
  }

  .ant-table-wrapper {
    .ant-table-small > .ant-table-content > .ant-table-body {
      margin: 0;
    }

    .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
      font-size: 13.222px;
      font-weight: 400;
      color: #151b26;
    }

    .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
      font-size: 11.5px;
      font-weight: 500;
      color: #848f99;
    }
  }
`

export const SingleLineTableContainer = styled(Content)`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 56px;
  overflow: auto;

  .ant-table-small {
    box-shadow: none;
    border: none;
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0;
  }

  .ant-table-wrapper {
    background: white;
    margin: 20px;
    margin-top: 0;
    padding: 10px 15px 0;
    box-shadow: 0 2px 4px 0 rgba(35, 37, 51, 0.08), 0 0 2px 0 rgba(35, 37, 51, 0.18);
    border-radius: 2px;

    .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-tbody > tr > td {
      font-size: 13.222px;
      font-weight: 400;
      color: #151b26;
      padding: 10px 8px;
      padding-left: 0;
      max-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ant-table-small > .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th {
      font-size: 11.5px;
      font-weight: 500;
      color: #848f99;
      white-space: nowrap;
      padding: 8px 8px;
      padding-left: 0;
    }
  }
`

export const SearchControls = styled.div`
  font-size: 13px;
  font-weight: 600;
  color: rgb(140, 142, 144);
  padding: 10px 25px;
  box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.15);

  .ant-btn {
    font-size: 13.5px;
  }
`

export const EmptyResults = styled.div`
  font-size: 14.5px;
  font-weight: 500;
  color: #848f99;
  text-align: center;
  padding-top: 100px;
  height: 100vh;
`

export const FiltersView = styled.div`
  width: 100%;
  padding-top: 0px;
  border-right: 1px solid rgb(235, 235, 235);
`

export const ResultCount = styled.div`
  font-size: 13.222px;
  font-weight: 500;
  padding: 10px 20px;
`

export const FilterText = styled.div`
  font-size: 13.222px;
  font-weight: 400;
  color: #848f99;
  line-height: normal;
  // margin-right: 5px;
`

export const SearchFilter = styled.div`
  padding: 0px 0 10px;

  .ant-select-selection--multiple {
    padding-bottom: 0px;
  }

  .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-bottom: 0px;
  }

  .ant-select-selection {
    border: none;
  }
`
