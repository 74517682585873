
import { Fragment } from 'react'
import { Drawer } from 'antd'
import styled from 'styled-components'
import { compose, withHandlers, withState } from 'recompose'

import SearchDrawerMenu from './SearchDrawerMenu'
import Icon from 'App/components/Icon'

const visibleState = withState('visible', 'setVisible', false)

const handlers = withHandlers({
  handleClick: (props) => () => {
    const { setVisible } = props
    setVisible(true)
  },
  handleClose: (props) => () => {
    const { setVisible } = props
    setVisible(false)
  }
})

const enhance = compose(visibleState, handlers)

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`

const SearchActionButton = (props) => {
  const { visible, handleClick, handleClose } = props

  return (
    <Fragment>
      <Icon
        type="search"
        style={{ cursor: 'pointer', padding: '16px 16px' }}
        onClick={handleClick}
      />
      <StyledDrawer closable={false} placement="right" onClose={handleClose} visible={visible}>
        <SearchDrawerMenu />
      </StyledDrawer>
    </Fragment>
  )
}

export default enhance(SearchActionButton)
