import { gql } from '@apollo/client'

export const fragments = {
  addressDetail: gql`
    fragment AddressDetail on Address {
      uuid
      name
      type
      address1
      address2
      address3
      address4
      city
      district
      postCode
      phone
      fax
      status
      countryAlpha3
    }
  `
}

export const ADDRESS_UPDATE = gql`
  mutation updateAddress2($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      name
    }
  }
`

export const ADDRESS_CREATE = gql`
  mutation addAddress1($input: AddAddressInput!) {
    addAddress(input: $input) {
      uuid
    }
  }
`

export const ADDRESS_DELETE = gql`
  mutation deleteAddress2($uuid: UUID) {
    deleteAddress(uuid: $uuid) {
      success
    }
  }
`

export const ADDRESSES_QUERY = gql`
  query addresses2($companyUuid: UUID, $type: [AddressType], $statuses: [AddressStatus]) {
    addresses(companyUuid: $companyUuid, type: $type, statuses: $statuses) {
      rows {
        ...AddressDetail
      }
    }
  }

  ${fragments.addressDetail}
`

export const BASE_COMPANY_ADDRESS = gql`
  query getBaseCompanyAddress {
    getBaseCompanyAddress {
      uuid
      name
      type
      status
      address1
      address2
      address3
      address4
      city
      district
      postCode
      areaCode
      zone
      phone
      fax
      tags
      location {
        type
        coordinates
      }
      plusCode
      placeId
      countryAlpha3
    }
  }
`
