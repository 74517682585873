
import { Summary } from '../Styled'

import useGlobalCompanyStore from 'App/store/globalCompany'

import FormattedCurrency from 'App/components/Formatted/Currency'

const { LabelItem, ValueItem, SummaryItem: SummaryItemRow, SummaryLabels, SummaryValues } = Summary

const SummaryItem = ({ label, value, state = 'normal' }) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const currencyCode = selectedGlobalCompany?.company?.currency?.code

  return (
    <SummaryItemRow state={state}>
      <SummaryLabels span={12}>
        <LabelItem>{label} : </LabelItem>
      </SummaryLabels>
      <SummaryValues span={12}>
        <ValueItem>
          <FormattedCurrency value={value} currency={currencyCode} />
        </ValueItem>
      </SummaryValues>
    </SummaryItemRow>
  )
}

export default SummaryItem
