import { Form, Input } from 'antd'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import find from 'lodash/find'
import compact from 'lodash/compact'
import groupBy from 'lodash/groupBy'
import fpGroupBy from 'lodash/fp/groupBy'
import sumBy from 'lodash/sumBy'
import filter from 'lodash/fp/filter'
import countBy from 'lodash/fp/countBy'
import toPairs from 'lodash/fp/toPairs'
import labelMap from './labelMap'
import includes from 'lodash/includes'
import * as math from 'mathjs'
import convert from 'convert-units'

export const tagTypes = [
  'HOUSE_BL',
  'HOUSE_BOOKING_CONFIRMATION',
  'AUTO_APPROVE_EXPORT_CUSTOMS',
  'AUTO_APPROVE_IMPORT_CUSTOMS',
  'BILL_OF_LADING_HOLD',
  'DOOR_TO_DOOR',
  'DOOR_TO_PORT_ORI',
  'DOOR_TO_PORT_DEST',
  'PORT_TO_PORT',
  'PORT_TO_DOOR'
]

export const transportDocumentTypes = ['SEA_WAYBILL', 'ORDER_BL', 'NOMINATE_BL', 'BEARER_BL']

export const serviceTypes = [
  'HAULAGE',
  'TRANSPORT',
  'FORWARDING',
  'FREIGHTFORWARDING',
  'SHIPPING_AGENT'
]

export const incoTerms = [
  {
    code: 'EXW',
    label: 'Ex Works'
  },
  {
    code: 'FCA',
    label: 'Free Carrier'
  },
  {
    code: 'CPT',
    label: 'Carriage Paid To'
  },
  {
    code: 'CIP',
    label: 'Carriage and Insurance Paid To'
  },
  {
    code: 'DAT',
    label: 'Delivered at Terminal'
  },
  {
    code: 'DAP',
    label: 'Delivered at Place'
  },
  {
    code: 'DDP',
    label: 'Delivered Duty Paid'
  },
  {
    code: 'FAS',
    label: 'Free Alongside Ship'
  },
  {
    code: 'FOB',
    label: 'Free on Board'
  },
  {
    code: 'CFR',
    label: 'Cost and Freight'
  },
  {
    code: 'CIF',
    label: 'Cost, Insurance, and Freight'
  }
]

export const getFields = (booking) => {
  const ieLabel = booking.type === 'IMPORT' ? 'Dest' : 'Ori'
  const currentHaulierRef = `details.haulier${ieLabel}Ref`
  const currentForwarderRef = `details.forwarder${ieLabel}Ref`

  let summaries = []
  if (booking.type === 'IMPORT' || booking.type === 'EXPORT') {
    summaries = [
      {
        label: 'Reference',
        data: [
          [
            [{ label: 'No.', ref: 'no', type: 'no' }],
            [{ label: 'BL', ref: 'details.bl' }],
            [{ label: 'Shipping Reference', ref: 'details.shippingRef' }],
            [{ label: 'Manifest No.', ref: 'details.manifestNo' }],
            [{ label: 'House BL No.', ref: 'details.houseBl' }],
            [{ label: 'References', type: 'reference', ref: 'details.references' }],
            [{ label: 'SA Booking No.', ref: 'details.saBookingNo' }],
            [{ label: 'Customs No', type: 'reference', ref: 'details.customsDocNo' }]
          ],
          [
            [{ label: 'Shipper Ref', ref: 'details.shipperRef' }],
            [{ label: 'Consignee Ref', ref: 'details.consigneeRef' }],
            [{ label: 'Forwarder Ref', ref: currentForwarderRef }],
            [{ label: 'Haulier Ref', ref: currentHaulierRef }],
            [{ label: 'Service Types', type: 'service', ref: 'serviceTypes' }],
            [{ label: 'IncoTerm', type: 'incoTerm', ref: 'details.incoTerm' }],
            [
              {
                label: 'Transport Document Type',
                type: 'transportDocumentType',
                ref: 'details.transportDocumentType'
              }
            ],
            [{ label: 'Tags', type: 'tag', ref: 'tags' }]
          ]
        ]
      },
      {
        label: 'Shipping',
        data: [
          [
            [
              { label: 'Voyage No', ref: 'details.voyageNo' },
              { label: 'Vessel ID', ref: 'details.vesselId' },
              { label: 'Vessel Name', ref: 'details.vesselName' },
              { label: 'Mother Vessel Name', ref: 'details.motherVesselName' },
              { label: 'Mother Vessel Voyage No', ref: 'details.motherVesselVoyageNo' },
              { label: 'Transshipment Port', ref: 'details.transshipmentPort' },
              { label: 'Ship Call Sign', ref: 'details.shipCallSign' },
              { label: 'SCN', ref: 'details.scn' }
            ],
            [
              { label: 'Origin', type: 'header', value: ' ' },
              {
                label: 'Country',
                type: 'country',
                value: ' ',
                portRef: 'portOri',
                ref: 'countryOri',
                oriDest: 'Ori'
              },
              { label: 'Place', type: 'place', portRef: 'portOri', ref: 'placeOri' },
              { label: 'Port', type: 'port', placeRef: 'placeOri', ref: 'portOri', oriDest: 'Ori' },
              { label: 'Port Terminal', ref: 'details.terminalOri' },
              { label: 'Operator code', ref: 'details.operatorOri' }
            ],
            [
              { label: 'Destination', type: 'header', value: ' ' },
              {
                label: 'Country',
                type: 'country',
                value: ' ',
                portRef: 'portDest',
                ref: 'countryDest',
                oriDest: 'Dest'
              },
              { label: 'Place', type: 'place', portRef: 'portDest', ref: 'placeDest' },
              {
                label: 'Port',
                type: 'port',
                placeRef: 'placeDest',
                ref: 'portDest',
                oriDest: 'Dest'
              },
              { label: 'Port terminal', ref: 'details.terminalDest' },
              { label: 'Operator code', ref: 'details.operatorDest' }
            ]
          ]
        ]
      },
      {
        label: 'Parties',
        data: [
          [
            [
              {
                label: 'Bill To',
                type: 'company',
                companyType: 'billing',
                ref: 'billTo',
                countryFilter: ieLabel
              },
              {
                label: 'Quotation No.',
                type: 'quotation',
                ref: 'quotation',
                useAnchor: true,
                oriDest: ieLabel
              }
            ],
            [
              {
                label: 'Shipper',
                type: 'company',
                companyType: 'shipperConsignee',
                ref: 'shipper',
                countryFilter: 'Ori'
              },
              { label: 'Address', type: 'address', addressType: 'shipper', ref: 'shipperAddress' }
            ],
            [
              {
                label: 'Consignee',
                type: 'company',
                companyType: 'shipperConsignee',
                ref: 'consignee',
                countryFilter: 'Dest'
              },
              {
                label: 'Address',
                type: 'address',
                addressType: 'consignee',
                ref: 'consigneeAddress'
              }
            ],
            [{ label: 'Warehouse', type: 'company', companyType: 'warehouse', ref: 'warehouse' }]
          ],
          [
            [
              {
                label: 'Shipping Agent (Ori.)',
                type: 'company',
                companyType: 'shippingAgent',
                ref: 'linerOri',
                countryFilter: 'Ori'
              },
              {
                label: 'Shipping Agent (Dest.)',
                type: 'company',
                companyType: 'shippingAgent',
                ref: 'linerDest',
                countryFilter: 'Dest'
              }
            ],
            [
              {
                label: 'Forwarder (Ori.)',
                type: 'company',
                companyType: 'forwarder',
                ref: 'forwarderOri',
                countryFilter: 'Ori',
                disabled: true
              },
              {
                label: 'Forwarder (Dest.)',
                type: 'company',
                companyType: 'forwarder',
                ref: 'forwarderDest',
                countryFilter: 'Dest',
                disabled: true
              }
            ]
          ]
        ]
      },
      {
        label: 'Others',
        data: [
          [
            [{ label: 'Commodity', type: 'commodity', ref: 'commodities' }],
            [{ label: 'Remarks', type: 'remarks', ref: 'remarks' }]
          ]
        ]
      }
    ]
  } else {
    summaries = [
      {
        label: 'Reference',
        data: [
          [
            [{ label: 'No.', ref: 'no', type: 'no' }],
            [{ label: 'Shipper Ref', ref: 'details.shipperRef' }],
            [{ label: 'References', type: 'reference', ref: 'details.references' }],
            [{ label: 'Departments', type: 'departments', ref: 'details.departments' }],
            [{ label: 'Tags', type: 'tags', ref: 'details.tags' }]
          ]
        ]
      },
      {
        label: 'Parties',
        data: [
          [
            [
              {
                label: 'Bill To',
                type: 'company',
                companyType: 'billing',
                ref: 'billTo',
                countryFilter: ieLabel
              },
              {
                label: 'Quotation No.',
                type: 'quotation',
                ref: 'quotation',
                useAnchor: true,
                oriDest: ieLabel
              }
            ],
            [
              {
                label: 'Shipper',
                type: 'company',
                companyType: 'shipperConsignee',
                ref: 'shipper',
                countryFilter: 'Ori'
              },
              { label: 'Address', type: 'address', addressType: 'shipper', ref: 'shipperAddress' }
            ],
            [
              {
                label: 'Consignee',
                type: 'company',
                companyType: 'shipperConsignee',
                ref: 'consignee',
                countryFilter: 'Dest'
              },
              {
                label: 'Address',
                type: 'address',
                addressType: 'consignee',
                ref: 'consigneeAddress'
              }
            ],
            [{ label: 'Warehouse', type: 'company', companyType: 'warehouse', ref: 'warehouse' }]
          ]
        ]
      },
      {
        label: 'Others',
        data: [
          [
            [{ label: 'Commodity', type: 'commodity', ref: 'commodities' }],
            [{ label: 'Remarks', type: 'remarks', ref: 'remarks' }]
          ]
        ]
      }
    ]
  }

  return summaries
}

export const getBl = (booking) => {
  if (booking.type === 'IMPORT') {
    return booking.details.bl
  }
  return (
    booking.details.shippingRef ||
    booking.details.shipperRef ||
    (booking.details.reference || []).join(',')
  )
}

export const getshipperOrConsignee = (booking) => {
  if (booking.type === 'IMPORT') {
    return (booking.consignee && booking.consignee.name) || booking.consigneeName
  }
  return (booking.shipper && booking.shipper.name) || booking.shipperName
}

export const getRef = (companyType, booking) => {
  if (!booking) {
    return '-'
  }

  if (booking.no) {
    return booking.no
  }

  if (booking.referenceNo) {
    return booking.referenceNo
  }

  let ref = ''

  if (!booking.details) {
    booking.details = {}
  }

  switch (companyType) {
    case 'shipperConsignee':
      ref = [
        booking.type === 'IMPORT' ? booking.details.consigneeRef : '',
        booking.type === 'EXPORT' ? booking.details.shipperRef : ''
      ]
        .filter((s) => s)
        .join(' & ')
      break
    case 'forwarder':
    case 'haulier':
    case 'liner':
      ref = [
        booking.type === 'IMPORT' ? booking.details[`${companyType}DestRef`] : '',
        booking.type === 'EXPORT' ? booking.details[`${companyType}OriRef`] : ''
      ]
        .filter((s) => s)
        .join(' & ')
      break
    default:
  }

  if (!ref) {
    ref =
      booking.details.bl ||
      booking.details.shippingRef ||
      (booking.details.reference || []).join(',') ||
      ''
  }

  return ref
}

export const getContainerStats = (jobs) =>
  flow(
    countBy((job) => (job.details?.containerType || '') + '/' + (job.details?.size || '')),
    toPairs,
    map(([key, count]) => {
      const splitted = key.split('/')
      return {
        type: splitted[0],
        size: splitted[1],
        count
      }
    })
  )(jobs)

export const getWeightStats = (jobs) =>
  flow(
    countBy((job) => job.details.weight + '/' + job.details.weightUnit),
    toPairs,
    map(([key, count]) => {
      const splitted = key.split('/')
      return {
        weight: splitted[0],
        unit: splitted[1],
        count
      }
    })
  )(jobs)

export const formatCompanies = (formValues, section) => {
  const types = section === 'transports' ? ['haulier', 'liner'] : ['shipper', 'forwarder']
  return flow(
    map((type) => {
      const item = find(formValues[section], ['type', type])
      if (item && formValues[`${type}Uuid`]) {
        const transport = { companyUuid: formValues[`${type}Uuid`] }
        if (item.uuid) {
          transport.uuid = item.uuid
        }
        if (item.type) {
          transport.type = item.type
        }
        return transport
      }
    }),
    compact
  )(types)
}

export const getJobsVolume = (jobs) => {
  return getContainerStats(jobs)
    .map((s) => `${s.count}x${s.size} ${s.type}`)
    .join(', ')
}

export const getJobsWeightSum = (jobs) => {
  const jobWeightStats = getWeightStats(jobs)

  return flow(
    filter(
      (j) =>
        !(j.weight === 'undefined' || j.weight === 'null') &&
        !(j.unit === 'undefined' || j.unit === 'null')
    ),
    fpGroupBy((j) => j.unit),
    map((jobs) => {
      return `${sumBy(jobs, (j) => math.multiply(j.weight || 1, j.count || 1))} ${jobs[0].unit}`
    })
  )(jobWeightStats).join(', ')
}

export const getJobsVolumeSum = (jobs) => {
  const sum = jobs
    .map((j) => {
      if (!j.details || !j.details.volume || !j.details.volumeUnit) {
        return 0
      }

      return convert(j.details.volume).from(j.details.volumeUnit).to('m3')
    })
    .reduce((acc, cur) => {
      return acc + cur
    }, 0)

  return sum
}

export const getCustomerRef = (booking) => {
  if (booking.type === 'EXPORT') {
    return booking.details.shipperRef
  }

  return booking.details.consigneeRef
}

export const parseCompanies = (formValues, section) => {
  const types =
    section === 'transports'
      ? ['haulier', 'liner', 'haulierOri', 'haulierDest', 'linerOri', 'linerDest']
      : ['shipper', 'forwarder', 'shipperOri', 'shipperDest', 'forwarderOri', 'forwarderDest']

  return flow(
    map((type) => formValues[`${type}Uuid`] && { companyUuid: formValues[`${type}Uuid`], type }),
    compact
  )(types)
}

export const handleResponse = (error) => {
  if (error.graphQLErrors && error.graphQLErrors.length) {
    const graphQLError = error.graphQLErrors[0]

    return {
      success: false,
      message: graphQLError.message
    }
  }
}

export const trimName = (userName) => {
  if (userName && userName.length > 6) {
    return userName.substring(0, 6) + '...'
  } else {
    return userName
  }
}

export const filterDocuments = (documents) =>
  groupBy(documents, (data) => {
    if (data.file && data.file.uuid !== null && data.type === null) {
      return 'identifiedDoc'
    }
    if (data.file && data.file.uuid !== null && data.type !== null && data.status !== 'VERIFIED') {
      return 'unverifiedDocs'
    }
    if (data.file && data.file.uuid !== null && data.status === 'VERIFIED') {
      return 'verifiedDocs'
    }
  })

export const filterBookingState = (bookingState) => {
  let iconName = icons.PENDING

  if (includes(bookingState, 'Comp')) {
    iconName = icons.DONE
  } else if (includes(bookingState, 'Less')) {
    iconName = icons.WARNING
  }

  if (bookingState) {
    return {
      stateName: bookingState,
      stateDisplayName: labelMap[bookingState] || bookingState,
      iconName
    }
  } else {
    return {
      stateDisplayName: 'Pending',
      iconName: 'access_time'
    }
  }
}

const icons = {
  PENDING: 'access_time',
  DONE: 'done',
  WARNING: 'warning'
}

const stateDisplayInfo = {
  documentation: { defaultDisplayName: 'Documentation', iconName: 'file-alt', fa: true },
  liner: { defaultDisplayName: 'Liner', iconName: 'anchor', fa: true },
  freight: { defaultDisplayName: 'Freight', iconName: 'ship', fa: true },
  customs: { defaultDisplayName: 'Customs', iconName: 'chalkboard-teacher', fa: true },
  haulage: { defaultDisplayName: 'Hlg/Trans', iconName: 'truck', fa: true },
  compAp: { defaultDisplayName: 'Payment', iconName: 'money-bill', fa: true },
  compAr: { defaultDisplayName: 'Invoice', iconName: 'file-invoice-dollar', fa: true },
  complete: { defaultDisplayName: 'Completion', iconName: 'flag-checkered', fa: true }
}

export const displayState = {
  expDoc: stateDisplayInfo.documentation,
  expHlg: stateDisplayInfo.haulage,
  expLiner: stateDisplayInfo.liner,
  expFreight: stateDisplayInfo.freight,
  expCust: stateDisplayInfo.customs,
  expCompAp: stateDisplayInfo.compAp,
  expCompAr: stateDisplayInfo.compAr,
  expComp: stateDisplayInfo.complete,
  impDoc: stateDisplayInfo.documentation,
  impLiner: stateDisplayInfo.liner,
  impFreight: stateDisplayInfo.freight,
  impCust: stateDisplayInfo.customs,
  impHlg: stateDisplayInfo.haulage,
  impCompAp: stateDisplayInfo.compAp,
  impCompAr: stateDisplayInfo.compAr,
  impComp: stateDisplayInfo.complete,
  transDoc: stateDisplayInfo.documentation,
  transHlg: stateDisplayInfo.haulage,
  transCompAr: stateDisplayInfo.compAr,
  transComp: stateDisplayInfo.complete
}

export const getDisplayState = (key) => {
  const dispState = displayState[key]

  return dispState || stateDisplayInfo.complete
}

export const generateFields = (requiredFields = []) => {
  const columns = [
    {
      name: 'transportType'
    },
    {
      name: 'containerType'
    },
    {
      name: 'containerGrade'
    },
    {
      name: 'no'
    },
    {
      name: 'options'
    },
    {
      name: 'size'
    },
    {
      name: 'weight'
    },
    {
      name: 'volume'
    },
    {
      name: 'seal'
    },
    {
      name: 'tripSeal'
    },
    {
      name: 'tripReferences'
    }
  ]

  return columns.reduce(
    (data, { name: colName }) => {
      const colFound = requiredFields.find(({ name }) => name === colName)

      data.hidden[colName] = colFound?.control === 'HIDDEN'
      data.required[colName] = colFound?.control === 'REQUIRED'
      return data
    },
    { hidden: {}, required: {} }
  )
}

export const generateDynamicFields = (
  dynamicFields,
  form,
  duplicateBookingObject,
  formItemLayout
) => {
  if (dynamicFields.length === 0) {
    return { firstCol: [], secondCol: [] }
  }

  const { getFieldDecorator } = form

  const half = Math.ceil(dynamicFields.length / 2)

  const generateField = ({ label, name, control }) => {
    return (
      <>
        <Form.Item label={label} {...formItemLayout}>
          {getFieldDecorator(`details.${name}`, {
            initialValue: duplicateBookingObject?.[name],
            rules: [{ required: control === 'REQUIRED', message: `${label} is required` }]
          })(<Input autoComplete="off" />)}
        </Form.Item>
      </>
    )
  }

  const firstCol = dynamicFields.slice(0, half).map(generateField)
  const secondCol = dynamicFields.slice(half).map(generateField)
  return { firstCol, secondCol }
}
