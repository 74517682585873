export const statuses = [
  {
    value: 'activated',
    label: 'Active'
  },
  {
    value: 'deleted',
    label: 'Deleted'
  }
]

export const tags = [
  {
    value: 'isConsolInvoice',
    label: 'Consol Invoice'
  },
  {
    value: 'isRebateInvoice',
    label: 'Rebate Invoice'
  },
  {
    value: 'isInterComp',
    label: 'Inter Company'
  },
  {
    value: 'isInterBranch',
    label: 'Inter Branch'
  },
  {
    value: 'isDomestic',
    label: 'Domestic'
  },
  {
    value: 'isInternational',
    label: 'International'
  },
  {
    value: 'isFcl',
    label: 'FCL'
  },
  {
    value: 'isLcl',
    label: 'LCL'
  },
  {
    value: 'isCon',
    label: 'Consolidate'
  },
  {
    value: 'isAir',
    label: 'Air'
  },
  {
    value: 'isSea',
    label: 'Sea'
  },
  {
    value: 'isLand',
    label: 'Land'
  },
  {
    value: 'DEPT:value',
    label: 'DEPT:[value]'
  }
]

export const formatCompanyAddress = (values) => {
  const rtn = { ...values }
  if (rtn.areaCode) {
    if (Array.isArray(rtn.areaCode)) {
      rtn.areaCode = rtn.areaCode.length && rtn.areaCode[0] ? rtn.areaCode[0] : null
    }
  }

  if (typeof rtn.latitude === 'number' && typeof rtn.longitude === 'number') {
    rtn.location = [rtn.latitude, rtn.longitude]
  }
  delete rtn.latitude
  delete rtn.longitude

  return rtn
}

// , {
//   value: 'oneTimeVendor',
//   label: 'One Time Vendor'
// }
