import { DatePicker, Input } from 'antd'
import { withApollo } from 'react-apollo'
import moment from 'moment'
import type { Moment } from 'moment'

import SelectWithQuery from 'App/components/Booking/DynamicField/SelectWithQuery'
import EnumSelector from 'App/components/Shared/EnumSelector'

import {
  getDynamicFieldQuery
} from 'App/components/Booking/DynamicField/helper'

import {
  BookingDynamicFieldType,
  BookingTypeDynamicField,
  GenericDynamicFieldQuery
} from 'App/types/types'
import TagsInput from 'App/components/Shared/TagsInput/TagsInput'

type DynamicFieldProps = {
  client?: any
  field: BookingTypeDynamicField
  id?: string
  value?: any
  onChange?: (value: any) => void
  disabled?: boolean
}

export const DATE_FORMAT = 'DD/MM/YYYY HH:mm'

const DynamicField = (props: DynamicFieldProps) => {
  const { value, field, onChange, client, id, disabled = false } = props

  const renderField = (dynamicField: any) => {
    const { key, query, type, customValues, multiple, isMultiline } = dynamicField

    const renameId = id?.split('.').join('-') || id

    let sharedProps: any = {
      value,
      id: renameId,
      disabled
    }

    switch (type) {
      case BookingDynamicFieldType.Selector:
        sharedProps = {
          ...sharedProps,
          onChange
        }
        switch (query) {
          case GenericDynamicFieldQuery.Enums:
            return (
              <EnumSelector
                client={client}
                placeholder="Select a value..."
                enumName={field.enumName}
                multiple={field.multiple}
                {...sharedProps}
              />
            )
          default: {
            if (query === GenericDynamicFieldQuery.CustomValues && !customValues) {
              return (
                <TagsInput
                  placeholder={`Enter ${key} here and press <Enter> to add`}
                  {...sharedProps}
                />
              )
            }

            return (
              <SelectWithQuery
                fieldKey={key}
                query={getDynamicFieldQuery(field)}
                options={
                  query === GenericDynamicFieldQuery.CustomValues
                    ? customValues?.split('\n') || []
                    : undefined
                }
                mode={
                  query === GenericDynamicFieldQuery.CustomValues && !customValues
                    ? 'tags'
                    : multiple
                      ? 'multiple'
                      : 'default'
                }
                style={{ width: '100%' }}
                {...sharedProps}
              />
            )
          }
        }
      case BookingDynamicFieldType.Date:
        sharedProps = {
          ...sharedProps,
          onChange: onChange ? (_: Moment, dateString: string) => {
            onChange(dateString)
          } : onChange,
          value: value ? moment(value, DATE_FORMAT) : undefined
        }

        return (
          <DatePicker
            showTime
            format={DATE_FORMAT}
            placeholder="Select a date and time"
            // @ts-ignore
            getCalendarContainer={(trigger) => trigger.parentNode}
            {...sharedProps}
          />
        )
      default:
        sharedProps = {
          ...sharedProps,
          onChange: onChange ? (e: any) => onChange(e.target.value) : onChange
        }

        return (
          isMultiline ? (
            <Input.TextArea rows={4} autoComplete="off" placeholder={`Enter ${key}`} {...sharedProps} />
          ) : (
            <Input id={renameId} autoComplete="off" placeholder={`Enter ${key}`} {...sharedProps} />
          )
        )
    }
  }

  return renderField(field)
}

export default withApollo(DynamicField)
