
import { Modal, Button } from 'antd'

import JobModalContent from './JobModalContent'

const JobModal = (props) => {
  const { visible, job, onClose, onChange } = props
  return (
    <Modal
      destroyOnClose
      visible={visible}
      width={'97%'}
      title="Job"
      footer={[
        <Button key="submit" type="primary" onClick={onClose}>
          Close
        </Button>
      ]}
      onCancel={onClose}
      onOk={onClose}
    >
      <JobModalContent job={job} onChange={onChange} />
    </Modal>
  )
}

export default JobModal
