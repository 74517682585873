import { ApolloClient, useLazyQuery } from '@apollo/client'
import { Icon, Select } from 'antd'
import debounce from 'lodash/debounce'
import { forwardRef, memo, useCallback, useEffect, useMemo, useState } from 'react'
import { withApollo } from 'react-apollo'

import { BOOKINGS_SEARCH_QUERY } from 'App/graphql/booking'
import { getBl, getRef, getshipperOrConsignee } from 'App/utils/booking'
import { logger } from 'App/utils/logger'
import responseHandler from 'App/utils/responseHandler'

const { Option } = Select

type BookingSelectProps = {
  allowClear?: boolean
  client?: ApolloClient<any>
  filterOption?: any
  filter?: any
  useIcon?: boolean
  mode?: 'default' | 'multiple' | 'tags' | 'combobox' | undefined
  value: any
  onChange: (value: any, option?: any) => void
  queryOnMount?: boolean
  isStoreEntireBooking?: boolean
  isIncludingSearchInput?: boolean
  placeholder?: string
  enablePasteBulk?: boolean
}

const BookingSelect = forwardRef((props: BookingSelectProps, ref: any): JSX.Element => {
  const {
    allowClear = false,
    client,
    filterOption,
    useIcon = true,
    mode,
    filter = {},
    value,
    onChange,
    queryOnMount,
    isStoreEntireBooking = false,
    isIncludingSearchInput = false,
    enablePasteBulk = true,
    placeholder = 'Search a booking here...'
  } = props
  const [searchInput, setSearchInput] = useState('')
  const [bookings, setBookings] = useState([])

  const input = useMemo(
    () => ({
      q: (!mode && value) || searchInput || '',
      limit: 20,
      filter
    }),
    [mode, value, searchInput, filter]
  )
  const [getBookingSearch, { loading, error, data }] = useLazyQuery(BOOKINGS_SEARCH_QUERY, {
    client,
    variables: { input },
    fetchPolicy: 'cache-and-network'
  })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearch = useCallback(
    debounce(async (userInput: string) => {
      if (userInput.includes(' ') && client && enablePasteBulk) {
        const splitBySpaceInput = userInput.split(' ')

        const newValueArray: string[] = []

        for (const no of splitBySpaceInput) {
          const { data } = await client.query({
            query: BOOKINGS_SEARCH_QUERY,
            variables: {
              input: {
                q: no,
                limit: 20,
                filter
              }
            }
          })

          if (data?.bookingsSearchJson?.rows?.length) {
            for (const booking of data.bookingsSearchJson.rows) {
              newValueArray.push(booking.no)
            }
          }
        }

        setSearchInput('')
        onChange([...value, ...newValueArray])
        return
      }

      setSearchInput(userInput)
      getBookingSearch()
    }, 500),
    []
  )

  useEffect(() => {
    if (queryOnMount) {
      getBookingSearch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!loading && data?.bookingsSearchJson?.rows?.length) {
      setBookings(data.bookingsSearchJson.rows)
    } else {
      setBookings([])
    }
  }, [loading, data])

  const filterBookings = useCallback((input: any, option: { props: { booking: any } }) => {
    if (option.props && option.props.booking) {
      return true
    }
  }, [])

  const renderPlaceholder = useCallback(() => {
    return (
      <>
        {useIcon && <Icon type='search' />} {placeholder}
      </>
    )
  }, [placeholder, useIcon])

  if (error) {
    logger.error('BookingSelect getBookingSearch error', error)
    responseHandler(error, 'error')
  }

  return (
    <Select
      allowClear={allowClear}
      placeholder={renderPlaceholder()}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
      filterOption={filterOption !== undefined ? filterOption : filterBookings}
      loading={loading}
      showSearch
      mode={mode}
      value={value}
      onChange={onChange}
      onSearch={handleSearch}
      ref={ref}
    >
      {bookings?.map((booking: { uuid: string }) => {
        const bookingnoOrrefno = getRef(undefined, booking)
        const blOrRef = getBl(booking)
        const shipperOrConsignee = getshipperOrConsignee(booking)

        return (
          <Option
            key={booking.uuid}
            value={
              isStoreEntireBooking
                ? isIncludingSearchInput
                  ? JSON.stringify({ ...booking, searchInput })
                  : JSON.stringify(booking)
                : booking.uuid
            }
            // @ts-ignore
            booking={booking}
          >
            {`${bookingnoOrrefno || 'N/A'} - ${blOrRef || 'N/A'} - ${shipperOrConsignee || 'N/A'}`}
          </Option>
        )
      })}
    </Select>
  )
})

export default withApollo(memo(BookingSelect))
