import { memo, useMemo } from 'react'
import { compose, withState } from 'recompose'
import { useQuery, gql } from '@apollo/client'
import { Dropdown, Icon, Menu } from 'antd'
import { bindActionCreators } from 'redux'
import { withApollo } from 'react-apollo'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import useGlobalCompanyStore from 'App/store/globalCompany'
import * as voucherActions from 'App/states/reducers/voucher'
import { getPrintableLink } from 'App/components/Shared/documentCreator/openPrintableInNewTab'
import VoucherPdfAsyncDownloadAction from 'App/components/Voucher/Management/VoucherPdfAsyncDownloadAction'

import { IncentiveVoucherStatus } from 'App/types/types'

export const DOCUMENT_CREATOR_TEMPLATES = gql`
  query documentCreatorTemplates3($q: String, $statuses: [DocumentCreatorTemplateStatus]) {
    documentCreatorTemplates(q: $q, statuses: $statuses) {
      rows {
        type
      }
    }
  }
`

const ModalTitleMenu = memo((props: any) => {
  const { client, currentVoucher } = props

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const { data: dcTemplates, loading: dcTemplatesLoading, error: dcTemplatesError } = useQuery(DOCUMENT_CREATOR_TEMPLATES, {
    client,
    variables: {
      q: 'INCENTIVE_VOUCHER',
      statuses: ['activated']
    },
    fetchPolicy: 'cache-and-network'
  })

  const status = useMemo(() => currentVoucher?.status, [currentVoucher])

  const fakeVoucher = useMemo(
    () => ({ uuid: currentVoucher?._id }),
    [currentVoucher]
  )

  const menu = useMemo(
    () => (
      <Menu>
        {(status === IncentiveVoucherStatus.Approved ||
          status === IncentiveVoucherStatus.Approvedfinance) &&
          dcTemplates?.documentCreatorTemplates?.rows?.length &&
          dcTemplates?.documentCreatorTemplates?.rows?.map((t: any) => (
            <Menu.Item key={t.type}>
              <VoucherPdfAsyncDownloadAction
                voucher={fakeVoucher}
                type={t.type}
                text={`${t.type} as PDF`}
              />
            </Menu.Item>
          ))}
        {(status === IncentiveVoucherStatus.Approved ||
          status === IncentiveVoucherStatus.Approvedfinance) &&
          dcTemplates?.documentCreatorTemplates?.rows?.length &&
          dcTemplates?.documentCreatorTemplates?.rows?.map((t: any) => (
            <Menu.Item key={t.type}>
              <a
                target="_blank"
                href={getPrintableLink(fakeVoucher.uuid, selectedGlobalCompany.uuid, t.type)}
                rel="noreferrer"
              >
                <Icon type="printer" style={{ marginRight: '8px' }} />
                {`Print as ${t.type}`}
              </a>
            </Menu.Item>
          ))}
        {(status === IncentiveVoucherStatus.Approved ||
          status === IncentiveVoucherStatus.Approvedfinance) &&
          !dcTemplates?.documentCreatorTemplates?.rows?.length && (
            <Menu.Item key="downloadAsyncNoTemplates">No Document Creator Templates</Menu.Item>
          )}
        {dcTemplatesLoading && <Menu.Item key="downloadAsyncLoading">Loading...</Menu.Item>}
        {dcTemplatesError && (
          <Menu.Item key="downloadAsyncError">Error loading templates</Menu.Item>
        )}
        <Menu.Item key="logs">
          <Link
            target="_blank"
            to={`/manage/logs/voucher/?models=incentiveVoucher&incentiveVoucher=${fakeVoucher.uuid}`}
          >
            <Icon type="database" style={{ marginRight: '8px' }} />
            Incentive voucher logs
          </Link>
        </Menu.Item>
      </Menu>
    ),
    [dcTemplates, dcTemplatesError, dcTemplatesLoading, fakeVoucher, status]
  )

  return (
    <>
      <Dropdown overlay={menu} trigger={['click']}>
        <Icon type="ellipsis" style={{ cursor: 'pointer' }} />
      </Dropdown>
    </>
  )
})

export default compose(
  withApollo,
  withState('showSelections', 'toggleSelections', false),
  connect(null, (dispatch) => ({
    dispatch,
    // @ts-ignore
    ...bindActionCreators(
      /* @ts-ignore */
      { ...voucherActions },
      dispatch
    )
  }))
)(ModalTitleMenu)
