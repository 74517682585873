import { Drawer, Icon } from 'antd'
import styled from 'styled-components'
import { compose, withHandlers, withState } from 'recompose'

import MainDrawerMenu from './MainDrawerMenu'

const visibleState = withState('visible', 'setVisible', false)

const handlers = withHandlers({
  handleClick: (props) => () => {
    const { setVisible } = props
    setVisible(true)
  },
  handleClose: (props) => () => {
    const { setVisible } = props
    setVisible(false)
  }
})

const enhance = compose(visibleState, handlers)

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`

const MainDrawerActionButton = (props) => {
  const { visible, handleClick, handleClose } = props

  return (
    <>
      <Icon
        type="menu-unfold"
        style={{ cursor: 'pointer', padding: '16px 16px' }}
        onClick={handleClick}
      />
      <StyledDrawer closable={false} placement="left" onClose={handleClose} visible={visible}>
        <MainDrawerMenu />
      </StyledDrawer>
    </>
  )
}

export default enhance(MainDrawerActionButton)
