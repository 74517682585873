import { memo, useCallback, useEffect, useState } from 'react'
import { Form, Row, Col, InputNumber, Input, DatePicker } from 'antd'
import { withApollo } from 'react-apollo'
import cloneDeep from 'lodash/cloneDeep'
import keyBy from 'lodash/keyBy'
import { isValidContainerNumber } from 'App/components/Transport/Utils/jobHelper'
import Trips from './Trips'
import SizeSelect from './SizeSelect'
import EnumSelector from 'App/components/Shared/EnumSelector'
import DynamicTransportSelect, { Mode } from 'App/components/Select/DynamicSelector'
import DynamicField from 'App/components/Shared/DynamicField'

import useJobTypes from 'App/hooks/useJobTypes'

import {
  JOB_TYPES_QUERY,
  JOB_OPTIONS_QUERY,
  VOLUME_UNIT_QUERY,
  WEIGHT_UNIT_QUERY,
  BILLING_UNITS_QUERY,
  CONTAINER_TYPES_QUERY,
  CONTAINER_GRADES_QUERY,
  TRANSPORT_JOB_TYPES_QUERY
} from 'App/graphql/booking'

import { BookingDynamicFieldType, BookingType, BookingTypeDynamicFieldChronologyLevel, Job, JobType } from 'App/types/types'
import type { FormComponentProps } from 'antd/es/form'
import { TripFormat, JobTypeRequiredFieldControl } from 'App/types/types'
import { startCase, get } from 'lodash'
import { DYNAMIC_FIELD_DATE_FORMAT } from 'App/components/Booking/DynamicField/helper'
import moment from 'moment'
import { useBookingStore } from 'App/store/booking'

const multiDropLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
}

// Use this when you're flexing two form items in a row on a job level.
const formItemDividedLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
}

const formItemLayoutSmall = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  }
}

export enum EditMode {
  TRIPS = 'trips',
  CREATE = 'create',
  DETAILS = 'details'
}

export type JobPreSubmitProps = Job & {
  key: string
  tripFormat: TripFormat
}

interface JobFormProps extends FormComponentProps {
  client: any
  updateJobs: any
  jobIndex: number
  editMode: EditMode
  allJobs: JobPreSubmitProps[]
  currentJob: JobPreSubmitProps
  bookingType: BookingType
  selectedCompany?: string
}

// this component is being called from src/App/pages/NewBookingTransport/NewBookingTransportForm/TransportDetailsSubForm/JobsForm/index.js
const JobForm = memo((props: JobFormProps) => {
  const { form, client, allJobs, jobIndex, currentJob, updateJobs = () => { }, bookingType } = props
  const { getFieldDecorator } = form
  const [fontColor, setFontColor] = useState<string>('black')
  const [jobType, setJobType] = useState<JobType | null>(null)
  const [requiredFields, setRequiredFields] = useState<any>([])
  const [dynamicFields, setDynamicFields] = useState<any>([])
  const setTripDynamicFields = useBookingStore(state => state.setTripDynamicFields)
  const resetTripDynamicFields = useBookingStore(state => state.resetTripDynamicFields)
  const [tripFormat, setTripFormat] = useState<TripFormat>(
    currentJob?.tripFormat
  )
  const { jobTypes, requestJobTypes } = useJobTypes(client)
  const selectedBillToCompany = useBookingStore(state => state.selectedBillToCompany)
  const [tripsRenderCount, setTripsRenderCount] = useState(0)

  useEffect(() => {
    return () => {
      resetTripDynamicFields()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setJobType(jobTypes?.[0])
  }, [jobTypes])

  useEffect(() => {
    if (!jobType) {
      return
    }

    const { trips } = form.getFieldsValue()

    const tripsToPopulate = jobType.tripDetails?.length
      ? jobType.tripDetails.map((t: any, i: number) => trips[i] || {})
      : trips || [{}]

    form.setFieldsValue({
      trips: tripsToPopulate,
      type: jobType.code,
    })

    setRequiredFields(keyBy(jobType.requiredFields, 'name'))
    setDynamicFields(jobType.dynamicFields || [])
    setTripDynamicFields(jobType.tripDynamicFields || [])

    if (!jobType.tripDetails?.length) return

    const currentTrips = form.getFieldValue('trips') || []

    const tripDetailsLength = jobType.tripDetails.length

    if (currentTrips.length > tripDetailsLength) {
      const diff = currentTrips.length - tripDetailsLength
      currentTrips.length -= diff

      form.setFieldsValue({ trips: currentTrips })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobType])

  const handleJobChange = useCallback(() => {
    const currentJobValues = form.getFieldsValue()

    if (
      (currentJobValues.type && !jobTypes) ||
      !jobTypes.find((jt: JobType) => jt.code === currentJobValues.type)
    ) {
      requestJobTypes([currentJobValues.type])
    }

    if (currentJobValues.tripFormat) {
      setTripFormat(currentJobValues.tripFormat)
    }

    allJobs[jobIndex] = { ...currentJob, ...currentJobValues }

    updateJobs(allJobs)

    const timeoutId = setTimeout(() => {
      setTripsRenderCount((prev) => prev + 1)
    }, 200)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [allJobs, form, jobIndex])

  const handelTripsChange = (trips) => {
    const newTrips = cloneDeep(trips)
    currentJob.trips = newTrips
    form.setFieldsValue({ trips: newTrips })
    handleJobChange()
  }

  useEffect(() => {
    handleJobChange()
  }, [handleJobChange, form])

  return (
    <>
      <Row>
        <Col span={12}>
          <Form.Item
            label="Job Type"
            {...formItemDividedLayout}
          >
            {getFieldDecorator('type', {
              initialValue: currentJob?.type,
              rules: [{ required: true, message: 'Job type is required.' }]
            })(
              <DynamicTransportSelect
                queryOnMount
                type={'jobTypes'}
                query={JOB_TYPES_QUERY}
                searchText={'Select job type...'}
                queryVariables={{ byCompany: selectedBillToCompany }}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label="Trip Order Format"
            {...formItemDividedLayout}
          >
            {getFieldDecorator('tripFormat', {
              initialValue: currentJob?.tripFormat || TripFormat.None
            })(
              <EnumSelector
                camelCaseToNormal
                enumName="TripFormat"
                placeholder="Select Trip Format"
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      {
        Object.keys(requiredFields).length > 0 && (
          <Row>
            <Col span={12}>
              {requiredFields?.transportType && (
                <Form.Item label="Transport Type" {...formItemDividedLayout}>
                  {getFieldDecorator('details.transportType', {
                    initialValue: currentJob?.details?.transportType,
                    rules: [
                      {
                        required:
                          requiredFields?.transportType?.control ===
                          JobTypeRequiredFieldControl.Required,
                        message: 'Transport type is required.'
                      }
                    ]
                  })(
                    <DynamicTransportSelect
                      query={TRANSPORT_JOB_TYPES_QUERY}
                      type={'transportJobTypes'}
                      searchText={'Select transport type...'}
                    />
                  )}
                </Form.Item>
              )}

              {requiredFields?.containerType && (
                <Form.Item label="Container Type" {...formItemDividedLayout}>
                  {getFieldDecorator('details.containerType', {
                    initialValue: currentJob?.details?.containerType,
                    rules: [
                      {
                        required:
                          requiredFields?.containerType?.control ===
                          JobTypeRequiredFieldControl.Required,
                        message: 'Container type is required.'
                      }
                    ]
                  })(
                    <DynamicTransportSelect
                      query={CONTAINER_TYPES_QUERY}
                      type={'containerTypes'}
                      searchText={'Select containerType(s)...'}
                    />
                  )}
                </Form.Item>
              )}

              {requiredFields?.containerGrade && (
                <Form.Item label="Container Grade" {...formItemDividedLayout}>
                  {getFieldDecorator('details.containerGrade', {
                    initialValue: currentJob?.details?.containerGrade,
                    rules: [
                      {
                        required:
                          requiredFields?.containerGrade?.control ===
                          JobTypeRequiredFieldControl.Required
                      }
                    ]
                  })(
                    <DynamicTransportSelect
                      query={CONTAINER_GRADES_QUERY}
                      type={'containerGrades'}
                      searchText={'Select containerGrade(s)...'}
                    />
                  )}
                </Form.Item>
              )}

              {requiredFields?.containerNo && (
                <Form.Item label="Container No" {...formItemDividedLayout}>
                  {getFieldDecorator('details.containerNo', {
                    initialValue: currentJob?.details?.containerNo,
                    rules: [
                      {
                        validator: (_, val) => {
                          if (!val) {
                            setFontColor('black')
                          } else if (isValidContainerNumber(val)) {
                            setFontColor('green')
                          } else if (val.length > 11) {
                            setFontColor('red')
                          } else {
                            setFontColor('#DAA520')
                          }
                        }
                      },
                      { required: requiredFields?.containerNo?.control === JobTypeRequiredFieldControl.Required }
                    ]
                  })(
                    <Input style={{ color: fontColor }} placeholder="Enter a container number..." />
                  )}
                </Form.Item>
              )}

              {requiredFields?.no && (
                <Form.Item label="No" {...formItemDividedLayout}>
                  {getFieldDecorator('no', {
                    initialValue: currentJob?.no,
                    rules: [
                      { required: requiredFields?.no?.control === JobTypeRequiredFieldControl.Required }
                    ]
                  })(<Input placeholder="Enter number..." />)}
                </Form.Item>
              )}

              {requiredFields?.seal && (
                <Form.Item label="Seal No" {...formItemDividedLayout}>
                  {getFieldDecorator('details.seal', {
                    initialValue: currentJob?.details?.seal,
                    rules: [
                      {
                        required: requiredFields?.seal?.control === JobTypeRequiredFieldControl.Required
                      }
                    ]
                  })(<Input placeholder="Enter a seal number..." />)}
                </Form.Item>
              )}

              {requiredFields?.options && (
                <Form.Item label="Options" {...formItemDividedLayout}>
                  {getFieldDecorator('details.options', {
                    initialValue:
                      currentJob?.details?.options?.length > 0
                        ? currentJob?.details?.options
                        : undefined,
                    rules: [
                      {
                        required:
                          requiredFields?.options?.control === JobTypeRequiredFieldControl.Required
                      }
                    ]
                  })(
                    <DynamicTransportSelect
                      query={JOB_OPTIONS_QUERY}
                      mode={Mode.MULTIPLE}
                      type={'jobOptions'}
                      searchText={'Select option(s)...'}
                    />
                  )}
                </Form.Item>
              )}
            </Col>

            <Col span={12}>
              <Row>
                {requiredFields?.refUnitAmount && (
                  <Form.Item label="Ref Unit Amount" {...formItemDividedLayout}>
                    {getFieldDecorator('details.refUnitAmount', {
                      initialValue: currentJob?.details?.refUnitAmount,
                      rules: [
                        {
                          required:
                            requiredFields?.refUnitAmount?.control ===
                            JobTypeRequiredFieldControl.Required
                        }
                      ]
                    })(<InputNumber placeholder="Unit" />)}
                  </Form.Item>
                )}

                {requiredFields?.refUnit && (
                  <Form.Item label="Ref Unit" {...formItemDividedLayout}>
                    {getFieldDecorator('details.refUnit', {
                      initialValue: currentJob?.details?.refUnit,
                      rules: [
                        {
                          required:
                            requiredFields?.refUnit?.control === JobTypeRequiredFieldControl.Required
                        }
                      ]
                    })(
                      <DynamicTransportSelect
                        query={BILLING_UNITS_QUERY}
                        type={'billingUnits'}
                        searchText={'Select billing unit...'}
                      />
                    )}
                  </Form.Item>
                )}
              </Row>

              <Row>
                {requiredFields?.size && (
                  <Form.Item label="Size (ft)" {...formItemDividedLayout}>
                    {getFieldDecorator('details.size', {
                      initialValue: currentJob?.details?.size,
                      rules: [
                        {
                          required:
                            requiredFields?.size?.control === JobTypeRequiredFieldControl.Required
                        }
                      ]
                    })(<SizeSelect />)}
                  </Form.Item>
                )}
              </Row>

              <Row>
                <Col span={12}>
                  {requiredFields?.weight && (
                    <Form.Item label="Weight" {...formItemLayoutSmall}>
                      {getFieldDecorator('details.weight', {
                        initialValue: currentJob?.details?.weight,
                        rules: [
                          {
                            required:
                              requiredFields?.weight?.control === JobTypeRequiredFieldControl.Required
                          }
                        ]
                      })(<InputNumber placeholder="Weight" />)}
                    </Form.Item>
                  )}
                </Col>
                <Col span={12}>
                  {requiredFields?.weight && (
                    <Form.Item {...formItemLayoutSmall}>
                      {getFieldDecorator('details.weightUnit', {
                        initialValue: currentJob?.details?.weightUnit,
                        rules: [
                          {
                            required:
                              requiredFields?.weight?.control === JobTypeRequiredFieldControl.Required
                          }
                        ]
                      })(
                        <DynamicTransportSelect
                          query={WEIGHT_UNIT_QUERY}
                          type={'weightUnits'}
                          searchText={'Select weight unit...'}
                        />
                      )}
                    </Form.Item>
                  )}
                </Col>
              </Row>

              <Row>
                <Col span={14}>
                  {requiredFields?.volume && (
                    <Form.Item label="Volume" {...formItemLayoutSmall}>
                      {getFieldDecorator('details.volume', {
                        initialValue: currentJob?.details?.volume,
                        rules: [
                          {
                            required:
                              requiredFields?.volume?.control === JobTypeRequiredFieldControl.Required
                          }
                        ]
                      })(<InputNumber placeholder="Volume" />)}
                    </Form.Item>
                  )}
                </Col>
                <Col span={10}>
                  {requiredFields?.volume && (
                    <Form.Item>
                      {getFieldDecorator('details.volumeUnit', {
                        initialValue: currentJob?.details?.volumeUnit,
                        rules: [
                          {
                            required:
                              requiredFields?.volume?.control === JobTypeRequiredFieldControl.Required
                          }
                        ]
                      })(
                        <DynamicTransportSelect
                          query={VOLUME_UNIT_QUERY}
                          type={'volumeUnits'}
                          searchText={'Select volume unit...'}
                          style={{ width: '100px', margin: '0 8px' }}
                        />
                      )}
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        )
      }

      {
        bookingType?.dynamicFields?.length
          ? bookingType?.dynamicFields.filter((bt) => bt?.type === BookingDynamicFieldType.Date && bt.chronologyLevel === BookingTypeDynamicFieldChronologyLevel.Job).map(
            (dynamicField, i) => {
              const fKey = `dates.${dynamicField?.key}`
              return (
                <Form.Item label={startCase(dynamicField?.key || '')} {...multiDropLayout} key={`${i}-${dynamicField?.key}`}>
                  <Row>
                    <Col span={24}>
                      { /*@ts-ignore*/}
                      {getFieldDecorator(fKey, { initialValue: get(currentJob, fKey) || moment().set({ hour: 14, minute: 0, second: 0, millisecond: 0 }) })(
                        <DatePicker
                          showTime
                          format={DYNAMIC_FIELD_DATE_FORMAT}
                          placeholder="Select a date and time"
                          // @ts-ignore
                          getCalendarContainer={(trigger) => trigger.parentNode}
                        />
                      )}
                    </Col>
                  </Row>
                </Form.Item>
              )
            }
          )
          : null
      }

      {
        dynamicFields.length > 0 && (
          <Row>
            <Col>
              {dynamicFields.map((field: any) => {
                return (
                  <Form.Item key={field.key} label={startCase(field.key)} {...multiDropLayout}>
                    {getFieldDecorator(`details.${field.key}`, {
                      initialValue: currentJob?.details?.[field.key],
                      rules: [
                        {
                          required: field.control === JobTypeRequiredFieldControl.Required,
                          message: `${field.key} is required`
                        }
                      ]
                    })(
                      <DynamicField id={field.key} field={field} />
                    )}
                  </Form.Item>
                )
              })}
            </Col>
          </Row>
        )
      }

      <Row>
        <Col>
          <Form.Item label="Remarks" {...multiDropLayout}>
            {getFieldDecorator('remarks', { initialValue: currentJob?.remarks })(
              <Input autoComplete="off" placeholder="Enter job remarks..." />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Form.Item label=" " colon={false} {...multiDropLayout}>
          {getFieldDecorator('trips', { initialValue: currentJob?.trips || [{}] })(
            <Trips
              jobType={jobType || {}}
              bookingType={bookingType}
              onChange={handelTripsChange}
              requiredFields={requiredFields}
              format={tripFormat || currentJob?.tripFormat}
              tripsRenderCount={tripsRenderCount}
            />
          )}
        </Form.Item>
      </Row>
    </>
  )
})

// @ts-ignore
export default withApollo(Form.create<FormComponentProps>()(JobForm))
