import { graphql } from 'react-apollo'

import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from 'App/containers/booking/schema/bookingForWorkflowsSlim'
import CREATE_DOCUMENT_CREATOR from '../schema/createDocumentCreator'

export default graphql(CREATE_DOCUMENT_CREATOR, {
  props: ({ mutate, ownProps }) => ({
    createDocumentCreator: (params) =>
      mutate({
        variables: {
          input: params
        },
        refetchQueries: [
          {
            query: BOOKING_FOR_WORKFLOWS_SLIM_QUERY,
            variables: {
              uuid:
                (ownProps.booking && ownProps.booking.uuid) ||
                (ownProps.documentCreator && ownProps.documentCreator.bookingUuid)
            }
          }
        ]
      })
  })
})
