

import { Button, Modal } from 'antd'

import { Actions, ActionItem } from 'App/components/Manage/Styled'

const confirm = Modal.confirm

const FormActions = (props) => {
  const { mode, googleMapsURL, resetAddressForm, loading, submitText, onCustomSubmit, onDelete } =
    props

  const handleDelete = () => {
    confirm({
      title: 'Confirm to delete this address?',
      content: 'All changes or links to this address will be gone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        try {
          onDelete()
        } catch (error) {
          console.log(error)
        }
      },
      onCancel() {
        console.log('Cancel')
      }
    })
  }

  const customProps = {}

  if (onCustomSubmit) {
    customProps.onClick = onCustomSubmit
  } else {
    customProps.htmlType = 'submit'
  }

  if (mode === 'edit') {
    return (
      <Actions>
        <ActionItem span={24} align="right">
          <Button type="danger" onClick={handleDelete}>
            Delete
          </Button>
          <Button onClick={() => resetAddressForm()}>Clear</Button>
          <a href={googleMapsURL} target="_blank" rel="noreferrer noopener">
            <Button>Open Map</Button>
          </a>
          <Button type="primary" {...customProps} loading={loading}>
            {submitText}
          </Button>
        </ActionItem>
      </Actions>
    )
  }

  return (
    <Actions>
      <ActionItem span={24} align="right">
        <Button onClick={() => resetAddressForm()}>Clear</Button>
        <a href={googleMapsURL} target="_blank" rel="noreferrer noopener">
          <Button>Open Map</Button>
        </a>
        <Button type="primary" {...customProps} loading={loading}>
          {loading ? 'Submitting...' : submitText}
        </Button>
      </ActionItem>
    </Actions>
  )
}

export default FormActions
