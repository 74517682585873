import { gql } from '@apollo/client'

export const documentCreatorTemplatesGql = gql`
  query documentCreatorTemplates1(
    $q: String
    $limit: Int
    $offset: Int
    $statuses: [DocumentCreatorTemplateStatus]
    $byCompany: UUID
  ) {
    documentCreatorTemplates(q: $q, limit: $limit, offset: $offset, statuses: $statuses, byCompany: $byCompany) {
      rows {
        uuid
        type
        status
        template
        companies {
          uuid
          name
        }
      }

      pageInfo {
        count
        limit
        offset
      }
    }
  }
`
