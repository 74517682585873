import { useCallback, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { withApollo } from 'react-apollo'
import { Base64 } from 'js-base64'
import { Col, Row, Tooltip } from 'antd'
import { gql } from '@apollo/client'

import responseHandler from 'App/utils/responseHandler'
import FAIcon from 'App/components/Icons/FA'
import { logger } from 'App/utils/logger'

export const PROCESS_FLOW_MAP_QUERY = gql`
  query getProcessFlowMap($type: String!, $process: String!) {
    processFlowMap(type: $type, process: $process)
  }
`
const MermaidLink = (props) => {
  const [click, setClick] = useState(false)

  const [getProcessFlowMap, { loading, data, error }] = useLazyQuery(PROCESS_FLOW_MAP_QUERY, {
    client: props.client,
    variables: {
      type: props.type,
      process: props.process
    },
    fetchPolicy: 'cache-and-network'
  })

  const loadProcessFlowMap = useCallback(() => setClick(true), [])

  useEffect(() => {
    click &&
      getProcessFlowMap({
        variables: {
          type: props.type,
          process: props.process
        }
      })
  }, [click])

  useEffect(() => {
    if (data?.processFlowMap && !loading) {
      const defaultState = {
        code: data.processFlowMap,
        mermaid: { theme: 'default' }
      }
      const base64Str = Base64.encodeURI(JSON.stringify(defaultState))
      const url = `https://mermaidjs.github.io/mermaid-live-editor/#/view/${base64Str}`
      window.open(url, '_blank')
    }
    setClick(false)
  }, [data, loading])

  if (loading) {
    return null
  }

  if (error) {
    responseHandler(error, 'error')
    logger.error(error, 'error')
  }

  return (
    <Tooltip title="View process flow map">
      <Row onClick={loadProcessFlowMap} style={{ cursor: 'pointer' }}>
        <FAIcon
          fullType
          style={{ color: 'gray' }}
          type="fas fa-project-diagram"
          onClick={loadProcessFlowMap}
        />
      </Row>
    </Tooltip>
  )
}

export default withApollo(MermaidLink)
