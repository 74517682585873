import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Checkbox } from 'antd'

import bookingContainer from 'App/containers/booking/query/fromRequisitionTemplate'
import { TemplateSection } from '../Requisition/Template/Container'
import { FieldWrapper, FieldText, FieldValue } from '../Requisition/Template/Fields'
import Attachments from '../Requisition/Template/Attachments'

const CheckboxGroup = Checkbox.Group

class ROTFormImportTemplate extends Component {
  render() {
    const {
      form: { getFieldDecorator },
      bookingQuery,
      requisitionTemplate,
      booking
    } = this.props
    const { fields } = requisitionTemplate
    const documents = fields.documents || []
    const jobs = bookingQuery.booking.jobs.map((job, index) => {
      return {
        label: job.no || index + 1,
        value: job.uuid
      }
    })

    const selectableDocuments = booking.bookingDocuments.filter((d) => {
      const documentTypes = ['K1Draft', 'impCustCustomsDocumentDraft', 'ImpGatepassDoc']

      return d.status === 'VERIFIED' && documentTypes.includes(d.type)
    })

    return (
      <React.Fragment>
        <TemplateSection>
          <FieldWrapper>
            <FieldText>Jobs</FieldText>
            {getFieldDecorator('jobs', {
              initialValue: []
            })(<CheckboxGroup options={jobs} />)}
          </FieldWrapper>
          <FieldWrapper>
            <FieldText>Import Container No</FieldText>
            <FieldValue>PCIU8899002</FieldValue>
          </FieldWrapper>
          <FieldWrapper>
            <FieldText>ROT Date/Time</FieldText>
            <FieldValue>09/04/2018 11:14</FieldValue>
          </FieldWrapper>
          <FieldWrapper>
            <FieldText>Last Port Storage Date</FieldText>
            <FieldValue>08/04/2018 23:59</FieldValue>
          </FieldWrapper>
        </TemplateSection>
        <TemplateSection>
          {getFieldDecorator('attachments', {
            initialValue: documents.map((a) => a.uuid)
          })(<Attachments attachments={documents} selectableDocuments={selectableDocuments} />)}
        </TemplateSection>
      </React.Fragment>
    )
  }
}

ROTFormImportTemplate.propTypes = {
  bookingQuery: PropTypes.object,

  form: PropTypes.object,
  requisitionTemplate: PropTypes.object
}

export default bookingContainer(ROTFormImportTemplate)
