export const SHIFTS_JSON_EXPORT1 = `
  query shiftsJson1 ($input: ShiftQueryInput) {
    shiftsJson (input: $input)
  }
`

export const ShiftsWithIncentivesGql = `
  query shiftsWithIncentives($input: ShiftQueryInput!) {
    shifts(input: $input) {
      rows {
        amount
        approvedAt
        approvedBy {
          email
        }
        approvedFinanceAt
        approvedFinanceBy {
          email
        }
        breakDuration
        createdAt
        driver {
          name
          code
          uuid
        }
        duration
        end
        jobUuids
        incentives {
          _id
          incentiveVoucher {
            _id
            voucherNo
            date
            status
            approvedAt
            approvedBy {
              email
            }
            approvedFinanceAt
            approvedFinanceBy {
              email
            }
            remarks
          }
          shiftUuid
          ownerUuid
          driverUuid
          driver {
            code
            name
            nickName
          }
          jobUuid
          status
          date
          remarks
          amount
          createdAt
          updatedAt
          type {
            category
            code
            name
          }
          transportJob {
            _id
            uuid
            bookingNo
            no
            jobNo
            consigneeName
            shipperName
            references
          }
        }
        incentiveCategorySum {
          category
          amount
        }
        incentiveTypeSum {
          typeUuid
          amount
        }
        no
        remarks
        start
        status
        updatedAt
        updatedBy {
          email
        }
        vehicle {
          code
          registration
          uuid
        }
        _id
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`
