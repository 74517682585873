import { gql } from '@apollo/client'

export const PLAN_VEHICLE_JOBS_QUERY = gql`
  query planVehicleTransportJobs($input: TransportJobQuery!) {
    transportJobs(input: $input) {
      rows {
        uuid
        ownerUuid
        bookingUuid
        jobUuid
        tripUuid
        legUuid

        from
        to

        jobType
        tripType
        tripSequence
        tripStatus
        legStatus
        sequence

        shipperCode
        shipperName
        consigneeCode
        consigneeName

        bookingNo
        no
        jobNo
        shipperRequiredDate

        planStart
        start
        startOut
        planEnd
        end
        endOut

        driverCode
        driverName
        driverUuid
        vehicleCode
        vehicleName
        vehicleUuid
        transportUuid
        transportName
        transportSource
        remarks
      }

      pageInfo {
        limit
        offset
        count
      }
    }
  }
`
