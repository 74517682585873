import React, { Component } from 'react'
import { compose, withState } from 'recompose'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Mutation } from 'react-apollo'
import { Icon, Popover, Modal } from 'antd'

import * as bookingDocumentActions from 'App/states/reducers/bookingDocument'
import EmptyAnchor from '../../EmptyAnchor'
import { StyledMenu, StyledMenuItem, StyledOptions } from './Styled'
import schema from 'App/containers/booking/schema'
import BookingDocumentQuery from 'App/components/Workflow/Uploaded/UploadedItems/BookingDocumentQuery'
import { DELETE_BOOKING_DOCUMENT } from '../Content/schema'
import { getBkQueryVariables } from 'App/components/Booking/bookingHelper'

const confirm = Modal.confirm

class HeaderOptions extends Component {
  handleDelete = (deleteDocument) => {
    const { bookingDocument, setVisible } = this.props

    setVisible(false)

    confirm({
      title: 'Confirm to delete this document?',
      content: 'All changes or links to this document will be gone.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      onOk() {
        deleteDocument({
          variables: {
            uuid: bookingDocument.uuid
          }
        })
      },
      onCancel() {
        console.log('Cancel')
      }
    })
  }

  handleDeleted = (result) => {
    const { deleteBookingDocument } = result

    const { bookingDocument, updateSelectedBookingDocument } = this.props

    updateSelectedBookingDocument({
      ...deleteBookingDocument,
      allowedApprovals: bookingDocument.allowedApprovals
    })
  }

  renderOptions = () => {
    const { bookingDocument } = this.props

    return (
      <Mutation
        mutation={DELETE_BOOKING_DOCUMENT}
        onCompleted={this.handleDeleted}
        refetchQueries={[
          {
            query: schema.BOOKING_QUERY,
            variables: getBkQueryVariables(this.props.match.params)
          },
          {
            query: BookingDocumentQuery,
            variables: {
              uuid: bookingDocument.uuid
            }
          }
        ]}
      >
        {(deleteDocument, { loading, error }) => {
          return (
            <StyledMenu>
              <StyledMenuItem target="_blank" href={`/booking/document/${bookingDocument.uuid}`}>
                View in new tab
              </StyledMenuItem>
              <StyledMenuItem
                target="_blank"
                href={`/booking/document/${bookingDocument.uuid}?tag=1`}
              >
                View Tagged in new tab
              </StyledMenuItem>
              {bookingDocument.status === 'DELETED' ? null : (
                <StyledMenuItem onClick={() => this.handleDelete(deleteDocument)}>
                  Delete document
                </StyledMenuItem>
              )}
            </StyledMenu>
          )
        }}
      </Mutation>
    )
  }

  render() {
    const { visible, setVisible } = this.props

    const handlePopverVisibility = () => {
      setVisible((state) => !state)
    }

    return (
      <StyledOptions>
        <Popover
          trigger="click"
          placement="bottomRight"
          content={this.renderOptions()}
          getPopupContainer={(trigger) => trigger.parentNode}
          visible={visible}
        >
          <EmptyAnchor>
            <Icon type="ellipsis" onClick={handlePopverVisibility} style={{ cursor: 'pointer' }} />
          </EmptyAnchor>
        </Popover>
      </StyledOptions>
    )
  }
}

export default compose(
  withState('visible', 'setVisible', false),
  withRouter,
  connect(
    (state, props) => ({}),
    (dispatch) => ({
      dispatch,
      ...bindActionCreators(
        {
          ...bookingDocumentActions
        },
        dispatch
      )
    })
  )
)(HeaderOptions)
