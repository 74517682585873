import styled, { css } from 'styled-components'

const VisibilityDiv = styled.div`
  ${(props) =>
    props.visible === false &&
    css`
      display: none;
    `};
`

export default VisibilityDiv
