import uuidv4 from 'uuid/v4'
import auth0 from 'auth0-js'
import { create } from 'zustand'
import jwtDecode from 'jwt-decode'
import { devtools, persist } from 'zustand/middleware'

import config from 'App/config'
import { BASE_URL } from 'App/utils/website'
import webStorage from 'App/utils/webStorage'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import createSelectors from 'App/store/createSelectors'

import type { User } from '@auth0/auth0-react'

type LoginProps = {
  email: string
  password: string
}

export type Auth0TokenProps = {
  access_token: string
  expires_in: number
  id_token: string
  scope?: string
  refresh_token?: string
}

interface AuthState {
  isLegacyMode: boolean
  setLegacyMode: (isLegacyMode: boolean) => void

  user: User | undefined
  setUser: (token: Auth0TokenProps) => any

  setLogout: () => void

  isLoading?: boolean
  setLegacyLogin: (data: LoginProps) => void

  profile: User | undefined
  setProfile: (prfoile: User) => void
}

const useAuthStore = createSelectors(
  create<AuthState>()(
    devtools(
      persist(
        (set) => ({
          isLegacyMode: true,
          setLegacyMode: (isLegacyMode) => set({ isLegacyMode }),

          isLoading: false,
          setLegacyLogin: ({ email, password }) => {
            set({ isLoading: false })

            const secret = uuidv4()

            secret && webStorage.setItem(LOCAL_STORAGE_KEYS.SECRET, secret)

            const auth0Client = new auth0.WebAuth({
              domai: config.auth0.domain,
              clientID: config.auth0.clientId,
              scope: 'openid profile email offline_access',
              responseType: 'token id_token',
              redirectUri: `${BASE_URL}/auth/check-in`
            })

            const loginRequest = {
              realm: 'Username-Password-Authentication',
              email,
              password,
              state: secret
            }

            return new Promise((resolve, reject) => {
              auth0Client.login(loginRequest, (error: any, result: unknown) => {
                if (error) return reject(error)

                resolve(result)
              })
            })
          },
          setLegacyUser: (token, history) => {
            if (!token) return

            if (token.id_token) {
              webStorage.setItem(LOCAL_STORAGE_KEYS.JWT, token.id_token)

              const profile = jwtDecode(token.id_token)
              if (profile) {
                webStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE, profile)
                set({ profile })
              }
            }

            if (token.refresh_token) {
              webStorage.setItem(
                LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
                token.refreshToken
              )
              webStorage.setItem(
                LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXPIRY,
                new Date(
                  Date.now() + config.auth0.logOutAfterDays * 24 * 60 * 60 * 1000
                )
              )
            }

            /* legacy stuff */
            const auth0Client = new auth0.WebAuth({
              domai: config.auth0.domain,
              clientID: config.auth0.clientId,
              scope: 'openid profile email offline_access',
              responseType: 'token id_token',
              redirectUri: `${BASE_URL}/auth/check-in`
            })

            auth0Client.client.userinfo(token.access_token, (error) => {
              if (error) return
              // TODO: redirect base on previous url, retreive from url
              history.push('/')
            })
          },

          profile: undefined,
          setProfile: (profile) => set({ profile }),

          user: undefined,
          setUser: (token) => {
            if (!token) return

            if (token.id_token) {
              webStorage.setItem(LOCAL_STORAGE_KEYS.JWT, token.id_token)

              const profile = jwtDecode(token.id_token)
              if (profile) {
                webStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE, profile)
                set({ profile })
              }
            }

            if (token?.refresh_token) {
              webStorage.setItem(
                LOCAL_STORAGE_KEYS.REFRESH_TOKEN,
                token.refresh_token
              )
              webStorage.setItem(
                LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXPIRY,
                new Date(
                  Date.now() + config.auth0.logOutAfterDays * 24 * 60 * 60 * 1000
                )
              )
            }

            return true
          },

          setLogout: () => {
            localStorage.clear()
            webStorage.setItem(LOCAL_STORAGE_KEYS.LOGOUT, Date.now())
          }
        }),
        { name: 'authStore' }
      ), { name: 'Auth Store' }
    )
  )
)


export default useAuthStore