import { gql } from '@apollo/client'

import quotationItemDetails from './quotationItem'

export default gql`
  fragment QuotationDetails on Quotation {
    uuid
    quotationNo
    term
    description
    date
    expiry
    status
    buyers {
      uuid
      name
    }
    buyerAddress {
      uuid
      name
    }
    buyerContact {
      uuid
      name
    }
    sellers {
      uuid
      name
    }
    sellerAddress {
      uuid
      name
    }
    sellerContact {
      uuid
      name
    }
    editBy {
      email
      name
      nickname
    }
    updatedAt
    createdAt
    tags
    quotationItems {
      ...QuotationItemDetails
    }
  }

  ${quotationItemDetails}
`
