import styled from 'styled-components'

export default styled.div`
  font-size: 0.95rem;
  font-weight: 600;
  color: rgb(40, 45, 50);
  padding: 6px 0px;
  margin-bottom: 10px;
  border-bottom: ${(props) => (props.removeBorder ? 'none' : '1px solid rgb(230, 232, 234)')};
`
