
import { Input, Form } from 'antd'
import styled from 'styled-components'
import flatten from 'flat'
import sortBy from 'lodash/sortBy'

const StyledDiv = styled.div`
  .ant-form-item {
    margin-bottom: 8px;

    .ant-form-item-label {
      line-height: 30px;
    }
  }
`

const DataForm = (props) => {
  const { documentCreator, form } = props
  const { getFieldDecorator } = form

  const flatData = flatten(documentCreator.dataJSON)
  const dataEntries = sortBy(Object.entries(flatData), [(e) => e[0]])

  return (
    <StyledDiv>
      <Form>
        {dataEntries.map((e) => {
          const disabled = documentCreator.status !== 'DRAFT' || e[0].startsWith('settings.')

          return (
            <Form.Item key={e[0]} label={e[0]}>
              {getFieldDecorator(`dataJSON.${e[0]}`, {
                initialValue: e[1]
              })(<Input.TextArea rows={2} disabled={disabled} />)}
            </Form.Item>
          )
        })}
      </Form>
    </StyledDiv>
  )
}

export default DataForm
