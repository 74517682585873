import moment from 'moment'
import { Icon, Tooltip } from 'antd'
import { memo, useMemo } from 'react'
import { withApollo } from 'react-apollo'

import { chronologyTypes } from 'App/utils/labelMap'
import ChronologyAddButton from './ChronologyAddButton'
import { Wrapper, ChronoName, ChronoDate } from './Styled'
import ChronologyUpdateButton from './ChronologyUpdateButton'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'

const dateFormat = 'DD/MM/YYYY HH:mm'

const getLastUpdated = (data) => {
  if (!data) {
    return null
  }

  const user = data.user || {}
  const name = user.nickname || user.email || '(unknown)'
  const updatedAt = moment(data.updatedAt).format('LLL')

  return (
    <span>
      Last updated by {name} on {updatedAt}
    </span>
  )
}

const Chronology = memo((props) => {
  const { chrono, booking, client } = props
  const formattedDate = useMemo(
    () => (chrono.data?.date ? moment(chrono.data.date).format(dateFormat) : 'Not set.'),
    [chrono]
  )
  const title = useMemo(() => chronologyTypes[chrono.title] || chrono.title, [chrono])

  const { hasPermission: canCreate } = usePermissionIsAllowed({
    client,
    resource: 'chronology',
    permission: 'create'
  })
  const { hasPermission: canUpdate } = usePermissionIsAllowed({
    client,
    resource: 'chronology',
    permission: 'update'
  })

  return (
    <Wrapper>
      <Tooltip title={chrono.title}>
        <ChronoName>{title}</ChronoName>
      </Tooltip>
      <ChronoDate>
        <Tooltip title={getLastUpdated(chrono.data)} placement="bottom">
          {formattedDate}
        </Tooltip>
        {chrono.data && chrono.data.remarks && (
          <Tooltip title={chrono.data.remarks} placement="bottom">
            <Icon type="notification" style={{ paddingLeft: '5px' }} />
          </Tooltip>
        )}
        {chrono.data && chrono.data.uuid && canUpdate && (
          <ChronologyUpdateButton booking={booking} bookingUuid={booking.uuid} chrono={chrono} />
        )}
        {canCreate && (
          <ChronologyAddButton booking={booking} bookingUuid={booking.uuid} chrono={chrono} />
        )}
      </ChronoDate>
    </Wrapper>
  )
})

export default withApollo(Chronology)
