
import { Icon } from 'antd'
import { compose, withState, withHandlers } from 'recompose'
import { StyledButton } from '../../../../../ButtonWrapper'

import DocumentCreatorModal from './DocumentCreatorModal'

const visibleState = withState('visible', 'setVisible', false)

const handlers = withHandlers({
  handleClick: (props) => () => {
    const { setVisible } = props
    setVisible(true)
  },
  handleCancel: (props) => () => {
    const { setVisible } = props
    setVisible(false)
  },
  handleAfterClose: (props) => () => {
    const { setVisible, onEdited = () => { } } = props
    setVisible(false)
    onEdited()
  }
})

const enhance = compose(visibleState, handlers)

const EditAction = (props) => {
  const {
    visible,
    documentCreatorUuid,
    handleClick = () => { },
    handleAfterClose = () => { }
  } = props

  return (
    <>
      <StyledButton onClick={handleClick}>
        <Icon type="edit" />
      </StyledButton>
      {visible && (
        <DocumentCreatorModal
          documentCreatorUuid={documentCreatorUuid}
          afterClose={handleAfterClose}
        />
      )}
    </>
  )
}

export default enhance(EditAction)
