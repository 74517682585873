import React, { Component } from 'react'

import withVoucherPayments from './Container'
import CustomText from '../CustomText'

import PaymentsTableView from './PaymentsTableView'

class VoucherPayments extends Component {
  render() {
    const { createPayment, selectedVoucher } = this.props

    const voucherPayments = selectedVoucher?.voucherPayments || []

    const dataSource = voucherPayments.map((item) => ({
      key: item.uuid,
      details: item
    }))

    const columns = []

    columns.push(
      {
        title: 'Reference No.',
        dataIndex: 'reference',
        key: 'reference',
        render: (text, record) => <CustomText text={record.details.payment?.reference} />
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => <CustomText text={record.details.payment?.status} />
      },
      {
        title: 'Transaction Type',
        dataIndex: 'transactionType',
        key: 'transactionType',
        render: (text, record) => <CustomText text={record.details.payment?.transactionType} />
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
        render: (text, record) => <CustomText text={record.details.amount} isNumeric />
      }
    )

    return (
      <PaymentsTableView
        columns={columns}
        dataSource={dataSource}
        createPayment={createPayment}
        selectedVoucher={selectedVoucher}
        voucherPayments={voucherPayments}
      />
    )
  }
}

export default withVoucherPayments(VoucherPayments)
