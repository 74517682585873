import styled from 'styled-components'

export const TemplateWrapper = styled.div`
  border: 1px solid rgb(240, 240, 240);
  padding: 20px;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
`

export const TemplateSection = styled.div`
  margin-bottom: 20px;
`

export const SectionTitle = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
  color: black;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(220, 220, 220);
`

export const Receivers = styled.div`
  margin-bottom: 20px;

  .ant-select-selection {
    border: none;
  }
`
