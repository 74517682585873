import React, { Component } from 'react'
import moment from 'moment'
// import { FormattedDate, FormattedTime } from 'react-intl'
import PropTypes from 'prop-types'

import { Row, Col, Tooltip } from 'antd'

import { requestTypes } from 'App/utils/labelMap'
import Container, { Status, RequestType, CreatedDate, RequestDesc } from './Container'
import RequestItemResultModal from '../RequestItemResultModal'

class RequestItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false
    }
  }

  handleClick = () => {
    this.setState({
      isModalVisible: true
    })
  }

  handleClose = () => {
    this.setState({
      isModalVisible: false
    })
  }

  render() {
    const { request } = this.props
    const requestType = requestTypes[request.type]

    return (
      <>
        <Container onClick={this.handleClick}>
          <RequestType>{(requestType && requestType.text) || request.type}</RequestType>
          <RequestDesc>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={14}>
                <Status>{request.status}</Status>
              </Col>
              <Col span={10}>
                <CreatedDate>
                  <Tooltip
                    title={
                      <>
                        {/* <FormattedDate value={request.createdAt} />,
                        <FormattedTime value={request.createdAt} /> */}
                        <span>{request.createdAt}</span>,<span>{request.createdAt}</span>
                      </>
                    }
                  >
                    <span>{moment(request.createdAt).fromNow()}</span>
                  </Tooltip>
                </CreatedDate>
              </Col>
            </Row>
          </RequestDesc>
        </Container>
        <RequestItemResultModal
          request={request}
          visible={this.state.isModalVisible}
          onClose={this.handleClose}
        />
      </>
    )
  }
}

RequestItem.propTypes = {
  request: PropTypes.object
}

export default RequestItem
