import { Row, Col } from 'antd'

import Link from 'App/components/Link'
import Icon from 'App/components/Icon'
import MainDrawerActionButton from 'App/components/Layouts/Drawers/MainDrawer/MainDrawerActionButton'
import EllipsisDropDown from './EllipsisDropDown'
import SearchActionButton from './SearchActionButton'

const BookingsTopBar = () => {
  return (
    <Row type="flex" justify="space-between">
      <Col>
        <Row type="flex" gutter={8}>
          <Col>
            <MainDrawerActionButton />
          </Col>
          <Col>Bookings</Col>
        </Row>
      </Col>
      <Col>
        <Row type="flex">
          <Col>
            <Link to="/bookings/new">
              <Icon type="plus" />
            </Link>
          </Col>
          <Col>
            <SearchActionButton />
          </Col>
          <Col>
            <EllipsisDropDown />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default BookingsTopBar
