import Responsive from 'react-responsive'

import { IS_SERVER } from 'App/utils/website'

export const Desktop = (props) => {
  if (IS_SERVER) {
    return props.children
  }

  return <Responsive {...props} minWidth={301} />
}

export const MobileTablet = (props) => {
  if (IS_SERVER) {
    return null
  }

  return <Responsive {...props} maxWidth={300} />
}
