import numeral from 'numeral'
import { Component } from 'react'
import filter from 'lodash/filter'
import { Icon, Badge } from 'antd'
import includes from 'lodash/includes'
import { sortCostItems } from '@shipx/formula2'

import CustomText from '../CustomText'
import withCostItems from './Container'
import UnitSizeContainer from '../UnitSizeContainer'
import EditCostItemAction from './EditCostItemAction'
import CostItemsTableView from './CostItemsTableView'
import VoucherCostItemUsage from './VoucherCostItemUsage'
import { MultiRow, StyledField, DeletedItem, ActionWrapper } from '../Styled'

const oprColNames = [
  'description',
  'unitSize',
  'quantity',
  'sell',
  'cost',
  'sellTotal',
  'costTotal',
  'estimatedProfit'
]
const finColNames = [
  'description',
  'unitSize',
  'quantity',
  'sellTotal',
  'costTotal',
  'ar',
  'ap',
  'accrual',
  'shortBill',
  'estimatedProfit',
  'grossProfit'
]

class VoucherCostItems extends Component {
  handleSelectAll = (dataSource) => () => {
    const { handleSelectAll = () => { } } = this.props
    handleSelectAll(true, null, dataSource)
  }

  handleSelectSingleCostItem = (costItem) => async () => {
    const { voucherBooking, onSelectSingleCostItem = () => { } } = this.props

    await onSelectSingleCostItem(voucherBooking, costItem)
  }

  handleCostItemEdited = (costItem) => (newCostItemValues) => {
    const bookingUuid = costItem.bookingUuid

    if (bookingUuid) {
      const { costsheetBookings, updateCostsheetBookings, onCostItemEdited = () => { } } = this.props

      const updatedBookings =
        costsheetBookings &&
        costsheetBookings.map((booking) => {
          const updatedBooking = { ...booking }

          updatedBooking.costItems =
            booking.costItems &&
            booking.costItems.map((ci) => {
              if (ci.uuid === newCostItemValues.uuid) {
                return {
                  ...ci,
                  ...newCostItemValues
                }
              } else {
                return { ...ci }
              }
            })

          return updatedBooking
        })

      updateCostsheetBookings(updatedBookings)

      onCostItemEdited(bookingUuid)
    }
  }

  handleCostItemDeleted = (costItem) => (deletedCostItem) => {
    const bookingUuid = costItem.bookingUuid

    if (bookingUuid) {
      const { costsheetBookings, updateCostsheetBookings, onCostItemEdited = () => { } } = this.props

      const updatedBookings =
        costsheetBookings &&
        costsheetBookings.map((booking) => {
          const updatedBooking = { ...booking }

          updatedBooking.costItems =
            booking.costItems &&
            booking.costItems.filter((ci) => {
              return ci.uuid !== deletedCostItem.uuid
            })

          return updatedBooking
        })

      updateCostsheetBookings(updatedBookings)

      onCostItemEdited(bookingUuid)
    }
  }

  render() {
    const {
      getError,
      costItemsView,
      voucherBooking,
      selectedVoucher,
      showDeletedCostItems,
      selectedGlobalCompany,
      onCostItemAdded,
      compact
    } = this.props

    const { voucherItems = [] } = selectedVoucher
    const voucherStatus = selectedVoucher.status
    const voucherBookingCostItems = voucherBooking.costItems || []
    const sgcCompany = selectedGlobalCompany && selectedGlobalCompany.company

    let sortedCostItems = sortCostItems(voucherBookingCostItems)

    if (!showDeletedCostItems) {
      sortedCostItems = filter(voucherBookingCostItems, (vbci) => !vbci.isDeleted)
    }

    const dataSource = sortedCostItems.map((item) => {
      return {
        key: item.uuid,
        details: item,
        isNetOff:
          numeral(item.grossProfit).value() !== 0 &&
          numeral(item.grossProfit).subtract(item.estimatedProfit).value() === 0
      }
    })

    const columns = []

    if (!includes(['DELETED'], voucherStatus)) {
      columns.push({
        title: () => {
          if (voucherStatus === 'NEW') {
            return (
              <ActionWrapper>
                <Icon
                  type="arrow-down"
                  style={{ margin: '0 5px 0 42px', color: '#1890ff', cursor: 'pointer' }}
                  onClick={this.handleSelectAll(dataSource)}
                />
              </ActionWrapper>
            )
          }

          return null
        },
        key: 'action',
        width: '60px',
        render: (text, record, index) => {
          if (record.details.isDeleted) {
            return (
              <DeletedItem>
                <Icon type="minus-circle" />
              </DeletedItem>
            )
          }

          const relatedVoucherItems = filter(
            voucherItems,
            ({ costItem, isDeleted }) => costItem.uuid === record.details.uuid && !isDeleted
          )

          return (
            <ActionWrapper>
              <EditCostItemAction
                index={index}
                costItem={record.details}
                onCostItemEdited={this.handleCostItemEdited(record.details)}
                onCostItemDeleted={this.handleCostItemDeleted(record.details)}
              />
              {['NEW', 'DRAFT'].includes(selectedVoucher.status) && (
                <VoucherCostItemUsage
                  index={index}
                  onSelectSingleCostItem={this.handleSelectSingleCostItem(record.details)}
                />
              )}
              <Badge count={relatedVoucherItems.length} style={{ backgroundColor: '#1890ff' }} />
            </ActionWrapper>
          )
        }
      })
    }

    const colsLookup = {
      description: {
        title: <StyledField>Description</StyledField>,
        dataIndex: 'description',
        key: 'description',
        render: (text, record) => {
          const desc = record.details && record.details.description

          return (
            <MultiRow>
              <CustomText
                text={record.details && record.details.chargeItem && record.details.chargeItem.code}
              />{' '}
              <CustomText
                text={record.details && record.details.chargeItem && record.details.chargeItem.name}
              />
              <br />
              {desc && <CustomText text={desc} muted />}
            </MultiRow>
          )
        }
      },
      unitSize: {
        title: <StyledField>Unit/Size</StyledField>,
        key: 'unitSize',
        render: (text, record) => (
          <UnitSizeContainer
            size={record.details && record.details.size}
            unit={record.details && record.details.unit}
          />
        )
      },
      quantity: {
        title: <StyledField>QTY</StyledField>,
        dataIndex: 'quantity',
        key: 'quantity',
        align: 'right',
        render: (text, record) => (
          <CustomText
            text={record.details && record.details.quantity}
            toRight
            error={getError(record.details.errors, 'quantity')}
          />
        )
      },
      sell: {
        title: <StyledField>Sell</StyledField>,
        dataIndex: 'sell',
        key: 'sell',
        align: 'right',
        render: (text, record) => {
          const showCurrency =
            sgcCompany &&
            sgcCompany.currency.uuid !==
            (record.details.sellCurrency && record.details.sellCurrency.uuid)
          const currency =
            (record.details.sellCurrency && record.details.sellCurrency.code) || 'N/A'
          const baseRate = record.details.sellBaseRate
          const exchangeRate = numeral(record.details.sellExchangeRate).value()

          if (baseRate) {
            return (
              <>
                {showCurrency && <CustomText text={`${currency} `} muted noPadding />}
                <CustomText text={baseRate} isNumeric noPadding />{' '}
                {exchangeRate !== 1 && (
                  <>
                    <CustomText text="@" muted />
                    <CustomText text={exchangeRate} format="(0,0.0000)" isNumeric muted noPadding />
                  </>
                )}
              </>
            )
          } else {
            return (
              <>
                <CustomText text={baseRate} isNumeric />
              </>
            )
          }
        }
      },
      cost: {
        title: <StyledField>Cost</StyledField>,
        dataIndex: 'cost',
        key: 'cost',
        align: 'right',
        render: (text, record) => {
          const showCurrency =
            sgcCompany.currency.uuid !==
            (record.details.costCurrency && record.details.costCurrency.uuid)
          const currency =
            (record.details.costCurrency && record.details.costCurrency.code) || 'N/A'
          const baseRate = record.details.costBaseRate
          const exchangeRate = numeral(record.details.costExchangeRate).value()

          if (baseRate) {
            return (
              <>
                {showCurrency && <CustomText text={`${currency} `} muted noPadding />}
                <CustomText text={baseRate} isNumeric noPadding />{' '}
                {exchangeRate !== 1 && (
                  <>
                    <CustomText text="@" muted />
                    <CustomText text={exchangeRate} format="(0,0.0000)" isNumeric muted noPadding />
                  </>
                )}
              </>
            )
          }

          return <CustomText text="-" />
        }
      },
      sellTotal: {
        title: <StyledField>Sell Total</StyledField>,
        dataIndex: 'sellTotal',
        key: 'sellTotal',
        align: 'right',
        render: (text, record) => <CustomText text={record.details.sellTotal} isNumeric toRight />
      },
      costTotal: {
        title: <StyledField>Cost Total</StyledField>,
        dataIndex: 'costTotal',
        key: 'costTotal',
        align: 'right',
        render: (text, record) => <CustomText text={record.details.costTotal} isNumeric toRight />
      },
      ar: {
        title: <StyledField>AR</StyledField>,
        dataIndex: 'ar',
        key: 'ar',
        align: 'right',
        render: (text, record) => {
          const accReceivable = numeral(record.details.accountReceivable).value()
          const accReceivableDraft = numeral(record.details.accountReceivableDraft).value()

          return (
            <>
              {accReceivable === 0 && accReceivableDraft === 0 && <CustomText text="-" noPadding />}
              {accReceivable !== 0 && <CustomText text={accReceivable} isNumeric toRight />}
              {accReceivableDraft !== 0 && (
                <>
                  {accReceivable !== 0 && <br />}
                  <CustomText text={accReceivableDraft} isNumeric toRight muted noPadding />
                </>
              )}
            </>
          )
        }
      },
      ap: {
        title: <StyledField>AP</StyledField>,
        dataIndex: 'ap',
        key: 'ap',
        align: 'right',
        render: (text, record) => {
          const accPayable = numeral(record.details.accountPayable).value()
          const accPayableDraft = numeral(record.details.accountPayableDraft).value()

          return (
            <>
              {accPayable === 0 && accPayableDraft === 0 && <CustomText text="-" noPadding />}
              {accPayable !== 0 && <CustomText text={accPayable} isNumeric toRight />}
              {accPayableDraft !== 0 && (
                <>
                  {accPayable !== 0 && <br />}
                  <CustomText text={accPayableDraft} isNumeric toRight muted noPadding />
                </>
              )}
            </>
          )
        }
      },
      accrual: {
        title: <StyledField>Accrual</StyledField>,
        dataIndex: 'accrual',
        key: 'accrual',
        align: 'right',
        render: (text, record) => (
          <CustomText
            text={record.details.accrual}
            isNumeric
            toRight
            error={getError(record.details.errors, 'accrual')}
          />
        )
      },
      shortBill: {
        title: <StyledField>Short Bill</StyledField>,
        dataIndex: 'shortBill',
        key: 'shortBill',
        align: 'right',
        render: (text, record) => (
          <CustomText
            text={record.details.shortBill}
            isNumeric
            toRight
            isNetOff={record.isNetOff}
            error={getError(record.details.errors, 'shortBill')}
          />
        )
      },
      estimatedProfit: {
        title: <StyledField>Est Profit</StyledField>,
        dataIndex: 'estimatedProfit',
        key: 'estimatedProfit',
        align: 'right',
        render: (text, record) => (
          <CustomText
            text={record.details.estimatedProfit}
            isNumeric
            toRight
            isNetOff={record.isNetOff}
            error={getError(record.details.errors, 'estimatedProfit')}
          />
        )
      },
      grossProfit: {
        title: <StyledField>Gross Profit</StyledField>,
        dataIndex: 'grossProfit',
        key: 'grossProfit',
        align: 'right',
        render: (text, record) => (
          <CustomText
            text={record.details.grossProfit}
            isNumeric
            toRight
            isNetOff={record.isNetOff}
            error={getError(record.details.errors, 'grossProfit')}
          />
        )
      }
    }

    if (costItemsView === 'operation') {
      oprColNames.forEach((n) => columns.push(colsLookup[n]))
    } else if (costItemsView === 'finance') {
      finColNames.forEach((n) => columns.push(colsLookup[n]))
    }

    return (
      <CostItemsTableView
        columns={columns}
        dataSource={dataSource}
        voucherBooking={voucherBooking}
        selectedVoucher={selectedVoucher}
        selectedGlobalCompany={selectedGlobalCompany}
        voucherBookingCostItems={voucherBookingCostItems}
        onCostItemAdded={onCostItemAdded}
        compact={compact}
      />
    )
  }
}

export default withCostItems(VoucherCostItems)
