
import { compose, withState, withHandlers } from 'recompose'
import { graphql } from 'react-apollo'
import { Table, Form, DatePicker, Input, Icon } from 'antd'
import moment from 'moment'
import styled from 'styled-components'
import { StyledButton } from '../../ButtonWrapper'

import { UPDATE_LEG } from 'App/graphql/booking'
import LegDeleteAction from 'App/components/Workflow/LegDeleteAction'
import respHandler from 'App/utils/responseHandler'

const withGraphqlUpdateLeg = graphql(UPDATE_LEG, {
  props: ({ mutate, ownProps }) => ({
    updateLeg: (input) =>
      mutate({
        variables: {
          input
        }
      })
  })
})

const editingKeyState = withState('editingKey', 'setEditingKey', '')

const handlers = withHandlers({
  handleEdit: (props) => (uuid) => (e) => {
    props.setEditingKey(uuid)
  },
  handleCancel: (props) => () => {
    props.setEditingKey('')
  },
  handleUpdate: (props) => () => {
    const { form, updateLeg, setEditingKey, onChange = () => { } } = props

    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          respHandler('Updating leg, hang on...', 'load')

          await updateLeg(values)
          await onChange()
          setEditingKey('')

          respHandler('Updated successfully.', 'success')
        } catch (error) {
          console.log(error)
          respHandler(error, 'error')
        }
      }
    })
  }
})

const enhance = compose(withGraphqlUpdateLeg, Form.create(), editingKeyState, handlers)

const ActionSpan = styled.span`
  a {
    margin-left: 5px;
  }
`

const getColumns = (props) => {
  const {
    editingKey,
    form: { getFieldDecorator },
    handleEdit,
    handleCancel,
    handleUpdate,
    onChange = () => { }
  } = props

  return [
    {
      key: 'start',
      title: 'Start',
      dataIndex: 'start',
      className: 'xs-font',
      render: (start, leg) => {
        if (editingKey === leg.uuid) {
          return (
            <Form.Item>
              {getFieldDecorator('start', {
                initialValue: start && moment(start)
              })(
                <DatePicker
                  placeholder="Start"
                  showTime={{ format: 'h:mm:ss a', use12Hours: true }}
                  format="YYYY-MM-DD h:mm:ss a"
                />
              )}
            </Form.Item>
          )
        }

        if (!start) {
          return '-'
        }

        return moment(start).format('lll')
      }
    },
    {
      key: 'name',
      title: 'Name',
      dataIndex: 'name',
      className: 'xs-font',
      render: (text, leg) => {
        const toAddressName = (leg && leg.to && leg.to.name) || '-'
        return toAddressName
      }
    },
    {
      key: 'type',
      title: 'Type',
      dataIndex: 'type',
      className: 'xs-font',
      render: (text, leg) => {
        if (editingKey === leg.uuid) {
          return (
            <Form.Item>
              {getFieldDecorator('type', {
                initialValue: leg.type
              })(<Input />)}
            </Form.Item>
          )
        }

        return leg.type
      }
    },
    {
      key: 'driver',
      title: 'Driver',
      dataIndex: 'driverId',
      className: 'xs-font',
      render: (text, leg) => {
        if (editingKey === leg.uuid) {
          return (
            <Form.Item>
              {getFieldDecorator('driverId', {
                initialValue: leg.driverId
              })(<Input />)}
            </Form.Item>
          )
        }

        return leg.driverId
      }
    },
    {
      key: 'pm',
      title: 'PM',
      dataIndex: 'pmId',
      className: 'xs-font',
      render: (end, leg) => {
        if (editingKey === leg.uuid) {
          return (
            <Form.Item>
              {getFieldDecorator('pmId', {
                initialValue: leg.pmId
              })(<Input />)}
            </Form.Item>
          )
        }

        return leg.pmId
      }
    },
    {
      key: 'trailer',
      title: 'Trailer',
      dataIndex: 'trailerId',
      className: 'xs-font',
      render: (end, leg) => {
        if (editingKey === leg.uuid) {
          return (
            <Form.Item>
              {getFieldDecorator('trailerId', {
                initialValue: leg.trailerId
              })(<Input />)}
            </Form.Item>
          )
        }

        return leg.trailerId
      }
    },
    {
      key: 'status',
      title: 'Status',
      dataIndex: 'status',
      className: 'xs-font'
    },
    {
      key: 'action',
      title: '',
      className: 'xs-font',
      render: (text, leg, index) => {
        if (editingKey === leg.uuid) {
          return (
            <ActionSpan>
              {getFieldDecorator('uuid', {
                initialValue: leg.uuid
              })(<Input type="hidden" />)}
              <StyledButton onClick={handleUpdate}>
                <Icon type="check" style={{ color: 'green' }} />
              </StyledButton>
              <StyledButton onClick={handleCancel}>
                <Icon type="close" style={{ color: 'red' }} />
              </StyledButton>
            </ActionSpan>
          )
        }

        return (
          <ActionSpan>
            <StyledButton onClick={handleEdit(leg.uuid)}>
              <Icon type="edit" />
            </StyledButton>
            <LegDeleteAction type="icon" leg={leg} onLegDeleted={onChange} />
          </ActionSpan>
        )
      }
    }
  ]
}

const LegsTable = (props) => {
  const { legs } = props
  return (
    <Table
      dataSource={legs}
      rowKey={(leg) => leg.uuid}
      columns={getColumns(props)}
      size="small"
      rowClassName={() => 'xs-font'}
      pagination={false}
    />
  )
}

export default enhance(LegsTable)
