import { gql } from '@apollo/client'

export const updateDocumentCreatorGql = gql`
  mutation updateDocumentCreatorForModal($input: UpdateDocumentCreator) {
    updateDocumentCreator(input: $input) {
      uuid
      status
      type
    }
  }
`
