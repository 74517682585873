import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  CountryAlpha2: { input: any; output: any; }
  CountryAlpha3: { input: any; output: any; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Email: { input: any; output: any; }
  JSON: { input: any; output: any; }
  JSONObject: { input: any; output: any; }
  LanguageCode: { input: any; output: any; }
  LimitedString: { input: any; output: any; }
  LimitedString2: { input: any; output: any; }
  LimitedString3: { input: any; output: any; }
  ObjectID: { input: any; output: any; }
  Password: { input: any; output: any; }
  URL: { input: any; output: any; }
  UUID: { input: any; output: any; }
};

export enum AccountType {
  Creditor = 'creditor',
  Debtor = 'debtor'
}

export type ActionTemplate = BookingDocumentTemplate | RequestTemplate | VoucherTemplate;

export enum ActiveStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type AddAddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  address4?: InputMaybe<Scalars['String']['input']>;
  areaCode?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  countryAlpha3?: InputMaybe<Scalars['String']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  plusCode?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AddressStatus>;
  tags?: InputMaybe<Array<InputMaybe<AddressTags>>>;
  type?: InputMaybe<Array<InputMaybe<AddressType>>>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type AddChargeItemCodeInput = {
  chargeItemUuid: Scalars['UUID']['input'];
  glCodeUuid: Scalars['UUID']['input'];
  type: ChargeItemCodeType;
};

export type AddChargeItemInput = {
  code: Scalars['String']['input'];
  costCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  costRate?: InputMaybe<Scalars['Float']['input']>;
  costRateId?: InputMaybe<Scalars['String']['input']>;
  costTaxUuid?: InputMaybe<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expenseCodeUuid?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  rateType?: InputMaybe<ChargeRateType>;
  revenueCodeUuid?: InputMaybe<Scalars['UUID']['input']>;
  sellCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  sellRate?: InputMaybe<Scalars['Float']['input']>;
  sellRateId?: InputMaybe<Scalars['String']['input']>;
  sellTaxUuid?: InputMaybe<Scalars['UUID']['input']>;
  sequence?: InputMaybe<Scalars['String']['input']>;
  status: ChargeItemStatus;
  tags?: InputMaybe<Array<InputMaybe<ChargeItemTagType>>>;
  term?: InputMaybe<Scalars['Int']['input']>;
  unit: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};

export type AddChargeItemTaxInput = {
  chargeItemUuid: Scalars['UUID']['input'];
  rate?: InputMaybe<Scalars['Float']['input']>;
  rateType?: InputMaybe<ChargeRateType>;
  taxUuid: Scalars['UUID']['input'];
  type: ChargeItemTaxType;
};

export type AddContactInput = {
  companyUuid: Scalars['UUID']['input'];
  designation?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ContactStatus>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type AddCostItemInput = {
  accrual?: InputMaybe<Scalars['Float']['input']>;
  bookingUuid: Scalars['UUID']['input'];
  chargeItemUuid: Scalars['UUID']['input'];
  costBaseRate: Scalars['Float']['input'];
  costCurrencyUuid: Scalars['UUID']['input'];
  costExchangeRate: Scalars['Float']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  quantity: Scalars['Float']['input'];
  sellBaseRate: Scalars['Float']['input'];
  sellCurrencyUuid: Scalars['UUID']['input'];
  sellExchangeRate: Scalars['Float']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type AddEntityAddressInput = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  address4?: InputMaybe<Scalars['String']['input']>;
  areaCode?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  countryAlpha3: Scalars['LimitedString']['input'];
  district?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Array<Scalars['Float']['input']>>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AddressStatus>;
  tags?: InputMaybe<Array<InputMaybe<AddressTags>>>;
  type: Array<InputMaybe<AddressType>>;
};

export type AddEntityDocumentInput = {
  baseCompanyUuid: Scalars['UUID']['input'];
  category: Scalars['String']['input'];
  end: Scalars['DateTime']['input'];
  entityType: EntityType;
  entityUuid: Scalars['UUID']['input'];
  fields?: InputMaybe<Array<InputMaybe<EntityFieldInput>>>;
  id: Scalars['String']['input'];
  start: Scalars['DateTime']['input'];
  status: DocumentStatus;
  uploadUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type AddJobInput = {
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  no?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<JobReferencesInput>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  tripFormat?: InputMaybe<TripFormat>;
  trips?: InputMaybe<Array<InputMaybe<JobTripUpdateInput>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type AddLegInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  addAfterSequence?: InputMaybe<Scalars['Int']['input']>;
  address?: InputMaybe<AddAddressInput>;
  legUuid?: InputMaybe<Scalars['UUID']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
  yardUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type AddLegsInput = {
  complete?: InputMaybe<Scalars['Boolean']['input']>;
  endLocation?: InputMaybe<ManifestLocationInput>;
  endOut?: InputMaybe<Scalars['DateTime']['input']>;
  legUuid?: InputMaybe<Scalars['UUID']['input']>;
  startLocation?: InputMaybe<ManifestLocationInput>;
  startOut?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AddManifestInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  driverUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  vehicleUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type AddManifestLegsInput = {
  legs?: InputMaybe<Array<InputMaybe<AddLegsInput>>>;
  location?: InputMaybe<ManifestLocationInput>;
  manifestUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type AddManifestTripsInput = {
  location?: InputMaybe<ManifestLocationInput>;
  manifestUuid?: InputMaybe<Scalars['UUID']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  startOut?: InputMaybe<Scalars['DateTime']['input']>;
  waybillIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type AddPaymentInput = {
  date: Scalars['DateTime']['input'];
  reference: Scalars['String']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
  transactionType: PaymentTransactionType;
  voucherPayments: Array<InputMaybe<AddVoucherPaymentInput>>;
};

export type AddVoucherItemInput = {
  baseRate: Scalars['Float']['input'];
  costItemUuid: Scalars['UUID']['input'];
  currencyUuid: Scalars['UUID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  exchangeRate: Scalars['Float']['input'];
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  localExchangeRate: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Int']['input']>;
  taxUuid: Scalars['UUID']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  voucherItemCnUuid?: InputMaybe<Scalars['UUID']['input']>;
  voucherUuid: Scalars['UUID']['input'];
};

export type AddVoucherItemViaVoucherInput = {
  baseRate: Scalars['Float']['input'];
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  costItemUuid: Scalars['UUID']['input'];
  currencyUuid: Scalars['UUID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  exchangeRate: Scalars['Float']['input'];
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  localExchangeRate: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  rate?: InputMaybe<Scalars['Float']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Int']['input']>;
  taxUuid: Scalars['UUID']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  voucherItemCnUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type AddVoucherPaymentInput = {
  amount: Scalars['Float']['input'];
  voucherUuid: Scalars['UUID']['input'];
};

export type Address = {
  __typename?: 'Address';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  address4?: Maybe<Scalars['String']['output']>;
  areaCode?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  company?: Maybe<Company>;
  companyUuid?: Maybe<Scalars['UUID']['output']>;
  countryAlpha3?: Maybe<Scalars['LimitedString']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Geometry>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  placeId?: Maybe<Scalars['String']['output']>;
  plusCode?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AddressStatus>;
  tags?: Maybe<Array<Maybe<AddressTags>>>;
  type?: Maybe<Array<Maybe<AddressType>>>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  zone?: Maybe<Scalars['String']['output']>;
};

export type AddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  address4?: InputMaybe<Scalars['String']['input']>;
  areaCode?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  countryAlpha3?: InputMaybe<Scalars['LimitedString']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<LocationInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  plusCode?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AddressStatus>;
  tags?: InputMaybe<Array<InputMaybe<AddressTags>>>;
  type?: InputMaybe<Array<InputMaybe<AddressType>>>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
};

export enum AddressStatus {
  Activated = 'activated',
  Deleted = 'deleted'
}

export enum AddressTags {
  IsDefault = 'isDefault'
}

export enum AddressType {
  Billing = 'BILLING',
  Contact = 'CONTACT',
  Delivery = 'DELIVERY',
  Mailing = 'MAILING',
  Warehouse = 'WAREHOUSE'
}

export type Addresses = {
  __typename?: 'Addresses';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Address>>>;
};

export type Approval = {
  __typename?: 'Approval';
  approvedBy?: Maybe<User>;
  bookingDocument?: Maybe<BookingDocument>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  request?: Maybe<Request>;
  status?: Maybe<ApprovalStatus>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  voucher?: Maybe<Voucher>;
};

export type ApprovalInput = {
  requestUuid?: InputMaybe<Scalars['UUID']['input']>;
  type: Scalars['String']['input'];
  voucherUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export enum ApprovalStatus {
  Approved = 'APPROVED',
  Pending = 'PENDING',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export type Approvals = {
  __typename?: 'Approvals';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Approval>>>;
};

export type AssignPortalCompanyInput = {
  hours?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  portalCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  reportUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type Assignee = {
  __typename?: 'Assignee';
  type?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type Attachment = {
  __typename?: 'Attachment';
  eTag?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type Auth = {
  __typename?: 'Auth';
  auth0Token: Scalars['String']['output'];
  auth0UserId: Scalars['String']['output'];
};

export type Auth0 = {
  token: Scalars['String']['input'];
};

export type AuthProvider = {
  auth0?: InputMaybe<Auth0>;
};

export type AvailabilitiesResults = {
  __typename?: 'AvailabilitiesResults';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Availability>>>;
};

export type Availability = {
  __typename?: 'Availability';
  baseCompanyUuid?: Maybe<Scalars['UUID']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  details?: Maybe<Array<Maybe<EntityDetails>>>;
  status?: Maybe<AvailabilityStatus>;
  type?: Maybe<EntityType>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum AvailabilityStatus {
  Available = 'AVAILABLE',
  Mc = 'MC',
  Repair = 'REPAIR',
  Unavailable = 'UNAVAILABLE'
}

export type BcSettingAreaCode = {
  __typename?: 'BCSettingAreaCode';
  value?: Maybe<BcSettingAreaCodeInputRequirement>;
};

export enum BcSettingAreaCodeInputRequirement {
  Freetext = 'FREETEXT',
  Optional = 'OPTIONAL',
  Strict = 'STRICT'
}

export type BaseCompanies = {
  __typename?: 'BaseCompanies';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<BaseCompany>>>;
};

export type BaseCompany = {
  __typename?: 'BaseCompany';
  company?: Maybe<Company>;
  country?: Maybe<Country>;
  currencies?: Maybe<Array<Maybe<Currency>>>;
  currency?: Maybe<Currency>;
  details?: Maybe<BaseCompanyDetails>;
  employees?: Maybe<Array<Maybe<Employee>>>;
  entityExceptions?: Maybe<EntityExceptions>;
  entityRequirements?: Maybe<EntityRequirements>;
  groups?: Maybe<Array<Maybe<Group>>>;
  logo?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  registration?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<Array<Maybe<BaseCompanySetting>>>;
  shortCode?: Maybe<Scalars['String']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CompanyStatus>;
  subscription?: Maybe<CompanySubscriptionType>;
  timezone?: Maybe<Scalars['String']['output']>;
  types?: Maybe<Array<Maybe<CompanyType>>>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type BaseCompanyDetails = {
  __typename?: 'BaseCompanyDetails';
  userTimeout?: Maybe<Scalars['Int']['output']>;
};

export type BaseCompanyDetailsInput = {
  userTimeout?: InputMaybe<Scalars['Int']['input']>;
};

export type BaseCompanySetting = {
  __typename?: 'BaseCompanySetting';
  category?: Maybe<Scalars['String']['output']>;
  count?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  setting?: Maybe<Array<Maybe<Setting>>>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type BaseCompanySettings = {
  __typename?: 'BaseCompanySettings';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<BaseCompanySetting>>>;
};

export enum BillingManner {
  ByBooking = 'byBooking',
  ByJob = 'byJob',
  ByTrip = 'byTrip'
}

export type BillingUnit = {
  __typename?: 'BillingUnit';
  baseCompanyUuid?: Maybe<Scalars['UUID']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<BillingUnitStatus>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum BillingUnitStatus {
  Activated = 'activated',
  Deleted = 'deleted'
}

export type BillingUnits = {
  __typename?: 'BillingUnits';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<BillingUnit>>>;
};

export enum Bloodtype {
  AbMinus = 'ABMinus',
  AbPlus = 'ABPlus',
  AMinus = 'AMinus',
  APlus = 'APlus',
  BMinus = 'BMinus',
  BPlus = 'BPlus',
  OMinus = 'OMinus',
  OPlus = 'OPlus'
}

export type Booking = {
  __typename?: 'Booking';
  addresses?: Maybe<Array<Maybe<Address>>>;
  assignees?: Maybe<Array<Maybe<Assignee>>>;
  billTo?: Maybe<Company>;
  bookingDocuments?: Maybe<Array<Maybe<BookingDocument>>>;
  chronologies?: Maybe<Array<Maybe<Chronology>>>;
  commodities?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  companies?: Maybe<Array<Maybe<Company>>>;
  costItems?: Maybe<Array<Maybe<CostItem>>>;
  costSheet?: Maybe<CostSheet>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  details?: Maybe<Scalars['JSON']['output']>;
  estDrop?: Maybe<Scalars['DateTime']['output']>;
  estPick?: Maybe<Scalars['DateTime']['output']>;
  events?: Maybe<Array<Maybe<Event>>>;
  isCloseFinance?: Maybe<Scalars['Boolean']['output']>;
  isCloseOperation?: Maybe<Scalars['Boolean']['output']>;
  jobUuid?: Maybe<Scalars['String']['output']>;
  jobs?: Maybe<Array<Maybe<Job>>>;
  no?: Maybe<Scalars['String']['output']>;
  owner?: Maybe<BaseCompany>;
  permissions?: Maybe<BookingPermissionType>;
  places?: Maybe<Array<Maybe<Place>>>;
  ports?: Maybe<Array<Maybe<Port>>>;
  processFlow?: Maybe<Array<Maybe<ProcessFlow>>>;
  quotation?: Maybe<Quotation>;
  remarks?: Maybe<Scalars['String']['output']>;
  requests?: Maybe<Array<Maybe<Request>>>;
  serviceTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  shortId?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Array<Maybe<BookingState>>>;
  status?: Maybe<BookingStatus>;
  statusUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tripUuid?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  vouchers?: Maybe<Array<Maybe<Voucher>>>;
};

export type BookingAssigneeInput = {
  bookingUuid: Scalars['UUID']['input'];
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type BookingDocument = {
  __typename?: 'BookingDocument';
  approvals?: Maybe<Array<Maybe<Approval>>>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  document?: Maybe<Document>;
  file?: Maybe<Document>;
  hardCopyStatus?: Maybe<DocumentHardCopyStatus>;
  jobUuid?: Maybe<Scalars['UUID']['output']>;
  location?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  status?: Maybe<DocumentStatus>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tripUuid?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  verifiedBy?: Maybe<User>;
};

export type BookingDocumentTemplate = {
  __typename?: 'BookingDocumentTemplate';
  approvals?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type BookingDocuments = {
  __typename?: 'BookingDocuments';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<BookingDocument>>>;
};

export enum BookingDynamicFieldQuery {
  Company = 'COMPANY',
  CustomValues = 'CUSTOM_VALUES',
  Department = 'DEPARTMENT',
  Enums = 'ENUMS',
  Place = 'PLACE',
  Port = 'PORT',
  Quotation = 'QUOTATION'
}

export enum BookingDynamicFieldType {
  Date = 'DATE',
  Selector = 'SELECTOR',
  Text = 'TEXT'
}

export type BookingEvent = {
  __typename?: 'BookingEvent';
  context?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<EventCreator>;
};

export type BookingEventPayload = {
  __typename?: 'BookingEventPayload';
  companyUuid?: Maybe<Scalars['UUID']['output']>;
  forwarderUuid?: Maybe<Scalars['UUID']['output']>;
  haulierUuid?: Maybe<Scalars['UUID']['output']>;
  shipperUuid?: Maybe<Scalars['UUID']['output']>;
};

export type BookingFieldValue = {
  __typename?: 'BookingFieldValue';
  value?: Maybe<Scalars['JSON']['output']>;
};

export type BookingFieldValueInput = {
  booking?: InputMaybe<Scalars['JSON']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type BookingPermissionType = {
  __typename?: 'BookingPermissionType';
  canImpLinerOverrideDoubleInvoice?: Maybe<Scalars['Boolean']['output']>;
  canImpLinerOverrideDoubleVoucher?: Maybe<Scalars['Boolean']['output']>;
  canImpLinerOverrideIncompleteDocs?: Maybe<Scalars['Boolean']['output']>;
};

export type BookingState = {
  __typename?: 'BookingState';
  key?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export enum BookingStatus {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED',
  CloseFinance = 'CLOSE_FINANCE',
  CloseOperation = 'CLOSE_OPERATION',
  Draft = 'DRAFT',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

export type BookingSummary = {
  __typename?: 'BookingSummary';
  pendingCount?: Maybe<Scalars['Int']['output']>;
};

export type BookingTransportInput = {
  _tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  billToCode?: InputMaybe<Scalars['String']['input']>;
  billToName?: InputMaybe<Scalars['String']['input']>;
  billToTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  billToUuid?: InputMaybe<Scalars['UUID']['input']>;
  chronologies?: InputMaybe<Array<InputMaybe<ChronologyInput>>>;
  commodities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consigneeAddress?: InputMaybe<AddressInput>;
  consigneeCode?: InputMaybe<Scalars['String']['input']>;
  consigneeName?: InputMaybe<Scalars['String']['input']>;
  consigneeTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  consigneeUuid?: InputMaybe<Scalars['UUID']['input']>;
  dateCreated?: InputMaybe<Scalars['DateTime']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  jobs: Array<InputMaybe<JobInput>>;
  no?: InputMaybe<Scalars['String']['input']>;
  ownerUuid?: InputMaybe<Scalars['UUID']['input']>;
  scn?: InputMaybe<Scalars['String']['input']>;
  shipperAddress?: InputMaybe<AddressInput>;
  shipperCode?: InputMaybe<Scalars['String']['input']>;
  shipperName?: InputMaybe<Scalars['String']['input']>;
  shipperTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  shipperUuid?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<BookingStatus>;
  type: Scalars['String']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type BookingType = {
  __typename?: 'BookingType';
  billing?: Maybe<Array<Maybe<BookingTypeBilling>>>;
  code?: Maybe<Scalars['String']['output']>;
  companies?: Maybe<Array<Maybe<Company>>>;
  description?: Maybe<Scalars['String']['output']>;
  dynamicFields?: Maybe<Array<Maybe<BookingTypeDynamicField>>>;
  flowType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sorting?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<BookingTypeStatus>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type BookingTypeBilling = {
  __typename?: 'BookingTypeBilling';
  billManner?: Maybe<BillingManner>;
  chargeItemUuid?: Maybe<Scalars['UUID']['output']>;
  costRate?: Maybe<Scalars['Float']['output']>;
  costRateId?: Maybe<Scalars['String']['output']>;
  desc?: Maybe<Scalars['String']['output']>;
  incentiveTreeId?: Maybe<Scalars['String']['output']>;
  sellRate?: Maybe<Scalars['Float']['output']>;
  sellRateId?: Maybe<Scalars['String']['output']>;
};

export type BookingTypeBillingInput = {
  billManner?: InputMaybe<BillingManner>;
  chargeItemUuid?: InputMaybe<Scalars['UUID']['input']>;
  costRate?: InputMaybe<Scalars['Float']['input']>;
  costRateId?: InputMaybe<Scalars['String']['input']>;
  desc?: InputMaybe<Scalars['String']['input']>;
  incentiveTreeId?: InputMaybe<Scalars['String']['input']>;
  sellRate?: InputMaybe<Scalars['Float']['input']>;
  sellRateId?: InputMaybe<Scalars['String']['input']>;
};

export type BookingTypeDynamicField = {
  __typename?: 'BookingTypeDynamicField';
  chronologyLevel?: Maybe<BookingTypeDynamicFieldChronologyLevel>;
  companyTypes?: Maybe<Array<Maybe<CompanyType>>>;
  control?: Maybe<BookingTypeDynamicFieldControl>;
  customValues?: Maybe<Scalars['String']['output']>;
  enumName?: Maybe<Scalars['String']['output']>;
  isMultiline?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  multiple?: Maybe<Scalars['Boolean']['output']>;
  query?: Maybe<BookingDynamicFieldQuery>;
  searchable?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<BookingDynamicFieldType>;
};

export enum BookingTypeDynamicFieldChronologyLevel {
  Booking = 'Booking',
  Job = 'Job',
  Trip = 'Trip'
}

export enum BookingTypeDynamicFieldControl {
  Optional = 'OPTIONAL',
  Required = 'REQUIRED'
}

export type BookingTypeDynamicFieldInput = {
  chronologyLevel?: InputMaybe<BookingTypeDynamicFieldChronologyLevel>;
  companyTypes?: InputMaybe<Array<InputMaybe<CompanyType>>>;
  control?: InputMaybe<BookingTypeDynamicFieldControl>;
  customValues?: InputMaybe<Scalars['String']['input']>;
  enumName?: InputMaybe<Scalars['String']['input']>;
  isMultiline?: InputMaybe<Scalars['Boolean']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  multiple?: InputMaybe<Scalars['Boolean']['input']>;
  query?: InputMaybe<BookingDynamicFieldQuery>;
  searchable?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<BookingDynamicFieldType>;
};

export enum BookingTypeStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type BookingTypes = {
  __typename?: 'BookingTypes';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<BookingType>>>;
};

export type Bookings = {
  __typename?: 'Bookings';
  facets?: Maybe<Scalars['JSON']['output']>;
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Booking>>>;
};

export type BookingsJson = {
  __typename?: 'BookingsJson';
  facets?: Maybe<Scalars['JSON']['output']>;
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Scalars['JSON']['output']>;
};

export type BookingsSearchFilter = {
  baseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  companyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  departments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  facetFilter?: InputMaybe<Scalars['JSON']['input']>;
  isAssigned?: InputMaybe<Scalars['Boolean']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type BookingsSearchInput = {
  _noSubGraph?: InputMaybe<Scalars['Boolean']['input']>;
  facets?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  filter?: InputMaybe<BookingsSearchFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type BulkImportFunction = {
  __typename?: 'BulkImportFunction';
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  options?: Maybe<Scalars['JSON']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  status?: Maybe<BulkImportFunctionStatus>;
  url?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type BulkImportFunctionSettingInput = {
  bulkImportFunctionUuid?: InputMaybe<Scalars['UUID']['input']>;
  portalCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export enum BulkImportFunctionStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type BulkImportFunctionUpdateInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  options?: InputMaybe<Scalars['JSON']['input']>;
  ownerUuid?: InputMaybe<Scalars['UUID']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type BulkImportFunctions = {
  __typename?: 'BulkImportFunctions';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<BulkImportFunction>>>;
};

export type BulkImportFunctionsInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  portalCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type ChangeCostItemInput = {
  newChargeItemUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type Channel = {
  __typename?: 'Channel';
  receiver?: Maybe<Company>;
  sender?: Maybe<Company>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type Channels = {
  __typename?: 'Channels';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Channel>>>;
};

export type ChargeCategories = {
  __typename?: 'ChargeCategories';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<ChargeCategory>>>;
};

export type ChargeCategory = {
  __typename?: 'ChargeCategory';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['String']['output']>;
  type?: Maybe<ChargeCategoryType>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum ChargeCategoryType {
  Department = 'department',
  Job = 'job',
  Location = 'location',
  SstTypeCode = 'sstTypeCode'
}

export type ChargeItem = {
  __typename?: 'ChargeItem';
  categories?: Maybe<Array<Maybe<ChargeCategory>>>;
  code?: Maybe<Scalars['String']['output']>;
  codes?: Maybe<Array<Maybe<ChargeItemCode>>>;
  costCurrency?: Maybe<Currency>;
  costRate?: Maybe<Scalars['Float']['output']>;
  costRateId?: Maybe<Scalars['String']['output']>;
  costTax?: Maybe<Tax>;
  description?: Maybe<Scalars['String']['output']>;
  expenseCode?: Maybe<GlCode>;
  name?: Maybe<Scalars['String']['output']>;
  rateType?: Maybe<ChargeRateType>;
  revenueCode?: Maybe<GlCode>;
  sellCurrency?: Maybe<Currency>;
  sellRate?: Maybe<Scalars['Float']['output']>;
  sellRateId?: Maybe<Scalars['String']['output']>;
  sellTax?: Maybe<Tax>;
  sequence?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ChargeItemStatus>;
  tags?: Maybe<Array<Maybe<ChargeItemTagType>>>;
  taxes?: Maybe<Array<Maybe<ChargeItemTax>>>;
  term?: Maybe<Scalars['Int']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type ChargeItemCode = {
  __typename?: 'ChargeItemCode';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  glCode?: Maybe<GlCode>;
  type?: Maybe<ChargeItemCodeType>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum ChargeItemCodeType {
  ExpenseExp = 'expenseExp',
  ExpenseInternal = 'expenseInternal',
  ExpenseInternalBranch = 'expenseInternalBranch',
  RevenueExp = 'revenueExp',
  RevenueInternal = 'revenueInternal',
  RevenueInternalBranch = 'revenueInternalBranch'
}

export type ChargeItemMisc = {
  __typename?: 'ChargeItemMisc';
  chargeItem?: Maybe<ChargeItem>;
  customer?: Maybe<Company>;
  documentUuid?: Maybe<Scalars['UUID']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type ChargeItemMiscs = {
  __typename?: 'ChargeItemMiscs';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<ChargeItemMisc>>>;
};

export enum ChargeItemStatus {
  Activated = 'activated',
  Deleted = 'deleted'
}

export enum ChargeItemTagType {
  IsAutoLoad = 'isAutoLoad',
  IsBillingUnit = 'isBillingUnit',
  IsContainerSize = 'isContainerSize',
  IsDisbursement = 'isDisbursement',
  IsFaf = 'isFaf',
  IsJobClose = 'isJobClose',
  IsOperationCostSheet = 'isOperationCostSheet',
  IsRevenue = 'isRevenue',
  IsRoundingExchangeRate = 'isRoundingExchangeRate',
  IsRoundingTax = 'isRoundingTax',
  IsTermControl = 'isTermControl'
}

export type ChargeItemTax = {
  __typename?: 'ChargeItemTax';
  rate?: Maybe<Scalars['Float']['output']>;
  rateType?: Maybe<ChargeRateType>;
  tax?: Maybe<Tax>;
  type?: Maybe<ChargeItemTaxType>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum ChargeItemTaxType {
  Cost = 'cost',
  InputInternal = 'inputInternal',
  InputInternalBranch = 'inputInternalBranch',
  OutputInternal = 'outputInternal',
  OutputInternalBranch = 'outputInternalBranch',
  Sell = 'sell'
}

export type ChargeItems = {
  __typename?: 'ChargeItems';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<ChargeItem>>>;
};

export enum ChargeRateRuleType {
  Equal = 'EQUAL',
  GreaterThan = 'GREATER_THAN',
  GreaterThanEqual = 'GREATER_THAN_EQUAL',
  LessThan = 'LESS_THAN',
  LessThanEqual = 'LESS_THAN_EQUAL',
  NotEqual = 'NOT_EQUAL'
}

export enum ChargeRateType {
  Fixed = 'FIXED',
  Percentage = 'PERCENTAGE',
  Unit = 'UNIT'
}

export type ChildNode = {
  __typename?: 'ChildNode';
  _id?: Maybe<Scalars['ObjectID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  isExit?: Maybe<Scalars['Boolean']['output']>;
  ownerUuid?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ActiveStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  value?: Maybe<RateValue>;
};

export type ChildNodes = {
  __typename?: 'ChildNodes';
  pageInfo?: Maybe<PageInfo>;
  rows?: Maybe<Array<Maybe<ChildNode>>>;
};

export type ChildNodesQueryInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  parentId?: InputMaybe<Scalars['ObjectID']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export type ChildSelector = {
  __typename?: 'ChildSelector';
  key?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Scalars['JSON']['output']>;
};

export type Chronology = {
  __typename?: 'Chronology';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  jobUuid?: Maybe<Scalars['UUID']['output']>;
  location?: Maybe<Location>;
  reference?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  tripUuid?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type ChronologyInput = {
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  location?: InputMaybe<LocationInput>;
  reference?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ChronologyNewBookingInput = {
  date: Scalars['DateTime']['input'];
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
  type: Scalars['String']['input'];
};

export type ChronologyUpdateInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  location?: InputMaybe<LocationInput>;
  reference?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CloneNodeBranchInput = {
  nodeId?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['String']['input']>;
};

export type CloseShiftInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  driverUuid?: InputMaybe<Scalars['UUID']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  vehicleUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type Commodity = {
  __typename?: 'Commodity';
  description?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type CommodityInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type CommodityType = {
  __typename?: 'CommodityType';
  path?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type CommodityTypes = {
  __typename?: 'CommodityTypes';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<CommodityType>>>;
};

export type Companies = {
  __typename?: 'Companies';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Company>>>;
};

export type Company = {
  __typename?: 'Company';
  accountUuid?: Maybe<Scalars['UUID']['output']>;
  addresses?: Maybe<Array<Maybe<Address>>>;
  billTo?: Maybe<Company>;
  code?: Maybe<Scalars['String']['output']>;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  country?: Maybe<Country>;
  countryAlpha3?: Maybe<Scalars['LimitedString']['output']>;
  creditorCode?: Maybe<Scalars['String']['output']>;
  creditorTerm?: Maybe<Scalars['Int']['output']>;
  currency?: Maybe<Currency>;
  debtorCode?: Maybe<Scalars['String']['output']>;
  debtorTerm?: Maybe<Scalars['Int']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  employees?: Maybe<Array<Maybe<User>>>;
  forwarders?: Maybe<Array<Maybe<Company>>>;
  integrationDetail?: Maybe<IntegrationDetail>;
  name?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  registration?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CompanyStatus>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  taxNumber?: Maybe<Scalars['String']['output']>;
  types?: Maybe<Array<Maybe<CompanyType>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  updatedByUuid?: Maybe<Scalars['UUID']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type CompanyAccess = {
  __typename?: 'CompanyAccess';
  externalBaseCompany?: Maybe<BaseCompany>;
  externalCompany?: Maybe<Company>;
  internalCompany?: Maybe<Company>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  status?: Maybe<CompanyAccessStatus>;
  type?: Maybe<CompanyAccessType>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum CompanyAccessStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export enum CompanyAccessType {
  Customer = 'CUSTOMER',
  ShipperConsignee = 'SHIPPER_CONSIGNEE'
}

export type CompanyAccesses = {
  __typename?: 'CompanyAccesses';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<CompanyAccess>>>;
};

export enum CompanyInviteStatus {
  Activated = 'ACTIVATED',
  Invited = 'INVITED'
}

export type CompanySearch = {
  __typename?: 'CompanySearch';
  exhaustiveNbHits?: Maybe<Scalars['Boolean']['output']>;
  hits?: Maybe<Array<Maybe<Company>>>;
  hitsPerPage?: Maybe<Scalars['Int']['output']>;
  nbHits?: Maybe<Scalars['Int']['output']>;
  nbPages?: Maybe<Scalars['Int']['output']>;
  page?: Maybe<Scalars['Int']['output']>;
  params?: Maybe<Scalars['String']['output']>;
  processingTimeMS?: Maybe<Scalars['Int']['output']>;
  query?: Maybe<Scalars['String']['output']>;
};

export enum CompanyStatus {
  Activated = 'activated',
  Deleted = 'deleted'
}

export enum CompanySubscriptionType {
  Premium = 'PREMIUM',
  Standard = 'STANDARD'
}

export enum CompanyType {
  Billing = 'billing',
  Customs = 'customs',
  Depot = 'depot',
  Forwarder = 'forwarder',
  FreightForwarder = 'freightForwarder',
  Haulier = 'haulier',
  Liner = 'liner',
  OneTimeVendor = 'oneTimeVendor',
  Port = 'port',
  ShipperConsignee = 'shipperConsignee',
  ShippingAgent = 'shippingAgent',
  Transporter = 'transporter',
  Warehouse = 'warehouse'
}

export type ComputeRateInput = {
  booking?: InputMaybe<Scalars['JSON']['input']>;
  keyPreference?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  nodeId?: InputMaybe<Scalars['String']['input']>;
  rootNodeCode?: InputMaybe<Scalars['String']['input']>;
};

export type ComputeReportJobSummaryInput = {
  baseCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
};

export enum ComputeSummaryType {
  Job = 'JOB',
  Leg = 'LEG',
  Trip = 'TRIP'
}

export type ComputedRate = {
  __typename?: 'ComputedRate';
  chargeItemCode?: Maybe<Scalars['String']['output']>;
  log?: Maybe<Array<Maybe<RateLogItem>>>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type ComputedRateResult = {
  __typename?: 'ComputedRateResult';
  bookingUuid?: Maybe<Scalars['String']['output']>;
  computeBy?: Maybe<Scalars['String']['output']>;
  computedRate?: Maybe<Array<Maybe<ComputedRate>>>;
  jobUuid?: Maybe<Scalars['String']['output']>;
  tripUuid?: Maybe<Scalars['String']['output']>;
};

export type ConsolVoucher = {
  __typename?: 'ConsolVoucher';
  issueDate?: Maybe<Scalars['DateTime']['output']>;
  owner?: Maybe<BaseCompany>;
  reference?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ConsolVoucherStatus>;
  type?: Maybe<ConsolVoucherType>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  vouchers?: Maybe<Array<Maybe<Voucher>>>;
};

export enum ConsolVoucherStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Void = 'VOID'
}

export enum ConsolVoucherType {
  ConsolApInvoice = 'ConsolAPInvoice',
  ConsolArInvoice = 'ConsolARInvoice'
}

export type ConsolVouchers = {
  __typename?: 'ConsolVouchers';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<ConsolVoucher>>>;
};

export type Contact = {
  __typename?: 'Contact';
  designation?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ContactStatus>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  title?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type ContactSimple = {
  __typename?: 'ContactSimple';
  number: Scalars['String']['output'];
  type: ContactSimpleType;
};

export type ContactSimpleInput = {
  number: Scalars['String']['input'];
  type: ContactSimpleType;
};

export enum ContactSimpleType {
  Other = 'OTHER',
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
  Tertiary = 'TERTIARY'
}

export enum ContactStatus {
  Activated = 'activated',
  Deleted = 'deleted'
}

export type Contacts = {
  __typename?: 'Contacts';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Contact>>>;
};

export type ContainerEvent = {
  __typename?: 'ContainerEvent';
  context?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
};

export type ContainerGrade = {
  __typename?: 'ContainerGrade';
  baseCompanyUuid?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sorting?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum ContainerGradeStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type ContainerGrades = {
  __typename?: 'ContainerGrades';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<ContainerGrade>>>;
};

export type ContainerType = {
  __typename?: 'ContainerType';
  baseCompanyUuid?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sorting?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum ContainerTypeStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type ContainerTypes = {
  __typename?: 'ContainerTypes';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<ContainerType>>>;
};

export type CostItem = {
  __typename?: 'CostItem';
  accountPayable?: Maybe<Scalars['Float']['output']>;
  accountPayableDraft?: Maybe<Scalars['Float']['output']>;
  accountReceivable?: Maybe<Scalars['Float']['output']>;
  accountReceivableDraft?: Maybe<Scalars['Float']['output']>;
  accrual?: Maybe<Scalars['Float']['output']>;
  blankCheque?: Maybe<Scalars['Float']['output']>;
  bookingUuid?: Maybe<Scalars['UUID']['output']>;
  cashBook?: Maybe<Scalars['Float']['output']>;
  chargeItem?: Maybe<ChargeItem>;
  costBaseRate?: Maybe<Scalars['Float']['output']>;
  costCurrency?: Maybe<Currency>;
  costExchangeRate?: Maybe<Scalars['Float']['output']>;
  costRate?: Maybe<Scalars['Float']['output']>;
  costTotal?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  editBy?: Maybe<User>;
  errors?: Maybe<Array<Maybe<CostItemErrorType>>>;
  estimatedProfit?: Maybe<Scalars['Float']['output']>;
  grossProfit?: Maybe<Scalars['Float']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  sellBaseRate?: Maybe<Scalars['Float']['output']>;
  sellCurrency?: Maybe<Currency>;
  sellExchangeRate?: Maybe<Scalars['Float']['output']>;
  sellRate?: Maybe<Scalars['Float']['output']>;
  sellTotal?: Maybe<Scalars['Float']['output']>;
  shortBill?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type CostSheet = {
  __typename?: 'CostSheet';
  accountPayable?: Maybe<Scalars['Float']['output']>;
  accountPayableDraft?: Maybe<Scalars['Float']['output']>;
  accountReceivable?: Maybe<Scalars['Float']['output']>;
  accountReceivableDraft?: Maybe<Scalars['Float']['output']>;
  accrual?: Maybe<Scalars['Float']['output']>;
  costTotal?: Maybe<Scalars['Float']['output']>;
  estimatedProfit?: Maybe<Scalars['Float']['output']>;
  grossProfit?: Maybe<Scalars['Float']['output']>;
  sellTotal?: Maybe<Scalars['Float']['output']>;
  shortBill?: Maybe<Scalars['Float']['output']>;
  shortBillPercentage?: Maybe<Scalars['Float']['output']>;
};

export type Counter = {
  __typename?: 'Counter';
  count?: Maybe<Scalars['Int']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type CounterResults = {
  __typename?: 'CounterResults';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Counter>>>;
};

export enum CounterType {
  Empty = 'empty',
  Incremental = 'incremental',
  Json = 'json',
  Nanoid = 'nanoid',
  Shortid = 'shortid'
}

export type Countries = {
  __typename?: 'Countries';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Country>>>;
};

export type Country = {
  __typename?: 'Country';
  alpha2?: Maybe<Scalars['LimitedString2']['output']>;
  alpha3?: Maybe<Scalars['LimitedString']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ports?: Maybe<Array<Maybe<Port>>>;
};

export type CreateBaseCompanyInput = {
  cloneSettingsFromBaseCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  countryAlpha3: Scalars['LimitedString']['input'];
  currencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  details?: InputMaybe<BaseCompanyDetailsInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  registration?: InputMaybe<Scalars['String']['input']>;
  shortCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CompanyStatus>;
  subscription?: InputMaybe<CompanySubscriptionType>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  types: Array<CompanyType>;
};

export type CreateBillingUnitInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BillingUnitStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateChannelInput = {
  receiverUuid: Scalars['UUID']['input'];
  senderUuid: Scalars['UUID']['input'];
  type: Scalars['String']['input'];
};

export type CreateChargeItemMiscInput = {
  chargeItemUuid: Scalars['UUID']['input'];
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  documentUuid?: InputMaybe<Scalars['UUID']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  type: Scalars['String']['input'];
  value?: InputMaybe<Scalars['String']['input']>;
};

export type CreateCompanyInput = {
  billToUuid?: InputMaybe<Scalars['UUID']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  countryAlpha3: Scalars['LimitedString']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  overrideDuplicateCode?: InputMaybe<Scalars['Boolean']['input']>;
  preferredCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CompanyStatus>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  types: Array<CompanyType>;
  uuid: Scalars['UUID']['input'];
};

export type CreateConsolVoucherInput = {
  type?: InputMaybe<Scalars['String']['input']>;
  voucherUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type CreateContainerGradeInput = {
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sorting: Scalars['Int']['input'];
  status?: InputMaybe<ContainerGradeStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateContainerTypeInput = {
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sorting: Scalars['Int']['input'];
  status?: InputMaybe<ContainerTypeStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateDocumentCreator = {
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  data?: InputMaybe<DataDetailsInput>;
  type?: InputMaybe<DocumentCreatorType>;
};

export type CreateDocumentCreatorTemplate = {
  companies?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  details?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DocumentCreatorTemplateStatus>;
  template?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateDriverInput = {
  address?: InputMaybe<Array<InputMaybe<AddEntityAddressInput>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<Array<InputMaybe<ContactSimpleInput>>>;
  countryAlpha3?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  governmentId?: InputMaybe<Scalars['String']['input']>;
  healthAllergies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  healthBloodtype?: InputMaybe<Bloodtype>;
  healthIssues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextOfKinAddress?: InputMaybe<Array<InputMaybe<AddEntityAddressInput>>>;
  nextOfKinContact?: InputMaybe<Array<InputMaybe<ContactSimpleInput>>>;
  nextOfKinName?: InputMaybe<Scalars['String']['input']>;
  nextOfKinRelationship?: InputMaybe<Scalars['String']['input']>;
  nickName?: InputMaybe<Scalars['String']['input']>;
  ownerType?: InputMaybe<DriverOwnerType>;
  preferredCurrency?: InputMaybe<Scalars['String']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<DriverStatus>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  type: DriverType;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  vehiclePreference?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CreateExchangeRateInput = {
  end: Scalars['String']['input'];
  fromUuid: Scalars['UUID']['input'];
  rate: Scalars['Float']['input'];
  start: Scalars['String']['input'];
  toUuid: Scalars['UUID']['input'];
};

export type CreateFafInput = {
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  end: Scalars['DateTime']['input'];
  rate: Scalars['Float']['input'];
  start: Scalars['DateTime']['input'];
  uuid: Scalars['UUID']['input'];
  vendorUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateFuelInput = {
  _id: Scalars['UUID']['input'];
  amount: Scalars['Float']['input'];
  amountRemaining?: InputMaybe<Scalars['Float']['input']>;
  date: Scalars['DateTime']['input'];
  driverUuid?: InputMaybe<Scalars['UUID']['input']>;
  odometer: Scalars['Int']['input'];
  rate?: InputMaybe<Scalars['Float']['input']>;
  ref?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  vehicleUuid: Scalars['UUID']['input'];
  vendor?: InputMaybe<Scalars['String']['input']>;
};

export type CreateGateLogInput = {
  _id: Scalars['UUID']['input'];
  date: Scalars['DateTime']['input'];
  driverUuid?: InputMaybe<Scalars['UUID']['input']>;
  odometer?: InputMaybe<Scalars['Int']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  trailerUuid?: InputMaybe<Scalars['UUID']['input']>;
  type: GateLogType;
  vehicleUuid: Scalars['UUID']['input'];
  yardUuid: Scalars['UUID']['input'];
};

export type CreateGlCodeInput = {
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIncentiveInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  costCode?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  legUuid?: InputMaybe<Scalars['UUID']['input']>;
  overrideDuplicateLeg?: InputMaybe<Scalars['Boolean']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  shiftUuid?: InputMaybe<Scalars['UUID']['input']>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
  typeUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateIncentiveRateInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rates?: InputMaybe<Array<InputMaybe<InputIncentiveRateDetails>>>;
  to?: InputMaybe<Scalars['String']['input']>;
  transportType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uom?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIncentiveTypeInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  glCodeUuid?: InputMaybe<Scalars['UUID']['input']>;
  isJobRequired?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIncentiveVoucherInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  incentiveUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type CreateIntegrationDetailInput = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  integrationUuid: Scalars['UUID']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  meta: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type CreateIntegrationMappingInput = {
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  externalCode: Scalars['String']['input'];
  integrationDetailUuid: Scalars['UUID']['input'];
  internalCode: Scalars['UUID']['input'];
  type: Scalars['String']['input'];
};

export type CreateJobOptionInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<JobOptionStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreatePortalCompanyInput = {
  bookCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  details?: InputMaybe<PortalCompanyDetailsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  reports?: InputMaybe<Array<InputMaybe<PortalCompanyReportConfigInput>>>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  viewCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type CreateQuotationInput = {
  buyerAddressUuid?: InputMaybe<Scalars['UUID']['input']>;
  buyerContactUuid?: InputMaybe<Scalars['UUID']['input']>;
  buyers: Array<Scalars['UUID']['input']>;
  date: Scalars['DateTime']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  expiry: Scalars['DateTime']['input'];
  note?: InputMaybe<Scalars['String']['input']>;
  quotationItems: Array<InputMaybe<CreateQuotationItemViaQuotationInput>>;
  sellerAddressUuid?: InputMaybe<Scalars['UUID']['input']>;
  sellerContactUuid?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<QuotationStatus>;
  tags?: InputMaybe<Array<InputMaybe<QuotationTagTypes>>>;
  term: Scalars['Int']['input'];
};

export type CreateQuotationItemInput = {
  chargeItemUuid: Scalars['UUID']['input'];
  containerType?: InputMaybe<Scalars['String']['input']>;
  costCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  costRate?: InputMaybe<Scalars['Float']['input']>;
  costRateId?: InputMaybe<Scalars['String']['input']>;
  costTaxUuid?: InputMaybe<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  maxChargeAmount?: InputMaybe<Scalars['Float']['input']>;
  minChargeAmount?: InputMaybe<Scalars['Float']['input']>;
  quotationUuid: Scalars['UUID']['input'];
  rateType?: InputMaybe<ChargeRateType>;
  sellCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  sellRate?: InputMaybe<Scalars['Float']['input']>;
  sellRateId?: InputMaybe<Scalars['String']['input']>;
  sellTaxUuid?: InputMaybe<Scalars['UUID']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<QuotationItemTags>>>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type CreateQuotationItemViaQuotationInput = {
  chargeItemUuid: Scalars['UUID']['input'];
  containerType?: InputMaybe<Scalars['String']['input']>;
  costCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  costRate?: InputMaybe<Scalars['Float']['input']>;
  costTaxUuid?: InputMaybe<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  maxChargeAmount?: InputMaybe<Scalars['Float']['input']>;
  minChargeAmount?: InputMaybe<Scalars['Float']['input']>;
  rateType?: InputMaybe<ChargeRateType>;
  sellCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  sellRate?: InputMaybe<Scalars['Float']['input']>;
  sellTaxUuid?: InputMaybe<Scalars['UUID']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<QuotationItemTags>>>;
  unit?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReportInput = {
  categories?: InputMaybe<Array<InputMaybe<ReportCategories>>>;
  columns?: InputMaybe<Array<InputMaybe<ReportColumnInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<ReportModel>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateRoleInput = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uuid: Scalars['UUID']['input'];
};

export type CreateSettingInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  setting?: InputMaybe<Array<InputMaybe<SettingInput>>>;
};

export type CreateShiftInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  breaks?: InputMaybe<Array<InputMaybe<ShiftBreakInput>>>;
  driverUuid?: InputMaybe<Scalars['UUID']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateTaxInput = {
  code: Scalars['String']['input'];
  exemptionDocumentUuid?: InputMaybe<Scalars['UUID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  percentage: Scalars['Float']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateTrailerInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  loadCapacity?: InputMaybe<Scalars['Int']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  manufactureYear?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<TrailerOwnerType>;
  registration?: InputMaybe<Scalars['String']['input']>;
  registrationYear?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<TrailerStatus>;
  tareWeight?: InputMaybe<Scalars['Int']['input']>;
  trailerType?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateTransportAreaCodeInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Array<Scalars['Float']['input']>>;
  locationPolygon?: InputMaybe<Array<InputMaybe<Array<Scalars['Float']['input']>>>>;
  name: Scalars['String']['input'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uuid: Scalars['UUID']['input'];
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateTransportJobTypeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TransportJobTypeStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateTransportRateInput = {
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rates?: InputMaybe<Array<InputMaybe<InputTransportRateDetails>>>;
  to?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  vendorUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateTransportZoneInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TransportZoneStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateVehicleInput = {
  airBags?: InputMaybe<Scalars['Boolean']['input']>;
  antilockBrakes?: InputMaybe<Scalars['Boolean']['input']>;
  axleWeight?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  chassisNo?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  driverPreference?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  engineCapacity?: InputMaybe<Scalars['Int']['input']>;
  engineNo?: InputMaybe<Scalars['String']['input']>;
  loadCapacity?: InputMaybe<Scalars['Int']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  manufactureYear?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  ownerType?: InputMaybe<VehicleOwnerType>;
  registration?: InputMaybe<Scalars['String']['input']>;
  registrationYear?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<VehicleStatus>;
  tareWeight?: InputMaybe<Scalars['Int']['input']>;
  trailerPreference?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateVolumeUnitInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<VolumeUnitStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateVoucherInput = {
  accountDate?: InputMaybe<Scalars['DateTime']['input']>;
  addressUuid?: InputMaybe<Scalars['UUID']['input']>;
  bookingDocumentUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  contactPerson?: InputMaybe<Scalars['String']['input']>;
  currencyUuid: Scalars['UUID']['input'];
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentCreatorTemplateUuid?: InputMaybe<Scalars['UUID']['input']>;
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  isCreditNote: Scalars['Boolean']['input'];
  issueDate: Scalars['DateTime']['input'];
  overrideDoubleReference?: InputMaybe<Scalars['Boolean']['input']>;
  overrideDoubleVoucher?: InputMaybe<Scalars['Boolean']['input']>;
  overrideIncompleteDocs?: InputMaybe<Scalars['Boolean']['input']>;
  paymentType: PaymentType;
  salesPersonUuid?: InputMaybe<Scalars['UUID']['input']>;
  term: Scalars['Int']['input'];
  type: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
  vendorUuid?: InputMaybe<Scalars['UUID']['input']>;
  voucherItems?: InputMaybe<Array<InputMaybe<AddVoucherItemViaVoucherInput>>>;
};

export type CreateWeightUnitInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<WeightUnitStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CreateYardInput = {
  address?: InputMaybe<AddressInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<YardStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type Currencies = {
  __typename?: 'Currencies';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Currency>>>;
};

export type Currency = {
  __typename?: 'Currency';
  code?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type DataDetails = {
  __typename?: 'DataDetails';
  billToCompany?: Maybe<Company>;
  customerRef?: Maybe<Scalars['String']['output']>;
  customerRef2?: Maybe<Scalars['String']['output']>;
  deliveryAddress?: Maybe<Address>;
  deliveryHour?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  dropoffAddress?: Maybe<Address>;
  dropoffCompany?: Maybe<Company>;
  haulier?: Maybe<Company>;
  jobs?: Maybe<Array<Maybe<Job>>>;
  options?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  pickupAddress?: Maybe<Address>;
  pickupCompany?: Maybe<Company>;
  remarks?: Maybe<Scalars['String']['output']>;
  requestNo?: Maybe<Scalars['String']['output']>;
  requiredDate?: Maybe<Scalars['DateTime']['output']>;
  submittedDate?: Maybe<Scalars['DateTime']['output']>;
};

export type DataDetailsInput = {
  billToUuid: Scalars['UUID']['input'];
  customerRef?: InputMaybe<Scalars['String']['input']>;
  customerRef2?: InputMaybe<Scalars['String']['input']>;
  deliveryAddressUuid: Scalars['UUID']['input'];
  deliveryHour: Scalars['String']['input'];
  documents?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  dropoffAddressUuid?: InputMaybe<Scalars['UUID']['input']>;
  dropoffCompanyUuid: Scalars['UUID']['input'];
  haulierUuid: Scalars['UUID']['input'];
  jobs: Array<Scalars['UUID']['input']>;
  options?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  pickupAddressUuid?: InputMaybe<Scalars['UUID']['input']>;
  pickupCompanyUuid: Scalars['UUID']['input'];
  remarks?: InputMaybe<Scalars['String']['input']>;
  requiredDate: Scalars['DateTime']['input'];
};

export type DeleteIncentiveTypeInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
};

export type DeleteLegInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  legUuid?: InputMaybe<Scalars['UUID']['input']>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type Document = {
  __typename?: 'Document';
  name?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type DocumentCreator = {
  __typename?: 'DocumentCreator';
  bookingUuid?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  data?: Maybe<DataDetails>;
  dataJSON?: Maybe<Scalars['JSON']['output']>;
  dataJSONObject?: Maybe<Scalars['JSONObject']['output']>;
  status?: Maybe<DocumentCreatorStatus>;
  template?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type DocumentCreatorReference = {
  __typename?: 'DocumentCreatorReference';
  no?: Maybe<Scalars['String']['output']>;
};

export enum DocumentCreatorStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Submitted = 'SUBMITTED',
  Void = 'VOID'
}

export type DocumentCreatorTemplate = {
  __typename?: 'DocumentCreatorTemplate';
  companies?: Maybe<Array<Maybe<Company>>>;
  details?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<DocumentCreatorTemplateStatus>;
  template?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum DocumentCreatorTemplateStatus {
  Activated = 'activated',
  Deleted = 'deleted'
}

export type DocumentCreatorTemplates = {
  __typename?: 'DocumentCreatorTemplates';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<DocumentCreatorTemplate>>>;
};

export enum DocumentCreatorType {
  HouseBl = 'HouseBL',
  HouseNoa = 'HouseNOA',
  Rot = 'ROT'
}

export type DocumentCreators = {
  __typename?: 'DocumentCreators';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<DocumentCreator>>>;
};

export type DocumentDescription = {
  __typename?: 'DocumentDescription';
  attachment?: Maybe<Attachment>;
  baseCompanyUuid?: Maybe<Scalars['UUID']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  entityType?: Maybe<EntityType>;
  entityUuid?: Maybe<Scalars['UUID']['output']>;
  fields?: Maybe<Array<Maybe<EntityField>>>;
  id?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<DocumentStatus>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum DocumentHardCopyStatus {
  Archived = 'ARCHIVED',
  Received = 'RECEIVED',
  Submitted = 'SUBMITTED'
}

export type DocumentInput = {
  name: Scalars['String']['input'];
  path: Scalars['String']['input'];
  size: Scalars['Int']['input'];
  type: Scalars['String']['input'];
};

export enum DocumentStatus {
  Deleted = 'DELETED',
  Requested = 'REQUESTED',
  Uploaded = 'UPLOADED',
  Verified = 'VERIFIED'
}

export type Driver = {
  __typename?: 'Driver';
  address?: Maybe<Array<Maybe<EntityAddress>>>;
  code?: Maybe<Scalars['String']['output']>;
  companies?: Maybe<Array<Maybe<EntityCompany>>>;
  contact?: Maybe<Array<Maybe<ContactSimple>>>;
  countryAlpha3?: Maybe<Scalars['String']['output']>;
  customerAddress?: Maybe<Array<Maybe<EntityCompanyAddress>>>;
  customers?: Maybe<Array<Maybe<EntityCompany>>>;
  department?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<DocumentDescription>>>;
  editBy?: Maybe<Scalars['String']['output']>;
  editDate?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  employeeUuid?: Maybe<Scalars['UUID']['output']>;
  entityException?: Maybe<EntityExceptionOutcome>;
  gender?: Maybe<Gender>;
  governmentId?: Maybe<Scalars['String']['output']>;
  healthAllergies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  healthBloodtype?: Maybe<Bloodtype>;
  healthIssues?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  image?: Maybe<Attachment>;
  name?: Maybe<Scalars['String']['output']>;
  nextOfKinAddress?: Maybe<Array<Maybe<EntityAddress>>>;
  nextOfKinContact?: Maybe<Array<Maybe<ContactSimple>>>;
  nextOfKinName?: Maybe<Scalars['String']['output']>;
  nextOfKinRelationship?: Maybe<Scalars['String']['output']>;
  nickName?: Maybe<Scalars['String']['output']>;
  ownerType?: Maybe<DriverOwnerType>;
  preferredCurrency?: Maybe<Scalars['String']['output']>;
  registration?: Maybe<Scalars['String']['output']>;
  status?: Maybe<DriverStatus>;
  subContractors?: Maybe<Array<Maybe<EntityCompany>>>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  taxNumber?: Maybe<Scalars['String']['output']>;
  type?: Maybe<DriverType>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  vehiclePreference?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DriverBehaviorAuth = {
  __typename?: 'DriverBehaviorAuth';
  jwt?: Maybe<Scalars['String']['output']>;
};

export enum DriverOwnerType {
  Interbranch = 'INTERBRANCH',
  Intercompany = 'INTERCOMPANY',
  Outsource = 'OUTSOURCE',
  Owned = 'OWNED'
}

export enum DriverStatus {
  Active = 'ACTIVE',
  Banned = 'BANNED',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  Resigned = 'RESIGNED'
}

export enum DriverType {
  Contract = 'CONTRACT',
  Permanent = 'PERMANENT',
  Subcontractor = 'SUBCONTRACTOR',
  Temp = 'TEMP'
}

export type DriverUtilDept = {
  __typename?: 'DriverUtilDept';
  c?: Maybe<Scalars['Int']['output']>;
  dept?: Maybe<Scalars['String']['output']>;
  tot?: Maybe<Scalars['Int']['output']>;
};

export type DriversResults = {
  __typename?: 'DriversResults';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Driver>>>;
};

export type EmailRequestFields = EmailRequestFieldsBookingConfirmationFromLiner | EmailRequestFieldsBookingConfirmationReeferFromLiner | EmailRequestFieldsConsigneeDraftNotification | EmailRequestFieldsEdo | EmailRequestFieldsExportDocs | EmailRequestFieldsHaulageRfc | EmailRequestFieldsHaulageRot | EmailRequestFieldsImportDocs | EmailRequestFieldsLinerBookingConfirmationCustomerRequest | EmailRequestFieldsLinerContainerMovementOrder | EmailRequestFieldsLinerNotifyCustomerComplete | EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill | EmailRequestFieldsNoa;

export type EmailRequestFieldsBookingConfirmationFromLiner = {
  __typename?: 'EmailRequestFieldsBookingConfirmationFromLiner';
  bl?: Maybe<Scalars['String']['output']>;
  commodity?: Maybe<Scalars['String']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  containers?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  etaTo?: Maybe<Scalars['String']['output']>;
  etdFrom?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  shipper?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  vessel?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsBookingConfirmationReeferFromLiner = {
  __typename?: 'EmailRequestFieldsBookingConfirmationReeferFromLiner';
  bl?: Maybe<Scalars['String']['output']>;
  commodity?: Maybe<Scalars['String']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  containerWeights?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  containers?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  destination?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  etaTo?: Maybe<Scalars['String']['output']>;
  etdFrom?: Maybe<Scalars['String']['output']>;
  origin?: Maybe<Scalars['String']['output']>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  shipper?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  temperatureSummary?: Maybe<Scalars['String']['output']>;
  vessel?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsConsigneeDraftNotification = {
  __typename?: 'EmailRequestFieldsConsigneeDraftNotification';
  bl?: Maybe<Scalars['String']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  containers?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsEdo = {
  __typename?: 'EmailRequestFieldsEdo';
  bl?: Maybe<Scalars['String']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  containers?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsExportDocs = {
  __typename?: 'EmailRequestFieldsExportDocs';
  bl?: Maybe<Scalars['String']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  containers?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsHaulageRfc = {
  __typename?: 'EmailRequestFieldsHaulageRFC';
  bl?: Maybe<Scalars['String']['output']>;
  closingDay?: Maybe<Scalars['DateTime']['output']>;
  commodity?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  deliveryHour?: Maybe<Scalars['String']['output']>;
  dropOff?: Maybe<Scalars['UUID']['output']>;
  eta?: Maybe<Scalars['DateTime']['output']>;
  etd?: Maybe<Scalars['DateTime']['output']>;
  jobs?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  lastDay?: Maybe<Scalars['DateTime']['output']>;
  operatorCode?: Maybe<Scalars['UUID']['output']>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  referenceNo?: Maybe<Scalars['UUID']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  requiredDate?: Maybe<Scalars['DateTime']['output']>;
  scn?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsHaulageRot = {
  __typename?: 'EmailRequestFieldsHaulageRot';
  billingCustomer?: Maybe<Scalars['UUID']['output']>;
  bl?: Maybe<Scalars['String']['output']>;
  closingDay?: Maybe<Scalars['DateTime']['output']>;
  commodity?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  delivery?: Maybe<Scalars['UUID']['output']>;
  deliveryHour?: Maybe<Scalars['String']['output']>;
  dischargePort?: Maybe<Scalars['UUID']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  dropOff?: Maybe<Scalars['UUID']['output']>;
  eta?: Maybe<Scalars['DateTime']['output']>;
  etd?: Maybe<Scalars['DateTime']['output']>;
  forwardingAgent?: Maybe<Scalars['UUID']['output']>;
  isBackToBack?: Maybe<Scalars['Boolean']['output']>;
  isCustomsSeal?: Maybe<Scalars['Boolean']['output']>;
  isDirectDelivery?: Maybe<Scalars['Boolean']['output']>;
  isDirectLoading?: Maybe<Scalars['Boolean']['output']>;
  jobs?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  lastDay?: Maybe<Scalars['DateTime']['output']>;
  manifestNo?: Maybe<Scalars['String']['output']>;
  operatorCode?: Maybe<Scalars['UUID']['output']>;
  pickup?: Maybe<Scalars['UUID']['output']>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  referenceNo?: Maybe<Scalars['UUID']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  requiredDate?: Maybe<Scalars['DateTime']['output']>;
  scn?: Maybe<Scalars['String']['output']>;
  shipperConsignee?: Maybe<Scalars['UUID']['output']>;
  shippingAgent?: Maybe<Scalars['UUID']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  vesselName?: Maybe<Scalars['String']['output']>;
  voyageNo?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsImportDocs = {
  __typename?: 'EmailRequestFieldsImportDocs';
  bl?: Maybe<Scalars['String']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  containers?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsLinerBookingConfirmationCustomerRequest = {
  __typename?: 'EmailRequestFieldsLinerBookingConfirmationCustomerRequest';
  bl?: Maybe<Scalars['String']['output']>;
  containers?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  shipper?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsLinerContainerMovementOrder = {
  __typename?: 'EmailRequestFieldsLinerContainerMovementOrder';
  bl?: Maybe<Scalars['String']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  containers?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  shipper?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsLinerNotifyCustomerComplete = {
  __typename?: 'EmailRequestFieldsLinerNotifyCustomerComplete';
  airwayBillNo?: Maybe<Scalars['String']['output']>;
  bl?: Maybe<Scalars['String']['output']>;
  commodity?: Maybe<Scalars['String']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  containers?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  customerRef?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  etaDestination?: Maybe<Scalars['String']['output']>;
  etdPkg?: Maybe<Scalars['String']['output']>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  shipper?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  vessel?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill = {
  __typename?: 'EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill';
  airwayBillNo?: Maybe<Scalars['String']['output']>;
  bl?: Maybe<Scalars['String']['output']>;
  commodity?: Maybe<Scalars['String']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  containers?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  customerRef?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  etaDestination?: Maybe<Scalars['String']['output']>;
  etdPkg?: Maybe<Scalars['String']['output']>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  shipper?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  vessel?: Maybe<Scalars['String']['output']>;
  volume?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsNoa = {
  __typename?: 'EmailRequestFieldsNoa';
  bl?: Maybe<Scalars['String']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  containers?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestFieldsPaymentNotification = {
  __typename?: 'EmailRequestFieldsPaymentNotification';
  bl?: Maybe<Scalars['String']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  containers?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type EmailRequestInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  bcc?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bookingUuid: Scalars['UUID']['input'];
  cc?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobs?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  override?: InputMaybe<Scalars['Boolean']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
  to: Array<InputMaybe<Scalars['String']['input']>>;
  type: RequestType;
};

export type Employee = {
  __typename?: 'Employee';
  baseCompany?: Maybe<Company>;
  driver?: Maybe<EmployeeDriver>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type EmployeeDriver = {
  __typename?: 'EmployeeDriver';
  code?: Maybe<Scalars['String']['output']>;
  employeeUuid?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickName?: Maybe<Scalars['String']['output']>;
  registration?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type Employees = {
  __typename?: 'Employees';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Employee>>>;
};

export type EmptyTrip = {
  __typename?: 'EmptyTrip';
  _id?: Maybe<Scalars['UUID']['output']>;
  billToCode?: Maybe<Scalars['String']['output']>;
  billToName?: Maybe<Scalars['String']['output']>;
  billToSource?: Maybe<TransportSource>;
  billToUuid?: Maybe<Scalars['UUID']['output']>;
  bookingUuid?: Maybe<Scalars['UUID']['output']>;
  consigneeAddress?: Maybe<Address>;
  consigneeCode?: Maybe<Scalars['String']['output']>;
  consigneeName?: Maybe<Scalars['String']['output']>;
  consigneeRequiredDate?: Maybe<Scalars['DateTime']['output']>;
  consigneeUuid?: Maybe<Scalars['UUID']['output']>;
  cost?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdByUUID?: Maybe<Scalars['UUID']['output']>;
  dFirstTs?: Maybe<Scalars['String']['output']>;
  dLastTs?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  driverCode?: Maybe<Scalars['String']['output']>;
  driverEntity?: Maybe<Driver>;
  driverName?: Maybe<Scalars['String']['output']>;
  driverUuid?: Maybe<Scalars['UUID']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  endOut?: Maybe<Scalars['DateTime']['output']>;
  firstTs?: Maybe<Scalars['DateTime']['output']>;
  from?: Maybe<Scalars['JSON']['output']>;
  jobNo?: Maybe<Scalars['String']['output']>;
  jobUuid?: Maybe<Scalars['UUID']['output']>;
  lastTs?: Maybe<Scalars['DateTime']['output']>;
  legUuid?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  planEnd?: Maybe<Scalars['DateTime']['output']>;
  planStart?: Maybe<Scalars['DateTime']['output']>;
  proposed?: Maybe<EmptyTripProposed>;
  reason?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  shipOutDate?: Maybe<Scalars['DateTime']['output']>;
  shipperAddress?: Maybe<Address>;
  shipperCode?: Maybe<Scalars['String']['output']>;
  shipperName?: Maybe<Scalars['String']['output']>;
  shipperRequiredDate?: Maybe<Scalars['DateTime']['output']>;
  shipperUuid?: Maybe<Scalars['UUID']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
  startOut?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<EmptyTripStatus>;
  tLastTs?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['JSON']['output']>;
  trailerCode?: Maybe<Scalars['String']['output']>;
  trailerEntity?: Maybe<Trailer>;
  trailerName?: Maybe<Scalars['String']['output']>;
  trailerUuid?: Maybe<Scalars['UUID']['output']>;
  transportSource?: Maybe<TransportSource>;
  tripUuid?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedByUUID?: Maybe<Scalars['UUID']['output']>;
  vFirstTs?: Maybe<Scalars['String']['output']>;
  vLastTs?: Maybe<Scalars['String']['output']>;
  vehicleCode?: Maybe<Scalars['String']['output']>;
  vehicleDepartments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleEntity?: Maybe<Vehicle>;
  vehicleName?: Maybe<Scalars['String']['output']>;
  vehicleUuid?: Maybe<Scalars['UUID']['output']>;
};

export type EmptyTripAddInput = {
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  consigneeAddress?: InputMaybe<Scalars['JSON']['input']>;
  consigneeCode?: InputMaybe<Scalars['String']['input']>;
  consigneeName?: InputMaybe<Scalars['String']['input']>;
  consigneeUuid?: InputMaybe<Scalars['UUID']['input']>;
  cost?: InputMaybe<Scalars['Float']['input']>;
  driverCode?: InputMaybe<Scalars['String']['input']>;
  driverName?: InputMaybe<Scalars['String']['input']>;
  driverNick?: InputMaybe<Scalars['String']['input']>;
  driverUuid?: InputMaybe<Scalars['UUID']['input']>;
  from?: InputMaybe<Scalars['JSON']['input']>;
  jobNo?: InputMaybe<Scalars['String']['input']>;
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  legUuid?: InputMaybe<Scalars['String']['input']>;
  ownerUuid?: InputMaybe<Scalars['UUID']['input']>;
  planStart?: InputMaybe<Scalars['DateTime']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  shipperAddress?: InputMaybe<Scalars['JSON']['input']>;
  shipperCode?: InputMaybe<Scalars['String']['input']>;
  shipperName?: InputMaybe<Scalars['String']['input']>;
  shipperUuid?: InputMaybe<Scalars['UUID']['input']>;
  to?: InputMaybe<Scalars['JSON']['input']>;
  trailerCode?: InputMaybe<Scalars['String']['input']>;
  trailerName?: InputMaybe<Scalars['String']['input']>;
  trailerNick?: InputMaybe<Scalars['String']['input']>;
  trailerUuid?: InputMaybe<Scalars['UUID']['input']>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
  vehicleCode?: InputMaybe<Scalars['String']['input']>;
  vehicleDepartments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleName?: InputMaybe<Scalars['String']['input']>;
  vehicleNick?: InputMaybe<Scalars['String']['input']>;
  vehicleUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type EmptyTripProposed = {
  __typename?: 'EmptyTripProposed';
  from?: Maybe<Scalars['JSON']['output']>;
  status?: Maybe<EmptyTripStatus>;
  to?: Maybe<Scalars['JSON']['output']>;
};

export type EmptyTripQuery = {
  activityEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  activityStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  baseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  billToUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  bookingUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  consigneeUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  driverCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  driverUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  fromCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fromZones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  legUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  shipOutEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  shipOutStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  shipperRequiredDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  shipperRequiredDateStart?: InputMaybe<Scalars['DateTime']['input']>;
  shipperUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  statuses?: InputMaybe<Array<InputMaybe<EmptyTripStatus>>>;
  toCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  toZones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tripUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  vehicleCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export enum EmptyTripStatus {
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT',
  Validated = 'VALIDATED'
}

export type EmptyTripUpdateInput = {
  cost?: InputMaybe<Scalars['Float']['input']>;
  driverCode?: InputMaybe<Scalars['String']['input']>;
  driverName?: InputMaybe<Scalars['String']['input']>;
  driverNick?: InputMaybe<Scalars['String']['input']>;
  driverUuid?: InputMaybe<Scalars['UUID']['input']>;
  from?: InputMaybe<Scalars['JSON']['input']>;
  legUuid?: InputMaybe<Scalars['UUID']['input']>;
  planStart?: InputMaybe<Scalars['DateTime']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Scalars['JSON']['input']>;
  trailerCode?: InputMaybe<Scalars['String']['input']>;
  trailerName?: InputMaybe<Scalars['String']['input']>;
  trailerNick?: InputMaybe<Scalars['String']['input']>;
  trailerUuid?: InputMaybe<Scalars['UUID']['input']>;
  vehicleCode?: InputMaybe<Scalars['String']['input']>;
  vehicleDepartments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleName?: InputMaybe<Scalars['String']['input']>;
  vehicleNick?: InputMaybe<Scalars['String']['input']>;
  vehicleUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type EmptyTripUpdateStatusInput = {
  cost?: InputMaybe<Scalars['Float']['input']>;
  legUuid?: InputMaybe<Scalars['UUID']['input']>;
  reason?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<EmptyTripStatus>;
};

export type EmptyTrips = {
  __typename?: 'EmptyTrips';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<EmptyTrip>>>;
};

export type EndManifestTripsInput = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  endOut?: InputMaybe<Scalars['DateTime']['input']>;
  location?: InputMaybe<ManifestLocationInput>;
  manifestUuid?: InputMaybe<Scalars['UUID']['input']>;
  waybillIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type EntityAddress = {
  __typename?: 'EntityAddress';
  address1?: Maybe<Scalars['String']['output']>;
  address2?: Maybe<Scalars['String']['output']>;
  address3?: Maybe<Scalars['String']['output']>;
  address4?: Maybe<Scalars['String']['output']>;
  areaCode?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  countryAlpha3?: Maybe<Scalars['LimitedString']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  fax?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Geometry>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  postCode?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AddressStatus>;
  tags?: Maybe<Array<Maybe<AddressTags>>>;
  type?: Maybe<Array<Maybe<AddressType>>>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type EntityCategoryRequirement = {
  __typename?: 'EntityCategoryRequirement';
  allowedCategories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  documentRequirements?: Maybe<Array<Maybe<EntityDocumentRequirement>>>;
};

export type EntityCompany = {
  __typename?: 'EntityCompany';
  companyReference?: Maybe<Scalars['String']['output']>;
  companyType?: Maybe<EntityCompanyType>;
  companyUuid?: Maybe<Scalars['UUID']['output']>;
};

export type EntityCompanyAddress = {
  __typename?: 'EntityCompanyAddress';
  addressUuid?: Maybe<Scalars['UUID']['output']>;
  companyReference?: Maybe<Scalars['String']['output']>;
};

export enum EntityCompanyType {
  Company = 'COMPANY',
  Customer = 'CUSTOMER',
  Subcon = 'SUBCON'
}

export enum EntityCompanyUpdateType {
  Customer = 'CUSTOMER',
  Subcon = 'SUBCON'
}

export type EntityDetails = {
  __typename?: 'EntityDetails';
  driver?: Maybe<Driver>;
  editBy?: Maybe<User>;
  editByDate?: Maybe<Scalars['DateTime']['output']>;
  editByUuid?: Maybe<Scalars['UUID']['output']>;
  entityUuid?: Maybe<Scalars['UUID']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  trailer?: Maybe<Trailer>;
  vehicle?: Maybe<Vehicle>;
};

export type EntityDocumentRequirement = {
  __typename?: 'EntityDocumentRequirement';
  category?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<Maybe<EntityFieldReq>>>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type EntityDocumentRequirementInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Array<InputMaybe<EntityFieldReqInput>>>;
  isRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

export type EntityEvent = {
  __typename?: 'EntityEvent';
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userEmail?: Maybe<Scalars['String']['output']>;
  userUuid?: Maybe<Scalars['UUID']['output']>;
};

export type EntityExceptionOutcome = {
  __typename?: 'EntityExceptionOutcome';
  entityUuid?: Maybe<Scalars['UUID']['output']>;
  exceptions?: Maybe<Array<Maybe<EntityExceptionsItem>>>;
  remark?: Maybe<Scalars['String']['output']>;
  status?: Maybe<EntityExceptionOutcomeStatus>;
};

export enum EntityExceptionOutcomeStatus {
  Fail = 'FAIL',
  Pass = 'PASS',
  Warn = 'WARN'
}

export type EntityExceptions = {
  __typename?: 'EntityExceptions';
  DRIVER?: Maybe<Array<Maybe<EntityExceptionOutcome>>>;
  TRAILER?: Maybe<Array<Maybe<EntityExceptionOutcome>>>;
  VEHICLE?: Maybe<Array<Maybe<EntityExceptionOutcome>>>;
};

export type EntityExceptionsItem = {
  __typename?: 'EntityExceptionsItem';
  category?: Maybe<Scalars['String']['output']>;
  companyUuid?: Maybe<Scalars['UUID']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  entityType?: Maybe<EntityType>;
  entityUuid?: Maybe<Scalars['UUID']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<EntityExceptionOutcomeStatus>;
};

export type EntityField = {
  __typename?: 'EntityField';
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<EntityFieldType>;
  value?: Maybe<Scalars['String']['output']>;
};

export type EntityFieldInput = {
  name: Scalars['String']['input'];
  type: EntityFieldType;
  value: Scalars['String']['input'];
};

export type EntityFieldReq = {
  __typename?: 'EntityFieldReq';
  name?: Maybe<Scalars['String']['output']>;
  selectOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<EntityFieldType>;
  validOptions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type EntityFieldReqInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  selectOptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<EntityFieldType>;
  validOptions?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export enum EntityFieldType {
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  String = 'STRING'
}

export type EntityRequirements = {
  __typename?: 'EntityRequirements';
  DRIVER?: Maybe<EntityCategoryRequirement>;
  TRAILER?: Maybe<EntityCategoryRequirement>;
  VEHICLE?: Maybe<EntityCategoryRequirement>;
};

export enum EntityType {
  Driver = 'DRIVER',
  Trailer = 'TRAILER',
  Vehicle = 'VEHICLE'
}

export type EntityTypeUnion = Driver | Trailer | Vehicle;

export type Event = BookingEvent | ContainerEvent | MessageEvent;

export type EventCreator = {
  __typename?: 'EventCreator';
  email?: Maybe<Scalars['Email']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type EventEtc = {
  __typename?: 'EventEtc';
  action?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  payload?: Maybe<Scalars['JSON']['output']>;
  user?: Maybe<User>;
};

export type EventVoucher = {
  __typename?: 'EventVoucher';
  action?: Maybe<Scalars['String']['output']>;
  context?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  payload?: Maybe<Scalars['JSON']['output']>;
  user?: Maybe<User>;
};

export type EventVouchers = {
  __typename?: 'EventVouchers';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<EventVoucher>>>;
};

export type EventsEtc = {
  __typename?: 'EventsEtc';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<EventEtc>>>;
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  company?: Maybe<Company>;
  end?: Maybe<Scalars['DateTime']['output']>;
  from?: Maybe<Currency>;
  rate?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
  to?: Maybe<Currency>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type ExchangeRates = {
  __typename?: 'ExchangeRates';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<ExchangeRate>>>;
};

export type Faf = {
  __typename?: 'Faf';
  customer?: Maybe<Company>;
  customerUuid?: Maybe<Scalars['UUID']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  vendor?: Maybe<Company>;
  vendorUuid?: Maybe<Scalars['UUID']['output']>;
};

export type Fafs = {
  __typename?: 'Fafs';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Faf>>>;
};

export type FmsCompany = {
  __typename?: 'FmsCompany';
  BranchCode?: Maybe<Scalars['String']['output']>;
  CompanyCode?: Maybe<Scalars['String']['output']>;
  CustomerAccountCode?: Maybe<Scalars['String']['output']>;
  CustomerCode?: Maybe<Scalars['String']['output']>;
  CustomerDebtorCode?: Maybe<Scalars['String']['output']>;
  CustomerName?: Maybe<Scalars['String']['output']>;
  CustomerType?: Maybe<Scalars['String']['output']>;
  IsSuspend?: Maybe<Scalars['Boolean']['output']>;
  Remarks?: Maybe<Scalars['String']['output']>;
};

export type Fuel = {
  __typename?: 'Fuel';
  _id?: Maybe<Scalars['UUID']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  amountRemaining?: Maybe<Scalars['Float']['output']>;
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  approvedBy?: Maybe<User>;
  approvedUuid?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  createdUuid?: Maybe<Scalars['UUID']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  driver?: Maybe<Driver>;
  driverUuid: Scalars['UUID']['output'];
  odometer?: Maybe<Scalars['Int']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  ref?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  status: FuelStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  updatedUuid?: Maybe<Scalars['UUID']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
  vehicle?: Maybe<Vehicle>;
  vehicleUuid: Scalars['UUID']['output'];
  vendor?: Maybe<Scalars['String']['output']>;
};

export type FuelQueryInput = {
  driverUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  end: Scalars['DateTime']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<FuelStatus>>>;
  vehicleUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export enum FuelStatus {
  Approved = 'APPROVED',
  Cancelled = 'CANCELLED',
  Created = 'CREATED'
}

export type Fuels = {
  __typename?: 'Fuels';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Fuel>>>;
};

export type GateLog = {
  __typename?: 'GateLog';
  _id?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  createdUuid?: Maybe<Scalars['UUID']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  driver?: Maybe<Driver>;
  driverUuid?: Maybe<Scalars['UUID']['output']>;
  odometer?: Maybe<Scalars['Int']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  status?: Maybe<GateLogStatus>;
  trailer?: Maybe<Trailer>;
  trailerUuid?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<GateLogType>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  updatedUuid?: Maybe<Scalars['UUID']['output']>;
  vehicle?: Maybe<Vehicle>;
  vehicleUuid?: Maybe<Scalars['UUID']['output']>;
  yard?: Maybe<Yard>;
  yardUuid?: Maybe<Scalars['UUID']['output']>;
};

export type GateLogQueryInput = {
  driverUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<GateLogStatus>>>;
  trailerUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  types?: InputMaybe<Array<InputMaybe<GateLogType>>>;
  vehicleUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  yardUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export enum GateLogStatus {
  Cancelled = 'CANCELLED',
  Created = 'CREATED'
}

export enum GateLogType {
  In = 'IN',
  Out = 'OUT'
}

export type GateLogs = {
  __typename?: 'GateLogs';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<GateLog>>>;
};

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Other = 'OTHER'
}

export type GenerateReportInput = {
  match?: InputMaybe<Scalars['JSON']['input']>;
  reportUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export enum GenericDynamicFieldQuery {
  Company = 'COMPANY',
  CustomValues = 'CUSTOM_VALUES',
  Enums = 'ENUMS'
}

export type Geocode = {
  __typename?: 'Geocode';
  address?: Maybe<ReverseGeoAddress>;
  id?: Maybe<Scalars['String']['output']>;
  resultType?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

export type Geocodes = {
  __typename?: 'Geocodes';
  items?: Maybe<Array<Maybe<Geocode>>>;
};

export type Geometry = {
  __typename?: 'Geometry';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GeometryPoint = {
  __typename?: 'GeometryPoint';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

export type GeometryPolygon = {
  __typename?: 'GeometryPolygon';
  coordinates?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']['output']>>>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type GlCode = {
  __typename?: 'GlCode';
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  status?: Maybe<GlCodeStatus>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum GlCodeStatus {
  Activated = 'activated',
  Deleted = 'deleted'
}

export type GlCodes = {
  __typename?: 'GlCodes';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<GlCode>>>;
};

export type GoogleGeometry = {
  __typename?: 'GoogleGeometry';
  location?: Maybe<GeometryPoint>;
};

export type Group = {
  __typename?: 'Group';
  company?: Maybe<Company>;
  employees?: Maybe<Array<Maybe<Employee>>>;
  name?: Maybe<Scalars['String']['output']>;
  ruleCondition?: Maybe<RuleCondition>;
  rules?: Maybe<Array<Maybe<GroupRule>>>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type GroupRule = {
  __typename?: 'GroupRule';
  id?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  type?: Maybe<GroupRuleType>;
};

export type GroupRuleInput = {
  id: Scalars['String']['input'];
  type: GroupRuleType;
};

export enum GroupRuleType {
  Company = 'company',
  ImportOrExport = 'importOrExport',
  JobCategory = 'jobCategory',
  Port = 'port',
  ShipmentType = 'shipmentType'
}

export type Groups = {
  __typename?: 'Groups';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Group>>>;
};

export type ImpCustConsigneeDraftNotification = {
  __typename?: 'ImpCustConsigneeDraftNotification';
  bl?: Maybe<Scalars['String']['output']>;
  consignee?: Maybe<Scalars['String']['output']>;
  containers?: Maybe<Scalars['String']['output']>;
  customer?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<BookingDocument>>>;
  receiverGreeting?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
};

export type Incentive = {
  __typename?: 'Incentive';
  _id?: Maybe<Scalars['UUID']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  bookingUuid?: Maybe<Scalars['UUID']['output']>;
  costCode?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  createdUuid?: Maybe<Scalars['UUID']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  driver?: Maybe<Driver>;
  driverCode?: Maybe<Scalars['String']['output']>;
  driverName?: Maybe<Scalars['String']['output']>;
  driverUuid?: Maybe<Scalars['UUID']['output']>;
  incentiveVoucher?: Maybe<IncentiveVoucher>;
  incentiveVoucherUuid?: Maybe<Scalars['UUID']['output']>;
  jobUuid?: Maybe<Scalars['UUID']['output']>;
  legUuid?: Maybe<Scalars['UUID']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  shift?: Maybe<Shift>;
  shiftUuid?: Maybe<Scalars['UUID']['output']>;
  status?: Maybe<IncentiveStatus>;
  transportJob?: Maybe<TransportJob>;
  tripUuid?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<IncentiveType>;
  typeUuid?: Maybe<Scalars['UUID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  updatedUuid?: Maybe<Scalars['UUID']['output']>;
  vehicle?: Maybe<Vehicle>;
  vehicleCode?: Maybe<Scalars['String']['output']>;
  vehicleName?: Maybe<Scalars['String']['output']>;
  vehicleUuid?: Maybe<Scalars['UUID']['output']>;
  voucher?: Maybe<Voucher>;
  voucherUuid?: Maybe<Scalars['UUID']['output']>;
};

export type IncentiveCategorySum = {
  __typename?: 'IncentiveCategorySum';
  amount?: Maybe<Scalars['Float']['output']>;
  category?: Maybe<Scalars['String']['output']>;
};

export type IncentiveQueryInput = {
  approved?: InputMaybe<Scalars['Boolean']['input']>;
  approvedFinance?: InputMaybe<Scalars['Boolean']['input']>;
  bookingUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  driverUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  hasVoucher?: InputMaybe<Scalars['Boolean']['input']>;
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  legUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  shiftUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<IncentiveStatus>>>;
  tripUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type IncentiveRate = {
  __typename?: 'IncentiveRate';
  _id?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  createdUuid?: Maybe<Scalars['UUID']['output']>;
  distance?: Maybe<Scalars['Int']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  rates?: Maybe<Array<Maybe<IncentiveRateDetails>>>;
  to?: Maybe<Scalars['String']['output']>;
  transportType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uom?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  updatedUuid?: Maybe<Scalars['UUID']['output']>;
};

export type IncentiveRateDetails = {
  __typename?: 'IncentiveRateDetails';
  cargoType?: Maybe<TransportRateCargoTypes>;
  customer?: Maybe<Company>;
  customerUuid?: Maybe<Scalars['UUID']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  rateType?: Maybe<ChargeRateType>;
  size?: Maybe<Scalars['Float']['output']>;
  transactionType?: Maybe<TransportRateTransactionType>;
  transportType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type IncentiveRateQueryInput = {
  from?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  to?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type IncentiveRates = {
  __typename?: 'IncentiveRates';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<IncentiveRate>>>;
};

export enum IncentiveStatus {
  Approved = 'APPROVED',
  Approvedfinance = 'APPROVEDFINANCE',
  Cancelled = 'CANCELLED',
  Deleted = 'DELETED',
  Generated = 'GENERATED'
}

export type IncentiveType = {
  __typename?: 'IncentiveType';
  _id?: Maybe<Scalars['UUID']['output']>;
  category?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  createdUuid?: Maybe<Scalars['UUID']['output']>;
  details?: Maybe<Scalars['String']['output']>;
  glCode?: Maybe<GlCode>;
  glCodeUuid?: Maybe<Scalars['UUID']['output']>;
  isJobRequired?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  updatedUuid?: Maybe<Scalars['UUID']['output']>;
};

export type IncentiveTypeQueryInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  shiftUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type IncentiveTypeSum = {
  __typename?: 'IncentiveTypeSum';
  amount?: Maybe<Scalars['Float']['output']>;
  typeUuid?: Maybe<Scalars['UUID']['output']>;
};

export type IncentiveTypes = {
  __typename?: 'IncentiveTypes';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<IncentiveType>>>;
};

export type IncentiveVoucher = {
  __typename?: 'IncentiveVoucher';
  _id?: Maybe<Scalars['UUID']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  approvedBy?: Maybe<User>;
  approvedFinanceAt?: Maybe<Scalars['DateTime']['output']>;
  approvedFinanceBy?: Maybe<User>;
  approvedFinanceUuid?: Maybe<Scalars['UUID']['output']>;
  approvedUuid?: Maybe<Scalars['UUID']['output']>;
  bookingUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  createdUuid?: Maybe<Scalars['UUID']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  driverUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  drivers?: Maybe<Array<Maybe<Driver>>>;
  incentiveCategorySum?: Maybe<Array<Maybe<IncentiveCategorySum>>>;
  incentiveTypeSum?: Maybe<Array<Maybe<IncentiveTypeSum>>>;
  incentiveTypeUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  incentiveUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  incentives?: Maybe<Array<Maybe<Incentive>>>;
  jobUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  legUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  status?: Maybe<IncentiveVoucherStatus>;
  tripUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  updatedUuid?: Maybe<Scalars['UUID']['output']>;
  vehicleUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  vehicles?: Maybe<Array<Maybe<Vehicle>>>;
  voucherNo?: Maybe<Scalars['String']['output']>;
};

export type IncentiveVoucherQueryInput = {
  bookingUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  driverUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  incentiveTypeUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  jsonQueryOptions?: InputMaybe<Array<InputMaybe<IncentiveVoucherQueryOptions>>>;
  legUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<IncentiveVoucherStatus>>>;
  tripUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  vehicleUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export enum IncentiveVoucherQueryOptions {
  Drivers = 'drivers',
  IncentiveTypes = 'incentiveTypes',
  Incentives = 'incentives',
  TransportJobs = 'transportJobs',
  Users = 'users',
  Vehicles = 'vehicles'
}

export enum IncentiveVoucherStatus {
  Approved = 'APPROVED',
  Approvedfinance = 'APPROVEDFINANCE',
  Cancelled = 'CANCELLED',
  Draft = 'DRAFT'
}

export type IncentiveVouchers = {
  __typename?: 'IncentiveVouchers';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<IncentiveVoucher>>>;
};

export type Incentives = {
  __typename?: 'Incentives';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Incentive>>>;
};

export type InputIncentiveRateDetails = {
  cargoType?: InputMaybe<TransportRateCargoTypes>;
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  rateType?: InputMaybe<ChargeRateType>;
  size?: InputMaybe<Scalars['Float']['input']>;
  transactionType?: InputMaybe<TransportRateTransactionType>;
  transportType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type InputTransportRateDetails = {
  cargoType?: InputMaybe<TransportRateCargoTypes>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  rateRule?: InputMaybe<ChargeRateRuleType>;
  rateType?: InputMaybe<ChargeRateType>;
  rateUom?: InputMaybe<Scalars['String']['input']>;
  rateValue?: InputMaybe<Scalars['Float']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  transactionType?: InputMaybe<TransportRateTransactionType>;
  transportType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type Integration = {
  __typename?: 'Integration';
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type IntegrationDetail = {
  __typename?: 'IntegrationDetail';
  company?: Maybe<Company>;
  integration?: Maybe<Integration>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  meta?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type IntegrationDetails = {
  __typename?: 'IntegrationDetails';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<IntegrationDetail>>>;
};

export type IntegrationLog = {
  __typename?: 'IntegrationLog';
  baseCompany?: Maybe<BaseCompany>;
  bookingUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  costItemUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  integrationDetail?: Maybe<IntegrationDetail>;
  receiveDetails?: Maybe<Scalars['String']['output']>;
  receiveType?: Maybe<IntegrationLogDetailsType>;
  sendDetails?: Maybe<Scalars['String']['output']>;
  sendType?: Maybe<IntegrationLogDetailsType>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  voucherUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
};

export enum IntegrationLogDetailsType {
  Json = 'JSON',
  String = 'STRING',
  Xml = 'XML'
}

export type IntegrationLogs = {
  __typename?: 'IntegrationLogs';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<IntegrationLog>>>;
};

export type IntegrationLogsQuery = {
  __typename?: 'IntegrationLogsQuery';
  bookingUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  costItemUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
  voucherUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
};

export type IntegrationMapping = {
  __typename?: 'IntegrationMapping';
  customer?: Maybe<Company>;
  externalCode?: Maybe<Scalars['String']['output']>;
  integrationDetailUuid?: Maybe<Scalars['UUID']['output']>;
  internalCode?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type IntegrationMappings = {
  __typename?: 'IntegrationMappings';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<IntegrationMapping>>>;
};

export type Integrations = {
  __typename?: 'Integrations';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Integration>>>;
};

export type InviteCompanyAccess = {
  __typename?: 'InviteCompanyAccess';
  acceptedAt?: Maybe<Scalars['DateTime']['output']>;
  acceptedBy?: Maybe<User>;
  companyUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  emails?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type InviteCompanyAccessInput = {
  companyUuids: Array<InputMaybe<Scalars['UUID']['input']>>;
  emails: Array<InputMaybe<Scalars['String']['input']>>;
  uuid: Scalars['UUID']['input'];
};

export type InviteCompanyAccesses = {
  __typename?: 'InviteCompanyAccesses';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<InviteCompanyAccess>>>;
};

export type InvitePortalCompanyInput = {
  portalCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  users?: InputMaybe<Array<InputMaybe<InviteUser>>>;
};

export type InviteUser = {
  email: Scalars['Email']['input'];
  name: Scalars['String']['input'];
  roles: Array<InputMaybe<Scalars['String']['input']>>;
};

export type InviteUserResponse = {
  __typename?: 'InviteUserResponse';
  email?: Maybe<Scalars['Email']['output']>;
  errorMessage?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  user?: Maybe<User>;
};

export type InvitedBaseCompany = {
  __typename?: 'InvitedBaseCompany';
  baseCompanyUuid?: Maybe<Scalars['UUID']['output']>;
  inviteUuid?: Maybe<Scalars['UUID']['output']>;
};

export type Job = {
  __typename?: 'Job';
  bookingUuid?: Maybe<Scalars['UUID']['output']>;
  details?: Maybe<Scalars['JSON']['output']>;
  expReadyForCollectionDate?: Maybe<Scalars['DateTime']['output']>;
  expRequestOfTransportDate?: Maybe<Scalars['DateTime']['output']>;
  impReadyForCollectionDate?: Maybe<Scalars['DateTime']['output']>;
  impRequestOfTransportDate?: Maybe<Scalars['DateTime']['output']>;
  jobNo?: Maybe<Scalars['String']['output']>;
  no?: Maybe<Scalars['String']['output']>;
  reference?: Maybe<JobReferences>;
  remarks?: Maybe<Scalars['String']['output']>;
  state?: Maybe<JobState>;
  status?: Maybe<JobStatus>;
  tripFormat?: Maybe<TripFormat>;
  trips?: Maybe<Array<Maybe<Trip>>>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type JobInput = {
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  jobNo?: InputMaybe<Scalars['String']['input']>;
  no?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<JobReferencesInput>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  status: JobStatus;
  tripFormat?: InputMaybe<Scalars['String']['input']>;
  trips?: InputMaybe<Array<InputMaybe<TripInput>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type JobOption = {
  __typename?: 'JobOption';
  baseCompanyUuid?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sorting?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum JobOptionStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type JobOptions = {
  __typename?: 'JobOptions';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<JobOption>>>;
};

export type JobReferences = {
  __typename?: 'JobReferences';
  consignee?: Maybe<Scalars['String']['output']>;
  forwardingDest?: Maybe<Scalars['String']['output']>;
  forwardingOri?: Maybe<Scalars['String']['output']>;
  haulage?: Maybe<Scalars['String']['output']>;
  shipper?: Maybe<Scalars['String']['output']>;
  transport?: Maybe<Scalars['String']['output']>;
};

export type JobReferencesInput = {
  consignee?: InputMaybe<Scalars['String']['input']>;
  forwardingDest?: InputMaybe<Scalars['String']['input']>;
  forwardingOri?: InputMaybe<Scalars['String']['input']>;
  haulage?: InputMaybe<Scalars['String']['input']>;
  shipper?: InputMaybe<Scalars['String']['input']>;
  transport?: InputMaybe<Scalars['String']['input']>;
};

export enum JobState {
  Complete = 'COMPLETE',
  Enroute = 'ENROUTE',
  Pending = 'PENDING'
}

export enum JobStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export type JobSummaries = {
  __typename?: 'JobSummaries';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<JobSummary>>>;
};

export type JobSummariesQueryInput = {
  baseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  billToSources?: InputMaybe<Array<InputMaybe<TransportSource>>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  transportSources?: InputMaybe<Array<InputMaybe<TransportSource>>>;
};

export type JobSummary = {
  __typename?: 'JobSummary';
  baseCompanyUuid?: Maybe<Scalars['UUID']['output']>;
  billToSource?: Maybe<TransportSource>;
  date?: Maybe<Scalars['DateTime']['output']>;
  dept?: Maybe<Scalars['JSON']['output']>;
  emptyCost?: Maybe<Scalars['Float']['output']>;
  emptyCount?: Maybe<Scalars['Int']['output']>;
  emptyUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  from?: Maybe<Scalars['String']['output']>;
  ladenCount?: Maybe<Scalars['Int']['output']>;
  ladenUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  to?: Maybe<Scalars['String']['output']>;
  transportSource?: Maybe<TransportSource>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type JobTripUpdateInput = {
  details?: InputMaybe<Scalars['JSON']['input']>;
  fromCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  fromUuid?: InputMaybe<Scalars['UUID']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  seal?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TripStatus>;
  toCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  toUuid?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  waybillId?: InputMaybe<Scalars['String']['input']>;
};

export type JobType = {
  __typename?: 'JobType';
  code?: Maybe<Scalars['String']['output']>;
  companies?: Maybe<Array<Maybe<Company>>>;
  computeType?: Maybe<JobTypeComputeType>;
  defaultTripFormat?: Maybe<TripFormat>;
  description?: Maybe<Scalars['String']['output']>;
  dynamicFields?: Maybe<Array<Maybe<BookingTypeDynamicField>>>;
  name?: Maybe<Scalars['String']['output']>;
  requiredFields?: Maybe<Array<Maybe<JobTypeRequiredFields>>>;
  sorting?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<JobTypeStatus>;
  tripDetails?: Maybe<Array<Maybe<JobTypeDetails>>>;
  tripDynamicFields?: Maybe<Array<Maybe<BookingTypeDynamicField>>>;
  unitOptions?: Maybe<Array<Maybe<JobTypeUnitOptions>>>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum JobTypeComputeType {
  AllTrips = 'ALL_TRIPS',
  FirstTrip = 'FIRST_TRIP',
  LastTrip = 'LAST_TRIP'
}

export type JobTypeDetails = {
  __typename?: 'JobTypeDetails';
  fromCompanyTypes?: Maybe<Array<Maybe<CompanyType>>>;
  toCompanyTypes?: Maybe<Array<Maybe<CompanyType>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type JobTypeDetailsInput = {
  fromCompanyTypes?: InputMaybe<Array<InputMaybe<CompanyType>>>;
  toCompanyTypes?: InputMaybe<Array<InputMaybe<CompanyType>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export enum JobTypeDynamicFieldControl {
  Optional = 'OPTIONAL',
  Required = 'REQUIRED'
}

export enum JobTypeRequiredFieldControl {
  Optional = 'OPTIONAL',
  Required = 'REQUIRED'
}

export enum JobTypeRequiredFieldTypes {
  ContainerGrade = 'containerGrade',
  ContainerNo = 'containerNo',
  ContainerType = 'containerType',
  No = 'no',
  Options = 'options',
  RefUnit = 'refUnit',
  RefUnitAmount = 'refUnitAmount',
  Seal = 'seal',
  Size = 'size',
  TransportType = 'transportType',
  TripReferences = 'tripReferences',
  TripSeal = 'tripSeal',
  Type = 'type',
  Volume = 'volume',
  Weight = 'weight'
}

export type JobTypeRequiredFields = {
  __typename?: 'JobTypeRequiredFields';
  control?: Maybe<JobTypeRequiredFieldControl>;
  name?: Maybe<JobTypeRequiredFieldTypes>;
};

export type JobTypeRequiredInput = {
  control?: InputMaybe<JobTypeRequiredFieldControl>;
  name?: InputMaybe<JobTypeRequiredFieldTypes>;
};

export enum JobTypeStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type JobTypeUnitOptions = {
  __typename?: 'JobTypeUnitOptions';
  units?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  values?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
};

export type JobTypeUnitOptionsInput = {
  units?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  values?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
};

export type JobTypes = {
  __typename?: 'JobTypes';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<JobType>>>;
};

export type Jobs = {
  __typename?: 'Jobs';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Job>>>;
};

export type JobsJson = {
  __typename?: 'JobsJson';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Scalars['JSON']['output']>;
};

export type JobsSearchFilter = {
  bookingUuid?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  companyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  shipConCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type JobsSearchInput = {
  filter?: InputMaybe<JobsSearchFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type Leg = {
  __typename?: 'Leg';
  activityType?: Maybe<LegTimeType>;
  assistantUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  assistants?: Maybe<Array<Maybe<Driver>>>;
  bookingUuid?: Maybe<Scalars['UUID']['output']>;
  dFirstTs?: Maybe<Scalars['String']['output']>;
  dLastTs?: Maybe<Scalars['String']['output']>;
  driverCode?: Maybe<Scalars['String']['output']>;
  driverEntity?: Maybe<Driver>;
  driverIc?: Maybe<Scalars['String']['output']>;
  driverName?: Maybe<Scalars['String']['output']>;
  driverPhoneNo?: Maybe<Scalars['String']['output']>;
  driverUuid?: Maybe<Scalars['UUID']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  endOut?: Maybe<Scalars['DateTime']['output']>;
  firstTs?: Maybe<Scalars['DateTime']['output']>;
  jobUuid?: Maybe<Scalars['UUID']['output']>;
  lastTs?: Maybe<Scalars['DateTime']['output']>;
  legStatus?: Maybe<LegStatus>;
  legUuid?: Maybe<Scalars['UUID']['output']>;
  planEnd?: Maybe<Scalars['DateTime']['output']>;
  planStart?: Maybe<Scalars['DateTime']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['Int']['output']>;
  sequenceTotal?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
  startOut?: Maybe<Scalars['DateTime']['output']>;
  tLastTs?: Maybe<Scalars['String']['output']>;
  trailerCode?: Maybe<Scalars['String']['output']>;
  trailerEntity?: Maybe<Trailer>;
  trailerName?: Maybe<Scalars['String']['output']>;
  trailerUuid?: Maybe<Scalars['UUID']['output']>;
  transportName?: Maybe<Scalars['String']['output']>;
  transportSource?: Maybe<TransportSource>;
  transportUuid?: Maybe<Scalars['UUID']['output']>;
  tripUuid?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<LegType>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  vFirstTs?: Maybe<Scalars['String']['output']>;
  vLastTs?: Maybe<Scalars['String']['output']>;
  vehicleCode?: Maybe<Scalars['String']['output']>;
  vehicleDepartments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleEntity?: Maybe<Vehicle>;
  vehicleName?: Maybe<Scalars['String']['output']>;
  vehicleUuid?: Maybe<Scalars['UUID']['output']>;
  yard?: Maybe<Yard>;
  yardUuid?: Maybe<Scalars['UUID']['output']>;
};

export type LegActivities = {
  __typename?: 'LegActivities';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<LegActivity>>>;
};

export type LegActivitiesQuery = {
  legUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type LegActivity = {
  __typename?: 'LegActivity';
  _id?: Maybe<Scalars['UUID']['output']>;
  addAfterSequence?: Maybe<Scalars['Int']['output']>;
  address?: Maybe<Scalars['JSON']['output']>;
  assistantUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  bookingUuid?: Maybe<Scalars['UUID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  dFirstTs?: Maybe<Scalars['String']['output']>;
  dLastTs?: Maybe<Scalars['String']['output']>;
  driverCode?: Maybe<Scalars['String']['output']>;
  driverEntity?: Maybe<Driver>;
  driverIc?: Maybe<Scalars['String']['output']>;
  driverName?: Maybe<Scalars['String']['output']>;
  driverPhoneNo?: Maybe<Scalars['String']['output']>;
  driverUuid?: Maybe<Scalars['UUID']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  endOut?: Maybe<Scalars['DateTime']['output']>;
  firstTs?: Maybe<Scalars['DateTime']['output']>;
  isDriver?: Maybe<Scalars['Boolean']['output']>;
  jobUuid?: Maybe<Scalars['UUID']['output']>;
  lastTs?: Maybe<Scalars['DateTime']['output']>;
  legStatus?: Maybe<LegStatus>;
  legUuid?: Maybe<Scalars['UUID']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  planEnd?: Maybe<Scalars['DateTime']['output']>;
  planStart?: Maybe<Scalars['DateTime']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['Int']['output']>;
  sequenceTotal?: Maybe<Scalars['Int']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
  startOut?: Maybe<Scalars['DateTime']['output']>;
  tLastTs?: Maybe<Scalars['String']['output']>;
  trailerCode?: Maybe<Scalars['String']['output']>;
  trailerEntity?: Maybe<Trailer>;
  trailerName?: Maybe<Scalars['String']['output']>;
  trailerUuid?: Maybe<Scalars['UUID']['output']>;
  transportName?: Maybe<Scalars['String']['output']>;
  transportSource?: Maybe<TransportSource>;
  transportUuid?: Maybe<Scalars['UUID']['output']>;
  triggerApi?: Maybe<Scalars['Int']['output']>;
  tripUuid?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<LegType>;
  userUuid?: Maybe<Scalars['UUID']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  vFirstTs?: Maybe<Scalars['String']['output']>;
  vLastTs?: Maybe<Scalars['String']['output']>;
  vehicleCode?: Maybe<Scalars['String']['output']>;
  vehicleEntity?: Maybe<Vehicle>;
  vehicleName?: Maybe<Scalars['String']['output']>;
  vehicleUuid?: Maybe<Scalars['UUID']['output']>;
  yard?: Maybe<Yard>;
  yardUuid?: Maybe<Scalars['UUID']['output']>;
};

export type LegInput = {
  driverId?: InputMaybe<Scalars['String']['input']>;
  driverName?: InputMaybe<Scalars['String']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  endOut?: InputMaybe<Scalars['DateTime']['input']>;
  from?: InputMaybe<AddressInput>;
  planStart?: InputMaybe<Scalars['DateTime']['input']>;
  pmId?: InputMaybe<Scalars['String']['input']>;
  pmName?: InputMaybe<Scalars['String']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  seal?: InputMaybe<Scalars['String']['input']>;
  sequence?: InputMaybe<Scalars['Float']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  startOut?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<AddressInput>;
  trailer?: InputMaybe<Scalars['String']['input']>;
  trailerId?: InputMaybe<Scalars['String']['input']>;
  tripReferences?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tripRemarks?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export enum LegStatus {
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Enroute = 'ENROUTE',
  Pending = 'PENDING',
  Planned = 'PLANNED'
}

export type LegSummaries = {
  __typename?: 'LegSummaries';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<LegSummary>>>;
};

export type LegSummariesQueryInput = {
  baseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  billToSources?: InputMaybe<Array<InputMaybe<TransportSource>>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  transportSources?: InputMaybe<Array<InputMaybe<TransportSource>>>;
};

export type LegSummary = {
  __typename?: 'LegSummary';
  baseCompanyUuid?: Maybe<Scalars['UUID']['output']>;
  billToSource?: Maybe<TransportSource>;
  date?: Maybe<Scalars['DateTime']['output']>;
  dept?: Maybe<Scalars['JSON']['output']>;
  emptyCost?: Maybe<Scalars['Float']['output']>;
  emptyCount?: Maybe<Scalars['Int']['output']>;
  emptyLegs?: Maybe<EmptyTrips>;
  emptyUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  from?: Maybe<Scalars['String']['output']>;
  ladenCount?: Maybe<Scalars['Int']['output']>;
  ladenLegs?: Maybe<Array<Maybe<TransportJob>>>;
  ladenUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  to?: Maybe<Scalars['String']['output']>;
  transportSource?: Maybe<TransportSource>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum LegTimeType {
  End = 'end',
  EndOut = 'endOut',
  PlanEnd = 'planEnd',
  PlanStart = 'planStart',
  Start = 'start',
  StartOut = 'startOut'
}

export enum LegType {
  Break = 'BREAK',
  Delivery = 'DELIVERY',
  Stage = 'STAGE'
}

export type Location = {
  __typename?: 'Location';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type LocationInput = {
  coordinates?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type MakeBookingCommentInput = {
  message: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};

export type MakeBookingInput = {
  billToUuid?: InputMaybe<Scalars['UUID']['input']>;
  chronologies?: InputMaybe<Array<InputMaybe<ChronologyNewBookingInput>>>;
  commodities?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  jobs?: InputMaybe<Array<InputMaybe<AddJobInput>>>;
  override?: InputMaybe<Scalars['Boolean']['input']>;
  quotationUuid?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  serviceTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};

export type Manifest = {
  __typename?: 'Manifest';
  driver?: Maybe<Driver>;
  driverUuid?: Maybe<Scalars['UUID']['output']>;
  endLocation?: Maybe<ManifestLocation>;
  endTime?: Maybe<Scalars['DateTime']['output']>;
  legs?: Maybe<Array<Maybe<TransportJob>>>;
  no?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  startLocation?: Maybe<ManifestLocation>;
  startTime?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<ManifestStatus>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  vehicle?: Maybe<Vehicle>;
  vehicleUuid?: Maybe<Scalars['UUID']['output']>;
};

export enum ManifestLegAction {
  EndIn = 'endIn',
  EndOut = 'endOut',
  StartIn = 'startIn',
  StartOut = 'startOut'
}

export type ManifestLocation = {
  __typename?: 'ManifestLocation';
  name?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type ManifestLocationInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export enum ManifestStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  Empty = 'EMPTY'
}

export type Manifests = {
  __typename?: 'Manifests';
  count?: Maybe<Scalars['Int']['output']>;
  rows?: Maybe<Array<Maybe<Manifest>>>;
};

export type MessageEvent = {
  __typename?: 'MessageEvent';
  context?: Maybe<Scalars['String']['output']>;
  event?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  time?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<EventCreator>;
};

export type MessageEventPayload = {
  __typename?: 'MessageEventPayload';
  message?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type Mutation = {
  __typename?: 'Mutation';
  _computeReportJobSummary?: Maybe<Response>;
  _emptyTripsDataPatch?: Maybe<Response>;
  _jobsConvertEmptyTrips?: Maybe<Response>;
  _jobsConvertLegs?: Maybe<Response>;
  _jobsDataPatch?: Maybe<Response>;
  _plannerQueueRunner?: Maybe<Response>;
  _transportQueueRunner?: Maybe<Response>;
  _triggerComputeTripSummary?: Maybe<Response>;
  _triggerJobReports?: Maybe<Response>;
  acceptInvitation?: Maybe<Response>;
  addAddress?: Maybe<Address>;
  addBookingCostItem?: Maybe<CostItem>;
  addChargeCategory?: Maybe<ChargeCategory>;
  addChargeItem?: Maybe<ChargeItem>;
  addChargeItemCategory?: Maybe<ChargeItem>;
  addChargeItemCode?: Maybe<ChargeItem>;
  addChargeItemTax?: Maybe<ChargeItem>;
  addContact?: Maybe<Contact>;
  addCurrency?: Maybe<Currency>;
  addEmployeeToDriver?: Maybe<Response>;
  addEmptyTrip?: Maybe<Response>;
  addEntityDocument?: Maybe<DocumentDescription>;
  addJob?: Maybe<Job>;
  addLeg?: Maybe<Response>;
  addLegsToManifest?: Maybe<Response>;
  addManifest?: Maybe<Manifest>;
  addMember?: Maybe<Group>;
  addMonitoringCompany?: Maybe<Company>;
  addPayment?: Maybe<Payment>;
  addPushNotification?: Maybe<Notification>;
  addSuperAdmin?: Maybe<Employee>;
  addTripsToManifest?: Maybe<Response>;
  addVoucherItem?: Maybe<VoucherItem>;
  approve?: Maybe<Approval>;
  approveBookingDocument?: Maybe<BookingDocument>;
  approveFuel?: Maybe<Response>;
  approveIncentiveVoucher?: Maybe<Response>;
  approveIncentiveVoucherFinance?: Maybe<Response>;
  approveShift?: Maybe<Response>;
  approveShiftFinance?: Maybe<Response>;
  assignPortalCompanyReport?: Maybe<Response>;
  cancelFuel?: Maybe<Response>;
  cancelGateLog?: Maybe<Response>;
  cancelIncentiveVoucher?: Maybe<Response>;
  cancelShift?: Maybe<Response>;
  changeBookingCostItemChargeCode?: Maybe<CostItem>;
  changeBookingStatus?: Maybe<Booking>;
  changeConsolVoucherStatus?: Maybe<ConsolVoucher>;
  changeDocumentCreatorStatus?: Maybe<DocumentCreator>;
  changeDocumentCreatorStatusAdvanced?: Maybe<DocumentCreator>;
  changeEmployeeRole?: Maybe<User>;
  changeEmployeeUser?: Maybe<Response>;
  changeVoucherStatus?: Maybe<Voucher>;
  changeVoucherType?: Maybe<Voucher>;
  cloneNodeBranch?: Maybe<Response>;
  closeManifests?: Maybe<Response>;
  closeShift?: Maybe<Shift>;
  computeCost?: Maybe<Array<Maybe<CostItem>>>;
  computeTripSummary?: Maybe<Response>;
  createBaseCompany?: Maybe<BaseCompany>;
  createBaseCompanyInviteCompanyAccess?: Maybe<InvitedBaseCompany>;
  createBillingUnit?: Maybe<BillingUnit>;
  createBookingType?: Maybe<BookingType>;
  createBulkImportFunction?: Maybe<BulkImportFunction>;
  createBulkImportFunctionSetting?: Maybe<Response>;
  createChannel?: Maybe<Channel>;
  createChargeItemMisc?: Maybe<ChargeItemMisc>;
  createCompany?: Maybe<Company>;
  createConsolVoucher?: Maybe<ConsolVoucher>;
  createContainerGrade?: Maybe<ContainerGrade>;
  createContainerType?: Maybe<ContainerType>;
  createCurrency?: Maybe<Currency>;
  createDocumentCreator?: Maybe<DocumentCreator>;
  createDocumentCreatorFromTemplate?: Maybe<DocumentCreator>;
  createDocumentCreatorTemplate?: Maybe<DocumentCreatorTemplate>;
  createDriver?: Maybe<Driver>;
  createExchangeRate?: Maybe<ExchangeRate>;
  createFaf?: Maybe<Faf>;
  createFuel?: Maybe<Fuel>;
  createGateLog?: Maybe<GateLog>;
  createGlCode?: Maybe<GlCode>;
  createGroup?: Maybe<Group>;
  createIncentive?: Maybe<Incentive>;
  createIncentiveRate?: Maybe<IncentiveRate>;
  createIncentiveType?: Maybe<IncentiveType>;
  createIncentiveVoucher?: Maybe<IncentiveVoucher>;
  createIntegrationDetail?: Maybe<IntegrationDetail>;
  createIntegrationMapping?: Maybe<IntegrationMapping>;
  createInviteCompanyAccess?: Maybe<InviteCompanyAccess>;
  createJobOption?: Maybe<JobOption>;
  createJobType?: Maybe<JobType>;
  createPermission?: Maybe<Array<Maybe<Permission>>>;
  createPortalCompany?: Maybe<PortalCompany>;
  createQuotation?: Maybe<Quotation>;
  createQuotationItem?: Maybe<QuotationItem>;
  createReport?: Maybe<Response>;
  createRole?: Maybe<Role>;
  createSetting?: Maybe<BaseCompanySetting>;
  createTax?: Maybe<Tax>;
  createTrailer?: Maybe<Trailer>;
  createTransportAreaCode?: Maybe<TransportAreaCode>;
  createTransportJobType?: Maybe<TransportJobType>;
  createTransportRate?: Maybe<TransportRate>;
  createTransportRateFromBooking?: Maybe<Response>;
  createTransportZone?: Maybe<TransportZone>;
  createVehicle?: Maybe<Vehicle>;
  createVolumeUnit?: Maybe<VolumeUnit>;
  createVoucher?: Maybe<Voucher>;
  createVoucherRevision?: Maybe<Voucher>;
  createWeightUnit?: Maybe<WeightUnit>;
  createYard?: Maybe<Yard>;
  deleteAddress?: Maybe<Response>;
  deleteBaseCompany?: Maybe<Response>;
  deleteBillingUnit?: Maybe<Response>;
  deleteBookingCostItem?: Maybe<Response>;
  deleteBookingDocument?: Maybe<BookingDocument>;
  deleteBookingType?: Maybe<Response>;
  deleteBulkImportFunction?: Maybe<Response>;
  deleteBulkImportFunctionSetting?: Maybe<Response>;
  deleteChannel?: Maybe<Response>;
  deleteChargeCategory?: Maybe<Response>;
  deleteChargeItem?: Maybe<Response>;
  deleteChargeItemCategory?: Maybe<ChargeItem>;
  deleteChargeItemCode?: Maybe<Response>;
  deleteChargeItemMisc?: Maybe<Response>;
  deleteChargeItemTax?: Maybe<Response>;
  deleteCompany?: Maybe<Response>;
  deleteCompanyAccess?: Maybe<Response>;
  deleteContact?: Maybe<Response>;
  deleteContainerGrade?: Maybe<Response>;
  deleteContainerType?: Maybe<Response>;
  deleteCounter?: Maybe<Response>;
  deleteDocumentCreatorTemplate?: Maybe<Response>;
  deleteExchangeRate?: Maybe<Response>;
  deleteFaf?: Maybe<Response>;
  deleteGlCode?: Maybe<Response>;
  deleteIncentive?: Maybe<Response>;
  deleteIncentiveRate?: Maybe<Response>;
  deleteIncentiveType?: Maybe<IncentiveType>;
  deleteIntegrationDetail?: Maybe<Response>;
  deleteIntegrationMapping?: Maybe<Response>;
  deleteJob?: Maybe<Response>;
  deleteJobOption?: Maybe<Response>;
  deleteJobType?: Maybe<Response>;
  deleteLeg?: Maybe<Response>;
  deleteManifests?: Maybe<Response>;
  deleteNodeBranch?: Maybe<Response>;
  deleteNodeConnection?: Maybe<Response>;
  deletePayment?: Maybe<Payment>;
  deletePermission?: Maybe<Array<Maybe<Permission>>>;
  deletePortalCompany?: Maybe<Response>;
  deletePushNotification?: Maybe<Notification>;
  deleteQuotationItem?: Maybe<Response>;
  deleteReport?: Maybe<Response>;
  deleteSetting?: Maybe<Response>;
  deleteTax?: Maybe<Response>;
  deleteTransportAreaCode?: Maybe<Response>;
  deleteTransportJobType?: Maybe<Response>;
  deleteTransportRate?: Maybe<Response>;
  deleteTransportZone?: Maybe<Response>;
  deleteUserPreference?: Maybe<Response>;
  deleteVolumeUnit?: Maybe<Response>;
  deleteVoucherItem?: Maybe<Response>;
  deleteWeightUnit?: Maybe<Response>;
  deleteYard?: Maybe<Response>;
  enableCountry?: Maybe<Country>;
  endTripsInManifest?: Maybe<Response>;
  expireQuotation?: Maybe<Quotation>;
  generateDocumentCreatorNumber?: Maybe<DocumentCreatorReference>;
  generateJobsDev?: Maybe<Response>;
  generateReport?: Maybe<Response>;
  insertChildNode?: Maybe<ChildNode>;
  insertChronology?: Maybe<Chronology>;
  invitePortalCompany?: Maybe<Response>;
  inviteToCompany?: Maybe<Array<Maybe<InviteUserResponse>>>;
  linkCompany?: Maybe<Company>;
  linkDocumentToVoucher?: Maybe<Voucher>;
  linkInviteCompanyAccess?: Maybe<Response>;
  makeBooking?: Maybe<Booking>;
  makeBookingComment?: Maybe<MessageEvent>;
  migrateFmsCharges?: Maybe<Array<Maybe<ChargeItem>>>;
  migrateFmsCompany?: Maybe<Company>;
  openShift?: Maybe<Shift>;
  populateAvailability?: Maybe<Response>;
  processAllJobs?: Maybe<Response>;
  processJob?: Maybe<Response>;
  processReportPortalCompany?: Maybe<Response>;
  processTransportBooking?: Maybe<Array<Maybe<TransportJob>>>;
  processVehiclePlan?: Maybe<Response>;
  purgeQueue?: Maybe<Response>;
  recomputeCost?: Maybe<Response>;
  recomputeLegActivities?: Maybe<Response>;
  reindexJob?: Maybe<Response>;
  rejectInviteCompanyAccess?: Maybe<Response>;
  removeChronology?: Maybe<Response>;
  removeCurrency?: Maybe<Response>;
  removeDriverCompany?: Maybe<Driver>;
  removeEmployee?: Maybe<Response>;
  removeGroup?: Maybe<Response>;
  removeLegsFromManifest?: Maybe<Response>;
  removeMember?: Maybe<Group>;
  removeMonitoringCompany?: Maybe<Response>;
  removeSuperAdmin?: Maybe<Response>;
  removeTrailerCompany?: Maybe<Trailer>;
  removeVehicleCompany?: Maybe<Vehicle>;
  requestApprove?: Maybe<Request>;
  resendInvite?: Maybe<Response>;
  resendInviteCompanyAccess?: Maybe<Response>;
  rolloverJob?: Maybe<Job>;
  rolloverVoucher?: Maybe<Voucher>;
  setBookingAssignee?: Maybe<Response>;
  spliceNodeBranch?: Maybe<Response>;
  submitROT?: Maybe<Request>;
  submitRequest?: Maybe<Request>;
  syncEmployees?: Maybe<Response>;
  triggerBookingEvent?: Maybe<Response>;
  triggerVoucherEvent?: Maybe<Response>;
  unInvitePortalCompany?: Maybe<Response>;
  unapproveFuel?: Maybe<Response>;
  unapproveIncentiveVoucher?: Maybe<Response>;
  unapproveIncentiveVoucherFinance?: Maybe<Response>;
  unapproveShift?: Maybe<Response>;
  unapproveShiftFinance?: Maybe<Response>;
  unassignPortalCompanyReport?: Maybe<Response>;
  uncancelFuel?: Maybe<Response>;
  uncancelGateLog?: Maybe<Response>;
  uncancelIncentiveVoucher?: Maybe<Response>;
  uncancelShift?: Maybe<Response>;
  undeleteBillingUnit?: Maybe<Response>;
  undeleteBookingType?: Maybe<Response>;
  undeleteBulkImportFunction?: Maybe<Response>;
  undeleteContainerGrade?: Maybe<Response>;
  undeleteContainerType?: Maybe<Response>;
  undeleteDocumentCreatorTemplate?: Maybe<Response>;
  undeleteGlCode?: Maybe<Response>;
  undeleteIncentive?: Maybe<Response>;
  undeleteIncentiveType?: Maybe<IncentiveType>;
  undeleteJob?: Maybe<Response>;
  undeleteJobOption?: Maybe<Response>;
  undeleteJobType?: Maybe<Response>;
  undeleteLeg?: Maybe<Response>;
  undeletePortalCompany?: Maybe<Response>;
  undeleteTax?: Maybe<Response>;
  undeleteTransportJobType?: Maybe<Response>;
  undeleteTransportZone?: Maybe<Response>;
  undeleteVolumeUnit?: Maybe<Response>;
  undeleteWeightUnit?: Maybe<Response>;
  undeleteYard?: Maybe<Response>;
  unlinkCompany?: Maybe<Company>;
  unlinkDocumentFromVoucher?: Maybe<Voucher>;
  unsetBookingAssignee?: Maybe<Response>;
  updateAddress?: Maybe<Address>;
  updateAddressLocation?: Maybe<Address>;
  updateAvailability?: Maybe<Response>;
  updateBaseCompany?: Maybe<BaseCompany>;
  updateBillingUnit?: Maybe<BillingUnit>;
  updateBooking?: Maybe<Booking>;
  updateBookingActivities?: Maybe<Booking>;
  updateBookingCostItem?: Maybe<CostItem>;
  updateBookingDocument?: Maybe<BookingDocument>;
  updateBookingType?: Maybe<BookingType>;
  updateBulkImportFunction?: Maybe<BulkImportFunction>;
  updateChannel?: Maybe<Channel>;
  updateChargeCategory?: Maybe<ChargeCategory>;
  updateChargeItem?: Maybe<ChargeItem>;
  updateChargeItemCode?: Maybe<ChargeItem>;
  updateChargeItemMisc?: Maybe<ChargeItemMisc>;
  updateChargeItemTax?: Maybe<ChargeItem>;
  updateChildNode?: Maybe<ChildNode>;
  updateChildNodeStatus?: Maybe<Response>;
  updateChronology?: Maybe<Chronology>;
  updateCompany?: Maybe<Company>;
  updateCompanyCode?: Maybe<Company>;
  updateContact?: Maybe<Contact>;
  updateContainerGrade?: Maybe<ContainerGrade>;
  updateContainerType?: Maybe<ContainerType>;
  updateCurrency?: Maybe<Currency>;
  updateDocumentCreator?: Maybe<DocumentCreator>;
  updateDocumentCreatorTemplate?: Maybe<DocumentCreatorTemplate>;
  updateDriver?: Maybe<Driver>;
  updateDriverCompany?: Maybe<Driver>;
  updateDriverEmployee?: Maybe<Driver>;
  updateDriverImage?: Maybe<Driver>;
  updateDriverStatus?: Maybe<Driver>;
  updateEmptyTrip?: Maybe<Response>;
  updateEmptyTripStatus?: Maybe<Response>;
  updateEntityDocument?: Maybe<DocumentDescription>;
  updateEntityDocumentStatus?: Maybe<DocumentDescription>;
  updateEntityRequirements?: Maybe<Response>;
  updateEntityRequirementsCategory?: Maybe<Response>;
  updateExchangeRate?: Maybe<ExchangeRate>;
  updateFaf?: Maybe<Faf>;
  updateFuel?: Maybe<Fuel>;
  updateGateLog?: Maybe<GateLog>;
  updateGlCode?: Maybe<GlCode>;
  updateGroup?: Maybe<Group>;
  updateIncentive?: Maybe<Incentive>;
  updateIncentiveRate?: Maybe<IncentiveRate>;
  updateIncentiveType?: Maybe<IncentiveType>;
  updateIncentiveVoucher?: Maybe<IncentiveVoucher>;
  updateIntegrationDetail?: Maybe<IntegrationDetail>;
  updateIntegrationMapping?: Maybe<IntegrationMapping>;
  updateJob?: Maybe<Job>;
  updateJobOption?: Maybe<JobOption>;
  updateJobTrips?: Maybe<Response>;
  updateJobType?: Maybe<JobType>;
  updateLeg?: Maybe<Response>;
  updateLegTiming?: Maybe<Response>;
  updatePayment?: Maybe<Payment>;
  updatePortalCompany?: Maybe<PortalCompany>;
  updateQuotation?: Maybe<Quotation>;
  updateQuotationItem?: Maybe<QuotationItem>;
  updateReport?: Maybe<Response>;
  updateRole?: Maybe<Role>;
  updateRootNode?: Maybe<RootNode>;
  updateRootNodeStatus?: Maybe<Response>;
  updateSetting?: Maybe<BaseCompanySetting>;
  updateShift?: Maybe<Shift>;
  updateTax?: Maybe<Tax>;
  updateTrailer?: Maybe<Trailer>;
  updateTrailerCompany?: Maybe<Trailer>;
  updateTrailerImage?: Maybe<Trailer>;
  updateTrailerStatus?: Maybe<Trailer>;
  updateTransportAreaCode?: Maybe<TransportAreaCode>;
  updateTransportJobAddress?: Maybe<Response>;
  updateTransportJobType?: Maybe<TransportJobType>;
  updateTransportRate?: Maybe<TransportRate>;
  updateTransportZone?: Maybe<TransportZone>;
  updateTrip?: Maybe<Response>;
  updateUser?: Maybe<User>;
  updateUserPreference?: Maybe<UserPreference>;
  updateVehicle?: Maybe<Vehicle>;
  updateVehicleCompany?: Maybe<Vehicle>;
  updateVehicleImage?: Maybe<Vehicle>;
  updateVehicleStatus?: Maybe<Vehicle>;
  updateVolumeUnit?: Maybe<VolumeUnit>;
  updateVoucher?: Maybe<Voucher>;
  updateVoucherItem?: Maybe<VoucherItem>;
  updateWeightUnit?: Maybe<WeightUnit>;
  updateYard?: Maybe<Yard>;
  uploadBookingDocument?: Maybe<BookingDocument>;
  verifyBookingDocument?: Maybe<BookingDocument>;
};


export type Mutation_ComputeReportJobSummaryArgs = {
  input?: InputMaybe<ComputeReportJobSummaryInput>;
};


export type Mutation_EmptyTripsDataPatchArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};


export type Mutation_JobsConvertEmptyTripsArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type Mutation_JobsConvertLegsArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};


export type Mutation_JobsDataPatchArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};


export type Mutation_PlannerQueueRunnerArgs = {
  input?: InputMaybe<PlannerQueueRunnerInput>;
};


export type Mutation_TransportQueueRunnerArgs = {
  input?: InputMaybe<TransportQueueRunnerInput>;
};


export type Mutation_TriggerComputeTripSummaryArgs = {
  input?: InputMaybe<TriggerComputeTripSummaryInput>;
};


export type Mutation_TriggerJobReportsArgs = {
  input?: InputMaybe<TriggerJobReportsInput>;
};


export type MutationAcceptInvitationArgs = {
  password?: InputMaybe<Scalars['Password']['input']>;
  userToken?: InputMaybe<Scalars['String']['input']>;
};


export type MutationAddAddressArgs = {
  input: AddAddressInput;
};


export type MutationAddBookingCostItemArgs = {
  input: AddCostItemInput;
};


export type MutationAddChargeCategoryArgs = {
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
  sequence?: InputMaybe<Scalars['String']['input']>;
  type: ChargeCategoryType;
};


export type MutationAddChargeItemArgs = {
  input: AddChargeItemInput;
};


export type MutationAddChargeItemCategoryArgs = {
  chargeCategoryUuid: Scalars['UUID']['input'];
  chargeItemUuid: Scalars['UUID']['input'];
};


export type MutationAddChargeItemCodeArgs = {
  input: AddChargeItemCodeInput;
};


export type MutationAddChargeItemTaxArgs = {
  input: AddChargeItemTaxInput;
};


export type MutationAddContactArgs = {
  input: AddContactInput;
};


export type MutationAddCurrencyArgs = {
  baseCompanyUuid: Scalars['UUID']['input'];
  currencyUuid: Scalars['UUID']['input'];
};


export type MutationAddEmployeeToDriverArgs = {
  driverUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid: Scalars['UUID']['input'];
};


export type MutationAddEmptyTripArgs = {
  input?: InputMaybe<EmptyTripAddInput>;
};


export type MutationAddEntityDocumentArgs = {
  input: AddEntityDocumentInput;
};


export type MutationAddJobArgs = {
  input: AddJobInput;
};


export type MutationAddLegArgs = {
  input: AddLegInput;
};


export type MutationAddLegsToManifestArgs = {
  input?: InputMaybe<AddManifestLegsInput>;
};


export type MutationAddManifestArgs = {
  input?: InputMaybe<AddManifestInput>;
};


export type MutationAddMemberArgs = {
  employeeUuid: Scalars['UUID']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationAddMonitoringCompanyArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationAddPaymentArgs = {
  input: AddPaymentInput;
};


export type MutationAddPushNotificationArgs = {
  input?: InputMaybe<NotificationInput>;
};


export type MutationAddSuperAdminArgs = {
  email: Scalars['Email']['input'];
};


export type MutationAddTripsToManifestArgs = {
  input?: InputMaybe<AddManifestTripsInput>;
};


export type MutationAddVoucherItemArgs = {
  input: AddVoucherItemInput;
};


export type MutationApproveArgs = {
  input: ApprovalInput;
};


export type MutationApproveBookingDocumentArgs = {
  type: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationApproveFuelArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationApproveIncentiveVoucherArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationApproveIncentiveVoucherFinanceArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationApproveShiftArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationApproveShiftFinanceArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationAssignPortalCompanyReportArgs = {
  input?: InputMaybe<AssignPortalCompanyInput>;
};


export type MutationCancelFuelArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationCancelGateLogArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationCancelIncentiveVoucherArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationCancelShiftArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationChangeBookingCostItemChargeCodeArgs = {
  input?: InputMaybe<ChangeCostItemInput>;
};


export type MutationChangeBookingStatusArgs = {
  remarks?: InputMaybe<Scalars['String']['input']>;
  status: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationChangeConsolVoucherStatusArgs = {
  status: ConsolVoucherStatus;
  uuid: Scalars['UUID']['input'];
};


export type MutationChangeDocumentCreatorStatusArgs = {
  status: DocumentCreatorStatus;
  uuid: Scalars['UUID']['input'];
};


export type MutationChangeDocumentCreatorStatusAdvancedArgs = {
  status: DocumentCreatorStatus;
  uuid: Scalars['UUID']['input'];
};


export type MutationChangeEmployeeRoleArgs = {
  companyUuid: Scalars['UUID']['input'];
  driverCode?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  employeeUuid?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  roles: Array<InputMaybe<Scalars['String']['input']>>;
  userUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationChangeEmployeeUserArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  employeeUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationChangeVoucherStatusArgs = {
  status?: InputMaybe<VoucherStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationChangeVoucherTypeArgs = {
  type: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationCloneNodeBranchArgs = {
  input?: InputMaybe<CloneNodeBranchInput>;
};


export type MutationCloseManifestsArgs = {
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type MutationCloseShiftArgs = {
  input: CloseShiftInput;
};


export type MutationComputeCostArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationComputeTripSummaryArgs = {
  baseCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationCreateBaseCompanyArgs = {
  input: CreateBaseCompanyInput;
};


export type MutationCreateBaseCompanyInviteCompanyAccessArgs = {
  inviteUuid: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateBillingUnitArgs = {
  input?: InputMaybe<CreateBillingUnitInput>;
};


export type MutationCreateBookingTypeArgs = {
  input?: InputMaybe<UpdateBookingTypeInput>;
};


export type MutationCreateBulkImportFunctionArgs = {
  input: BulkImportFunctionUpdateInput;
};


export type MutationCreateBulkImportFunctionSettingArgs = {
  input: BulkImportFunctionSettingInput;
};


export type MutationCreateChannelArgs = {
  input: CreateChannelInput;
};


export type MutationCreateChargeItemMiscArgs = {
  input: CreateChargeItemMiscInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateConsolVoucherArgs = {
  input: CreateConsolVoucherInput;
};


export type MutationCreateContainerGradeArgs = {
  input: CreateContainerGradeInput;
};


export type MutationCreateContainerTypeArgs = {
  input: CreateContainerTypeInput;
};


export type MutationCreateCurrencyArgs = {
  code: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateDocumentCreatorArgs = {
  input: CreateDocumentCreator;
};


export type MutationCreateDocumentCreatorFromTemplateArgs = {
  bookingUuid: Scalars['UUID']['input'];
  templateUuid: Scalars['UUID']['input'];
};


export type MutationCreateDocumentCreatorTemplateArgs = {
  input?: InputMaybe<CreateDocumentCreatorTemplate>;
};


export type MutationCreateDriverArgs = {
  input: CreateDriverInput;
};


export type MutationCreateExchangeRateArgs = {
  input: CreateExchangeRateInput;
};


export type MutationCreateFafArgs = {
  input: CreateFafInput;
};


export type MutationCreateFuelArgs = {
  input?: InputMaybe<CreateFuelInput>;
};


export type MutationCreateGateLogArgs = {
  input?: InputMaybe<CreateGateLogInput>;
};


export type MutationCreateGlCodeArgs = {
  input: CreateGlCodeInput;
};


export type MutationCreateGroupArgs = {
  companyUuid: Scalars['UUID']['input'];
  name: Scalars['String']['input'];
};


export type MutationCreateIncentiveArgs = {
  input?: InputMaybe<CreateIncentiveInput>;
};


export type MutationCreateIncentiveRateArgs = {
  input?: InputMaybe<CreateIncentiveRateInput>;
};


export type MutationCreateIncentiveTypeArgs = {
  input?: InputMaybe<CreateIncentiveTypeInput>;
};


export type MutationCreateIncentiveVoucherArgs = {
  input: CreateIncentiveVoucherInput;
};


export type MutationCreateIntegrationDetailArgs = {
  input: CreateIntegrationDetailInput;
};


export type MutationCreateIntegrationMappingArgs = {
  input: CreateIntegrationMappingInput;
};


export type MutationCreateInviteCompanyAccessArgs = {
  input: InviteCompanyAccessInput;
};


export type MutationCreateJobOptionArgs = {
  input: CreateJobOptionInput;
};


export type MutationCreateJobTypeArgs = {
  input?: InputMaybe<UpdateJobTypeInput>;
};


export type MutationCreatePermissionArgs = {
  permission: Scalars['String']['input'];
  resource: Scalars['String']['input'];
  role: Scalars['String']['input'];
};


export type MutationCreatePortalCompanyArgs = {
  input?: InputMaybe<CreatePortalCompanyInput>;
};


export type MutationCreateQuotationArgs = {
  input: CreateQuotationInput;
};


export type MutationCreateQuotationItemArgs = {
  input: CreateQuotationItemInput;
};


export type MutationCreateReportArgs = {
  input?: InputMaybe<CreateReportInput>;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateSettingArgs = {
  input: CreateSettingInput;
};


export type MutationCreateTaxArgs = {
  input: CreateTaxInput;
};


export type MutationCreateTrailerArgs = {
  input: CreateTrailerInput;
};


export type MutationCreateTransportAreaCodeArgs = {
  input: CreateTransportAreaCodeInput;
};


export type MutationCreateTransportJobTypeArgs = {
  input: CreateTransportJobTypeInput;
};


export type MutationCreateTransportRateArgs = {
  input: CreateTransportRateInput;
};


export type MutationCreateTransportRateFromBookingArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationCreateTransportZoneArgs = {
  input: CreateTransportZoneInput;
};


export type MutationCreateVehicleArgs = {
  input: CreateVehicleInput;
};


export type MutationCreateVolumeUnitArgs = {
  input: CreateVolumeUnitInput;
};


export type MutationCreateVoucherArgs = {
  input: CreateVoucherInput;
};


export type MutationCreateVoucherRevisionArgs = {
  type: VoucherRevisionType;
  uuid: Scalars['UUID']['input'];
};


export type MutationCreateWeightUnitArgs = {
  input: CreateWeightUnitInput;
};


export type MutationCreateYardArgs = {
  input: CreateYardInput;
};


export type MutationDeleteAddressArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteBaseCompanyArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteBillingUnitArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteBookingCostItemArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteBookingDocumentArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteBookingTypeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteBulkImportFunctionArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteBulkImportFunctionSettingArgs = {
  input: BulkImportFunctionSettingInput;
};


export type MutationDeleteChannelArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteChargeCategoryArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteChargeItemArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteChargeItemCategoryArgs = {
  chargeCategoryUuid: Scalars['UUID']['input'];
  chargeItemUuid: Scalars['UUID']['input'];
};


export type MutationDeleteChargeItemCodeArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteChargeItemMiscArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteChargeItemTaxArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteCompanyArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteCompanyAccessArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteContactArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteContainerGradeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteContainerTypeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteCounterArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteDocumentCreatorTemplateArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteExchangeRateArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteFafArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteGlCodeArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteIncentiveArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteIncentiveRateArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteIncentiveTypeArgs = {
  input?: InputMaybe<DeleteIncentiveTypeInput>;
};


export type MutationDeleteIntegrationDetailArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteIntegrationMappingArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteJobArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteJobOptionArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteJobTypeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteLegArgs = {
  input?: InputMaybe<DeleteLegInput>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteManifestsArgs = {
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type MutationDeleteNodeBranchArgs = {
  nodeId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteNodeConnectionArgs = {
  connectionId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeletePaymentArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeletePermissionArgs = {
  permission: Scalars['String']['input'];
  resource: Scalars['String']['input'];
  role: Scalars['String']['input'];
};


export type MutationDeletePortalCompanyArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeletePushNotificationArgs = {
  input?: InputMaybe<NotificationInput>;
};


export type MutationDeleteQuotationItemArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteReportArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteSettingArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteTaxArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteTransportAreaCodeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteTransportJobTypeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteTransportRateArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteTransportZoneArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteUserPreferenceArgs = {
  key: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationDeleteVolumeUnitArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteVoucherItemArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationDeleteWeightUnitArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationDeleteYardArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationEnableCountryArgs = {
  alpha3?: InputMaybe<Scalars['LimitedString']['input']>;
};


export type MutationEndTripsInManifestArgs = {
  input?: InputMaybe<EndManifestTripsInput>;
};


export type MutationExpireQuotationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationGenerateDocumentCreatorNumberArgs = {
  data?: InputMaybe<Scalars['JSONObject']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGenerateReportArgs = {
  input?: InputMaybe<GenerateReportInput>;
};


export type MutationInsertChildNodeArgs = {
  input?: InputMaybe<UpdateChildNodeInput>;
};


export type MutationInsertChronologyArgs = {
  input: ChronologyInput;
};


export type MutationInvitePortalCompanyArgs = {
  input?: InputMaybe<InvitePortalCompanyInput>;
};


export type MutationInviteToCompanyArgs = {
  users: Array<InputMaybe<InviteUser>>;
  uuid: Scalars['UUID']['input'];
};


export type MutationLinkCompanyArgs = {
  baseCompanyUuid: Scalars['UUID']['input'];
  companyUuid: Scalars['UUID']['input'];
};


export type MutationLinkDocumentToVoucherArgs = {
  bookingDocumentUuid: Scalars['UUID']['input'];
  voucherUuid: Scalars['UUID']['input'];
};


export type MutationLinkInviteCompanyAccessArgs = {
  externalCompanyUuids: Array<InputMaybe<Scalars['UUID']['input']>>;
  uuid: Scalars['UUID']['input'];
};


export type MutationMakeBookingArgs = {
  input: MakeBookingInput;
};


export type MutationMakeBookingCommentArgs = {
  message: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationMigrateFmsChargesArgs = {
  items?: InputMaybe<Array<InputMaybe<ChargeItemMigrateType>>>;
};


export type MutationMigrateFmsCompanyArgs = {
  code: Scalars['String']['input'];
};


export type MutationOpenShiftArgs = {
  input: OpenShiftInput;
};


export type MutationPopulateAvailabilityArgs = {
  input?: InputMaybe<PopulateAvailabilityInput>;
};


export type MutationProcessAllJobsArgs = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationProcessJobArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationProcessReportPortalCompanyArgs = {
  input?: InputMaybe<ProcessReportPortalCompanyInput>;
};


export type MutationProcessTransportBookingArgs = {
  input: BookingTransportInput;
};


export type MutationProcessVehiclePlanArgs = {
  baseCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  rmTripUuid?: InputMaybe<Scalars['UUID']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationRecomputeCostArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationRecomputeLegActivitiesArgs = {
  input?: InputMaybe<RecomputeLegActivitiesQuery>;
};


export type MutationReindexJobArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationRejectInviteCompanyAccessArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveChronologyArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveCurrencyArgs = {
  baseCompanyUuid: Scalars['UUID']['input'];
  currencyUuid: Scalars['UUID']['input'];
};


export type MutationRemoveDriverCompanyArgs = {
  input: RemoveDriverCompanyInput;
};


export type MutationRemoveEmployeeArgs = {
  companyUuid: Scalars['UUID']['input'];
  userUuid: Scalars['UUID']['input'];
};


export type MutationRemoveGroupArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveLegsFromManifestArgs = {
  input?: InputMaybe<RemoveManifestLegsInput>;
};


export type MutationRemoveMemberArgs = {
  employeeUuid: Scalars['UUID']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveMonitoringCompanyArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveSuperAdminArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRemoveTrailerCompanyArgs = {
  input: RemoveTrailerCompanyInput;
};


export type MutationRemoveVehicleCompanyArgs = {
  input: RemoveVehicleCompanyInput;
};


export type MutationRequestApproveArgs = {
  type: RequestType;
  uuid: Scalars['UUID']['input'];
};


export type MutationResendInviteArgs = {
  employeeUuid: Scalars['UUID']['input'];
};


export type MutationResendInviteCompanyAccessArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationRolloverJobArgs = {
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  jobUuid: Scalars['UUID']['input'];
};


export type MutationRolloverVoucherArgs = {
  bookingUuid: Scalars['UUID']['input'];
  jobUuid: Scalars['UUID']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationSetBookingAssigneeArgs = {
  input: BookingAssigneeInput;
};


export type MutationSpliceNodeBranchArgs = {
  nodeId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSubmitRotArgs = {
  input: SubmitRotInput;
};


export type MutationSubmitRequestArgs = {
  input: EmailRequestInput;
};


export type MutationTriggerBookingEventArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationTriggerVoucherEventArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUnInvitePortalCompanyArgs = {
  employeeUuid?: InputMaybe<Scalars['UUID']['input']>;
  portalCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUnapproveFuelArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUnapproveIncentiveVoucherArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUnapproveIncentiveVoucherFinanceArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUnapproveShiftArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUnapproveShiftFinanceArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUnassignPortalCompanyReportArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUncancelFuelArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUncancelGateLogArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUncancelIncentiveVoucherArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUncancelShiftArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteBillingUnitArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteBookingTypeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteBulkImportFunctionArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUndeleteContainerGradeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteContainerTypeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteDocumentCreatorTemplateArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUndeleteGlCodeArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUndeleteIncentiveArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUndeleteIncentiveTypeArgs = {
  input?: InputMaybe<UndeleteIncentiveTypeInput>;
};


export type MutationUndeleteJobArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteJobOptionArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteJobTypeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteLegArgs = {
  input?: InputMaybe<DeleteLegInput>;
};


export type MutationUndeletePortalCompanyArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteTaxArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUndeleteTransportJobTypeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteTransportZoneArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteVolumeUnitArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteWeightUnitArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUndeleteYardArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUnlinkCompanyArgs = {
  companyUuid: Scalars['UUID']['input'];
};


export type MutationUnlinkDocumentFromVoucherArgs = {
  bookingDocumentUuid: Scalars['UUID']['input'];
  voucherUuid: Scalars['UUID']['input'];
};


export type MutationUnsetBookingAssigneeArgs = {
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateAddressArgs = {
  input: UpdateAddressInput;
};


export type MutationUpdateAddressLocationArgs = {
  input: UpdateAddressLocationInput;
};


export type MutationUpdateAvailabilityArgs = {
  input?: InputMaybe<UpdateAvailabilityInput>;
};


export type MutationUpdateBaseCompanyArgs = {
  input: UpdateBaseCompanyInput;
};


export type MutationUpdateBillingUnitArgs = {
  input?: InputMaybe<UpdateBillingUnitInput>;
};


export type MutationUpdateBookingArgs = {
  input: UpdateBookingInput;
};


export type MutationUpdateBookingActivitiesArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUpdateBookingCostItemArgs = {
  input: UpdateCostItemInput;
};


export type MutationUpdateBookingDocumentArgs = {
  hardCopyStatus?: InputMaybe<DocumentHardCopyStatus>;
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
  type: Scalars['String']['input'];
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateBookingTypeArgs = {
  input?: InputMaybe<UpdateBookingTypeInput>;
};


export type MutationUpdateBulkImportFunctionArgs = {
  input: BulkImportFunctionUpdateInput;
};


export type MutationUpdateChannelArgs = {
  input: UpdateChannelInput;
};


export type MutationUpdateChargeCategoryArgs = {
  input: UpdateChargeCategoryInput;
};


export type MutationUpdateChargeItemArgs = {
  input: UpdateChargeItemInput;
};


export type MutationUpdateChargeItemCodeArgs = {
  input: UpdateChargeItemCodeInput;
};


export type MutationUpdateChargeItemMiscArgs = {
  input: UpdateChargeItemMiscInput;
};


export type MutationUpdateChargeItemTaxArgs = {
  input: UpdateChargeItemTaxInput;
};


export type MutationUpdateChildNodeArgs = {
  input?: InputMaybe<UpdateChildNodeInput>;
};


export type MutationUpdateChildNodeStatusArgs = {
  input?: InputMaybe<UpdateChildNodeStatusInput>;
};


export type MutationUpdateChronologyArgs = {
  input: ChronologyUpdateInput;
};


export type MutationUpdateCompanyArgs = {
  input: UpdateCompanyInput;
};


export type MutationUpdateCompanyCodeArgs = {
  input: UpdateCompanyCodeInput;
};


export type MutationUpdateContactArgs = {
  input: UpdateContactInput;
};


export type MutationUpdateContainerGradeArgs = {
  input: UpdateContainerGradeInput;
};


export type MutationUpdateContainerTypeArgs = {
  input: UpdateContainerTypeInput;
};


export type MutationUpdateCurrencyArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};


export type MutationUpdateDocumentCreatorArgs = {
  input?: InputMaybe<UpdateDocumentCreator>;
};


export type MutationUpdateDocumentCreatorTemplateArgs = {
  input?: InputMaybe<UpdateDocumentCreatorTemplate>;
};


export type MutationUpdateDriverArgs = {
  input: UpdateDriverInput;
};


export type MutationUpdateDriverCompanyArgs = {
  input: UpdateDriverCompanyInput;
};


export type MutationUpdateDriverEmployeeArgs = {
  input: UpdateDriverEmployeeInput;
};


export type MutationUpdateDriverImageArgs = {
  input: UpdateDriverImageInput;
};


export type MutationUpdateDriverStatusArgs = {
  input: UpdateDriverStatusInput;
};


export type MutationUpdateEmptyTripArgs = {
  input?: InputMaybe<EmptyTripUpdateInput>;
};


export type MutationUpdateEmptyTripStatusArgs = {
  input?: InputMaybe<EmptyTripUpdateStatusInput>;
};


export type MutationUpdateEntityDocumentArgs = {
  input: UpdateEntityDocumentInput;
};


export type MutationUpdateEntityDocumentStatusArgs = {
  input: UpdateEntityDocumentStatusInput;
};


export type MutationUpdateEntityRequirementsArgs = {
  input: UpdateEntityRequirementsInput;
};


export type MutationUpdateEntityRequirementsCategoryArgs = {
  input: UpdateEntityRequirementCategoryInput;
};


export type MutationUpdateExchangeRateArgs = {
  input: UpdateExchangeRateInput;
};


export type MutationUpdateFafArgs = {
  input: UpdateFafInput;
};


export type MutationUpdateFuelArgs = {
  input?: InputMaybe<UpdateFuelInput>;
};


export type MutationUpdateGateLogArgs = {
  input?: InputMaybe<UpdateGateLogInput>;
};


export type MutationUpdateGlCodeArgs = {
  input: UpdateGlCodeInput;
};


export type MutationUpdateGroupArgs = {
  input: UpdateGroupInput;
};


export type MutationUpdateIncentiveArgs = {
  input?: InputMaybe<UpdateIncentiveInput>;
};


export type MutationUpdateIncentiveRateArgs = {
  input?: InputMaybe<UpdateIncentiveRateInput>;
};


export type MutationUpdateIncentiveTypeArgs = {
  input?: InputMaybe<UpdateIncentiveTypeInput>;
};


export type MutationUpdateIncentiveVoucherArgs = {
  input: UpdateIncentiveVoucherInput;
};


export type MutationUpdateIntegrationDetailArgs = {
  input: UpdateIntegrationDetailInput;
};


export type MutationUpdateIntegrationMappingArgs = {
  input: UpdateIntegrationMappingInput;
};


export type MutationUpdateJobArgs = {
  input?: InputMaybe<UpdateJobInput>;
};


export type MutationUpdateJobOptionArgs = {
  input: UpdateJobOptionInput;
};


export type MutationUpdateJobTripsArgs = {
  input?: InputMaybe<UpdateJobTripInput>;
};


export type MutationUpdateJobTypeArgs = {
  input?: InputMaybe<UpdateJobTypeInput>;
};


export type MutationUpdateLegArgs = {
  input: UpdateLegInput;
};


export type MutationUpdateLegTimingArgs = {
  input: UpdateLegTimingInput;
};


export type MutationUpdatePaymentArgs = {
  input: UpdatePaymentInput;
};


export type MutationUpdatePortalCompanyArgs = {
  input?: InputMaybe<UpdatePortalCompanyInput>;
};


export type MutationUpdateQuotationArgs = {
  input: UpdateQuotationInput;
};


export type MutationUpdateQuotationItemArgs = {
  input: UpdateQuotationItemInput;
};


export type MutationUpdateReportArgs = {
  input?: InputMaybe<UpdateReportInput>;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateRootNodeArgs = {
  input?: InputMaybe<UpdateRootNodeInput>;
};


export type MutationUpdateRootNodeStatusArgs = {
  input?: InputMaybe<UpdateRootNodeStatusInput>;
};


export type MutationUpdateSettingArgs = {
  input: UpdateSettingInput;
};


export type MutationUpdateShiftArgs = {
  input: UpdateShiftInput;
};


export type MutationUpdateTaxArgs = {
  input: UpdateTaxInput;
};


export type MutationUpdateTrailerArgs = {
  input: UpdateTrailerInput;
};


export type MutationUpdateTrailerCompanyArgs = {
  input: UpdateTrailerCompanyInput;
};


export type MutationUpdateTrailerImageArgs = {
  input: UpdateTrailerImageInput;
};


export type MutationUpdateTrailerStatusArgs = {
  input: UpdateTrailerStatusInput;
};


export type MutationUpdateTransportAreaCodeArgs = {
  input: UpdateTransportAreaCodeInput;
};


export type MutationUpdateTransportJobAddressArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationUpdateTransportJobTypeArgs = {
  input: UpdateTransportJobTypeInput;
};


export type MutationUpdateTransportRateArgs = {
  input: UpdateTransportRateInput;
};


export type MutationUpdateTransportZoneArgs = {
  input: CreateTransportZoneInput;
};


export type MutationUpdateTripArgs = {
  input?: InputMaybe<UpdateTripInput>;
};


export type MutationUpdateUserArgs = {
  input: UserInput;
};


export type MutationUpdateUserPreferenceArgs = {
  key: Scalars['String']['input'];
  type: Scalars['String']['input'];
  value: Scalars['String']['input'];
};


export type MutationUpdateVehicleArgs = {
  input: UpdateVehicleInput;
};


export type MutationUpdateVehicleCompanyArgs = {
  input: UpdateVehicleCompanyInput;
};


export type MutationUpdateVehicleImageArgs = {
  input: UpdateVehicleImageInput;
};


export type MutationUpdateVehicleStatusArgs = {
  input: UpdateVehicleStatusInput;
};


export type MutationUpdateVolumeUnitArgs = {
  input: UpdateVolumeUnitInput;
};


export type MutationUpdateVoucherArgs = {
  input: UpdateVoucherInput;
};


export type MutationUpdateVoucherItemArgs = {
  input: UpdateVoucherItemInput;
};


export type MutationUpdateWeightUnitArgs = {
  input: UpdateWeightUnitInput;
};


export type MutationUpdateYardArgs = {
  input: UpdateYardInput;
};


export type MutationUploadBookingDocumentArgs = {
  files?: InputMaybe<Array<InputMaybe<DocumentInput>>>;
  hardCopyStatus?: InputMaybe<DocumentHardCopyStatus>;
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  location?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type MutationVerifyBookingDocumentArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type NodeConnection = {
  __typename?: 'NodeConnection';
  _id?: Maybe<Scalars['ObjectID']['output']>;
  childId?: Maybe<Scalars['ObjectID']['output']>;
  childSelector?: Maybe<ChildSelector>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ownerUuid?: Maybe<Scalars['String']['output']>;
  parentId?: Maybe<Scalars['ObjectID']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type Notification = {
  __typename?: 'Notification';
  driverUuid?: Maybe<Scalars['UUID']['output']>;
  tokens?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type NotificationInput = {
  driverUuid: Scalars['UUID']['input'];
  tokens?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type OpenShiftInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  driverUuid?: InputMaybe<Scalars['UUID']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  count?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type Payment = {
  __typename?: 'Payment';
  amount?: Maybe<Scalars['Float']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  status?: Maybe<PaymentStatus>;
  transactionType?: Maybe<PaymentTransactionType>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  voucherPayments?: Maybe<Array<Maybe<VoucherPayment>>>;
};

export enum PaymentStatus {
  Paid = 'PAID',
  Voided = 'VOIDED'
}

export enum PaymentTransactionType {
  Cash = 'CASH',
  Cheque = 'CHEQUE',
  Other = 'OTHER',
  WireTransfer = 'WIRE_TRANSFER'
}

export enum PaymentType {
  Cash = 'CASH',
  Credit = 'CREDIT'
}

export type Payments = {
  __typename?: 'Payments';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Payment>>>;
};

export type Permission = {
  __typename?: 'Permission';
  permissions?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  resources?: Maybe<Scalars['String']['output']>;
};

export type PermissionType = {
  __typename?: 'PermissionType';
  canApprove?: Maybe<Scalars['Boolean']['output']>;
  canReject?: Maybe<Scalars['Boolean']['output']>;
};

export type Permissions = {
  __typename?: 'Permissions';
  role?: Maybe<Scalars['String']['output']>;
  rolePermissions?: Maybe<Array<Maybe<Permission>>>;
};

export type Place = {
  __typename?: 'Place';
  country?: Maybe<Country>;
  location?: Maybe<Geometry>;
  name?: Maybe<Scalars['String']['output']>;
  placeId?: Maybe<Scalars['String']['output']>;
};

export type PlaceResults = {
  __typename?: 'PlaceResults';
  description?: Maybe<Scalars['String']['output']>;
  placeId?: Maybe<Scalars['String']['output']>;
};

export type PlannerQueueRunnerInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  queueContent?: InputMaybe<Scalars['JSON']['input']>;
  queueName?: InputMaybe<Scalars['String']['input']>;
};

export type PmUtilDept = {
  __typename?: 'PmUtilDept';
  c?: Maybe<Scalars['Int']['output']>;
  dept?: Maybe<Scalars['String']['output']>;
  tot?: Maybe<Scalars['Int']['output']>;
};

export type Point = {
  __typename?: 'Point';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type PopulateAvailabilityInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<EntityType>;
};

export type Port = {
  __typename?: 'Port';
  cnIso2?: Maybe<Scalars['String']['output']>;
  cnName?: Maybe<Scalars['String']['output']>;
  countryAlpha3?: Maybe<Scalars['LimitedString']['output']>;
  distance?: Maybe<Scalars['Int']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Int']['output']>;
  locode?: Maybe<Scalars['String']['output']>;
  locodeAlternatives?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  longitude?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameAlternatives?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type PortalCompanies = {
  __typename?: 'PortalCompanies';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<PortalCompany>>>;
};

export type PortalCompany = {
  __typename?: 'PortalCompany';
  bookCompanies?: Maybe<Array<Maybe<Company>>>;
  bulkImportFunctions?: Maybe<Array<Maybe<BulkImportFunction>>>;
  details?: Maybe<PortalCompanyDetails>;
  employees?: Maybe<Array<Maybe<Employee>>>;
  name?: Maybe<Scalars['String']['output']>;
  reports?: Maybe<PortalReports>;
  status?: Maybe<CompanyStatus>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  viewCompanies?: Maybe<Array<Maybe<Company>>>;
};

export type PortalCompanyDetails = {
  __typename?: 'PortalCompanyDetails';
  userTimeout?: Maybe<Scalars['Int']['output']>;
};

export type PortalCompanyDetailsInput = {
  userTimeout?: InputMaybe<Scalars['Int']['input']>;
};

export enum PortalCompanyQueryType {
  All = 'all',
  Book = 'book',
  View = 'view'
}

export type PortalCompanyReportConfig = {
  __typename?: 'PortalCompanyReportConfig';
  fields?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  hours?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  sort?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
  userUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
};

export type PortalCompanyReportConfigInput = {
  fields?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  hours?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  userUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type PortalReports = {
  __typename?: 'PortalReports';
  pageInfo?: Maybe<NumberPagination>;
  portalCompanyUuid?: Maybe<Scalars['UUID']['output']>;
  rows?: Maybe<Array<Maybe<ReportPortalCompany>>>;
};

export type Ports = {
  __typename?: 'Ports';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Port>>>;
};

export type ProcessFlow = {
  __typename?: 'ProcessFlow';
  sections?: Maybe<Array<Maybe<ProcessFlowSection>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ProcessFlowChronologiesType = {
  __typename?: 'ProcessFlowChronologiesType';
  isJobRequired?: Maybe<Scalars['Boolean']['output']>;
  isTripRequired?: Maybe<Scalars['Boolean']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ProcessFlowCreateType = {
  __typename?: 'ProcessFlowCreateType';
  bookingDocuments?: Maybe<Array<Maybe<ProcessFlowDocumentType>>>;
  chronologies?: Maybe<Array<Maybe<ProcessFlowChronologiesType>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ProcessFlowDocumentType = {
  __typename?: 'ProcessFlowDocumentType';
  approvals?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  isJobRequired?: Maybe<Scalars['Boolean']['output']>;
  isProofOfDelivery?: Maybe<Scalars['Boolean']['output']>;
  isTripRequired?: Maybe<Scalars['Boolean']['output']>;
  required?: Maybe<Scalars['Boolean']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ProcessFlowRequestType = {
  __typename?: 'ProcessFlowRequestType';
  type?: Maybe<Scalars['String']['output']>;
};

export type ProcessFlowSection = {
  __typename?: 'ProcessFlowSection';
  bookingDocuments?: Maybe<Array<Maybe<ProcessFlowDocumentType>>>;
  chronologies?: Maybe<Array<Maybe<ProcessFlowChronologiesType>>>;
  display?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  requests?: Maybe<Array<Maybe<ProcessFlowRequestType>>>;
  steps?: Maybe<Array<Maybe<ProcessFlowStepType>>>;
  type?: Maybe<Scalars['String']['output']>;
  vouchers?: Maybe<Array<Maybe<ProcessFlowVoucherType>>>;
};

export type ProcessFlowStepType = {
  __typename?: 'ProcessFlowStepType';
  helpText?: Maybe<Scalars['String']['output']>;
  isComplete?: Maybe<Scalars['Boolean']['output']>;
  isCurrentStep?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  permission?: Maybe<PermissionType>;
};

export type ProcessFlowVoucherType = {
  __typename?: 'ProcessFlowVoucherType';
  approvals?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  bookingDocuments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  transactionCategory?: Maybe<VoucherCategoryType>;
  transactionType?: Maybe<VoucherTypeType>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ProcessReportPortalCompanyInput = {
  match?: InputMaybe<Scalars['JSON']['input']>;
  portalCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type ProcessTypeCategories = {
  __typename?: 'ProcessTypeCategories';
  value?: Maybe<Scalars['String']['output']>;
};

export type ProcessTypes = {
  __typename?: 'ProcessTypes';
  key?: Maybe<Scalars['String']['output']>;
  meta?: Maybe<Scalars['JSON']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type Profile = {
  __typename?: 'Profile';
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  actionTemplate?: Maybe<ActionTemplate>;
  actionTemplates?: Maybe<Array<Maybe<ActionTemplate>>>;
  address?: Maybe<Address>;
  addresses?: Maybe<Addresses>;
  approval?: Maybe<Approval>;
  approvals?: Maybe<Approvals>;
  availabilities?: Maybe<AvailabilitiesResults>;
  availability?: Maybe<Availability>;
  baseCompanies?: Maybe<BaseCompanies>;
  baseCompany?: Maybe<BaseCompany>;
  billingUnit?: Maybe<BillingUnit>;
  billingUnits?: Maybe<BillingUnits>;
  booking?: Maybe<Booking>;
  bookingDocument?: Maybe<BookingDocument>;
  bookingDocuments?: Maybe<BookingDocuments>;
  bookingDocumentsSummary?: Maybe<Scalars['String']['output']>;
  bookingEvents?: Maybe<Array<Maybe<Event>>>;
  bookingFieldValue?: Maybe<BookingFieldValue>;
  bookingForRates?: Maybe<Scalars['JSON']['output']>;
  bookingPublic?: Maybe<Scalars['JSON']['output']>;
  bookingSummary?: Maybe<Array<Maybe<BookingSummary>>>;
  bookingType?: Maybe<BookingType>;
  bookingTypes?: Maybe<BookingTypes>;
  bookingsSearch?: Maybe<Bookings>;
  bookingsSearchJson?: Maybe<BookingsJson>;
  bulkImportFunction?: Maybe<BulkImportFunction>;
  bulkImportFunctions?: Maybe<BulkImportFunctions>;
  channel?: Maybe<Channel>;
  channels?: Maybe<Channels>;
  chargeCategories?: Maybe<ChargeCategories>;
  chargeCategory?: Maybe<ChargeCategory>;
  chargeItem?: Maybe<ChargeItem>;
  chargeItemMisc?: Maybe<ChargeItemMisc>;
  chargeItemMiscs?: Maybe<ChargeItemMiscs>;
  chargeItems?: Maybe<ChargeItems>;
  childNode?: Maybe<ChildNode>;
  childNodes?: Maybe<ChildNodes>;
  commodityTypes?: Maybe<CommodityTypes>;
  companies?: Maybe<Companies>;
  company?: Maybe<Company>;
  companyAccess?: Maybe<CompanyAccess>;
  companyAccessExternalBaseCompany?: Maybe<Array<Maybe<BaseCompany>>>;
  companyAccesses?: Maybe<CompanyAccesses>;
  computeRateFromBooking?: Maybe<Array<Maybe<ComputedRateResult>>>;
  computedCostLastNode?: Maybe<Scalars['JSON']['output']>;
  consolVoucher?: Maybe<ConsolVoucher>;
  consolVouchers?: Maybe<ConsolVouchers>;
  contact?: Maybe<Contact>;
  contacts?: Maybe<Contacts>;
  containerGrade?: Maybe<ContainerGrade>;
  containerGrades?: Maybe<ContainerGrades>;
  containerType?: Maybe<ContainerType>;
  containerTypes?: Maybe<ContainerTypes>;
  costItems?: Maybe<Array<Maybe<CostItem>>>;
  costSheet?: Maybe<Array<Maybe<CostSheet>>>;
  counters?: Maybe<CounterResults>;
  countries?: Maybe<Countries>;
  currencies?: Maybe<Currencies>;
  currency?: Maybe<Currency>;
  documentCreator?: Maybe<DocumentCreator>;
  documentCreatorTemplate?: Maybe<DocumentCreatorTemplate>;
  documentCreatorTemplates?: Maybe<DocumentCreatorTemplates>;
  documentCreators?: Maybe<DocumentCreators>;
  driver?: Maybe<Driver>;
  drivers?: Maybe<DriversResults>;
  emailTemplate?: Maybe<Template>;
  employee?: Maybe<Employee>;
  employees?: Maybe<Employees>;
  emptyTrip?: Maybe<EmptyTrip>;
  emptyTrips?: Maybe<EmptyTrips>;
  entityDocument?: Maybe<DocumentDescription>;
  estimateCostItem?: Maybe<CostItem>;
  eventsEtc?: Maybe<EventsEtc>;
  eventsVoucher?: Maybe<EventVouchers>;
  exchangeRate?: Maybe<ExchangeRate>;
  exchangeRates?: Maybe<ExchangeRates>;
  faf?: Maybe<Faf>;
  fafs?: Maybe<Fafs>;
  flowTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fuel?: Maybe<Fuel>;
  fuels?: Maybe<Fuels>;
  gateLog?: Maybe<GateLog>;
  gateLogs?: Maybe<GateLogs>;
  getBaseCompanyAddress?: Maybe<Address>;
  getBaseCompanyTypes?: Maybe<Array<Maybe<ProcessTypes>>>;
  getLastNode?: Maybe<Array<Maybe<TraversedJourney>>>;
  getPushNotification?: Maybe<Notification>;
  getSettingAreaCodeInputRequirement?: Maybe<BcSettingAreaCode>;
  getTimezones?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  getTransportRate?: Maybe<TransportRateDetails>;
  getTypeCategories?: Maybe<Array<Maybe<ProcessTypeCategories>>>;
  getUserTimeout?: Maybe<UserTimeout>;
  getUsers?: Maybe<Array<Maybe<User>>>;
  glCode?: Maybe<GlCode>;
  glCodes?: Maybe<GlCodes>;
  group?: Maybe<Group>;
  groups?: Maybe<Groups>;
  incentive?: Maybe<Incentive>;
  incentiveRate?: Maybe<IncentiveRate>;
  incentiveRates?: Maybe<IncentiveRates>;
  incentiveType?: Maybe<IncentiveType>;
  incentiveTypes?: Maybe<IncentiveTypes>;
  incentiveVoucher?: Maybe<IncentiveVoucher>;
  incentiveVouchers?: Maybe<IncentiveVouchers>;
  incentiveVouchersJson?: Maybe<Scalars['JSON']['output']>;
  incentives?: Maybe<Incentives>;
  integrationDetail?: Maybe<IntegrationDetail>;
  integrationDetails?: Maybe<IntegrationDetails>;
  integrationLog?: Maybe<IntegrationLog>;
  integrationLogs?: Maybe<IntegrationLogs>;
  integrationMapping?: Maybe<IntegrationMapping>;
  integrationMappings?: Maybe<IntegrationMappings>;
  integrations?: Maybe<Integrations>;
  inviteCompanyAccess?: Maybe<InviteCompanyAccess>;
  inviteCompanyAccesses?: Maybe<InviteCompanyAccesses>;
  job?: Maybe<Job>;
  jobOption?: Maybe<JobOption>;
  jobOptions?: Maybe<JobOptions>;
  jobSummaries?: Maybe<JobSummaries>;
  jobSummary?: Maybe<JobSummary>;
  jobType?: Maybe<JobType>;
  jobTypes?: Maybe<JobTypes>;
  jobs?: Maybe<Jobs>;
  jobsSearch?: Maybe<Jobs>;
  jobsSearchJson?: Maybe<JobsJson>;
  legActivities?: Maybe<LegActivities>;
  legSummaries?: Maybe<LegSummaries>;
  legSummary?: Maybe<LegSummary>;
  manifest?: Maybe<Manifest>;
  manifests?: Maybe<Manifests>;
  monitoringCompanies?: Maybe<Companies>;
  nodeTree?: Maybe<Scalars['JSON']['output']>;
  payment?: Maybe<Payment>;
  payments?: Maybe<Payments>;
  permission?: Maybe<Array<Maybe<Permission>>>;
  permissionIsAllowed?: Maybe<Scalars['Boolean']['output']>;
  permissionTypes?: Maybe<Array<Maybe<Permission>>>;
  place?: Maybe<Place>;
  places?: Maybe<Array<Maybe<PlaceResults>>>;
  port?: Maybe<Port>;
  portalCompanies?: Maybe<PortalCompanies>;
  portalCompany?: Maybe<PortalCompany>;
  ports?: Maybe<Ports>;
  precomputeEmptyTrip?: Maybe<Scalars['JSON']['output']>;
  processFlowCreate?: Maybe<ProcessFlowCreateType>;
  processFlowDashboard?: Maybe<Array<Maybe<ProcessFlow>>>;
  processFlowMap?: Maybe<Scalars['String']['output']>;
  processFlowTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  processReportPortalCompany?: Maybe<Response>;
  processVehiclePlan?: Maybe<Scalars['JSON']['output']>;
  profile?: Maybe<Profile>;
  quotation?: Maybe<Quotation>;
  quotations?: Maybe<Quotations>;
  rates?: Maybe<Array<Maybe<Rate>>>;
  report?: Maybe<Report>;
  reportFields?: Maybe<Array<Maybe<ReportField>>>;
  reportJobSummaries?: Maybe<ReportJobSummaries>;
  reportJobSummary?: Maybe<ReportJobSummary>;
  reportList?: Maybe<Array<Maybe<ReportSummary>>>;
  reportMeta?: Maybe<ReportMeta>;
  reports?: Maybe<Reports>;
  requestEmailResults?: Maybe<Array<Maybe<RequestEmailResultsType>>>;
  requestTemplate?: Maybe<RequisitionTemplate>;
  reverseGeocode?: Maybe<Geocodes>;
  roles?: Maybe<Roles>;
  rootNode?: Maybe<RootNode>;
  rootNodes?: Maybe<RootNodes>;
  seaRoute?: Maybe<SeaRoute>;
  search?: Maybe<Search>;
  searchFmsCompanies?: Maybe<Array<Maybe<FmsCompany>>>;
  serviceTypes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  setting?: Maybe<BaseCompanySetting>;
  settings?: Maybe<BaseCompanySettings>;
  shift?: Maybe<Shift>;
  shifts?: Maybe<Shifts>;
  shiftsJson?: Maybe<Scalars['JSON']['output']>;
  shippingRates?: Maybe<ShippingRates>;
  signDriverBehaviorAuth?: Maybe<DriverBehaviorAuth>;
  superAdmins?: Maybe<SuperAdmins>;
  tax?: Maybe<Tax>;
  taxes?: Maybe<Taxes>;
  trailer?: Maybe<Trailer>;
  trailers?: Maybe<TrailersResults>;
  transportActivities?: Maybe<Array<Maybe<TransportActivityFlow>>>;
  transportAreaCode?: Maybe<TransportAreaCode>;
  transportAreaCodeFromLocation?: Maybe<TransportAreaCode>;
  transportAreaCodes?: Maybe<TransportAreaCodes>;
  transportJob?: Maybe<TransportJob>;
  transportJobType?: Maybe<TransportJobType>;
  transportJobTypes?: Maybe<TransportJobTypes>;
  transportJobs?: Maybe<TransportJobs>;
  transportJobsJson?: Maybe<Scalars['JSON']['output']>;
  transportRate?: Maybe<TransportRate>;
  transportRates?: Maybe<TransportRates>;
  transportReportJob?: Maybe<TransportReportJob>;
  transportReportJobs?: Maybe<TransportReportJobs>;
  transportReportJobsJson?: Maybe<Scalars['JSON']['output']>;
  transportReportLegActivities?: Maybe<TransportReportLegActivities>;
  transportReportLegActivitiesJson?: Maybe<Scalars['JSON']['output']>;
  transportReportTrip?: Maybe<TransportReportTrip>;
  transportReportTrips?: Maybe<TransportReportTrips>;
  transportReportTripsJson?: Maybe<Scalars['JSON']['output']>;
  transportZone?: Maybe<TransportZone>;
  transportZones?: Maybe<TransportZones>;
  trip?: Maybe<Trip>;
  tripSummaries?: Maybe<TripSummaries>;
  tripSummary?: Maybe<TripSummary>;
  trips?: Maybe<Trips>;
  truckAlerts?: Maybe<TruckAlerts>;
  truckLocation?: Maybe<Array<Maybe<TruckLocation>>>;
  truckLocationByTrips?: Maybe<Array<Maybe<TripLocations>>>;
  truckLocationByTripsV2?: Maybe<Array<Maybe<TripLocationsV2>>>;
  truckLocationLatest?: Maybe<TruckLocation>;
  truckLocationLatestV2?: Maybe<TruckLocationV2>;
  truckLocationV2?: Maybe<Scalars['JSON']['output']>;
  trucksSpeeds?: Maybe<Array<Maybe<TruckSpeeds>>>;
  trucksSpeedsV2?: Maybe<Array<Maybe<TruckSpeeds>>>;
  user?: Maybe<User>;
  userPreferences?: Maybe<Array<Maybe<UserPreference>>>;
  users?: Maybe<Users>;
  vehicle?: Maybe<Vehicle>;
  vehicles?: Maybe<VehiclesResults>;
  vessels?: Maybe<Array<Maybe<Vessel>>>;
  viewer?: Maybe<Viewer>;
  volumeUnit?: Maybe<VolumeUnit>;
  volumeUnits?: Maybe<VolumeUnits>;
  voucher?: Maybe<Voucher>;
  voucherItems?: Maybe<Array<Maybe<VoucherItem>>>;
  vouchers?: Maybe<Vouchers>;
  vouchersSearch?: Maybe<Vouchers>;
  vouchersSearchJson?: Maybe<VouchersJson>;
  weightUnit?: Maybe<WeightUnit>;
  weightUnits?: Maybe<WeightUnits>;
  yard?: Maybe<Yard>;
  yards?: Maybe<Yards>;
};


export type QueryActionTemplateArgs = {
  bookingType: Scalars['String']['input'];
  resource: TemplateResource;
  type: Scalars['String']['input'];
};


export type QueryActionTemplatesArgs = {
  bookingType: Scalars['String']['input'];
  resource: TemplateResource;
};


export type QueryAddressArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryAddressesArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  queryByOwner?: InputMaybe<Scalars['Boolean']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<AddressStatus>>>;
  type?: InputMaybe<Array<InputMaybe<AddressType>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type QueryApprovalArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryApprovalsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  requestType?: InputMaybe<Scalars['String']['input']>;
  requestUuid?: InputMaybe<Scalars['UUID']['input']>;
  voucherType?: InputMaybe<Scalars['String']['input']>;
  voucherUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryAvailabilitiesArgs = {
  input?: InputMaybe<QueryAvailabilitiesInput>;
};


export type QueryAvailabilityArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryBaseCompaniesArgs = {
  countryAlpha3?: InputMaybe<Scalars['LimitedString']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<CompanyType>>;
};


export type QueryBaseCompanyArgs = {
  slug?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryBillingUnitArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryBillingUnitsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<BillingUnitStatus>>>;
};


export type QueryBookingArgs = {
  no?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryBookingDocumentArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryBookingDocumentsArgs = {
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  bookingUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  requestType?: InputMaybe<Scalars['String']['input']>;
  requestUuid?: InputMaybe<Scalars['UUID']['input']>;
  voucherType?: InputMaybe<Scalars['String']['input']>;
  voucherUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryBookingDocumentsSummaryArgs = {
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryBookingEventsArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryBookingFieldValueArgs = {
  input?: InputMaybe<BookingFieldValueInput>;
};


export type QueryBookingForRatesArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryBookingPublicArgs = {
  nos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryBookingTypeArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryBookingTypesArgs = {
  byCompany?: InputMaybe<Scalars['UUID']['input']>;
  codes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<BookingTypeStatus>>>;
};


export type QueryBookingsSearchArgs = {
  input?: InputMaybe<BookingsSearchInput>;
};


export type QueryBookingsSearchJsonArgs = {
  input?: InputMaybe<BookingsSearchInput>;
};


export type QueryBulkImportFunctionArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryBulkImportFunctionsArgs = {
  input?: InputMaybe<BulkImportFunctionsInput>;
};


export type QueryChannelArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryChannelsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryChargeCategoriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<ChargeItemStatus>>>;
};


export type QueryChargeCategoryArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryChargeItemArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryChargeItemMiscArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryChargeItemMiscsArgs = {
  chargeItemUuid?: InputMaybe<Scalars['UUID']['input']>;
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryChargeItemsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryChildNodeArgs = {
  nodeId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryChildNodesArgs = {
  input?: InputMaybe<ChildNodesQueryInput>;
};


export type QueryCommodityTypesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCompaniesArgs = {
  accountTypes?: InputMaybe<Array<InputMaybe<AccountType>>>;
  countryAlpha3?: InputMaybe<Scalars['LimitedString']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  portalCompanyQueryType?: InputMaybe<PortalCompanyQueryType>;
  query?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<CompanyStatus>>;
  types?: InputMaybe<Array<InputMaybe<CompanyType>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type QueryCompanyArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryCompanyAccessArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryCompanyAccessesArgs = {
  externalBaseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<CompanyAccessStatus>>>;
  types?: InputMaybe<Array<InputMaybe<CompanyAccessType>>>;
};


export type QueryComputeRateFromBookingArgs = {
  input?: InputMaybe<ComputeRateInput>;
};


export type QueryComputedCostLastNodeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryConsolVoucherArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryConsolVouchersArgs = {
  input?: InputMaybe<SearchConsolVouchersInput>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryContactArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryContactsArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<ContactStatus>>>;
};


export type QueryContainerGradeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryContainerGradesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryContainerTypeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryContainerTypesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryCostItemsArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryCostSheetArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryCountersArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryCountriesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCurrenciesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCurrencyArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryDocumentCreatorArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryDocumentCreatorTemplateArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryDocumentCreatorTemplatesArgs = {
  byCompany?: InputMaybe<Scalars['UUID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<DocumentCreatorTemplateStatus>>>;
};


export type QueryDocumentCreatorsArgs = {
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryDriverArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  employeeUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryDriversArgs = {
  companyUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customerUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  ownerTypes?: InputMaybe<Array<InputMaybe<DriverOwnerType>>>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<DriverStatus>>>;
  subContractorUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type QueryEmailTemplateArgs = {
  countryCode?: InputMaybe<Scalars['LimitedString']['input']>;
  language?: InputMaybe<Scalars['LimitedString3']['input']>;
  name: Scalars['String']['input'];
};


export type QueryEmployeeArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryEmployeesArgs = {
  companyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryEmptyTripArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryEmptyTripsArgs = {
  input?: InputMaybe<EmptyTripQuery>;
};


export type QueryEntityDocumentArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryEstimateCostItemArgs = {
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  chargeItemUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryEventsEtcArgs = {
  input?: InputMaybe<QueryEventEtc>;
};


export type QueryEventsVoucherArgs = {
  input?: InputMaybe<QueryEventVoucher>;
};


export type QueryExchangeRateArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryExchangeRatesArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  fromUuid?: InputMaybe<Scalars['UUID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  toUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryFafArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryFafsArgs = {
  customerUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  vendorUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type QueryFuelArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryFuelsArgs = {
  input: FuelQueryInput;
};


export type QueryGateLogArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryGateLogsArgs = {
  input: GateLogQueryInput;
};


export type QueryGetLastNodeArgs = {
  input?: InputMaybe<ComputeRateInput>;
};


export type QueryGetPushNotificationArgs = {
  driverUuid?: InputMaybe<Scalars['String']['input']>;
};


export type QueryGetTransportRateArgs = {
  options: TransportRateOptions;
  selector: TransportRateSelector;
};


export type QueryGetTypeCategoriesArgs = {
  processType: Scalars['String']['input'];
};


export type QueryGetUsersArgs = {
  userUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type QueryGlCodeArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryGlCodesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<GlCodeStatus>;
};


export type QueryGroupArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryGroupsArgs = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryIncentiveArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryIncentiveRateArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryIncentiveRatesArgs = {
  input?: InputMaybe<IncentiveRateQueryInput>;
};


export type QueryIncentiveTypeArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryIncentiveTypesArgs = {
  input?: InputMaybe<IncentiveTypeQueryInput>;
};


export type QueryIncentiveVoucherArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryIncentiveVouchersArgs = {
  input: IncentiveVoucherQueryInput;
};


export type QueryIncentiveVouchersJsonArgs = {
  input?: InputMaybe<IncentiveVoucherQueryInput>;
};


export type QueryIncentivesArgs = {
  input: IncentiveQueryInput;
};


export type QueryIntegrationDetailArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryIntegrationDetailsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryIntegrationLogArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryIntegrationLogsArgs = {
  bookingUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  costItemUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  voucherUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryIntegrationMappingArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryIntegrationMappingsArgs = {
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryIntegrationsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryInviteCompanyAccessArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryInviteCompanyAccessesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryJobOptionArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryJobOptionsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryJobSummariesArgs = {
  input?: InputMaybe<JobSummariesQueryInput>;
};


export type QueryJobSummaryArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryJobTypeArgs = {
  code?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryJobTypesArgs = {
  byCompany?: InputMaybe<Scalars['UUID']['input']>;
  codes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<JobTypeStatus>>>;
};


export type QueryJobsArgs = {
  bookingUuid: Scalars['UUID']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryJobsSearchArgs = {
  input?: InputMaybe<JobsSearchInput>;
};


export type QueryJobsSearchJsonArgs = {
  input?: InputMaybe<JobsSearchInput>;
};


export type QueryLegActivitiesArgs = {
  input: LegActivitiesQuery;
};


export type QueryLegSummariesArgs = {
  input?: InputMaybe<LegSummariesQueryInput>;
};


export type QueryLegSummaryArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryManifestArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryManifestsArgs = {
  input?: InputMaybe<QueryManifestsInput>;
};


export type QueryMonitoringCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNodeTreeArgs = {
  nodeId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPaymentArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryPaymentsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  voucherUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryPermissionArgs = {
  role?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPermissionIsAllowedArgs = {
  permission?: InputMaybe<Scalars['String']['input']>;
  resource?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPlaceArgs = {
  placeId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPlacesArgs = {
  country?: InputMaybe<Scalars['LimitedString']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPortArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryPortalCompaniesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPortalCompanyArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryPortsArgs = {
  center?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  countryAlpha2?: InputMaybe<Scalars['LimitedString2']['input']>;
  countryAlpha3?: InputMaybe<Scalars['LimitedString']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Scalars['String']['input']>;
  query?: InputMaybe<Scalars['String']['input']>;
};


export type QueryPrecomputeEmptyTripArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryProcessFlowCreateArgs = {
  type: Scalars['String']['input'];
};


export type QueryProcessFlowDashboardArgs = {
  type: Scalars['String']['input'];
};


export type QueryProcessFlowMapArgs = {
  process: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type QueryProcessVehiclePlanArgs = {
  baseCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  describe?: InputMaybe<Scalars['Boolean']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  rmTripUuid?: InputMaybe<Scalars['UUID']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryQuotationArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryQuotationsArgs = {
  buyerUuid?: InputMaybe<Scalars['UUID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sellerUuid?: InputMaybe<Scalars['UUID']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<QuotationStatus>>>;
  valid?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryRatesArgs = {
  fromCity?: InputMaybe<Scalars['String']['input']>;
  fromPort?: InputMaybe<Scalars['String']['input']>;
  goodsType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  gp20?: InputMaybe<Scalars['Int']['input']>;
  gp40?: InputMaybe<Scalars['Int']['input']>;
  hc40?: InputMaybe<Scalars['Int']['input']>;
  hc45?: InputMaybe<Scalars['Int']['input']>;
  shipmentType?: InputMaybe<Scalars['String']['input']>;
  toCity?: InputMaybe<Scalars['String']['input']>;
  toPort?: InputMaybe<Scalars['String']['input']>;
};


export type QueryReportArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryReportFieldsArgs = {
  model?: InputMaybe<ReportModel>;
};


export type QueryReportJobSummariesArgs = {
  input?: InputMaybe<ReportJobSummariesQueryInput>;
};


export type QueryReportJobSummaryArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryReportMetaArgs = {
  key: Scalars['String']['input'];
};


export type QueryReportsArgs = {
  input?: InputMaybe<ReportQueryInput>;
};


export type QueryRequestEmailResultsArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryRequestTemplateArgs = {
  bookingType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<RequestType>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryReverseGeocodeArgs = {
  input?: InputMaybe<QueryReverseGeocode>;
};


export type QueryRolesArgs = {
  input?: InputMaybe<RolesQuery>;
};


export type QueryRootNodeArgs = {
  input?: InputMaybe<RootNodeQueryInput>;
};


export type QueryRootNodesArgs = {
  input?: InputMaybe<RootNodesQueryInput>;
};


export type QuerySearchArgs = {
  input?: InputMaybe<SearchInput>;
};


export type QuerySearchFmsCompaniesArgs = {
  query: Scalars['String']['input'];
};


export type QuerySettingArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySettingsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryShiftArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryShiftsArgs = {
  input: ShiftQueryInput;
};


export type QueryShiftsJsonArgs = {
  input?: InputMaybe<ShiftQueryInput>;
};


export type QueryShippingRatesArgs = {
  fourtyContainer?: InputMaybe<Scalars['Int']['input']>;
  fourtyFiveHCContainer?: InputMaybe<Scalars['Int']['input']>;
  fourtyHCContainer?: InputMaybe<Scalars['Int']['input']>;
  fromPort?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  shipmentType?: InputMaybe<Scalars['String']['input']>;
  toCity?: InputMaybe<Scalars['String']['input']>;
  twentyContainer?: InputMaybe<Scalars['Int']['input']>;
  volume?: InputMaybe<Scalars['Int']['input']>;
  weight?: InputMaybe<Scalars['Int']['input']>;
};


export type QuerySuperAdminsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTaxArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryTaxesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  showAll?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryTrailerArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryTrailersArgs = {
  companyUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customerUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<TrailerStatus>>>;
  subContractorUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type QueryTransportActivitiesArgs = {
  baseCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryTransportAreaCodeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryTransportAreaCodeFromLocationArgs = {
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  plusCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTransportAreaCodesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTransportJobArgs = {
  legUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryTransportJobTypeArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryTransportJobTypesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryTransportJobsArgs = {
  input: TransportJobQuery;
};


export type QueryTransportJobsJsonArgs = {
  input?: InputMaybe<TransportJobQuery>;
};


export type QueryTransportRateArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryTransportRatesArgs = {
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  from?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  to?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vendorUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryTransportReportJobArgs = {
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryTransportReportJobsArgs = {
  input: TransportReportJobQuery;
};


export type QueryTransportReportJobsJsonArgs = {
  input?: InputMaybe<TransportReportJobQuery>;
};


export type QueryTransportReportLegActivitiesArgs = {
  input: TransportReportLegActivitiesQuery;
};


export type QueryTransportReportLegActivitiesJsonArgs = {
  input: TransportReportLegActivitiesQuery;
};


export type QueryTransportReportTripArgs = {
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryTransportReportTripsArgs = {
  input: TransportReportTripQuery;
};


export type QueryTransportReportTripsJsonArgs = {
  input?: InputMaybe<TransportReportTripQuery>;
};


export type QueryTransportZoneArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryTransportZonesArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<TransportZoneStatus>>>;
};


export type QueryTripArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  waybillId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTripSummariesArgs = {
  input?: InputMaybe<TripSummariesQueryInput>;
};


export type QueryTripSummaryArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryTripsArgs = {
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryTruckAlertsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryTruckLocationArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  tripUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTruckLocationByTripsArgs = {
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tripUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryTruckLocationByTripsV2Args = {
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tripUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryTruckLocationLatestArgs = {
  vehicleCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTruckLocationLatestV2Args = {
  vehicleCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTruckLocationV2Args = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  tripUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleCode?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTrucksSpeedsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};


export type QueryTrucksSpeedsV2Args = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryUserArgs = {
  auth0?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryUserPreferencesArgs = {
  key?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsersArgs = {
  companyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type QueryVehicleArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryVehiclesArgs = {
  companyUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  customerUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<VehicleStatus>>>;
  subContractorUuids?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};


export type QueryVesselsArgs = {
  q?: InputMaybe<Scalars['String']['input']>;
};


export type QueryViewerArgs = {
  auth0UserId?: InputMaybe<Scalars['String']['input']>;
  userToken?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVolumeUnitArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryVolumeUnitsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryVoucherArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryVoucherItemsArgs = {
  input?: InputMaybe<VoucherItemQueryInput>;
};


export type QueryVouchersArgs = {
  input?: InputMaybe<SearchVouchersInput>;
};


export type QueryVouchersSearchArgs = {
  input?: InputMaybe<VouchersSearchInput>;
};


export type QueryVouchersSearchJsonArgs = {
  input?: InputMaybe<VouchersSearchInput>;
};


export type QueryWeightUnitArgs = {
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};


export type QueryWeightUnitsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};


export type QueryYardArgs = {
  uuid: Scalars['UUID']['input'];
};


export type QueryYardsArgs = {
  input: YardQueryInput;
};

export type QueryAvailabilitiesInput = {
  baseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<AvailabilityStatus>>>;
  types?: InputMaybe<Array<InputMaybe<EntityType>>>;
};

export type QueryEventEtc = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryEventVoucher = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type QueryManifestsInput = {
  driverUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  legUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<ManifestStatus>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type QueryReverseGeocode = {
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
};

export type Quotation = {
  __typename?: 'Quotation';
  buyerAddress?: Maybe<Address>;
  buyerContact?: Maybe<Contact>;
  buyers?: Maybe<Array<Maybe<Company>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  editBy?: Maybe<User>;
  expiry?: Maybe<Scalars['DateTime']['output']>;
  note?: Maybe<Scalars['String']['output']>;
  quotationItems?: Maybe<Array<Maybe<QuotationItem>>>;
  quotationNo?: Maybe<Scalars['String']['output']>;
  sellerAddress?: Maybe<Address>;
  sellerContact?: Maybe<Contact>;
  sellers?: Maybe<Array<Maybe<Company>>>;
  status?: Maybe<QuotationStatus>;
  tags?: Maybe<Array<Maybe<QuotationTagTypes>>>;
  term?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type QuotationItem = {
  __typename?: 'QuotationItem';
  chargeItem?: Maybe<ChargeItem>;
  containerType?: Maybe<Scalars['String']['output']>;
  costCurrency?: Maybe<Currency>;
  costRate?: Maybe<Scalars['Float']['output']>;
  costRateId?: Maybe<Scalars['String']['output']>;
  costTax?: Maybe<Tax>;
  description?: Maybe<Scalars['String']['output']>;
  maxChargeAmount?: Maybe<Scalars['Float']['output']>;
  minChargeAmount?: Maybe<Scalars['Float']['output']>;
  quotationUuid?: Maybe<Scalars['UUID']['output']>;
  rateType?: Maybe<ChargeRateType>;
  sellCurrency?: Maybe<Currency>;
  sellRate?: Maybe<Scalars['Float']['output']>;
  sellRateId?: Maybe<Scalars['String']['output']>;
  sellTax?: Maybe<Tax>;
  size?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<QuotationItemTags>>>;
  unit?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum QuotationItemTags {
  IsCon = 'isCon',
  IsDefault = 'isDefault',
  IsFcl = 'isFcl',
  IsLcl = 'isLcl',
  IsPrint = 'isPrint'
}

export enum QuotationStatus {
  Activated = 'activated',
  Deleted = 'deleted'
}

export enum QuotationTagTypes {
  AllowImportCustomsSubmitBeforeInvoice = 'ALLOW_IMPORT_CUSTOMS_SUBMIT_BEFORE_INVOICE',
  AutoApproveExportCustoms = 'AUTO_APPROVE_EXPORT_CUSTOMS',
  AutoApproveImportCustoms = 'AUTO_APPROVE_IMPORT_CUSTOMS',
  BillToConsignee = 'BILL_TO_CONSIGNEE',
  BillToForwarder = 'BILL_TO_FORWARDER',
  ServiceTypeForwarding = 'SERVICE_TYPE_FORWARDING',
  ServiceTypeFreightforwarding = 'SERVICE_TYPE_FREIGHTFORWARDING',
  ServiceTypeHaulage = 'SERVICE_TYPE_HAULAGE',
  ServiceTypeTransport = 'SERVICE_TYPE_TRANSPORT'
}

export type Quotations = {
  __typename?: 'Quotations';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Quotation>>>;
};

export type Rate = {
  __typename?: 'Rate';
  name?: Maybe<Scalars['String']['output']>;
};

export type RateLogItem = {
  __typename?: 'RateLogItem';
  currentTotal?: Maybe<Scalars['Float']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  operation?: Maybe<Scalars['String']['output']>;
};

export type RateValue = {
  __typename?: 'RateValue';
  brackets?: Maybe<Array<Maybe<RateValueBracket>>>;
  chargeItemCode?: Maybe<Scalars['String']['output']>;
  op?: Maybe<Scalars['String']['output']>;
  operationIndex?: Maybe<Scalars['Int']['output']>;
  unitField?: Maybe<Scalars['String']['output']>;
  val?: Maybe<Scalars['JSON']['output']>;
};

export type RateValueBracket = {
  __typename?: 'RateValueBracket';
  maxCutoff?: Maybe<Scalars['Int']['output']>;
  minCutoff?: Maybe<Scalars['Int']['output']>;
  op?: Maybe<Scalars['String']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  val?: Maybe<Scalars['JSON']['output']>;
};

export type RateValueBracketInput = {
  maxCutoff?: InputMaybe<Scalars['Int']['input']>;
  minCutoff?: InputMaybe<Scalars['Int']['input']>;
  op?: InputMaybe<RateValueOp>;
  unit?: InputMaybe<Scalars['String']['input']>;
  val?: InputMaybe<Scalars['JSON']['input']>;
};

export type RateValueInput = {
  brackets?: InputMaybe<Array<InputMaybe<RateValueBracketInput>>>;
  chargeItemCode?: InputMaybe<Scalars['String']['input']>;
  op?: InputMaybe<RateValueOp>;
  operationIndex?: InputMaybe<Scalars['Int']['input']>;
  unitField?: InputMaybe<Scalars['String']['input']>;
  val?: InputMaybe<Scalars['JSON']['input']>;
};

export enum RateValueOp {
  Add = 'ADD',
  Base = 'BASE',
  Cascade = 'CASCADE',
  Divide = 'DIVIDE',
  Multiply = 'MULTIPLY',
  Subtract = 'SUBTRACT',
  Unit = 'UNIT'
}

export type RemoveManifestLegsInput = {
  legUuid?: InputMaybe<Scalars['UUID']['input']>;
  manifestUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type Report = {
  __typename?: 'Report';
  categories?: Maybe<Array<Maybe<ReportCategories>>>;
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  data?: Maybe<Scalars['JSON']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  model?: Maybe<ReportModel>;
  name?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  portalCompanies?: Maybe<Array<Maybe<PortalCompany>>>;
  sorts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<ReportStatus>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum ReportAggregationType {
  Avg = 'avg',
  First = 'first',
  Max = 'max',
  Min = 'min',
  Sum = 'sum'
}

export enum ReportCategories {
  Finance = 'finance',
  Management = 'management',
  Operation = 'operation'
}

export type ReportColumn = {
  __typename?: 'ReportColumn';
  formatFnc?: Maybe<Array<Maybe<ReportFormatFnc>>>;
  formatParams?: Maybe<Array<Maybe<Array<Maybe<Scalars['String']['output']>>>>>;
  header?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export type ReportColumnInput = {
  formatFnc?: InputMaybe<Array<InputMaybe<ReportFormatFnc>>>;
  formatParams?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>>>;
  header?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type ReportField = {
  __typename?: 'ReportField';
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type ReportFieldsInput = {
  aggregation?: InputMaybe<ReportAggregationType>;
  aggregationLayer?: InputMaybe<Scalars['Int']['input']>;
  formatFnc?: InputMaybe<Array<InputMaybe<ReportFormatFnc>>>;
  formatParams?: InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>>>;
  header?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
};

export type ReportFilter = {
  __typename?: 'ReportFilter';
  ctrlType?: Maybe<Scalars['String']['output']>;
  dataType?: Maybe<Scalars['String']['output']>;
  defaultShow?: Maybe<Scalars['Boolean']['output']>;
  defaultValues?: Maybe<Scalars['JSON']['output']>;
  isArray?: Maybe<Scalars['Boolean']['output']>;
  key?: Maybe<Scalars['String']['output']>;
};

export enum ReportFormatFnc {
  DateDiff = 'dateDiff',
  DateFormat = 'dateFormat'
}

export type ReportJobSummaries = {
  __typename?: 'ReportJobSummaries';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<ReportJobSummary>>>;
};

export type ReportJobSummariesQueryInput = {
  baseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ReportJobSummary = {
  __typename?: 'ReportJobSummary';
  _id?: Maybe<Scalars['UUID']['output']>;
  bcUuid?: Maybe<Scalars['UUID']['output']>;
  date?: Maybe<Scalars['DateTime']['output']>;
  depts?: Maybe<Array<Maybe<ReportJobSummaryDept>>>;
  drUtil?: Maybe<Array<Maybe<DriverUtilDept>>>;
  jobUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  metrics?: Maybe<Scalars['JSON']['output']>;
  pmUtil?: Maybe<Array<Maybe<PmUtilDept>>>;
  ts?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  zones?: Maybe<Array<Maybe<ReportJobSummaryZone>>>;
};

export type ReportJobSummaryDept = {
  __typename?: 'ReportJobSummaryDept';
  cost?: Maybe<Scalars['Float']['output']>;
  dept?: Maybe<Scalars['String']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  sell?: Maybe<Scalars['Float']['output']>;
  teu?: Maybe<Scalars['Int']['output']>;
  trips?: Maybe<Scalars['JSON']['output']>;
};

export type ReportJobSummaryZone = {
  __typename?: 'ReportJobSummaryZone';
  cost?: Maybe<Scalars['Float']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  revenue?: Maybe<Scalars['Float']['output']>;
  sell?: Maybe<Scalars['Float']['output']>;
  teu?: Maybe<Scalars['Int']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  trips?: Maybe<Scalars['JSON']['output']>;
};

export type ReportMeta = {
  __typename?: 'ReportMeta';
  categories?: Maybe<Array<Maybe<ReportCategories>>>;
  columns?: Maybe<Array<Maybe<ReportColumn>>>;
  description?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Array<Maybe<ReportFilter>>>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sorts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export enum ReportModel {
  Booking = 'Booking',
  CostItem = 'CostItem',
  Job = 'Job',
  TransportJob = 'TransportJob',
  Voucher = 'Voucher',
  VoucherItem = 'VoucherItem'
}

export type ReportPortalCompany = {
  __typename?: 'ReportPortalCompany';
  hours?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  portalCompanyUuid?: Maybe<Scalars['UUID']['output']>;
  report?: Maybe<Report>;
  reportUuid?: Maybe<Scalars['UUID']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type ReportQueryInput = {
  categories?: InputMaybe<Array<InputMaybe<ReportCategories>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export enum ReportStatus {
  Activated = 'activated',
  Deleted = 'deleted'
}

export type ReportSummary = {
  __typename?: 'ReportSummary';
  categories?: Maybe<Array<Maybe<ReportCategories>>>;
  description?: Maybe<Scalars['String']['output']>;
  key?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Reports = {
  __typename?: 'Reports';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Report>>>;
};

export type Request = {
  __typename?: 'Request';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  from?: Maybe<User>;
  meta?: Maybe<RequestMetaType>;
  remarks?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type RequestBookingDocument = {
  hardCopy?: InputMaybe<Scalars['Boolean']['input']>;
  softCopy?: InputMaybe<Scalars['Boolean']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type RequestDocumentTypesInput = {
  types?: InputMaybe<Array<InputMaybe<RequestBookingDocument>>>;
};

export type RequestEmailResultsType = {
  __typename?: 'RequestEmailResultsType';
  event?: Maybe<Scalars['String']['output']>;
  logLevel?: Maybe<Scalars['String']['output']>;
  recipient?: Maybe<Scalars['String']['output']>;
  timestamp?: Maybe<Scalars['DateTime']['output']>;
};

export type RequestMetaType = {
  __typename?: 'RequestMetaType';
  attachments?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  jobs?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
};

export type RequestTemplate = {
  __typename?: 'RequestTemplate';
  template?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  vendorType?: Maybe<Scalars['String']['output']>;
};

export enum RequestType {
  ExpBillOfLadingDraftLinerConfirmationRequest = 'ExpBillOfLadingDraftLinerConfirmationRequest',
  ExpBillOfLadingDraftShipperConfirmationRequest = 'ExpBillOfLadingDraftShipperConfirmationRequest',
  ExpCompOutstandingSupplierPaymentNotification = 'ExpCompOutstandingSupplierPaymentNotification',
  ExpCustShipperDraftNotification = 'ExpCustShipperDraftNotification',
  ExpCustShipperDraftNotificationAutoApprove = 'ExpCustShipperDraftNotificationAutoApprove',
  ExpHlgContainerMovementOrder = 'ExpHlgContainerMovementOrder',
  ExpHlgRequestForCollection = 'ExpHlgRequestForCollection',
  ExpHlgRequestOfTransport = 'ExpHlgRequestOfTransport',
  ExpHlgRequestOfTransportAttachments = 'ExpHlgRequestOfTransportAttachments',
  ExpLinerBookingConfirmationCustomerRequest = 'ExpLinerBookingConfirmationCustomerRequest',
  ExpLinerContainerMovementOrder = 'ExpLinerContainerMovementOrder',
  ExpLinerLinerPaymentNotify = 'ExpLinerLinerPaymentNotify',
  ExpLinerNotifyCustomerComplete = 'ExpLinerNotifyCustomerComplete',
  ExpLinerNotifyForeignAgentOfWaybill = 'ExpLinerNotifyForeignAgentOfWaybill',
  ExpLinerPendNotifyForeignAgentOfWaybill = 'ExpLinerPendNotifyForeignAgentOfWaybill',
  ExpLinerShippingInstructions = 'ExpLinerShippingInstructions',
  ExpRequestBookingConfirmationFromLiner = 'ExpRequestBookingConfirmationFromLiner',
  ExpRequestBookingConfirmationReeferFromLiner = 'ExpRequestBookingConfirmationReeferFromLiner',
  ExpShipperCustomsDocumentDraftNotiRequest = 'ExpShipperCustomsDocumentDraftNotiRequest',
  ImpCompOutstandingSupplierPaymentNotification = 'ImpCompOutstandingSupplierPaymentNotification',
  ImpCustConsigneeCustomsPaymentCashNotification = 'ImpCustConsigneeCustomsPaymentCashNotification',
  ImpCustConsigneeDraftNotification = 'ImpCustConsigneeDraftNotification',
  ImpCustConsigneeDraftNotificationAutoApprove = 'ImpCustConsigneeDraftNotificationAutoApprove',
  ImpEdoRequisition = 'ImpEdoRequisition',
  ImpFreightEdoExternalForwarderNotice = 'ImpFreightEdoExternalForwarderNotice',
  ImpFreightNoaExternalForwarderNotice = 'ImpFreightNoaExternalForwarderNotice',
  ImpHlgRequestForCollection = 'ImpHlgRequestForCollection',
  ImpHlgRequestOfTransport = 'ImpHlgRequestOfTransport',
  ImpHlgRequestOfTransportExtraDocs = 'ImpHlgRequestOfTransportExtraDocs',
  ImpLoiContainerDepositRequisition = 'ImpLoiContainerDepositRequisition',
  ImpNoaHouseRequisition = 'ImpNoaHouseRequisition',
  ImpNoaRequisition = 'ImpNoaRequisition',
  RequestExportDocuments = 'RequestExportDocuments',
  RequestImportDocuments = 'RequestImportDocuments'
}

export type RequisitionTemplate = {
  __typename?: 'RequisitionTemplate';
  bcc?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  cc?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  fields?: Maybe<EmailRequestFields>;
  template?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  type?: Maybe<RequestType>;
  uuid: Scalars['UUID']['output'];
};

export type Response = {
  __typename?: 'Response';
  message?: Maybe<Scalars['String']['output']>;
  success?: Maybe<Scalars['Boolean']['output']>;
};

export type ReverseGeoAddress = {
  __typename?: 'ReverseGeoAddress';
  city?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  county?: Maybe<Scalars['String']['output']>;
  district?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
};

export type Role = {
  __typename?: 'Role';
  baseCompanyUuid?: Maybe<Scalars['UUID']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  editBy?: Maybe<User>;
  editByUuid?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<RoleStatus>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum RoleStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED'
}

export enum RoleTag {
  PortalAdmin = 'portalAdmin',
  PortalUser = 'portalUser'
}

export type Roles = {
  __typename?: 'Roles';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Role>>>;
};

export type RolesQuery = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  noTags?: InputMaybe<Scalars['Boolean']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<RoleStatus>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type RootNode = {
  __typename?: 'RootNode';
  _id?: Maybe<Scalars['ObjectID']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  keyPreference?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ActiveStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type RootNodeQueryInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  nodeId?: InputMaybe<Scalars['String']['input']>;
};

export type RootNodes = {
  __typename?: 'RootNodes';
  pageInfo?: Maybe<PageInfo>;
  rows?: Maybe<Array<Maybe<RootNode>>>;
};

export type RootNodesQueryInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nodeIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
};

export enum RuleCondition {
  And = 'AND',
  Or = 'OR'
}

export type SRate = {
  __typename?: 'SRate';
  currency?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Int']['output']>;
};

export type SeaPath = {
  __typename?: 'SeaPath';
  distance?: Maybe<Scalars['Float']['output']>;
  distanceSeca?: Maybe<Scalars['Int']['output']>;
  fromPort?: Maybe<Scalars['String']['output']>;
  toPort?: Maybe<Scalars['String']['output']>;
  waypoint?: Maybe<Array<Maybe<Waypoint>>>;
};

export type SeaRoute = {
  __typename?: 'SeaRoute';
  code?: Maybe<Scalars['Int']['output']>;
  crossSeca?: Maybe<Scalars['Boolean']['output']>;
  dayLimit?: Maybe<Scalars['Int']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  section?: Maybe<Array<Maybe<SeaPath>>>;
  todayUsed?: Maybe<Scalars['Int']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  totalDistance?: Maybe<Scalars['Float']['output']>;
  totalSecaDistance?: Maybe<Scalars['Int']['output']>;
};

export type Seaport = {
  __typename?: 'Seaport';
  alternativeName?: Maybe<Scalars['String']['output']>;
  alternativeNameCaps?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  dayLightSavings?: Maybe<Scalars['String']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  name?: Maybe<Scalars['String']['output']>;
  nameCaps?: Maybe<Scalars['String']['output']>;
  portsDbId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  unlocode?: Maybe<Scalars['String']['output']>;
};

export type Search = {
  __typename?: 'Search';
  hits?: Maybe<Array<Maybe<SearchResult>>>;
  page?: Maybe<Scalars['Int']['output']>;
  params?: Maybe<Scalars['String']['output']>;
  query?: Maybe<Scalars['String']['output']>;
};

export type SearchInput = {
  body?: InputMaybe<Scalars['String']['input']>;
  index?: InputMaybe<Scalars['String']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type SearchResult = Booking | Voucher;

export type Service = {
  __typename?: 'Service';
  company?: Maybe<Company>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type ServiceInput = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type Setting = {
  __typename?: 'Setting';
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type SettingInput = {
  type?: InputMaybe<CounterType>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type Shift = {
  __typename?: 'Shift';
  _id?: Maybe<Scalars['UUID']['output']>;
  amount?: Maybe<Scalars['Float']['output']>;
  approvedAt?: Maybe<Scalars['DateTime']['output']>;
  approvedBy?: Maybe<User>;
  approvedFinanceAt?: Maybe<Scalars['DateTime']['output']>;
  approvedFinanceBy?: Maybe<User>;
  approvedFinanceUuid?: Maybe<Scalars['UUID']['output']>;
  approvedUuid?: Maybe<Scalars['UUID']['output']>;
  bookingUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  breakDuration?: Maybe<Scalars['Int']['output']>;
  breaks?: Maybe<Array<Maybe<ShiftBreak>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  createdUuid?: Maybe<Scalars['UUID']['output']>;
  driver?: Maybe<Driver>;
  driverUuid?: Maybe<Scalars['UUID']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  incentiveCategories?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  incentiveCategorySum?: Maybe<Array<Maybe<IncentiveCategorySum>>>;
  incentiveTypeSum?: Maybe<Array<Maybe<IncentiveTypeSum>>>;
  incentiveTypeUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  incentives?: Maybe<Array<Maybe<Incentive>>>;
  jobUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  legUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  no?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  references?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  remarks?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
  status?: Maybe<ShiftStatus>;
  tripUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  updatedUuid?: Maybe<Scalars['UUID']['output']>;
  vehicle?: Maybe<Vehicle>;
  vehicleUuid?: Maybe<Scalars['UUID']['output']>;
};

export type ShiftBreak = {
  __typename?: 'ShiftBreak';
  end?: Maybe<Scalars['DateTime']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
};

export type ShiftBreakInput = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ShiftQueryInput = {
  _ids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  bookingUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  driverUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  financeApproveEnd?: InputMaybe<Scalars['DateTime']['input']>;
  financeApproveStart?: InputMaybe<Scalars['DateTime']['input']>;
  incentiveCategories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  incentiveTypeUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  jsonQueryOptions?: InputMaybe<Array<InputMaybe<ShiftQueryOptions>>>;
  legUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  nos?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  startEnd?: InputMaybe<Scalars['DateTime']['input']>;
  startStart?: InputMaybe<Scalars['DateTime']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<ShiftStatus>>>;
  tripUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  vehicleUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export enum ShiftQueryOptions {
  Drivers = 'drivers',
  IncentiveTypes = 'incentiveTypes',
  Incentives = 'incentives',
  TransportJobs = 'transportJobs',
  Users = 'users',
  Vehicles = 'vehicles'
}

export enum ShiftStatus {
  Approved = 'APPROVED',
  Approvedfinance = 'APPROVEDFINANCE',
  Cancelled = 'CANCELLED',
  Closed = 'CLOSED',
  Open = 'OPEN'
}

export type Shifts = {
  __typename?: 'Shifts';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Shift>>>;
};

export enum ShipmentTypeInput {
  Air = 'AIR',
  Fcl = 'FCL',
  Lcl = 'LCL',
  Sc = 'SC',
  Transport = 'TRANSPORT'
}

export type ShippingRate = {
  __typename?: 'ShippingRate';
  days?: Maybe<Scalars['String']['output']>;
  effective?: Maybe<Scalars['String']['output']>;
  expiry?: Maybe<Scalars['String']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  gp20?: Maybe<SRate>;
  gp40?: Maybe<SRate>;
  hc40?: Maybe<SRate>;
  liner?: Maybe<Scalars['String']['output']>;
  route?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['String']['output']>;
  updatedBy?: Maybe<Scalars['String']['output']>;
};

export type ShippingRateType = {
  __typename?: 'ShippingRateType';
  fourtyContainer?: Maybe<Scalars['Int']['output']>;
  fourtyFiveHCContainer?: Maybe<Scalars['Int']['output']>;
  fourtyHCContainer?: Maybe<Scalars['Int']['output']>;
  twentyContainer?: Maybe<Scalars['Int']['output']>;
  volume?: Maybe<Scalars['Int']['output']>;
  weight?: Maybe<Scalars['Int']['output']>;
};

export type ShippingRates = {
  __typename?: 'ShippingRates';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<ShippingRate>>>;
};

export type SubmitRotInput = {
  attachments?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  billToUuid?: InputMaybe<Scalars['UUID']['input']>;
  bookingUuid: Scalars['UUID']['input'];
  customerRef?: InputMaybe<Scalars['String']['input']>;
  customerRef2?: InputMaybe<Scalars['String']['input']>;
  deliveryAddressUuid?: InputMaybe<Scalars['UUID']['input']>;
  deliveryHour?: InputMaybe<Scalars['String']['input']>;
  dropoffAddressUuid?: InputMaybe<Scalars['UUID']['input']>;
  dropoffCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  haulierUuid?: InputMaybe<Scalars['UUID']['input']>;
  jobs?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  pickupAddressUuid?: InputMaybe<Scalars['UUID']['input']>;
  pickupCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type SuperAdmins = {
  __typename?: 'SuperAdmins';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<User>>>;
};

export type Tax = {
  __typename?: 'Tax';
  code?: Maybe<Scalars['String']['output']>;
  exemptionDocumentUuid?: Maybe<Scalars['UUID']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  percentage?: Maybe<Scalars['Float']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type Taxes = {
  __typename?: 'Taxes';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Tax>>>;
};

export type Template = {
  __typename?: 'Template';
  content: Scalars['String']['output'];
};

export type Trailer = {
  __typename?: 'Trailer';
  code?: Maybe<Scalars['String']['output']>;
  companies?: Maybe<Array<Maybe<EntityCompany>>>;
  customerAddress?: Maybe<Array<Maybe<EntityCompanyAddress>>>;
  customers?: Maybe<Array<Maybe<EntityCompany>>>;
  department?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<DocumentDescription>>>;
  entityException?: Maybe<EntityExceptionOutcome>;
  image?: Maybe<Attachment>;
  loadCapacity?: Maybe<Scalars['Int']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  manufactureYear?: Maybe<Scalars['Int']['output']>;
  ownerType?: Maybe<TrailerOwnerType>;
  registration?: Maybe<Scalars['String']['output']>;
  registrationYear?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TrailerStatus>;
  subContractors?: Maybe<Array<Maybe<EntityCompany>>>;
  tareWeight?: Maybe<Scalars['Int']['output']>;
  trailerType?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum TrailerOwnerType {
  Interbranch = 'INTERBRANCH',
  Intercompany = 'INTERCOMPANY',
  Outsource = 'OUTSOURCE',
  Owned = 'OWNED'
}

export enum TrailerStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  Service = 'SERVICE',
  Sold = 'SOLD'
}

export type TrailersResults = {
  __typename?: 'TrailersResults';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Trailer>>>;
};

export enum TransactionType {
  Accpay = 'ACCPAY',
  Accrec = 'ACCREC'
}

export type Transport = {
  __typename?: 'Transport';
  company?: Maybe<Company>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type TransportActivity = {
  __typename?: 'TransportActivity';
  code?: Maybe<Scalars['String']['output']>;
  compile?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  driverCode?: Maybe<Scalars['String']['output']>;
  driverEntity?: Maybe<Driver>;
  driverNick?: Maybe<Scalars['String']['output']>;
  driverUuid?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  short?: Maybe<Scalars['String']['output']>;
  trailerCode?: Maybe<Scalars['String']['output']>;
  trailerEntity?: Maybe<Trailer>;
  trailerNick?: Maybe<Scalars['String']['output']>;
  trailerUuid?: Maybe<Scalars['UUID']['output']>;
  ts?: Maybe<Scalars['DateTime']['output']>;
  type?: Maybe<TransportActivityType>;
  vehicleCode?: Maybe<Scalars['String']['output']>;
  vehicleDepartments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleEntity?: Maybe<Vehicle>;
  vehicleNick?: Maybe<Scalars['String']['output']>;
  vehicleUuid?: Maybe<Scalars['UUID']['output']>;
};

export type TransportActivityFlow = {
  __typename?: 'TransportActivityFlow';
  code?: Maybe<Scalars['String']['output']>;
  compile?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  next?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  shipXTrigger?: Maybe<TransportActivityShipXTrigger>;
  short?: Maybe<Scalars['String']['output']>;
};

export type TransportActivityShipXTrigger = {
  __typename?: 'TransportActivityShipXTrigger';
  date?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum TransportActivityType {
  Created = 'created',
  End = 'end',
  EndOut = 'endOut',
  PlanEnd = 'planEnd',
  PlanStart = 'planStart',
  Start = 'start',
  StartOut = 'startOut'
}

export enum TransportActivityVerb {
  Add = 'ADD',
  Remove = 'REMOVE'
}

export type TransportAreaCode = {
  __typename?: 'TransportAreaCode';
  category?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Geometry>;
  locationPolygon?: Maybe<GeometryPolygon>;
  name?: Maybe<Scalars['String']['output']>;
  tags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  zone?: Maybe<Scalars['String']['output']>;
};

export type TransportAreaCodes = {
  __typename?: 'TransportAreaCodes';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<TransportAreaCode>>>;
};

export type TransportInput = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type TransportJob = {
  __typename?: 'TransportJob';
  _id?: Maybe<Scalars['UUID']['output']>;
  _tags?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  address?: Maybe<Scalars['JSON']['output']>;
  assistantUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  assistants?: Maybe<Array<Maybe<Driver>>>;
  billToCode?: Maybe<Scalars['String']['output']>;
  billToDept?: Maybe<Scalars['String']['output']>;
  billToName?: Maybe<Scalars['String']['output']>;
  billToSource?: Maybe<TransportSource>;
  billToUuid?: Maybe<Scalars['UUID']['output']>;
  booking?: Maybe<Booking>;
  bookingDepts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  bookingDetails?: Maybe<Scalars['JSON']['output']>;
  bookingNo?: Maybe<Scalars['String']['output']>;
  bookingStatus?: Maybe<BookingStatus>;
  bookingTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  bookingType?: Maybe<Scalars['String']['output']>;
  bookingUuid?: Maybe<Scalars['UUID']['output']>;
  conDept?: Maybe<Scalars['String']['output']>;
  consigneeAddress?: Maybe<Address>;
  consigneeCode?: Maybe<Scalars['String']['output']>;
  consigneeName?: Maybe<Scalars['String']['output']>;
  consigneeRequiredDate?: Maybe<Scalars['DateTime']['output']>;
  consigneeUuid?: Maybe<Scalars['UUID']['output']>;
  dFirstTs?: Maybe<Scalars['String']['output']>;
  dLastTs?: Maybe<Scalars['String']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  dept?: Maybe<Scalars['String']['output']>;
  driverCode?: Maybe<Scalars['String']['output']>;
  driverEntity?: Maybe<Driver>;
  driverIc?: Maybe<Scalars['String']['output']>;
  driverName?: Maybe<Scalars['String']['output']>;
  driverPhoneNo?: Maybe<Scalars['String']['output']>;
  driverUuid?: Maybe<Scalars['UUID']['output']>;
  end?: Maybe<Scalars['DateTime']['output']>;
  endOut?: Maybe<Scalars['DateTime']['output']>;
  firstTs?: Maybe<Scalars['DateTime']['output']>;
  from?: Maybe<Scalars['JSON']['output']>;
  fromAddressUuid?: Maybe<Scalars['UUID']['output']>;
  fromCompanyUuid?: Maybe<Scalars['UUID']['output']>;
  incentiveVouchers?: Maybe<Array<Maybe<IncentiveVoucher>>>;
  jobDetails?: Maybe<Scalars['JSON']['output']>;
  jobNo?: Maybe<Scalars['String']['output']>;
  jobStatus?: Maybe<JobStatus>;
  jobType?: Maybe<Scalars['String']['output']>;
  jobUuid?: Maybe<Scalars['UUID']['output']>;
  lastTs?: Maybe<Scalars['DateTime']['output']>;
  legStatus?: Maybe<LegStatus>;
  legUuid?: Maybe<Scalars['UUID']['output']>;
  manifestUuid?: Maybe<Scalars['UUID']['output']>;
  no?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  planEnd?: Maybe<Scalars['DateTime']['output']>;
  planStart?: Maybe<Scalars['DateTime']['output']>;
  references?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  remarks?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['Int']['output']>;
  sequenceTotal?: Maybe<Scalars['Int']['output']>;
  shipDept?: Maybe<Scalars['String']['output']>;
  shipOutDate?: Maybe<Scalars['DateTime']['output']>;
  shipperAddress?: Maybe<Address>;
  shipperCode?: Maybe<Scalars['String']['output']>;
  shipperName?: Maybe<Scalars['String']['output']>;
  shipperRequiredDate?: Maybe<Scalars['DateTime']['output']>;
  shipperUuid?: Maybe<Scalars['UUID']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
  startOut?: Maybe<Scalars['DateTime']['output']>;
  tLastTs?: Maybe<Scalars['String']['output']>;
  to?: Maybe<Scalars['JSON']['output']>;
  toAddressUuid?: Maybe<Scalars['UUID']['output']>;
  toCompanyUuid?: Maybe<Scalars['UUID']['output']>;
  trailerCode?: Maybe<Scalars['String']['output']>;
  trailerEntity?: Maybe<Trailer>;
  trailerName?: Maybe<Scalars['String']['output']>;
  trailerUuid?: Maybe<Scalars['UUID']['output']>;
  transportName?: Maybe<Scalars['String']['output']>;
  transportSource?: Maybe<TransportSource>;
  transportUuid?: Maybe<Scalars['UUID']['output']>;
  tripDetails?: Maybe<Scalars['JSON']['output']>;
  tripFormat?: Maybe<Scalars['String']['output']>;
  tripReferences?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  tripRemarks?: Maybe<Scalars['String']['output']>;
  tripSeal?: Maybe<Scalars['String']['output']>;
  tripSequence?: Maybe<Scalars['Int']['output']>;
  tripStatus?: Maybe<TripStatus>;
  tripType?: Maybe<Scalars['String']['output']>;
  tripUuid?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<LegType>;
  uuid?: Maybe<Scalars['String']['output']>;
  vFirstTs?: Maybe<Scalars['String']['output']>;
  vLastTs?: Maybe<Scalars['String']['output']>;
  vehicleCode?: Maybe<Scalars['String']['output']>;
  vehicleDepartments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleEntity?: Maybe<Vehicle>;
  vehicleName?: Maybe<Scalars['String']['output']>;
  vehicleUuid?: Maybe<Scalars['UUID']['output']>;
};

export type TransportJobQuery = {
  _tags?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  activityEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  activityStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  baseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  billToSources?: InputMaybe<Array<InputMaybe<TransportSource>>>;
  billToUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  bookingDepts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bookingStatuses?: InputMaybe<Array<InputMaybe<BookingStatus>>>;
  bookingTags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bookingTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bookingUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  consigneeUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  driverCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  driverUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  fromCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fromZones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  isSearchAllFields?: InputMaybe<Scalars['Boolean']['input']>;
  isSearchCaseSensitive?: InputMaybe<Scalars['Boolean']['input']>;
  jobStatuses?: InputMaybe<Array<InputMaybe<JobStatus>>>;
  jobTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  jsonQueryOptions?: InputMaybe<Array<InputMaybe<TransportJobQueryOptions>>>;
  legStatuses?: InputMaybe<Array<InputMaybe<LegStatus>>>;
  legUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  secondaryPreferred?: InputMaybe<Scalars['Boolean']['input']>;
  shipOutEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  shipOutStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  shipperRequiredDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  shipperRequiredDateStart?: InputMaybe<Scalars['DateTime']['input']>;
  shipperUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  toCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  toZones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transportSources?: InputMaybe<Array<InputMaybe<TransportSource>>>;
  transportType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tripStatuses?: InputMaybe<Array<InputMaybe<TripStatus>>>;
  tripTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tripUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  vehicleCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export enum TransportJobQueryOptions {
  Drivers = 'drivers',
  IncentiveVouchers = 'incentiveVouchers',
  Trailers = 'trailers',
  Vehicles = 'vehicles',
  VoucherItems = 'voucherItems'
}

export type TransportJobType = {
  __typename?: 'TransportJobType';
  baseCompanyUuid?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sorting?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum TransportJobTypeStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type TransportJobTypes = {
  __typename?: 'TransportJobTypes';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<TransportJobType>>>;
};

export type TransportJobs = {
  __typename?: 'TransportJobs';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<TransportJob>>>;
};

export type TransportQueueRunnerInput = {
  key?: InputMaybe<Scalars['String']['input']>;
  queueContent?: InputMaybe<Scalars['JSON']['input']>;
  queueName?: InputMaybe<Scalars['String']['input']>;
};

export type TransportRate = {
  __typename?: 'TransportRate';
  customer?: Maybe<Company>;
  distance?: Maybe<Scalars['Int']['output']>;
  from?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  rates?: Maybe<Array<Maybe<TransportRateDetails>>>;
  to?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  vendor?: Maybe<Company>;
};

export enum TransportRateCargoTypes {
  Dg1 = 'DG1',
  Dg2 = 'DG2',
  Dg3 = 'DG3',
  Fr = 'FR',
  Gp = 'GP',
  Hq = 'HQ',
  Ht = 'HT',
  Ol = 'OL',
  Ot = 'OT',
  Rf = 'RF',
  Tk = 'TK',
  Up = 'UP'
}

export type TransportRateDetails = {
  __typename?: 'TransportRateDetails';
  cargoType?: Maybe<TransportRateCargoTypes>;
  rate?: Maybe<Scalars['Float']['output']>;
  rateRule?: Maybe<ChargeRateRuleType>;
  rateType?: Maybe<ChargeRateType>;
  rateUom?: Maybe<Scalars['String']['output']>;
  rateValue?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['Float']['output']>;
  transactionType?: Maybe<TransportRateTransactionType>;
  transportType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum TransportRateDetailsType {
  Divert = 'DIVERT',
  Faf = 'FAF',
  Haul = 'HAUL',
  Rebate = 'REBATE',
  Stag = 'STAG',
  Toll = 'TOLL',
  Trip = 'TRIP'
}

export enum TransportRateTransactionType {
  Cost = 'COST',
  Sell = 'SELL'
}

export type TransportRates = {
  __typename?: 'TransportRates';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<TransportRate>>>;
};

export type TransportReportJob = {
  __typename?: 'TransportReportJob';
  _id?: Maybe<Scalars['UUID']['output']>;
  actualDistanceKm?: Maybe<Scalars['Int']['output']>;
  actualTravelTimeMins?: Maybe<Scalars['Int']['output']>;
  billToAccount?: Maybe<Scalars['UUID']['output']>;
  billToCode?: Maybe<Scalars['String']['output']>;
  billToName?: Maybe<Scalars['String']['output']>;
  billToSource?: Maybe<TransportSource>;
  billToUuid?: Maybe<Scalars['UUID']['output']>;
  booking?: Maybe<Booking>;
  bookingDepts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  bookingDetails?: Maybe<Scalars['JSON']['output']>;
  bookingDocumentsSummary?: Maybe<Scalars['String']['output']>;
  bookingNo?: Maybe<Scalars['String']['output']>;
  bookingStatus?: Maybe<BookingStatus>;
  bookingTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  bookingType?: Maybe<Scalars['String']['output']>;
  bookingUuid?: Maybe<Scalars['UUID']['output']>;
  consigneeAddress?: Maybe<Address>;
  consigneeAddressCache?: Maybe<Scalars['JSON']['output']>;
  consigneeName?: Maybe<Scalars['String']['output']>;
  consigneeRequiredDate?: Maybe<Scalars['DateTime']['output']>;
  consigneeUuid?: Maybe<Scalars['UUID']['output']>;
  costItemAPDraftTotal?: Maybe<Scalars['Float']['output']>;
  costItemAPTotal?: Maybe<Scalars['Float']['output']>;
  costItemARDraftTotal?: Maybe<Scalars['Float']['output']>;
  costItemARTotal?: Maybe<Scalars['Float']['output']>;
  costItemCostCurrencies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  costItemCostLocalTotal?: Maybe<Scalars['Float']['output']>;
  costItemCostTotal?: Maybe<Scalars['Float']['output']>;
  costItemLocalCurrency?: Maybe<Scalars['String']['output']>;
  costItemSellCurrencies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  costItemSellLocalTotal?: Maybe<Scalars['Float']['output']>;
  costItemSellTotal?: Maybe<Scalars['Float']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  driverCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  driverEntities?: Maybe<Array<Maybe<Driver>>>;
  driverNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  driverUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  estimatedDistanceKm?: Maybe<Scalars['Int']['output']>;
  estimatedTravelTimeMins?: Maybe<Scalars['Int']['output']>;
  incentiveVoucherDates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  incentiveVoucherNos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  incentiveVoucherStatuses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  incentiveVoucherTotal?: Maybe<Scalars['Float']['output']>;
  incentiveVoucherUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  incentiveVouchers?: Maybe<Array<Maybe<IncentiveVoucher>>>;
  jobDetails?: Maybe<Scalars['JSON']['output']>;
  jobNo?: Maybe<Scalars['String']['output']>;
  jobStatus?: Maybe<JobStatus>;
  jobType?: Maybe<Scalars['String']['output']>;
  jobUuid?: Maybe<Scalars['UUID']['output']>;
  legStatuses?: Maybe<Array<Maybe<LegStatus>>>;
  legUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  no?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  remarks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  shiftNos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  shiftUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  shifts?: Maybe<Array<Maybe<Shift>>>;
  shipperAddress?: Maybe<Address>;
  shipperAddressCache?: Maybe<Scalars['JSON']['output']>;
  shipperName?: Maybe<Scalars['String']['output']>;
  shipperRequiredDate?: Maybe<Scalars['DateTime']['output']>;
  shipperUuid?: Maybe<Scalars['UUID']['output']>;
  trailerCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  trailerEntities?: Maybe<Array<Maybe<Trailer>>>;
  trailerNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  trailerUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  transportJobs?: Maybe<Array<Maybe<TransportJob>>>;
  transportNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  transportSources?: Maybe<Array<Maybe<TransportSource>>>;
  transportUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  tripDetails?: Maybe<Scalars['JSON']['output']>;
  tripUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  tripsTimings?: Maybe<Array<Maybe<TripTimings>>>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  vehicleCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleDepts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleEntities?: Maybe<Array<Maybe<Vehicle>>>;
  vehicleNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  voucherAccountDates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherApLocalTotal?: Maybe<Scalars['Float']['output']>;
  voucherApTotal?: Maybe<Scalars['Float']['output']>;
  voucherArLocalTotal?: Maybe<Scalars['Float']['output']>;
  voucherArTotal?: Maybe<Scalars['Float']['output']>;
  voucherCurrencies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherIssueDates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherLocalCurrency?: Maybe<Scalars['String']['output']>;
  voucherNos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherNosAp?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherNosAr?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherStatuses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  vouchers?: Maybe<Array<Maybe<Voucher>>>;
};

export type TransportReportJobQuery = {
  activityEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  activityStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  baseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  billToSources?: InputMaybe<Array<InputMaybe<TransportSource>>>;
  billToUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  bookingStatuses?: InputMaybe<Array<InputMaybe<BookingStatus>>>;
  bookingTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bookingUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  consigneeUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  dateCreatedEnd?: InputMaybe<Scalars['DateTime']['input']>;
  dateCreatedStart?: InputMaybe<Scalars['DateTime']['input']>;
  driverUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  fromCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fromZones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  includeIncentives?: InputMaybe<Scalars['Boolean']['input']>;
  jobStatuses?: InputMaybe<Array<InputMaybe<JobStatus>>>;
  jobTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  jsonQueryOptions?: InputMaybe<Array<InputMaybe<TransportJobQueryOptions>>>;
  legStatuses?: InputMaybe<Array<InputMaybe<LegStatus>>>;
  legUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  shipOutEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  shipOutStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  shipperRequiredDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  shipperRequiredDateStart?: InputMaybe<Scalars['DateTime']['input']>;
  shipperUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  toCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  toZones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transportNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transportSources?: InputMaybe<Array<InputMaybe<TransportSource>>>;
  transportType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transportUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  tripStatuses?: InputMaybe<Array<InputMaybe<TripStatus>>>;
  tripTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tripUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  vehicleUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type TransportReportJobs = {
  __typename?: 'TransportReportJobs';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<TransportReportJob>>>;
};

export type TransportReportLegActivities = {
  __typename?: 'TransportReportLegActivities';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<TransportReportLegActivity>>>;
};

export type TransportReportLegActivitiesQuery = {
  activityEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  activityStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  activityType?: InputMaybe<Array<InputMaybe<TransportActivityType>>>;
  baseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  createdAtEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  createdAtStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  department?: InputMaybe<Scalars['String']['input']>;
  driverUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  editedAtEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  editedAtStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  editedUuid?: InputMaybe<Scalars['UUID']['input']>;
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  shipperRequiredEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  shipperRequiredStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type TransportReportLegActivity = {
  __typename?: 'TransportReportLegActivity';
  activityDate?: Maybe<Scalars['DateTime']['output']>;
  activityType?: Maybe<TransportActivityType>;
  bookingDepartments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  department?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  driverCode?: Maybe<Scalars['String']['output']>;
  driverNick?: Maybe<Scalars['String']['output']>;
  driverUuid?: Maybe<Scalars['UUID']['output']>;
  editedAt?: Maybe<Scalars['DateTime']['output']>;
  editedBy?: Maybe<User>;
  editedUuid?: Maybe<Scalars['UUID']['output']>;
  isDriverApp?: Maybe<Scalars['Boolean']['output']>;
  jobNo?: Maybe<Scalars['String']['output']>;
  jobUuid?: Maybe<Scalars['UUID']['output']>;
  legSequence?: Maybe<Scalars['Int']['output']>;
  legUuid?: Maybe<Scalars['UUID']['output']>;
  minuteVariance?: Maybe<Scalars['Int']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  shipperRequiredDate?: Maybe<Scalars['DateTime']['output']>;
  trailerCode?: Maybe<Scalars['String']['output']>;
  trailerNick?: Maybe<Scalars['String']['output']>;
  trailerUuid?: Maybe<Scalars['UUID']['output']>;
  tripSequence?: Maybe<Scalars['Int']['output']>;
  tripType?: Maybe<Scalars['String']['output']>;
  tripUuid?: Maybe<Scalars['UUID']['output']>;
  usrType?: Maybe<TransportReportLegActivityUsrType>;
  vehicleCode?: Maybe<Scalars['String']['output']>;
  vehicleDepartments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleNick?: Maybe<Scalars['String']['output']>;
  vehicleUuid?: Maybe<Scalars['UUID']['output']>;
};

export enum TransportReportLegActivityUsrType {
  Dr = 'DR',
  Op = 'OP'
}

export type TransportReportTrip = {
  __typename?: 'TransportReportTrip';
  _id?: Maybe<Scalars['UUID']['output']>;
  actualDistanceKm?: Maybe<Scalars['Int']['output']>;
  actualTravelTimeMins?: Maybe<Scalars['Int']['output']>;
  billToAccount?: Maybe<Scalars['UUID']['output']>;
  billToCode?: Maybe<Scalars['String']['output']>;
  billToName?: Maybe<Scalars['String']['output']>;
  billToSource?: Maybe<TransportSource>;
  billToUuid?: Maybe<Scalars['UUID']['output']>;
  booking?: Maybe<Booking>;
  bookingDepts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  bookingDetails?: Maybe<Scalars['JSON']['output']>;
  bookingNo?: Maybe<Scalars['String']['output']>;
  bookingStatus?: Maybe<BookingStatus>;
  bookingTags?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  bookingType?: Maybe<Scalars['String']['output']>;
  bookingUuid?: Maybe<Scalars['UUID']['output']>;
  consigneeAddress?: Maybe<Address>;
  consigneeAddressCache?: Maybe<Scalars['JSON']['output']>;
  consigneeName?: Maybe<Scalars['String']['output']>;
  consigneeRequiredDate?: Maybe<Scalars['DateTime']['output']>;
  consigneeUuid: Scalars['UUID']['output'];
  costItemAPDraftTotal?: Maybe<Scalars['Float']['output']>;
  costItemAPTotal?: Maybe<Scalars['Float']['output']>;
  costItemARDraftTotal?: Maybe<Scalars['Float']['output']>;
  costItemARTotal?: Maybe<Scalars['Float']['output']>;
  costItemCostCurrencies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  costItemCostLocalTotal?: Maybe<Scalars['Float']['output']>;
  costItemCostTotal?: Maybe<Scalars['Float']['output']>;
  costItemLocalCurrency?: Maybe<Scalars['String']['output']>;
  costItemSellCurrencies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  costItemSellLocalTotal?: Maybe<Scalars['Float']['output']>;
  costItemSellTotal?: Maybe<Scalars['Float']['output']>;
  dateCreated?: Maybe<Scalars['DateTime']['output']>;
  driverCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  driverEntities?: Maybe<Array<Maybe<Driver>>>;
  driverNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  driverUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  estimatedDistanceKm?: Maybe<Scalars['Int']['output']>;
  estimatedTravelTimeMins?: Maybe<Scalars['Int']['output']>;
  firstLegStart?: Maybe<Scalars['DateTime']['output']>;
  firstLegStartOut?: Maybe<Scalars['DateTime']['output']>;
  incentiveVoucherDates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  incentiveVoucherNos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  incentiveVoucherStatuses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  incentiveVoucherTotal?: Maybe<Scalars['Float']['output']>;
  incentiveVoucherUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  incentiveVouchers?: Maybe<Array<Maybe<IncentiveVoucher>>>;
  jobDetails?: Maybe<Scalars['JSON']['output']>;
  jobNo?: Maybe<Scalars['String']['output']>;
  jobStatus?: Maybe<JobStatus>;
  jobType?: Maybe<Scalars['String']['output']>;
  jobUuid?: Maybe<Scalars['UUID']['output']>;
  lastLegEnd?: Maybe<Scalars['DateTime']['output']>;
  lastLegEndOut?: Maybe<Scalars['DateTime']['output']>;
  legStatuses?: Maybe<Array<Maybe<LegStatus>>>;
  legUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  no?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  remarks?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  shiftNos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  shiftUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  shifts?: Maybe<Array<Maybe<Shift>>>;
  shipperAddress?: Maybe<Address>;
  shipperAddressCache?: Maybe<Scalars['JSON']['output']>;
  shipperName: Scalars['String']['output'];
  shipperRequiredDate?: Maybe<Scalars['DateTime']['output']>;
  shipperUuid: Scalars['UUID']['output'];
  timeAtConsigneeMins?: Maybe<Scalars['Int']['output']>;
  timeAtShipperMins?: Maybe<Scalars['Int']['output']>;
  trailerCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  trailerEntities?: Maybe<Array<Maybe<Trailer>>>;
  trailerNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  trailerUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  transportJobs?: Maybe<Array<Maybe<TransportJob>>>;
  transportNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  transportSources?: Maybe<Array<Maybe<TransportSource>>>;
  transportUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  tripSequence?: Maybe<Scalars['Int']['output']>;
  tripStatus?: Maybe<TripStatus>;
  tripType?: Maybe<Scalars['String']['output']>;
  tripUuid?: Maybe<Scalars['UUID']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  vehicleCodes?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleDepts?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleEntities?: Maybe<Array<Maybe<Vehicle>>>;
  vehicleNames?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  vehicleUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  voucherAccountDates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherApLocalTotal?: Maybe<Scalars['Float']['output']>;
  voucherApTotal?: Maybe<Scalars['Float']['output']>;
  voucherArLocalTotal?: Maybe<Scalars['Float']['output']>;
  voucherArTotal?: Maybe<Scalars['Float']['output']>;
  voucherCurrencies?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherIssueDates?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherLocalCurrency?: Maybe<Scalars['String']['output']>;
  voucherNos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherNosAp?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherNosAr?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherStatuses?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  voucherUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  vouchers?: Maybe<Array<Maybe<Voucher>>>;
};

export type TransportReportTripQuery = {
  activityEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  activityStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  baseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  billToSources?: InputMaybe<Array<InputMaybe<TransportSource>>>;
  billToUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  bookingStatuses?: InputMaybe<Array<InputMaybe<BookingStatus>>>;
  bookingTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  bookingUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  consigneeUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  driverUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  fromCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fromZones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobStatuses?: InputMaybe<Array<InputMaybe<JobStatus>>>;
  jobTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  jobUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  jsonQueryOptions?: InputMaybe<Array<InputMaybe<TransportJobQueryOptions>>>;
  legStatuses?: InputMaybe<Array<InputMaybe<LegStatus>>>;
  legUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  shipOutEndDate?: InputMaybe<Scalars['DateTime']['input']>;
  shipOutStartDate?: InputMaybe<Scalars['DateTime']['input']>;
  shipperRequiredDateEnd?: InputMaybe<Scalars['DateTime']['input']>;
  shipperRequiredDateStart?: InputMaybe<Scalars['DateTime']['input']>;
  shipperUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  toCodes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  toZones?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transportNames?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transportSources?: InputMaybe<Array<InputMaybe<TransportSource>>>;
  transportType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  transportUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  tripStatuses?: InputMaybe<Array<InputMaybe<TripStatus>>>;
  tripTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tripUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  vehicleUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type TransportReportTrips = {
  __typename?: 'TransportReportTrips';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<TransportReportTrip>>>;
};

export enum TransportSource {
  Interbranch = 'INTERBRANCH',
  Intercompany = 'INTERCOMPANY',
  Outsource = 'OUTSOURCE',
  Own = 'OWN'
}

export type TransportZone = {
  __typename?: 'TransportZone';
  baseCompanyUuid?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sorting?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum TransportZoneStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type TransportZones = {
  __typename?: 'TransportZones';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<TransportZone>>>;
};

export type TraversedJourney = {
  __typename?: 'TraversedJourney';
  nodeId?: Maybe<Scalars['String']['output']>;
  rootNodeId?: Maybe<Scalars['String']['output']>;
};

export type TriggerComputeTripSummaryInput = {
  baseCompanyUuid?: InputMaybe<Scalars['UUID']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TriggerJobReportsInput = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type Trip = {
  __typename?: 'Trip';
  details?: Maybe<Scalars['JSON']['output']>;
  from?: Maybe<Address>;
  fromUuid?: Maybe<Scalars['UUID']['output']>;
  legs?: Maybe<Array<Maybe<TransportJob>>>;
  num?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  references?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  remarks?: Maybe<Scalars['String']['output']>;
  seal?: Maybe<Scalars['String']['output']>;
  sequence?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<TripStatus>;
  to?: Maybe<Address>;
  toUuid?: Maybe<Scalars['UUID']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  waybillId?: Maybe<Scalars['String']['output']>;
};

export enum TripFormat {
  Linear = 'linear',
  None = 'none',
  SameEnd = 'sameEnd',
  SameStart = 'sameStart'
}

export type TripInput = {
  details?: InputMaybe<Scalars['JSON']['input']>;
  from?: InputMaybe<AddressInput>;
  legs?: InputMaybe<Array<InputMaybe<LegInput>>>;
  num?: InputMaybe<Scalars['String']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  seal?: InputMaybe<Scalars['String']['input']>;
  sequence?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TripStatus>;
  to?: InputMaybe<AddressInput>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type TripLocations = {
  __typename?: 'TripLocations';
  locations?: Maybe<Array<Maybe<TruckLocation>>>;
  trip?: Maybe<TransportJob>;
};

export type TripLocationsV2 = {
  __typename?: 'TripLocationsV2';
  locations?: Maybe<Array<Maybe<Scalars['JSON']['output']>>>;
  trip?: Maybe<TransportJob>;
};

export enum TripStatus {
  AtDropoff = 'AT_DROPOFF',
  AtPickup = 'AT_PICKUP',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Deleted = 'DELETED',
  Enroute = 'ENROUTE',
  Pending = 'PENDING',
  Planned = 'PLANNED',
  Staged = 'STAGED',
  Started = 'STARTED'
}

export type TripSummaries = {
  __typename?: 'TripSummaries';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<TripSummary>>>;
};

export type TripSummariesQueryInput = {
  baseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  billToSources?: InputMaybe<Array<InputMaybe<TransportSource>>>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<Array<InputMaybe<VehicleOwnerType>>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['String']['input']>;
  transportSources?: InputMaybe<Array<InputMaybe<TransportSource>>>;
};

export type TripSummary = {
  __typename?: 'TripSummary';
  baseCompanyUuid?: Maybe<Scalars['UUID']['output']>;
  billToSource?: Maybe<TransportSource>;
  date?: Maybe<Scalars['DateTime']['output']>;
  dept?: Maybe<Scalars['JSON']['output']>;
  emptyCost?: Maybe<Scalars['Float']['output']>;
  emptyCount?: Maybe<Scalars['Int']['output']>;
  emptyTrips?: Maybe<EmptyTrips>;
  emptyUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  from?: Maybe<Scalars['String']['output']>;
  ladenCount?: Maybe<Scalars['Int']['output']>;
  ladenTrips?: Maybe<Array<Maybe<TransportJob>>>;
  ladenUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  ownerType?: Maybe<VehicleOwnerType>;
  to?: Maybe<Scalars['String']['output']>;
  transportSource?: Maybe<TransportSource>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type TripTimings = {
  __typename?: 'TripTimings';
  firstLegStart?: Maybe<Scalars['DateTime']['output']>;
  firstLegStartOut?: Maybe<Scalars['DateTime']['output']>;
  lastLegEnd?: Maybe<Scalars['DateTime']['output']>;
  lastLegEndOut?: Maybe<Scalars['DateTime']['output']>;
  timeAtConsigneeMins?: Maybe<Scalars['Int']['output']>;
  timeAtShipperMins?: Maybe<Scalars['Int']['output']>;
  tripSequence?: Maybe<Scalars['Int']['output']>;
  tripStatus?: Maybe<TripStatus>;
  tripType?: Maybe<Scalars['String']['output']>;
};

export type Trips = {
  __typename?: 'Trips';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Trip>>>;
};

export type TruckAlert = {
  __typename?: 'TruckAlert';
  _id?: Maybe<Scalars['String']['output']>;
  al?: Maybe<Scalars['String']['output']>;
  be?: Maybe<Scalars['Int']['output']>;
  f?: Maybe<Scalars['Int']['output']>;
  ign?: Maybe<Scalars['Int']['output']>;
  ll?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  media?: Maybe<TruckAlertMedia>;
  odm?: Maybe<Scalars['Int']['output']>;
  r?: Maybe<Scalars['String']['output']>;
  s?: Maybe<Scalars['Int']['output']>;
  t?: Maybe<Scalars['Int']['output']>;
};

export type TruckAlertMedia = {
  __typename?: 'TruckAlertMedia';
  images?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  videos?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type TruckAlerts = {
  __typename?: 'TruckAlerts';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<TruckAlert>>>;
};

export type TruckLocation = {
  __typename?: 'TruckLocation';
  branches?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  datetime?: Maybe<Scalars['Float']['output']>;
  idling?: Maybe<Scalars['Boolean']['output']>;
  ignition?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  loc?: Maybe<Point>;
  longitude?: Maybe<Scalars['Float']['output']>;
  odometer?: Maybe<Scalars['Float']['output']>;
  pmId?: Maybe<Scalars['String']['output']>;
  reg?: Maybe<Scalars['String']['output']>;
  speed?: Maybe<Scalars['Float']['output']>;
};

export type TruckLocationLatest = {
  __typename?: 'TruckLocationLatest';
  branches?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  datetime?: Maybe<Scalars['Float']['output']>;
  idling?: Maybe<Scalars['Boolean']['output']>;
  ignition?: Maybe<Scalars['Boolean']['output']>;
  latitude?: Maybe<Scalars['Float']['output']>;
  loc?: Maybe<Point>;
  longitude?: Maybe<Scalars['Float']['output']>;
  odometer?: Maybe<Scalars['Float']['output']>;
  pmId?: Maybe<Scalars['String']['output']>;
  reg?: Maybe<Scalars['String']['output']>;
  speed?: Maybe<Scalars['Float']['output']>;
};

export type TruckLocationV2 = {
  __typename?: 'TruckLocationV2';
  be?: Maybe<Scalars['Int']['output']>;
  f?: Maybe<Scalars['Int']['output']>;
  ign?: Maybe<Scalars['Int']['output']>;
  ll?: Maybe<Array<Maybe<Scalars['Float']['output']>>>;
  odm?: Maybe<Scalars['Int']['output']>;
  r?: Maybe<Scalars['String']['output']>;
  s?: Maybe<Scalars['Int']['output']>;
  t?: Maybe<Scalars['Int']['output']>;
};

export type TruckSpeeds = {
  __typename?: 'TruckSpeeds';
  id?: Maybe<Scalars['String']['output']>;
  speeds?: Maybe<Scalars['JSON']['output']>;
};

export type UndeleteIncentiveTypeInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateAddressInput = {
  address1?: InputMaybe<Scalars['String']['input']>;
  address2?: InputMaybe<Scalars['String']['input']>;
  address3?: InputMaybe<Scalars['String']['input']>;
  address4?: InputMaybe<Scalars['String']['input']>;
  areaCode?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  countryAlpha3?: InputMaybe<Scalars['LimitedString']['input']>;
  district?: InputMaybe<Scalars['String']['input']>;
  fax?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  plusCode?: InputMaybe<Scalars['String']['input']>;
  postCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AddressStatus>;
  tags?: InputMaybe<Array<InputMaybe<AddressTags>>>;
  type?: InputMaybe<Array<InputMaybe<AddressType>>>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateAddressLocationInput = {
  countryAlpha3?: InputMaybe<Scalars['LimitedString']['input']>;
  location?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  placeId?: InputMaybe<Scalars['String']['input']>;
  plusCode?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateAvailabilityInput = {
  date?: InputMaybe<Scalars['DateTime']['input']>;
  entityUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AvailabilityStatus>;
  type?: InputMaybe<EntityType>;
};

export type UpdateBaseCompanyInput = {
  currencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  details?: InputMaybe<BaseCompanyDetailsInput>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  shortCode?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CompanyStatus>;
  subscription?: InputMaybe<CompanySubscriptionType>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<CompanyType>>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateBillingUnitInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<BillingUnitStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateBookingInput = {
  billToUuid?: InputMaybe<Scalars['UUID']['input']>;
  details?: InputMaybe<Scalars['JSON']['input']>;
  quotationUuid?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  serviceTypes?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateBookingTypeInput = {
  billing?: InputMaybe<Array<InputMaybe<BookingTypeBillingInput>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  companies?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  dynamicFields?: InputMaybe<Array<InputMaybe<BookingTypeDynamicFieldInput>>>;
  flowType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<BookingTypeStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateChannelInput = {
  receiverUuid?: InputMaybe<Scalars['UUID']['input']>;
  senderUuid?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateChargeCategoryInput = {
  code: Scalars['String']['input'];
  description: Scalars['String']['input'];
  sequence?: InputMaybe<Scalars['String']['input']>;
  type: ChargeCategoryType;
  uuid: Scalars['UUID']['input'];
};

export type UpdateChargeItemCodeInput = {
  glCodeUuid: Scalars['UUID']['input'];
  type?: InputMaybe<ChargeItemCodeType>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateChargeItemInput = {
  code: Scalars['String']['input'];
  costCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  costRate?: InputMaybe<Scalars['Float']['input']>;
  costRateId?: InputMaybe<Scalars['String']['input']>;
  costTaxUuid?: InputMaybe<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expenseCodeUuid?: InputMaybe<Scalars['UUID']['input']>;
  name: Scalars['String']['input'];
  rateType?: InputMaybe<ChargeRateType>;
  revenueCodeUuid?: InputMaybe<Scalars['UUID']['input']>;
  sellCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  sellRate?: InputMaybe<Scalars['Float']['input']>;
  sellRateId?: InputMaybe<Scalars['String']['input']>;
  sellTaxUuid?: InputMaybe<Scalars['UUID']['input']>;
  sequence?: InputMaybe<Scalars['String']['input']>;
  status: ChargeItemStatus;
  tags?: InputMaybe<Array<InputMaybe<ChargeItemTagType>>>;
  term?: InputMaybe<Scalars['Int']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateChargeItemMiscInput = {
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  documentUuid?: InputMaybe<Scalars['UUID']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateChargeItemTaxInput = {
  rate?: InputMaybe<Scalars['Float']['input']>;
  rateType?: InputMaybe<ChargeRateType>;
  taxUuid: Scalars['UUID']['input'];
  type?: InputMaybe<ChargeItemTaxType>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateChildNodeInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  isExit?: InputMaybe<Scalars['Boolean']['input']>;
  nodeId?: InputMaybe<Scalars['String']['input']>;
  parentId?: InputMaybe<Scalars['ObjectID']['input']>;
  query?: InputMaybe<Scalars['JSON']['input']>;
  value?: InputMaybe<RateValueInput>;
};

export type UpdateChildNodeStatusInput = {
  nodeId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ActiveStatus>;
};

export type UpdateCompanyCodeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  creditorCode?: InputMaybe<Scalars['String']['input']>;
  creditorTerm?: InputMaybe<Scalars['Int']['input']>;
  debtorCode?: InputMaybe<Scalars['String']['input']>;
  debtorTerm?: InputMaybe<Scalars['Int']['input']>;
  overrideDuplicateCode?: InputMaybe<Scalars['Boolean']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateCompanyInput = {
  billToUuid?: InputMaybe<Scalars['UUID']['input']>;
  countryAlpha3: Scalars['LimitedString']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  preferredCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<CompanyStatus>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<CompanyType>>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateContactInput = {
  designation?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ContactStatus>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateContainerGradeInput = {
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ContainerGradeStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateContainerTypeInput = {
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<ContainerTypeStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateCostItemInput = {
  accrual?: InputMaybe<Scalars['Float']['input']>;
  costBaseRate?: InputMaybe<Scalars['Float']['input']>;
  costCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  costExchangeRate?: InputMaybe<Scalars['Float']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  quantity?: InputMaybe<Scalars['Float']['input']>;
  sellBaseRate?: InputMaybe<Scalars['Float']['input']>;
  sellCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  sellExchangeRate?: InputMaybe<Scalars['Float']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  unit?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateDocumentCreator = {
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  data?: InputMaybe<DataDetailsInput>;
  dataJSON?: InputMaybe<Scalars['JSON']['input']>;
  dataJSONObject?: InputMaybe<Scalars['JSONObject']['input']>;
  status?: InputMaybe<DocumentCreatorStatus>;
  template?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DocumentCreatorType>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateDocumentCreatorTemplate = {
  companies?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  details?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  template?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateDriverImageInput = {
  attachmentUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateDriverInput = {
  address?: InputMaybe<Array<InputMaybe<AddEntityAddressInput>>>;
  code?: InputMaybe<Scalars['String']['input']>;
  contact?: InputMaybe<Array<InputMaybe<ContactSimpleInput>>>;
  countryAlpha3?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Gender>;
  governmentId?: InputMaybe<Scalars['String']['input']>;
  healthAllergies?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  healthBloodtype?: InputMaybe<Bloodtype>;
  healthIssues?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nextOfKinAddress?: InputMaybe<Array<InputMaybe<AddEntityAddressInput>>>;
  nextOfKinContact?: InputMaybe<Array<InputMaybe<ContactSimpleInput>>>;
  nextOfKinName?: InputMaybe<Scalars['String']['input']>;
  nextOfKinRelationship?: InputMaybe<Scalars['String']['input']>;
  nickName?: InputMaybe<Scalars['String']['input']>;
  ownerType?: InputMaybe<DriverOwnerType>;
  preferredCurrency?: InputMaybe<Scalars['String']['input']>;
  registration?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  taxNumber?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<DriverType>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  vehiclePreference?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type UpdateDriverStatusInput = {
  status?: InputMaybe<DriverStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateEntityDocumentInput = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  fields?: InputMaybe<Array<InputMaybe<EntityFieldInput>>>;
  id?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateEntityDocumentStatusInput = {
  status: DocumentStatus;
  uuid: Scalars['UUID']['input'];
};

export type UpdateExchangeRateInput = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateFafInput = {
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  vendorUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateFuelInput = {
  _id: Scalars['UUID']['input'];
  amount: Scalars['Float']['input'];
  amountRemaining?: InputMaybe<Scalars['Float']['input']>;
  date: Scalars['DateTime']['input'];
  driverUuid?: InputMaybe<Scalars['UUID']['input']>;
  odometer: Scalars['Int']['input'];
  rate?: InputMaybe<Scalars['Float']['input']>;
  ref?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['Float']['input']>;
  vendor?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateGateLogInput = {
  _id: Scalars['UUID']['input'];
  date?: InputMaybe<Scalars['DateTime']['input']>;
  driverUuid?: InputMaybe<Scalars['UUID']['input']>;
  odometer?: InputMaybe<Scalars['Int']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  trailerUuid?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<GateLogType>;
  vehicleUuid?: InputMaybe<Scalars['UUID']['input']>;
  yardUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateGlCodeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateGroupInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  ruleCondition?: InputMaybe<RuleCondition>;
  rules?: InputMaybe<Array<InputMaybe<GroupRuleInput>>>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateIncentiveInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  amount?: InputMaybe<Scalars['Float']['input']>;
  bookingUuid?: InputMaybe<Scalars['UUID']['input']>;
  costCode?: InputMaybe<Scalars['String']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  legUuid?: InputMaybe<Scalars['UUID']['input']>;
  overrideDuplicateLeg?: InputMaybe<Scalars['Boolean']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
  typeUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateIncentiveRateInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  rates?: InputMaybe<Array<InputMaybe<InputIncentiveRateDetails>>>;
  to?: InputMaybe<Scalars['String']['input']>;
  transportType?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uom?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIncentiveTypeInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  category?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  details?: InputMaybe<Scalars['String']['input']>;
  glCodeUuid?: InputMaybe<Scalars['UUID']['input']>;
  isJobRequired?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIncentiveVoucherInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  incentiveUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateIntegrationDetailInput = {
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  integrationUuid: Scalars['UUID']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  meta?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateIntegrationMappingInput = {
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  externalCode?: InputMaybe<Scalars['String']['input']>;
  integrationDetailUuid?: InputMaybe<Scalars['UUID']['input']>;
  internalCode?: InputMaybe<Scalars['UUID']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateJobInput = {
  details?: InputMaybe<Scalars['JSON']['input']>;
  no?: InputMaybe<Scalars['String']['input']>;
  reference?: InputMaybe<JobReferencesInput>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  tripFormat?: InputMaybe<TripFormat>;
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateJobOptionInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<JobOptionStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateJobTripInput = {
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  trips?: InputMaybe<Array<InputMaybe<JobTripUpdateInput>>>;
  type?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateJobTypeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  companies?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  computeType?: InputMaybe<JobTypeComputeType>;
  defaultTripFormat?: InputMaybe<TripFormat>;
  description?: InputMaybe<Scalars['String']['input']>;
  dynamicFields?: InputMaybe<Array<InputMaybe<BookingTypeDynamicFieldInput>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  requiredFields?: InputMaybe<Array<InputMaybe<JobTypeRequiredInput>>>;
  sorting?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<JobTypeStatus>;
  tripDetails?: InputMaybe<Array<InputMaybe<JobTypeDetailsInput>>>;
  tripDynamicFields?: InputMaybe<Array<InputMaybe<BookingTypeDynamicFieldInput>>>;
  unitOptions?: InputMaybe<Array<InputMaybe<JobTypeUnitOptionsInput>>>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateLegInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  assistantUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  driverCode?: InputMaybe<Scalars['String']['input']>;
  driverIc?: InputMaybe<Scalars['String']['input']>;
  driverName?: InputMaybe<Scalars['String']['input']>;
  driverPhoneNo?: InputMaybe<Scalars['String']['input']>;
  driverUuid?: InputMaybe<Scalars['UUID']['input']>;
  isSynchronous?: InputMaybe<Scalars['Boolean']['input']>;
  legUuid?: InputMaybe<Scalars['UUID']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  trailerCode?: InputMaybe<Scalars['String']['input']>;
  trailerName?: InputMaybe<Scalars['String']['input']>;
  trailerUuid?: InputMaybe<Scalars['UUID']['input']>;
  transportUuid?: InputMaybe<Scalars['UUID']['input']>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
  vehicleCode?: InputMaybe<Scalars['String']['input']>;
  vehicleDepartments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vehicleName?: InputMaybe<Scalars['String']['input']>;
  vehicleUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateLegTimingInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  endOut?: InputMaybe<Scalars['DateTime']['input']>;
  isSynchronous?: InputMaybe<Scalars['Boolean']['input']>;
  isUpdateNextTrip?: InputMaybe<Scalars['Boolean']['input']>;
  legUuid?: InputMaybe<Scalars['UUID']['input']>;
  planEnd?: InputMaybe<Scalars['DateTime']['input']>;
  planStart?: InputMaybe<Scalars['DateTime']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  startOut?: InputMaybe<Scalars['DateTime']['input']>;
  tripUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdatePaymentInput = {
  date: Scalars['DateTime']['input'];
  reference?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  transactionType?: InputMaybe<PaymentTransactionType>;
  uuid: Scalars['UUID']['input'];
  voucherPayments?: InputMaybe<Array<InputMaybe<AddVoucherPaymentInput>>>;
};

export type UpdatePortalCompanyInput = {
  bookCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  details?: InputMaybe<PortalCompanyDetailsInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  reports?: InputMaybe<Array<InputMaybe<PortalCompanyReportConfigInput>>>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  viewCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export type UpdateQuotationInput = {
  buyerAddressUuid?: InputMaybe<Scalars['UUID']['input']>;
  buyerContactUuid?: InputMaybe<Scalars['UUID']['input']>;
  buyers?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  date?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  expiry?: InputMaybe<Scalars['DateTime']['input']>;
  note?: InputMaybe<Scalars['String']['input']>;
  sellerAddressUuid?: InputMaybe<Scalars['UUID']['input']>;
  sellerContactUuid?: InputMaybe<Scalars['UUID']['input']>;
  status?: InputMaybe<QuotationStatus>;
  tags?: InputMaybe<Array<InputMaybe<QuotationTagTypes>>>;
  term?: InputMaybe<Scalars['Int']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateQuotationItemInput = {
  chargeItemUuid?: InputMaybe<Scalars['UUID']['input']>;
  containerType?: InputMaybe<Scalars['String']['input']>;
  costCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  costRate?: InputMaybe<Scalars['Float']['input']>;
  costRateId?: InputMaybe<Scalars['String']['input']>;
  costTaxUuid?: InputMaybe<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  maxChargeAmount?: InputMaybe<Scalars['Float']['input']>;
  minChargeAmount?: InputMaybe<Scalars['Float']['input']>;
  rateType?: InputMaybe<ChargeRateType>;
  sellCurrencyUuid?: InputMaybe<Scalars['UUID']['input']>;
  sellRate?: InputMaybe<Scalars['Float']['input']>;
  sellRateId?: InputMaybe<Scalars['String']['input']>;
  sellTaxUuid?: InputMaybe<Scalars['UUID']['input']>;
  size?: InputMaybe<Scalars['String']['input']>;
  tags?: InputMaybe<Array<InputMaybe<QuotationItemTags>>>;
  unit?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateReportInput = {
  categories?: InputMaybe<Array<InputMaybe<ReportCategories>>>;
  columns?: InputMaybe<Array<InputMaybe<ReportColumnInput>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorts?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  status?: InputMaybe<ReportStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateRoleInput = {
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<RoleStatus>;
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uuid: Scalars['UUID']['input'];
};

export type UpdateRootNodeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  keyPreference?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  nodeId?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateRootNodeStatusInput = {
  nodeId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<ActiveStatus>;
};

export type UpdateSettingInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  key?: InputMaybe<Scalars['String']['input']>;
  setting?: InputMaybe<Array<InputMaybe<SettingInput>>>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateShiftInput = {
  _id?: InputMaybe<Scalars['UUID']['input']>;
  breaks?: InputMaybe<Array<InputMaybe<ShiftBreakInput>>>;
  end?: InputMaybe<Scalars['DateTime']['input']>;
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
  vehicleUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateTaxInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  exemptionDocumentUuid?: InputMaybe<Scalars['UUID']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  percentage?: InputMaybe<Scalars['Float']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateTrailerImageInput = {
  attachmentUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateTrailerInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  loadCapacity?: InputMaybe<Scalars['Int']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  manufactureYear?: InputMaybe<Scalars['Int']['input']>;
  ownerType?: InputMaybe<TrailerOwnerType>;
  registration?: InputMaybe<Scalars['String']['input']>;
  registrationYear?: InputMaybe<Scalars['Int']['input']>;
  tareWeight?: InputMaybe<Scalars['Int']['input']>;
  trailerType?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateTrailerStatusInput = {
  status?: InputMaybe<TrailerStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateTransportAreaCodeInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  code: Scalars['String']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Array<Scalars['Float']['input']>>;
  locationPolygon?: InputMaybe<Array<InputMaybe<Array<Scalars['Float']['input']>>>>;
  name: Scalars['String']['input'];
  tags?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uuid: Scalars['UUID']['input'];
  zone?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateTransportJobTypeInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TransportJobTypeStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateTransportRateInput = {
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  distance?: InputMaybe<Scalars['Int']['input']>;
  from?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  rates?: InputMaybe<Array<InputMaybe<InputTransportRateDetails>>>;
  to: Scalars['String']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  vendorUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateTransportZoneInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<TransportZoneStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateTripInput = {
  references?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  seal?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TripStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateVehicleImageInput = {
  attachmentUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateVehicleInput = {
  airBags?: InputMaybe<Scalars['Boolean']['input']>;
  antilockBrakes?: InputMaybe<Scalars['Boolean']['input']>;
  axleWeight?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  chassisNo?: InputMaybe<Scalars['String']['input']>;
  code?: InputMaybe<Scalars['String']['input']>;
  department?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description?: InputMaybe<Scalars['String']['input']>;
  driverPreference?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  engineCapacity?: InputMaybe<Scalars['Int']['input']>;
  engineNo?: InputMaybe<Scalars['String']['input']>;
  loadCapacity?: InputMaybe<Scalars['Int']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  manufactureYear?: InputMaybe<Scalars['Int']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  ownerType?: InputMaybe<VehicleOwnerType>;
  registration?: InputMaybe<Scalars['String']['input']>;
  registrationYear?: InputMaybe<Scalars['Int']['input']>;
  tareWeight?: InputMaybe<Scalars['Int']['input']>;
  trailerPreference?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateVehicleStatusInput = {
  status?: InputMaybe<VehicleStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateVolumeUnitInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<VolumeUnitStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateVoucherInput = {
  accountDate?: InputMaybe<Scalars['DateTime']['input']>;
  addressUuid?: InputMaybe<Scalars['UUID']['input']>;
  contactPerson?: InputMaybe<Scalars['String']['input']>;
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documentCreatorTemplateUuid?: InputMaybe<Scalars['UUID']['input']>;
  internalDescription?: InputMaybe<Scalars['String']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  issueDate?: InputMaybe<Scalars['DateTime']['input']>;
  paymentType?: InputMaybe<PaymentType>;
  salesPersonUuid?: InputMaybe<Scalars['UUID']['input']>;
  term?: InputMaybe<Scalars['Int']['input']>;
  uuid: Scalars['UUID']['input'];
  vendorUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateVoucherItemInput = {
  baseRate: Scalars['Float']['input'];
  currencyUuid: Scalars['UUID']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  exchangeRate: Scalars['Float']['input'];
  jobUuid?: InputMaybe<Scalars['UUID']['input']>;
  localExchangeRate: Scalars['Float']['input'];
  quantity: Scalars['Float']['input'];
  size?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Int']['input']>;
  taxUuid: Scalars['UUID']['input'];
  unit?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
  voucherItemCnUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateWeightUnitInput = {
  code?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['Float']['input']>;
  status?: InputMaybe<WeightUnitStatus>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateYardInput = {
  address?: InputMaybe<AddressInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  remarks?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<YardStatus>;
  uuid: Scalars['UUID']['input'];
};

export type User = {
  __typename?: 'User';
  algoliaKey?: Maybe<Scalars['String']['output']>;
  auth0Token?: Maybe<Scalars['String']['output']>;
  auth0UserId?: Maybe<Scalars['String']['output']>;
  baseCompanies?: Maybe<Array<Maybe<BaseCompany>>>;
  companies?: Maybe<Array<Maybe<UserCompany>>>;
  email?: Maybe<Scalars['Email']['output']>;
  familyName?: Maybe<Scalars['String']['output']>;
  givenName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nickname?: Maybe<Scalars['String']['output']>;
  picture?: Maybe<Scalars['String']['output']>;
  roles?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  status?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type UserCompany = {
  __typename?: 'UserCompany';
  country?: Maybe<Country>;
  countryAlpha3?: Maybe<Scalars['LimitedString']['output']>;
  currency?: Maybe<Currency>;
  employee?: Maybe<Employee>;
  name?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CompanyStatus>;
  types?: Maybe<Array<Maybe<CompanyType>>>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type UserInput = {
  familyName?: InputMaybe<Scalars['String']['input']>;
  givenName?: InputMaybe<Scalars['String']['input']>;
  nickname?: InputMaybe<Scalars['String']['input']>;
  uuid: Scalars['UUID']['input'];
};

export type UserPreference = {
  __typename?: 'UserPreference';
  key?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['String']['output']>;
};

export type UserTimeout = {
  __typename?: 'UserTimeout';
  seconds?: Maybe<Scalars['Int']['output']>;
};

export type Users = {
  __typename?: 'Users';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<User>>>;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  airBags?: Maybe<Scalars['Boolean']['output']>;
  antilockBrakes?: Maybe<Scalars['Boolean']['output']>;
  axleWeight?: Maybe<Array<Maybe<Scalars['Int']['output']>>>;
  chassisNo?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  companies?: Maybe<Array<Maybe<EntityCompany>>>;
  customerAddress?: Maybe<Array<Maybe<EntityCompanyAddress>>>;
  customers?: Maybe<Array<Maybe<EntityCompany>>>;
  department?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  description?: Maybe<Scalars['String']['output']>;
  documents?: Maybe<Array<Maybe<DocumentDescription>>>;
  driverPreference?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  engineCapacity?: Maybe<Scalars['Int']['output']>;
  engineNo?: Maybe<Scalars['String']['output']>;
  entityException?: Maybe<EntityExceptionOutcome>;
  image?: Maybe<Attachment>;
  loadCapacity?: Maybe<Scalars['Int']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  manufactureYear?: Maybe<Scalars['Int']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  ownerType?: Maybe<VehicleOwnerType>;
  plan?: Maybe<VehiclePlan>;
  registration?: Maybe<Scalars['String']['output']>;
  registrationYear?: Maybe<Scalars['Int']['output']>;
  status?: Maybe<VehicleStatus>;
  subContractors?: Maybe<Array<Maybe<EntityCompany>>>;
  tareWeight?: Maybe<Scalars['Int']['output']>;
  trailerPreference?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum VehicleOwnerType {
  Interbranch = 'INTERBRANCH',
  Intercompany = 'INTERCOMPANY',
  Outsource = 'OUTSOURCE',
  Owned = 'OWNED'
}

export type VehiclePlan = {
  __typename?: 'VehiclePlan';
  etaAvailable?: Maybe<Scalars['DateTime']['output']>;
  explanation?: Maybe<Scalars['String']['output']>;
  jobsUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  remarks?: Maybe<Scalars['String']['output']>;
  shiftsUuids?: Maybe<Array<Maybe<Scalars['UUID']['output']>>>;
  vehicleUuid?: Maybe<Scalars['UUID']['output']>;
};

export enum VehicleStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED',
  Inactive = 'INACTIVE',
  Service = 'SERVICE',
  Sold = 'SOLD'
}

export type VehiclesResults = {
  __typename?: 'VehiclesResults';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Vehicle>>>;
};

export type Vessel = {
  __typename?: 'Vessel';
  imo?: Maybe<Scalars['String']['output']>;
  mmsi?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type Viewer = {
  __typename?: 'Viewer';
  algoliaKey?: Maybe<Scalars['String']['output']>;
  baseCompanies?: Maybe<Array<Maybe<BaseCompany>>>;
  email: Scalars['String']['output'];
  employee?: Maybe<Employee>;
  registered?: Maybe<Scalars['Boolean']['output']>;
  superAdmin?: Maybe<Employee>;
};

export type VolumeUnit = {
  __typename?: 'VolumeUnit';
  baseCompanyUuid?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sorting?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum VolumeUnitStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type VolumeUnits = {
  __typename?: 'VolumeUnits';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<VolumeUnit>>>;
};

export type Voucher = {
  __typename?: 'Voucher';
  accountDate?: Maybe<Scalars['DateTime']['output']>;
  address?: Maybe<Address>;
  approvals?: Maybe<Array<Maybe<Approval>>>;
  approvedBy?: Maybe<User>;
  balance?: Maybe<Scalars['Float']['output']>;
  baseSubTotal?: Maybe<Scalars['Float']['output']>;
  baseTaxTotal?: Maybe<Scalars['Float']['output']>;
  bookingDocuments?: Maybe<Array<Maybe<BookingDocument>>>;
  bookings?: Maybe<Array<Maybe<Booking>>>;
  canApprove?: Maybe<Scalars['Boolean']['output']>;
  canCancel?: Maybe<Scalars['Boolean']['output']>;
  contactPerson?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  currency?: Maybe<Currency>;
  customer?: Maybe<Company>;
  description?: Maybe<Scalars['String']['output']>;
  documentCreatorTemplate?: Maybe<DocumentCreatorTemplate>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  exchangeRounding?: Maybe<Scalars['Float']['output']>;
  internalDescription?: Maybe<Scalars['String']['output']>;
  invoiceNumber?: Maybe<Scalars['String']['output']>;
  isCreditNote?: Maybe<Scalars['Boolean']['output']>;
  issueDate?: Maybe<Scalars['DateTime']['output']>;
  localSubTotal?: Maybe<Scalars['Float']['output']>;
  localTaxTotal?: Maybe<Scalars['Float']['output']>;
  localTotal?: Maybe<Scalars['Float']['output']>;
  owner?: Maybe<BaseCompany>;
  paymentType?: Maybe<PaymentType>;
  permissions?: Maybe<VoucherPermissions>;
  printCount?: Maybe<Scalars['Int']['output']>;
  salesPerson?: Maybe<User>;
  status?: Maybe<VoucherStatus>;
  subTotal?: Maybe<Scalars['Float']['output']>;
  taxRounding?: Maybe<Scalars['Float']['output']>;
  taxTotal?: Maybe<Scalars['Float']['output']>;
  term?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  transactionType?: Maybe<TransactionType>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  vendor?: Maybe<Company>;
  voucherItems?: Maybe<Array<Maybe<VoucherItem>>>;
  voucherNumber?: Maybe<Scalars['String']['output']>;
  voucherPayments?: Maybe<Array<Maybe<VoucherPayment>>>;
};

export enum VoucherCategoryType {
  Claim = 'claim',
  CreditNote = 'creditNote',
  CustPermits = 'custPermits',
  CustomerInvoice = 'customerInvoice',
  CustomsPayment = 'customsPayment',
  ForeignAgent = 'foreignAgent',
  PortBill = 'portBill',
  SupplierPayment = 'supplierPayment'
}

export type VoucherItem = {
  __typename?: 'VoucherItem';
  baseRate?: Maybe<Scalars['Float']['output']>;
  baseSubTotal?: Maybe<Scalars['Float']['output']>;
  baseTaxTotal?: Maybe<Scalars['Float']['output']>;
  booking?: Maybe<Booking>;
  bookingUuid?: Maybe<Scalars['UUID']['output']>;
  costItem?: Maybe<CostItem>;
  currency?: Maybe<Currency>;
  description?: Maybe<Scalars['String']['output']>;
  editBy?: Maybe<User>;
  exchangeRate?: Maybe<Scalars['Float']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  job?: Maybe<Job>;
  localExchangeRate?: Maybe<Scalars['Float']['output']>;
  localSubTotal?: Maybe<Scalars['Float']['output']>;
  localTaxTotal?: Maybe<Scalars['Float']['output']>;
  localTotal?: Maybe<Scalars['Float']['output']>;
  quantity?: Maybe<Scalars['Float']['output']>;
  rate?: Maybe<Scalars['Float']['output']>;
  size?: Maybe<Scalars['String']['output']>;
  sorting?: Maybe<Scalars['Int']['output']>;
  subTotal?: Maybe<Scalars['Float']['output']>;
  tax?: Maybe<Tax>;
  taxPercentage?: Maybe<Scalars['Float']['output']>;
  taxTotal?: Maybe<Scalars['Float']['output']>;
  total?: Maybe<Scalars['Float']['output']>;
  unit?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  voucher?: Maybe<Voucher>;
  voucherCn?: Maybe<Voucher>;
  voucherItemCn?: Maybe<VoucherItem>;
};

export type VoucherPayment = {
  __typename?: 'VoucherPayment';
  amount?: Maybe<Scalars['Float']['output']>;
  payment?: Maybe<Payment>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  voucher?: Maybe<Voucher>;
  voucherUuid?: Maybe<Scalars['UUID']['output']>;
};

export type VoucherPermissions = {
  __typename?: 'VoucherPermissions';
  canApprove?: Maybe<Scalars['Boolean']['output']>;
  canCancel?: Maybe<Scalars['Boolean']['output']>;
};

export enum VoucherRevisionType {
  CreditNote = 'CREDIT_NOTE',
  Revision = 'REVISION',
  Void = 'VOID'
}

export type VoucherSearchFilter = {
  accountDate?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  approvedCount?: InputMaybe<Scalars['Int']['input']>;
  baseCompanyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  bookingUuid?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  companyUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  customerUuid?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  dueDate?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  isCreditNote?: InputMaybe<Scalars['Boolean']['input']>;
  issueDate?: InputMaybe<Array<InputMaybe<Scalars['Date']['input']>>>;
  paymentPaid?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  paymentType?: InputMaybe<Array<InputMaybe<PaymentType>>>;
  status?: InputMaybe<Array<InputMaybe<VoucherStatus>>>;
  total?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  transactionType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vendorUuid?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  voucherType?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  voucherUuid?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export enum VoucherStatus {
  Approved = 'APPROVED',
  Deleted = 'DELETED',
  Draft = 'DRAFT',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  Submitted = 'SUBMITTED',
  Voided = 'VOIDED'
}

export type VoucherTemplate = {
  __typename?: 'VoucherTemplate';
  approvals?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  bookingDocuments?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  transactionCategory?: Maybe<Scalars['String']['output']>;
  transactionType?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export enum VoucherTypeType {
  Accpay = 'ACCPAY',
  Accrec = 'ACCREC'
}

export type Vouchers = {
  __typename?: 'Vouchers';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Voucher>>>;
};

export type VouchersJson = {
  __typename?: 'VouchersJson';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Scalars['JSON']['output']>;
};

export type VouchersSearchInput = {
  filter?: InputMaybe<VoucherSearchFilter>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  sort?: InputMaybe<Scalars['String']['input']>;
};

export type Waypoint = {
  __typename?: 'Waypoint';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  seca?: Maybe<Scalars['Boolean']['output']>;
};

export type WeightUnit = {
  __typename?: 'WeightUnit';
  baseCompanyUuid?: Maybe<Scalars['String']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sorting?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export enum WeightUnitStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type WeightUnits = {
  __typename?: 'WeightUnits';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<WeightUnit>>>;
};

export type Yard = {
  __typename?: 'Yard';
  address?: Maybe<Scalars['JSON']['output']>;
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  createdBy?: Maybe<User>;
  createdUuid?: Maybe<Scalars['UUID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  ownerUuid?: Maybe<Scalars['UUID']['output']>;
  remarks?: Maybe<Scalars['String']['output']>;
  status?: Maybe<YardStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedBy?: Maybe<User>;
  updatedUuid?: Maybe<Scalars['UUID']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};

export type YardQueryInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  q?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InputMaybe<YardStatus>>>;
  uuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export enum YardStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export type Yards = {
  __typename?: 'Yards';
  pageInfo?: Maybe<NumberPagination>;
  rows?: Maybe<Array<Maybe<Yard>>>;
};

export type ChargeItemMigrateType = {
  code?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type CostItemErrorType = {
  __typename?: 'costItemErrorType';
  expectedValue?: Maybe<Scalars['String']['output']>;
  field?: Maybe<Scalars['String']['output']>;
  msg?: Maybe<Scalars['String']['output']>;
  receivedValue?: Maybe<Scalars['String']['output']>;
  urgency?: Maybe<CostSheetErrorEnum>;
};

export enum CostSheetErrorEnum {
  Error = 'ERROR',
  Info = 'INFO',
  Warn = 'WARN'
}

export type MakeRequestInput = {
  bookingUuid: Scalars['UUID']['input'];
  invoiceAmount?: InputMaybe<Scalars['Int']['input']>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

export type NumberPagination = {
  __typename?: 'numberPagination';
  count?: Maybe<Scalars['Int']['output']>;
  limit?: Maybe<Scalars['Int']['output']>;
  offset?: Maybe<Scalars['Int']['output']>;
};

export type RecomputeLegActivitiesQuery = {
  end?: InputMaybe<Scalars['DateTime']['input']>;
  force?: InputMaybe<Scalars['Boolean']['input']>;
  start?: InputMaybe<Scalars['DateTime']['input']>;
};

export type RemoveDriverCompanyInput = {
  companyType?: InputMaybe<EntityCompanyUpdateType>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type RemoveTrailerCompanyInput = {
  companyType?: InputMaybe<EntityCompanyUpdateType>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type RemoveVehicleCompanyInput = {
  companyType?: InputMaybe<EntityCompanyUpdateType>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type SearchConsolVouchersInput = {
  issueDate?: InputMaybe<SearchConsolVouchersIssueDateInput>;
  reference?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Array<InputMaybe<ConsolVoucherStatus>>>;
  type?: InputMaybe<Array<InputMaybe<ConsolVoucherType>>>;
};

export type SearchConsolVouchersIssueDateInput = {
  from?: InputMaybe<Scalars['DateTime']['input']>;
  to?: InputMaybe<Scalars['DateTime']['input']>;
};

export type SearchVouchersInput = {
  accountDate?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  customer?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
  dueDate?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  invoiceNumber?: InputMaybe<Scalars['String']['input']>;
  issueDate?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  paymentType?: InputMaybe<Array<InputMaybe<PaymentType>>>;
  status?: InputMaybe<Array<InputMaybe<VoucherStatus>>>;
  type?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  vendor?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

export enum TemplateResource {
  BookingDocument = 'bookingDocument',
  Request = 'request',
  Voucher = 'voucher'
}

export type TransportRateOptions = {
  cargoType?: InputMaybe<TransportRateCargoTypes>;
  rateRule?: InputMaybe<ChargeRateRuleType>;
  rateType?: InputMaybe<ChargeRateType>;
  rateUom?: InputMaybe<Scalars['String']['input']>;
  rateValue?: InputMaybe<Scalars['Float']['input']>;
  transactionType: TransportRateTransactionType;
  type: Scalars['String']['input'];
};

export type TransportRateSelector = {
  customerUuid?: InputMaybe<Scalars['UUID']['input']>;
  from: Scalars['String']['input'];
  to: Scalars['String']['input'];
  type: Scalars['String']['input'];
  vendorUuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateDriverCompanyInput = {
  companyReference?: InputMaybe<Scalars['String']['input']>;
  companyType?: InputMaybe<EntityCompanyUpdateType>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateDriverEmployeeInput = {
  employeeUuid: Scalars['UUID']['input'];
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateEntityRequirementCategoryInput = {
  allowedCategories?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  entityType: EntityType;
  uuid: Scalars['UUID']['input'];
};

export type UpdateEntityRequirementsInput = {
  documentRequirements: Array<InputMaybe<EntityDocumentRequirementInput>>;
  entityType: EntityType;
  uuid: Scalars['UUID']['input'];
};

export type UpdateTrailerCompanyInput = {
  companyReference?: InputMaybe<Scalars['String']['input']>;
  companyType?: InputMaybe<EntityCompanyUpdateType>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type UpdateVehicleCompanyInput = {
  companyReference?: InputMaybe<Scalars['String']['input']>;
  companyType?: InputMaybe<EntityCompanyUpdateType>;
  companyUuid?: InputMaybe<Scalars['UUID']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

export type VoucherItemQueryInput = {
  bookingUuids?: InputMaybe<Array<InputMaybe<Scalars['UUID']['input']>>>;
};

/**
 * A Directive provides a way to describe alternate runtime execution and type validation behavior in a GraphQL document.
 *
 * In some cases, you need to provide options to alter GraphQL's execution behavior in ways field arguments will not suffice, such as conditionally including or skipping a field. Directives provide this by describing additional information to the executor.
 */
export type __Directive = {
  __typename?: '__Directive';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isRepeatable: Scalars['Boolean']['output'];
  locations: Array<__DirectiveLocation>;
  args: Array<__InputValue>;
};


/**
 * A Directive provides a way to describe alternate runtime execution and type validation behavior in a GraphQL document.
 *
 * In some cases, you need to provide options to alter GraphQL's execution behavior in ways field arguments will not suffice, such as conditionally including or skipping a field. Directives provide this by describing additional information to the executor.
 */
export type __DirectiveArgsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};

/** A Directive can be adjacent to many parts of the GraphQL language, a __DirectiveLocation describes one such possible adjacencies. */
export enum __DirectiveLocation {
  /** Location adjacent to a query operation. */
  Query = 'QUERY',
  /** Location adjacent to a mutation operation. */
  Mutation = 'MUTATION',
  /** Location adjacent to a subscription operation. */
  Subscription = 'SUBSCRIPTION',
  /** Location adjacent to a field. */
  Field = 'FIELD',
  /** Location adjacent to a fragment definition. */
  FragmentDefinition = 'FRAGMENT_DEFINITION',
  /** Location adjacent to a fragment spread. */
  FragmentSpread = 'FRAGMENT_SPREAD',
  /** Location adjacent to an inline fragment. */
  InlineFragment = 'INLINE_FRAGMENT',
  /** Location adjacent to a variable definition. */
  VariableDefinition = 'VARIABLE_DEFINITION',
  /** Location adjacent to a schema definition. */
  Schema = 'SCHEMA',
  /** Location adjacent to a scalar definition. */
  Scalar = 'SCALAR',
  /** Location adjacent to an object type definition. */
  Object = 'OBJECT',
  /** Location adjacent to a field definition. */
  FieldDefinition = 'FIELD_DEFINITION',
  /** Location adjacent to an argument definition. */
  ArgumentDefinition = 'ARGUMENT_DEFINITION',
  /** Location adjacent to an interface definition. */
  Interface = 'INTERFACE',
  /** Location adjacent to a union definition. */
  Union = 'UNION',
  /** Location adjacent to an enum definition. */
  Enum = 'ENUM',
  /** Location adjacent to an enum value definition. */
  EnumValue = 'ENUM_VALUE',
  /** Location adjacent to an input object type definition. */
  InputObject = 'INPUT_OBJECT',
  /** Location adjacent to an input object field definition. */
  InputFieldDefinition = 'INPUT_FIELD_DEFINITION'
}

/** One possible value for a given Enum. Enum values are unique values, not a placeholder for a string or numeric value. However an Enum value is returned in a JSON response as a string. */
export type __EnumValue = {
  __typename?: '__EnumValue';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  isDeprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
};

/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __Field = {
  __typename?: '__Field';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  args: Array<__InputValue>;
  type: __Type;
  isDeprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
};


/** Object and Interface types are described by a list of Fields, each of which has a name, potentially a list of arguments, and a return type. */
export type __FieldArgsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Arguments provided to Fields or Directives and the input fields of an InputObject are represented as Input Values which describe their type and optionally a default value. */
export type __InputValue = {
  __typename?: '__InputValue';
  name: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
  type: __Type;
  /** A GraphQL-formatted string representing the default value for this input value. */
  defaultValue?: Maybe<Scalars['String']['output']>;
  isDeprecated: Scalars['Boolean']['output'];
  deprecationReason?: Maybe<Scalars['String']['output']>;
};

/** A GraphQL Schema defines the capabilities of a GraphQL server. It exposes all available types and directives on the server, as well as the entry points for query, mutation, and subscription operations. */
export type __Schema = {
  __typename?: '__Schema';
  description?: Maybe<Scalars['String']['output']>;
  /** A list of all types supported by this server. */
  types: Array<__Type>;
  /** The type that query operations will be rooted at. */
  queryType: __Type;
  /** If this server supports mutation, the type that mutation operations will be rooted at. */
  mutationType?: Maybe<__Type>;
  /** If this server support subscription, the type that subscription operations will be rooted at. */
  subscriptionType?: Maybe<__Type>;
  /** A list of all directives supported by this server. */
  directives: Array<__Directive>;
};

/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __Type = {
  __typename?: '__Type';
  kind: __TypeKind;
  name?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  specifiedByURL?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Array<__Field>>;
  interfaces?: Maybe<Array<__Type>>;
  possibleTypes?: Maybe<Array<__Type>>;
  enumValues?: Maybe<Array<__EnumValue>>;
  inputFields?: Maybe<Array<__InputValue>>;
  ofType?: Maybe<__Type>;
  isOneOf?: Maybe<Scalars['Boolean']['output']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeEnumValuesArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};


/**
 * The fundamental unit of any GraphQL Schema is the type. There are many kinds of types in GraphQL as represented by the `__TypeKind` enum.
 *
 * Depending on the kind of a type, certain fields describe information about that type. Scalar types provide no information beyond a name, description and optional `specifiedByURL`, while Enum types provide their values. Object and Interface types provide the fields they describe. Abstract types, Union and Interface, provide the Object types possible at runtime. List and NonNull types compose other types.
 */
export type __TypeInputFieldsArgs = {
  includeDeprecated?: InputMaybe<Scalars['Boolean']['input']>;
};

/** An enum describing what kind of type a given `__Type` is. */
export enum __TypeKind {
  /** Indicates this type is a scalar. */
  Scalar = 'SCALAR',
  /** Indicates this type is an object. `fields` and `interfaces` are valid fields. */
  Object = 'OBJECT',
  /** Indicates this type is an interface. `fields`, `interfaces`, and `possibleTypes` are valid fields. */
  Interface = 'INTERFACE',
  /** Indicates this type is a union. `possibleTypes` is a valid field. */
  Union = 'UNION',
  /** Indicates this type is an enum. `enumValues` is a valid field. */
  Enum = 'ENUM',
  /** Indicates this type is an input object. `inputFields` is a valid field. */
  InputObject = 'INPUT_OBJECT',
  /** Indicates this type is a list. `ofType` is a valid field. */
  List = 'LIST',
  /** Indicates this type is a non-null. `ofType` is a valid field. */
  NonNull = 'NON_NULL'
}



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

/** Mapping of union types */
export type ResolversUnionTypes<_RefType extends Record<string, unknown>> = {
  ActionTemplate: (BookingDocumentTemplate) | (RequestTemplate) | (VoucherTemplate);
  EmailRequestFields: (Omit<EmailRequestFieldsBookingConfirmationFromLiner, 'documents'> & { documents?: Maybe<Array<Maybe<_RefType['BookingDocument']>>> }) | (Omit<EmailRequestFieldsBookingConfirmationReeferFromLiner, 'documents'> & { documents?: Maybe<Array<Maybe<_RefType['BookingDocument']>>> }) | (Omit<EmailRequestFieldsConsigneeDraftNotification, 'documents'> & { documents?: Maybe<Array<Maybe<_RefType['BookingDocument']>>> }) | (Omit<EmailRequestFieldsEdo, 'documents'> & { documents?: Maybe<Array<Maybe<_RefType['BookingDocument']>>> }) | (Omit<EmailRequestFieldsExportDocs, 'documents'> & { documents?: Maybe<Array<Maybe<_RefType['BookingDocument']>>> }) | (EmailRequestFieldsHaulageRfc) | (Omit<EmailRequestFieldsHaulageRot, 'documents'> & { documents?: Maybe<Array<Maybe<_RefType['BookingDocument']>>> }) | (Omit<EmailRequestFieldsImportDocs, 'documents'> & { documents?: Maybe<Array<Maybe<_RefType['BookingDocument']>>> }) | (Omit<EmailRequestFieldsLinerBookingConfirmationCustomerRequest, 'documents'> & { documents?: Maybe<Array<Maybe<_RefType['BookingDocument']>>> }) | (Omit<EmailRequestFieldsLinerContainerMovementOrder, 'documents'> & { documents?: Maybe<Array<Maybe<_RefType['BookingDocument']>>> }) | (Omit<EmailRequestFieldsLinerNotifyCustomerComplete, 'documents'> & { documents?: Maybe<Array<Maybe<_RefType['BookingDocument']>>> }) | (Omit<EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill, 'documents'> & { documents?: Maybe<Array<Maybe<_RefType['BookingDocument']>>> }) | (Omit<EmailRequestFieldsNoa, 'documents'> & { documents?: Maybe<Array<Maybe<_RefType['BookingDocument']>>> });
  EntityTypeUnion: (Driver) | (Trailer) | (Vehicle);
  Event: (BookingEvent) | (ContainerEvent) | (MessageEvent);
  SearchResult: (Omit<Booking, 'assignees' | 'bookingDocuments' | 'events' | 'jobs' | 'vouchers'> & { assignees?: Maybe<Array<Maybe<_RefType['Assignee']>>>, bookingDocuments?: Maybe<Array<Maybe<_RefType['BookingDocument']>>>, events?: Maybe<Array<Maybe<_RefType['Event']>>>, jobs?: Maybe<Array<Maybe<_RefType['Job']>>>, vouchers?: Maybe<Array<Maybe<_RefType['Voucher']>>> }) | (Omit<Voucher, 'approvals' | 'bookingDocuments' | 'bookings' | 'voucherItems' | 'voucherPayments'> & { approvals?: Maybe<Array<Maybe<_RefType['Approval']>>>, bookingDocuments?: Maybe<Array<Maybe<_RefType['BookingDocument']>>>, bookings?: Maybe<Array<Maybe<_RefType['Booking']>>>, voucherItems?: Maybe<Array<Maybe<_RefType['VoucherItem']>>>, voucherPayments?: Maybe<Array<Maybe<_RefType['VoucherPayment']>>> });
};


/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  AccountType: AccountType;
  ActionTemplate: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['ActionTemplate']>;
  ActiveStatus: ActiveStatus;
  AddAddressInput: AddAddressInput;
  AddChargeItemCodeInput: AddChargeItemCodeInput;
  AddChargeItemInput: AddChargeItemInput;
  AddChargeItemTaxInput: AddChargeItemTaxInput;
  AddContactInput: AddContactInput;
  AddCostItemInput: AddCostItemInput;
  AddEntityAddressInput: AddEntityAddressInput;
  AddEntityDocumentInput: AddEntityDocumentInput;
  AddJobInput: AddJobInput;
  AddLegInput: AddLegInput;
  AddLegsInput: AddLegsInput;
  AddManifestInput: AddManifestInput;
  AddManifestLegsInput: AddManifestLegsInput;
  AddManifestTripsInput: AddManifestTripsInput;
  AddPaymentInput: AddPaymentInput;
  AddVoucherItemInput: AddVoucherItemInput;
  AddVoucherItemViaVoucherInput: AddVoucherItemViaVoucherInput;
  AddVoucherPaymentInput: AddVoucherPaymentInput;
  Address: ResolverTypeWrapper<Address>;
  AddressInput: AddressInput;
  AddressStatus: AddressStatus;
  AddressTags: AddressTags;
  AddressType: AddressType;
  Addresses: ResolverTypeWrapper<Addresses>;
  Approval: ResolverTypeWrapper<Omit<Approval, 'bookingDocument' | 'voucher'> & { bookingDocument?: Maybe<ResolversTypes['BookingDocument']>, voucher?: Maybe<ResolversTypes['Voucher']> }>;
  ApprovalInput: ApprovalInput;
  ApprovalStatus: ApprovalStatus;
  Approvals: ResolverTypeWrapper<Omit<Approvals, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['Approval']>>> }>;
  AssignPortalCompanyInput: AssignPortalCompanyInput;
  Assignee: ResolverTypeWrapper<Assignee>;
  Attachment: ResolverTypeWrapper<Attachment>;
  Auth: ResolverTypeWrapper<Auth>;
  Auth0: Auth0;
  AuthProvider: AuthProvider;
  AvailabilitiesResults: ResolverTypeWrapper<AvailabilitiesResults>;
  Availability: ResolverTypeWrapper<Availability>;
  AvailabilityStatus: AvailabilityStatus;
  BCSettingAreaCode: ResolverTypeWrapper<BcSettingAreaCode>;
  BCSettingAreaCodeInputRequirement: BcSettingAreaCodeInputRequirement;
  BaseCompanies: ResolverTypeWrapper<BaseCompanies>;
  BaseCompany: ResolverTypeWrapper<BaseCompany>;
  BaseCompanyDetails: ResolverTypeWrapper<BaseCompanyDetails>;
  BaseCompanyDetailsInput: BaseCompanyDetailsInput;
  BaseCompanySetting: ResolverTypeWrapper<BaseCompanySetting>;
  BaseCompanySettings: ResolverTypeWrapper<BaseCompanySettings>;
  BillingManner: BillingManner;
  BillingUnit: ResolverTypeWrapper<BillingUnit>;
  BillingUnitStatus: BillingUnitStatus;
  BillingUnits: ResolverTypeWrapper<BillingUnits>;
  Bloodtype: Bloodtype;
  Booking: ResolverTypeWrapper<Omit<Booking, 'assignees' | 'bookingDocuments' | 'events' | 'jobs' | 'vouchers'> & { assignees?: Maybe<Array<Maybe<ResolversTypes['Assignee']>>>, bookingDocuments?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, events?: Maybe<Array<Maybe<ResolversTypes['Event']>>>, jobs?: Maybe<Array<Maybe<ResolversTypes['Job']>>>, vouchers?: Maybe<Array<Maybe<ResolversTypes['Voucher']>>> }>;
  BookingAssigneeInput: BookingAssigneeInput;
  BookingDocument: ResolverTypeWrapper<Omit<BookingDocument, 'approvals' | 'bookings'> & { approvals?: Maybe<Array<Maybe<ResolversTypes['Approval']>>>, bookings?: Maybe<Array<Maybe<ResolversTypes['Booking']>>> }>;
  BookingDocumentTemplate: ResolverTypeWrapper<BookingDocumentTemplate>;
  BookingDocuments: ResolverTypeWrapper<Omit<BookingDocuments, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  BookingDynamicFieldQuery: BookingDynamicFieldQuery;
  BookingDynamicFieldType: BookingDynamicFieldType;
  BookingEvent: ResolverTypeWrapper<BookingEvent>;
  BookingEventPayload: ResolverTypeWrapper<BookingEventPayload>;
  BookingFieldValue: ResolverTypeWrapper<BookingFieldValue>;
  BookingFieldValueInput: BookingFieldValueInput;
  BookingPermissionType: ResolverTypeWrapper<BookingPermissionType>;
  BookingState: ResolverTypeWrapper<BookingState>;
  BookingStatus: BookingStatus;
  BookingSummary: ResolverTypeWrapper<BookingSummary>;
  BookingTransportInput: BookingTransportInput;
  BookingType: ResolverTypeWrapper<BookingType>;
  BookingTypeBilling: ResolverTypeWrapper<BookingTypeBilling>;
  BookingTypeBillingInput: BookingTypeBillingInput;
  BookingTypeDynamicField: ResolverTypeWrapper<BookingTypeDynamicField>;
  BookingTypeDynamicFieldChronologyLevel: BookingTypeDynamicFieldChronologyLevel;
  BookingTypeDynamicFieldControl: BookingTypeDynamicFieldControl;
  BookingTypeDynamicFieldInput: BookingTypeDynamicFieldInput;
  BookingTypeStatus: BookingTypeStatus;
  BookingTypes: ResolverTypeWrapper<BookingTypes>;
  Bookings: ResolverTypeWrapper<Omit<Bookings, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['Booking']>>> }>;
  BookingsJson: ResolverTypeWrapper<BookingsJson>;
  BookingsSearchFilter: BookingsSearchFilter;
  BookingsSearchInput: BookingsSearchInput;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  BulkImportFunction: ResolverTypeWrapper<BulkImportFunction>;
  BulkImportFunctionSettingInput: BulkImportFunctionSettingInput;
  BulkImportFunctionStatus: BulkImportFunctionStatus;
  BulkImportFunctionUpdateInput: BulkImportFunctionUpdateInput;
  BulkImportFunctions: ResolverTypeWrapper<BulkImportFunctions>;
  BulkImportFunctionsInput: BulkImportFunctionsInput;
  ChangeCostItemInput: ChangeCostItemInput;
  Channel: ResolverTypeWrapper<Channel>;
  Channels: ResolverTypeWrapper<Channels>;
  ChargeCategories: ResolverTypeWrapper<ChargeCategories>;
  ChargeCategory: ResolverTypeWrapper<ChargeCategory>;
  ChargeCategoryType: ChargeCategoryType;
  ChargeItem: ResolverTypeWrapper<ChargeItem>;
  ChargeItemCode: ResolverTypeWrapper<ChargeItemCode>;
  ChargeItemCodeType: ChargeItemCodeType;
  ChargeItemMisc: ResolverTypeWrapper<ChargeItemMisc>;
  ChargeItemMiscs: ResolverTypeWrapper<ChargeItemMiscs>;
  ChargeItemStatus: ChargeItemStatus;
  ChargeItemTagType: ChargeItemTagType;
  ChargeItemTax: ResolverTypeWrapper<ChargeItemTax>;
  ChargeItemTaxType: ChargeItemTaxType;
  ChargeItems: ResolverTypeWrapper<ChargeItems>;
  ChargeRateRuleType: ChargeRateRuleType;
  ChargeRateType: ChargeRateType;
  ChildNode: ResolverTypeWrapper<ChildNode>;
  ChildNodes: ResolverTypeWrapper<ChildNodes>;
  ChildNodesQueryInput: ChildNodesQueryInput;
  ChildSelector: ResolverTypeWrapper<ChildSelector>;
  Chronology: ResolverTypeWrapper<Chronology>;
  ChronologyInput: ChronologyInput;
  ChronologyNewBookingInput: ChronologyNewBookingInput;
  ChronologyUpdateInput: ChronologyUpdateInput;
  CloneNodeBranchInput: CloneNodeBranchInput;
  CloseShiftInput: CloseShiftInput;
  Commodity: ResolverTypeWrapper<Commodity>;
  CommodityInput: CommodityInput;
  CommodityType: ResolverTypeWrapper<CommodityType>;
  CommodityTypes: ResolverTypeWrapper<CommodityTypes>;
  Companies: ResolverTypeWrapper<Companies>;
  Company: ResolverTypeWrapper<Company>;
  CompanyAccess: ResolverTypeWrapper<CompanyAccess>;
  CompanyAccessStatus: CompanyAccessStatus;
  CompanyAccessType: CompanyAccessType;
  CompanyAccesses: ResolverTypeWrapper<CompanyAccesses>;
  CompanyInviteStatus: CompanyInviteStatus;
  CompanySearch: ResolverTypeWrapper<CompanySearch>;
  CompanyStatus: CompanyStatus;
  CompanySubscriptionType: CompanySubscriptionType;
  CompanyType: CompanyType;
  ComputeRateInput: ComputeRateInput;
  ComputeReportJobSummaryInput: ComputeReportJobSummaryInput;
  ComputeSummaryType: ComputeSummaryType;
  ComputedRate: ResolverTypeWrapper<ComputedRate>;
  ComputedRateResult: ResolverTypeWrapper<ComputedRateResult>;
  ConsolVoucher: ResolverTypeWrapper<Omit<ConsolVoucher, 'vouchers'> & { vouchers?: Maybe<Array<Maybe<ResolversTypes['Voucher']>>> }>;
  ConsolVoucherStatus: ConsolVoucherStatus;
  ConsolVoucherType: ConsolVoucherType;
  ConsolVouchers: ResolverTypeWrapper<Omit<ConsolVouchers, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['ConsolVoucher']>>> }>;
  Contact: ResolverTypeWrapper<Contact>;
  ContactSimple: ResolverTypeWrapper<ContactSimple>;
  ContactSimpleInput: ContactSimpleInput;
  ContactSimpleType: ContactSimpleType;
  ContactStatus: ContactStatus;
  Contacts: ResolverTypeWrapper<Contacts>;
  ContainerEvent: ResolverTypeWrapper<ContainerEvent>;
  ContainerGrade: ResolverTypeWrapper<ContainerGrade>;
  ContainerGradeStatus: ContainerGradeStatus;
  ContainerGrades: ResolverTypeWrapper<ContainerGrades>;
  ContainerType: ResolverTypeWrapper<ContainerType>;
  ContainerTypeStatus: ContainerTypeStatus;
  ContainerTypes: ResolverTypeWrapper<ContainerTypes>;
  CostItem: ResolverTypeWrapper<CostItem>;
  CostSheet: ResolverTypeWrapper<CostSheet>;
  Counter: ResolverTypeWrapper<Counter>;
  CounterResults: ResolverTypeWrapper<CounterResults>;
  CounterType: CounterType;
  Countries: ResolverTypeWrapper<Countries>;
  Country: ResolverTypeWrapper<Country>;
  CountryAlpha2: ResolverTypeWrapper<Scalars['CountryAlpha2']['output']>;
  CountryAlpha3: ResolverTypeWrapper<Scalars['CountryAlpha3']['output']>;
  CreateBaseCompanyInput: CreateBaseCompanyInput;
  CreateBillingUnitInput: CreateBillingUnitInput;
  CreateChannelInput: CreateChannelInput;
  CreateChargeItemMiscInput: CreateChargeItemMiscInput;
  CreateCompanyInput: CreateCompanyInput;
  CreateConsolVoucherInput: CreateConsolVoucherInput;
  CreateContainerGradeInput: CreateContainerGradeInput;
  CreateContainerTypeInput: CreateContainerTypeInput;
  CreateDocumentCreator: CreateDocumentCreator;
  CreateDocumentCreatorTemplate: CreateDocumentCreatorTemplate;
  CreateDriverInput: CreateDriverInput;
  CreateExchangeRateInput: CreateExchangeRateInput;
  CreateFafInput: CreateFafInput;
  CreateFuelInput: CreateFuelInput;
  CreateGateLogInput: CreateGateLogInput;
  CreateGlCodeInput: CreateGlCodeInput;
  CreateIncentiveInput: CreateIncentiveInput;
  CreateIncentiveRateInput: CreateIncentiveRateInput;
  CreateIncentiveTypeInput: CreateIncentiveTypeInput;
  CreateIncentiveVoucherInput: CreateIncentiveVoucherInput;
  CreateIntegrationDetailInput: CreateIntegrationDetailInput;
  CreateIntegrationMappingInput: CreateIntegrationMappingInput;
  CreateJobOptionInput: CreateJobOptionInput;
  CreatePortalCompanyInput: CreatePortalCompanyInput;
  CreateQuotationInput: CreateQuotationInput;
  CreateQuotationItemInput: CreateQuotationItemInput;
  CreateQuotationItemViaQuotationInput: CreateQuotationItemViaQuotationInput;
  CreateReportInput: CreateReportInput;
  CreateRoleInput: CreateRoleInput;
  CreateSettingInput: CreateSettingInput;
  CreateShiftInput: CreateShiftInput;
  CreateTaxInput: CreateTaxInput;
  CreateTrailerInput: CreateTrailerInput;
  CreateTransportAreaCodeInput: CreateTransportAreaCodeInput;
  CreateTransportJobTypeInput: CreateTransportJobTypeInput;
  CreateTransportRateInput: CreateTransportRateInput;
  CreateTransportZoneInput: CreateTransportZoneInput;
  CreateVehicleInput: CreateVehicleInput;
  CreateVolumeUnitInput: CreateVolumeUnitInput;
  CreateVoucherInput: CreateVoucherInput;
  CreateWeightUnitInput: CreateWeightUnitInput;
  CreateYardInput: CreateYardInput;
  Currencies: ResolverTypeWrapper<Currencies>;
  Currency: ResolverTypeWrapper<Currency>;
  DataDetails: ResolverTypeWrapper<Omit<DataDetails, 'jobs'> & { jobs?: Maybe<Array<Maybe<ResolversTypes['Job']>>> }>;
  DataDetailsInput: DataDetailsInput;
  Date: ResolverTypeWrapper<Scalars['Date']['output']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DeleteIncentiveTypeInput: DeleteIncentiveTypeInput;
  DeleteLegInput: DeleteLegInput;
  Document: ResolverTypeWrapper<Document>;
  DocumentCreator: ResolverTypeWrapper<Omit<DocumentCreator, 'data'> & { data?: Maybe<ResolversTypes['DataDetails']> }>;
  DocumentCreatorReference: ResolverTypeWrapper<DocumentCreatorReference>;
  DocumentCreatorStatus: DocumentCreatorStatus;
  DocumentCreatorTemplate: ResolverTypeWrapper<DocumentCreatorTemplate>;
  DocumentCreatorTemplateStatus: DocumentCreatorTemplateStatus;
  DocumentCreatorTemplates: ResolverTypeWrapper<DocumentCreatorTemplates>;
  DocumentCreatorType: DocumentCreatorType;
  DocumentCreators: ResolverTypeWrapper<Omit<DocumentCreators, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['DocumentCreator']>>> }>;
  DocumentDescription: ResolverTypeWrapper<DocumentDescription>;
  DocumentHardCopyStatus: DocumentHardCopyStatus;
  DocumentInput: DocumentInput;
  DocumentStatus: DocumentStatus;
  Driver: ResolverTypeWrapper<Driver>;
  DriverBehaviorAuth: ResolverTypeWrapper<DriverBehaviorAuth>;
  DriverOwnerType: DriverOwnerType;
  DriverStatus: DriverStatus;
  DriverType: DriverType;
  DriverUtilDept: ResolverTypeWrapper<DriverUtilDept>;
  DriversResults: ResolverTypeWrapper<DriversResults>;
  Email: ResolverTypeWrapper<Scalars['Email']['output']>;
  EmailRequestFields: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['EmailRequestFields']>;
  EmailRequestFieldsBookingConfirmationFromLiner: ResolverTypeWrapper<Omit<EmailRequestFieldsBookingConfirmationFromLiner, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestFieldsBookingConfirmationReeferFromLiner: ResolverTypeWrapper<Omit<EmailRequestFieldsBookingConfirmationReeferFromLiner, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestFieldsConsigneeDraftNotification: ResolverTypeWrapper<Omit<EmailRequestFieldsConsigneeDraftNotification, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestFieldsEdo: ResolverTypeWrapper<Omit<EmailRequestFieldsEdo, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestFieldsExportDocs: ResolverTypeWrapper<Omit<EmailRequestFieldsExportDocs, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestFieldsHaulageRFC: ResolverTypeWrapper<EmailRequestFieldsHaulageRfc>;
  EmailRequestFieldsHaulageRot: ResolverTypeWrapper<Omit<EmailRequestFieldsHaulageRot, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestFieldsImportDocs: ResolverTypeWrapper<Omit<EmailRequestFieldsImportDocs, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestFieldsLinerBookingConfirmationCustomerRequest: ResolverTypeWrapper<Omit<EmailRequestFieldsLinerBookingConfirmationCustomerRequest, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestFieldsLinerContainerMovementOrder: ResolverTypeWrapper<Omit<EmailRequestFieldsLinerContainerMovementOrder, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestFieldsLinerNotifyCustomerComplete: ResolverTypeWrapper<Omit<EmailRequestFieldsLinerNotifyCustomerComplete, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill: ResolverTypeWrapper<Omit<EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestFieldsNoa: ResolverTypeWrapper<Omit<EmailRequestFieldsNoa, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestFieldsPaymentNotification: ResolverTypeWrapper<Omit<EmailRequestFieldsPaymentNotification, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  EmailRequestInput: EmailRequestInput;
  Employee: ResolverTypeWrapper<Employee>;
  EmployeeDriver: ResolverTypeWrapper<EmployeeDriver>;
  Employees: ResolverTypeWrapper<Employees>;
  EmptyTrip: ResolverTypeWrapper<EmptyTrip>;
  EmptyTripAddInput: EmptyTripAddInput;
  EmptyTripProposed: ResolverTypeWrapper<EmptyTripProposed>;
  EmptyTripQuery: EmptyTripQuery;
  EmptyTripStatus: EmptyTripStatus;
  EmptyTripUpdateInput: EmptyTripUpdateInput;
  EmptyTripUpdateStatusInput: EmptyTripUpdateStatusInput;
  EmptyTrips: ResolverTypeWrapper<EmptyTrips>;
  EndManifestTripsInput: EndManifestTripsInput;
  EntityAddress: ResolverTypeWrapper<EntityAddress>;
  EntityCategoryRequirement: ResolverTypeWrapper<EntityCategoryRequirement>;
  EntityCompany: ResolverTypeWrapper<EntityCompany>;
  EntityCompanyAddress: ResolverTypeWrapper<EntityCompanyAddress>;
  EntityCompanyType: EntityCompanyType;
  EntityCompanyUpdateType: EntityCompanyUpdateType;
  EntityDetails: ResolverTypeWrapper<EntityDetails>;
  EntityDocumentRequirement: ResolverTypeWrapper<EntityDocumentRequirement>;
  EntityDocumentRequirementInput: EntityDocumentRequirementInput;
  EntityEvent: ResolverTypeWrapper<EntityEvent>;
  EntityExceptionOutcome: ResolverTypeWrapper<EntityExceptionOutcome>;
  EntityExceptionOutcomeStatus: EntityExceptionOutcomeStatus;
  EntityExceptions: ResolverTypeWrapper<EntityExceptions>;
  EntityExceptionsItem: ResolverTypeWrapper<EntityExceptionsItem>;
  EntityField: ResolverTypeWrapper<EntityField>;
  EntityFieldInput: EntityFieldInput;
  EntityFieldReq: ResolverTypeWrapper<EntityFieldReq>;
  EntityFieldReqInput: EntityFieldReqInput;
  EntityFieldType: EntityFieldType;
  EntityRequirements: ResolverTypeWrapper<EntityRequirements>;
  EntityType: EntityType;
  EntityTypeUnion: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['EntityTypeUnion']>;
  Event: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['Event']>;
  EventCreator: ResolverTypeWrapper<EventCreator>;
  EventEtc: ResolverTypeWrapper<EventEtc>;
  EventVoucher: ResolverTypeWrapper<EventVoucher>;
  EventVouchers: ResolverTypeWrapper<EventVouchers>;
  EventsEtc: ResolverTypeWrapper<EventsEtc>;
  ExchangeRate: ResolverTypeWrapper<ExchangeRate>;
  ExchangeRates: ResolverTypeWrapper<ExchangeRates>;
  Faf: ResolverTypeWrapper<Faf>;
  Fafs: ResolverTypeWrapper<Fafs>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  FmsCompany: ResolverTypeWrapper<FmsCompany>;
  Fuel: ResolverTypeWrapper<Fuel>;
  FuelQueryInput: FuelQueryInput;
  FuelStatus: FuelStatus;
  Fuels: ResolverTypeWrapper<Fuels>;
  GateLog: ResolverTypeWrapper<GateLog>;
  GateLogQueryInput: GateLogQueryInput;
  GateLogStatus: GateLogStatus;
  GateLogType: GateLogType;
  GateLogs: ResolverTypeWrapper<GateLogs>;
  Gender: Gender;
  GenerateReportInput: GenerateReportInput;
  GenericDynamicFieldQuery: GenericDynamicFieldQuery;
  Geocode: ResolverTypeWrapper<Geocode>;
  Geocodes: ResolverTypeWrapper<Geocodes>;
  Geometry: ResolverTypeWrapper<Geometry>;
  GeometryPoint: ResolverTypeWrapper<GeometryPoint>;
  GeometryPolygon: ResolverTypeWrapper<GeometryPolygon>;
  GlCode: ResolverTypeWrapper<GlCode>;
  GlCodeStatus: GlCodeStatus;
  GlCodes: ResolverTypeWrapper<GlCodes>;
  GoogleGeometry: ResolverTypeWrapper<GoogleGeometry>;
  Group: ResolverTypeWrapper<Group>;
  GroupRule: ResolverTypeWrapper<GroupRule>;
  GroupRuleInput: GroupRuleInput;
  GroupRuleType: GroupRuleType;
  Groups: ResolverTypeWrapper<Groups>;
  ImpCustConsigneeDraftNotification: ResolverTypeWrapper<Omit<ImpCustConsigneeDraftNotification, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>> }>;
  Incentive: ResolverTypeWrapper<Omit<Incentive, 'incentiveVoucher' | 'transportJob' | 'voucher'> & { incentiveVoucher?: Maybe<ResolversTypes['IncentiveVoucher']>, transportJob?: Maybe<ResolversTypes['TransportJob']>, voucher?: Maybe<ResolversTypes['Voucher']> }>;
  IncentiveCategorySum: ResolverTypeWrapper<IncentiveCategorySum>;
  IncentiveQueryInput: IncentiveQueryInput;
  IncentiveRate: ResolverTypeWrapper<IncentiveRate>;
  IncentiveRateDetails: ResolverTypeWrapper<IncentiveRateDetails>;
  IncentiveRateQueryInput: IncentiveRateQueryInput;
  IncentiveRates: ResolverTypeWrapper<IncentiveRates>;
  IncentiveStatus: IncentiveStatus;
  IncentiveType: ResolverTypeWrapper<IncentiveType>;
  IncentiveTypeQueryInput: IncentiveTypeQueryInput;
  IncentiveTypeSum: ResolverTypeWrapper<IncentiveTypeSum>;
  IncentiveTypes: ResolverTypeWrapper<IncentiveTypes>;
  IncentiveVoucher: ResolverTypeWrapper<Omit<IncentiveVoucher, 'incentiveCategorySum' | 'incentives'> & { incentiveCategorySum?: Maybe<Array<Maybe<ResolversTypes['IncentiveCategorySum']>>>, incentives?: Maybe<Array<Maybe<ResolversTypes['Incentive']>>> }>;
  IncentiveVoucherQueryInput: IncentiveVoucherQueryInput;
  IncentiveVoucherQueryOptions: IncentiveVoucherQueryOptions;
  IncentiveVoucherStatus: IncentiveVoucherStatus;
  IncentiveVouchers: ResolverTypeWrapper<Omit<IncentiveVouchers, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['IncentiveVoucher']>>> }>;
  Incentives: ResolverTypeWrapper<Omit<Incentives, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['Incentive']>>> }>;
  InputIncentiveRateDetails: InputIncentiveRateDetails;
  InputTransportRateDetails: InputTransportRateDetails;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  Integration: ResolverTypeWrapper<Integration>;
  IntegrationDetail: ResolverTypeWrapper<IntegrationDetail>;
  IntegrationDetails: ResolverTypeWrapper<IntegrationDetails>;
  IntegrationLog: ResolverTypeWrapper<IntegrationLog>;
  IntegrationLogDetailsType: IntegrationLogDetailsType;
  IntegrationLogs: ResolverTypeWrapper<IntegrationLogs>;
  IntegrationLogsQuery: ResolverTypeWrapper<IntegrationLogsQuery>;
  IntegrationMapping: ResolverTypeWrapper<IntegrationMapping>;
  IntegrationMappings: ResolverTypeWrapper<IntegrationMappings>;
  Integrations: ResolverTypeWrapper<Integrations>;
  InviteCompanyAccess: ResolverTypeWrapper<InviteCompanyAccess>;
  InviteCompanyAccessInput: InviteCompanyAccessInput;
  InviteCompanyAccesses: ResolverTypeWrapper<InviteCompanyAccesses>;
  InvitePortalCompanyInput: InvitePortalCompanyInput;
  InviteUser: InviteUser;
  InviteUserResponse: ResolverTypeWrapper<InviteUserResponse>;
  InvitedBaseCompany: ResolverTypeWrapper<InvitedBaseCompany>;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  JSONObject: ResolverTypeWrapper<Scalars['JSONObject']['output']>;
  Job: ResolverTypeWrapper<Omit<Job, 'reference' | 'trips'> & { reference?: Maybe<ResolversTypes['JobReferences']>, trips?: Maybe<Array<Maybe<ResolversTypes['Trip']>>> }>;
  JobInput: JobInput;
  JobOption: ResolverTypeWrapper<JobOption>;
  JobOptionStatus: JobOptionStatus;
  JobOptions: ResolverTypeWrapper<JobOptions>;
  JobReferences: ResolverTypeWrapper<JobReferences>;
  JobReferencesInput: JobReferencesInput;
  JobState: JobState;
  JobStatus: JobStatus;
  JobSummaries: ResolverTypeWrapper<JobSummaries>;
  JobSummariesQueryInput: JobSummariesQueryInput;
  JobSummary: ResolverTypeWrapper<JobSummary>;
  JobTripUpdateInput: JobTripUpdateInput;
  JobType: ResolverTypeWrapper<JobType>;
  JobTypeComputeType: JobTypeComputeType;
  JobTypeDetails: ResolverTypeWrapper<JobTypeDetails>;
  JobTypeDetailsInput: JobTypeDetailsInput;
  JobTypeDynamicFieldControl: JobTypeDynamicFieldControl;
  JobTypeRequiredFieldControl: JobTypeRequiredFieldControl;
  JobTypeRequiredFieldTypes: JobTypeRequiredFieldTypes;
  JobTypeRequiredFields: ResolverTypeWrapper<JobTypeRequiredFields>;
  JobTypeRequiredInput: JobTypeRequiredInput;
  JobTypeStatus: JobTypeStatus;
  JobTypeUnitOptions: ResolverTypeWrapper<JobTypeUnitOptions>;
  JobTypeUnitOptionsInput: JobTypeUnitOptionsInput;
  JobTypes: ResolverTypeWrapper<JobTypes>;
  Jobs: ResolverTypeWrapper<Omit<Jobs, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['Job']>>> }>;
  JobsJson: ResolverTypeWrapper<JobsJson>;
  JobsSearchFilter: JobsSearchFilter;
  JobsSearchInput: JobsSearchInput;
  LanguageCode: ResolverTypeWrapper<Scalars['LanguageCode']['output']>;
  Leg: ResolverTypeWrapper<Leg>;
  LegActivities: ResolverTypeWrapper<LegActivities>;
  LegActivitiesQuery: LegActivitiesQuery;
  LegActivity: ResolverTypeWrapper<LegActivity>;
  LegInput: LegInput;
  LegStatus: LegStatus;
  LegSummaries: ResolverTypeWrapper<Omit<LegSummaries, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['LegSummary']>>> }>;
  LegSummariesQueryInput: LegSummariesQueryInput;
  LegSummary: ResolverTypeWrapper<Omit<LegSummary, 'emptyLegs' | 'ladenLegs'> & { emptyLegs?: Maybe<ResolversTypes['EmptyTrips']>, ladenLegs?: Maybe<Array<Maybe<ResolversTypes['TransportJob']>>> }>;
  LegTimeType: LegTimeType;
  LegType: LegType;
  LimitedString: ResolverTypeWrapper<Scalars['LimitedString']['output']>;
  LimitedString2: ResolverTypeWrapper<Scalars['LimitedString2']['output']>;
  LimitedString3: ResolverTypeWrapper<Scalars['LimitedString3']['output']>;
  Location: ResolverTypeWrapper<Location>;
  LocationInput: LocationInput;
  MakeBookingCommentInput: MakeBookingCommentInput;
  MakeBookingInput: MakeBookingInput;
  Manifest: ResolverTypeWrapper<Omit<Manifest, 'legs'> & { legs?: Maybe<Array<Maybe<ResolversTypes['TransportJob']>>> }>;
  ManifestLegAction: ManifestLegAction;
  ManifestLocation: ResolverTypeWrapper<ManifestLocation>;
  ManifestLocationInput: ManifestLocationInput;
  ManifestStatus: ManifestStatus;
  Manifests: ResolverTypeWrapper<Omit<Manifests, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['Manifest']>>> }>;
  MessageEvent: ResolverTypeWrapper<MessageEvent>;
  MessageEventPayload: ResolverTypeWrapper<MessageEventPayload>;
  Mutation: ResolverTypeWrapper<{}>;
  NodeConnection: ResolverTypeWrapper<NodeConnection>;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationInput: NotificationInput;
  ObjectID: ResolverTypeWrapper<Scalars['ObjectID']['output']>;
  OpenShiftInput: OpenShiftInput;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  Password: ResolverTypeWrapper<Scalars['Password']['output']>;
  Payment: ResolverTypeWrapper<Omit<Payment, 'voucherPayments'> & { voucherPayments?: Maybe<Array<Maybe<ResolversTypes['VoucherPayment']>>> }>;
  PaymentStatus: PaymentStatus;
  PaymentTransactionType: PaymentTransactionType;
  PaymentType: PaymentType;
  Payments: ResolverTypeWrapper<Omit<Payments, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['Payment']>>> }>;
  Permission: ResolverTypeWrapper<Permission>;
  PermissionType: ResolverTypeWrapper<PermissionType>;
  Permissions: ResolverTypeWrapper<Permissions>;
  Place: ResolverTypeWrapper<Place>;
  PlaceResults: ResolverTypeWrapper<PlaceResults>;
  PlannerQueueRunnerInput: PlannerQueueRunnerInput;
  PmUtilDept: ResolverTypeWrapper<PmUtilDept>;
  Point: ResolverTypeWrapper<Point>;
  PopulateAvailabilityInput: PopulateAvailabilityInput;
  Port: ResolverTypeWrapper<Port>;
  PortalCompanies: ResolverTypeWrapper<PortalCompanies>;
  PortalCompany: ResolverTypeWrapper<PortalCompany>;
  PortalCompanyDetails: ResolverTypeWrapper<PortalCompanyDetails>;
  PortalCompanyDetailsInput: PortalCompanyDetailsInput;
  PortalCompanyQueryType: PortalCompanyQueryType;
  PortalCompanyReportConfig: ResolverTypeWrapper<PortalCompanyReportConfig>;
  PortalCompanyReportConfigInput: PortalCompanyReportConfigInput;
  PortalReports: ResolverTypeWrapper<PortalReports>;
  Ports: ResolverTypeWrapper<Ports>;
  ProcessFlow: ResolverTypeWrapper<ProcessFlow>;
  ProcessFlowChronologiesType: ResolverTypeWrapper<ProcessFlowChronologiesType>;
  ProcessFlowCreateType: ResolverTypeWrapper<ProcessFlowCreateType>;
  ProcessFlowDocumentType: ResolverTypeWrapper<ProcessFlowDocumentType>;
  ProcessFlowRequestType: ResolverTypeWrapper<ProcessFlowRequestType>;
  ProcessFlowSection: ResolverTypeWrapper<ProcessFlowSection>;
  ProcessFlowStepType: ResolverTypeWrapper<ProcessFlowStepType>;
  ProcessFlowVoucherType: ResolverTypeWrapper<ProcessFlowVoucherType>;
  ProcessReportPortalCompanyInput: ProcessReportPortalCompanyInput;
  ProcessTypeCategories: ResolverTypeWrapper<ProcessTypeCategories>;
  ProcessTypes: ResolverTypeWrapper<ProcessTypes>;
  Profile: ResolverTypeWrapper<Profile>;
  Query: ResolverTypeWrapper<{}>;
  QueryAvailabilitiesInput: QueryAvailabilitiesInput;
  QueryEventEtc: QueryEventEtc;
  QueryEventVoucher: QueryEventVoucher;
  QueryManifestsInput: QueryManifestsInput;
  QueryReverseGeocode: QueryReverseGeocode;
  Quotation: ResolverTypeWrapper<Quotation>;
  QuotationItem: ResolverTypeWrapper<QuotationItem>;
  QuotationItemTags: QuotationItemTags;
  QuotationStatus: QuotationStatus;
  QuotationTagTypes: QuotationTagTypes;
  Quotations: ResolverTypeWrapper<Quotations>;
  Rate: ResolverTypeWrapper<Rate>;
  RateLogItem: ResolverTypeWrapper<RateLogItem>;
  RateValue: ResolverTypeWrapper<RateValue>;
  RateValueBracket: ResolverTypeWrapper<RateValueBracket>;
  RateValueBracketInput: RateValueBracketInput;
  RateValueInput: RateValueInput;
  RateValueOp: RateValueOp;
  RemoveManifestLegsInput: RemoveManifestLegsInput;
  Report: ResolverTypeWrapper<Report>;
  ReportAggregationType: ReportAggregationType;
  ReportCategories: ReportCategories;
  ReportColumn: ResolverTypeWrapper<ReportColumn>;
  ReportColumnInput: ReportColumnInput;
  ReportField: ResolverTypeWrapper<ReportField>;
  ReportFieldsInput: ReportFieldsInput;
  ReportFilter: ResolverTypeWrapper<ReportFilter>;
  ReportFormatFnc: ReportFormatFnc;
  ReportJobSummaries: ResolverTypeWrapper<ReportJobSummaries>;
  ReportJobSummariesQueryInput: ReportJobSummariesQueryInput;
  ReportJobSummary: ResolverTypeWrapper<ReportJobSummary>;
  ReportJobSummaryDept: ResolverTypeWrapper<ReportJobSummaryDept>;
  ReportJobSummaryZone: ResolverTypeWrapper<ReportJobSummaryZone>;
  ReportMeta: ResolverTypeWrapper<ReportMeta>;
  ReportModel: ReportModel;
  ReportPortalCompany: ResolverTypeWrapper<ReportPortalCompany>;
  ReportQueryInput: ReportQueryInput;
  ReportStatus: ReportStatus;
  ReportSummary: ResolverTypeWrapper<ReportSummary>;
  Reports: ResolverTypeWrapper<Reports>;
  Request: ResolverTypeWrapper<Request>;
  RequestBookingDocument: RequestBookingDocument;
  RequestDocumentTypesInput: RequestDocumentTypesInput;
  RequestEmailResultsType: ResolverTypeWrapper<RequestEmailResultsType>;
  RequestMetaType: ResolverTypeWrapper<RequestMetaType>;
  RequestTemplate: ResolverTypeWrapper<RequestTemplate>;
  RequestType: RequestType;
  RequisitionTemplate: ResolverTypeWrapper<Omit<RequisitionTemplate, 'fields'> & { fields?: Maybe<ResolversTypes['EmailRequestFields']> }>;
  Response: ResolverTypeWrapper<Response>;
  ReverseGeoAddress: ResolverTypeWrapper<ReverseGeoAddress>;
  Role: ResolverTypeWrapper<Role>;
  RoleStatus: RoleStatus;
  RoleTag: RoleTag;
  Roles: ResolverTypeWrapper<Roles>;
  RolesQuery: RolesQuery;
  RootNode: ResolverTypeWrapper<RootNode>;
  RootNodeQueryInput: RootNodeQueryInput;
  RootNodes: ResolverTypeWrapper<RootNodes>;
  RootNodesQueryInput: RootNodesQueryInput;
  RuleCondition: RuleCondition;
  SRate: ResolverTypeWrapper<SRate>;
  SeaPath: ResolverTypeWrapper<SeaPath>;
  SeaRoute: ResolverTypeWrapper<SeaRoute>;
  Seaport: ResolverTypeWrapper<Seaport>;
  Search: ResolverTypeWrapper<Omit<Search, 'hits'> & { hits?: Maybe<Array<Maybe<ResolversTypes['SearchResult']>>> }>;
  SearchInput: SearchInput;
  SearchResult: ResolverTypeWrapper<ResolversUnionTypes<ResolversTypes>['SearchResult']>;
  Service: ResolverTypeWrapper<Service>;
  ServiceInput: ServiceInput;
  Setting: ResolverTypeWrapper<Setting>;
  SettingInput: SettingInput;
  Shift: ResolverTypeWrapper<Omit<Shift, 'incentiveCategorySum' | 'incentives'> & { incentiveCategorySum?: Maybe<Array<Maybe<ResolversTypes['IncentiveCategorySum']>>>, incentives?: Maybe<Array<Maybe<ResolversTypes['Incentive']>>> }>;
  ShiftBreak: ResolverTypeWrapper<ShiftBreak>;
  ShiftBreakInput: ShiftBreakInput;
  ShiftQueryInput: ShiftQueryInput;
  ShiftQueryOptions: ShiftQueryOptions;
  ShiftStatus: ShiftStatus;
  Shifts: ResolverTypeWrapper<Shifts>;
  ShipmentTypeInput: ShipmentTypeInput;
  ShippingRate: ResolverTypeWrapper<ShippingRate>;
  ShippingRateType: ResolverTypeWrapper<ShippingRateType>;
  ShippingRates: ResolverTypeWrapper<ShippingRates>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  SubmitROTInput: SubmitRotInput;
  SuperAdmins: ResolverTypeWrapper<SuperAdmins>;
  Tax: ResolverTypeWrapper<Tax>;
  Taxes: ResolverTypeWrapper<Taxes>;
  Template: ResolverTypeWrapper<Template>;
  Trailer: ResolverTypeWrapper<Trailer>;
  TrailerOwnerType: TrailerOwnerType;
  TrailerStatus: TrailerStatus;
  TrailersResults: ResolverTypeWrapper<TrailersResults>;
  TransactionType: TransactionType;
  Transport: ResolverTypeWrapper<Transport>;
  TransportActivity: ResolverTypeWrapper<TransportActivity>;
  TransportActivityFlow: ResolverTypeWrapper<TransportActivityFlow>;
  TransportActivityShipXTrigger: ResolverTypeWrapper<TransportActivityShipXTrigger>;
  TransportActivityType: TransportActivityType;
  TransportActivityVerb: TransportActivityVerb;
  TransportAreaCode: ResolverTypeWrapper<TransportAreaCode>;
  TransportAreaCodes: ResolverTypeWrapper<TransportAreaCodes>;
  TransportInput: TransportInput;
  TransportJob: ResolverTypeWrapper<Omit<TransportJob, 'booking' | 'incentiveVouchers'> & { booking?: Maybe<ResolversTypes['Booking']>, incentiveVouchers?: Maybe<Array<Maybe<ResolversTypes['IncentiveVoucher']>>> }>;
  TransportJobQuery: TransportJobQuery;
  TransportJobQueryOptions: TransportJobQueryOptions;
  TransportJobType: ResolverTypeWrapper<TransportJobType>;
  TransportJobTypeStatus: TransportJobTypeStatus;
  TransportJobTypes: ResolverTypeWrapper<TransportJobTypes>;
  TransportJobs: ResolverTypeWrapper<Omit<TransportJobs, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['TransportJob']>>> }>;
  TransportQueueRunnerInput: TransportQueueRunnerInput;
  TransportRate: ResolverTypeWrapper<TransportRate>;
  TransportRateCargoTypes: TransportRateCargoTypes;
  TransportRateDetails: ResolverTypeWrapper<TransportRateDetails>;
  TransportRateDetailsType: TransportRateDetailsType;
  TransportRateTransactionType: TransportRateTransactionType;
  TransportRates: ResolverTypeWrapper<TransportRates>;
  TransportReportJob: ResolverTypeWrapper<Omit<TransportReportJob, 'booking' | 'incentiveVouchers' | 'transportJobs' | 'vouchers'> & { booking?: Maybe<ResolversTypes['Booking']>, incentiveVouchers?: Maybe<Array<Maybe<ResolversTypes['IncentiveVoucher']>>>, transportJobs?: Maybe<Array<Maybe<ResolversTypes['TransportJob']>>>, vouchers?: Maybe<Array<Maybe<ResolversTypes['Voucher']>>> }>;
  TransportReportJobQuery: TransportReportJobQuery;
  TransportReportJobs: ResolverTypeWrapper<Omit<TransportReportJobs, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['TransportReportJob']>>> }>;
  TransportReportLegActivities: ResolverTypeWrapper<TransportReportLegActivities>;
  TransportReportLegActivitiesQuery: TransportReportLegActivitiesQuery;
  TransportReportLegActivity: ResolverTypeWrapper<TransportReportLegActivity>;
  TransportReportLegActivityUsrType: TransportReportLegActivityUsrType;
  TransportReportTrip: ResolverTypeWrapper<Omit<TransportReportTrip, 'booking' | 'incentiveVouchers' | 'transportJobs' | 'vouchers'> & { booking?: Maybe<ResolversTypes['Booking']>, incentiveVouchers?: Maybe<Array<Maybe<ResolversTypes['IncentiveVoucher']>>>, transportJobs?: Maybe<Array<Maybe<ResolversTypes['TransportJob']>>>, vouchers?: Maybe<Array<Maybe<ResolversTypes['Voucher']>>> }>;
  TransportReportTripQuery: TransportReportTripQuery;
  TransportReportTrips: ResolverTypeWrapper<Omit<TransportReportTrips, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['TransportReportTrip']>>> }>;
  TransportSource: TransportSource;
  TransportZone: ResolverTypeWrapper<TransportZone>;
  TransportZoneStatus: TransportZoneStatus;
  TransportZones: ResolverTypeWrapper<TransportZones>;
  TraversedJourney: ResolverTypeWrapper<TraversedJourney>;
  TriggerComputeTripSummaryInput: TriggerComputeTripSummaryInput;
  TriggerJobReportsInput: TriggerJobReportsInput;
  Trip: ResolverTypeWrapper<Omit<Trip, 'legs'> & { legs?: Maybe<Array<Maybe<ResolversTypes['TransportJob']>>> }>;
  TripFormat: TripFormat;
  TripInput: TripInput;
  TripLocations: ResolverTypeWrapper<Omit<TripLocations, 'trip'> & { trip?: Maybe<ResolversTypes['TransportJob']> }>;
  TripLocationsV2: ResolverTypeWrapper<Omit<TripLocationsV2, 'trip'> & { trip?: Maybe<ResolversTypes['TransportJob']> }>;
  TripStatus: TripStatus;
  TripSummaries: ResolverTypeWrapper<Omit<TripSummaries, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['TripSummary']>>> }>;
  TripSummariesQueryInput: TripSummariesQueryInput;
  TripSummary: ResolverTypeWrapper<Omit<TripSummary, 'emptyTrips' | 'ladenTrips'> & { emptyTrips?: Maybe<ResolversTypes['EmptyTrips']>, ladenTrips?: Maybe<Array<Maybe<ResolversTypes['TransportJob']>>> }>;
  TripTimings: ResolverTypeWrapper<TripTimings>;
  Trips: ResolverTypeWrapper<Omit<Trips, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['Trip']>>> }>;
  TruckAlert: ResolverTypeWrapper<TruckAlert>;
  TruckAlertMedia: ResolverTypeWrapper<TruckAlertMedia>;
  TruckAlerts: ResolverTypeWrapper<TruckAlerts>;
  TruckLocation: ResolverTypeWrapper<TruckLocation>;
  TruckLocationLatest: ResolverTypeWrapper<TruckLocationLatest>;
  TruckLocationV2: ResolverTypeWrapper<TruckLocationV2>;
  TruckSpeeds: ResolverTypeWrapper<TruckSpeeds>;
  URL: ResolverTypeWrapper<Scalars['URL']['output']>;
  UUID: ResolverTypeWrapper<Scalars['UUID']['output']>;
  UndeleteIncentiveTypeInput: UndeleteIncentiveTypeInput;
  UpdateAddressInput: UpdateAddressInput;
  UpdateAddressLocationInput: UpdateAddressLocationInput;
  UpdateAvailabilityInput: UpdateAvailabilityInput;
  UpdateBaseCompanyInput: UpdateBaseCompanyInput;
  UpdateBillingUnitInput: UpdateBillingUnitInput;
  UpdateBookingInput: UpdateBookingInput;
  UpdateBookingTypeInput: UpdateBookingTypeInput;
  UpdateChannelInput: UpdateChannelInput;
  UpdateChargeCategoryInput: UpdateChargeCategoryInput;
  UpdateChargeItemCodeInput: UpdateChargeItemCodeInput;
  UpdateChargeItemInput: UpdateChargeItemInput;
  UpdateChargeItemMiscInput: UpdateChargeItemMiscInput;
  UpdateChargeItemTaxInput: UpdateChargeItemTaxInput;
  UpdateChildNodeInput: UpdateChildNodeInput;
  UpdateChildNodeStatusInput: UpdateChildNodeStatusInput;
  UpdateCompanyCodeInput: UpdateCompanyCodeInput;
  UpdateCompanyInput: UpdateCompanyInput;
  UpdateContactInput: UpdateContactInput;
  UpdateContainerGradeInput: UpdateContainerGradeInput;
  UpdateContainerTypeInput: UpdateContainerTypeInput;
  UpdateCostItemInput: UpdateCostItemInput;
  UpdateDocumentCreator: UpdateDocumentCreator;
  UpdateDocumentCreatorTemplate: UpdateDocumentCreatorTemplate;
  UpdateDriverImageInput: UpdateDriverImageInput;
  UpdateDriverInput: UpdateDriverInput;
  UpdateDriverStatusInput: UpdateDriverStatusInput;
  UpdateEntityDocumentInput: UpdateEntityDocumentInput;
  UpdateEntityDocumentStatusInput: UpdateEntityDocumentStatusInput;
  UpdateExchangeRateInput: UpdateExchangeRateInput;
  UpdateFafInput: UpdateFafInput;
  UpdateFuelInput: UpdateFuelInput;
  UpdateGateLogInput: UpdateGateLogInput;
  UpdateGlCodeInput: UpdateGlCodeInput;
  UpdateGroupInput: UpdateGroupInput;
  UpdateIncentiveInput: UpdateIncentiveInput;
  UpdateIncentiveRateInput: UpdateIncentiveRateInput;
  UpdateIncentiveTypeInput: UpdateIncentiveTypeInput;
  UpdateIncentiveVoucherInput: UpdateIncentiveVoucherInput;
  UpdateIntegrationDetailInput: UpdateIntegrationDetailInput;
  UpdateIntegrationMappingInput: UpdateIntegrationMappingInput;
  UpdateJobInput: UpdateJobInput;
  UpdateJobOptionInput: UpdateJobOptionInput;
  UpdateJobTripInput: UpdateJobTripInput;
  UpdateJobTypeInput: UpdateJobTypeInput;
  UpdateLegInput: UpdateLegInput;
  UpdateLegTimingInput: UpdateLegTimingInput;
  UpdatePaymentInput: UpdatePaymentInput;
  UpdatePortalCompanyInput: UpdatePortalCompanyInput;
  UpdateQuotationInput: UpdateQuotationInput;
  UpdateQuotationItemInput: UpdateQuotationItemInput;
  UpdateReportInput: UpdateReportInput;
  UpdateRoleInput: UpdateRoleInput;
  UpdateRootNodeInput: UpdateRootNodeInput;
  UpdateRootNodeStatusInput: UpdateRootNodeStatusInput;
  UpdateSettingInput: UpdateSettingInput;
  UpdateShiftInput: UpdateShiftInput;
  UpdateTaxInput: UpdateTaxInput;
  UpdateTrailerImageInput: UpdateTrailerImageInput;
  UpdateTrailerInput: UpdateTrailerInput;
  UpdateTrailerStatusInput: UpdateTrailerStatusInput;
  UpdateTransportAreaCodeInput: UpdateTransportAreaCodeInput;
  UpdateTransportJobTypeInput: UpdateTransportJobTypeInput;
  UpdateTransportRateInput: UpdateTransportRateInput;
  UpdateTransportZoneInput: UpdateTransportZoneInput;
  UpdateTripInput: UpdateTripInput;
  UpdateVehicleImageInput: UpdateVehicleImageInput;
  UpdateVehicleInput: UpdateVehicleInput;
  UpdateVehicleStatusInput: UpdateVehicleStatusInput;
  UpdateVolumeUnitInput: UpdateVolumeUnitInput;
  UpdateVoucherInput: UpdateVoucherInput;
  UpdateVoucherItemInput: UpdateVoucherItemInput;
  UpdateWeightUnitInput: UpdateWeightUnitInput;
  UpdateYardInput: UpdateYardInput;
  User: ResolverTypeWrapper<User>;
  UserCompany: ResolverTypeWrapper<UserCompany>;
  UserInput: UserInput;
  UserPreference: ResolverTypeWrapper<UserPreference>;
  UserTimeout: ResolverTypeWrapper<UserTimeout>;
  Users: ResolverTypeWrapper<Users>;
  Vehicle: ResolverTypeWrapper<Vehicle>;
  VehicleOwnerType: VehicleOwnerType;
  VehiclePlan: ResolverTypeWrapper<VehiclePlan>;
  VehicleStatus: VehicleStatus;
  VehiclesResults: ResolverTypeWrapper<VehiclesResults>;
  Vessel: ResolverTypeWrapper<Vessel>;
  Viewer: ResolverTypeWrapper<Viewer>;
  VolumeUnit: ResolverTypeWrapper<VolumeUnit>;
  VolumeUnitStatus: VolumeUnitStatus;
  VolumeUnits: ResolverTypeWrapper<VolumeUnits>;
  Voucher: ResolverTypeWrapper<Omit<Voucher, 'approvals' | 'bookingDocuments' | 'bookings' | 'voucherItems' | 'voucherPayments'> & { approvals?: Maybe<Array<Maybe<ResolversTypes['Approval']>>>, bookingDocuments?: Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, bookings?: Maybe<Array<Maybe<ResolversTypes['Booking']>>>, voucherItems?: Maybe<Array<Maybe<ResolversTypes['VoucherItem']>>>, voucherPayments?: Maybe<Array<Maybe<ResolversTypes['VoucherPayment']>>> }>;
  VoucherCategoryType: VoucherCategoryType;
  VoucherItem: ResolverTypeWrapper<Omit<VoucherItem, 'booking' | 'job' | 'voucher' | 'voucherCn' | 'voucherItemCn'> & { booking?: Maybe<ResolversTypes['Booking']>, job?: Maybe<ResolversTypes['Job']>, voucher?: Maybe<ResolversTypes['Voucher']>, voucherCn?: Maybe<ResolversTypes['Voucher']>, voucherItemCn?: Maybe<ResolversTypes['VoucherItem']> }>;
  VoucherPayment: ResolverTypeWrapper<Omit<VoucherPayment, 'payment' | 'voucher'> & { payment?: Maybe<ResolversTypes['Payment']>, voucher?: Maybe<ResolversTypes['Voucher']> }>;
  VoucherPermissions: ResolverTypeWrapper<VoucherPermissions>;
  VoucherRevisionType: VoucherRevisionType;
  VoucherSearchFilter: VoucherSearchFilter;
  VoucherStatus: VoucherStatus;
  VoucherTemplate: ResolverTypeWrapper<VoucherTemplate>;
  VoucherTypeType: VoucherTypeType;
  Vouchers: ResolverTypeWrapper<Omit<Vouchers, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversTypes['Voucher']>>> }>;
  VouchersJson: ResolverTypeWrapper<VouchersJson>;
  VouchersSearchInput: VouchersSearchInput;
  Waypoint: ResolverTypeWrapper<Waypoint>;
  WeightUnit: ResolverTypeWrapper<WeightUnit>;
  WeightUnitStatus: WeightUnitStatus;
  WeightUnits: ResolverTypeWrapper<WeightUnits>;
  Yard: ResolverTypeWrapper<Yard>;
  YardQueryInput: YardQueryInput;
  YardStatus: YardStatus;
  Yards: ResolverTypeWrapper<Yards>;
  chargeItemMigrateType: ChargeItemMigrateType;
  costItemErrorType: ResolverTypeWrapper<CostItemErrorType>;
  costSheetErrorEnum: CostSheetErrorEnum;
  makeRequestInput: MakeRequestInput;
  numberPagination: ResolverTypeWrapper<NumberPagination>;
  recomputeLegActivitiesQuery: RecomputeLegActivitiesQuery;
  removeDriverCompanyInput: RemoveDriverCompanyInput;
  removeTrailerCompanyInput: RemoveTrailerCompanyInput;
  removeVehicleCompanyInput: RemoveVehicleCompanyInput;
  searchConsolVouchersInput: SearchConsolVouchersInput;
  searchConsolVouchersIssueDateInput: SearchConsolVouchersIssueDateInput;
  searchVouchersInput: SearchVouchersInput;
  templateResource: TemplateResource;
  transportRateOptions: TransportRateOptions;
  transportRateSelector: TransportRateSelector;
  updateDriverCompanyInput: UpdateDriverCompanyInput;
  updateDriverEmployeeInput: UpdateDriverEmployeeInput;
  updateEntityRequirementCategoryInput: UpdateEntityRequirementCategoryInput;
  updateEntityRequirementsInput: UpdateEntityRequirementsInput;
  updateTrailerCompanyInput: UpdateTrailerCompanyInput;
  updateVehicleCompanyInput: UpdateVehicleCompanyInput;
  voucherItemQueryInput: VoucherItemQueryInput;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  ActionTemplate: ResolversUnionTypes<ResolversParentTypes>['ActionTemplate'];
  AddAddressInput: AddAddressInput;
  AddChargeItemCodeInput: AddChargeItemCodeInput;
  AddChargeItemInput: AddChargeItemInput;
  AddChargeItemTaxInput: AddChargeItemTaxInput;
  AddContactInput: AddContactInput;
  AddCostItemInput: AddCostItemInput;
  AddEntityAddressInput: AddEntityAddressInput;
  AddEntityDocumentInput: AddEntityDocumentInput;
  AddJobInput: AddJobInput;
  AddLegInput: AddLegInput;
  AddLegsInput: AddLegsInput;
  AddManifestInput: AddManifestInput;
  AddManifestLegsInput: AddManifestLegsInput;
  AddManifestTripsInput: AddManifestTripsInput;
  AddPaymentInput: AddPaymentInput;
  AddVoucherItemInput: AddVoucherItemInput;
  AddVoucherItemViaVoucherInput: AddVoucherItemViaVoucherInput;
  AddVoucherPaymentInput: AddVoucherPaymentInput;
  Address: Address;
  AddressInput: AddressInput;
  Addresses: Addresses;
  Approval: Omit<Approval, 'bookingDocument' | 'voucher'> & { bookingDocument?: Maybe<ResolversParentTypes['BookingDocument']>, voucher?: Maybe<ResolversParentTypes['Voucher']> };
  ApprovalInput: ApprovalInput;
  Approvals: Omit<Approvals, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['Approval']>>> };
  AssignPortalCompanyInput: AssignPortalCompanyInput;
  Assignee: Assignee;
  Attachment: Attachment;
  Auth: Auth;
  Auth0: Auth0;
  AuthProvider: AuthProvider;
  AvailabilitiesResults: AvailabilitiesResults;
  Availability: Availability;
  BCSettingAreaCode: BcSettingAreaCode;
  BaseCompanies: BaseCompanies;
  BaseCompany: BaseCompany;
  BaseCompanyDetails: BaseCompanyDetails;
  BaseCompanyDetailsInput: BaseCompanyDetailsInput;
  BaseCompanySetting: BaseCompanySetting;
  BaseCompanySettings: BaseCompanySettings;
  BillingUnit: BillingUnit;
  BillingUnits: BillingUnits;
  Booking: Omit<Booking, 'assignees' | 'bookingDocuments' | 'events' | 'jobs' | 'vouchers'> & { assignees?: Maybe<Array<Maybe<ResolversParentTypes['Assignee']>>>, bookingDocuments?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>>, events?: Maybe<Array<Maybe<ResolversParentTypes['Event']>>>, jobs?: Maybe<Array<Maybe<ResolversParentTypes['Job']>>>, vouchers?: Maybe<Array<Maybe<ResolversParentTypes['Voucher']>>> };
  BookingAssigneeInput: BookingAssigneeInput;
  BookingDocument: Omit<BookingDocument, 'approvals' | 'bookings'> & { approvals?: Maybe<Array<Maybe<ResolversParentTypes['Approval']>>>, bookings?: Maybe<Array<Maybe<ResolversParentTypes['Booking']>>> };
  BookingDocumentTemplate: BookingDocumentTemplate;
  BookingDocuments: Omit<BookingDocuments, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  BookingEvent: BookingEvent;
  BookingEventPayload: BookingEventPayload;
  BookingFieldValue: BookingFieldValue;
  BookingFieldValueInput: BookingFieldValueInput;
  BookingPermissionType: BookingPermissionType;
  BookingState: BookingState;
  BookingSummary: BookingSummary;
  BookingTransportInput: BookingTransportInput;
  BookingType: BookingType;
  BookingTypeBilling: BookingTypeBilling;
  BookingTypeBillingInput: BookingTypeBillingInput;
  BookingTypeDynamicField: BookingTypeDynamicField;
  BookingTypeDynamicFieldInput: BookingTypeDynamicFieldInput;
  BookingTypes: BookingTypes;
  Bookings: Omit<Bookings, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['Booking']>>> };
  BookingsJson: BookingsJson;
  BookingsSearchFilter: BookingsSearchFilter;
  BookingsSearchInput: BookingsSearchInput;
  Boolean: Scalars['Boolean']['output'];
  BulkImportFunction: BulkImportFunction;
  BulkImportFunctionSettingInput: BulkImportFunctionSettingInput;
  BulkImportFunctionUpdateInput: BulkImportFunctionUpdateInput;
  BulkImportFunctions: BulkImportFunctions;
  BulkImportFunctionsInput: BulkImportFunctionsInput;
  ChangeCostItemInput: ChangeCostItemInput;
  Channel: Channel;
  Channels: Channels;
  ChargeCategories: ChargeCategories;
  ChargeCategory: ChargeCategory;
  ChargeItem: ChargeItem;
  ChargeItemCode: ChargeItemCode;
  ChargeItemMisc: ChargeItemMisc;
  ChargeItemMiscs: ChargeItemMiscs;
  ChargeItemTax: ChargeItemTax;
  ChargeItems: ChargeItems;
  ChildNode: ChildNode;
  ChildNodes: ChildNodes;
  ChildNodesQueryInput: ChildNodesQueryInput;
  ChildSelector: ChildSelector;
  Chronology: Chronology;
  ChronologyInput: ChronologyInput;
  ChronologyNewBookingInput: ChronologyNewBookingInput;
  ChronologyUpdateInput: ChronologyUpdateInput;
  CloneNodeBranchInput: CloneNodeBranchInput;
  CloseShiftInput: CloseShiftInput;
  Commodity: Commodity;
  CommodityInput: CommodityInput;
  CommodityType: CommodityType;
  CommodityTypes: CommodityTypes;
  Companies: Companies;
  Company: Company;
  CompanyAccess: CompanyAccess;
  CompanyAccesses: CompanyAccesses;
  CompanySearch: CompanySearch;
  ComputeRateInput: ComputeRateInput;
  ComputeReportJobSummaryInput: ComputeReportJobSummaryInput;
  ComputedRate: ComputedRate;
  ComputedRateResult: ComputedRateResult;
  ConsolVoucher: Omit<ConsolVoucher, 'vouchers'> & { vouchers?: Maybe<Array<Maybe<ResolversParentTypes['Voucher']>>> };
  ConsolVouchers: Omit<ConsolVouchers, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['ConsolVoucher']>>> };
  Contact: Contact;
  ContactSimple: ContactSimple;
  ContactSimpleInput: ContactSimpleInput;
  Contacts: Contacts;
  ContainerEvent: ContainerEvent;
  ContainerGrade: ContainerGrade;
  ContainerGrades: ContainerGrades;
  ContainerType: ContainerType;
  ContainerTypes: ContainerTypes;
  CostItem: CostItem;
  CostSheet: CostSheet;
  Counter: Counter;
  CounterResults: CounterResults;
  Countries: Countries;
  Country: Country;
  CountryAlpha2: Scalars['CountryAlpha2']['output'];
  CountryAlpha3: Scalars['CountryAlpha3']['output'];
  CreateBaseCompanyInput: CreateBaseCompanyInput;
  CreateBillingUnitInput: CreateBillingUnitInput;
  CreateChannelInput: CreateChannelInput;
  CreateChargeItemMiscInput: CreateChargeItemMiscInput;
  CreateCompanyInput: CreateCompanyInput;
  CreateConsolVoucherInput: CreateConsolVoucherInput;
  CreateContainerGradeInput: CreateContainerGradeInput;
  CreateContainerTypeInput: CreateContainerTypeInput;
  CreateDocumentCreator: CreateDocumentCreator;
  CreateDocumentCreatorTemplate: CreateDocumentCreatorTemplate;
  CreateDriverInput: CreateDriverInput;
  CreateExchangeRateInput: CreateExchangeRateInput;
  CreateFafInput: CreateFafInput;
  CreateFuelInput: CreateFuelInput;
  CreateGateLogInput: CreateGateLogInput;
  CreateGlCodeInput: CreateGlCodeInput;
  CreateIncentiveInput: CreateIncentiveInput;
  CreateIncentiveRateInput: CreateIncentiveRateInput;
  CreateIncentiveTypeInput: CreateIncentiveTypeInput;
  CreateIncentiveVoucherInput: CreateIncentiveVoucherInput;
  CreateIntegrationDetailInput: CreateIntegrationDetailInput;
  CreateIntegrationMappingInput: CreateIntegrationMappingInput;
  CreateJobOptionInput: CreateJobOptionInput;
  CreatePortalCompanyInput: CreatePortalCompanyInput;
  CreateQuotationInput: CreateQuotationInput;
  CreateQuotationItemInput: CreateQuotationItemInput;
  CreateQuotationItemViaQuotationInput: CreateQuotationItemViaQuotationInput;
  CreateReportInput: CreateReportInput;
  CreateRoleInput: CreateRoleInput;
  CreateSettingInput: CreateSettingInput;
  CreateShiftInput: CreateShiftInput;
  CreateTaxInput: CreateTaxInput;
  CreateTrailerInput: CreateTrailerInput;
  CreateTransportAreaCodeInput: CreateTransportAreaCodeInput;
  CreateTransportJobTypeInput: CreateTransportJobTypeInput;
  CreateTransportRateInput: CreateTransportRateInput;
  CreateTransportZoneInput: CreateTransportZoneInput;
  CreateVehicleInput: CreateVehicleInput;
  CreateVolumeUnitInput: CreateVolumeUnitInput;
  CreateVoucherInput: CreateVoucherInput;
  CreateWeightUnitInput: CreateWeightUnitInput;
  CreateYardInput: CreateYardInput;
  Currencies: Currencies;
  Currency: Currency;
  DataDetails: Omit<DataDetails, 'jobs'> & { jobs?: Maybe<Array<Maybe<ResolversParentTypes['Job']>>> };
  DataDetailsInput: DataDetailsInput;
  Date: Scalars['Date']['output'];
  DateTime: Scalars['DateTime']['output'];
  DeleteIncentiveTypeInput: DeleteIncentiveTypeInput;
  DeleteLegInput: DeleteLegInput;
  Document: Document;
  DocumentCreator: Omit<DocumentCreator, 'data'> & { data?: Maybe<ResolversParentTypes['DataDetails']> };
  DocumentCreatorReference: DocumentCreatorReference;
  DocumentCreatorTemplate: DocumentCreatorTemplate;
  DocumentCreatorTemplates: DocumentCreatorTemplates;
  DocumentCreators: Omit<DocumentCreators, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['DocumentCreator']>>> };
  DocumentDescription: DocumentDescription;
  DocumentInput: DocumentInput;
  Driver: Driver;
  DriverBehaviorAuth: DriverBehaviorAuth;
  DriverUtilDept: DriverUtilDept;
  DriversResults: DriversResults;
  Email: Scalars['Email']['output'];
  EmailRequestFields: ResolversUnionTypes<ResolversParentTypes>['EmailRequestFields'];
  EmailRequestFieldsBookingConfirmationFromLiner: Omit<EmailRequestFieldsBookingConfirmationFromLiner, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestFieldsBookingConfirmationReeferFromLiner: Omit<EmailRequestFieldsBookingConfirmationReeferFromLiner, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestFieldsConsigneeDraftNotification: Omit<EmailRequestFieldsConsigneeDraftNotification, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestFieldsEdo: Omit<EmailRequestFieldsEdo, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestFieldsExportDocs: Omit<EmailRequestFieldsExportDocs, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestFieldsHaulageRFC: EmailRequestFieldsHaulageRfc;
  EmailRequestFieldsHaulageRot: Omit<EmailRequestFieldsHaulageRot, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestFieldsImportDocs: Omit<EmailRequestFieldsImportDocs, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestFieldsLinerBookingConfirmationCustomerRequest: Omit<EmailRequestFieldsLinerBookingConfirmationCustomerRequest, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestFieldsLinerContainerMovementOrder: Omit<EmailRequestFieldsLinerContainerMovementOrder, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestFieldsLinerNotifyCustomerComplete: Omit<EmailRequestFieldsLinerNotifyCustomerComplete, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill: Omit<EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestFieldsNoa: Omit<EmailRequestFieldsNoa, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestFieldsPaymentNotification: Omit<EmailRequestFieldsPaymentNotification, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  EmailRequestInput: EmailRequestInput;
  Employee: Employee;
  EmployeeDriver: EmployeeDriver;
  Employees: Employees;
  EmptyTrip: EmptyTrip;
  EmptyTripAddInput: EmptyTripAddInput;
  EmptyTripProposed: EmptyTripProposed;
  EmptyTripQuery: EmptyTripQuery;
  EmptyTripUpdateInput: EmptyTripUpdateInput;
  EmptyTripUpdateStatusInput: EmptyTripUpdateStatusInput;
  EmptyTrips: EmptyTrips;
  EndManifestTripsInput: EndManifestTripsInput;
  EntityAddress: EntityAddress;
  EntityCategoryRequirement: EntityCategoryRequirement;
  EntityCompany: EntityCompany;
  EntityCompanyAddress: EntityCompanyAddress;
  EntityDetails: EntityDetails;
  EntityDocumentRequirement: EntityDocumentRequirement;
  EntityDocumentRequirementInput: EntityDocumentRequirementInput;
  EntityEvent: EntityEvent;
  EntityExceptionOutcome: EntityExceptionOutcome;
  EntityExceptions: EntityExceptions;
  EntityExceptionsItem: EntityExceptionsItem;
  EntityField: EntityField;
  EntityFieldInput: EntityFieldInput;
  EntityFieldReq: EntityFieldReq;
  EntityFieldReqInput: EntityFieldReqInput;
  EntityRequirements: EntityRequirements;
  EntityTypeUnion: ResolversUnionTypes<ResolversParentTypes>['EntityTypeUnion'];
  Event: ResolversUnionTypes<ResolversParentTypes>['Event'];
  EventCreator: EventCreator;
  EventEtc: EventEtc;
  EventVoucher: EventVoucher;
  EventVouchers: EventVouchers;
  EventsEtc: EventsEtc;
  ExchangeRate: ExchangeRate;
  ExchangeRates: ExchangeRates;
  Faf: Faf;
  Fafs: Fafs;
  Float: Scalars['Float']['output'];
  FmsCompany: FmsCompany;
  Fuel: Fuel;
  FuelQueryInput: FuelQueryInput;
  Fuels: Fuels;
  GateLog: GateLog;
  GateLogQueryInput: GateLogQueryInput;
  GateLogs: GateLogs;
  GenerateReportInput: GenerateReportInput;
  Geocode: Geocode;
  Geocodes: Geocodes;
  Geometry: Geometry;
  GeometryPoint: GeometryPoint;
  GeometryPolygon: GeometryPolygon;
  GlCode: GlCode;
  GlCodes: GlCodes;
  GoogleGeometry: GoogleGeometry;
  Group: Group;
  GroupRule: GroupRule;
  GroupRuleInput: GroupRuleInput;
  Groups: Groups;
  ImpCustConsigneeDraftNotification: Omit<ImpCustConsigneeDraftNotification, 'documents'> & { documents?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>> };
  Incentive: Omit<Incentive, 'incentiveVoucher' | 'transportJob' | 'voucher'> & { incentiveVoucher?: Maybe<ResolversParentTypes['IncentiveVoucher']>, transportJob?: Maybe<ResolversParentTypes['TransportJob']>, voucher?: Maybe<ResolversParentTypes['Voucher']> };
  IncentiveCategorySum: IncentiveCategorySum;
  IncentiveQueryInput: IncentiveQueryInput;
  IncentiveRate: IncentiveRate;
  IncentiveRateDetails: IncentiveRateDetails;
  IncentiveRateQueryInput: IncentiveRateQueryInput;
  IncentiveRates: IncentiveRates;
  IncentiveType: IncentiveType;
  IncentiveTypeQueryInput: IncentiveTypeQueryInput;
  IncentiveTypeSum: IncentiveTypeSum;
  IncentiveTypes: IncentiveTypes;
  IncentiveVoucher: Omit<IncentiveVoucher, 'incentiveCategorySum' | 'incentives'> & { incentiveCategorySum?: Maybe<Array<Maybe<ResolversParentTypes['IncentiveCategorySum']>>>, incentives?: Maybe<Array<Maybe<ResolversParentTypes['Incentive']>>> };
  IncentiveVoucherQueryInput: IncentiveVoucherQueryInput;
  IncentiveVouchers: Omit<IncentiveVouchers, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['IncentiveVoucher']>>> };
  Incentives: Omit<Incentives, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['Incentive']>>> };
  InputIncentiveRateDetails: InputIncentiveRateDetails;
  InputTransportRateDetails: InputTransportRateDetails;
  Int: Scalars['Int']['output'];
  Integration: Integration;
  IntegrationDetail: IntegrationDetail;
  IntegrationDetails: IntegrationDetails;
  IntegrationLog: IntegrationLog;
  IntegrationLogs: IntegrationLogs;
  IntegrationLogsQuery: IntegrationLogsQuery;
  IntegrationMapping: IntegrationMapping;
  IntegrationMappings: IntegrationMappings;
  Integrations: Integrations;
  InviteCompanyAccess: InviteCompanyAccess;
  InviteCompanyAccessInput: InviteCompanyAccessInput;
  InviteCompanyAccesses: InviteCompanyAccesses;
  InvitePortalCompanyInput: InvitePortalCompanyInput;
  InviteUser: InviteUser;
  InviteUserResponse: InviteUserResponse;
  InvitedBaseCompany: InvitedBaseCompany;
  JSON: Scalars['JSON']['output'];
  JSONObject: Scalars['JSONObject']['output'];
  Job: Omit<Job, 'reference' | 'trips'> & { reference?: Maybe<ResolversParentTypes['JobReferences']>, trips?: Maybe<Array<Maybe<ResolversParentTypes['Trip']>>> };
  JobInput: JobInput;
  JobOption: JobOption;
  JobOptions: JobOptions;
  JobReferences: JobReferences;
  JobReferencesInput: JobReferencesInput;
  JobSummaries: JobSummaries;
  JobSummariesQueryInput: JobSummariesQueryInput;
  JobSummary: JobSummary;
  JobTripUpdateInput: JobTripUpdateInput;
  JobType: JobType;
  JobTypeDetails: JobTypeDetails;
  JobTypeDetailsInput: JobTypeDetailsInput;
  JobTypeRequiredFields: JobTypeRequiredFields;
  JobTypeRequiredInput: JobTypeRequiredInput;
  JobTypeUnitOptions: JobTypeUnitOptions;
  JobTypeUnitOptionsInput: JobTypeUnitOptionsInput;
  JobTypes: JobTypes;
  Jobs: Omit<Jobs, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['Job']>>> };
  JobsJson: JobsJson;
  JobsSearchFilter: JobsSearchFilter;
  JobsSearchInput: JobsSearchInput;
  LanguageCode: Scalars['LanguageCode']['output'];
  Leg: Leg;
  LegActivities: LegActivities;
  LegActivitiesQuery: LegActivitiesQuery;
  LegActivity: LegActivity;
  LegInput: LegInput;
  LegSummaries: Omit<LegSummaries, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['LegSummary']>>> };
  LegSummariesQueryInput: LegSummariesQueryInput;
  LegSummary: Omit<LegSummary, 'emptyLegs' | 'ladenLegs'> & { emptyLegs?: Maybe<ResolversParentTypes['EmptyTrips']>, ladenLegs?: Maybe<Array<Maybe<ResolversParentTypes['TransportJob']>>> };
  LimitedString: Scalars['LimitedString']['output'];
  LimitedString2: Scalars['LimitedString2']['output'];
  LimitedString3: Scalars['LimitedString3']['output'];
  Location: Location;
  LocationInput: LocationInput;
  MakeBookingCommentInput: MakeBookingCommentInput;
  MakeBookingInput: MakeBookingInput;
  Manifest: Omit<Manifest, 'legs'> & { legs?: Maybe<Array<Maybe<ResolversParentTypes['TransportJob']>>> };
  ManifestLocation: ManifestLocation;
  ManifestLocationInput: ManifestLocationInput;
  Manifests: Omit<Manifests, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['Manifest']>>> };
  MessageEvent: MessageEvent;
  MessageEventPayload: MessageEventPayload;
  Mutation: {};
  NodeConnection: NodeConnection;
  Notification: Notification;
  NotificationInput: NotificationInput;
  ObjectID: Scalars['ObjectID']['output'];
  OpenShiftInput: OpenShiftInput;
  PageInfo: PageInfo;
  Password: Scalars['Password']['output'];
  Payment: Omit<Payment, 'voucherPayments'> & { voucherPayments?: Maybe<Array<Maybe<ResolversParentTypes['VoucherPayment']>>> };
  Payments: Omit<Payments, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['Payment']>>> };
  Permission: Permission;
  PermissionType: PermissionType;
  Permissions: Permissions;
  Place: Place;
  PlaceResults: PlaceResults;
  PlannerQueueRunnerInput: PlannerQueueRunnerInput;
  PmUtilDept: PmUtilDept;
  Point: Point;
  PopulateAvailabilityInput: PopulateAvailabilityInput;
  Port: Port;
  PortalCompanies: PortalCompanies;
  PortalCompany: PortalCompany;
  PortalCompanyDetails: PortalCompanyDetails;
  PortalCompanyDetailsInput: PortalCompanyDetailsInput;
  PortalCompanyReportConfig: PortalCompanyReportConfig;
  PortalCompanyReportConfigInput: PortalCompanyReportConfigInput;
  PortalReports: PortalReports;
  Ports: Ports;
  ProcessFlow: ProcessFlow;
  ProcessFlowChronologiesType: ProcessFlowChronologiesType;
  ProcessFlowCreateType: ProcessFlowCreateType;
  ProcessFlowDocumentType: ProcessFlowDocumentType;
  ProcessFlowRequestType: ProcessFlowRequestType;
  ProcessFlowSection: ProcessFlowSection;
  ProcessFlowStepType: ProcessFlowStepType;
  ProcessFlowVoucherType: ProcessFlowVoucherType;
  ProcessReportPortalCompanyInput: ProcessReportPortalCompanyInput;
  ProcessTypeCategories: ProcessTypeCategories;
  ProcessTypes: ProcessTypes;
  Profile: Profile;
  Query: {};
  QueryAvailabilitiesInput: QueryAvailabilitiesInput;
  QueryEventEtc: QueryEventEtc;
  QueryEventVoucher: QueryEventVoucher;
  QueryManifestsInput: QueryManifestsInput;
  QueryReverseGeocode: QueryReverseGeocode;
  Quotation: Quotation;
  QuotationItem: QuotationItem;
  Quotations: Quotations;
  Rate: Rate;
  RateLogItem: RateLogItem;
  RateValue: RateValue;
  RateValueBracket: RateValueBracket;
  RateValueBracketInput: RateValueBracketInput;
  RateValueInput: RateValueInput;
  RemoveManifestLegsInput: RemoveManifestLegsInput;
  Report: Report;
  ReportColumn: ReportColumn;
  ReportColumnInput: ReportColumnInput;
  ReportField: ReportField;
  ReportFieldsInput: ReportFieldsInput;
  ReportFilter: ReportFilter;
  ReportJobSummaries: ReportJobSummaries;
  ReportJobSummariesQueryInput: ReportJobSummariesQueryInput;
  ReportJobSummary: ReportJobSummary;
  ReportJobSummaryDept: ReportJobSummaryDept;
  ReportJobSummaryZone: ReportJobSummaryZone;
  ReportMeta: ReportMeta;
  ReportPortalCompany: ReportPortalCompany;
  ReportQueryInput: ReportQueryInput;
  ReportSummary: ReportSummary;
  Reports: Reports;
  Request: Request;
  RequestBookingDocument: RequestBookingDocument;
  RequestDocumentTypesInput: RequestDocumentTypesInput;
  RequestEmailResultsType: RequestEmailResultsType;
  RequestMetaType: RequestMetaType;
  RequestTemplate: RequestTemplate;
  RequisitionTemplate: Omit<RequisitionTemplate, 'fields'> & { fields?: Maybe<ResolversParentTypes['EmailRequestFields']> };
  Response: Response;
  ReverseGeoAddress: ReverseGeoAddress;
  Role: Role;
  Roles: Roles;
  RolesQuery: RolesQuery;
  RootNode: RootNode;
  RootNodeQueryInput: RootNodeQueryInput;
  RootNodes: RootNodes;
  RootNodesQueryInput: RootNodesQueryInput;
  SRate: SRate;
  SeaPath: SeaPath;
  SeaRoute: SeaRoute;
  Seaport: Seaport;
  Search: Omit<Search, 'hits'> & { hits?: Maybe<Array<Maybe<ResolversParentTypes['SearchResult']>>> };
  SearchInput: SearchInput;
  SearchResult: ResolversUnionTypes<ResolversParentTypes>['SearchResult'];
  Service: Service;
  ServiceInput: ServiceInput;
  Setting: Setting;
  SettingInput: SettingInput;
  Shift: Omit<Shift, 'incentiveCategorySum' | 'incentives'> & { incentiveCategorySum?: Maybe<Array<Maybe<ResolversParentTypes['IncentiveCategorySum']>>>, incentives?: Maybe<Array<Maybe<ResolversParentTypes['Incentive']>>> };
  ShiftBreak: ShiftBreak;
  ShiftBreakInput: ShiftBreakInput;
  ShiftQueryInput: ShiftQueryInput;
  Shifts: Shifts;
  ShippingRate: ShippingRate;
  ShippingRateType: ShippingRateType;
  ShippingRates: ShippingRates;
  String: Scalars['String']['output'];
  SubmitROTInput: SubmitRotInput;
  SuperAdmins: SuperAdmins;
  Tax: Tax;
  Taxes: Taxes;
  Template: Template;
  Trailer: Trailer;
  TrailersResults: TrailersResults;
  Transport: Transport;
  TransportActivity: TransportActivity;
  TransportActivityFlow: TransportActivityFlow;
  TransportActivityShipXTrigger: TransportActivityShipXTrigger;
  TransportAreaCode: TransportAreaCode;
  TransportAreaCodes: TransportAreaCodes;
  TransportInput: TransportInput;
  TransportJob: Omit<TransportJob, 'booking' | 'incentiveVouchers'> & { booking?: Maybe<ResolversParentTypes['Booking']>, incentiveVouchers?: Maybe<Array<Maybe<ResolversParentTypes['IncentiveVoucher']>>> };
  TransportJobQuery: TransportJobQuery;
  TransportJobType: TransportJobType;
  TransportJobTypes: TransportJobTypes;
  TransportJobs: Omit<TransportJobs, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['TransportJob']>>> };
  TransportQueueRunnerInput: TransportQueueRunnerInput;
  TransportRate: TransportRate;
  TransportRateDetails: TransportRateDetails;
  TransportRates: TransportRates;
  TransportReportJob: Omit<TransportReportJob, 'booking' | 'incentiveVouchers' | 'transportJobs' | 'vouchers'> & { booking?: Maybe<ResolversParentTypes['Booking']>, incentiveVouchers?: Maybe<Array<Maybe<ResolversParentTypes['IncentiveVoucher']>>>, transportJobs?: Maybe<Array<Maybe<ResolversParentTypes['TransportJob']>>>, vouchers?: Maybe<Array<Maybe<ResolversParentTypes['Voucher']>>> };
  TransportReportJobQuery: TransportReportJobQuery;
  TransportReportJobs: Omit<TransportReportJobs, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['TransportReportJob']>>> };
  TransportReportLegActivities: TransportReportLegActivities;
  TransportReportLegActivitiesQuery: TransportReportLegActivitiesQuery;
  TransportReportLegActivity: TransportReportLegActivity;
  TransportReportTrip: Omit<TransportReportTrip, 'booking' | 'incentiveVouchers' | 'transportJobs' | 'vouchers'> & { booking?: Maybe<ResolversParentTypes['Booking']>, incentiveVouchers?: Maybe<Array<Maybe<ResolversParentTypes['IncentiveVoucher']>>>, transportJobs?: Maybe<Array<Maybe<ResolversParentTypes['TransportJob']>>>, vouchers?: Maybe<Array<Maybe<ResolversParentTypes['Voucher']>>> };
  TransportReportTripQuery: TransportReportTripQuery;
  TransportReportTrips: Omit<TransportReportTrips, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['TransportReportTrip']>>> };
  TransportZone: TransportZone;
  TransportZones: TransportZones;
  TraversedJourney: TraversedJourney;
  TriggerComputeTripSummaryInput: TriggerComputeTripSummaryInput;
  TriggerJobReportsInput: TriggerJobReportsInput;
  Trip: Omit<Trip, 'legs'> & { legs?: Maybe<Array<Maybe<ResolversParentTypes['TransportJob']>>> };
  TripInput: TripInput;
  TripLocations: Omit<TripLocations, 'trip'> & { trip?: Maybe<ResolversParentTypes['TransportJob']> };
  TripLocationsV2: Omit<TripLocationsV2, 'trip'> & { trip?: Maybe<ResolversParentTypes['TransportJob']> };
  TripSummaries: Omit<TripSummaries, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['TripSummary']>>> };
  TripSummariesQueryInput: TripSummariesQueryInput;
  TripSummary: Omit<TripSummary, 'emptyTrips' | 'ladenTrips'> & { emptyTrips?: Maybe<ResolversParentTypes['EmptyTrips']>, ladenTrips?: Maybe<Array<Maybe<ResolversParentTypes['TransportJob']>>> };
  TripTimings: TripTimings;
  Trips: Omit<Trips, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['Trip']>>> };
  TruckAlert: TruckAlert;
  TruckAlertMedia: TruckAlertMedia;
  TruckAlerts: TruckAlerts;
  TruckLocation: TruckLocation;
  TruckLocationLatest: TruckLocationLatest;
  TruckLocationV2: TruckLocationV2;
  TruckSpeeds: TruckSpeeds;
  URL: Scalars['URL']['output'];
  UUID: Scalars['UUID']['output'];
  UndeleteIncentiveTypeInput: UndeleteIncentiveTypeInput;
  UpdateAddressInput: UpdateAddressInput;
  UpdateAddressLocationInput: UpdateAddressLocationInput;
  UpdateAvailabilityInput: UpdateAvailabilityInput;
  UpdateBaseCompanyInput: UpdateBaseCompanyInput;
  UpdateBillingUnitInput: UpdateBillingUnitInput;
  UpdateBookingInput: UpdateBookingInput;
  UpdateBookingTypeInput: UpdateBookingTypeInput;
  UpdateChannelInput: UpdateChannelInput;
  UpdateChargeCategoryInput: UpdateChargeCategoryInput;
  UpdateChargeItemCodeInput: UpdateChargeItemCodeInput;
  UpdateChargeItemInput: UpdateChargeItemInput;
  UpdateChargeItemMiscInput: UpdateChargeItemMiscInput;
  UpdateChargeItemTaxInput: UpdateChargeItemTaxInput;
  UpdateChildNodeInput: UpdateChildNodeInput;
  UpdateChildNodeStatusInput: UpdateChildNodeStatusInput;
  UpdateCompanyCodeInput: UpdateCompanyCodeInput;
  UpdateCompanyInput: UpdateCompanyInput;
  UpdateContactInput: UpdateContactInput;
  UpdateContainerGradeInput: UpdateContainerGradeInput;
  UpdateContainerTypeInput: UpdateContainerTypeInput;
  UpdateCostItemInput: UpdateCostItemInput;
  UpdateDocumentCreator: UpdateDocumentCreator;
  UpdateDocumentCreatorTemplate: UpdateDocumentCreatorTemplate;
  UpdateDriverImageInput: UpdateDriverImageInput;
  UpdateDriverInput: UpdateDriverInput;
  UpdateDriverStatusInput: UpdateDriverStatusInput;
  UpdateEntityDocumentInput: UpdateEntityDocumentInput;
  UpdateEntityDocumentStatusInput: UpdateEntityDocumentStatusInput;
  UpdateExchangeRateInput: UpdateExchangeRateInput;
  UpdateFafInput: UpdateFafInput;
  UpdateFuelInput: UpdateFuelInput;
  UpdateGateLogInput: UpdateGateLogInput;
  UpdateGlCodeInput: UpdateGlCodeInput;
  UpdateGroupInput: UpdateGroupInput;
  UpdateIncentiveInput: UpdateIncentiveInput;
  UpdateIncentiveRateInput: UpdateIncentiveRateInput;
  UpdateIncentiveTypeInput: UpdateIncentiveTypeInput;
  UpdateIncentiveVoucherInput: UpdateIncentiveVoucherInput;
  UpdateIntegrationDetailInput: UpdateIntegrationDetailInput;
  UpdateIntegrationMappingInput: UpdateIntegrationMappingInput;
  UpdateJobInput: UpdateJobInput;
  UpdateJobOptionInput: UpdateJobOptionInput;
  UpdateJobTripInput: UpdateJobTripInput;
  UpdateJobTypeInput: UpdateJobTypeInput;
  UpdateLegInput: UpdateLegInput;
  UpdateLegTimingInput: UpdateLegTimingInput;
  UpdatePaymentInput: UpdatePaymentInput;
  UpdatePortalCompanyInput: UpdatePortalCompanyInput;
  UpdateQuotationInput: UpdateQuotationInput;
  UpdateQuotationItemInput: UpdateQuotationItemInput;
  UpdateReportInput: UpdateReportInput;
  UpdateRoleInput: UpdateRoleInput;
  UpdateRootNodeInput: UpdateRootNodeInput;
  UpdateRootNodeStatusInput: UpdateRootNodeStatusInput;
  UpdateSettingInput: UpdateSettingInput;
  UpdateShiftInput: UpdateShiftInput;
  UpdateTaxInput: UpdateTaxInput;
  UpdateTrailerImageInput: UpdateTrailerImageInput;
  UpdateTrailerInput: UpdateTrailerInput;
  UpdateTrailerStatusInput: UpdateTrailerStatusInput;
  UpdateTransportAreaCodeInput: UpdateTransportAreaCodeInput;
  UpdateTransportJobTypeInput: UpdateTransportJobTypeInput;
  UpdateTransportRateInput: UpdateTransportRateInput;
  UpdateTransportZoneInput: UpdateTransportZoneInput;
  UpdateTripInput: UpdateTripInput;
  UpdateVehicleImageInput: UpdateVehicleImageInput;
  UpdateVehicleInput: UpdateVehicleInput;
  UpdateVehicleStatusInput: UpdateVehicleStatusInput;
  UpdateVolumeUnitInput: UpdateVolumeUnitInput;
  UpdateVoucherInput: UpdateVoucherInput;
  UpdateVoucherItemInput: UpdateVoucherItemInput;
  UpdateWeightUnitInput: UpdateWeightUnitInput;
  UpdateYardInput: UpdateYardInput;
  User: User;
  UserCompany: UserCompany;
  UserInput: UserInput;
  UserPreference: UserPreference;
  UserTimeout: UserTimeout;
  Users: Users;
  Vehicle: Vehicle;
  VehiclePlan: VehiclePlan;
  VehiclesResults: VehiclesResults;
  Vessel: Vessel;
  Viewer: Viewer;
  VolumeUnit: VolumeUnit;
  VolumeUnits: VolumeUnits;
  Voucher: Omit<Voucher, 'approvals' | 'bookingDocuments' | 'bookings' | 'voucherItems' | 'voucherPayments'> & { approvals?: Maybe<Array<Maybe<ResolversParentTypes['Approval']>>>, bookingDocuments?: Maybe<Array<Maybe<ResolversParentTypes['BookingDocument']>>>, bookings?: Maybe<Array<Maybe<ResolversParentTypes['Booking']>>>, voucherItems?: Maybe<Array<Maybe<ResolversParentTypes['VoucherItem']>>>, voucherPayments?: Maybe<Array<Maybe<ResolversParentTypes['VoucherPayment']>>> };
  VoucherItem: Omit<VoucherItem, 'booking' | 'job' | 'voucher' | 'voucherCn' | 'voucherItemCn'> & { booking?: Maybe<ResolversParentTypes['Booking']>, job?: Maybe<ResolversParentTypes['Job']>, voucher?: Maybe<ResolversParentTypes['Voucher']>, voucherCn?: Maybe<ResolversParentTypes['Voucher']>, voucherItemCn?: Maybe<ResolversParentTypes['VoucherItem']> };
  VoucherPayment: Omit<VoucherPayment, 'payment' | 'voucher'> & { payment?: Maybe<ResolversParentTypes['Payment']>, voucher?: Maybe<ResolversParentTypes['Voucher']> };
  VoucherPermissions: VoucherPermissions;
  VoucherSearchFilter: VoucherSearchFilter;
  VoucherTemplate: VoucherTemplate;
  Vouchers: Omit<Vouchers, 'rows'> & { rows?: Maybe<Array<Maybe<ResolversParentTypes['Voucher']>>> };
  VouchersJson: VouchersJson;
  VouchersSearchInput: VouchersSearchInput;
  Waypoint: Waypoint;
  WeightUnit: WeightUnit;
  WeightUnits: WeightUnits;
  Yard: Yard;
  YardQueryInput: YardQueryInput;
  Yards: Yards;
  chargeItemMigrateType: ChargeItemMigrateType;
  costItemErrorType: CostItemErrorType;
  makeRequestInput: MakeRequestInput;
  numberPagination: NumberPagination;
  recomputeLegActivitiesQuery: RecomputeLegActivitiesQuery;
  removeDriverCompanyInput: RemoveDriverCompanyInput;
  removeTrailerCompanyInput: RemoveTrailerCompanyInput;
  removeVehicleCompanyInput: RemoveVehicleCompanyInput;
  searchConsolVouchersInput: SearchConsolVouchersInput;
  searchConsolVouchersIssueDateInput: SearchConsolVouchersIssueDateInput;
  searchVouchersInput: SearchVouchersInput;
  transportRateOptions: TransportRateOptions;
  transportRateSelector: TransportRateSelector;
  updateDriverCompanyInput: UpdateDriverCompanyInput;
  updateDriverEmployeeInput: UpdateDriverEmployeeInput;
  updateEntityRequirementCategoryInput: UpdateEntityRequirementCategoryInput;
  updateEntityRequirementsInput: UpdateEntityRequirementsInput;
  updateTrailerCompanyInput: UpdateTrailerCompanyInput;
  updateVehicleCompanyInput: UpdateVehicleCompanyInput;
  voucherItemQueryInput: VoucherItemQueryInput;
};

export type ActionTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ActionTemplate'] = ResolversParentTypes['ActionTemplate']> = {
  __resolveType: TypeResolveFn<'BookingDocumentTemplate' | 'RequestTemplate' | 'VoucherTemplate', ParentType, ContextType>;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  areaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  companyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  countryAlpha3?: Resolver<Maybe<ResolversTypes['LimitedString']>, ParentType, ContextType>;
  district?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Geometry']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  plusCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['AddressStatus']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['AddressTags']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<Array<Maybe<ResolversTypes['AddressType']>>>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  zone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AddressesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Addresses'] = ResolversParentTypes['Addresses']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Address']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApprovalResolvers<ContextType = any, ParentType extends ResolversParentTypes['Approval'] = ResolversParentTypes['Approval']> = {
  approvedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  bookingDocument?: Resolver<Maybe<ResolversTypes['BookingDocument']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  request?: Resolver<Maybe<ResolversTypes['Request']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ApprovalStatus']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  voucher?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ApprovalsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Approvals'] = ResolversParentTypes['Approvals']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Approval']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AssigneeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Assignee'] = ResolversParentTypes['Assignee']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AttachmentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Attachment'] = ResolversParentTypes['Attachment']> = {
  eTag?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AuthResolvers<ContextType = any, ParentType extends ResolversParentTypes['Auth'] = ResolversParentTypes['Auth']> = {
  auth0Token?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  auth0UserId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvailabilitiesResultsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AvailabilitiesResults'] = ResolversParentTypes['AvailabilitiesResults']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Availability']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AvailabilityResolvers<ContextType = any, ParentType extends ResolversParentTypes['Availability'] = ResolversParentTypes['Availability']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  details?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityDetails']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['AvailabilityStatus']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['EntityType']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BcSettingAreaCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BCSettingAreaCode'] = ResolversParentTypes['BCSettingAreaCode']> = {
  value?: Resolver<Maybe<ResolversTypes['BCSettingAreaCodeInputRequirement']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseCompaniesResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseCompanies'] = ResolversParentTypes['BaseCompanies']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['BaseCompany']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseCompany'] = ResolversParentTypes['BaseCompany']> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>;
  currencies?: Resolver<Maybe<Array<Maybe<ResolversTypes['Currency']>>>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['BaseCompanyDetails']>, ParentType, ContextType>;
  employees?: Resolver<Maybe<Array<Maybe<ResolversTypes['Employee']>>>, ParentType, ContextType>;
  entityExceptions?: Resolver<Maybe<ResolversTypes['EntityExceptions']>, ParentType, ContextType>;
  entityRequirements?: Resolver<Maybe<ResolversTypes['EntityRequirements']>, ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<Maybe<ResolversTypes['Group']>>>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<Array<Maybe<ResolversTypes['BaseCompanySetting']>>>, ParentType, ContextType>;
  shortCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['CompanyStatus']>, ParentType, ContextType>;
  subscription?: Resolver<Maybe<ResolversTypes['CompanySubscriptionType']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  types?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompanyType']>>>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseCompanyDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseCompanyDetails'] = ResolversParentTypes['BaseCompanyDetails']> = {
  userTimeout?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseCompanySettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseCompanySetting'] = ResolversParentTypes['BaseCompanySetting']> = {
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  setting?: Resolver<Maybe<Array<Maybe<ResolversTypes['Setting']>>>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BaseCompanySettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BaseCompanySettings'] = ResolversParentTypes['BaseCompanySettings']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['BaseCompanySetting']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingUnitResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingUnit'] = ResolversParentTypes['BillingUnit']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['BillingUnitStatus']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BillingUnitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BillingUnits'] = ResolversParentTypes['BillingUnits']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['BillingUnit']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Booking'] = ResolversParentTypes['Booking']> = {
  addresses?: Resolver<Maybe<Array<Maybe<ResolversTypes['Address']>>>, ParentType, ContextType>;
  assignees?: Resolver<Maybe<Array<Maybe<ResolversTypes['Assignee']>>>, ParentType, ContextType>;
  billTo?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  bookingDocuments?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  chronologies?: Resolver<Maybe<Array<Maybe<ResolversTypes['Chronology']>>>, ParentType, ContextType>;
  commodities?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  costItems?: Resolver<Maybe<Array<Maybe<ResolversTypes['CostItem']>>>, ParentType, ContextType>;
  costSheet?: Resolver<Maybe<ResolversTypes['CostSheet']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  estDrop?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  estPick?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  events?: Resolver<Maybe<Array<Maybe<ResolversTypes['Event']>>>, ParentType, ContextType>;
  isCloseFinance?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isCloseOperation?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  jobUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Job']>>>, ParentType, ContextType>;
  no?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['BaseCompany']>, ParentType, ContextType>;
  permissions?: Resolver<Maybe<ResolversTypes['BookingPermissionType']>, ParentType, ContextType>;
  places?: Resolver<Maybe<Array<Maybe<ResolversTypes['Place']>>>, ParentType, ContextType>;
  ports?: Resolver<Maybe<Array<Maybe<ResolversTypes['Port']>>>, ParentType, ContextType>;
  processFlow?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProcessFlow']>>>, ParentType, ContextType>;
  quotation?: Resolver<Maybe<ResolversTypes['Quotation']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requests?: Resolver<Maybe<Array<Maybe<ResolversTypes['Request']>>>, ParentType, ContextType>;
  serviceTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  shortId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingState']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['BookingStatus']>, ParentType, ContextType>;
  statusUpdatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  tripUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vouchers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Voucher']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingDocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingDocument'] = ResolversParentTypes['BookingDocument']> = {
  approvals?: Resolver<Maybe<Array<Maybe<ResolversTypes['Approval']>>>, ParentType, ContextType>;
  bookings?: Resolver<Maybe<Array<Maybe<ResolversTypes['Booking']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  document?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType>;
  file?: Resolver<Maybe<ResolversTypes['Document']>, ParentType, ContextType>;
  hardCopyStatus?: Resolver<Maybe<ResolversTypes['DocumentHardCopyStatus']>, ParentType, ContextType>;
  jobUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DocumentStatus']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  tripUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  verifiedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingDocumentTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingDocumentTemplate'] = ResolversParentTypes['BookingDocumentTemplate']> = {
  approvals?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingDocumentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingDocuments'] = ResolversParentTypes['BookingDocuments']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingEvent'] = ResolversParentTypes['BookingEvent']> = {
  context?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['EventCreator']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingEventPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingEventPayload'] = ResolversParentTypes['BookingEventPayload']> = {
  companyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  forwarderUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  haulierUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  shipperUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingFieldValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingFieldValue'] = ResolversParentTypes['BookingFieldValue']> = {
  value?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingPermissionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingPermissionType'] = ResolversParentTypes['BookingPermissionType']> = {
  canImpLinerOverrideDoubleInvoice?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  canImpLinerOverrideDoubleVoucher?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  canImpLinerOverrideIncompleteDocs?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingStateResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingState'] = ResolversParentTypes['BookingState']> = {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingSummary'] = ResolversParentTypes['BookingSummary']> = {
  pendingCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingType'] = ResolversParentTypes['BookingType']> = {
  billing?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingTypeBilling']>>>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dynamicFields?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingTypeDynamicField']>>>, ParentType, ContextType>;
  flowType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sorting?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['BookingTypeStatus']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingTypeBillingResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingTypeBilling'] = ResolversParentTypes['BookingTypeBilling']> = {
  billManner?: Resolver<Maybe<ResolversTypes['BillingManner']>, ParentType, ContextType>;
  chargeItemUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  costRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costRateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  desc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  incentiveTreeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sellRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sellRateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingTypeDynamicFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingTypeDynamicField'] = ResolversParentTypes['BookingTypeDynamicField']> = {
  chronologyLevel?: Resolver<Maybe<ResolversTypes['BookingTypeDynamicFieldChronologyLevel']>, ParentType, ContextType>;
  companyTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompanyType']>>>, ParentType, ContextType>;
  control?: Resolver<Maybe<ResolversTypes['BookingTypeDynamicFieldControl']>, ParentType, ContextType>;
  customValues?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  enumName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isMultiline?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  multiple?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  query?: Resolver<Maybe<ResolversTypes['BookingDynamicFieldQuery']>, ParentType, ContextType>;
  searchable?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['BookingDynamicFieldType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingTypes'] = ResolversParentTypes['BookingTypes']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingType']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Bookings'] = ResolversParentTypes['Bookings']> = {
  facets?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Booking']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BookingsJsonResolvers<ContextType = any, ParentType extends ResolversParentTypes['BookingsJson'] = ResolversParentTypes['BookingsJson']> = {
  facets?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BulkImportFunctionResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkImportFunction'] = ResolversParentTypes['BulkImportFunction']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  options?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['BulkImportFunctionStatus']>, ParentType, ContextType>;
  url?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type BulkImportFunctionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['BulkImportFunctions'] = ResolversParentTypes['BulkImportFunctions']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['BulkImportFunction']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelResolvers<ContextType = any, ParentType extends ResolversParentTypes['Channel'] = ResolversParentTypes['Channel']> = {
  receiver?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  sender?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChannelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Channels'] = ResolversParentTypes['Channels']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Channel']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeCategoriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeCategories'] = ResolversParentTypes['ChargeCategories']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChargeCategory']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeCategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeCategory'] = ResolversParentTypes['ChargeCategory']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sequence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ChargeCategoryType']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeItem'] = ResolversParentTypes['ChargeItem']> = {
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChargeCategory']>>>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  codes?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChargeItemCode']>>>, ParentType, ContextType>;
  costCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  costRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costRateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  costTax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expenseCode?: Resolver<Maybe<ResolversTypes['GlCode']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rateType?: Resolver<Maybe<ResolversTypes['ChargeRateType']>, ParentType, ContextType>;
  revenueCode?: Resolver<Maybe<ResolversTypes['GlCode']>, ParentType, ContextType>;
  sellCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  sellRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sellRateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sellTax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType>;
  sequence?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ChargeItemStatus']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChargeItemTagType']>>>, ParentType, ContextType>;
  taxes?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChargeItemTax']>>>, ParentType, ContextType>;
  term?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeItemCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeItemCode'] = ResolversParentTypes['ChargeItemCode']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  glCode?: Resolver<Maybe<ResolversTypes['GlCode']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ChargeItemCodeType']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeItemMiscResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeItemMisc'] = ResolversParentTypes['ChargeItemMisc']> = {
  chargeItem?: Resolver<Maybe<ResolversTypes['ChargeItem']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  documentUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  endDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  startDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeItemMiscsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeItemMiscs'] = ResolversParentTypes['ChargeItemMiscs']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChargeItemMisc']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeItemTaxResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeItemTax'] = ResolversParentTypes['ChargeItemTax']> = {
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rateType?: Resolver<Maybe<ResolversTypes['ChargeRateType']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ChargeItemTaxType']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChargeItemsResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChargeItems'] = ResolversParentTypes['ChargeItems']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChargeItem']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChildNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChildNode'] = ResolversParentTypes['ChildNode']> = {
  _id?: Resolver<Maybe<ResolversTypes['ObjectID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isExit?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ActiveStatus']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['RateValue']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChildNodesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChildNodes'] = ResolversParentTypes['ChildNodes']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChildNode']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChildSelectorResolvers<ContextType = any, ParentType extends ResolversParentTypes['ChildSelector'] = ResolversParentTypes['ChildSelector']> = {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  query?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ChronologyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Chronology'] = ResolversParentTypes['Chronology']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  jobUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tripUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommodityResolvers<ContextType = any, ParentType extends ResolversParentTypes['Commodity'] = ResolversParentTypes['Commodity']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommodityTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommodityType'] = ResolversParentTypes['CommodityType']> = {
  path?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CommodityTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CommodityTypes'] = ResolversParentTypes['CommodityTypes']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['CommodityType']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompaniesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Companies'] = ResolversParentTypes['Companies']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Company'] = ResolversParentTypes['Company']> = {
  accountUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  addresses?: Resolver<Maybe<Array<Maybe<ResolversTypes['Address']>>>, ParentType, ContextType>;
  billTo?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  contacts?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contact']>>>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>;
  countryAlpha3?: Resolver<Maybe<ResolversTypes['LimitedString']>, ParentType, ContextType>;
  creditorCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  creditorTerm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  debtorCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  debtorTerm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employees?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  forwarders?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  integrationDetail?: Resolver<Maybe<ResolversTypes['IntegrationDetail']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  registration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['CompanyStatus']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  taxNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  types?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompanyType']>>>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedByUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyAccess'] = ResolversParentTypes['CompanyAccess']> = {
  externalBaseCompany?: Resolver<Maybe<ResolversTypes['BaseCompany']>, ParentType, ContextType>;
  externalCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  internalCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['CompanyAccessStatus']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['CompanyAccessType']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanyAccessesResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanyAccesses'] = ResolversParentTypes['CompanyAccesses']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompanyAccess']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CompanySearchResolvers<ContextType = any, ParentType extends ResolversParentTypes['CompanySearch'] = ResolversParentTypes['CompanySearch']> = {
  exhaustiveNbHits?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  hits?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  hitsPerPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nbHits?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  nbPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  processingTimeMS?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  query?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComputedRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ComputedRate'] = ResolversParentTypes['ComputedRate']> = {
  chargeItemCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  log?: Resolver<Maybe<Array<Maybe<ResolversTypes['RateLogItem']>>>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ComputedRateResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['ComputedRateResult'] = ResolversParentTypes['ComputedRateResult']> = {
  bookingUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  computeBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  computedRate?: Resolver<Maybe<Array<Maybe<ResolversTypes['ComputedRate']>>>, ParentType, ContextType>;
  jobUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tripUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsolVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsolVoucher'] = ResolversParentTypes['ConsolVoucher']> = {
  issueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['BaseCompany']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ConsolVoucherStatus']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['ConsolVoucherType']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vouchers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Voucher']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ConsolVouchersResolvers<ContextType = any, ParentType extends ResolversParentTypes['ConsolVouchers'] = ResolversParentTypes['ConsolVouchers']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['ConsolVoucher']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contact'] = ResolversParentTypes['Contact']> = {
  designation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ContactStatus']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactSimpleResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactSimple'] = ResolversParentTypes['ContactSimple']> = {
  number?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  type?: Resolver<ResolversTypes['ContactSimpleType'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contacts'] = ResolversParentTypes['Contacts']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Contact']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContainerEvent'] = ResolversParentTypes['ContainerEvent']> = {
  context?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerGradeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContainerGrade'] = ResolversParentTypes['ContainerGrade']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sorting?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerGradesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContainerGrades'] = ResolversParentTypes['ContainerGrades']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContainerGrade']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContainerType'] = ResolversParentTypes['ContainerType']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sorting?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContainerTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContainerTypes'] = ResolversParentTypes['ContainerTypes']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContainerType']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CostItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['CostItem'] = ResolversParentTypes['CostItem']> = {
  accountPayable?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  accountPayableDraft?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  accountReceivable?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  accountReceivableDraft?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  accrual?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  blankCheque?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bookingUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  cashBook?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  chargeItem?: Resolver<Maybe<ResolversTypes['ChargeItem']>, ParentType, ContextType>;
  costBaseRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  costExchangeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  editBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  errors?: Resolver<Maybe<Array<Maybe<ResolversTypes['costItemErrorType']>>>, ParentType, ContextType>;
  estimatedProfit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  grossProfit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  isDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sellBaseRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sellCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  sellExchangeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sellRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sellTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shortBill?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CostSheetResolvers<ContextType = any, ParentType extends ResolversParentTypes['CostSheet'] = ResolversParentTypes['CostSheet']> = {
  accountPayable?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  accountPayableDraft?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  accountReceivable?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  accountReceivableDraft?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  accrual?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  estimatedProfit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  grossProfit?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sellTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shortBill?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  shortBillPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CounterResolvers<ContextType = any, ParentType extends ResolversParentTypes['Counter'] = ResolversParentTypes['Counter']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CounterResultsResolvers<ContextType = any, ParentType extends ResolversParentTypes['CounterResults'] = ResolversParentTypes['CounterResults']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Counter']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Countries'] = ResolversParentTypes['Countries']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Country']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CountryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Country'] = ResolversParentTypes['Country']> = {
  alpha2?: Resolver<Maybe<ResolversTypes['LimitedString2']>, ParentType, ContextType>;
  alpha3?: Resolver<Maybe<ResolversTypes['LimitedString']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ports?: Resolver<Maybe<Array<Maybe<ResolversTypes['Port']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CountryAlpha2ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['CountryAlpha2'], any> {
  name: 'CountryAlpha2';
}

export interface CountryAlpha3ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['CountryAlpha3'], any> {
  name: 'CountryAlpha3';
}

export type CurrenciesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Currencies'] = ResolversParentTypes['Currencies']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Currency']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CurrencyResolvers<ContextType = any, ParentType extends ResolversParentTypes['Currency'] = ResolversParentTypes['Currency']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DataDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DataDetails'] = ResolversParentTypes['DataDetails']> = {
  billToCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  customerRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerRef2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deliveryAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  deliveryHour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  dropoffAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  dropoffCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  haulier?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  jobs?: Resolver<Maybe<Array<Maybe<ResolversTypes['Job']>>>, ParentType, ContextType>;
  options?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  pickupAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  pickupCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requestNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requiredDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  submittedDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface DateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Date'], any> {
  name: 'Date';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DocumentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Document'] = ResolversParentTypes['Document']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentCreatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentCreator'] = ResolversParentTypes['DocumentCreator']> = {
  bookingUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['DataDetails']>, ParentType, ContextType>;
  dataJSON?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  dataJSONObject?: Resolver<Maybe<ResolversTypes['JSONObject']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DocumentCreatorStatus']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentCreatorReferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentCreatorReference'] = ResolversParentTypes['DocumentCreatorReference']> = {
  no?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentCreatorTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentCreatorTemplate'] = ResolversParentTypes['DocumentCreatorTemplate']> = {
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DocumentCreatorTemplateStatus']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentCreatorTemplatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentCreatorTemplates'] = ResolversParentTypes['DocumentCreatorTemplates']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentCreatorTemplate']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentCreatorsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentCreators'] = ResolversParentTypes['DocumentCreators']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentCreator']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DocumentDescriptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['DocumentDescription'] = ResolversParentTypes['DocumentDescription']> = {
  attachment?: Resolver<Maybe<ResolversTypes['Attachment']>, ParentType, ContextType>;
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  entityType?: Resolver<Maybe<ResolversTypes['EntityType']>, ParentType, ContextType>;
  entityUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityField']>>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DocumentStatus']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DriverResolvers<ContextType = any, ParentType extends ResolversParentTypes['Driver'] = ResolversParentTypes['Driver']> = {
  address?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityAddress']>>>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityCompany']>>>, ParentType, ContextType>;
  contact?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContactSimple']>>>, ParentType, ContextType>;
  countryAlpha3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerAddress?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityCompanyAddress']>>>, ParentType, ContextType>;
  customers?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityCompany']>>>, ParentType, ContextType>;
  department?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentDescription']>>>, ParentType, ContextType>;
  editBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  editDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employeeUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  entityException?: Resolver<Maybe<ResolversTypes['EntityExceptionOutcome']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['Gender']>, ParentType, ContextType>;
  governmentId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  healthAllergies?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  healthBloodtype?: Resolver<Maybe<ResolversTypes['Bloodtype']>, ParentType, ContextType>;
  healthIssues?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Attachment']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextOfKinAddress?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityAddress']>>>, ParentType, ContextType>;
  nextOfKinContact?: Resolver<Maybe<Array<Maybe<ResolversTypes['ContactSimple']>>>, ParentType, ContextType>;
  nextOfKinName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nextOfKinRelationship?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nickName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerType?: Resolver<Maybe<ResolversTypes['DriverOwnerType']>, ParentType, ContextType>;
  preferredCurrency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['DriverStatus']>, ParentType, ContextType>;
  subContractors?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityCompany']>>>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  taxNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['DriverType']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vehiclePreference?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DriverBehaviorAuthResolvers<ContextType = any, ParentType extends ResolversParentTypes['DriverBehaviorAuth'] = ResolversParentTypes['DriverBehaviorAuth']> = {
  jwt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DriverUtilDeptResolvers<ContextType = any, ParentType extends ResolversParentTypes['DriverUtilDept'] = ResolversParentTypes['DriverUtilDept']> = {
  c?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  dept?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tot?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DriversResultsResolvers<ContextType = any, ParentType extends ResolversParentTypes['DriversResults'] = ResolversParentTypes['DriversResults']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Driver']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface EmailScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Email'], any> {
  name: 'Email';
}

export type EmailRequestFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFields'] = ResolversParentTypes['EmailRequestFields']> = {
  __resolveType: TypeResolveFn<'EmailRequestFieldsBookingConfirmationFromLiner' | 'EmailRequestFieldsBookingConfirmationReeferFromLiner' | 'EmailRequestFieldsConsigneeDraftNotification' | 'EmailRequestFieldsEdo' | 'EmailRequestFieldsExportDocs' | 'EmailRequestFieldsHaulageRFC' | 'EmailRequestFieldsHaulageRot' | 'EmailRequestFieldsImportDocs' | 'EmailRequestFieldsLinerBookingConfirmationCustomerRequest' | 'EmailRequestFieldsLinerContainerMovementOrder' | 'EmailRequestFieldsLinerNotifyCustomerComplete' | 'EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill' | 'EmailRequestFieldsNoa', ParentType, ContextType>;
};

export type EmailRequestFieldsBookingConfirmationFromLinerResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsBookingConfirmationFromLiner'] = ResolversParentTypes['EmailRequestFieldsBookingConfirmationFromLiner']> = {
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  etaTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  etdFrom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  origin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipper?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vessel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  volume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsBookingConfirmationReeferFromLinerResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsBookingConfirmationReeferFromLiner'] = ResolversParentTypes['EmailRequestFieldsBookingConfirmationReeferFromLiner']> = {
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containerWeights?: Resolver<Maybe<Array<Maybe<ResolversTypes['JSON']>>>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  destination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  etaTo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  etdFrom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  origin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipper?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  temperatureSummary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vessel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  volume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsConsigneeDraftNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsConsigneeDraftNotification'] = ResolversParentTypes['EmailRequestFieldsConsigneeDraftNotification']> = {
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsEdoResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsEdo'] = ResolversParentTypes['EmailRequestFieldsEdo']> = {
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsExportDocsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsExportDocs'] = ResolversParentTypes['EmailRequestFieldsExportDocs']> = {
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsHaulageRfcResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsHaulageRFC'] = ResolversParentTypes['EmailRequestFieldsHaulageRFC']> = {
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  closingDay?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deliveryHour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dropOff?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  eta?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  etd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  jobs?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  lastDay?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  operatorCode?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referenceNo?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requiredDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  scn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsHaulageRotResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsHaulageRot'] = ResolversParentTypes['EmailRequestFieldsHaulageRot']> = {
  billingCustomer?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  closingDay?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  delivery?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  deliveryHour?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dischargePort?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  dropOff?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  eta?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  etd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  forwardingAgent?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  isBackToBack?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isCustomsSeal?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isDirectDelivery?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isDirectLoading?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  jobs?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  lastDay?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  manifestNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operatorCode?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  pickup?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  referenceNo?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requiredDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  scn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipperConsignee?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  shippingAgent?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vesselName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voyageNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsImportDocsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsImportDocs'] = ResolversParentTypes['EmailRequestFieldsImportDocs']> = {
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsLinerBookingConfirmationCustomerRequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsLinerBookingConfirmationCustomerRequest'] = ResolversParentTypes['EmailRequestFieldsLinerBookingConfirmationCustomerRequest']> = {
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipper?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsLinerContainerMovementOrderResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsLinerContainerMovementOrder'] = ResolversParentTypes['EmailRequestFieldsLinerContainerMovementOrder']> = {
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipper?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsLinerNotifyCustomerCompleteResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsLinerNotifyCustomerComplete'] = ResolversParentTypes['EmailRequestFieldsLinerNotifyCustomerComplete']> = {
  airwayBillNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  etaDestination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  etdPkg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipper?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vessel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  volume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybillResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill'] = ResolversParentTypes['EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill']> = {
  airwayBillNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  commodity?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customerRef?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  etaDestination?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  etdPkg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipper?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vessel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  volume?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsNoaResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsNoa'] = ResolversParentTypes['EmailRequestFieldsNoa']> = {
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmailRequestFieldsPaymentNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmailRequestFieldsPaymentNotification'] = ResolversParentTypes['EmailRequestFieldsPaymentNotification']> = {
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Employee'] = ResolversParentTypes['Employee']> = {
  baseCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  driver?: Resolver<Maybe<ResolversTypes['EmployeeDriver']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeeDriverResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmployeeDriver'] = ResolversParentTypes['EmployeeDriver']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  employeeUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nickName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmployeesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Employees'] = ResolversParentTypes['Employees']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Employee']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmptyTripResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmptyTrip'] = ResolversParentTypes['EmptyTrip']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  billToCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  billToUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  bookingUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  consigneeAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  consigneeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consigneeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consigneeRequiredDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  consigneeUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdByUUID?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  dFirstTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dLastTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  driverCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverEntity?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType>;
  driverName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  endOut?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstTs?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  jobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  lastTs?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  legUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  planEnd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  planStart?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  proposed?: Resolver<Maybe<ResolversTypes['EmptyTripProposed']>, ParentType, ContextType>;
  reason?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipOutDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  shipperAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  shipperCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipperName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipperRequiredDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  shipperUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  startOut?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['EmptyTripStatus']>, ParentType, ContextType>;
  tLastTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  trailerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerEntity?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType>;
  trailerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  transportSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  tripUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedByUUID?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vFirstTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vLastTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleDepartments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  vehicleEntity?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>;
  vehicleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmptyTripProposedResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmptyTripProposed'] = ResolversParentTypes['EmptyTripProposed']> = {
  from?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['EmptyTripStatus']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EmptyTripsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EmptyTrips'] = ResolversParentTypes['EmptyTrips']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['EmptyTrip']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityAddress'] = ResolversParentTypes['EntityAddress']> = {
  address1?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address3?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  address4?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  areaCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryAlpha3?: Resolver<Maybe<ResolversTypes['LimitedString']>, ParentType, ContextType>;
  district?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fax?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Geometry']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['AddressStatus']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['AddressTags']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<Array<Maybe<ResolversTypes['AddressType']>>>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityCategoryRequirementResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityCategoryRequirement'] = ResolversParentTypes['EntityCategoryRequirement']> = {
  allowedCategories?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  documentRequirements?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityDocumentRequirement']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityCompany'] = ResolversParentTypes['EntityCompany']> = {
  companyReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyType?: Resolver<Maybe<ResolversTypes['EntityCompanyType']>, ParentType, ContextType>;
  companyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityCompanyAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityCompanyAddress'] = ResolversParentTypes['EntityCompanyAddress']> = {
  addressUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  companyReference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityDetails'] = ResolversParentTypes['EntityDetails']> = {
  driver?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType>;
  editBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  editByDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  editByUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  entityUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailer?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType>;
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityDocumentRequirementResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityDocumentRequirement'] = ResolversParentTypes['EntityDocumentRequirement']> = {
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityFieldReq']>>>, ParentType, ContextType>;
  isRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityEvent'] = ResolversParentTypes['EntityEvent']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityExceptionOutcomeResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityExceptionOutcome'] = ResolversParentTypes['EntityExceptionOutcome']> = {
  entityUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  exceptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityExceptionsItem']>>>, ParentType, ContextType>;
  remark?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['EntityExceptionOutcomeStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityExceptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityExceptions'] = ResolversParentTypes['EntityExceptions']> = {
  DRIVER?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityExceptionOutcome']>>>, ParentType, ContextType>;
  TRAILER?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityExceptionOutcome']>>>, ParentType, ContextType>;
  VEHICLE?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityExceptionOutcome']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityExceptionsItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityExceptionsItem'] = ResolversParentTypes['EntityExceptionsItem']> = {
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  entityType?: Resolver<Maybe<ResolversTypes['EntityType']>, ParentType, ContextType>;
  entityUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  error?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['EntityExceptionOutcomeStatus']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityField'] = ResolversParentTypes['EntityField']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['EntityFieldType']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityFieldReqResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityFieldReq'] = ResolversParentTypes['EntityFieldReq']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  selectOptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['EntityFieldType']>, ParentType, ContextType>;
  validOptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityRequirementsResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityRequirements'] = ResolversParentTypes['EntityRequirements']> = {
  DRIVER?: Resolver<Maybe<ResolversTypes['EntityCategoryRequirement']>, ParentType, ContextType>;
  TRAILER?: Resolver<Maybe<ResolversTypes['EntityCategoryRequirement']>, ParentType, ContextType>;
  VEHICLE?: Resolver<Maybe<ResolversTypes['EntityCategoryRequirement']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EntityTypeUnionResolvers<ContextType = any, ParentType extends ResolversParentTypes['EntityTypeUnion'] = ResolversParentTypes['EntityTypeUnion']> = {
  __resolveType: TypeResolveFn<'Driver' | 'Trailer' | 'Vehicle', ParentType, ContextType>;
};

export type EventResolvers<ContextType = any, ParentType extends ResolversParentTypes['Event'] = ResolversParentTypes['Event']> = {
  __resolveType: TypeResolveFn<'BookingEvent' | 'ContainerEvent' | 'MessageEvent', ParentType, ContextType>;
};

export type EventCreatorResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventCreator'] = ResolversParentTypes['EventCreator']> = {
  email?: Resolver<Maybe<ResolversTypes['Email']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventEtcResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventEtc'] = ResolversParentTypes['EventEtc']> = {
  action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventVoucher'] = ResolversParentTypes['EventVoucher']> = {
  action?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  context?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventVouchersResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventVouchers'] = ResolversParentTypes['EventVouchers']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['EventVoucher']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type EventsEtcResolvers<ContextType = any, ParentType extends ResolversParentTypes['EventsEtc'] = ResolversParentTypes['EventsEtc']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['EventEtc']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExchangeRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExchangeRate'] = ResolversParentTypes['ExchangeRate']> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ExchangeRatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ExchangeRates'] = ResolversParentTypes['ExchangeRates']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['ExchangeRate']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FafResolvers<ContextType = any, ParentType extends ResolversParentTypes['Faf'] = ResolversParentTypes['Faf']> = {
  customer?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  customerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vendor?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  vendorUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FafsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Fafs'] = ResolversParentTypes['Fafs']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Faf']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FmsCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['FmsCompany'] = ResolversParentTypes['FmsCompany']> = {
  BranchCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CompanyCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerAccountCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerDebtorCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  CustomerType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  IsSuspend?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  Remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FuelResolvers<ContextType = any, ParentType extends ResolversParentTypes['Fuel'] = ResolversParentTypes['Fuel']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  amountRemaining?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  approvedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  approvedUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  driver?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType>;
  driverUuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  odometer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  ref?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['FuelStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>;
  vehicleUuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  vendor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type FuelsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Fuels'] = ResolversParentTypes['Fuels']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Fuel']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GateLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['GateLog'] = ResolversParentTypes['GateLog']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  driver?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType>;
  driverUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  odometer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['GateLogStatus']>, ParentType, ContextType>;
  trailer?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType>;
  trailerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['GateLogType']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>;
  vehicleUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  yard?: Resolver<Maybe<ResolversTypes['Yard']>, ParentType, ContextType>;
  yardUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GateLogsResolvers<ContextType = any, ParentType extends ResolversParentTypes['GateLogs'] = ResolversParentTypes['GateLogs']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['GateLog']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeocodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['Geocode'] = ResolversParentTypes['Geocode']> = {
  address?: Resolver<Maybe<ResolversTypes['ReverseGeoAddress']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resultType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  title?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeocodesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Geocodes'] = ResolversParentTypes['Geocodes']> = {
  items?: Resolver<Maybe<Array<Maybe<ResolversTypes['Geocode']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeometryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Geometry'] = ResolversParentTypes['Geometry']> = {
  coordinates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeometryPointResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeometryPoint'] = ResolversParentTypes['GeometryPoint']> = {
  lat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lng?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GeometryPolygonResolvers<ContextType = any, ParentType extends ResolversParentTypes['GeometryPolygon'] = ResolversParentTypes['GeometryPolygon']> = {
  coordinates?: Resolver<Maybe<Array<Maybe<Array<Maybe<ResolversTypes['Float']>>>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GlCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlCode'] = ResolversParentTypes['GlCode']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['GlCodeStatus']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GlCodesResolvers<ContextType = any, ParentType extends ResolversParentTypes['GlCodes'] = ResolversParentTypes['GlCodes']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['GlCode']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GoogleGeometryResolvers<ContextType = any, ParentType extends ResolversParentTypes['GoogleGeometry'] = ResolversParentTypes['GoogleGeometry']> = {
  location?: Resolver<Maybe<ResolversTypes['GeometryPoint']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupResolvers<ContextType = any, ParentType extends ResolversParentTypes['Group'] = ResolversParentTypes['Group']> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  employees?: Resolver<Maybe<Array<Maybe<ResolversTypes['Employee']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ruleCondition?: Resolver<Maybe<ResolversTypes['RuleCondition']>, ParentType, ContextType>;
  rules?: Resolver<Maybe<Array<Maybe<ResolversTypes['GroupRule']>>>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupRuleResolvers<ContextType = any, ParentType extends ResolversParentTypes['GroupRule'] = ResolversParentTypes['GroupRule']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['GroupRuleType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type GroupsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Groups'] = ResolversParentTypes['Groups']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Group']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ImpCustConsigneeDraftNotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ImpCustConsigneeDraftNotification'] = ResolversParentTypes['ImpCustConsigneeDraftNotification']> = {
  bl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  containers?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  receiverGreeting?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  subject?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncentiveResolvers<ContextType = any, ParentType extends ResolversParentTypes['Incentive'] = ResolversParentTypes['Incentive']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bookingUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  costCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  driver?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType>;
  driverCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  incentiveVoucher?: Resolver<Maybe<ResolversTypes['IncentiveVoucher']>, ParentType, ContextType>;
  incentiveVoucherUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  jobUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  legUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shift?: Resolver<Maybe<ResolversTypes['Shift']>, ParentType, ContextType>;
  shiftUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['IncentiveStatus']>, ParentType, ContextType>;
  transportJob?: Resolver<Maybe<ResolversTypes['TransportJob']>, ParentType, ContextType>;
  tripUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['IncentiveType']>, ParentType, ContextType>;
  typeUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>;
  vehicleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  voucher?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType>;
  voucherUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncentiveCategorySumResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncentiveCategorySum'] = ResolversParentTypes['IncentiveCategorySum']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncentiveRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncentiveRate'] = ResolversParentTypes['IncentiveRate']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  rates?: Resolver<Maybe<Array<Maybe<ResolversTypes['IncentiveRateDetails']>>>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transportType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncentiveRateDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncentiveRateDetails'] = ResolversParentTypes['IncentiveRateDetails']> = {
  cargoType?: Resolver<Maybe<ResolversTypes['TransportRateCargoTypes']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  customerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rateType?: Resolver<Maybe<ResolversTypes['ChargeRateType']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transactionType?: Resolver<Maybe<ResolversTypes['TransportRateTransactionType']>, ParentType, ContextType>;
  transportType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncentiveRatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncentiveRates'] = ResolversParentTypes['IncentiveRates']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['IncentiveRate']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncentiveTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncentiveType'] = ResolversParentTypes['IncentiveType']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  glCode?: Resolver<Maybe<ResolversTypes['GlCode']>, ParentType, ContextType>;
  glCodeUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  isJobRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncentiveTypeSumResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncentiveTypeSum'] = ResolversParentTypes['IncentiveTypeSum']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  typeUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncentiveTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncentiveTypes'] = ResolversParentTypes['IncentiveTypes']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['IncentiveType']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncentiveVoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncentiveVoucher'] = ResolversParentTypes['IncentiveVoucher']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  approvedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  approvedFinanceAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  approvedFinanceBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  approvedFinanceUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  approvedUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  bookingUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  driverUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  drivers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Driver']>>>, ParentType, ContextType>;
  incentiveCategorySum?: Resolver<Maybe<Array<Maybe<ResolversTypes['IncentiveCategorySum']>>>, ParentType, ContextType>;
  incentiveTypeSum?: Resolver<Maybe<Array<Maybe<ResolversTypes['IncentiveTypeSum']>>>, ParentType, ContextType>;
  incentiveTypeUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  incentiveUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  incentives?: Resolver<Maybe<Array<Maybe<ResolversTypes['Incentive']>>>, ParentType, ContextType>;
  jobUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  legUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['IncentiveVoucherStatus']>, ParentType, ContextType>;
  tripUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vehicleUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  vehicles?: Resolver<Maybe<Array<Maybe<ResolversTypes['Vehicle']>>>, ParentType, ContextType>;
  voucherNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncentiveVouchersResolvers<ContextType = any, ParentType extends ResolversParentTypes['IncentiveVouchers'] = ResolversParentTypes['IncentiveVouchers']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['IncentiveVoucher']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IncentivesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Incentives'] = ResolversParentTypes['Incentives']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Incentive']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Integration'] = ResolversParentTypes['Integration']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationDetailResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationDetail'] = ResolversParentTypes['IntegrationDetail']> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  integration?: Resolver<Maybe<ResolversTypes['Integration']>, ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationDetails'] = ResolversParentTypes['IntegrationDetails']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['IntegrationDetail']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationLogResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationLog'] = ResolversParentTypes['IntegrationLog']> = {
  baseCompany?: Resolver<Maybe<ResolversTypes['BaseCompany']>, ParentType, ContextType>;
  bookingUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  costItemUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  integrationDetail?: Resolver<Maybe<ResolversTypes['IntegrationDetail']>, ParentType, ContextType>;
  receiveDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receiveType?: Resolver<Maybe<ResolversTypes['IntegrationLogDetailsType']>, ParentType, ContextType>;
  sendDetails?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sendType?: Resolver<Maybe<ResolversTypes['IntegrationLogDetailsType']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  voucherUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationLogsResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationLogs'] = ResolversParentTypes['IntegrationLogs']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['IntegrationLog']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationLogsQueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationLogsQuery'] = ResolversParentTypes['IntegrationLogsQuery']> = {
  bookingUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  costItemUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  voucherUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationMappingResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationMapping'] = ResolversParentTypes['IntegrationMapping']> = {
  customer?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  externalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  integrationDetailUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  internalCode?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationMappingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['IntegrationMappings'] = ResolversParentTypes['IntegrationMappings']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['IntegrationMapping']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type IntegrationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Integrations'] = ResolversParentTypes['Integrations']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Integration']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteCompanyAccessResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteCompanyAccess'] = ResolversParentTypes['InviteCompanyAccess']> = {
  acceptedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  acceptedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  companyUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  emails?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteCompanyAccessesResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteCompanyAccesses'] = ResolversParentTypes['InviteCompanyAccesses']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['InviteCompanyAccess']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InviteUserResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['InviteUserResponse'] = ResolversParentTypes['InviteUserResponse']> = {
  email?: Resolver<Maybe<ResolversTypes['Email']>, ParentType, ContextType>;
  errorMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitedBaseCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitedBaseCompany'] = ResolversParentTypes['InvitedBaseCompany']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  inviteUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}

export interface JsonObjectScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSONObject'], any> {
  name: 'JSONObject';
}

export type JobResolvers<ContextType = any, ParentType extends ResolversParentTypes['Job'] = ResolversParentTypes['Job']> = {
  bookingUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  expReadyForCollectionDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expRequestOfTransportDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  impReadyForCollectionDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  impRequestOfTransportDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  jobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  no?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['JobReferences']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['JobState']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['JobStatus']>, ParentType, ContextType>;
  tripFormat?: Resolver<Maybe<ResolversTypes['TripFormat']>, ParentType, ContextType>;
  trips?: Resolver<Maybe<Array<Maybe<ResolversTypes['Trip']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobOptionResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobOption'] = ResolversParentTypes['JobOption']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sorting?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobOptions'] = ResolversParentTypes['JobOptions']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['JobOption']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobReferencesResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobReferences'] = ResolversParentTypes['JobReferences']> = {
  consignee?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  forwardingDest?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  forwardingOri?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  haulage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipper?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transport?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobSummariesResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobSummaries'] = ResolversParentTypes['JobSummaries']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['JobSummary']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobSummary'] = ResolversParentTypes['JobSummary']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  billToSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dept?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  emptyCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  emptyCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  emptyUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ladenCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ladenUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transportSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobType'] = ResolversParentTypes['JobType']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  computeType?: Resolver<Maybe<ResolversTypes['JobTypeComputeType']>, ParentType, ContextType>;
  defaultTripFormat?: Resolver<Maybe<ResolversTypes['TripFormat']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dynamicFields?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingTypeDynamicField']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requiredFields?: Resolver<Maybe<Array<Maybe<ResolversTypes['JobTypeRequiredFields']>>>, ParentType, ContextType>;
  sorting?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['JobTypeStatus']>, ParentType, ContextType>;
  tripDetails?: Resolver<Maybe<Array<Maybe<ResolversTypes['JobTypeDetails']>>>, ParentType, ContextType>;
  tripDynamicFields?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingTypeDynamicField']>>>, ParentType, ContextType>;
  unitOptions?: Resolver<Maybe<Array<Maybe<ResolversTypes['JobTypeUnitOptions']>>>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobTypeDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobTypeDetails'] = ResolversParentTypes['JobTypeDetails']> = {
  fromCompanyTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompanyType']>>>, ParentType, ContextType>;
  toCompanyTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompanyType']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobTypeRequiredFieldsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobTypeRequiredFields'] = ResolversParentTypes['JobTypeRequiredFields']> = {
  control?: Resolver<Maybe<ResolversTypes['JobTypeRequiredFieldControl']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['JobTypeRequiredFieldTypes']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobTypeUnitOptionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobTypeUnitOptions'] = ResolversParentTypes['JobTypeUnitOptions']> = {
  units?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  values?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobTypes'] = ResolversParentTypes['JobTypes']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['JobType']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Jobs'] = ResolversParentTypes['Jobs']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Job']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type JobsJsonResolvers<ContextType = any, ParentType extends ResolversParentTypes['JobsJson'] = ResolversParentTypes['JobsJson']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LanguageCodeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['LanguageCode'], any> {
  name: 'LanguageCode';
}

export type LegResolvers<ContextType = any, ParentType extends ResolversParentTypes['Leg'] = ResolversParentTypes['Leg']> = {
  activityType?: Resolver<Maybe<ResolversTypes['LegTimeType']>, ParentType, ContextType>;
  assistantUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  assistants?: Resolver<Maybe<Array<Maybe<ResolversTypes['Driver']>>>, ParentType, ContextType>;
  bookingUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  dFirstTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dLastTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverEntity?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType>;
  driverIc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverPhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  endOut?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstTs?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  jobUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  lastTs?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  legStatus?: Resolver<Maybe<ResolversTypes['LegStatus']>, ParentType, ContextType>;
  legUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  planEnd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  planStart?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sequenceTotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  startOut?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  tLastTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerEntity?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType>;
  trailerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  transportName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transportSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  transportUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  tripUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['LegType']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vFirstTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vLastTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleDepartments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  vehicleEntity?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>;
  vehicleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  yard?: Resolver<Maybe<ResolversTypes['Yard']>, ParentType, ContextType>;
  yardUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegActivitiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegActivities'] = ResolversParentTypes['LegActivities']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['LegActivity']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegActivityResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegActivity'] = ResolversParentTypes['LegActivity']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  addAfterSequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  assistantUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  bookingUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  dFirstTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dLastTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverEntity?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType>;
  driverIc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverPhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  endOut?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstTs?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  isDriver?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  jobUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  lastTs?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  legStatus?: Resolver<Maybe<ResolversTypes['LegStatus']>, ParentType, ContextType>;
  legUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  planEnd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  planStart?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sequenceTotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  startOut?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  tLastTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerEntity?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType>;
  trailerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  transportName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transportSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  transportUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  triggerApi?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tripUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['LegType']>, ParentType, ContextType>;
  userUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vFirstTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vLastTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleEntity?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>;
  vehicleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  yard?: Resolver<Maybe<ResolversTypes['Yard']>, ParentType, ContextType>;
  yardUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegSummariesResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegSummaries'] = ResolversParentTypes['LegSummaries']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['LegSummary']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type LegSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['LegSummary'] = ResolversParentTypes['LegSummary']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  billToSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dept?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  emptyCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  emptyCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  emptyLegs?: Resolver<Maybe<ResolversTypes['EmptyTrips']>, ParentType, ContextType>;
  emptyUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ladenCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ladenLegs?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportJob']>>>, ParentType, ContextType>;
  ladenUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transportSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface LimitedStringScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['LimitedString'], any> {
  name: 'LimitedString';
}

export interface LimitedString2ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['LimitedString2'], any> {
  name: 'LimitedString2';
}

export interface LimitedString3ScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['LimitedString3'], any> {
  name: 'LimitedString3';
}

export type LocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Location'] = ResolversParentTypes['Location']> = {
  coordinates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManifestResolvers<ContextType = any, ParentType extends ResolversParentTypes['Manifest'] = ResolversParentTypes['Manifest']> = {
  driver?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType>;
  driverUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  endLocation?: Resolver<Maybe<ResolversTypes['ManifestLocation']>, ParentType, ContextType>;
  endTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  legs?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportJob']>>>, ParentType, ContextType>;
  no?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  startLocation?: Resolver<Maybe<ResolversTypes['ManifestLocation']>, ParentType, ContextType>;
  startTime?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ManifestStatus']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>;
  vehicleUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManifestLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['ManifestLocation'] = ResolversParentTypes['ManifestLocation']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ManifestsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Manifests'] = ResolversParentTypes['Manifests']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Manifest']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageEventResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageEvent'] = ResolversParentTypes['MessageEvent']> = {
  context?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  event?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  time?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['EventCreator']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MessageEventPayloadResolvers<ContextType = any, ParentType extends ResolversParentTypes['MessageEventPayload'] = ResolversParentTypes['MessageEventPayload']> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  _computeReportJobSummary?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<Mutation_ComputeReportJobSummaryArgs>>;
  _emptyTripsDataPatch?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<Mutation_EmptyTripsDataPatchArgs>>;
  _jobsConvertEmptyTrips?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<Mutation_JobsConvertEmptyTripsArgs>>;
  _jobsConvertLegs?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<Mutation_JobsConvertLegsArgs>>;
  _jobsDataPatch?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<Mutation_JobsDataPatchArgs>>;
  _plannerQueueRunner?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<Mutation_PlannerQueueRunnerArgs>>;
  _transportQueueRunner?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<Mutation_TransportQueueRunnerArgs>>;
  _triggerComputeTripSummary?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<Mutation_TriggerComputeTripSummaryArgs>>;
  _triggerJobReports?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<Mutation_TriggerJobReportsArgs>>;
  acceptInvitation?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationAcceptInvitationArgs>>;
  addAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType, RequireFields<MutationAddAddressArgs, 'input'>>;
  addBookingCostItem?: Resolver<Maybe<ResolversTypes['CostItem']>, ParentType, ContextType, RequireFields<MutationAddBookingCostItemArgs, 'input'>>;
  addChargeCategory?: Resolver<Maybe<ResolversTypes['ChargeCategory']>, ParentType, ContextType, RequireFields<MutationAddChargeCategoryArgs, 'code' | 'description' | 'type'>>;
  addChargeItem?: Resolver<Maybe<ResolversTypes['ChargeItem']>, ParentType, ContextType, RequireFields<MutationAddChargeItemArgs, 'input'>>;
  addChargeItemCategory?: Resolver<Maybe<ResolversTypes['ChargeItem']>, ParentType, ContextType, RequireFields<MutationAddChargeItemCategoryArgs, 'chargeCategoryUuid' | 'chargeItemUuid'>>;
  addChargeItemCode?: Resolver<Maybe<ResolversTypes['ChargeItem']>, ParentType, ContextType, RequireFields<MutationAddChargeItemCodeArgs, 'input'>>;
  addChargeItemTax?: Resolver<Maybe<ResolversTypes['ChargeItem']>, ParentType, ContextType, RequireFields<MutationAddChargeItemTaxArgs, 'input'>>;
  addContact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType, RequireFields<MutationAddContactArgs, 'input'>>;
  addCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType, RequireFields<MutationAddCurrencyArgs, 'baseCompanyUuid' | 'currencyUuid'>>;
  addEmployeeToDriver?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationAddEmployeeToDriverArgs, 'uuid'>>;
  addEmptyTrip?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationAddEmptyTripArgs>>;
  addEntityDocument?: Resolver<Maybe<ResolversTypes['DocumentDescription']>, ParentType, ContextType, RequireFields<MutationAddEntityDocumentArgs, 'input'>>;
  addJob?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType, RequireFields<MutationAddJobArgs, 'input'>>;
  addLeg?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationAddLegArgs, 'input'>>;
  addLegsToManifest?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationAddLegsToManifestArgs>>;
  addManifest?: Resolver<Maybe<ResolversTypes['Manifest']>, ParentType, ContextType, Partial<MutationAddManifestArgs>>;
  addMember?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType, RequireFields<MutationAddMemberArgs, 'employeeUuid' | 'uuid'>>;
  addMonitoringCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<MutationAddMonitoringCompanyArgs, 'uuid'>>;
  addPayment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType, RequireFields<MutationAddPaymentArgs, 'input'>>;
  addPushNotification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, Partial<MutationAddPushNotificationArgs>>;
  addSuperAdmin?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType, RequireFields<MutationAddSuperAdminArgs, 'email'>>;
  addTripsToManifest?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationAddTripsToManifestArgs>>;
  addVoucherItem?: Resolver<Maybe<ResolversTypes['VoucherItem']>, ParentType, ContextType, RequireFields<MutationAddVoucherItemArgs, 'input'>>;
  approve?: Resolver<Maybe<ResolversTypes['Approval']>, ParentType, ContextType, RequireFields<MutationApproveArgs, 'input'>>;
  approveBookingDocument?: Resolver<Maybe<ResolversTypes['BookingDocument']>, ParentType, ContextType, RequireFields<MutationApproveBookingDocumentArgs, 'type' | 'uuid'>>;
  approveFuel?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationApproveFuelArgs, 'uuid'>>;
  approveIncentiveVoucher?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationApproveIncentiveVoucherArgs>>;
  approveIncentiveVoucherFinance?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationApproveIncentiveVoucherFinanceArgs>>;
  approveShift?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationApproveShiftArgs>>;
  approveShiftFinance?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationApproveShiftFinanceArgs>>;
  assignPortalCompanyReport?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationAssignPortalCompanyReportArgs>>;
  cancelFuel?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationCancelFuelArgs, 'uuid'>>;
  cancelGateLog?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationCancelGateLogArgs, 'uuid'>>;
  cancelIncentiveVoucher?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationCancelIncentiveVoucherArgs>>;
  cancelShift?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationCancelShiftArgs>>;
  changeBookingCostItemChargeCode?: Resolver<Maybe<ResolversTypes['CostItem']>, ParentType, ContextType, Partial<MutationChangeBookingCostItemChargeCodeArgs>>;
  changeBookingStatus?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<MutationChangeBookingStatusArgs, 'status' | 'uuid'>>;
  changeConsolVoucherStatus?: Resolver<Maybe<ResolversTypes['ConsolVoucher']>, ParentType, ContextType, RequireFields<MutationChangeConsolVoucherStatusArgs, 'status' | 'uuid'>>;
  changeDocumentCreatorStatus?: Resolver<Maybe<ResolversTypes['DocumentCreator']>, ParentType, ContextType, RequireFields<MutationChangeDocumentCreatorStatusArgs, 'status' | 'uuid'>>;
  changeDocumentCreatorStatusAdvanced?: Resolver<Maybe<ResolversTypes['DocumentCreator']>, ParentType, ContextType, RequireFields<MutationChangeDocumentCreatorStatusAdvancedArgs, 'status' | 'uuid'>>;
  changeEmployeeRole?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationChangeEmployeeRoleArgs, 'companyUuid' | 'name' | 'roles'>>;
  changeEmployeeUser?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationChangeEmployeeUserArgs>>;
  changeVoucherStatus?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType, Partial<MutationChangeVoucherStatusArgs>>;
  changeVoucherType?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType, RequireFields<MutationChangeVoucherTypeArgs, 'type' | 'uuid'>>;
  cloneNodeBranch?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationCloneNodeBranchArgs>>;
  closeManifests?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationCloseManifestsArgs>>;
  closeShift?: Resolver<Maybe<ResolversTypes['Shift']>, ParentType, ContextType, RequireFields<MutationCloseShiftArgs, 'input'>>;
  computeCost?: Resolver<Maybe<Array<Maybe<ResolversTypes['CostItem']>>>, ParentType, ContextType, Partial<MutationComputeCostArgs>>;
  computeTripSummary?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationComputeTripSummaryArgs>>;
  createBaseCompany?: Resolver<Maybe<ResolversTypes['BaseCompany']>, ParentType, ContextType, RequireFields<MutationCreateBaseCompanyArgs, 'input'>>;
  createBaseCompanyInviteCompanyAccess?: Resolver<Maybe<ResolversTypes['InvitedBaseCompany']>, ParentType, ContextType, RequireFields<MutationCreateBaseCompanyInviteCompanyAccessArgs, 'inviteUuid' | 'name'>>;
  createBillingUnit?: Resolver<Maybe<ResolversTypes['BillingUnit']>, ParentType, ContextType, Partial<MutationCreateBillingUnitArgs>>;
  createBookingType?: Resolver<Maybe<ResolversTypes['BookingType']>, ParentType, ContextType, Partial<MutationCreateBookingTypeArgs>>;
  createBulkImportFunction?: Resolver<Maybe<ResolversTypes['BulkImportFunction']>, ParentType, ContextType, RequireFields<MutationCreateBulkImportFunctionArgs, 'input'>>;
  createBulkImportFunctionSetting?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationCreateBulkImportFunctionSettingArgs, 'input'>>;
  createChannel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType, RequireFields<MutationCreateChannelArgs, 'input'>>;
  createChargeItemMisc?: Resolver<Maybe<ResolversTypes['ChargeItemMisc']>, ParentType, ContextType, RequireFields<MutationCreateChargeItemMiscArgs, 'input'>>;
  createCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<MutationCreateCompanyArgs, 'input'>>;
  createConsolVoucher?: Resolver<Maybe<ResolversTypes['ConsolVoucher']>, ParentType, ContextType, RequireFields<MutationCreateConsolVoucherArgs, 'input'>>;
  createContainerGrade?: Resolver<Maybe<ResolversTypes['ContainerGrade']>, ParentType, ContextType, RequireFields<MutationCreateContainerGradeArgs, 'input'>>;
  createContainerType?: Resolver<Maybe<ResolversTypes['ContainerType']>, ParentType, ContextType, RequireFields<MutationCreateContainerTypeArgs, 'input'>>;
  createCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType, RequireFields<MutationCreateCurrencyArgs, 'code' | 'name'>>;
  createDocumentCreator?: Resolver<Maybe<ResolversTypes['DocumentCreator']>, ParentType, ContextType, RequireFields<MutationCreateDocumentCreatorArgs, 'input'>>;
  createDocumentCreatorFromTemplate?: Resolver<Maybe<ResolversTypes['DocumentCreator']>, ParentType, ContextType, RequireFields<MutationCreateDocumentCreatorFromTemplateArgs, 'bookingUuid' | 'templateUuid'>>;
  createDocumentCreatorTemplate?: Resolver<Maybe<ResolversTypes['DocumentCreatorTemplate']>, ParentType, ContextType, Partial<MutationCreateDocumentCreatorTemplateArgs>>;
  createDriver?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType, RequireFields<MutationCreateDriverArgs, 'input'>>;
  createExchangeRate?: Resolver<Maybe<ResolversTypes['ExchangeRate']>, ParentType, ContextType, RequireFields<MutationCreateExchangeRateArgs, 'input'>>;
  createFaf?: Resolver<Maybe<ResolversTypes['Faf']>, ParentType, ContextType, RequireFields<MutationCreateFafArgs, 'input'>>;
  createFuel?: Resolver<Maybe<ResolversTypes['Fuel']>, ParentType, ContextType, Partial<MutationCreateFuelArgs>>;
  createGateLog?: Resolver<Maybe<ResolversTypes['GateLog']>, ParentType, ContextType, Partial<MutationCreateGateLogArgs>>;
  createGlCode?: Resolver<Maybe<ResolversTypes['GlCode']>, ParentType, ContextType, RequireFields<MutationCreateGlCodeArgs, 'input'>>;
  createGroup?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType, RequireFields<MutationCreateGroupArgs, 'companyUuid' | 'name'>>;
  createIncentive?: Resolver<Maybe<ResolversTypes['Incentive']>, ParentType, ContextType, Partial<MutationCreateIncentiveArgs>>;
  createIncentiveRate?: Resolver<Maybe<ResolversTypes['IncentiveRate']>, ParentType, ContextType, Partial<MutationCreateIncentiveRateArgs>>;
  createIncentiveType?: Resolver<Maybe<ResolversTypes['IncentiveType']>, ParentType, ContextType, Partial<MutationCreateIncentiveTypeArgs>>;
  createIncentiveVoucher?: Resolver<Maybe<ResolversTypes['IncentiveVoucher']>, ParentType, ContextType, RequireFields<MutationCreateIncentiveVoucherArgs, 'input'>>;
  createIntegrationDetail?: Resolver<Maybe<ResolversTypes['IntegrationDetail']>, ParentType, ContextType, RequireFields<MutationCreateIntegrationDetailArgs, 'input'>>;
  createIntegrationMapping?: Resolver<Maybe<ResolversTypes['IntegrationMapping']>, ParentType, ContextType, RequireFields<MutationCreateIntegrationMappingArgs, 'input'>>;
  createInviteCompanyAccess?: Resolver<Maybe<ResolversTypes['InviteCompanyAccess']>, ParentType, ContextType, RequireFields<MutationCreateInviteCompanyAccessArgs, 'input'>>;
  createJobOption?: Resolver<Maybe<ResolversTypes['JobOption']>, ParentType, ContextType, RequireFields<MutationCreateJobOptionArgs, 'input'>>;
  createJobType?: Resolver<Maybe<ResolversTypes['JobType']>, ParentType, ContextType, Partial<MutationCreateJobTypeArgs>>;
  createPermission?: Resolver<Maybe<Array<Maybe<ResolversTypes['Permission']>>>, ParentType, ContextType, RequireFields<MutationCreatePermissionArgs, 'permission' | 'resource' | 'role'>>;
  createPortalCompany?: Resolver<Maybe<ResolversTypes['PortalCompany']>, ParentType, ContextType, Partial<MutationCreatePortalCompanyArgs>>;
  createQuotation?: Resolver<Maybe<ResolversTypes['Quotation']>, ParentType, ContextType, RequireFields<MutationCreateQuotationArgs, 'input'>>;
  createQuotationItem?: Resolver<Maybe<ResolversTypes['QuotationItem']>, ParentType, ContextType, RequireFields<MutationCreateQuotationItemArgs, 'input'>>;
  createReport?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationCreateReportArgs>>;
  createRole?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType, RequireFields<MutationCreateRoleArgs, 'input'>>;
  createSetting?: Resolver<Maybe<ResolversTypes['BaseCompanySetting']>, ParentType, ContextType, RequireFields<MutationCreateSettingArgs, 'input'>>;
  createTax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType, RequireFields<MutationCreateTaxArgs, 'input'>>;
  createTrailer?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType, RequireFields<MutationCreateTrailerArgs, 'input'>>;
  createTransportAreaCode?: Resolver<Maybe<ResolversTypes['TransportAreaCode']>, ParentType, ContextType, RequireFields<MutationCreateTransportAreaCodeArgs, 'input'>>;
  createTransportJobType?: Resolver<Maybe<ResolversTypes['TransportJobType']>, ParentType, ContextType, RequireFields<MutationCreateTransportJobTypeArgs, 'input'>>;
  createTransportRate?: Resolver<Maybe<ResolversTypes['TransportRate']>, ParentType, ContextType, RequireFields<MutationCreateTransportRateArgs, 'input'>>;
  createTransportRateFromBooking?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationCreateTransportRateFromBookingArgs>>;
  createTransportZone?: Resolver<Maybe<ResolversTypes['TransportZone']>, ParentType, ContextType, RequireFields<MutationCreateTransportZoneArgs, 'input'>>;
  createVehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType, RequireFields<MutationCreateVehicleArgs, 'input'>>;
  createVolumeUnit?: Resolver<Maybe<ResolversTypes['VolumeUnit']>, ParentType, ContextType, RequireFields<MutationCreateVolumeUnitArgs, 'input'>>;
  createVoucher?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType, RequireFields<MutationCreateVoucherArgs, 'input'>>;
  createVoucherRevision?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType, RequireFields<MutationCreateVoucherRevisionArgs, 'type' | 'uuid'>>;
  createWeightUnit?: Resolver<Maybe<ResolversTypes['WeightUnit']>, ParentType, ContextType, RequireFields<MutationCreateWeightUnitArgs, 'input'>>;
  createYard?: Resolver<Maybe<ResolversTypes['Yard']>, ParentType, ContextType, RequireFields<MutationCreateYardArgs, 'input'>>;
  deleteAddress?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteAddressArgs>>;
  deleteBaseCompany?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteBaseCompanyArgs, 'uuid'>>;
  deleteBillingUnit?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteBillingUnitArgs>>;
  deleteBookingCostItem?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteBookingCostItemArgs, 'uuid'>>;
  deleteBookingDocument?: Resolver<Maybe<ResolversTypes['BookingDocument']>, ParentType, ContextType, Partial<MutationDeleteBookingDocumentArgs>>;
  deleteBookingType?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteBookingTypeArgs>>;
  deleteBulkImportFunction?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteBulkImportFunctionArgs, 'uuid'>>;
  deleteBulkImportFunctionSetting?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteBulkImportFunctionSettingArgs, 'input'>>;
  deleteChannel?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteChannelArgs>>;
  deleteChargeCategory?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteChargeCategoryArgs, 'uuid'>>;
  deleteChargeItem?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteChargeItemArgs, 'uuid'>>;
  deleteChargeItemCategory?: Resolver<Maybe<ResolversTypes['ChargeItem']>, ParentType, ContextType, RequireFields<MutationDeleteChargeItemCategoryArgs, 'chargeCategoryUuid' | 'chargeItemUuid'>>;
  deleteChargeItemCode?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteChargeItemCodeArgs, 'uuid'>>;
  deleteChargeItemMisc?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteChargeItemMiscArgs>>;
  deleteChargeItemTax?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteChargeItemTaxArgs, 'uuid'>>;
  deleteCompany?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteCompanyArgs, 'uuid'>>;
  deleteCompanyAccess?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteCompanyAccessArgs, 'uuid'>>;
  deleteContact?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteContactArgs>>;
  deleteContainerGrade?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteContainerGradeArgs>>;
  deleteContainerType?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteContainerTypeArgs>>;
  deleteCounter?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteCounterArgs, 'uuid'>>;
  deleteDocumentCreatorTemplate?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteDocumentCreatorTemplateArgs, 'uuid'>>;
  deleteExchangeRate?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteExchangeRateArgs>>;
  deleteFaf?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteFafArgs>>;
  deleteGlCode?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteGlCodeArgs, 'uuid'>>;
  deleteIncentive?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteIncentiveArgs, 'uuid'>>;
  deleteIncentiveRate?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteIncentiveRateArgs, 'uuid'>>;
  deleteIncentiveType?: Resolver<Maybe<ResolversTypes['IncentiveType']>, ParentType, ContextType, Partial<MutationDeleteIncentiveTypeArgs>>;
  deleteIntegrationDetail?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteIntegrationDetailArgs>>;
  deleteIntegrationMapping?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteIntegrationMappingArgs>>;
  deleteJob?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteJobArgs>>;
  deleteJobOption?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteJobOptionArgs>>;
  deleteJobType?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteJobTypeArgs>>;
  deleteLeg?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteLegArgs>>;
  deleteManifests?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteManifestsArgs>>;
  deleteNodeBranch?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteNodeBranchArgs>>;
  deleteNodeConnection?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteNodeConnectionArgs>>;
  deletePayment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType, Partial<MutationDeletePaymentArgs>>;
  deletePermission?: Resolver<Maybe<Array<Maybe<ResolversTypes['Permission']>>>, ParentType, ContextType, RequireFields<MutationDeletePermissionArgs, 'permission' | 'resource' | 'role'>>;
  deletePortalCompany?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeletePortalCompanyArgs>>;
  deletePushNotification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, Partial<MutationDeletePushNotificationArgs>>;
  deleteQuotationItem?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteQuotationItemArgs, 'uuid'>>;
  deleteReport?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteReportArgs>>;
  deleteSetting?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteSettingArgs>>;
  deleteTax?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteTaxArgs, 'uuid'>>;
  deleteTransportAreaCode?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteTransportAreaCodeArgs>>;
  deleteTransportJobType?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteTransportJobTypeArgs>>;
  deleteTransportRate?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteTransportRateArgs>>;
  deleteTransportZone?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteTransportZoneArgs>>;
  deleteUserPreference?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteUserPreferenceArgs, 'key' | 'type'>>;
  deleteVolumeUnit?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteVolumeUnitArgs>>;
  deleteVoucherItem?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteVoucherItemArgs, 'uuid'>>;
  deleteWeightUnit?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationDeleteWeightUnitArgs>>;
  deleteYard?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationDeleteYardArgs, 'uuid'>>;
  enableCountry?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType, Partial<MutationEnableCountryArgs>>;
  endTripsInManifest?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationEndTripsInManifestArgs>>;
  expireQuotation?: Resolver<Maybe<ResolversTypes['Quotation']>, ParentType, ContextType, RequireFields<MutationExpireQuotationArgs, 'uuid'>>;
  generateDocumentCreatorNumber?: Resolver<Maybe<ResolversTypes['DocumentCreatorReference']>, ParentType, ContextType, Partial<MutationGenerateDocumentCreatorNumberArgs>>;
  generateJobsDev?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType>;
  generateReport?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationGenerateReportArgs>>;
  insertChildNode?: Resolver<Maybe<ResolversTypes['ChildNode']>, ParentType, ContextType, Partial<MutationInsertChildNodeArgs>>;
  insertChronology?: Resolver<Maybe<ResolversTypes['Chronology']>, ParentType, ContextType, RequireFields<MutationInsertChronologyArgs, 'input'>>;
  invitePortalCompany?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationInvitePortalCompanyArgs>>;
  inviteToCompany?: Resolver<Maybe<Array<Maybe<ResolversTypes['InviteUserResponse']>>>, ParentType, ContextType, RequireFields<MutationInviteToCompanyArgs, 'users' | 'uuid'>>;
  linkCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<MutationLinkCompanyArgs, 'baseCompanyUuid' | 'companyUuid'>>;
  linkDocumentToVoucher?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType, RequireFields<MutationLinkDocumentToVoucherArgs, 'bookingDocumentUuid' | 'voucherUuid'>>;
  linkInviteCompanyAccess?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationLinkInviteCompanyAccessArgs, 'externalCompanyUuids' | 'uuid'>>;
  makeBooking?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<MutationMakeBookingArgs, 'input'>>;
  makeBookingComment?: Resolver<Maybe<ResolversTypes['MessageEvent']>, ParentType, ContextType, RequireFields<MutationMakeBookingCommentArgs, 'message' | 'uuid'>>;
  migrateFmsCharges?: Resolver<Maybe<Array<Maybe<ResolversTypes['ChargeItem']>>>, ParentType, ContextType, Partial<MutationMigrateFmsChargesArgs>>;
  migrateFmsCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<MutationMigrateFmsCompanyArgs, 'code'>>;
  openShift?: Resolver<Maybe<ResolversTypes['Shift']>, ParentType, ContextType, RequireFields<MutationOpenShiftArgs, 'input'>>;
  populateAvailability?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationPopulateAvailabilityArgs>>;
  processAllJobs?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationProcessAllJobsArgs>>;
  processJob?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationProcessJobArgs>>;
  processReportPortalCompany?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationProcessReportPortalCompanyArgs>>;
  processTransportBooking?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportJob']>>>, ParentType, ContextType, RequireFields<MutationProcessTransportBookingArgs, 'input'>>;
  processVehiclePlan?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationProcessVehiclePlanArgs>>;
  purgeQueue?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType>;
  recomputeCost?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationRecomputeCostArgs>>;
  recomputeLegActivities?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationRecomputeLegActivitiesArgs>>;
  reindexJob?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationReindexJobArgs>>;
  rejectInviteCompanyAccess?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationRejectInviteCompanyAccessArgs, 'uuid'>>;
  removeChronology?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationRemoveChronologyArgs, 'uuid'>>;
  removeCurrency?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationRemoveCurrencyArgs, 'baseCompanyUuid' | 'currencyUuid'>>;
  removeDriverCompany?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType, RequireFields<MutationRemoveDriverCompanyArgs, 'input'>>;
  removeEmployee?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationRemoveEmployeeArgs, 'companyUuid' | 'userUuid'>>;
  removeGroup?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationRemoveGroupArgs, 'uuid'>>;
  removeLegsFromManifest?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationRemoveLegsFromManifestArgs>>;
  removeMember?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType, RequireFields<MutationRemoveMemberArgs, 'employeeUuid' | 'uuid'>>;
  removeMonitoringCompany?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationRemoveMonitoringCompanyArgs, 'uuid'>>;
  removeSuperAdmin?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationRemoveSuperAdminArgs, 'uuid'>>;
  removeTrailerCompany?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType, RequireFields<MutationRemoveTrailerCompanyArgs, 'input'>>;
  removeVehicleCompany?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType, RequireFields<MutationRemoveVehicleCompanyArgs, 'input'>>;
  requestApprove?: Resolver<Maybe<ResolversTypes['Request']>, ParentType, ContextType, RequireFields<MutationRequestApproveArgs, 'type' | 'uuid'>>;
  resendInvite?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationResendInviteArgs, 'employeeUuid'>>;
  resendInviteCompanyAccess?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationResendInviteCompanyAccessArgs, 'uuid'>>;
  rolloverJob?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType, RequireFields<MutationRolloverJobArgs, 'jobUuid'>>;
  rolloverVoucher?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType, RequireFields<MutationRolloverVoucherArgs, 'bookingUuid' | 'jobUuid' | 'uuid'>>;
  setBookingAssignee?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationSetBookingAssigneeArgs, 'input'>>;
  spliceNodeBranch?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationSpliceNodeBranchArgs>>;
  submitROT?: Resolver<Maybe<ResolversTypes['Request']>, ParentType, ContextType, RequireFields<MutationSubmitRotArgs, 'input'>>;
  submitRequest?: Resolver<Maybe<ResolversTypes['Request']>, ParentType, ContextType, RequireFields<MutationSubmitRequestArgs, 'input'>>;
  syncEmployees?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType>;
  triggerBookingEvent?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationTriggerBookingEventArgs>>;
  triggerVoucherEvent?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationTriggerVoucherEventArgs>>;
  unInvitePortalCompany?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUnInvitePortalCompanyArgs>>;
  unapproveFuel?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUnapproveFuelArgs, 'uuid'>>;
  unapproveIncentiveVoucher?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUnapproveIncentiveVoucherArgs>>;
  unapproveIncentiveVoucherFinance?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUnapproveIncentiveVoucherFinanceArgs>>;
  unapproveShift?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUnapproveShiftArgs>>;
  unapproveShiftFinance?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUnapproveShiftFinanceArgs>>;
  unassignPortalCompanyReport?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUnassignPortalCompanyReportArgs>>;
  uncancelFuel?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUncancelFuelArgs, 'uuid'>>;
  uncancelGateLog?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUncancelGateLogArgs, 'uuid'>>;
  uncancelIncentiveVoucher?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUncancelIncentiveVoucherArgs>>;
  uncancelShift?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUncancelShiftArgs>>;
  undeleteBillingUnit?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeleteBillingUnitArgs>>;
  undeleteBookingType?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeleteBookingTypeArgs>>;
  undeleteBulkImportFunction?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUndeleteBulkImportFunctionArgs, 'uuid'>>;
  undeleteContainerGrade?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeleteContainerGradeArgs>>;
  undeleteContainerType?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeleteContainerTypeArgs>>;
  undeleteDocumentCreatorTemplate?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUndeleteDocumentCreatorTemplateArgs, 'uuid'>>;
  undeleteGlCode?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUndeleteGlCodeArgs, 'uuid'>>;
  undeleteIncentive?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUndeleteIncentiveArgs, 'uuid'>>;
  undeleteIncentiveType?: Resolver<Maybe<ResolversTypes['IncentiveType']>, ParentType, ContextType, Partial<MutationUndeleteIncentiveTypeArgs>>;
  undeleteJob?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeleteJobArgs>>;
  undeleteJobOption?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeleteJobOptionArgs>>;
  undeleteJobType?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeleteJobTypeArgs>>;
  undeleteLeg?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeleteLegArgs>>;
  undeletePortalCompany?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeletePortalCompanyArgs>>;
  undeleteTax?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUndeleteTaxArgs, 'uuid'>>;
  undeleteTransportJobType?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeleteTransportJobTypeArgs>>;
  undeleteTransportZone?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeleteTransportZoneArgs>>;
  undeleteVolumeUnit?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeleteVolumeUnitArgs>>;
  undeleteWeightUnit?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUndeleteWeightUnitArgs>>;
  undeleteYard?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUndeleteYardArgs, 'uuid'>>;
  unlinkCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<MutationUnlinkCompanyArgs, 'companyUuid'>>;
  unlinkDocumentFromVoucher?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType, RequireFields<MutationUnlinkDocumentFromVoucherArgs, 'bookingDocumentUuid' | 'voucherUuid'>>;
  unsetBookingAssignee?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUnsetBookingAssigneeArgs, 'uuid'>>;
  updateAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType, RequireFields<MutationUpdateAddressArgs, 'input'>>;
  updateAddressLocation?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType, RequireFields<MutationUpdateAddressLocationArgs, 'input'>>;
  updateAvailability?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUpdateAvailabilityArgs>>;
  updateBaseCompany?: Resolver<Maybe<ResolversTypes['BaseCompany']>, ParentType, ContextType, RequireFields<MutationUpdateBaseCompanyArgs, 'input'>>;
  updateBillingUnit?: Resolver<Maybe<ResolversTypes['BillingUnit']>, ParentType, ContextType, Partial<MutationUpdateBillingUnitArgs>>;
  updateBooking?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType, RequireFields<MutationUpdateBookingArgs, 'input'>>;
  updateBookingActivities?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType, Partial<MutationUpdateBookingActivitiesArgs>>;
  updateBookingCostItem?: Resolver<Maybe<ResolversTypes['CostItem']>, ParentType, ContextType, RequireFields<MutationUpdateBookingCostItemArgs, 'input'>>;
  updateBookingDocument?: Resolver<Maybe<ResolversTypes['BookingDocument']>, ParentType, ContextType, RequireFields<MutationUpdateBookingDocumentArgs, 'type' | 'uuid'>>;
  updateBookingType?: Resolver<Maybe<ResolversTypes['BookingType']>, ParentType, ContextType, Partial<MutationUpdateBookingTypeArgs>>;
  updateBulkImportFunction?: Resolver<Maybe<ResolversTypes['BulkImportFunction']>, ParentType, ContextType, RequireFields<MutationUpdateBulkImportFunctionArgs, 'input'>>;
  updateChannel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType, RequireFields<MutationUpdateChannelArgs, 'input'>>;
  updateChargeCategory?: Resolver<Maybe<ResolversTypes['ChargeCategory']>, ParentType, ContextType, RequireFields<MutationUpdateChargeCategoryArgs, 'input'>>;
  updateChargeItem?: Resolver<Maybe<ResolversTypes['ChargeItem']>, ParentType, ContextType, RequireFields<MutationUpdateChargeItemArgs, 'input'>>;
  updateChargeItemCode?: Resolver<Maybe<ResolversTypes['ChargeItem']>, ParentType, ContextType, RequireFields<MutationUpdateChargeItemCodeArgs, 'input'>>;
  updateChargeItemMisc?: Resolver<Maybe<ResolversTypes['ChargeItemMisc']>, ParentType, ContextType, RequireFields<MutationUpdateChargeItemMiscArgs, 'input'>>;
  updateChargeItemTax?: Resolver<Maybe<ResolversTypes['ChargeItem']>, ParentType, ContextType, RequireFields<MutationUpdateChargeItemTaxArgs, 'input'>>;
  updateChildNode?: Resolver<Maybe<ResolversTypes['ChildNode']>, ParentType, ContextType, Partial<MutationUpdateChildNodeArgs>>;
  updateChildNodeStatus?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUpdateChildNodeStatusArgs>>;
  updateChronology?: Resolver<Maybe<ResolversTypes['Chronology']>, ParentType, ContextType, RequireFields<MutationUpdateChronologyArgs, 'input'>>;
  updateCompany?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<MutationUpdateCompanyArgs, 'input'>>;
  updateCompanyCode?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<MutationUpdateCompanyCodeArgs, 'input'>>;
  updateContact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType, RequireFields<MutationUpdateContactArgs, 'input'>>;
  updateContainerGrade?: Resolver<Maybe<ResolversTypes['ContainerGrade']>, ParentType, ContextType, RequireFields<MutationUpdateContainerGradeArgs, 'input'>>;
  updateContainerType?: Resolver<Maybe<ResolversTypes['ContainerType']>, ParentType, ContextType, RequireFields<MutationUpdateContainerTypeArgs, 'input'>>;
  updateCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType, RequireFields<MutationUpdateCurrencyArgs, 'uuid'>>;
  updateDocumentCreator?: Resolver<Maybe<ResolversTypes['DocumentCreator']>, ParentType, ContextType, Partial<MutationUpdateDocumentCreatorArgs>>;
  updateDocumentCreatorTemplate?: Resolver<Maybe<ResolversTypes['DocumentCreatorTemplate']>, ParentType, ContextType, Partial<MutationUpdateDocumentCreatorTemplateArgs>>;
  updateDriver?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType, RequireFields<MutationUpdateDriverArgs, 'input'>>;
  updateDriverCompany?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType, RequireFields<MutationUpdateDriverCompanyArgs, 'input'>>;
  updateDriverEmployee?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType, RequireFields<MutationUpdateDriverEmployeeArgs, 'input'>>;
  updateDriverImage?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType, RequireFields<MutationUpdateDriverImageArgs, 'input'>>;
  updateDriverStatus?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType, RequireFields<MutationUpdateDriverStatusArgs, 'input'>>;
  updateEmptyTrip?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUpdateEmptyTripArgs>>;
  updateEmptyTripStatus?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUpdateEmptyTripStatusArgs>>;
  updateEntityDocument?: Resolver<Maybe<ResolversTypes['DocumentDescription']>, ParentType, ContextType, RequireFields<MutationUpdateEntityDocumentArgs, 'input'>>;
  updateEntityDocumentStatus?: Resolver<Maybe<ResolversTypes['DocumentDescription']>, ParentType, ContextType, RequireFields<MutationUpdateEntityDocumentStatusArgs, 'input'>>;
  updateEntityRequirements?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUpdateEntityRequirementsArgs, 'input'>>;
  updateEntityRequirementsCategory?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUpdateEntityRequirementsCategoryArgs, 'input'>>;
  updateExchangeRate?: Resolver<Maybe<ResolversTypes['ExchangeRate']>, ParentType, ContextType, RequireFields<MutationUpdateExchangeRateArgs, 'input'>>;
  updateFaf?: Resolver<Maybe<ResolversTypes['Faf']>, ParentType, ContextType, RequireFields<MutationUpdateFafArgs, 'input'>>;
  updateFuel?: Resolver<Maybe<ResolversTypes['Fuel']>, ParentType, ContextType, Partial<MutationUpdateFuelArgs>>;
  updateGateLog?: Resolver<Maybe<ResolversTypes['GateLog']>, ParentType, ContextType, Partial<MutationUpdateGateLogArgs>>;
  updateGlCode?: Resolver<Maybe<ResolversTypes['GlCode']>, ParentType, ContextType, RequireFields<MutationUpdateGlCodeArgs, 'input'>>;
  updateGroup?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType, RequireFields<MutationUpdateGroupArgs, 'input'>>;
  updateIncentive?: Resolver<Maybe<ResolversTypes['Incentive']>, ParentType, ContextType, Partial<MutationUpdateIncentiveArgs>>;
  updateIncentiveRate?: Resolver<Maybe<ResolversTypes['IncentiveRate']>, ParentType, ContextType, Partial<MutationUpdateIncentiveRateArgs>>;
  updateIncentiveType?: Resolver<Maybe<ResolversTypes['IncentiveType']>, ParentType, ContextType, Partial<MutationUpdateIncentiveTypeArgs>>;
  updateIncentiveVoucher?: Resolver<Maybe<ResolversTypes['IncentiveVoucher']>, ParentType, ContextType, RequireFields<MutationUpdateIncentiveVoucherArgs, 'input'>>;
  updateIntegrationDetail?: Resolver<Maybe<ResolversTypes['IntegrationDetail']>, ParentType, ContextType, RequireFields<MutationUpdateIntegrationDetailArgs, 'input'>>;
  updateIntegrationMapping?: Resolver<Maybe<ResolversTypes['IntegrationMapping']>, ParentType, ContextType, RequireFields<MutationUpdateIntegrationMappingArgs, 'input'>>;
  updateJob?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType, Partial<MutationUpdateJobArgs>>;
  updateJobOption?: Resolver<Maybe<ResolversTypes['JobOption']>, ParentType, ContextType, RequireFields<MutationUpdateJobOptionArgs, 'input'>>;
  updateJobTrips?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUpdateJobTripsArgs>>;
  updateJobType?: Resolver<Maybe<ResolversTypes['JobType']>, ParentType, ContextType, Partial<MutationUpdateJobTypeArgs>>;
  updateLeg?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUpdateLegArgs, 'input'>>;
  updateLegTiming?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, RequireFields<MutationUpdateLegTimingArgs, 'input'>>;
  updatePayment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType, RequireFields<MutationUpdatePaymentArgs, 'input'>>;
  updatePortalCompany?: Resolver<Maybe<ResolversTypes['PortalCompany']>, ParentType, ContextType, Partial<MutationUpdatePortalCompanyArgs>>;
  updateQuotation?: Resolver<Maybe<ResolversTypes['Quotation']>, ParentType, ContextType, RequireFields<MutationUpdateQuotationArgs, 'input'>>;
  updateQuotationItem?: Resolver<Maybe<ResolversTypes['QuotationItem']>, ParentType, ContextType, RequireFields<MutationUpdateQuotationItemArgs, 'input'>>;
  updateReport?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUpdateReportArgs>>;
  updateRole?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType, RequireFields<MutationUpdateRoleArgs, 'input'>>;
  updateRootNode?: Resolver<Maybe<ResolversTypes['RootNode']>, ParentType, ContextType, Partial<MutationUpdateRootNodeArgs>>;
  updateRootNodeStatus?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUpdateRootNodeStatusArgs>>;
  updateSetting?: Resolver<Maybe<ResolversTypes['BaseCompanySetting']>, ParentType, ContextType, RequireFields<MutationUpdateSettingArgs, 'input'>>;
  updateShift?: Resolver<Maybe<ResolversTypes['Shift']>, ParentType, ContextType, RequireFields<MutationUpdateShiftArgs, 'input'>>;
  updateTax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType, RequireFields<MutationUpdateTaxArgs, 'input'>>;
  updateTrailer?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType, RequireFields<MutationUpdateTrailerArgs, 'input'>>;
  updateTrailerCompany?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType, RequireFields<MutationUpdateTrailerCompanyArgs, 'input'>>;
  updateTrailerImage?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType, RequireFields<MutationUpdateTrailerImageArgs, 'input'>>;
  updateTrailerStatus?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType, RequireFields<MutationUpdateTrailerStatusArgs, 'input'>>;
  updateTransportAreaCode?: Resolver<Maybe<ResolversTypes['TransportAreaCode']>, ParentType, ContextType, RequireFields<MutationUpdateTransportAreaCodeArgs, 'input'>>;
  updateTransportJobAddress?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUpdateTransportJobAddressArgs>>;
  updateTransportJobType?: Resolver<Maybe<ResolversTypes['TransportJobType']>, ParentType, ContextType, RequireFields<MutationUpdateTransportJobTypeArgs, 'input'>>;
  updateTransportRate?: Resolver<Maybe<ResolversTypes['TransportRate']>, ParentType, ContextType, RequireFields<MutationUpdateTransportRateArgs, 'input'>>;
  updateTransportZone?: Resolver<Maybe<ResolversTypes['TransportZone']>, ParentType, ContextType, RequireFields<MutationUpdateTransportZoneArgs, 'input'>>;
  updateTrip?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType, Partial<MutationUpdateTripArgs>>;
  updateUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'input'>>;
  updateUserPreference?: Resolver<Maybe<ResolversTypes['UserPreference']>, ParentType, ContextType, RequireFields<MutationUpdateUserPreferenceArgs, 'key' | 'type' | 'value'>>;
  updateVehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType, RequireFields<MutationUpdateVehicleArgs, 'input'>>;
  updateVehicleCompany?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType, RequireFields<MutationUpdateVehicleCompanyArgs, 'input'>>;
  updateVehicleImage?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType, RequireFields<MutationUpdateVehicleImageArgs, 'input'>>;
  updateVehicleStatus?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType, RequireFields<MutationUpdateVehicleStatusArgs, 'input'>>;
  updateVolumeUnit?: Resolver<Maybe<ResolversTypes['VolumeUnit']>, ParentType, ContextType, RequireFields<MutationUpdateVolumeUnitArgs, 'input'>>;
  updateVoucher?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType, RequireFields<MutationUpdateVoucherArgs, 'input'>>;
  updateVoucherItem?: Resolver<Maybe<ResolversTypes['VoucherItem']>, ParentType, ContextType, RequireFields<MutationUpdateVoucherItemArgs, 'input'>>;
  updateWeightUnit?: Resolver<Maybe<ResolversTypes['WeightUnit']>, ParentType, ContextType, RequireFields<MutationUpdateWeightUnitArgs, 'input'>>;
  updateYard?: Resolver<Maybe<ResolversTypes['Yard']>, ParentType, ContextType, RequireFields<MutationUpdateYardArgs, 'input'>>;
  uploadBookingDocument?: Resolver<Maybe<ResolversTypes['BookingDocument']>, ParentType, ContextType, Partial<MutationUploadBookingDocumentArgs>>;
  verifyBookingDocument?: Resolver<Maybe<ResolversTypes['BookingDocument']>, ParentType, ContextType, Partial<MutationVerifyBookingDocumentArgs>>;
};

export type NodeConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['NodeConnection'] = ResolversParentTypes['NodeConnection']> = {
  _id?: Resolver<Maybe<ResolversTypes['ObjectID']>, ParentType, ContextType>;
  childId?: Resolver<Maybe<ResolversTypes['ObjectID']>, ParentType, ContextType>;
  childSelector?: Resolver<Maybe<ResolversTypes['ChildSelector']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  parentId?: Resolver<Maybe<ResolversTypes['ObjectID']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NotificationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = {
  driverUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  tokens?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface ObjectIdScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['ObjectID'], any> {
  name: 'ObjectID';
}

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface PasswordScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Password'], any> {
  name: 'Password';
}

export type PaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payment'] = ResolversParentTypes['Payment']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['PaymentStatus']>, ParentType, ContextType>;
  transactionType?: Resolver<Maybe<ResolversTypes['PaymentTransactionType']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  voucherPayments?: Resolver<Maybe<Array<Maybe<ResolversTypes['VoucherPayment']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PaymentsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Payments'] = ResolversParentTypes['Payments']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Payment']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionResolvers<ContextType = any, ParentType extends ResolversParentTypes['Permission'] = ResolversParentTypes['Permission']> = {
  permissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  resources?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['PermissionType'] = ResolversParentTypes['PermissionType']> = {
  canApprove?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  canReject?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PermissionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Permissions'] = ResolversParentTypes['Permissions']> = {
  role?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rolePermissions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Permission']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlaceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Place'] = ResolversParentTypes['Place']> = {
  country?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Geometry']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlaceResultsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PlaceResults'] = ResolversParentTypes['PlaceResults']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  placeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PmUtilDeptResolvers<ContextType = any, ParentType extends ResolversParentTypes['PmUtilDept'] = ResolversParentTypes['PmUtilDept']> = {
  c?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  dept?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tot?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PointResolvers<ContextType = any, ParentType extends ResolversParentTypes['Point'] = ResolversParentTypes['Point']> = {
  coordinates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PortResolvers<ContextType = any, ParentType extends ResolversParentTypes['Port'] = ResolversParentTypes['Port']> = {
  cnIso2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  cnName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryAlpha3?: Resolver<Maybe<ResolversTypes['LimitedString']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  locode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  locodeAlternatives?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameAlternatives?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PortalCompaniesResolvers<ContextType = any, ParentType extends ResolversParentTypes['PortalCompanies'] = ResolversParentTypes['PortalCompanies']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['PortalCompany']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PortalCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['PortalCompany'] = ResolversParentTypes['PortalCompany']> = {
  bookCompanies?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  bulkImportFunctions?: Resolver<Maybe<Array<Maybe<ResolversTypes['BulkImportFunction']>>>, ParentType, ContextType>;
  details?: Resolver<Maybe<ResolversTypes['PortalCompanyDetails']>, ParentType, ContextType>;
  employees?: Resolver<Maybe<Array<Maybe<ResolversTypes['Employee']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reports?: Resolver<Maybe<ResolversTypes['PortalReports']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['CompanyStatus']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  viewCompanies?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PortalCompanyDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PortalCompanyDetails'] = ResolversParentTypes['PortalCompanyDetails']> = {
  userTimeout?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PortalCompanyReportConfigResolvers<ContextType = any, ParentType extends ResolversParentTypes['PortalCompanyReportConfig'] = ResolversParentTypes['PortalCompanyReportConfig']> = {
  fields?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  hours?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sort?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PortalReportsResolvers<ContextType = any, ParentType extends ResolversParentTypes['PortalReports'] = ResolversParentTypes['PortalReports']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  portalCompanyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportPortalCompany']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PortsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Ports'] = ResolversParentTypes['Ports']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Port']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessFlowResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessFlow'] = ResolversParentTypes['ProcessFlow']> = {
  sections?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProcessFlowSection']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessFlowChronologiesTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessFlowChronologiesType'] = ResolversParentTypes['ProcessFlowChronologiesType']> = {
  isJobRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTripRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessFlowCreateTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessFlowCreateType'] = ResolversParentTypes['ProcessFlowCreateType']> = {
  bookingDocuments?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProcessFlowDocumentType']>>>, ParentType, ContextType>;
  chronologies?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProcessFlowChronologiesType']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessFlowDocumentTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessFlowDocumentType'] = ResolversParentTypes['ProcessFlowDocumentType']> = {
  approvals?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  isJobRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isProofOfDelivery?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isTripRequired?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  required?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessFlowRequestTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessFlowRequestType'] = ResolversParentTypes['ProcessFlowRequestType']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessFlowSectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessFlowSection'] = ResolversParentTypes['ProcessFlowSection']> = {
  bookingDocuments?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProcessFlowDocumentType']>>>, ParentType, ContextType>;
  chronologies?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProcessFlowChronologiesType']>>>, ParentType, ContextType>;
  display?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  requests?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProcessFlowRequestType']>>>, ParentType, ContextType>;
  steps?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProcessFlowStepType']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vouchers?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProcessFlowVoucherType']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessFlowStepTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessFlowStepType'] = ResolversParentTypes['ProcessFlowStepType']> = {
  helpText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isComplete?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isCurrentStep?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  permission?: Resolver<Maybe<ResolversTypes['PermissionType']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessFlowVoucherTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessFlowVoucherType'] = ResolversParentTypes['ProcessFlowVoucherType']> = {
  approvals?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  bookingDocuments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  transactionCategory?: Resolver<Maybe<ResolversTypes['VoucherCategoryType']>, ParentType, ContextType>;
  transactionType?: Resolver<Maybe<ResolversTypes['VoucherTypeType']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessTypeCategoriesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessTypeCategories'] = ResolversParentTypes['ProcessTypeCategories']> = {
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProcessTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProcessTypes'] = ResolversParentTypes['ProcessTypes']> = {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProfileResolvers<ContextType = any, ParentType extends ResolversParentTypes['Profile'] = ResolversParentTypes['Profile']> = {
  displayName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  actionTemplate?: Resolver<Maybe<ResolversTypes['ActionTemplate']>, ParentType, ContextType, RequireFields<QueryActionTemplateArgs, 'bookingType' | 'resource' | 'type'>>;
  actionTemplates?: Resolver<Maybe<Array<Maybe<ResolversTypes['ActionTemplate']>>>, ParentType, ContextType, RequireFields<QueryActionTemplatesArgs, 'bookingType' | 'resource'>>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType, Partial<QueryAddressArgs>>;
  addresses?: Resolver<Maybe<ResolversTypes['Addresses']>, ParentType, ContextType, Partial<QueryAddressesArgs>>;
  approval?: Resolver<Maybe<ResolversTypes['Approval']>, ParentType, ContextType, RequireFields<QueryApprovalArgs, 'uuid'>>;
  approvals?: Resolver<Maybe<ResolversTypes['Approvals']>, ParentType, ContextType, Partial<QueryApprovalsArgs>>;
  availabilities?: Resolver<Maybe<ResolversTypes['AvailabilitiesResults']>, ParentType, ContextType, Partial<QueryAvailabilitiesArgs>>;
  availability?: Resolver<Maybe<ResolversTypes['Availability']>, ParentType, ContextType, Partial<QueryAvailabilityArgs>>;
  baseCompanies?: Resolver<Maybe<ResolversTypes['BaseCompanies']>, ParentType, ContextType, Partial<QueryBaseCompaniesArgs>>;
  baseCompany?: Resolver<Maybe<ResolversTypes['BaseCompany']>, ParentType, ContextType, Partial<QueryBaseCompanyArgs>>;
  billingUnit?: Resolver<Maybe<ResolversTypes['BillingUnit']>, ParentType, ContextType, Partial<QueryBillingUnitArgs>>;
  billingUnits?: Resolver<Maybe<ResolversTypes['BillingUnits']>, ParentType, ContextType, Partial<QueryBillingUnitsArgs>>;
  booking?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType, Partial<QueryBookingArgs>>;
  bookingDocument?: Resolver<Maybe<ResolversTypes['BookingDocument']>, ParentType, ContextType, Partial<QueryBookingDocumentArgs>>;
  bookingDocuments?: Resolver<Maybe<ResolversTypes['BookingDocuments']>, ParentType, ContextType, Partial<QueryBookingDocumentsArgs>>;
  bookingDocumentsSummary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, Partial<QueryBookingDocumentsSummaryArgs>>;
  bookingEvents?: Resolver<Maybe<Array<Maybe<ResolversTypes['Event']>>>, ParentType, ContextType, Partial<QueryBookingEventsArgs>>;
  bookingFieldValue?: Resolver<Maybe<ResolversTypes['BookingFieldValue']>, ParentType, ContextType, Partial<QueryBookingFieldValueArgs>>;
  bookingForRates?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<QueryBookingForRatesArgs>>;
  bookingPublic?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<QueryBookingPublicArgs>>;
  bookingSummary?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingSummary']>>>, ParentType, ContextType>;
  bookingType?: Resolver<Maybe<ResolversTypes['BookingType']>, ParentType, ContextType, Partial<QueryBookingTypeArgs>>;
  bookingTypes?: Resolver<Maybe<ResolversTypes['BookingTypes']>, ParentType, ContextType, Partial<QueryBookingTypesArgs>>;
  bookingsSearch?: Resolver<Maybe<ResolversTypes['Bookings']>, ParentType, ContextType, Partial<QueryBookingsSearchArgs>>;
  bookingsSearchJson?: Resolver<Maybe<ResolversTypes['BookingsJson']>, ParentType, ContextType, Partial<QueryBookingsSearchJsonArgs>>;
  bulkImportFunction?: Resolver<Maybe<ResolversTypes['BulkImportFunction']>, ParentType, ContextType, RequireFields<QueryBulkImportFunctionArgs, 'uuid'>>;
  bulkImportFunctions?: Resolver<Maybe<ResolversTypes['BulkImportFunctions']>, ParentType, ContextType, Partial<QueryBulkImportFunctionsArgs>>;
  channel?: Resolver<Maybe<ResolversTypes['Channel']>, ParentType, ContextType, Partial<QueryChannelArgs>>;
  channels?: Resolver<Maybe<ResolversTypes['Channels']>, ParentType, ContextType, Partial<QueryChannelsArgs>>;
  chargeCategories?: Resolver<Maybe<ResolversTypes['ChargeCategories']>, ParentType, ContextType, Partial<QueryChargeCategoriesArgs>>;
  chargeCategory?: Resolver<Maybe<ResolversTypes['ChargeCategory']>, ParentType, ContextType, Partial<QueryChargeCategoryArgs>>;
  chargeItem?: Resolver<Maybe<ResolversTypes['ChargeItem']>, ParentType, ContextType, Partial<QueryChargeItemArgs>>;
  chargeItemMisc?: Resolver<Maybe<ResolversTypes['ChargeItemMisc']>, ParentType, ContextType, Partial<QueryChargeItemMiscArgs>>;
  chargeItemMiscs?: Resolver<Maybe<ResolversTypes['ChargeItemMiscs']>, ParentType, ContextType, Partial<QueryChargeItemMiscsArgs>>;
  chargeItems?: Resolver<Maybe<ResolversTypes['ChargeItems']>, ParentType, ContextType, Partial<QueryChargeItemsArgs>>;
  childNode?: Resolver<Maybe<ResolversTypes['ChildNode']>, ParentType, ContextType, Partial<QueryChildNodeArgs>>;
  childNodes?: Resolver<Maybe<ResolversTypes['ChildNodes']>, ParentType, ContextType, Partial<QueryChildNodesArgs>>;
  commodityTypes?: Resolver<Maybe<ResolversTypes['CommodityTypes']>, ParentType, ContextType, Partial<QueryCommodityTypesArgs>>;
  companies?: Resolver<Maybe<ResolversTypes['Companies']>, ParentType, ContextType, Partial<QueryCompaniesArgs>>;
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType, RequireFields<QueryCompanyArgs, 'uuid'>>;
  companyAccess?: Resolver<Maybe<ResolversTypes['CompanyAccess']>, ParentType, ContextType, RequireFields<QueryCompanyAccessArgs, 'uuid'>>;
  companyAccessExternalBaseCompany?: Resolver<Maybe<Array<Maybe<ResolversTypes['BaseCompany']>>>, ParentType, ContextType>;
  companyAccesses?: Resolver<Maybe<ResolversTypes['CompanyAccesses']>, ParentType, ContextType, Partial<QueryCompanyAccessesArgs>>;
  computeRateFromBooking?: Resolver<Maybe<Array<Maybe<ResolversTypes['ComputedRateResult']>>>, ParentType, ContextType, Partial<QueryComputeRateFromBookingArgs>>;
  computedCostLastNode?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<QueryComputedCostLastNodeArgs>>;
  consolVoucher?: Resolver<Maybe<ResolversTypes['ConsolVoucher']>, ParentType, ContextType, Partial<QueryConsolVoucherArgs>>;
  consolVouchers?: Resolver<Maybe<ResolversTypes['ConsolVouchers']>, ParentType, ContextType, Partial<QueryConsolVouchersArgs>>;
  contact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType, Partial<QueryContactArgs>>;
  contacts?: Resolver<Maybe<ResolversTypes['Contacts']>, ParentType, ContextType, Partial<QueryContactsArgs>>;
  containerGrade?: Resolver<Maybe<ResolversTypes['ContainerGrade']>, ParentType, ContextType, Partial<QueryContainerGradeArgs>>;
  containerGrades?: Resolver<Maybe<ResolversTypes['ContainerGrades']>, ParentType, ContextType, Partial<QueryContainerGradesArgs>>;
  containerType?: Resolver<Maybe<ResolversTypes['ContainerType']>, ParentType, ContextType, Partial<QueryContainerTypeArgs>>;
  containerTypes?: Resolver<Maybe<ResolversTypes['ContainerTypes']>, ParentType, ContextType, Partial<QueryContainerTypesArgs>>;
  costItems?: Resolver<Maybe<Array<Maybe<ResolversTypes['CostItem']>>>, ParentType, ContextType, RequireFields<QueryCostItemsArgs, 'uuid'>>;
  costSheet?: Resolver<Maybe<Array<Maybe<ResolversTypes['CostSheet']>>>, ParentType, ContextType, RequireFields<QueryCostSheetArgs, 'uuid'>>;
  counters?: Resolver<Maybe<ResolversTypes['CounterResults']>, ParentType, ContextType, Partial<QueryCountersArgs>>;
  countries?: Resolver<Maybe<ResolversTypes['Countries']>, ParentType, ContextType, Partial<QueryCountriesArgs>>;
  currencies?: Resolver<Maybe<ResolversTypes['Currencies']>, ParentType, ContextType, Partial<QueryCurrenciesArgs>>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType, RequireFields<QueryCurrencyArgs, 'uuid'>>;
  documentCreator?: Resolver<Maybe<ResolversTypes['DocumentCreator']>, ParentType, ContextType, Partial<QueryDocumentCreatorArgs>>;
  documentCreatorTemplate?: Resolver<Maybe<ResolversTypes['DocumentCreatorTemplate']>, ParentType, ContextType, Partial<QueryDocumentCreatorTemplateArgs>>;
  documentCreatorTemplates?: Resolver<Maybe<ResolversTypes['DocumentCreatorTemplates']>, ParentType, ContextType, Partial<QueryDocumentCreatorTemplatesArgs>>;
  documentCreators?: Resolver<Maybe<ResolversTypes['DocumentCreators']>, ParentType, ContextType, Partial<QueryDocumentCreatorsArgs>>;
  driver?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType, Partial<QueryDriverArgs>>;
  drivers?: Resolver<Maybe<ResolversTypes['DriversResults']>, ParentType, ContextType, Partial<QueryDriversArgs>>;
  emailTemplate?: Resolver<Maybe<ResolversTypes['Template']>, ParentType, ContextType, RequireFields<QueryEmailTemplateArgs, 'name'>>;
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType, RequireFields<QueryEmployeeArgs, 'uuid'>>;
  employees?: Resolver<Maybe<ResolversTypes['Employees']>, ParentType, ContextType, Partial<QueryEmployeesArgs>>;
  emptyTrip?: Resolver<Maybe<ResolversTypes['EmptyTrip']>, ParentType, ContextType, Partial<QueryEmptyTripArgs>>;
  emptyTrips?: Resolver<Maybe<ResolversTypes['EmptyTrips']>, ParentType, ContextType, Partial<QueryEmptyTripsArgs>>;
  entityDocument?: Resolver<Maybe<ResolversTypes['DocumentDescription']>, ParentType, ContextType, RequireFields<QueryEntityDocumentArgs, 'uuid'>>;
  estimateCostItem?: Resolver<Maybe<ResolversTypes['CostItem']>, ParentType, ContextType, Partial<QueryEstimateCostItemArgs>>;
  eventsEtc?: Resolver<Maybe<ResolversTypes['EventsEtc']>, ParentType, ContextType, Partial<QueryEventsEtcArgs>>;
  eventsVoucher?: Resolver<Maybe<ResolversTypes['EventVouchers']>, ParentType, ContextType, Partial<QueryEventsVoucherArgs>>;
  exchangeRate?: Resolver<Maybe<ResolversTypes['ExchangeRate']>, ParentType, ContextType, Partial<QueryExchangeRateArgs>>;
  exchangeRates?: Resolver<Maybe<ResolversTypes['ExchangeRates']>, ParentType, ContextType, Partial<QueryExchangeRatesArgs>>;
  faf?: Resolver<Maybe<ResolversTypes['Faf']>, ParentType, ContextType, Partial<QueryFafArgs>>;
  fafs?: Resolver<Maybe<ResolversTypes['Fafs']>, ParentType, ContextType, Partial<QueryFafsArgs>>;
  flowTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  fuel?: Resolver<Maybe<ResolversTypes['Fuel']>, ParentType, ContextType, RequireFields<QueryFuelArgs, 'uuid'>>;
  fuels?: Resolver<Maybe<ResolversTypes['Fuels']>, ParentType, ContextType, RequireFields<QueryFuelsArgs, 'input'>>;
  gateLog?: Resolver<Maybe<ResolversTypes['GateLog']>, ParentType, ContextType, RequireFields<QueryGateLogArgs, 'uuid'>>;
  gateLogs?: Resolver<Maybe<ResolversTypes['GateLogs']>, ParentType, ContextType, RequireFields<QueryGateLogsArgs, 'input'>>;
  getBaseCompanyAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  getBaseCompanyTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProcessTypes']>>>, ParentType, ContextType>;
  getLastNode?: Resolver<Maybe<Array<Maybe<ResolversTypes['TraversedJourney']>>>, ParentType, ContextType, Partial<QueryGetLastNodeArgs>>;
  getPushNotification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, Partial<QueryGetPushNotificationArgs>>;
  getSettingAreaCodeInputRequirement?: Resolver<Maybe<ResolversTypes['BCSettingAreaCode']>, ParentType, ContextType>;
  getTimezones?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  getTransportRate?: Resolver<Maybe<ResolversTypes['TransportRateDetails']>, ParentType, ContextType, RequireFields<QueryGetTransportRateArgs, 'options' | 'selector'>>;
  getTypeCategories?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProcessTypeCategories']>>>, ParentType, ContextType, RequireFields<QueryGetTypeCategoriesArgs, 'processType'>>;
  getUserTimeout?: Resolver<Maybe<ResolversTypes['UserTimeout']>, ParentType, ContextType>;
  getUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType, Partial<QueryGetUsersArgs>>;
  glCode?: Resolver<Maybe<ResolversTypes['GlCode']>, ParentType, ContextType, RequireFields<QueryGlCodeArgs, 'uuid'>>;
  glCodes?: Resolver<Maybe<ResolversTypes['GlCodes']>, ParentType, ContextType, Partial<QueryGlCodesArgs>>;
  group?: Resolver<Maybe<ResolversTypes['Group']>, ParentType, ContextType, RequireFields<QueryGroupArgs, 'uuid'>>;
  groups?: Resolver<Maybe<ResolversTypes['Groups']>, ParentType, ContextType, Partial<QueryGroupsArgs>>;
  incentive?: Resolver<Maybe<ResolversTypes['Incentive']>, ParentType, ContextType, RequireFields<QueryIncentiveArgs, 'uuid'>>;
  incentiveRate?: Resolver<Maybe<ResolversTypes['IncentiveRate']>, ParentType, ContextType, RequireFields<QueryIncentiveRateArgs, 'uuid'>>;
  incentiveRates?: Resolver<Maybe<ResolversTypes['IncentiveRates']>, ParentType, ContextType, Partial<QueryIncentiveRatesArgs>>;
  incentiveType?: Resolver<Maybe<ResolversTypes['IncentiveType']>, ParentType, ContextType, RequireFields<QueryIncentiveTypeArgs, 'uuid'>>;
  incentiveTypes?: Resolver<Maybe<ResolversTypes['IncentiveTypes']>, ParentType, ContextType, Partial<QueryIncentiveTypesArgs>>;
  incentiveVoucher?: Resolver<Maybe<ResolversTypes['IncentiveVoucher']>, ParentType, ContextType, Partial<QueryIncentiveVoucherArgs>>;
  incentiveVouchers?: Resolver<Maybe<ResolversTypes['IncentiveVouchers']>, ParentType, ContextType, RequireFields<QueryIncentiveVouchersArgs, 'input'>>;
  incentiveVouchersJson?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<QueryIncentiveVouchersJsonArgs>>;
  incentives?: Resolver<Maybe<ResolversTypes['Incentives']>, ParentType, ContextType, RequireFields<QueryIncentivesArgs, 'input'>>;
  integrationDetail?: Resolver<Maybe<ResolversTypes['IntegrationDetail']>, ParentType, ContextType, RequireFields<QueryIntegrationDetailArgs, 'uuid'>>;
  integrationDetails?: Resolver<Maybe<ResolversTypes['IntegrationDetails']>, ParentType, ContextType, Partial<QueryIntegrationDetailsArgs>>;
  integrationLog?: Resolver<Maybe<ResolversTypes['IntegrationLog']>, ParentType, ContextType, RequireFields<QueryIntegrationLogArgs, 'uuid'>>;
  integrationLogs?: Resolver<Maybe<ResolversTypes['IntegrationLogs']>, ParentType, ContextType, Partial<QueryIntegrationLogsArgs>>;
  integrationMapping?: Resolver<Maybe<ResolversTypes['IntegrationMapping']>, ParentType, ContextType, Partial<QueryIntegrationMappingArgs>>;
  integrationMappings?: Resolver<Maybe<ResolversTypes['IntegrationMappings']>, ParentType, ContextType, Partial<QueryIntegrationMappingsArgs>>;
  integrations?: Resolver<Maybe<ResolversTypes['Integrations']>, ParentType, ContextType, Partial<QueryIntegrationsArgs>>;
  inviteCompanyAccess?: Resolver<Maybe<ResolversTypes['InviteCompanyAccess']>, ParentType, ContextType, RequireFields<QueryInviteCompanyAccessArgs, 'uuid'>>;
  inviteCompanyAccesses?: Resolver<Maybe<ResolversTypes['InviteCompanyAccesses']>, ParentType, ContextType, Partial<QueryInviteCompanyAccessesArgs>>;
  job?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType, RequireFields<QueryJobArgs, 'uuid'>>;
  jobOption?: Resolver<Maybe<ResolversTypes['JobOption']>, ParentType, ContextType, Partial<QueryJobOptionArgs>>;
  jobOptions?: Resolver<Maybe<ResolversTypes['JobOptions']>, ParentType, ContextType, Partial<QueryJobOptionsArgs>>;
  jobSummaries?: Resolver<Maybe<ResolversTypes['JobSummaries']>, ParentType, ContextType, Partial<QueryJobSummariesArgs>>;
  jobSummary?: Resolver<Maybe<ResolversTypes['JobSummary']>, ParentType, ContextType, Partial<QueryJobSummaryArgs>>;
  jobType?: Resolver<Maybe<ResolversTypes['JobType']>, ParentType, ContextType, Partial<QueryJobTypeArgs>>;
  jobTypes?: Resolver<Maybe<ResolversTypes['JobTypes']>, ParentType, ContextType, Partial<QueryJobTypesArgs>>;
  jobs?: Resolver<Maybe<ResolversTypes['Jobs']>, ParentType, ContextType, RequireFields<QueryJobsArgs, 'bookingUuid'>>;
  jobsSearch?: Resolver<Maybe<ResolversTypes['Jobs']>, ParentType, ContextType, Partial<QueryJobsSearchArgs>>;
  jobsSearchJson?: Resolver<Maybe<ResolversTypes['JobsJson']>, ParentType, ContextType, Partial<QueryJobsSearchJsonArgs>>;
  legActivities?: Resolver<Maybe<ResolversTypes['LegActivities']>, ParentType, ContextType, RequireFields<QueryLegActivitiesArgs, 'input'>>;
  legSummaries?: Resolver<Maybe<ResolversTypes['LegSummaries']>, ParentType, ContextType, Partial<QueryLegSummariesArgs>>;
  legSummary?: Resolver<Maybe<ResolversTypes['LegSummary']>, ParentType, ContextType, Partial<QueryLegSummaryArgs>>;
  manifest?: Resolver<Maybe<ResolversTypes['Manifest']>, ParentType, ContextType, Partial<QueryManifestArgs>>;
  manifests?: Resolver<Maybe<ResolversTypes['Manifests']>, ParentType, ContextType, Partial<QueryManifestsArgs>>;
  monitoringCompanies?: Resolver<Maybe<ResolversTypes['Companies']>, ParentType, ContextType, Partial<QueryMonitoringCompaniesArgs>>;
  nodeTree?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<QueryNodeTreeArgs>>;
  payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType, Partial<QueryPaymentArgs>>;
  payments?: Resolver<Maybe<ResolversTypes['Payments']>, ParentType, ContextType, Partial<QueryPaymentsArgs>>;
  permission?: Resolver<Maybe<Array<Maybe<ResolversTypes['Permission']>>>, ParentType, ContextType, Partial<QueryPermissionArgs>>;
  permissionIsAllowed?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType, Partial<QueryPermissionIsAllowedArgs>>;
  permissionTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['Permission']>>>, ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['Place']>, ParentType, ContextType, Partial<QueryPlaceArgs>>;
  places?: Resolver<Maybe<Array<Maybe<ResolversTypes['PlaceResults']>>>, ParentType, ContextType, Partial<QueryPlacesArgs>>;
  port?: Resolver<Maybe<ResolversTypes['Port']>, ParentType, ContextType, RequireFields<QueryPortArgs, 'uuid'>>;
  portalCompanies?: Resolver<Maybe<ResolversTypes['PortalCompanies']>, ParentType, ContextType, Partial<QueryPortalCompaniesArgs>>;
  portalCompany?: Resolver<Maybe<ResolversTypes['PortalCompany']>, ParentType, ContextType, Partial<QueryPortalCompanyArgs>>;
  ports?: Resolver<Maybe<ResolversTypes['Ports']>, ParentType, ContextType, Partial<QueryPortsArgs>>;
  precomputeEmptyTrip?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<QueryPrecomputeEmptyTripArgs>>;
  processFlowCreate?: Resolver<Maybe<ResolversTypes['ProcessFlowCreateType']>, ParentType, ContextType, RequireFields<QueryProcessFlowCreateArgs, 'type'>>;
  processFlowDashboard?: Resolver<Maybe<Array<Maybe<ResolversTypes['ProcessFlow']>>>, ParentType, ContextType, RequireFields<QueryProcessFlowDashboardArgs, 'type'>>;
  processFlowMap?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType, RequireFields<QueryProcessFlowMapArgs, 'process' | 'type'>>;
  processFlowTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  processReportPortalCompany?: Resolver<Maybe<ResolversTypes['Response']>, ParentType, ContextType>;
  processVehiclePlan?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<QueryProcessVehiclePlanArgs>>;
  profile?: Resolver<Maybe<ResolversTypes['Profile']>, ParentType, ContextType>;
  quotation?: Resolver<Maybe<ResolversTypes['Quotation']>, ParentType, ContextType, RequireFields<QueryQuotationArgs, 'uuid'>>;
  quotations?: Resolver<Maybe<ResolversTypes['Quotations']>, ParentType, ContextType, Partial<QueryQuotationsArgs>>;
  rates?: Resolver<Maybe<Array<Maybe<ResolversTypes['Rate']>>>, ParentType, ContextType, Partial<QueryRatesArgs>>;
  report?: Resolver<Maybe<ResolversTypes['Report']>, ParentType, ContextType, Partial<QueryReportArgs>>;
  reportFields?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportField']>>>, ParentType, ContextType, Partial<QueryReportFieldsArgs>>;
  reportJobSummaries?: Resolver<Maybe<ResolversTypes['ReportJobSummaries']>, ParentType, ContextType, Partial<QueryReportJobSummariesArgs>>;
  reportJobSummary?: Resolver<Maybe<ResolversTypes['ReportJobSummary']>, ParentType, ContextType, Partial<QueryReportJobSummaryArgs>>;
  reportList?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportSummary']>>>, ParentType, ContextType>;
  reportMeta?: Resolver<Maybe<ResolversTypes['ReportMeta']>, ParentType, ContextType, RequireFields<QueryReportMetaArgs, 'key'>>;
  reports?: Resolver<Maybe<ResolversTypes['Reports']>, ParentType, ContextType, Partial<QueryReportsArgs>>;
  requestEmailResults?: Resolver<Maybe<Array<Maybe<ResolversTypes['RequestEmailResultsType']>>>, ParentType, ContextType, RequireFields<QueryRequestEmailResultsArgs, 'uuid'>>;
  requestTemplate?: Resolver<Maybe<ResolversTypes['RequisitionTemplate']>, ParentType, ContextType, Partial<QueryRequestTemplateArgs>>;
  reverseGeocode?: Resolver<Maybe<ResolversTypes['Geocodes']>, ParentType, ContextType, Partial<QueryReverseGeocodeArgs>>;
  roles?: Resolver<Maybe<ResolversTypes['Roles']>, ParentType, ContextType, Partial<QueryRolesArgs>>;
  rootNode?: Resolver<Maybe<ResolversTypes['RootNode']>, ParentType, ContextType, Partial<QueryRootNodeArgs>>;
  rootNodes?: Resolver<Maybe<ResolversTypes['RootNodes']>, ParentType, ContextType, Partial<QueryRootNodesArgs>>;
  seaRoute?: Resolver<Maybe<ResolversTypes['SeaRoute']>, ParentType, ContextType>;
  search?: Resolver<Maybe<ResolversTypes['Search']>, ParentType, ContextType, Partial<QuerySearchArgs>>;
  searchFmsCompanies?: Resolver<Maybe<Array<Maybe<ResolversTypes['FmsCompany']>>>, ParentType, ContextType, RequireFields<QuerySearchFmsCompaniesArgs, 'query'>>;
  serviceTypes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  setting?: Resolver<Maybe<ResolversTypes['BaseCompanySetting']>, ParentType, ContextType, Partial<QuerySettingArgs>>;
  settings?: Resolver<Maybe<ResolversTypes['BaseCompanySettings']>, ParentType, ContextType, Partial<QuerySettingsArgs>>;
  shift?: Resolver<Maybe<ResolversTypes['Shift']>, ParentType, ContextType, Partial<QueryShiftArgs>>;
  shifts?: Resolver<Maybe<ResolversTypes['Shifts']>, ParentType, ContextType, RequireFields<QueryShiftsArgs, 'input'>>;
  shiftsJson?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<QueryShiftsJsonArgs>>;
  shippingRates?: Resolver<Maybe<ResolversTypes['ShippingRates']>, ParentType, ContextType, Partial<QueryShippingRatesArgs>>;
  signDriverBehaviorAuth?: Resolver<Maybe<ResolversTypes['DriverBehaviorAuth']>, ParentType, ContextType>;
  superAdmins?: Resolver<Maybe<ResolversTypes['SuperAdmins']>, ParentType, ContextType, Partial<QuerySuperAdminsArgs>>;
  tax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType, RequireFields<QueryTaxArgs, 'uuid'>>;
  taxes?: Resolver<Maybe<ResolversTypes['Taxes']>, ParentType, ContextType, Partial<QueryTaxesArgs>>;
  trailer?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType, RequireFields<QueryTrailerArgs, 'uuid'>>;
  trailers?: Resolver<Maybe<ResolversTypes['TrailersResults']>, ParentType, ContextType, Partial<QueryTrailersArgs>>;
  transportActivities?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportActivityFlow']>>>, ParentType, ContextType, Partial<QueryTransportActivitiesArgs>>;
  transportAreaCode?: Resolver<Maybe<ResolversTypes['TransportAreaCode']>, ParentType, ContextType, Partial<QueryTransportAreaCodeArgs>>;
  transportAreaCodeFromLocation?: Resolver<Maybe<ResolversTypes['TransportAreaCode']>, ParentType, ContextType, Partial<QueryTransportAreaCodeFromLocationArgs>>;
  transportAreaCodes?: Resolver<Maybe<ResolversTypes['TransportAreaCodes']>, ParentType, ContextType, Partial<QueryTransportAreaCodesArgs>>;
  transportJob?: Resolver<Maybe<ResolversTypes['TransportJob']>, ParentType, ContextType, Partial<QueryTransportJobArgs>>;
  transportJobType?: Resolver<Maybe<ResolversTypes['TransportJobType']>, ParentType, ContextType, Partial<QueryTransportJobTypeArgs>>;
  transportJobTypes?: Resolver<Maybe<ResolversTypes['TransportJobTypes']>, ParentType, ContextType, Partial<QueryTransportJobTypesArgs>>;
  transportJobs?: Resolver<Maybe<ResolversTypes['TransportJobs']>, ParentType, ContextType, RequireFields<QueryTransportJobsArgs, 'input'>>;
  transportJobsJson?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<QueryTransportJobsJsonArgs>>;
  transportRate?: Resolver<Maybe<ResolversTypes['TransportRate']>, ParentType, ContextType, Partial<QueryTransportRateArgs>>;
  transportRates?: Resolver<Maybe<ResolversTypes['TransportRates']>, ParentType, ContextType, Partial<QueryTransportRatesArgs>>;
  transportReportJob?: Resolver<Maybe<ResolversTypes['TransportReportJob']>, ParentType, ContextType, Partial<QueryTransportReportJobArgs>>;
  transportReportJobs?: Resolver<Maybe<ResolversTypes['TransportReportJobs']>, ParentType, ContextType, RequireFields<QueryTransportReportJobsArgs, 'input'>>;
  transportReportJobsJson?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<QueryTransportReportJobsJsonArgs>>;
  transportReportLegActivities?: Resolver<Maybe<ResolversTypes['TransportReportLegActivities']>, ParentType, ContextType, RequireFields<QueryTransportReportLegActivitiesArgs, 'input'>>;
  transportReportLegActivitiesJson?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, RequireFields<QueryTransportReportLegActivitiesJsonArgs, 'input'>>;
  transportReportTrip?: Resolver<Maybe<ResolversTypes['TransportReportTrip']>, ParentType, ContextType, Partial<QueryTransportReportTripArgs>>;
  transportReportTrips?: Resolver<Maybe<ResolversTypes['TransportReportTrips']>, ParentType, ContextType, RequireFields<QueryTransportReportTripsArgs, 'input'>>;
  transportReportTripsJson?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<QueryTransportReportTripsJsonArgs>>;
  transportZone?: Resolver<Maybe<ResolversTypes['TransportZone']>, ParentType, ContextType, Partial<QueryTransportZoneArgs>>;
  transportZones?: Resolver<Maybe<ResolversTypes['TransportZones']>, ParentType, ContextType, Partial<QueryTransportZonesArgs>>;
  trip?: Resolver<Maybe<ResolversTypes['Trip']>, ParentType, ContextType, Partial<QueryTripArgs>>;
  tripSummaries?: Resolver<Maybe<ResolversTypes['TripSummaries']>, ParentType, ContextType, Partial<QueryTripSummariesArgs>>;
  tripSummary?: Resolver<Maybe<ResolversTypes['TripSummary']>, ParentType, ContextType, Partial<QueryTripSummaryArgs>>;
  trips?: Resolver<Maybe<ResolversTypes['Trips']>, ParentType, ContextType, Partial<QueryTripsArgs>>;
  truckAlerts?: Resolver<Maybe<ResolversTypes['TruckAlerts']>, ParentType, ContextType, Partial<QueryTruckAlertsArgs>>;
  truckLocation?: Resolver<Maybe<Array<Maybe<ResolversTypes['TruckLocation']>>>, ParentType, ContextType, Partial<QueryTruckLocationArgs>>;
  truckLocationByTrips?: Resolver<Maybe<Array<Maybe<ResolversTypes['TripLocations']>>>, ParentType, ContextType, Partial<QueryTruckLocationByTripsArgs>>;
  truckLocationByTripsV2?: Resolver<Maybe<Array<Maybe<ResolversTypes['TripLocationsV2']>>>, ParentType, ContextType, Partial<QueryTruckLocationByTripsV2Args>>;
  truckLocationLatest?: Resolver<Maybe<ResolversTypes['TruckLocation']>, ParentType, ContextType, Partial<QueryTruckLocationLatestArgs>>;
  truckLocationLatestV2?: Resolver<Maybe<ResolversTypes['TruckLocationV2']>, ParentType, ContextType, Partial<QueryTruckLocationLatestV2Args>>;
  truckLocationV2?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType, Partial<QueryTruckLocationV2Args>>;
  trucksSpeeds?: Resolver<Maybe<Array<Maybe<ResolversTypes['TruckSpeeds']>>>, ParentType, ContextType, Partial<QueryTrucksSpeedsArgs>>;
  trucksSpeedsV2?: Resolver<Maybe<Array<Maybe<ResolversTypes['TruckSpeeds']>>>, ParentType, ContextType, Partial<QueryTrucksSpeedsV2Args>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, Partial<QueryUserArgs>>;
  userPreferences?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserPreference']>>>, ParentType, ContextType, Partial<QueryUserPreferencesArgs>>;
  users?: Resolver<Maybe<ResolversTypes['Users']>, ParentType, ContextType, Partial<QueryUsersArgs>>;
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType, RequireFields<QueryVehicleArgs, 'uuid'>>;
  vehicles?: Resolver<Maybe<ResolversTypes['VehiclesResults']>, ParentType, ContextType, Partial<QueryVehiclesArgs>>;
  vessels?: Resolver<Maybe<Array<Maybe<ResolversTypes['Vessel']>>>, ParentType, ContextType, Partial<QueryVesselsArgs>>;
  viewer?: Resolver<Maybe<ResolversTypes['Viewer']>, ParentType, ContextType, Partial<QueryViewerArgs>>;
  volumeUnit?: Resolver<Maybe<ResolversTypes['VolumeUnit']>, ParentType, ContextType, Partial<QueryVolumeUnitArgs>>;
  volumeUnits?: Resolver<Maybe<ResolversTypes['VolumeUnits']>, ParentType, ContextType, Partial<QueryVolumeUnitsArgs>>;
  voucher?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType, Partial<QueryVoucherArgs>>;
  voucherItems?: Resolver<Maybe<Array<Maybe<ResolversTypes['VoucherItem']>>>, ParentType, ContextType, Partial<QueryVoucherItemsArgs>>;
  vouchers?: Resolver<Maybe<ResolversTypes['Vouchers']>, ParentType, ContextType, Partial<QueryVouchersArgs>>;
  vouchersSearch?: Resolver<Maybe<ResolversTypes['Vouchers']>, ParentType, ContextType, Partial<QueryVouchersSearchArgs>>;
  vouchersSearchJson?: Resolver<Maybe<ResolversTypes['VouchersJson']>, ParentType, ContextType, Partial<QueryVouchersSearchJsonArgs>>;
  weightUnit?: Resolver<Maybe<ResolversTypes['WeightUnit']>, ParentType, ContextType, Partial<QueryWeightUnitArgs>>;
  weightUnits?: Resolver<Maybe<ResolversTypes['WeightUnits']>, ParentType, ContextType, Partial<QueryWeightUnitsArgs>>;
  yard?: Resolver<Maybe<ResolversTypes['Yard']>, ParentType, ContextType, RequireFields<QueryYardArgs, 'uuid'>>;
  yards?: Resolver<Maybe<ResolversTypes['Yards']>, ParentType, ContextType, RequireFields<QueryYardsArgs, 'input'>>;
};

export type QuotationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Quotation'] = ResolversParentTypes['Quotation']> = {
  buyerAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  buyerContact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  buyers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  editBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  expiry?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  note?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quotationItems?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuotationItem']>>>, ParentType, ContextType>;
  quotationNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sellerAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  sellerContact?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  sellers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Company']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['QuotationStatus']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuotationTagTypes']>>>, ParentType, ContextType>;
  term?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuotationItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuotationItem'] = ResolversParentTypes['QuotationItem']> = {
  chargeItem?: Resolver<Maybe<ResolversTypes['ChargeItem']>, ParentType, ContextType>;
  containerType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  costCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  costRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costRateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  costTax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  maxChargeAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  minChargeAmount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quotationUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  rateType?: Resolver<Maybe<ResolversTypes['ChargeRateType']>, ParentType, ContextType>;
  sellCurrency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  sellRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sellRateId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sellTax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['QuotationItemTags']>>>, ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuotationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Quotations'] = ResolversParentTypes['Quotations']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Quotation']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Rate'] = ResolversParentTypes['Rate']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateLogItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateLogItem'] = ResolversParentTypes['RateLogItem']> = {
  currentTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateValue'] = ResolversParentTypes['RateValue']> = {
  brackets?: Resolver<Maybe<Array<Maybe<ResolversTypes['RateValueBracket']>>>, ParentType, ContextType>;
  chargeItemCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  op?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  operationIndex?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  unitField?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  val?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RateValueBracketResolvers<ContextType = any, ParentType extends ResolversParentTypes['RateValueBracket'] = ResolversParentTypes['RateValueBracket']> = {
  maxCutoff?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  minCutoff?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  op?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  val?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportResolvers<ContextType = any, ParentType extends ResolversParentTypes['Report'] = ResolversParentTypes['Report']> = {
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportCategories']>>>, ParentType, ContextType>;
  columns?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportColumn']>>>, ParentType, ContextType>;
  data?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['ReportModel']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  portalCompanies?: Resolver<Maybe<Array<Maybe<ResolversTypes['PortalCompany']>>>, ParentType, ContextType>;
  sorts?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ReportStatus']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportColumnResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportColumn'] = ResolversParentTypes['ReportColumn']> = {
  formatFnc?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportFormatFnc']>>>, ParentType, ContextType>;
  formatParams?: Resolver<Maybe<Array<Maybe<Array<Maybe<ResolversTypes['String']>>>>>, ParentType, ContextType>;
  header?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportField'] = ResolversParentTypes['ReportField']> = {
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportFilterResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportFilter'] = ResolversParentTypes['ReportFilter']> = {
  ctrlType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dataType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  defaultShow?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  defaultValues?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  isArray?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportJobSummariesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportJobSummaries'] = ResolversParentTypes['ReportJobSummaries']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportJobSummary']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportJobSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportJobSummary'] = ResolversParentTypes['ReportJobSummary']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  bcUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  depts?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportJobSummaryDept']>>>, ParentType, ContextType>;
  drUtil?: Resolver<Maybe<Array<Maybe<ResolversTypes['DriverUtilDept']>>>, ParentType, ContextType>;
  jobUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  metrics?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  pmUtil?: Resolver<Maybe<Array<Maybe<ResolversTypes['PmUtilDept']>>>, ParentType, ContextType>;
  ts?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  zones?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportJobSummaryZone']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportJobSummaryDeptResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportJobSummaryDept'] = ResolversParentTypes['ReportJobSummaryDept']> = {
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dept?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  revenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sell?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  teu?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  trips?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportJobSummaryZoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportJobSummaryZone'] = ResolversParentTypes['ReportJobSummaryZone']> = {
  cost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  revenue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sell?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  teu?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trips?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportMetaResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportMeta'] = ResolversParentTypes['ReportMeta']> = {
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportCategories']>>>, ParentType, ContextType>;
  columns?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportColumn']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  filters?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportFilter']>>>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sorts?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportPortalCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportPortalCompany'] = ResolversParentTypes['ReportPortalCompany']> = {
  hours?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  portalCompanyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  report?: Resolver<Maybe<ResolversTypes['Report']>, ParentType, ContextType>;
  reportUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReportSummary'] = ResolversParentTypes['ReportSummary']> = {
  categories?: Resolver<Maybe<Array<Maybe<ResolversTypes['ReportCategories']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReportsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Reports'] = ResolversParentTypes['Reports']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Report']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestResolvers<ContextType = any, ParentType extends ResolversParentTypes['Request'] = ResolversParentTypes['Request']> = {
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  meta?: Resolver<Maybe<ResolversTypes['RequestMetaType']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestEmailResultsTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestEmailResultsType'] = ResolversParentTypes['RequestEmailResultsType']> = {
  event?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  logLevel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  recipient?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timestamp?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestMetaTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestMetaType'] = ResolversParentTypes['RequestMetaType']> = {
  attachments?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  jobs?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequestTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequestTemplate'] = ResolversParentTypes['RequestTemplate']> = {
  template?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vendorType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RequisitionTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['RequisitionTemplate'] = ResolversParentTypes['RequisitionTemplate']> = {
  bcc?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  cc?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  fields?: Resolver<Maybe<ResolversTypes['EmailRequestFields']>, ParentType, ContextType>;
  template?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  to?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['RequestType']>, ParentType, ContextType>;
  uuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['Response'] = ResolversParentTypes['Response']> = {
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  success?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ReverseGeoAddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['ReverseGeoAddress'] = ResolversParentTypes['ReverseGeoAddress']> = {
  city?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  countryName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  county?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  district?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  state?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RoleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Role'] = ResolversParentTypes['Role']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  editBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  editByUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['RoleStatus']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RolesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Roles'] = ResolversParentTypes['Roles']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Role']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RootNodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['RootNode'] = ResolversParentTypes['RootNode']> = {
  _id?: Resolver<Maybe<ResolversTypes['ObjectID']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  keyPreference?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ActiveStatus']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type RootNodesResolvers<ContextType = any, ParentType extends ResolversParentTypes['RootNodes'] = ResolversParentTypes['RootNodes']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['PageInfo']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['RootNode']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['SRate'] = ResolversParentTypes['SRate']> = {
  currency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeaPathResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeaPath'] = ResolversParentTypes['SeaPath']> = {
  distance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  distanceSeca?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fromPort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  toPort?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  waypoint?: Resolver<Maybe<Array<Maybe<ResolversTypes['Waypoint']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeaRouteResolvers<ContextType = any, ParentType extends ResolversParentTypes['SeaRoute'] = ResolversParentTypes['SeaRoute']> = {
  code?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  crossSeca?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dayLimit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  message?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  section?: Resolver<Maybe<Array<Maybe<ResolversTypes['SeaPath']>>>, ParentType, ContextType>;
  todayUsed?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalDistance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalSecaDistance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SeaportResolvers<ContextType = any, ParentType extends ResolversParentTypes['Seaport'] = ResolversParentTypes['Seaport']> = {
  alternativeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alternativeNameCaps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dayLightSavings?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nameCaps?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  portsDbId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timezone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  unlocode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchResolvers<ContextType = any, ParentType extends ResolversParentTypes['Search'] = ResolversParentTypes['Search']> = {
  hits?: Resolver<Maybe<Array<Maybe<ResolversTypes['SearchResult']>>>, ParentType, ContextType>;
  page?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  params?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  query?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchResultResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchResult'] = ResolversParentTypes['SearchResult']> = {
  __resolveType: TypeResolveFn<'Booking' | 'Voucher', ParentType, ContextType>;
};

export type ServiceResolvers<ContextType = any, ParentType extends ResolversParentTypes['Service'] = ResolversParentTypes['Service']> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SettingResolvers<ContextType = any, ParentType extends ResolversParentTypes['Setting'] = ResolversParentTypes['Setting']> = {
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftResolvers<ContextType = any, ParentType extends ResolversParentTypes['Shift'] = ResolversParentTypes['Shift']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  approvedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  approvedFinanceAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  approvedFinanceBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  approvedFinanceUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  approvedUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  bookingUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  breakDuration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  breaks?: Resolver<Maybe<Array<Maybe<ResolversTypes['ShiftBreak']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  driver?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType>;
  driverUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  duration?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  incentiveCategories?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  incentiveCategorySum?: Resolver<Maybe<Array<Maybe<ResolversTypes['IncentiveCategorySum']>>>, ParentType, ContextType>;
  incentiveTypeSum?: Resolver<Maybe<Array<Maybe<ResolversTypes['IncentiveTypeSum']>>>, ParentType, ContextType>;
  incentiveTypeUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  incentives?: Resolver<Maybe<Array<Maybe<ResolversTypes['Incentive']>>>, ParentType, ContextType>;
  jobUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  legUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  no?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  references?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['ShiftStatus']>, ParentType, ContextType>;
  tripUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vehicle?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>;
  vehicleUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftBreakResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShiftBreak'] = ResolversParentTypes['ShiftBreak']> = {
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShiftsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Shifts'] = ResolversParentTypes['Shifts']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Shift']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingRate'] = ResolversParentTypes['ShippingRate']> = {
  days?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  effective?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  expiry?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gp20?: Resolver<Maybe<ResolversTypes['SRate']>, ParentType, ContextType>;
  gp40?: Resolver<Maybe<ResolversTypes['SRate']>, ParentType, ContextType>;
  hc40?: Resolver<Maybe<ResolversTypes['SRate']>, ParentType, ContextType>;
  liner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  route?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingRateTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingRateType'] = ResolversParentTypes['ShippingRateType']> = {
  fourtyContainer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fourtyFiveHCContainer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fourtyHCContainer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  twentyContainer?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  volume?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ShippingRatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['ShippingRates'] = ResolversParentTypes['ShippingRates']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['ShippingRate']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SuperAdminsResolvers<ContextType = any, ParentType extends ResolversParentTypes['SuperAdmins'] = ResolversParentTypes['SuperAdmins']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxResolvers<ContextType = any, ParentType extends ResolversParentTypes['Tax'] = ResolversParentTypes['Tax']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exemptionDocumentUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  percentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxesResolvers<ContextType = any, ParentType extends ResolversParentTypes['Taxes'] = ResolversParentTypes['Taxes']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Tax']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['Template'] = ResolversParentTypes['Template']> = {
  content?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrailerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Trailer'] = ResolversParentTypes['Trailer']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityCompany']>>>, ParentType, ContextType>;
  customerAddress?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityCompanyAddress']>>>, ParentType, ContextType>;
  customers?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityCompany']>>>, ParentType, ContextType>;
  department?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentDescription']>>>, ParentType, ContextType>;
  entityException?: Resolver<Maybe<ResolversTypes['EntityExceptionOutcome']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Attachment']>, ParentType, ContextType>;
  loadCapacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  make?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufactureYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerType?: Resolver<Maybe<ResolversTypes['TrailerOwnerType']>, ParentType, ContextType>;
  registration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registrationYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['TrailerStatus']>, ParentType, ContextType>;
  subContractors?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityCompany']>>>, ParentType, ContextType>;
  tareWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  trailerType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TrailersResultsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TrailersResults'] = ResolversParentTypes['TrailersResults']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Trailer']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportResolvers<ContextType = any, ParentType extends ResolversParentTypes['Transport'] = ResolversParentTypes['Transport']> = {
  company?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportActivityResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportActivity'] = ResolversParentTypes['TransportActivity']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  compile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  driverCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverEntity?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType>;
  driverNick?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  short?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerEntity?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType>;
  trailerNick?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  ts?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['TransportActivityType']>, ParentType, ContextType>;
  vehicleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleDepartments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  vehicleEntity?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>;
  vehicleNick?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportActivityFlowResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportActivityFlow'] = ResolversParentTypes['TransportActivityFlow']> = {
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  compile?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  next?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  shipXTrigger?: Resolver<Maybe<ResolversTypes['TransportActivityShipXTrigger']>, ParentType, ContextType>;
  short?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportActivityShipXTriggerResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportActivityShipXTrigger'] = ResolversParentTypes['TransportActivityShipXTrigger']> = {
  date?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportAreaCodeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportAreaCode'] = ResolversParentTypes['TransportAreaCode']> = {
  category?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Geometry']>, ParentType, ContextType>;
  locationPolygon?: Resolver<Maybe<ResolversTypes['GeometryPolygon']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  zone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportAreaCodesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportAreaCodes'] = ResolversParentTypes['TransportAreaCodes']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportAreaCode']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportJobResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportJob'] = ResolversParentTypes['TransportJob']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  _tags?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  assistantUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  assistants?: Resolver<Maybe<Array<Maybe<ResolversTypes['Driver']>>>, ParentType, ContextType>;
  billToCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToDept?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  billToUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  booking?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType>;
  bookingDepts?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  bookingDetails?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  bookingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookingStatus?: Resolver<Maybe<ResolversTypes['BookingStatus']>, ParentType, ContextType>;
  bookingTags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  bookingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookingUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  conDept?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consigneeAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  consigneeCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consigneeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consigneeRequiredDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  consigneeUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  dFirstTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dLastTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dept?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverEntity?: Resolver<Maybe<ResolversTypes['Driver']>, ParentType, ContextType>;
  driverIc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverPhoneNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  endOut?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstTs?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  fromAddressUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  fromCompanyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  incentiveVouchers?: Resolver<Maybe<Array<Maybe<ResolversTypes['IncentiveVoucher']>>>, ParentType, ContextType>;
  jobDetails?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  jobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobStatus?: Resolver<Maybe<ResolversTypes['JobStatus']>, ParentType, ContextType>;
  jobType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  lastTs?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  legStatus?: Resolver<Maybe<ResolversTypes['LegStatus']>, ParentType, ContextType>;
  legUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  manifestUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  no?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  planEnd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  planStart?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  references?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sequenceTotal?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  shipDept?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipOutDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  shipperAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  shipperCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipperName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipperRequiredDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  shipperUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  startOut?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  tLastTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  toAddressUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  toCompanyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  trailerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerEntity?: Resolver<Maybe<ResolversTypes['Trailer']>, ParentType, ContextType>;
  trailerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  transportName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transportSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  transportUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  tripDetails?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  tripFormat?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tripReferences?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  tripRemarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tripSeal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tripSequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tripStatus?: Resolver<Maybe<ResolversTypes['TripStatus']>, ParentType, ContextType>;
  tripType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tripUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['LegType']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vFirstTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vLastTs?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleDepartments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  vehicleEntity?: Resolver<Maybe<ResolversTypes['Vehicle']>, ParentType, ContextType>;
  vehicleName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportJobTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportJobType'] = ResolversParentTypes['TransportJobType']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sorting?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportJobTypesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportJobTypes'] = ResolversParentTypes['TransportJobTypes']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportJobType']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportJobsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportJobs'] = ResolversParentTypes['TransportJobs']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportJob']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportRateResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportRate'] = ResolversParentTypes['TransportRate']> = {
  customer?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  distance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rates?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportRateDetails']>>>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vendor?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportRateDetailsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportRateDetails'] = ResolversParentTypes['TransportRateDetails']> = {
  cargoType?: Resolver<Maybe<ResolversTypes['TransportRateCargoTypes']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rateRule?: Resolver<Maybe<ResolversTypes['ChargeRateRuleType']>, ParentType, ContextType>;
  rateType?: Resolver<Maybe<ResolversTypes['ChargeRateType']>, ParentType, ContextType>;
  rateUom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rateValue?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transactionType?: Resolver<Maybe<ResolversTypes['TransportRateTransactionType']>, ParentType, ContextType>;
  transportType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportRatesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportRates'] = ResolversParentTypes['TransportRates']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportRate']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportReportJobResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportReportJob'] = ResolversParentTypes['TransportReportJob']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  actualDistanceKm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  actualTravelTimeMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  billToAccount?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  billToCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  billToUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  booking?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType>;
  bookingDepts?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  bookingDetails?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  bookingDocumentsSummary?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookingStatus?: Resolver<Maybe<ResolversTypes['BookingStatus']>, ParentType, ContextType>;
  bookingTags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  bookingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookingUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  consigneeAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  consigneeAddressCache?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  consigneeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consigneeRequiredDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  consigneeUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  costItemAPDraftTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemAPTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemARDraftTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemARTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemCostCurrencies?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  costItemCostLocalTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemCostTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemLocalCurrency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  costItemSellCurrencies?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  costItemSellLocalTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemSellTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  driverCodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  driverEntities?: Resolver<Maybe<Array<Maybe<ResolversTypes['Driver']>>>, ParentType, ContextType>;
  driverNames?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  driverUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  estimatedDistanceKm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  estimatedTravelTimeMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  incentiveVoucherDates?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  incentiveVoucherNos?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  incentiveVoucherStatuses?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  incentiveVoucherTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  incentiveVoucherUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  incentiveVouchers?: Resolver<Maybe<Array<Maybe<ResolversTypes['IncentiveVoucher']>>>, ParentType, ContextType>;
  jobDetails?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  jobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobStatus?: Resolver<Maybe<ResolversTypes['JobStatus']>, ParentType, ContextType>;
  jobType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  legStatuses?: Resolver<Maybe<Array<Maybe<ResolversTypes['LegStatus']>>>, ParentType, ContextType>;
  legUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  no?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  shiftNos?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  shiftUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  shifts?: Resolver<Maybe<Array<Maybe<ResolversTypes['Shift']>>>, ParentType, ContextType>;
  shipperAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  shipperAddressCache?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  shipperName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shipperRequiredDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  shipperUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  trailerCodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  trailerEntities?: Resolver<Maybe<Array<Maybe<ResolversTypes['Trailer']>>>, ParentType, ContextType>;
  trailerNames?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  trailerUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  transportJobs?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportJob']>>>, ParentType, ContextType>;
  transportNames?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  transportSources?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportSource']>>>, ParentType, ContextType>;
  transportUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  tripDetails?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  tripUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  tripsTimings?: Resolver<Maybe<Array<Maybe<ResolversTypes['TripTimings']>>>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vehicleCodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  vehicleDepts?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  vehicleEntities?: Resolver<Maybe<Array<Maybe<ResolversTypes['Vehicle']>>>, ParentType, ContextType>;
  vehicleNames?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  vehicleUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  voucherAccountDates?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherApLocalTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voucherApTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voucherArLocalTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voucherArTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voucherCurrencies?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherIssueDates?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherLocalCurrency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voucherNos?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherNosAp?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherNosAr?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherStatuses?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  vouchers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Voucher']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportReportJobsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportReportJobs'] = ResolversParentTypes['TransportReportJobs']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportReportJob']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportReportLegActivitiesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportReportLegActivities'] = ResolversParentTypes['TransportReportLegActivities']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportReportLegActivity']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportReportLegActivityResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportReportLegActivity'] = ResolversParentTypes['TransportReportLegActivity']> = {
  activityDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  activityType?: Resolver<Maybe<ResolversTypes['TransportActivityType']>, ParentType, ContextType>;
  bookingDepartments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  department?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  driverCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverNick?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  driverUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  editedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  editedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  editedUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  isDriverApp?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  jobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  legSequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  legUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  minuteVariance?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  shipperRequiredDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  trailerCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerNick?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  trailerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  tripSequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tripType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tripUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  usrType?: Resolver<Maybe<ResolversTypes['TransportReportLegActivityUsrType']>, ParentType, ContextType>;
  vehicleCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleDepartments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  vehicleNick?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  vehicleUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportReportTripResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportReportTrip'] = ResolversParentTypes['TransportReportTrip']> = {
  _id?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  actualDistanceKm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  actualTravelTimeMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  billToAccount?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  billToCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  billToSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  billToUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  booking?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType>;
  bookingDepts?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  bookingDetails?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  bookingNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookingStatus?: Resolver<Maybe<ResolversTypes['BookingStatus']>, ParentType, ContextType>;
  bookingTags?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  bookingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bookingUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  consigneeAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  consigneeAddressCache?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  consigneeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  consigneeRequiredDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  consigneeUuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  costItemAPDraftTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemAPTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemARDraftTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemARTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemCostCurrencies?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  costItemCostLocalTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemCostTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemLocalCurrency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  costItemSellCurrencies?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  costItemSellLocalTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costItemSellTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  dateCreated?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  driverCodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  driverEntities?: Resolver<Maybe<Array<Maybe<ResolversTypes['Driver']>>>, ParentType, ContextType>;
  driverNames?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  driverUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  estimatedDistanceKm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  estimatedTravelTimeMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  firstLegStart?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstLegStartOut?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  incentiveVoucherDates?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  incentiveVoucherNos?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  incentiveVoucherStatuses?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  incentiveVoucherTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  incentiveVoucherUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  incentiveVouchers?: Resolver<Maybe<Array<Maybe<ResolversTypes['IncentiveVoucher']>>>, ParentType, ContextType>;
  jobDetails?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  jobNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobStatus?: Resolver<Maybe<ResolversTypes['JobStatus']>, ParentType, ContextType>;
  jobType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  lastLegEnd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastLegEndOut?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  legStatuses?: Resolver<Maybe<Array<Maybe<ResolversTypes['LegStatus']>>>, ParentType, ContextType>;
  legUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  no?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  shiftNos?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  shiftUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  shifts?: Resolver<Maybe<Array<Maybe<ResolversTypes['Shift']>>>, ParentType, ContextType>;
  shipperAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  shipperAddressCache?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  shipperName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  shipperRequiredDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  shipperUuid?: Resolver<ResolversTypes['UUID'], ParentType, ContextType>;
  timeAtConsigneeMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeAtShipperMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  trailerCodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  trailerEntities?: Resolver<Maybe<Array<Maybe<ResolversTypes['Trailer']>>>, ParentType, ContextType>;
  trailerNames?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  trailerUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  transportJobs?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportJob']>>>, ParentType, ContextType>;
  transportNames?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  transportSources?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportSource']>>>, ParentType, ContextType>;
  transportUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  tripSequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tripStatus?: Resolver<Maybe<ResolversTypes['TripStatus']>, ParentType, ContextType>;
  tripType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tripUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vehicleCodes?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  vehicleDepts?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  vehicleEntities?: Resolver<Maybe<Array<Maybe<ResolversTypes['Vehicle']>>>, ParentType, ContextType>;
  vehicleNames?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  vehicleUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  voucherAccountDates?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherApLocalTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voucherApTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voucherArLocalTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voucherArTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  voucherCurrencies?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherIssueDates?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherLocalCurrency?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voucherNos?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherNosAp?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherNosAr?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherStatuses?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  voucherUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  vouchers?: Resolver<Maybe<Array<Maybe<ResolversTypes['Voucher']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportReportTripsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportReportTrips'] = ResolversParentTypes['TransportReportTrips']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportReportTrip']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportZoneResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportZone'] = ResolversParentTypes['TransportZone']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sorting?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TransportZonesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TransportZones'] = ResolversParentTypes['TransportZones']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportZone']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TraversedJourneyResolvers<ContextType = any, ParentType extends ResolversParentTypes['TraversedJourney'] = ResolversParentTypes['TraversedJourney']> = {
  nodeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rootNodeId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TripResolvers<ContextType = any, ParentType extends ResolversParentTypes['Trip'] = ResolversParentTypes['Trip']> = {
  details?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  fromUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  legs?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportJob']>>>, ParentType, ContextType>;
  num?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  references?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seal?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['TripStatus']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  toUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  waybillId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TripLocationsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TripLocations'] = ResolversParentTypes['TripLocations']> = {
  locations?: Resolver<Maybe<Array<Maybe<ResolversTypes['TruckLocation']>>>, ParentType, ContextType>;
  trip?: Resolver<Maybe<ResolversTypes['TransportJob']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TripLocationsV2Resolvers<ContextType = any, ParentType extends ResolversParentTypes['TripLocationsV2'] = ResolversParentTypes['TripLocationsV2']> = {
  locations?: Resolver<Maybe<Array<Maybe<ResolversTypes['JSON']>>>, ParentType, ContextType>;
  trip?: Resolver<Maybe<ResolversTypes['TransportJob']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TripSummariesResolvers<ContextType = any, ParentType extends ResolversParentTypes['TripSummaries'] = ResolversParentTypes['TripSummaries']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['TripSummary']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TripSummaryResolvers<ContextType = any, ParentType extends ResolversParentTypes['TripSummary'] = ResolversParentTypes['TripSummary']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  billToSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  date?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dept?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  emptyCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  emptyCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  emptyTrips?: Resolver<Maybe<ResolversTypes['EmptyTrips']>, ParentType, ContextType>;
  emptyUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  from?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ladenCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ladenTrips?: Resolver<Maybe<Array<Maybe<ResolversTypes['TransportJob']>>>, ParentType, ContextType>;
  ladenUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  ownerType?: Resolver<Maybe<ResolversTypes['VehicleOwnerType']>, ParentType, ContextType>;
  to?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transportSource?: Resolver<Maybe<ResolversTypes['TransportSource']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TripTimingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TripTimings'] = ResolversParentTypes['TripTimings']> = {
  firstLegStart?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  firstLegStartOut?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastLegEnd?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastLegEndOut?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  timeAtConsigneeMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  timeAtShipperMins?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tripSequence?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  tripStatus?: Resolver<Maybe<ResolversTypes['TripStatus']>, ParentType, ContextType>;
  tripType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TripsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Trips'] = ResolversParentTypes['Trips']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Trip']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TruckAlertResolvers<ContextType = any, ParentType extends ResolversParentTypes['TruckAlert'] = ResolversParentTypes['TruckAlert']> = {
  _id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  al?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  be?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  f?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ign?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ll?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  media?: Resolver<Maybe<ResolversTypes['TruckAlertMedia']>, ParentType, ContextType>;
  odm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  r?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  s?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  t?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TruckAlertMediaResolvers<ContextType = any, ParentType extends ResolversParentTypes['TruckAlertMedia'] = ResolversParentTypes['TruckAlertMedia']> = {
  images?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  videos?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TruckAlertsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TruckAlerts'] = ResolversParentTypes['TruckAlerts']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['TruckAlert']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TruckLocationResolvers<ContextType = any, ParentType extends ResolversParentTypes['TruckLocation'] = ResolversParentTypes['TruckLocation']> = {
  branches?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  datetime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  idling?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ignition?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  loc?: Resolver<Maybe<ResolversTypes['Point']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  odometer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pmId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  speed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TruckLocationLatestResolvers<ContextType = any, ParentType extends ResolversParentTypes['TruckLocationLatest'] = ResolversParentTypes['TruckLocationLatest']> = {
  branches?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  datetime?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  idling?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  ignition?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  latitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  loc?: Resolver<Maybe<ResolversTypes['Point']>, ParentType, ContextType>;
  longitude?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  odometer?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  pmId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  reg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  speed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TruckLocationV2Resolvers<ContextType = any, ParentType extends ResolversParentTypes['TruckLocationV2'] = ResolversParentTypes['TruckLocationV2']> = {
  be?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  f?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ign?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  ll?: Resolver<Maybe<Array<Maybe<ResolversTypes['Float']>>>, ParentType, ContextType>;
  odm?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  r?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  s?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  t?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TruckSpeedsResolvers<ContextType = any, ParentType extends ResolversParentTypes['TruckSpeeds'] = ResolversParentTypes['TruckSpeeds']> = {
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  speeds?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface UrlScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['URL'], any> {
  name: 'URL';
}

export interface UuidScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['UUID'], any> {
  name: 'UUID';
}

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  algoliaKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  auth0Token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  auth0UserId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  baseCompanies?: Resolver<Maybe<Array<Maybe<ResolversTypes['BaseCompany']>>>, ParentType, ContextType>;
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserCompany']>>>, ParentType, ContextType>;
  email?: Resolver<Maybe<ResolversTypes['Email']>, ParentType, ContextType>;
  familyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  givenName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  nickname?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  picture?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  roles?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserCompany'] = ResolversParentTypes['UserCompany']> = {
  country?: Resolver<Maybe<ResolversTypes['Country']>, ParentType, ContextType>;
  countryAlpha3?: Resolver<Maybe<ResolversTypes['LimitedString']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['CompanyStatus']>, ParentType, ContextType>;
  types?: Resolver<Maybe<Array<Maybe<ResolversTypes['CompanyType']>>>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserPreferenceResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserPreference'] = ResolversParentTypes['UserPreference']> = {
  key?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  value?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserTimeoutResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserTimeout'] = ResolversParentTypes['UserTimeout']> = {
  seconds?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UsersResolvers<ContextType = any, ParentType extends ResolversParentTypes['Users'] = ResolversParentTypes['Users']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VehicleResolvers<ContextType = any, ParentType extends ResolversParentTypes['Vehicle'] = ResolversParentTypes['Vehicle']> = {
  airBags?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  antilockBrakes?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  axleWeight?: Resolver<Maybe<Array<Maybe<ResolversTypes['Int']>>>, ParentType, ContextType>;
  chassisNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companies?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityCompany']>>>, ParentType, ContextType>;
  customerAddress?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityCompanyAddress']>>>, ParentType, ContextType>;
  customers?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityCompany']>>>, ParentType, ContextType>;
  department?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documents?: Resolver<Maybe<Array<Maybe<ResolversTypes['DocumentDescription']>>>, ParentType, ContextType>;
  driverPreference?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  engineCapacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  engineNo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  entityException?: Resolver<Maybe<ResolversTypes['EntityExceptionOutcome']>, ParentType, ContextType>;
  image?: Resolver<Maybe<ResolversTypes['Attachment']>, ParentType, ContextType>;
  loadCapacity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  make?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  manufactureYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  model?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerType?: Resolver<Maybe<ResolversTypes['VehicleOwnerType']>, ParentType, ContextType>;
  plan?: Resolver<Maybe<ResolversTypes['VehiclePlan']>, ParentType, ContextType>;
  registration?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  registrationYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['VehicleStatus']>, ParentType, ContextType>;
  subContractors?: Resolver<Maybe<Array<Maybe<ResolversTypes['EntityCompany']>>>, ParentType, ContextType>;
  tareWeight?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  trailerPreference?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VehiclePlanResolvers<ContextType = any, ParentType extends ResolversParentTypes['VehiclePlan'] = ResolversParentTypes['VehiclePlan']> = {
  etaAvailable?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  explanation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobsUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shiftsUuids?: Resolver<Maybe<Array<Maybe<ResolversTypes['UUID']>>>, ParentType, ContextType>;
  vehicleUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VehiclesResultsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VehiclesResults'] = ResolversParentTypes['VehiclesResults']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Vehicle']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VesselResolvers<ContextType = any, ParentType extends ResolversParentTypes['Vessel'] = ResolversParentTypes['Vessel']> = {
  imo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  mmsi?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ViewerResolvers<ContextType = any, ParentType extends ResolversParentTypes['Viewer'] = ResolversParentTypes['Viewer']> = {
  algoliaKey?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  baseCompanies?: Resolver<Maybe<Array<Maybe<ResolversTypes['BaseCompany']>>>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  employee?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  registered?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  superAdmin?: Resolver<Maybe<ResolversTypes['Employee']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VolumeUnitResolvers<ContextType = any, ParentType extends ResolversParentTypes['VolumeUnit'] = ResolversParentTypes['VolumeUnit']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sorting?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VolumeUnitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VolumeUnits'] = ResolversParentTypes['VolumeUnits']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['VolumeUnit']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoucherResolvers<ContextType = any, ParentType extends ResolversParentTypes['Voucher'] = ResolversParentTypes['Voucher']> = {
  accountDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  address?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  approvals?: Resolver<Maybe<Array<Maybe<ResolversTypes['Approval']>>>, ParentType, ContextType>;
  approvedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  balance?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  baseSubTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  baseTaxTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  bookingDocuments?: Resolver<Maybe<Array<Maybe<ResolversTypes['BookingDocument']>>>, ParentType, ContextType>;
  bookings?: Resolver<Maybe<Array<Maybe<ResolversTypes['Booking']>>>, ParentType, ContextType>;
  canApprove?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  canCancel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  contactPerson?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  documentCreatorTemplate?: Resolver<Maybe<ResolversTypes['DocumentCreatorTemplate']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  exchangeRounding?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  internalDescription?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  invoiceNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isCreditNote?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  issueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  localSubTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  localTaxTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  localTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  owner?: Resolver<Maybe<ResolversTypes['BaseCompany']>, ParentType, ContextType>;
  paymentType?: Resolver<Maybe<ResolversTypes['PaymentType']>, ParentType, ContextType>;
  permissions?: Resolver<Maybe<ResolversTypes['VoucherPermissions']>, ParentType, ContextType>;
  printCount?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  salesPerson?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['VoucherStatus']>, ParentType, ContextType>;
  subTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taxRounding?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taxTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  term?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  transactionType?: Resolver<Maybe<ResolversTypes['TransactionType']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  vendor?: Resolver<Maybe<ResolversTypes['Company']>, ParentType, ContextType>;
  voucherItems?: Resolver<Maybe<Array<Maybe<ResolversTypes['VoucherItem']>>>, ParentType, ContextType>;
  voucherNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  voucherPayments?: Resolver<Maybe<Array<Maybe<ResolversTypes['VoucherPayment']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoucherItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoucherItem'] = ResolversParentTypes['VoucherItem']> = {
  baseRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  baseSubTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  baseTaxTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  booking?: Resolver<Maybe<ResolversTypes['Booking']>, ParentType, ContextType>;
  bookingUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  costItem?: Resolver<Maybe<ResolversTypes['CostItem']>, ParentType, ContextType>;
  currency?: Resolver<Maybe<ResolversTypes['Currency']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  editBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  exchangeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  isDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  job?: Resolver<Maybe<ResolversTypes['Job']>, ParentType, ContextType>;
  localExchangeRate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  localSubTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  localTaxTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  localTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  quantity?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  rate?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sorting?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  subTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  tax?: Resolver<Maybe<ResolversTypes['Tax']>, ParentType, ContextType>;
  taxPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  taxTotal?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  unit?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  voucher?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType>;
  voucherCn?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType>;
  voucherItemCn?: Resolver<Maybe<ResolversTypes['VoucherItem']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoucherPaymentResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoucherPayment'] = ResolversParentTypes['VoucherPayment']> = {
  amount?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  payment?: Resolver<Maybe<ResolversTypes['Payment']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  voucher?: Resolver<Maybe<ResolversTypes['Voucher']>, ParentType, ContextType>;
  voucherUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoucherPermissionsResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoucherPermissions'] = ResolversParentTypes['VoucherPermissions']> = {
  canApprove?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  canCancel?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VoucherTemplateResolvers<ContextType = any, ParentType extends ResolversParentTypes['VoucherTemplate'] = ResolversParentTypes['VoucherTemplate']> = {
  approvals?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  bookingDocuments?: Resolver<Maybe<Array<Maybe<ResolversTypes['String']>>>, ParentType, ContextType>;
  transactionCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  transactionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VouchersResolvers<ContextType = any, ParentType extends ResolversParentTypes['Vouchers'] = ResolversParentTypes['Vouchers']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Voucher']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type VouchersJsonResolvers<ContextType = any, ParentType extends ResolversParentTypes['VouchersJson'] = ResolversParentTypes['VouchersJson']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WaypointResolvers<ContextType = any, ParentType extends ResolversParentTypes['Waypoint'] = ResolversParentTypes['Waypoint']> = {
  lat?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  lng?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seca?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WeightUnitResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeightUnit'] = ResolversParentTypes['WeightUnit']> = {
  baseCompanyUuid?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sorting?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type WeightUnitsResolvers<ContextType = any, ParentType extends ResolversParentTypes['WeightUnits'] = ResolversParentTypes['WeightUnits']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['WeightUnit']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type YardResolvers<ContextType = any, ParentType extends ResolversParentTypes['Yard'] = ResolversParentTypes['Yard']> = {
  address?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  code?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  createdBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  createdUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  ownerUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  remarks?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['YardStatus']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  updatedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedUuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  uuid?: Resolver<Maybe<ResolversTypes['UUID']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type YardsResolvers<ContextType = any, ParentType extends ResolversParentTypes['Yards'] = ResolversParentTypes['Yards']> = {
  pageInfo?: Resolver<Maybe<ResolversTypes['numberPagination']>, ParentType, ContextType>;
  rows?: Resolver<Maybe<Array<Maybe<ResolversTypes['Yard']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CostItemErrorTypeResolvers<ContextType = any, ParentType extends ResolversParentTypes['costItemErrorType'] = ResolversParentTypes['costItemErrorType']> = {
  expectedValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  field?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  msg?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  receivedValue?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  urgency?: Resolver<Maybe<ResolversTypes['costSheetErrorEnum']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type NumberPaginationResolvers<ContextType = any, ParentType extends ResolversParentTypes['numberPagination'] = ResolversParentTypes['numberPagination']> = {
  count?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  limit?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  offset?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  ActionTemplate?: ActionTemplateResolvers<ContextType>;
  Address?: AddressResolvers<ContextType>;
  Addresses?: AddressesResolvers<ContextType>;
  Approval?: ApprovalResolvers<ContextType>;
  Approvals?: ApprovalsResolvers<ContextType>;
  Assignee?: AssigneeResolvers<ContextType>;
  Attachment?: AttachmentResolvers<ContextType>;
  Auth?: AuthResolvers<ContextType>;
  AvailabilitiesResults?: AvailabilitiesResultsResolvers<ContextType>;
  Availability?: AvailabilityResolvers<ContextType>;
  BCSettingAreaCode?: BcSettingAreaCodeResolvers<ContextType>;
  BaseCompanies?: BaseCompaniesResolvers<ContextType>;
  BaseCompany?: BaseCompanyResolvers<ContextType>;
  BaseCompanyDetails?: BaseCompanyDetailsResolvers<ContextType>;
  BaseCompanySetting?: BaseCompanySettingResolvers<ContextType>;
  BaseCompanySettings?: BaseCompanySettingsResolvers<ContextType>;
  BillingUnit?: BillingUnitResolvers<ContextType>;
  BillingUnits?: BillingUnitsResolvers<ContextType>;
  Booking?: BookingResolvers<ContextType>;
  BookingDocument?: BookingDocumentResolvers<ContextType>;
  BookingDocumentTemplate?: BookingDocumentTemplateResolvers<ContextType>;
  BookingDocuments?: BookingDocumentsResolvers<ContextType>;
  BookingEvent?: BookingEventResolvers<ContextType>;
  BookingEventPayload?: BookingEventPayloadResolvers<ContextType>;
  BookingFieldValue?: BookingFieldValueResolvers<ContextType>;
  BookingPermissionType?: BookingPermissionTypeResolvers<ContextType>;
  BookingState?: BookingStateResolvers<ContextType>;
  BookingSummary?: BookingSummaryResolvers<ContextType>;
  BookingType?: BookingTypeResolvers<ContextType>;
  BookingTypeBilling?: BookingTypeBillingResolvers<ContextType>;
  BookingTypeDynamicField?: BookingTypeDynamicFieldResolvers<ContextType>;
  BookingTypes?: BookingTypesResolvers<ContextType>;
  Bookings?: BookingsResolvers<ContextType>;
  BookingsJson?: BookingsJsonResolvers<ContextType>;
  BulkImportFunction?: BulkImportFunctionResolvers<ContextType>;
  BulkImportFunctions?: BulkImportFunctionsResolvers<ContextType>;
  Channel?: ChannelResolvers<ContextType>;
  Channels?: ChannelsResolvers<ContextType>;
  ChargeCategories?: ChargeCategoriesResolvers<ContextType>;
  ChargeCategory?: ChargeCategoryResolvers<ContextType>;
  ChargeItem?: ChargeItemResolvers<ContextType>;
  ChargeItemCode?: ChargeItemCodeResolvers<ContextType>;
  ChargeItemMisc?: ChargeItemMiscResolvers<ContextType>;
  ChargeItemMiscs?: ChargeItemMiscsResolvers<ContextType>;
  ChargeItemTax?: ChargeItemTaxResolvers<ContextType>;
  ChargeItems?: ChargeItemsResolvers<ContextType>;
  ChildNode?: ChildNodeResolvers<ContextType>;
  ChildNodes?: ChildNodesResolvers<ContextType>;
  ChildSelector?: ChildSelectorResolvers<ContextType>;
  Chronology?: ChronologyResolvers<ContextType>;
  Commodity?: CommodityResolvers<ContextType>;
  CommodityType?: CommodityTypeResolvers<ContextType>;
  CommodityTypes?: CommodityTypesResolvers<ContextType>;
  Companies?: CompaniesResolvers<ContextType>;
  Company?: CompanyResolvers<ContextType>;
  CompanyAccess?: CompanyAccessResolvers<ContextType>;
  CompanyAccesses?: CompanyAccessesResolvers<ContextType>;
  CompanySearch?: CompanySearchResolvers<ContextType>;
  ComputedRate?: ComputedRateResolvers<ContextType>;
  ComputedRateResult?: ComputedRateResultResolvers<ContextType>;
  ConsolVoucher?: ConsolVoucherResolvers<ContextType>;
  ConsolVouchers?: ConsolVouchersResolvers<ContextType>;
  Contact?: ContactResolvers<ContextType>;
  ContactSimple?: ContactSimpleResolvers<ContextType>;
  Contacts?: ContactsResolvers<ContextType>;
  ContainerEvent?: ContainerEventResolvers<ContextType>;
  ContainerGrade?: ContainerGradeResolvers<ContextType>;
  ContainerGrades?: ContainerGradesResolvers<ContextType>;
  ContainerType?: ContainerTypeResolvers<ContextType>;
  ContainerTypes?: ContainerTypesResolvers<ContextType>;
  CostItem?: CostItemResolvers<ContextType>;
  CostSheet?: CostSheetResolvers<ContextType>;
  Counter?: CounterResolvers<ContextType>;
  CounterResults?: CounterResultsResolvers<ContextType>;
  Countries?: CountriesResolvers<ContextType>;
  Country?: CountryResolvers<ContextType>;
  CountryAlpha2?: GraphQLScalarType;
  CountryAlpha3?: GraphQLScalarType;
  Currencies?: CurrenciesResolvers<ContextType>;
  Currency?: CurrencyResolvers<ContextType>;
  DataDetails?: DataDetailsResolvers<ContextType>;
  Date?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  Document?: DocumentResolvers<ContextType>;
  DocumentCreator?: DocumentCreatorResolvers<ContextType>;
  DocumentCreatorReference?: DocumentCreatorReferenceResolvers<ContextType>;
  DocumentCreatorTemplate?: DocumentCreatorTemplateResolvers<ContextType>;
  DocumentCreatorTemplates?: DocumentCreatorTemplatesResolvers<ContextType>;
  DocumentCreators?: DocumentCreatorsResolvers<ContextType>;
  DocumentDescription?: DocumentDescriptionResolvers<ContextType>;
  Driver?: DriverResolvers<ContextType>;
  DriverBehaviorAuth?: DriverBehaviorAuthResolvers<ContextType>;
  DriverUtilDept?: DriverUtilDeptResolvers<ContextType>;
  DriversResults?: DriversResultsResolvers<ContextType>;
  Email?: GraphQLScalarType;
  EmailRequestFields?: EmailRequestFieldsResolvers<ContextType>;
  EmailRequestFieldsBookingConfirmationFromLiner?: EmailRequestFieldsBookingConfirmationFromLinerResolvers<ContextType>;
  EmailRequestFieldsBookingConfirmationReeferFromLiner?: EmailRequestFieldsBookingConfirmationReeferFromLinerResolvers<ContextType>;
  EmailRequestFieldsConsigneeDraftNotification?: EmailRequestFieldsConsigneeDraftNotificationResolvers<ContextType>;
  EmailRequestFieldsEdo?: EmailRequestFieldsEdoResolvers<ContextType>;
  EmailRequestFieldsExportDocs?: EmailRequestFieldsExportDocsResolvers<ContextType>;
  EmailRequestFieldsHaulageRFC?: EmailRequestFieldsHaulageRfcResolvers<ContextType>;
  EmailRequestFieldsHaulageRot?: EmailRequestFieldsHaulageRotResolvers<ContextType>;
  EmailRequestFieldsImportDocs?: EmailRequestFieldsImportDocsResolvers<ContextType>;
  EmailRequestFieldsLinerBookingConfirmationCustomerRequest?: EmailRequestFieldsLinerBookingConfirmationCustomerRequestResolvers<ContextType>;
  EmailRequestFieldsLinerContainerMovementOrder?: EmailRequestFieldsLinerContainerMovementOrderResolvers<ContextType>;
  EmailRequestFieldsLinerNotifyCustomerComplete?: EmailRequestFieldsLinerNotifyCustomerCompleteResolvers<ContextType>;
  EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybill?: EmailRequestFieldsLinerPendNotifyForeignAgentOfWaybillResolvers<ContextType>;
  EmailRequestFieldsNoa?: EmailRequestFieldsNoaResolvers<ContextType>;
  EmailRequestFieldsPaymentNotification?: EmailRequestFieldsPaymentNotificationResolvers<ContextType>;
  Employee?: EmployeeResolvers<ContextType>;
  EmployeeDriver?: EmployeeDriverResolvers<ContextType>;
  Employees?: EmployeesResolvers<ContextType>;
  EmptyTrip?: EmptyTripResolvers<ContextType>;
  EmptyTripProposed?: EmptyTripProposedResolvers<ContextType>;
  EmptyTrips?: EmptyTripsResolvers<ContextType>;
  EntityAddress?: EntityAddressResolvers<ContextType>;
  EntityCategoryRequirement?: EntityCategoryRequirementResolvers<ContextType>;
  EntityCompany?: EntityCompanyResolvers<ContextType>;
  EntityCompanyAddress?: EntityCompanyAddressResolvers<ContextType>;
  EntityDetails?: EntityDetailsResolvers<ContextType>;
  EntityDocumentRequirement?: EntityDocumentRequirementResolvers<ContextType>;
  EntityEvent?: EntityEventResolvers<ContextType>;
  EntityExceptionOutcome?: EntityExceptionOutcomeResolvers<ContextType>;
  EntityExceptions?: EntityExceptionsResolvers<ContextType>;
  EntityExceptionsItem?: EntityExceptionsItemResolvers<ContextType>;
  EntityField?: EntityFieldResolvers<ContextType>;
  EntityFieldReq?: EntityFieldReqResolvers<ContextType>;
  EntityRequirements?: EntityRequirementsResolvers<ContextType>;
  EntityTypeUnion?: EntityTypeUnionResolvers<ContextType>;
  Event?: EventResolvers<ContextType>;
  EventCreator?: EventCreatorResolvers<ContextType>;
  EventEtc?: EventEtcResolvers<ContextType>;
  EventVoucher?: EventVoucherResolvers<ContextType>;
  EventVouchers?: EventVouchersResolvers<ContextType>;
  EventsEtc?: EventsEtcResolvers<ContextType>;
  ExchangeRate?: ExchangeRateResolvers<ContextType>;
  ExchangeRates?: ExchangeRatesResolvers<ContextType>;
  Faf?: FafResolvers<ContextType>;
  Fafs?: FafsResolvers<ContextType>;
  FmsCompany?: FmsCompanyResolvers<ContextType>;
  Fuel?: FuelResolvers<ContextType>;
  Fuels?: FuelsResolvers<ContextType>;
  GateLog?: GateLogResolvers<ContextType>;
  GateLogs?: GateLogsResolvers<ContextType>;
  Geocode?: GeocodeResolvers<ContextType>;
  Geocodes?: GeocodesResolvers<ContextType>;
  Geometry?: GeometryResolvers<ContextType>;
  GeometryPoint?: GeometryPointResolvers<ContextType>;
  GeometryPolygon?: GeometryPolygonResolvers<ContextType>;
  GlCode?: GlCodeResolvers<ContextType>;
  GlCodes?: GlCodesResolvers<ContextType>;
  GoogleGeometry?: GoogleGeometryResolvers<ContextType>;
  Group?: GroupResolvers<ContextType>;
  GroupRule?: GroupRuleResolvers<ContextType>;
  Groups?: GroupsResolvers<ContextType>;
  ImpCustConsigneeDraftNotification?: ImpCustConsigneeDraftNotificationResolvers<ContextType>;
  Incentive?: IncentiveResolvers<ContextType>;
  IncentiveCategorySum?: IncentiveCategorySumResolvers<ContextType>;
  IncentiveRate?: IncentiveRateResolvers<ContextType>;
  IncentiveRateDetails?: IncentiveRateDetailsResolvers<ContextType>;
  IncentiveRates?: IncentiveRatesResolvers<ContextType>;
  IncentiveType?: IncentiveTypeResolvers<ContextType>;
  IncentiveTypeSum?: IncentiveTypeSumResolvers<ContextType>;
  IncentiveTypes?: IncentiveTypesResolvers<ContextType>;
  IncentiveVoucher?: IncentiveVoucherResolvers<ContextType>;
  IncentiveVouchers?: IncentiveVouchersResolvers<ContextType>;
  Incentives?: IncentivesResolvers<ContextType>;
  Integration?: IntegrationResolvers<ContextType>;
  IntegrationDetail?: IntegrationDetailResolvers<ContextType>;
  IntegrationDetails?: IntegrationDetailsResolvers<ContextType>;
  IntegrationLog?: IntegrationLogResolvers<ContextType>;
  IntegrationLogs?: IntegrationLogsResolvers<ContextType>;
  IntegrationLogsQuery?: IntegrationLogsQueryResolvers<ContextType>;
  IntegrationMapping?: IntegrationMappingResolvers<ContextType>;
  IntegrationMappings?: IntegrationMappingsResolvers<ContextType>;
  Integrations?: IntegrationsResolvers<ContextType>;
  InviteCompanyAccess?: InviteCompanyAccessResolvers<ContextType>;
  InviteCompanyAccesses?: InviteCompanyAccessesResolvers<ContextType>;
  InviteUserResponse?: InviteUserResponseResolvers<ContextType>;
  InvitedBaseCompany?: InvitedBaseCompanyResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  JSONObject?: GraphQLScalarType;
  Job?: JobResolvers<ContextType>;
  JobOption?: JobOptionResolvers<ContextType>;
  JobOptions?: JobOptionsResolvers<ContextType>;
  JobReferences?: JobReferencesResolvers<ContextType>;
  JobSummaries?: JobSummariesResolvers<ContextType>;
  JobSummary?: JobSummaryResolvers<ContextType>;
  JobType?: JobTypeResolvers<ContextType>;
  JobTypeDetails?: JobTypeDetailsResolvers<ContextType>;
  JobTypeRequiredFields?: JobTypeRequiredFieldsResolvers<ContextType>;
  JobTypeUnitOptions?: JobTypeUnitOptionsResolvers<ContextType>;
  JobTypes?: JobTypesResolvers<ContextType>;
  Jobs?: JobsResolvers<ContextType>;
  JobsJson?: JobsJsonResolvers<ContextType>;
  LanguageCode?: GraphQLScalarType;
  Leg?: LegResolvers<ContextType>;
  LegActivities?: LegActivitiesResolvers<ContextType>;
  LegActivity?: LegActivityResolvers<ContextType>;
  LegSummaries?: LegSummariesResolvers<ContextType>;
  LegSummary?: LegSummaryResolvers<ContextType>;
  LimitedString?: GraphQLScalarType;
  LimitedString2?: GraphQLScalarType;
  LimitedString3?: GraphQLScalarType;
  Location?: LocationResolvers<ContextType>;
  Manifest?: ManifestResolvers<ContextType>;
  ManifestLocation?: ManifestLocationResolvers<ContextType>;
  Manifests?: ManifestsResolvers<ContextType>;
  MessageEvent?: MessageEventResolvers<ContextType>;
  MessageEventPayload?: MessageEventPayloadResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NodeConnection?: NodeConnectionResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  ObjectID?: GraphQLScalarType;
  PageInfo?: PageInfoResolvers<ContextType>;
  Password?: GraphQLScalarType;
  Payment?: PaymentResolvers<ContextType>;
  Payments?: PaymentsResolvers<ContextType>;
  Permission?: PermissionResolvers<ContextType>;
  PermissionType?: PermissionTypeResolvers<ContextType>;
  Permissions?: PermissionsResolvers<ContextType>;
  Place?: PlaceResolvers<ContextType>;
  PlaceResults?: PlaceResultsResolvers<ContextType>;
  PmUtilDept?: PmUtilDeptResolvers<ContextType>;
  Point?: PointResolvers<ContextType>;
  Port?: PortResolvers<ContextType>;
  PortalCompanies?: PortalCompaniesResolvers<ContextType>;
  PortalCompany?: PortalCompanyResolvers<ContextType>;
  PortalCompanyDetails?: PortalCompanyDetailsResolvers<ContextType>;
  PortalCompanyReportConfig?: PortalCompanyReportConfigResolvers<ContextType>;
  PortalReports?: PortalReportsResolvers<ContextType>;
  Ports?: PortsResolvers<ContextType>;
  ProcessFlow?: ProcessFlowResolvers<ContextType>;
  ProcessFlowChronologiesType?: ProcessFlowChronologiesTypeResolvers<ContextType>;
  ProcessFlowCreateType?: ProcessFlowCreateTypeResolvers<ContextType>;
  ProcessFlowDocumentType?: ProcessFlowDocumentTypeResolvers<ContextType>;
  ProcessFlowRequestType?: ProcessFlowRequestTypeResolvers<ContextType>;
  ProcessFlowSection?: ProcessFlowSectionResolvers<ContextType>;
  ProcessFlowStepType?: ProcessFlowStepTypeResolvers<ContextType>;
  ProcessFlowVoucherType?: ProcessFlowVoucherTypeResolvers<ContextType>;
  ProcessTypeCategories?: ProcessTypeCategoriesResolvers<ContextType>;
  ProcessTypes?: ProcessTypesResolvers<ContextType>;
  Profile?: ProfileResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Quotation?: QuotationResolvers<ContextType>;
  QuotationItem?: QuotationItemResolvers<ContextType>;
  Quotations?: QuotationsResolvers<ContextType>;
  Rate?: RateResolvers<ContextType>;
  RateLogItem?: RateLogItemResolvers<ContextType>;
  RateValue?: RateValueResolvers<ContextType>;
  RateValueBracket?: RateValueBracketResolvers<ContextType>;
  Report?: ReportResolvers<ContextType>;
  ReportColumn?: ReportColumnResolvers<ContextType>;
  ReportField?: ReportFieldResolvers<ContextType>;
  ReportFilter?: ReportFilterResolvers<ContextType>;
  ReportJobSummaries?: ReportJobSummariesResolvers<ContextType>;
  ReportJobSummary?: ReportJobSummaryResolvers<ContextType>;
  ReportJobSummaryDept?: ReportJobSummaryDeptResolvers<ContextType>;
  ReportJobSummaryZone?: ReportJobSummaryZoneResolvers<ContextType>;
  ReportMeta?: ReportMetaResolvers<ContextType>;
  ReportPortalCompany?: ReportPortalCompanyResolvers<ContextType>;
  ReportSummary?: ReportSummaryResolvers<ContextType>;
  Reports?: ReportsResolvers<ContextType>;
  Request?: RequestResolvers<ContextType>;
  RequestEmailResultsType?: RequestEmailResultsTypeResolvers<ContextType>;
  RequestMetaType?: RequestMetaTypeResolvers<ContextType>;
  RequestTemplate?: RequestTemplateResolvers<ContextType>;
  RequisitionTemplate?: RequisitionTemplateResolvers<ContextType>;
  Response?: ResponseResolvers<ContextType>;
  ReverseGeoAddress?: ReverseGeoAddressResolvers<ContextType>;
  Role?: RoleResolvers<ContextType>;
  Roles?: RolesResolvers<ContextType>;
  RootNode?: RootNodeResolvers<ContextType>;
  RootNodes?: RootNodesResolvers<ContextType>;
  SRate?: SRateResolvers<ContextType>;
  SeaPath?: SeaPathResolvers<ContextType>;
  SeaRoute?: SeaRouteResolvers<ContextType>;
  Seaport?: SeaportResolvers<ContextType>;
  Search?: SearchResolvers<ContextType>;
  SearchResult?: SearchResultResolvers<ContextType>;
  Service?: ServiceResolvers<ContextType>;
  Setting?: SettingResolvers<ContextType>;
  Shift?: ShiftResolvers<ContextType>;
  ShiftBreak?: ShiftBreakResolvers<ContextType>;
  Shifts?: ShiftsResolvers<ContextType>;
  ShippingRate?: ShippingRateResolvers<ContextType>;
  ShippingRateType?: ShippingRateTypeResolvers<ContextType>;
  ShippingRates?: ShippingRatesResolvers<ContextType>;
  SuperAdmins?: SuperAdminsResolvers<ContextType>;
  Tax?: TaxResolvers<ContextType>;
  Taxes?: TaxesResolvers<ContextType>;
  Template?: TemplateResolvers<ContextType>;
  Trailer?: TrailerResolvers<ContextType>;
  TrailersResults?: TrailersResultsResolvers<ContextType>;
  Transport?: TransportResolvers<ContextType>;
  TransportActivity?: TransportActivityResolvers<ContextType>;
  TransportActivityFlow?: TransportActivityFlowResolvers<ContextType>;
  TransportActivityShipXTrigger?: TransportActivityShipXTriggerResolvers<ContextType>;
  TransportAreaCode?: TransportAreaCodeResolvers<ContextType>;
  TransportAreaCodes?: TransportAreaCodesResolvers<ContextType>;
  TransportJob?: TransportJobResolvers<ContextType>;
  TransportJobType?: TransportJobTypeResolvers<ContextType>;
  TransportJobTypes?: TransportJobTypesResolvers<ContextType>;
  TransportJobs?: TransportJobsResolvers<ContextType>;
  TransportRate?: TransportRateResolvers<ContextType>;
  TransportRateDetails?: TransportRateDetailsResolvers<ContextType>;
  TransportRates?: TransportRatesResolvers<ContextType>;
  TransportReportJob?: TransportReportJobResolvers<ContextType>;
  TransportReportJobs?: TransportReportJobsResolvers<ContextType>;
  TransportReportLegActivities?: TransportReportLegActivitiesResolvers<ContextType>;
  TransportReportLegActivity?: TransportReportLegActivityResolvers<ContextType>;
  TransportReportTrip?: TransportReportTripResolvers<ContextType>;
  TransportReportTrips?: TransportReportTripsResolvers<ContextType>;
  TransportZone?: TransportZoneResolvers<ContextType>;
  TransportZones?: TransportZonesResolvers<ContextType>;
  TraversedJourney?: TraversedJourneyResolvers<ContextType>;
  Trip?: TripResolvers<ContextType>;
  TripLocations?: TripLocationsResolvers<ContextType>;
  TripLocationsV2?: TripLocationsV2Resolvers<ContextType>;
  TripSummaries?: TripSummariesResolvers<ContextType>;
  TripSummary?: TripSummaryResolvers<ContextType>;
  TripTimings?: TripTimingsResolvers<ContextType>;
  Trips?: TripsResolvers<ContextType>;
  TruckAlert?: TruckAlertResolvers<ContextType>;
  TruckAlertMedia?: TruckAlertMediaResolvers<ContextType>;
  TruckAlerts?: TruckAlertsResolvers<ContextType>;
  TruckLocation?: TruckLocationResolvers<ContextType>;
  TruckLocationLatest?: TruckLocationLatestResolvers<ContextType>;
  TruckLocationV2?: TruckLocationV2Resolvers<ContextType>;
  TruckSpeeds?: TruckSpeedsResolvers<ContextType>;
  URL?: GraphQLScalarType;
  UUID?: GraphQLScalarType;
  User?: UserResolvers<ContextType>;
  UserCompany?: UserCompanyResolvers<ContextType>;
  UserPreference?: UserPreferenceResolvers<ContextType>;
  UserTimeout?: UserTimeoutResolvers<ContextType>;
  Users?: UsersResolvers<ContextType>;
  Vehicle?: VehicleResolvers<ContextType>;
  VehiclePlan?: VehiclePlanResolvers<ContextType>;
  VehiclesResults?: VehiclesResultsResolvers<ContextType>;
  Vessel?: VesselResolvers<ContextType>;
  Viewer?: ViewerResolvers<ContextType>;
  VolumeUnit?: VolumeUnitResolvers<ContextType>;
  VolumeUnits?: VolumeUnitsResolvers<ContextType>;
  Voucher?: VoucherResolvers<ContextType>;
  VoucherItem?: VoucherItemResolvers<ContextType>;
  VoucherPayment?: VoucherPaymentResolvers<ContextType>;
  VoucherPermissions?: VoucherPermissionsResolvers<ContextType>;
  VoucherTemplate?: VoucherTemplateResolvers<ContextType>;
  Vouchers?: VouchersResolvers<ContextType>;
  VouchersJson?: VouchersJsonResolvers<ContextType>;
  Waypoint?: WaypointResolvers<ContextType>;
  WeightUnit?: WeightUnitResolvers<ContextType>;
  WeightUnits?: WeightUnitsResolvers<ContextType>;
  Yard?: YardResolvers<ContextType>;
  Yards?: YardsResolvers<ContextType>;
  costItemErrorType?: CostItemErrorTypeResolvers<ContextType>;
  numberPagination?: NumberPaginationResolvers<ContextType>;
};

