export default {
  setItem(key: string, val: any, ttl = 86400000) {
    const item = {
      val,
      exp: new Date().getTime() + ttl
    }
    localStorage.setItem(key, JSON.stringify(item))
  },

  getItem(key: string) {
    const str = localStorage.getItem(key)

    if (!str) return null

    try {
      const parsed = JSON.parse(str)
      if (parsed.val && parsed.exp) {
        if (new Date().getTime() > parsed.exp) {
          localStorage.removeItem(key)
          return null
        }
        return parsed.val
      }
    } catch {
      return str
    }
  },

  removeItem(key: string) {
    localStorage.removeItem(key)
  }
}
