import { gql } from '@apollo/client'

export const DEPARTMENT_QUERY = gql`
  query bookingDynamicField($key: String) {
    setting(key: $key) {
      key
      setting {
        value
        type
      }
    }
  }
`

export const QUOTATION_QUERY = gql`
  query quotations3(
    $buyerUuid: UUID
    $sellerUuid: UUID
    $q: String
    $statuses: [QuotationStatus]
    $valid: Boolean
    $limit: Int
    $offset: Int
  ) {
    quotations(
      buyerUuid: $buyerUuid
      sellerUuid: $sellerUuid
      q: $q
      statuses: $statuses
      valid: $valid
      limit: $limit
      offset: $offset
    ) {
      rows {
        uuid
        quotationNo
        term
        description
      }

      pageInfo {
        count
        limit
        offset
      }
    }
  }
`

export const ENUMS_QUERY = gql`
  query IntrospectionQuery {
    __schema {
      types {
        name
        kind
        enumValues {
          name
        }
      }
    }
  }
`

export const GET_COMPANIES_QUERY = gql`
  query getCompaniesSelectWithQuery(
    $query: String
    $types: [CompanyType]
    $limit: Int
    $offset: Int
  ) {
    companies(query: $query, types: $types, limit: $limit, offset: $offset) {
      rows {
        uuid
        name
      }
    }
  }
`
export const PLACES_QUERY = gql`
  query placesPortSelector2($q: String) {
    places(q: $q) {
      placeId
      description
    }
  }
`
