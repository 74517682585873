import { Component } from 'react'
import { DatePicker, Input, Modal, Button, Skeleton } from 'antd'
import { calculateVoucherItem } from '@shipx/formula2'
import { compose, bindActionCreators } from 'redux'
import { withApollo } from 'react-apollo'
import { connect } from 'react-redux'
import filter from 'lodash/filter'
import find from 'lodash/find'
import numeral from 'numeral'
import moment from 'moment'

import InputForm from './InputForm'
import { withBooking } from 'App/contexts/booking'
import * as voucherActions from 'App/states/reducers/voucher'
import CompanyLabel from 'App/components/Company/CompanyLabel'
import CurrencySelect from 'App/components/Select/CurrencySelect'
import SelectField from 'App/components/FieldsWithLabel/SelectField'
import CompanyAddressSelect from 'App/components/Select/AddressSelect'
import UppercaseOnlyInput from 'App/components/TextFields/UppercaseOnly'
import { FieldsAction, DisplayOnlyLabel, CreditNoteLabel } from './Styled'
import CompanySelect from 'App/components/Select/TypeToFetch/CompanySelect'
import updateVoucherMutation from 'App/containers/voucher/mutation/updateVoucher'
import DocumentCreatorTemplateSelect from '../Select/DocumentCreatorTemplateSelect'
import updateVoucherItemMutation from 'App/containers/voucher/mutation/updateVoucherItem'
import { getExchangeRates, calculateExchangeRate, getUpdateVoucherItemRequest } from './Utils'
import { t } from 'i18next'

const confirm = Modal.confirm

const defaultRequiredMessage = 'Field required.'
const otvTypes = ['oneTimeVendor']
const apTypes = [
  'customs',
  'forwarder',
  'transporter',
  'haulier',
  'liner',
  'shippingAgent',
  'freightForwarder',
  'port',
  'depot'
]
const arTypes = ['billing']
const pettyCashTypes = [...apTypes, ...otvTypes]

const queryTypes = {
  apPettyCash: pettyCashTypes,
  oneTimeVendor: otvTypes
}

const enhance = compose(
  withBooking,
  withApollo,
  updateVoucherMutation,
  updateVoucherItemMutation,
  connect(
    (state, props) => ({
      paymentTypes: state.voucher.paymentTypes,
      costsheetBookings: state.voucher.costsheetBookings,
      selectedGlobalCompany: state.globalCompany.selectedGlobalCompany,
      selectedVoucherBooking: state.voucher.selectedVoucherBooking
    }),
    (dispatch) => ({
      dispatch,
      ...bindActionCreators(
        {
          ...voucherActions
        },
        dispatch
      )
    })
  )
)

class VoucherInputFields extends Component {
  validateTerm = (rule, value, callback) => {
    const paymentType = this.props.form.getFieldValue('paymentType')
    const term = numeral(value).value()

    if (paymentType === 'CREDIT' && term <= 0) {
      const errorMsg = 'Term cannot be 0.'

      callback(errorMsg)
    } else {
      callback()
    }
  }

  handlePaymentTypeChange = (value) => {
    const { form, selectedVoucher } = this.props

    form.setFieldsValue({
      term: value === 'CASH' ? 0 : selectedVoucher.term,
      paymentType: value
    })
  }

  handleBillChange = (value, company) => {
    const { form, selectedVoucher, updateSelectedVoucher } = this.props

    const isAp = selectedVoucher.transactionType === 'ACCPAY'

    const companyTerm = isAp ? company && company.creditorTerm : company && company.debtorTerm

    const term = companyTerm || 0

    form.setFieldsValue({
      term: selectedVoucher.paymentType === 'CASH' ? 0 : term
    })

    const updatedVoucher = {
      ...selectedVoucher,
      [`${isAp ? 'vendor' : 'customer'}`]: company,
      term
    }

    updateSelectedVoucher(updatedVoucher)

    form.setFieldsValue({
      addressUuid: null
    })
  }

  handleCurrencyChange = async (value, option) => {
    const {
      updateVoucher,
      selectedVoucher,
      updateVoucherItem,
      costsheetBookings,
      updateSelectedVoucher,
      selectedGlobalCompany,
      selectedVoucherBooking
    } = this.props

    const isAp = selectedVoucher.transactionType === 'ACCPAY'

    const updatedVoucher = { ...selectedVoucher }
    updatedVoucher.currency = option

    const voucherItems = filter(updatedVoucher.voucherItems, (vi) => !vi.isDeleted)

    if (voucherItems.length) {
      const exchangeRates = await getExchangeRates({
        client: this.props.client,
        voucher: updatedVoucher
      })

      const newVoucherItems = voucherItems.map((vi) => {
        const voucherBooking = find(costsheetBookings, (b) => b.uuid === vi.bookingUuid)
        const voucherCostItem = find(voucherBooking.costItems, (ci) => ci.uuid === vi.costItem.uuid)

        const exchangeRate = calculateExchangeRate({
          isAp,
          voucher: updatedVoucher,
          booking: voucherBooking,
          costItem: voucherCostItem,
          voucherItem: vi,
          exchangeRates,
          selectedGlobalCompany
        })

        return calculateVoucherItem({
          ...vi,
          exchangeRate: exchangeRate.rate
        })
      })

      if (updatedVoucher.status === 'DRAFT' && newVoucherItems.length) {
        confirm({
          title: 'Confirm changing to new currency ?',
          content: 'All voucher items will be recomputed.',
          okText: 'Change currency',
          okType: 'danger',
          cancelText: 'Cancel',
          onOk: async () => {
            try {
              await Promise.all(
                newVoucherItems.map((vi) => {
                  return updateVoucherItem(getUpdateVoucherItemRequest(vi), selectedVoucherBooking)
                })
              )

              const result = await updateVoucher({
                uuid: updatedVoucher.uuid,
                currencyUuid: updatedVoucher.currency.uuid
              })

              if (result && result.data && result.data.updateVoucher) {
                updateSelectedVoucher(result.data.updateVoucher)
              }
            } catch (error) {
              console.log(error)
            }
          }
        })
      } else {
        updatedVoucher.voucherItems = [...newVoucherItems]

        updateSelectedVoucher(updatedVoucher)
      }
    } else {
      updateSelectedVoucher(updatedVoucher)
    }
  }

  renderUpdateControls = () => {
    const { handleUpdate } = this.props
    const selectedVoucher = this.props.selectedVoucher || {}
    const voucherStatus = selectedVoucher.status

    if (voucherStatus === 'DRAFT') {
      return (
        <FieldsAction>
          <Button type="primary" onClick={handleUpdate}>
            Update Details
          </Button>
        </FieldsAction>
      )
    }

    return null
  }

  renderVoucherNo = (selectedVoucher) => {
    const isCreditNote = selectedVoucher.isCreditNote

    return (
      <DisplayOnlyLabel>
        {selectedVoucher.voucherNumber}
        {isCreditNote && <CreditNoteLabel> (Credit Note)</CreditNoteLabel>}
      </DisplayOnlyLabel>
    )
  }

  renderDisplayOnly = (value) => {
    return <DisplayOnlyLabel>{value || '-'}</DisplayOnlyLabel>
  }

  getCompanyQueryTypes = (isAp, paymentType) => {
    const isApPettyCash = isAp && paymentType === 'PETTYCASH'
    const isOtv = paymentType === 'ONETIMEVENDOR'

    if (isOtv) {
      return queryTypes.oneTimeVendor
    }

    if (isApPettyCash) {
      return queryTypes.apPettyCash
    }

    if (isAp) {
      return apTypes
    } else {
      return arTypes
    }
  }

  render() {
    const { form, booking, paymentTypes, selectedVoucher, selectedGlobalCompany } = this.props

    if (!selectedVoucher) return <Skeleton />

    const paymentType = form?.getFieldValue('paymentType') || selectedVoucher.paymentType

    const isAp = selectedVoucher.transactionType === 'ACCPAY'
    const vendorOrCustomerUuid = isAp ? 'vendorUuid' : 'customerUuid'

    const selectedCompanyUuid = form?.getFieldValue(vendorOrCustomerUuid)

    const termRules = []
    let filteredPaymentTypes = [...paymentTypes]

    const sgcCompany = selectedGlobalCompany?.company
    let selectedCompany = {}

    if (selectedVoucher.status === 'NEW' && selectedVoucher.transactionType === 'ACCREC') {
      selectedCompany =
        booking.billTo || (isAp ? selectedVoucher.vendor : selectedVoucher.customer) || {}
    } else {
      selectedCompany = (isAp ? selectedVoucher.vendor : selectedVoucher.customer) || {}
    }

    const initAddressUuid = selectedVoucher.address?.uuid
    const selectedCurrency = selectedVoucher.currency || sgcCompany?.currency
    const initCurrencyUuid = selectedVoucher.currency?.uuid || sgcCompany?.currency?.uuid
    const companyDescription = selectedCompany.description

    if (!isAp) {
      filteredPaymentTypes = filter(paymentTypes, (type) => type.value !== 'PETTYCASH')
    }

    if (paymentType === 'CREDIT') {
      termRules.push({
        validator: this.validateTerm
      })
    }

    const fields = []
    const firstCol = []

    if (selectedVoucher.status !== 'NEW') {
      firstCol.push({
        label: 'Voucher No.',
        value: 'voucherNumber',
        attributes: {
          initialValue: selectedVoucher.voucherNumber
        },
        input: this.renderVoucherNo(selectedVoucher),
        displayOnly: this.renderDisplayOnly(
          selectedVoucher.voucherNumber || selectedVoucher.uuid.substring(0, 8)
        )
      })
    }

    if (isAp) {
      firstCol.push({
        label: 'Invoice No.',
        value: 'invoiceNumber',
        attributes: {
          initialValue: selectedVoucher.invoiceNumber,
          rules: [
            {
              required: isAp,
              message: defaultRequiredMessage
            }
          ]
        },
        input: <UppercaseOnlyInput />,
        displayOnly: this.renderDisplayOnly(selectedVoucher.invoiceNumber)
      })
    }

    firstCol.push({
      label: 'Payment Type',
      value: 'paymentType',
      attributes: {
        rules: [
          {
            required: true,
            message: defaultRequiredMessage
          }
        ],
        initialValue: selectedVoucher.paymentType || 'CASH'
      },
      input: (
        <SelectField
          dataSource={filteredPaymentTypes}
          editMode
          onChange={this.handlePaymentTypeChange}
        />
      ),
      displayOnly: this.renderDisplayOnly(selectedVoucher.paymentType)
    })

    const companyQueryTypes = this.getCompanyQueryTypes(isAp, paymentType)

    firstCol.push({
      label: isAp ? 'Pay To' : 'Bill To',
      value: vendorOrCustomerUuid,
      attributes: {
        initialValue: selectedCompany.uuid,
        rules: [
          {
            required: true,
            message: defaultRequiredMessage
          }
        ]
      },
      input: (
        <CompanySelect
          keyword={selectedCompany.uuid}
          types={companyQueryTypes}
          accountTypes={isAp ? ['creditor'] : ['debtor']}
          name={selectedCompany?.name}
          quickCreate={isAp && paymentType === 'ONETIMEVENDOR'}
          onChange={(value, company) => this.handleBillChange(value, company)}
        />
      ),
      displayOnly: (
        <DisplayOnlyLabel>
          <CompanyLabel company={selectedCompany} />
        </DisplayOnlyLabel>
      )
    })

    firstCol.push(
      {
        label: 'Address',
        value: 'addressUuid',
        attributes: {
          rules: [
            {
              required: !isAp,
              message: defaultRequiredMessage
            }
          ],
          initialValue: initAddressUuid
        },
        input: (
          <CompanyAddressSelect
            companyUuid={selectedCompanyUuid || selectedCompany.uuid}
            name={selectedVoucher.address?.name}
            type={['BILLING']}
          />
        ),
        displayOnly: this.renderDisplayOnly(selectedVoucher.address?.name)
      },
      {
        label: 'Contact Person',
        value: 'contactPerson',
        attributes: {
          initialValue: selectedVoucher.contactPerson
        },
        input: <Input autoComplete="off" />,
        displayOnly: this.renderDisplayOnly(selectedVoucher.contactPerson)
      },
      {
        label: 'Sales Person',
        value: 'salesPersonUuid',
        attributes: {
          initialValue: selectedVoucher.salesPersonUuid
        },
        input: <SelectField editMode />,
        displayOnly: this.renderDisplayOnly(selectedVoucher.salesPersonUuid)
      },
      {
        label: 'Remarks',
        value: 'description',
        attributes: {
          initialValue: selectedVoucher.description
        },
        input: <Input.TextArea autoComplete="off" />,
        displayOnly: this.renderDisplayOnly(selectedVoucher.description)
      }
    )

    const voucherStatus = selectedVoucher.status
    const editable = voucherStatus === 'DRAFT' || voucherStatus === 'NEW'
    
    if (!editable && selectedVoucher.voucherItems) {
      let displayData = []

      for (const vi of selectedVoucher.voucherItems) {
        if (vi.voucherItemCn) {
          isAp 
            ? displayData.push(vi.voucherItemCn?.voucher?.invoiceNumber)
            : displayData.push(vi.voucherItemCn?.voucher?.voucherNumber)
        }
      }

      displayData = [...new Set(displayData)]

      if (displayData.length) {
        firstCol.splice(1, 0, {
          label: isAp ? t('voucher.invoiceNumber') : t('voucher.voucherList'),
          value: isAp ? 'invoiceNumber' : 'voucherItems',
          attributes: {
            initialValue: displayData.join(', ')
          },
          displayOnly: this.renderDisplayOnly(displayData.join(', '))
        })
      }
    }

    fields.push(
      {
        cols: firstCol
      },
      {
        cols: [
          {
            label: 'Invoice Date',
            value: 'issueDate',
            attributes: {
              rules: [
                {
                  required: true,
                  message: defaultRequiredMessage
                }
              ],
              initialValue:
                moment(selectedVoucher.issueDate).startOf('day') || moment().startOf('day')
            },
            input: (
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="Select a date and time"
                style={{ width: '100%' }}
              />
            ),
            displayOnly: this.renderDisplayOnly(
              moment(selectedVoucher.issueDate).format('DD/MM/YYYY')
            )
          },
          {
            label: 'Account Date',
            value: 'accountDate',
            attributes: {
              rules: [
                {
                  required: true,
                  message: defaultRequiredMessage
                }
              ],
              initialValue:
                moment(selectedVoucher.accountDate).startOf('day') || moment().startOf('day')
            },
            input: (
              <DatePicker
                format="DD/MM/YYYY"
                placeholder="Select a date and time"
                style={{ width: '100%' }}
              />
            ),
            displayOnly: this.renderDisplayOnly(
              moment(selectedVoucher.accountDate).format('DD/MM/YYYY')
            )
          },
          {
            label: 'Term',
            value: 'term',
            attributes: {
              rules: termRules,
              initialValue: selectedVoucher.paymentType === 'CASH' ? 0 : selectedVoucher.term
            },
            input: <Input type="number" autoComplete="off" disabled={paymentType !== 'CREDIT'} />,
            displayOnly: this.renderDisplayOnly(selectedVoucher.term)
          },
          {
            label: 'Currency',
            value: 'currencyUuid',
            attributes: {
              rules: [
                {
                  required: true,
                  message: defaultRequiredMessage
                }
              ],
              initialValue: initCurrencyUuid,
              valuePropName: 'selectedCurrencyUuid',
              trigger: 'onCurrencyChanged'
            },
            input: (
              <CurrencySelect
                disabled={selectedVoucher.status !== 'NEW'}
                onCurrencyChanged={this.handleCurrencyChange}
              />
            ),
            displayOnly: this.renderDisplayOnly(selectedCurrency?.name)
          },
          {
            label: 'Internal Description',
            value: 'internalDescription',
            attributes: {
              initialValue: selectedVoucher.internalDescription
            },
            input: <Input.TextArea autoComplete="off" />,
            displayOnly: this.renderDisplayOnly(selectedVoucher.internalDescription)
          },
          {
            label: 'Company Description',
            value: 'companyDescription',
            attributes: {
              initialValue: companyDescription
            },
            input: <DisplayOnlyLabel>{companyDescription}</DisplayOnlyLabel>,
            displayOnly: <DisplayOnlyLabel>{companyDescription}</DisplayOnlyLabel>
          },
          {
            label: 'Document Type',
            value: 'documentCreatorTemplateUuid',
            attributes: {
              initialValue: selectedVoucher.documentCreatorTemplate?.uuid,
              rules: [
                {
                  required: true,
                  message: defaultRequiredMessage
                }
              ]
            },
            input: (
              <DocumentCreatorTemplateSelect
                filter={'INVOICE'}
                statuses={['activated']}
                queryOnMount
                byCompany={selectedCompany.uuid}
              />
            ),
            displayOnly: (
              <DisplayOnlyLabel>{selectedVoucher.documentCreatorTemplate?.name}</DisplayOnlyLabel>
            )
          }
        ]
      }
    )

    if (!isAp) {
      fields[1].cols.splice(1, 1)
    }

    const { getFieldDecorator } = this.props.form

    return (
      <>
        <InputForm fields={fields} editable={editable} getFieldDecorator={getFieldDecorator} />
        {this.renderUpdateControls()}
      </>
    )
  }
}

export default enhance(VoucherInputFields)
