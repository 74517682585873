import { gql } from '@apollo/client'

export const companyFragment = gql`
  fragment CompanySelectDetails on Company {
    uuid
    name
    types
    # status
    code
    tags
    # debtorTerm
    # debtorCode
    # creditorTerm
    country {
      name
      # alpha2
      alpha3
    }
    # billTo {
    #   uuid
    #   name
    #   code
    # }
  }
`

export default gql`
  query companies4(
    $limit: Int
    $offset: Int
    $types: [CompanyType!]
    $countryAlpha3: LimitedString
    $query: String
    $portalCompanyQueryType: PortalCompanyQueryType
    $accountTypes: [AccountType]
    $statuses: [CompanyStatus!]
    $uuids: [UUID]
  ) {
    companies(
      limit: $limit
      offset: $offset
      types: $types
      countryAlpha3: $countryAlpha3
      query: $query
      portalCompanyQueryType: $portalCompanyQueryType
      accountTypes: $accountTypes
      statuses: $statuses
      uuids: $uuids
    ) {
      rows {
        ...CompanySelectDetails
      }
    }
  }

  ${companyFragment}
`
