import { Icon } from 'antd'

import config from 'App/config'
import webStorage from 'App/utils/webStorage'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'

type DocumentCreatorPrintableProps = {
  modelUUID: string
  baseCompanyUuid: string
  type: string
}

export const generateLink = (modelUUID: string, baseCompanyUuid: string, type: string) => {
  const token = webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)
  return `${config.api.baseUrl}/documentCreator/${type}/${modelUUID}/${baseCompanyUuid}?base-company-uuid=${baseCompanyUuid}&auth_token=${token}`
}

export const PrintDocumentCreator = ({ modelUUID, baseCompanyUuid, type }: DocumentCreatorPrintableProps) => {
  return (
    <div style={{ cursor: 'pointer' }} onClick={() => openPrintableInNewTab(modelUUID, baseCompanyUuid, type)} >
      <Icon type="printer" style={{ color: 'rgba(0, 0, 0, 0.65)' }} />
    </div >
  )
}

export const openPrintableInNewTab = (modelUUID: string, baseCompanyUuid: string, type: string) => {
  const url = generateLink(modelUUID, baseCompanyUuid, type)

  window.open(url, '_blank')
}

export const getPrintableLink = (modelUUID: string, baseCompanyUuid: string, type: string) => {
  return generateLink(modelUUID, baseCompanyUuid, type)
}
