import { forwardRef, memo, useMemo } from 'react'
import { gql, useQuery } from '@apollo/client'
import { SelectProps } from 'antd/es/select'
import { withApollo } from 'react-apollo'
import { Select } from 'antd'

import { logger } from 'App/utils/logger'
import { camelCaseToSpace } from 'App/utils/u'
import responseHandler from 'App/utils/responseHandler'

interface Props extends SelectProps {
  camelCaseToNormal?: boolean
  enumName: string
  client?: any
  style?: any
  value?: any
  onChange?: any
  allowClear?: boolean
  multiple?: boolean
  id?: string
}

export const enumQueryString = `
  query enum1 ($name: String!) {
    __type(name: $name) {
      name
      enumValues {
        name
      }
    }
  }
`

export const ENUM_QUERY = gql`
  query enum1 ($name: String!) {
    __type(name: $name) {
      name
      enumValues {
        name
      }
    }
  }
`

const EnumSelector = forwardRef((props: Props, ref: any) => {
  const {
    id,
    value,
    client,
    enumName,
    onChange,
    multiple,
    camelCaseToNormal,
    allowClear = false,
  } = props

  const { loading, error, data } = useQuery(ENUM_QUERY, {
    client,
    variables: { name: enumName },
    fetchPolicy: 'cache-and-network'
  })

  const enums = useMemo(
    () =>
      data?.__type?.enumValues?.map((t: any) => {
        if (camelCaseToNormal) {
          return {
            value: t.name,
            label: camelCaseToSpace(t.name)
          }
        }
        return {
          value: t.name,
          label: t.name
        }
      }),
    [camelCaseToNormal, data?.__type?.enumValues]
  )

  if (error) {
    logger.error(`EnumSelector ${enumName} error`, error)
    responseHandler(error, 'error')
  }

  return (
    <Select
      {...props}
      id={id}
      ref={ref}
      value={value}
      loading={loading}
      onChange={onChange}
      allowClear={allowClear}
      mode={multiple ? 'multiple' : props.mode || undefined}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
    >
      {enums?.map((t: any) =>
        <Select.Option key={t.value} value={t.value}>
          {t.label}
        </Select.Option>
      )}
    </Select>
  )
})

export default withApollo(memo(EnumSelector))
