import ADD_PAYMENT from './addPayment'
import CREATE_VOUCHER_SLIM_MUT from './createVoucherSlim'
import UPDATE_VOUCHER_SLIM_MUT from './updateVoucherSlim'
import APPROVE_VOUCHER_SLIM_MUT from './approveVoucherSlim'
import VOUCHER_QUERY from './voucherQuery'
import ADD_VOUCHER_ITEM_SLIM_MUT from './addVoucherItem'
import { UPDATE_VOUCHER_ITEM_SLIM } from '../mutation/updateVoucherItem'
import DELETE_VOUCHER_ITEM from './deleteVoucherItem'
import CHANGE_VOUCHER_STATUS_SLIM_MUT from './changeVoucherStatusSlim'
import LINK_DOCUMENT_TO_VOUCHER from './linkDocumentToVoucher'
import UNLINK_DOCUMENT_FROM_VOUCHER from './unlinkDocumentFromVoucher'

export default {
  ADD_PAYMENT,
  VOUCHER_QUERY,
  CREATE_VOUCHER: CREATE_VOUCHER_SLIM_MUT,
  UPDATE_VOUCHER: UPDATE_VOUCHER_SLIM_MUT,
  APPROVE_VOUCHER: APPROVE_VOUCHER_SLIM_MUT,
  ADD_VOUCHER_ITEM: ADD_VOUCHER_ITEM_SLIM_MUT,
  UPDATE_VOUCHER_ITEM: UPDATE_VOUCHER_ITEM_SLIM,
  DELETE_VOUCHER_ITEM,
  CHANGE_VOUCHER_STATUS: CHANGE_VOUCHER_STATUS_SLIM_MUT,
  LINK_DOCUMENT_TO_VOUCHER,
  UNLINK_DOCUMENT_FROM_VOUCHER
}
