import { Fragment } from 'react'
import LegsTable from './LegsTable'
import NewLegContent from './NewLegContent'

const LegsContent = (props) => {
  const { trip, onChange } = props
  return (
    <Fragment>
      <LegsTable legs={trip.legs} onChange={onChange} />
      <NewLegContent tripUuid={trip.uuid} onChange={onChange} />
    </Fragment>
  )
}

export default LegsContent
