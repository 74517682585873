import { gql } from '@apollo/client'

import documentCreatorFragment from '../fragment/documentCreator'

export default gql`
  query documentCreatorsContainer($limit: Int, $offset: Int, $bookingUuid: UUID) {
    documentCreators(limit: $limit, offset: $offset, bookingUuid: $bookingUuid) {
      rows {
        ...DocumentCreatorDetails
      }
      pageInfo {
        count
        limit
        offset
      }
    }
  }
  ${documentCreatorFragment}
`
