export const actions = {
  UPDATE_SELECTED_CHARGE_ITEM: 'UPDATE_SELECTED_CHARGE_ITEM'
}

export const updateSelectedChargeItem = (chargeItem) => ({
  type: actions.UPDATE_SELECTED_CHARGE_ITEM,
  chargeItem
})

const initialState = {
  selectedChargeItem: {}
}

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_SELECTED_CHARGE_ITEM:
      return { ...state, selectedChargeItem: action.chargeItem }
    default:
      return state
  }
}
