import { graphql } from 'react-apollo'

import schema from '../schema'

export default graphql(schema.BOOKING_QUERY, {
  name: 'bookingQuery',
  options: (props) => ({
    variables: {
      uuid: props.match && props.match.params && props.match.params.uuid
    }
  })
})
