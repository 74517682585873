import { gql } from '@apollo/client'

export const documentCreatorsGql = gql`
  query documentCreatorsForBooking($bookingUuid: UUID!, $limit: Int, $offset: Int) {
    documentCreators(bookingUuid: $bookingUuid, limit: $limit, offset: $offset) {
      rows {
        uuid
        bookingUuid
        status
        type
        dataJSON
        dataJSONObject
        createdAt
        updatedAt
      }

      pageInfo {
        count
        limit
        offset
      }
    }
  }
`
