import { gql } from '@apollo/client'

export default gql`
  query company5($uuid: UUID!) {
    company(uuid: $uuid) {
      uuid
      name
    }
  }
`
