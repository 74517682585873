import { gql } from '@apollo/client'

import exchangeRate from 'App/containers/exchangeRates/fragment/exchangeRate'

// TODO: double check this GQL is working in code,
// changed from returning single object to returning rows.

export default gql`
  query multiExchangeRates(
    $sellFromUuid: UUID
    $sellToUuid: UUID
    $costFromUuid: UUID
    $costToUuid: UUID
    $date: DateTime
  ) {
    sellExchangeRate: exchangeRates(fromUuid: $sellFromUuid, toUuid: $sellToUuid, date: $date) {
      rows {
        ...ExchangeRateDetails
      }
    }

    costExchangeRate: exchangeRates(fromUuid: $costFromUuid, toUuid: $costToUuid, date: $date) {
      rows {
        ...ExchangeRateDetails
      }
    }
  }

  ${exchangeRate}
`
