import { Fragment } from 'react'
import { Modal, Button } from 'antd'
import { compose, withState, withHandlers } from 'recompose'

import TemplateSelectionList from './TemplateSelectionList'
import DocumentCreatorModal from '../DocumentCreatorsSection/TableView/EditAction/DocumentCreatorModal'

const visibleState = withState('visible', 'setVisible', false)
const uuidState = withState('documentCreatorUuid', 'setDocumentCreatorUuid', '')

const handlers = withHandlers({
  handleClick: (props) => () => {
    const { setVisible } = props
    setVisible(true)
  },
  handleModalCancel: (props) => () => {
    const { setVisible } = props
    setVisible(false)
  },
  handleModalOk: (props) => () => {
    const { setVisible } = props
    setVisible(false)
  },
  handleCreated: (props) => (documentCreator) => {
    const { setVisible, setDocumentCreatorUuid } = props
    setVisible(false)
    setDocumentCreatorUuid(documentCreator.uuid)
  },
  handleEditAfterClose: (props) => () => {
    const { setDocumentCreatorUuid, onCreated = () => { } } = props
    setDocumentCreatorUuid('')
    onCreated()
  }
})

const enhance = compose(visibleState, uuidState, handlers)

const AddAction = (props) => {
  const {
    visible,
    documentCreatorUuid,
    bookingUuid,
    handleClick,
    handleModalCancel,
    handleModalOk,
    handleCreated,
    handleEditAfterClose
  } = props

  return (
    <Fragment>
      <Button icon="plus" shape="circle" onClick={handleClick} />
      <Modal
        destroyOnClose
        width="80%"
        title="Select a template"
        visible={visible}
        footer={null}
        onCancel={handleModalCancel}
        onOk={handleModalOk}
      >
        <TemplateSelectionList bookingUuid={bookingUuid} onCreated={handleCreated} />
      </Modal>
      {documentCreatorUuid && (
        <DocumentCreatorModal
          documentCreatorUuid={documentCreatorUuid}
          afterClose={handleEditAfterClose}
        />
      )}
    </Fragment>
  )
}

export default enhance(AddAction)
