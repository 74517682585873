
import PropTypes from 'prop-types'

import {
  Name,
  Wrapper,
  Header,
  FileName,
  Selector,
  RemoveIcon,
  Description,
  StatusWrapper,
  SelectorWrapper
} from './Styled'

import { Col, Icon } from 'antd'

import { documentType } from 'App/utils/labelMap'
import StatusIcon from './StatusIcon'

const DocumentSelector = (props) => {
  const { uploadedDoc, removable = false, hrefUrl } = props

  const isVerified = uploadedDoc.status === 'VERIFIED'
  const isDeleted = uploadedDoc.status === 'DELETED'

  return (
    <Wrapper onClick={props.onClick}>
      {removable && <RemoveIcon type="close" onClick={props.onRemove} />}
      <SelectorWrapper>
        <Selector verified={isVerified} deleted={isDeleted} href={hrefUrl} target="_blank">
          <Header type="flex" justify="space-around" align="middle">
            <Col span={24}>
              <Name>{documentType[uploadedDoc.type] || uploadedDoc.type}</Name>
            </Col>
          </Header>
          <Description>
            <Col span={20}>
              <FileName>
                <Icon type={isDeleted ? 'delete' : 'paper-clip'} />
                {uploadedDoc.document.name}
              </FileName>
            </Col>
            <Col span={4}>
              <StatusWrapper>
                <StatusIcon uploadedDoc={uploadedDoc} />
              </StatusWrapper>
            </Col>
          </Description>
        </Selector>
      </SelectorWrapper>
    </Wrapper>
  )
}

DocumentSelector.propTypes = {
  uploadedDoc: PropTypes.object,
  onClick: PropTypes.func
}

export default DocumentSelector
