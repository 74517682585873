import { useEffect } from 'react'

import { withRouter } from 'App/RouterHOC'

/* HOC to secure ShipX from unauthorized access */
export default (WrappedComponent) => {
  const WithSecure = ({ history, isAuthenticated, ...props }) => {
    useEffect(() => {
      if (!isAuthenticated) { // if not authenticated, redirect to sign-off page
        history.push('/auth/sign-off')
      }
    }, [isAuthenticated, history])

    return isAuthenticated ? <WrappedComponent {...props} /> : null;
  }

  return withRouter(WithSecure)
}
