import styled from 'styled-components'

const stdHeight = '35px'

export const Wrapper = styled.div`
  min-width: 250px;

  .ant-select {
    width: 100%;
  }

  .ant-select-selection--multiple {
    padding-bottom: 0px;
    min-height: ${stdHeight};
  }

  .ant-select-selection--multiple .ant-select-selection__rendered {
    margin-bottom: 0px;
    line-height: normal;
  }

  .ant-select-selection {
    border: 1px solid white;
    transition: 0s;
    padding: 4px 0;
    border-radius: 0;
    padding-right: 50px;
  }

  .ant-select-selection:hover {
    border-color: rgb(235, 235, 235);
  }

  .ant-select-focused .ant-select-selection,
  .ant-select-open .ant-select-selection,
  .ant-select-selection:active {
    box-shadow: none;
    border-color: rgb(215, 215, 215);
  }

  .ant-select-selection--multiple .ant-select-selection__choice {
    color: black;
  }

  .ant-select-selection--multiple .ant-select-selection__choice__remove {
    top: 3px;
  }

  .ant-select-selection--multiple .ant-select-selection__rendered > ul > li {
    height: 30px;
    line-height: 25px;
    margin-top: 0px;
  }

  .ant-avatar {
    height: 28px;
    width: 28px;
    border: none;
    margin-right: 3px;
    vertical-align: top;
  }

  .ant-btn-circle {
    height: 28px;
    width: 28px;

    .anticon-plus {
      font-size: 14px;
    }
  }
`

export const TagsWrapper = styled.div`
  padding: 0 0 0px;
`

export const OptionWrapper = styled.div`
  .option-avatar {
    display: inline-block;
    vertical-align: top;
    padding-right: 3px;

    .ant-avatar {
      height: 24px;
      width: 24px;
      border: none;
      margin: unset;
      vertical-align: middle;
    }
  }

  .option-email {
    font-size: 13.222px;
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    line-height: 28px;
  }
`
