import { gql } from '@apollo/client'

export default gql`
  mutation changeDocumentCreatorStatusAdvanced($uuid: UUID!, $status: DocumentCreatorStatus!) {
    changeDocumentCreatorStatusAdvanced(uuid: $uuid, status: $status) {
      uuid
      bookingUuid
      status
      type
    }
  }
`
