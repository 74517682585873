import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Select, Col } from 'antd'

import FieldLabel from '../FieldLabel'
import FieldWrapper from '../FieldWrapper'
import DisplayOnlyField from '../DisplayOnlyField'

class SelectField extends Component {
  handleChange = (value, option) => {
    const { onChange } = this.props

    onChange(value, option)
  }

  render() {
    const {
      allowClear,
      placeholder,
      label,
      position,
      dataSource = [],
      value,
      width = '100%',
      editMode = false,
      showSearch = false
    } = this.props

    const options = dataSource.map((item, index) => {
      return (
        <Select.Option key={index} value={item.value}>
          {item.text}
        </Select.Option>
      )
    })

    const selectField = (
      <Select
        allowClear={allowClear}
        onChange={this.handleChange}
        value={value}
        placeholder={placeholder}
        style={{ width }}
        disabled={!editMode}
        showSearch={showSearch}
        optionFilterProp="children"
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        getPopupContainer={(trigger) => trigger.parentNode}
      >
        {options}
      </Select>
    )

    if (!editMode) {
      return (
        <div>
          <FieldLabel>{label}</FieldLabel>
          <DisplayOnlyField>{value}</DisplayOnlyField>
        </div>
      )
    }

    if (position === 'sideBySide') {
      return (
        <FieldWrapper align="middle" justify="center">
          <Col span={4}>
            <FieldLabel>{label}</FieldLabel>
          </Col>
          <Col span={20}>{selectField}</Col>
        </FieldWrapper>
      )
    } else {
      return (
        <FieldWrapper align="middle" justify="center">
          <FieldLabel>{label}</FieldLabel>
          {selectField}
        </FieldWrapper>
      )
    }
  }
}

SelectField.propTypes = {
  width: PropTypes.string,
  value: PropTypes.string,
  editMode: PropTypes.bool,
  showSearch: PropTypes.bool,
  dataSource: PropTypes.array,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  position: PropTypes.string
}

export default SelectField
