import React, { Component } from 'react'

import { Row, Col, Icon } from 'antd'

import { documentType } from 'App/utils/labelMap'

import { StyledHeader, DocumentDetails, DocumentName, DocumentType, Actions } from './Styled'
import HeaderOptions from './Options'

class ViewerHeader extends Component {
  render() {
    const { bookingDocument, onBack, bookingQuery } = this.props

    const type = documentType[bookingDocument.type] || bookingDocument.type

    const docPOD = bookingQuery?.booking?.jobs.flatMap(job =>
      job.trips.filter(trip => trip.uuid === bookingDocument.tripUuid)
        .map(trip => {
          const jobNoPart = job.jobNo.slice(0, job.jobNo.length);
          return [job.no ? job.no : jobNoPart, trip.sequence].filter(Boolean).join('-')
        })
    )

    return (
      <StyledHeader type="flex" align="middle">
        <Col span={14}>
          <Row type="flex" align="middle">
            <DocumentDetails>
              <DocumentName>
                {bookingDocument.document && bookingDocument.document.name} - {docPOD}
              </DocumentName>
              <DocumentType>{type}</DocumentType>
            </DocumentDetails>
          </Row>
        </Col>
        <Col span={10}>
          <Row type="flex" align="middle" justify="end">
            <Actions>
              <HeaderOptions bookingDocument={bookingDocument} />
            </Actions>
            <Col>
              <Icon type="close" onClick={onBack} />
            </Col>
          </Row>
        </Col>
      </StyledHeader>
    )
  }
}

export default ViewerHeader
