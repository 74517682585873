import { gql } from '@apollo/client'

// import voucherPaymentFragment from './voucherPayment'

export default gql`
  fragment PaymentDetails on Payment {
    uuid
    status
    remarks
    transactionType
    reference
    date
    amount
  }
`
