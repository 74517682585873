import { gql } from '@apollo/client'

import { fragments as costItemFragment } from 'App/containers/costItem/fragment'

export default gql`
  mutation addBookingCostItem1($input: AddCostItemInput!) {
    addBookingCostItem(input: $input) {
      ...CostItemDetails
    }
  }

  ${costItemFragment.costItemDetails}
`
