import { Component } from 'react'
import isEmpty from 'lodash/isEmpty'

import { Select, Popover, Avatar, Button, Row, Col, Tooltip } from 'antd'

import { OptionLabel, OptionSubLabel, AvatarSelector } from './Styled'
import * as SelectWrapper from './SelectWrapper'

class SingleSelect extends Component {
  filterUsers = (input, option) => {
    const { props } = option

    return (
      (props.user.email &&
        props.user.email.toLowerCase().indexOf(input && input.toLowerCase()) >= 0) ||
      (props.user.nickname &&
        props.user.nickname.toLowerCase().indexOf(input && input.toLowerCase()) >= 0)
    )
  }

  renderSelector = () => {
    const { options, selectedUser, handleSelect } = this.props

    return (
      <SelectWrapper.Wrapper>
        <SelectWrapper.Title>Assign to</SelectWrapper.Title>
        <Select
          defaultValue={selectedUser && selectedUser.nickname}
          mode="combobox"
          allowClear
          placeholder="Select an assignee"
          showArrow={false}
          onSelect={handleSelect}
          filterOption={this.filterUsers}
          optionLabelProp="nickname"
        >
          {options.map((option) => {
            return (
              <Select.Option
                key={option.uuid}
                value={option.uuid}
                user={option}
                nickname={option.nickname}
              >
                <Row type="flex" justify="space-around" align="middle">
                  <Col span={5}>
                    <Avatar src={option.picture} />
                  </Col>
                  <Col span={19}>
                    <OptionLabel>{option.nickname}</OptionLabel>
                    <OptionSubLabel>{option.email}</OptionSubLabel>
                  </Col>
                </Row>
              </Select.Option>
            )
          })}
        </Select>
      </SelectWrapper.Wrapper>
    )
  }

  renderButton = () => {
    const { selectedUser } = this.props

    if (isEmpty(selectedUser)) {
      return <Button icon="plus" type="dashed" shape="circle" />
    } else {
      return (
        <Tooltip title={selectedUser.email}>
          <AvatarSelector>
            <Avatar src={selectedUser.picture} />
          </AvatarSelector>
        </Tooltip>
      )
    }
  }

  render() {
    return (
      <Popover trigger="click" content={this.renderSelector()} placement="bottomRight">
        {this.renderButton()}
      </Popover>
    )
  }
}

export default SingleSelect
