import { gql } from '@apollo/client'

import { fragments as currencyFragment } from '../fragment'

export const CURRENCIES_QUERY = gql`
  query currencies2 {
    currencies(limit: 10000) {
      rows {
        ...CurrencyDetails
      }
    }
  }

  ${currencyFragment.currencyDetails}
`
