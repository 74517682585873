import { gql } from '@apollo/client'

import { fragments as userFragment } from '../fragment'

export default gql`
  query users($q: String, $companyUuids: [UUID], $limit: Int, $offset: Int) {
    users(q: $q, companyUuids: $companyUuids, limit: $limit, offset: $offset) {
      rows {
        ...UserDetails
      }
    }
  }

  ${userFragment.userDetails}
`
