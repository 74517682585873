import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import createSelectors from './createSelectors'

import type { BaseCompanies, BaseCompany } from 'App/types/types'

interface GlobalCompanyState {
  globalCompanies: BaseCompanies
  selectedGlobalCompany: BaseCompany
  setGlobalCompanies: (companies: BaseCompanies) => void
  setSelectedGlobalCompany: (company: BaseCompany) => void
}

const useGlobalCompanyStore = createSelectors(
  create<GlobalCompanyState>()(
    devtools(
      persist(
        (set) => ({
          globalCompanies: [] as BaseCompanies,
          selectedGlobalCompany: {},
          setGlobalCompanies: (companies) => set(() => ({ globalCompanies: companies })),
          setSelectedGlobalCompany: (company) => set(() => ({ selectedGlobalCompany: company }))
        }),
        { name: 'globalCompanyStore' }
      ),
      { name: 'GlobalCompany Store' }
    )
  )
)

export default useGlobalCompanyStore
