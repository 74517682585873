import { useLazyQuery } from '@apollo/client'
import FormItemMapper from 'App/components/Manage/FormItemMapper'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'

import { JOB_TYPE_QUERY } from 'App/graphql/booking'

import { BookingDynamicFieldType, BookingTypeDynamicField, BookingTypeDynamicFieldControl, type Job, type JobType, type TransportJob } from 'App/types/types'
import { startCase } from 'lodash'
import DisplayBookingDetails from './DisplayBookingDetails'
import responseHandler from 'App/utils/responseHandler'
import DynamicField, { DATE_FORMAT } from 'App/components/Shared/DynamicField'
import moment from 'moment'

const leftFormItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 }
  }
}

interface JobFormDetailsProps {
  form: any
  value: any
  job: Job
  leg: TransportJob
  client: any
  editMode: boolean
}

const JobFormDetails = memo((props: JobFormDetailsProps) => {
  const { form, job, leg, client, editMode = false, value = {} } = props
  const { getFieldDecorator } = form
  const [jobType, setJobType] = useState<JobType>()

  const generateFormItemProps = useCallback((field: BookingTypeDynamicField, details: Record<string, any>, type: 'job' | 'trip') => {
    const key = field.key || ''
    const val = details?.[key]

    return {
      label: startCase(key),
      value: `${type}.details.${key}`,
      input: <DynamicField id={key} field={field} disabled={!editMode} />,
      attributes: {
        initialValue: field.type === BookingDynamicFieldType.Date ? val ? moment(val, DATE_FORMAT) : null : val,
        rules: [
          {
            required: BookingTypeDynamicFieldControl.Required === field?.control,
            message: `${field.key} is required`
          }
        ]
      },
      ...leftFormItemLayout
    }
  }, [editMode, value])

  const cols = useMemo(() => {
    if (job && !job.details) job.details = {}
    const jobFields = (jobType?.dynamicFields || []).map((field: BookingTypeDynamicField) => generateFormItemProps(field, job?.details, 'job'))

    const trip = job.trips?.find((trip) => trip?.uuid === leg.tripUuid)

    if (trip && !trip.details) trip.details = {}
    const tripFields = (jobType?.tripDynamicFields || []).map((field: BookingTypeDynamicField) => generateFormItemProps(field, trip?.details, 'trip'))

    return {
      jobFields,
      tripFields,
    }
  }, [jobType?.dynamicFields, jobType?.tripDynamicFields, job.details, job.trips, editMode, leg.tripUuid, value])

  const [getJobType] = useLazyQuery(JOB_TYPE_QUERY, {
    client,
    onCompleted: (data) => {
      const jobType: JobType = data?.jobType

      if (!jobType) return

      setJobType(jobType)
    },
    onError: (error) => {
      responseHandler(error, 'error')
    }
  })

  useEffect(() => {
    if (!job.type || jobType?.code === job.type) return

    getJobType({
      variables: {
        code: job.type
      }
    })
  }, [job.type, jobType])

  return (
    <>
      <DisplayBookingDetails leg={leg} headerTitle={'Booking Details'} />
      <FormItemMapper fields={[{ cols: cols.jobFields }]} headerTitle={'Job Details'} getFieldDecorator={getFieldDecorator} />
      <FormItemMapper fields={[{ cols: cols.tripFields }]} headerTitle={'Trip Details'} getFieldDecorator={getFieldDecorator} />
    </>
  )
})

export default JobFormDetails
