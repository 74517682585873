import { gql } from '@apollo/client'

export default gql`
  fragment VoucherTemplateDetails on VoucherTemplate {
    type
    transactionCategory
    transactionType
    approvals
    bookingDocuments
  }
`
