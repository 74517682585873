/* eslint-disable camelcase */
import { memo, useState, useCallback, useMemo } from 'react'
import { Button, Divider, Form, Input, Popover, Radio, Row } from 'antd'
import { withApollo } from 'react-apollo'
import { useMutation } from '@apollo/client'
import { v4 as uuidv4 } from 'uuid'

import { CompanyStatus, YardStatus } from 'App/types/types'
import { yardCreate } from 'App/components/Manage/Yards/Create'
import { QuickCreateDiv } from 'App/components/Select/TypeToFetch/CompanySelect/Styled'
import { Actions, ActionItem } from 'App/components/Manage/Styled'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'
import YardAddressForm from './YardAddressForm'
import config from 'App/config'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 17 }
  }
}

const QuickCreateYard = memo((props: any) => {
  const { client, form } = props
  const { getFieldDecorator } = form

  const frontGoogleMapsUrl = useMemo(() => config.google.mapsPlaceUrl, [])

  const [visible, setVisible] = useState(false)
  const [googleMapsURL, setGoogleMapsURL] = useState(frontGoogleMapsUrl)

  const [createYard, { loading }] = useMutation(yardCreate, { client })

  const onVisibleChange = useCallback(() => {
    setVisible((state: boolean) => !state)
  }, [])

  const onSubmit = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) {
        return
      }

      try {
        const inputObj = {
          uuid: uuidv4(),
          code: values?.code,
          name: values?.name,
          remarks: values?.remarks,
          status: values?.status,
          address: {
            uuid: values?.address_uuid || uuidv4(),
            name: values?.address_name,
            status: values?.address_status || CompanyStatus.Activated,
            type: values?.address_type || null,
            address1: values?.address_address1,
            address2: values?.address_address2 || null,
            address3: values?.address_address3 || null,
            address4: values?.address_address4 || null,
            city: values?.address_city || null,
            district: values?.address_district || null,
            postCode: values?.address_postCode || null,
            placeId: values?.address_placeId || null,
            plusCode: values?.address_plusCode || null,
            areaCode: values?.address_areaCode || null,
            zone: values?.address_zone || null,
            phone: values?.address_phone || null,
            fax: values?.address_fax || null,
            tags: values?.address_tags || null,
            countryAlpha3: values?.address_countryAlpha3 || null,
            location:
              values?.address_latitude && values?.address_latitude
                ? {
                  type: 'Point',
                  coordinates: [values?.address_latitude, values?.address_longitude]
                }
                : null
          }
        }

        const res = await createYard({
          variables: {
            input: inputObj
          }
        })

        if (res?.data?.createYard?.uuid) {
          respHandler('Successfully created yard.', 'success')
          onVisibleChange()
        }
      } catch (error) {
        logger.error('Quick create yard error', error)
        respHandler(error, 'error')
      }
    })
  }, [createYard, form, onVisibleChange])

  const redirect = useCallback(() => {
    window.open(`${window.location.origin}/manage/yards/create`, 'yardCreateTab')
  }, [])

  const popOverContent = (
    <Row style={{ maxHeight: '420px', overflowY: 'auto' }}>
      <Form style={{ width: '600px' }}>
        <Form.Item label="Code" {...formItemLayout}>
          {getFieldDecorator('code', {
            rules: [
              {
                required: true,
                message: 'Code is required.'
              }
            ]
          })(<Input autoComplete="off" placeholder="Enter a yard code..." />)}
        </Form.Item>
        <Form.Item label="Name" {...formItemLayout}>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Name is required.'
              }
            ]
          })(<Input autoComplete="off" placeholder="Enter a yard name..." />)}
        </Form.Item>
        <Form.Item label="Remarks" {...formItemLayout}>
          {getFieldDecorator(
            'remarks',
            {}
          )(<Input autoComplete="off" placeholder="Enter remarks..." />)}
        </Form.Item>
        <Form.Item label="Status" {...formItemLayout}>
          {getFieldDecorator('status', {
            initialValue: YardStatus.Active
          })(
            <Radio.Group buttonStyle="solid">
              <Radio.Button value={YardStatus.Active}>
                {YardStatus.Active}
              </Radio.Button>
              <Radio.Button value={YardStatus.Deleted}>
                {YardStatus.Deleted}
              </Radio.Button>
            </Radio.Group>
          )}
        </Form.Item>

        <Row style={{ marginTop: '20px', marginLeft: '42px', marginRight: '42px' }}>
          <Divider style={{ paddingBottom: '10px', fontSize: '13px' }}>Address</Divider>
        </Row>
      </Form>

      <YardAddressForm
        form={form}
        frontGoogleMapsUrl={frontGoogleMapsUrl}
        setGoogleMapsURL={setGoogleMapsURL}
        style={{ width: '600px' }}
      />

      <Actions>
        <ActionItem span={24}>
          <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={onVisibleChange}>Cancel</Button>
            <a href={googleMapsURL} target="_blank" rel="noreferrer noopener">
              <Button>Open Map</Button>
            </a>
            <Button onClick={onSubmit} loading={loading} type="primary">
              Create
            </Button>
          </Row>
        </ActionItem>
      </Actions>
    </Row>
  )

  return (
    <QuickCreateDiv>
      <Popover
        content={popOverContent}
        placement="left"
        title="Create Yard"
        trigger="click"
        visible={visible}
      >
        <Button
          icon="plus-circle"
          type="link"
          style={{ color: 'rgba(0, 0, 0, 0.65)', padding: 0, margin: 0 }}
          onClick={redirect}
        >
          Create New
        </Button>
      </Popover>
    </QuickCreateDiv>
  )
})

// @ts-ignore
export default withApollo(Form.create()(QuickCreateYard))
