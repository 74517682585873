import { forwardRef } from 'react'
import { Select, Icon } from 'antd'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { compose, withHandlers } from 'recompose'

import { BaseCompanyCurrenciesGql } from './BaseCompanyCurrenciesGql'
import { CURRENCIES_QUERY } from 'App/containers/currency/schema'

const baseCompanyCurrenciesGraphql = graphql(BaseCompanyCurrenciesGql, {
  name: 'baseCompanyCurrenciesGraphql',
  skip: (props) => {
    return !(props.selectedGlobalCompany && props.selectedGlobalCompany.uuid)
  },
  options: (props) => ({
    variables: {
      uuid: props.selectedGlobalCompany && props.selectedGlobalCompany.uuid
    }
  })
})

const allCurrencies = graphql(CURRENCIES_QUERY, {
  name: 'allCurrencies',
  skip: (props) => {
    return !props.showAll
  }
})

const handlers = withHandlers({
  changeCurrency: (props) => (value, option) => {
    const { onCurrencyChanged = () => { } } = props
    onCurrencyChanged(value, option.props.currency)
  }
})

const enhance = compose(
  connect((state) => ({ selectedGlobalCompany: state.globalCompany?.selectedGlobalCompany?.company })),
  baseCompanyCurrenciesGraphql,
  allCurrencies,
  handlers
)

const Option = Select.Option

const sort = (currencies) => {
  const result = [...currencies].sort((a, b) => {
    return a.code < b.code ? -1 : 1
  })

  return result
}

const CurrencySelect = forwardRef((props, ref) => {
  const {
    onChange,
    allCurrencies,
    changeCurrency,
    selectedCurrencyUuid,
    baseCompanyCurrenciesGraphql,
    disabled = false,
    showAll = false
  } = props

  const currencies = showAll
    ? allCurrencies?.currencies?.rows || []
    : baseCompanyCurrenciesGraphql?.baseCompany?.currencies || []

  const sorted = sort(currencies)

  return (
    <Select
      ref={ref}
      showSearch
      filterOption
      disabled={disabled}
      optionFilterProp="children"
      value={selectedCurrencyUuid}
      placeholder="Select a currency..."
      onChange={onChange || changeCurrency}
      getPopupContainer={(trigger) => trigger.parentNode}
      notFoundContent={baseCompanyCurrenciesGraphql?.loading ? <Icon type="loading" /> : 'Not found'}
    >
      {sorted.map((currency) => (
        <Option key={currency.uuid} value={currency.uuid} currency={currency}>
          {currency.code} - {currency.name}
        </Option>
      ))}
    </Select>
  )
})

export default enhance(CurrencySelect)
