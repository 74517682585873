import { useEffect } from 'react'
import config from 'App/config'
import * as download from 'downloadjs'

import respHandler from 'App/utils/responseHandler'
import { logger } from 'App/utils/logger'

const downloadFile = async (state: any, setState: any, voucher: any) => {
  const downloadUrl = `${config.pdf.baseUrl}/pdf/${state.pdfUuid}`

  try {
    const response = await fetch(downloadUrl, {
      method: 'GET'
    })

    const blob = await response.blob()

    // @ts-ignore
    download(blob, `${voucher.voucherNumber || voucher.reference || voucher.uuid}.pdf`)
    setState({ ...state, status: 'downloaded' })
    respHandler('Downloaded successfully.', 'success')
  } catch (error) {
    logger.error('useDownloadEffect downloadFile pdf error', error)
    respHandler(error, 'error')
  }
}

const useDownloadEffect = (state: any, setState: any, voucher: any) => {
  useEffect(() => {
    if (state.status === 'downloading' && state.pdfUuid !== null) {
      downloadFile(state, setState, voucher)
    }

    return () => {}
  })
}

export default useDownloadEffect
