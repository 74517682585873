import { gql } from '@apollo/client'

export const PLACES_QUERY = gql`
  query placesPortSelector($q: String) {
    places(q: $q) {
      placeId
      description
    }
  }
`
