import { graphql } from 'react-apollo'

import schema from '../schema'

export default graphql(schema.ADD_PAYMENT, {
  props: ({ mutate, ownProps }) => ({
    addPayment: (params) =>
      mutate({
        variables: {
          input: params
        }
      })
  })
})
